@media (min-width: 1200px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1200px;
  }
}

@media only screen and (max-width: 767px) {
  .container-big {
    width: calc(100% - 10px)!important;
    padding-left: 0!important;
    padding-right: 0!important;
  }
}
