.bootstrap-tagsinput {
	background-color: $textForDarkElements;
	border: 1px solid #ccc;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	//display: inline;
	padding: 4px 6px;
	color: #555;
	vertical-align: middle;
	border-radius: 4px;
	max-width: 100%;
	line-height: 30px;
	cursor: text;
}

.bootstrap-tagsinput input {
	border: none;
	box-shadow: none;
	outline: none;
	background-color: transparent;
	padding: 0 6px;
	margin: 0;
	//width: 100%;
	width: auto;
	max-width: inherit;
}

.bootstrap-tagsinput.form-control input::-moz-placeholder {
	color: #777;
	opacity: 1;
}

.bootstrap-tagsinput.form-control input:-ms-input-placeholder {
	color: #777;
}

.bootstrap-tagsinput.form-control input::-webkit-input-placeholder {
	color: #777;
}

.bootstrap-tagsinput input:focus {
	border: none;
	box-shadow: none;
}

.bootstrap-tagsinput .tag {
	font-size: 100%;
	font-weight: normal;
	background-color: #e8e8e8;
	margin-right: 2px;
	color: #333;
}

.bootstrap-tagsinput .tag [data-role="remove"] {
	font-weight: bold;
	margin-left: 8px;
	cursor: pointer;
}

.bootstrap-tagsinput .tag [data-role="remove"]:after {
	content: "x";
	color: #888888;
	padding: 0px 2px;
	font-size: 15px;
}

.bootstrap-tagsinput .tag [data-role="remove"]:hover {
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
}

.bootstrap-tagsinput .tag [data-role="remove"]:hover:active {
	box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.label {
	display: inline;
	padding: .2em .6em .3em;
	font-size: 75%;
	color: $textForDarkElements;
	border-radius: .25em;
}