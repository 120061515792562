.services-content {
  .is-active-tariff {
    .color8,
    .color7,
    .color6,
    .color5,
    .color1,
    .color2,
    .color3,
    .color4 {
      &:hover:before {
        visibility: hidden;
      }
    }

    .active:before,
    .active:hover:before {
      visibility: visible;
    }

    .text-is-active {
      display: inline-block;
    }

    .btn {
      display: none;
    }
  }

  &-title {
    margin-bottom: 20px;
  }

  &-subtitle {
    margin-bottom: 30px;

    .read-more {
      color: #008AEA;
      cursor: pointer;
    }
  }
}

.block-services-subtitles {
  .services-content-subtitle__hm {
    display: none;
  }

  .read-more-subtitle {
    .services-content-subtitle__hm {
      display: inline-block !important;
    }

    .services-content-subtitle__sm,
    .services-content-subtitle__md,
    .services-content-subtitle__rm {
      display: none !important;
    }
  }

  .services-content-subtitle__sm,
  .services-content-subtitle__md {
    display: none !important;
  }
}

.services-list {
  @include flexbox();
  @include flex-wrap(wrap);
  @include justify-content(center);
  padding: 0;
  margin-bottom: 10px;

  .text-is-active {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    display: none;
  }
}

.service-card {
  width: calc(25% - 12.75px);
  margin-right: 9.5px;
  margin-bottom: 20px;
  padding: 17px 20px 20px;
  border: 1px solid #D6D6D6;
  border-radius: 10px;
  @include flexbox();
  @include flex-direction(column);
  @include align-items(center);
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3);

  &:nth-child(4n + 4) {
    margin-right: 0;
  }

  .fa-check {
    color: #02B538;
  }

  .fa-times {
    color: #FF8484;
  }

  ul {
    margin-bottom: 22px;
    padding: 0;
    width: 100%;
  }

  li {
    @include flexbox();
    @include align-items(baseline);
    @include justify-content(flex-start);
  }

  .service-name-count {
    @include flexbox();
    @include align-items(baseline);
    @include justify-content(space-between);
    padding-left: 10px;
    width: calc(100% - 15px);

    p:nth-child(1) {
      width: calc(100% - 50px);
      padding-right: 5px;
      margin-bottom: 0;

      .fa-medal {
        font-size: 14px;
        color: #EA4E00;
        margin-left: 5px;
      }
    }

    p:nth-child(2) {
      max-width: 50px;
      margin-bottom: 0;
    }

    .inactive-services {
      font-weight: 300;
      color: #6C757D;
    }
  }

  .no-subtitle {
    min-height: 78px;
  }

  .title-content {
    height: calc(100% - 35px);
    width: 100%;
    @include flexbox();
    @include flex-direction(column);
    @include align-items(center);

    .icon-wrap {
      width: 15px;
      text-align: center;
    }

    .svg-inline--fa {
      font-size: 15px;
    }
  }
}

.color1 {
  .service-card-name {
    color: #40505F;
  }

  &:before {
    background-color: #40505F;
  }
}

.color2 {
  .service-card-name {
    color: #02B538;
  }

  &:before {
    background-color: #02B538;
  }
}

.color3 {
  .service-card-name {
    color: #FF9E00;
  }

  &:before {
    background-color: #FF9E00;
  }
}

.color4 {
  .service-card-name {
    color: $hoverPrimary;
  }

  &:before {
    background-color: $hoverPrimary;
  }
}

.color5 {
  .service-card-name {
    color: #296EBF;
  }

  &:before {
    background-color: #296ebf;
  }
}

.color6 {
  .service-card-name {
    color: #10ACA3;
  }

  &:before {
    background-color: #10ACA3;
  }
}

.color7 {
  .service-card-name {
    color: #009CDA;
  }

  &:before {
    background-color: #009CDA;
  }
}

.color8 {
  .service-card-name {
    color: #D2A400;
  }

  &:before {
    background-color: #D2A400;
  }
}

.color8,
.color7,
.color6,
.color5,
.color1,
.color2,
.color3,
.color4 {
  position: relative;

  &:before {
    content: '';
    width: 100%;
    height: 8px;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 10px 10px 0 0;
    visibility: hidden;
  }

  &:hover:before {
    visibility: visible;
  }
}

.service-card-name {
  font-size: 25px;

  .currency-icon {
    font-size: 17px;
  }
}

.service-card-content {
  @include flexbox();
  @include align-items(flex-start);
  @include justify-content(flex-start);

  .service-card {
    width: 100%;
    max-width: 282px;
    margin-right: 35px;

    li p {
      line-height: 1.8;
    }
  }

  .color8:before,
  .color7:before,
  .color6:before,
  .color5:before,
  .color1:before,
  .color2:before,
  .color3:before,
  .color4:before {
    visibility: visible;
  }

  .block-answer-success-fail {
    width: calc(100% - 397px);
  }

  .service-not-active,
  .service-active {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
  }
}

.block-answer-success {
  @include flexbox();
  @include flex-direction(column);
  @include align-items(flex-start);

  .block-answer-success-title {
    font-size: 20px;
    color: #02B538;
  }
}

.block-answer-fail {
  .block-answer-success-title {
    font-size: 20px;
    color: #E96E25;
  }
}

.title-payment-block {
  font-size: 24px;
  margin-bottom: 20px;
}

.services-payment-block {
  margin-top: 28px;

  .tariff-pro-price {
    background-color: rgba(214, 214, 214, .2);
    padding: 15px 40px;
    margin-bottom: 35px;

    ul {
      width: 220px;
    }

    li > p:nth-child(1) {
      width: 63%;
      text-align: left;
      padding-left: 0;
    }

    li > p:nth-last-child(1) {
      width: 37%;
      text-align: right;
      padding-left: 0;
    }
  }

  .tariff-name {
    font-size: 20px;

    &-price {
      @include flexbox();
      @include align-items(center);
      @include justify-content(space-between);
    }
  }

  .form-check-input {
    margin-left: 0;
  }

  label {
    margin-left: 30px;
    margin-right: 140px;
  }

  .form-invoice,
  .payment-method-content {
    margin-top: 0;
  }

  .form-check-label {
    margin-left: 0;
    margin-right: 0;
  }

  .payment-method-block {
    .marker-nav-link {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      position: relative;
      background-color: #F0F0F0;
      border: 1px solid #D5D5D5;
      @include flexbox();
      @include align-items(center);
      @include justify-content(center);
      margin-right: 12px;

      &-active {
        width: 12px;
        height: 12px;
        background-color: $primary;
        border-radius: 50%;
        display: none;
      }
    }

    .nav-link {
      @include flexbox();
      @include align-items(center);
      @include justify-content(flex-start);
      padding: 0;
      color: $textPrimary;

      &.active {
        border-bottom: none;
        margin-bottom: 0;
        color: $textPrimary;

        .marker-nav-link-active {
          display: inline-block;
        }
      }

      input[type="radio"] {
        opacity: 0;
      }
    }

    .nav-item {
      margin-right: 140px;
    }

    .nav-tabs {
      border-bottom: none;
    }
  }
}

.nav-tabs-castom-services {
  border-bottom: 1px solid #008AEA;
  margin-bottom: 20px;

  .nav-link {
    padding: 0 0 10px;
    color: $textPrimary;
    margin-right: 30px;
    line-height: 1;

    &.active {
      color: #008AEA;
    }
  }

  .material-icons {
    display: none;
  }
}

.services-table {
  &-head {
    border: 1px solid #D6D6D6;
    border-bottom: none;
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
  }

  &__col {
    text-align: center;
    font-size: 16px;
    color: #6F7273;
    font-weight: 500;
    padding: 16px 0;

    &__1 {
      width: 60.8%;
    }

    &__2 {
      width: 19.6%;
    }

    &__3 {
      width: 19.6%;
    }
  }

  &-body {
    margin-bottom: 20px;
  }

  &-item {
    border: 1px solid #D6D6D6;
    border-bottom: none;
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);

    &__col {
      padding: 20px 0;

      &__1 {
        width: 60.8%;
        text-align: left;
        padding: 20px 18px;
      }

      &__2 {
        width: 19.6%;
        text-align: center;
        font-size: 16px;
        color: $textPrimary;
        text-transform: uppercase;
      }

      &__3 {
        width: 19.6%;
      }

      .services-item-mob {
        display: none;
      }
    }

    &:nth-last-child(1) {
      border-bottom: 1px solid #D6D6D6;
    }

    &__title {
      font-size: 16px;
      color: $textPrimary;
      font-weight: 500;
      margin-bottom: 7px;
    }

    &__subtitle {
      color: #40505F;
      margin-bottom: 0;
    }
  }

  &-footer {
    @include flexbox();
    @include align-items(flex-start);
    @include justify-content(space-between);

    &__p {
      margin-bottom: 0;
      text-align: left;
      color: #6F7273;
    }
  }
}

.border-block {
  position: relative;

  &:before {
    content: '';
    width: 1px;
    height: 100%;
    background-color: #D6D6D6;
    position: absolute;
    top: 0;
    left: 60.8%;
  }

  &:after {
    content: '';
    width: 1px;
    height: 100%;
    background-color: #D6D6D6;
    position: absolute;
    top: 0;
    right: 19.6%;
  }
}

.counter {
  @include flexbox();
  @include align-items(center);
  @include justify-content(center);
  border: 1px solid #E0E0E0;
  width: 110px;
  margin: auto;

  &__btn {
    width: 30px;
    height: 30px;
    cursor: pointer;
    color: $textPrimary;
    font-size: 20px;
    text-align: center;

    &:hover {
      background-color: #008AEA;
      color: #fff;
    }
  }

  &__plus {
    margin: 0 1px;
    position: relative;

    &:before {
      content: '';
      width: 1px;
      height: 100%;
      background-color: #E0E0E0;
      position: absolute;
      top: 0;
      left: -1px;
    }

    &:after {
      content: '';
      width: 1px;
      height: 100%;
      background-color: #E0E0E0;
      position: absolute;
      top: 0;
      right: -1px;
    }
  }

  &__field {
    border: none;
    width: 50px;
    padding: 0 10px;
    text-align: right;
    font-size: 16px;

    &:focus-visible {
      outline: none;
    }
  }
}

.sum-n-btn {
  @include flexbox();
  @include flex-direction(column);
  @include align-items(flex-end);
}

.block-tab-sum {
  border-bottom: 1px solid #40505F;
  color: $textPrimary;
  margin-bottom: 30px;
  @include flexbox();
  @include align-items(flex-end);
  @include justify-content(flex-end);

  &__p {
    font-weight: 500;
    text-align: left;
    margin-bottom: 0;
  }

  &__sum {
    text-align: right;
    font-size: 16px;
    margin-bottom: 0;
    margin-left: 5px;
    min-width: 110px;
  }
}

.tab-content-castom .tab-pane {
  position: relative;
}

.service-total-block {
  display: none;
  margin-bottom: 0;
}

.services-publications {
  &-container {
    > div {
      @include flexbox();
      @include align-items(flex-start);
      @include justify-content(space-between);
    }
  }

  &-footer {
    font-size: 12px;
    color: $secondary;
    margin-bottom: 0;
  }
}

.publications-list {
  padding: 0;
  margin-bottom: 0;
  width: 776px;
}

.publication-item {
  padding: 20px 30px 15px 30px;
  border: 1px solid #D6D6D6;
  border-left-width: 4px;
  margin-bottom: 10px;
  @include flexbox();
  @include align-items(flex-start);
  @include justify-content(space-between);
}

.block-publications-price {
  @include align-items(flex-start);
  @include flex-direction(column);
  @include flexbox();

  &__count {
    font-weight: 500;
    margin-bottom: 10px;
    font-size: 16px;
  }

  &__price {
    line-height: 20px;
    margin-bottom: 0;
    background: #FFF3CD;
    font-size: 12px;
    padding: 0 10px;
    border-radius: 3px;
  }
}

.publication-total-price-discount {
  @include align-items(flex-start);
  @include flex-direction(column);
  @include flexbox();
  min-width: 150px;
}

.publication-total-price {
  margin-bottom: 10px;
  font-size: 16px;

  &-mob {
    display: none;
  }
}

.publication-discount {
  margin-bottom: 0;
  font-size: 12px;
  color: #28A745;

  &-mob {
    display: none;
  }
}

.not-authorized {
  &-block {
    @include align-items(flex-end);
    @include flex-direction(column);
    @include flexbox();

    .btn {
      line-height: 27px;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &-info {
    margin-top: 9px;
    margin-bottom: 0;
    font-size: 12px;
    color: $secondary;
  }
}

.jobPostingTab-footer {
  color: $secondary;
}

.publications-banner {
  width: 380px;

  &__img {
    width: 100%;
    height: auto;

    &__tab {
      display: none;
    }
  }

  &-tab {
    display: none;
    width: 100%;
    margin-bottom: 20px;
  }
}

$material-icons-font-path: '~material-icons/iconfont/';
@import '~material-icons/iconfont/material-icons.scss';
@media only screen and (max-width: 1199px) {
  .service-card {
    width: calc(33.3% - 12.75px);

    &:nth-child(4n + 4) {
      margin-right: 9.5px;
    }

    &:nth-child(3n + 3) {
      margin-right: 0;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .publications-banner {
    display: none;

    &__img {
      display: none;
    }

    &__img__tab,
    &-tab {
      display: inline-block;
    }
  }
  .publication-item {
    padding: 15px 20px;
    border-left: 1px solid #D6D6D6 !important;
  }
  .publication-total-price {
    font-size: 14px;
  }

  .publications-list {
    width: 100%;
  }
  .not-authorized {
    &-block {
      .btn {
        line-height: 25px;
        font-size: 13px;
      }
    }
  }
  .services-publications-container > div {
    @include flex-direction(column);
    @include align-items(center);
  }
}

.payment-block-title-btns {
  @include flexbox();
  @include align-items(center);
  @include justify-content(flex-start);
  margin-bottom: 12px;

  > p {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 0;
    margin-right: 30px;
    color: $textPrimary;
  }
}

.payment-method-block-services {
  .payment-systems {
    padding-left: 257px;
    padding-bottom: 20px;
  }
}

.total-block-services {
  .information {
    padding: 0 0 10px;

    span:nth-child(1) {
      text-align: left;
      width: 200px;
    }

    span:nth-child(2) {
      width: 95px;
      text-align: right;
    }

    &-last {
      padding: 10px 0;
      border-bottom: 1px solid $primary;
      border-top: 1px solid $primary;

      span {
        font-size: 16px;
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .services-table {
    &__col {
      font-size: 14px;
      padding: 12px 0;

      &__1 {
        width: 60.1%;
      }

      &__2 {
        width: 16.9%;
      }

      &__3 {
        width: 23%;
      }
    }

    &-item {
      &__col {
        &__1 {
          width: 60.1%;
          padding: 20px;
        }

        &__2 {
          width: 16.9%;
          font-size: 14px;
        }

        &__3 {
          width: 23%;
        }
      }
    }
  }
  .border-block {
    &:before {
      left: 60.1%;
    }

    &:after {
      right: 23%;
    }
  }
  .counter__field {
    font-size: 14px;
  }
  .services-payment-block {
    .payment-method-block-services {
      .nav-item {
        margin-right: 139px;
        min-width: 131px;

        &:nth-last-child(1) {
          margin-right: 45px;
        }
      }
    }
  }

  .block-services-subtitles {
    .services-content-subtitle__md {
      display: inline-block !important;
    }

    .services-content-subtitle__md.services-content-subtitle__hm,
    .services-content-subtitle__lg {
      display: none !important;
    }
  }
  .service-card {
    width: calc(43% - 10px);
    margin-right: 20px;

    &:nth-child(3n + 3),
    &:nth-child(4n + 4) {
      margin-right: 20px;
    }

    &:nth-child(2n + 2) {
      margin-right: 0;
    }
  }
}

@media only screen and (max-width: 767px) {
  .services-page .container {
    padding-right: 0;
    padding-left: 0;
    width: 100%;
  }
  .services-content {
    &-title {
      padding-left: 15px;
      padding-right: 15px;
      font-size: 18px;
    }

    &-subtitle {
      padding-left: 15px;
      padding-right: 15px;
      margin-bottom: 20px;
    }
  }
  .publications-banner-tab,
  .publications-banner,
  .border-block:before,
  .border-block:after,
  .services-table-head {
    display: none;
  }
  .nav-tabs-castom {
    border-bottom: none;
    position: relative;

    &:before {
      content: '';
      width: 100%;
      height: 1px;
      background-color: #008AEA;
      position: absolute;
      bottom: 1px;
      left: 0;
    }

    .nav-link {
      padding: 0 20px 11px;

      .active {
        margin-bottom: -2px;
      }
    }
  }
  .services-table {
    &-item {
      @include flex-direction(column);
      @include align-items(flex-start);
      border: none;
      border-bottom: 1px solid #D6D6D6;
      padding: 18px 16px 20px;

      &:nth-child(1) {
        border-top: 1px solid #D6D6D6;
      }

      &__col {
        padding: 0;
        width: 100%;
        text-align: left;

        &__2 {
          margin-bottom: 15px;
        }

        &__3 {
          @include flexbox();
          @include align-items(center);
          @include justify-content(space-between);
        }
      }

      &__subtitle {
        margin-bottom: 20px;
      }

      .services-item-mob {
        display: inline-block;
        font-weight: 500;
        text-transform: none;
      }
    }

    &-footer {
      @include flex-direction(column);
      @include align-items(flex-start);
      padding-left: 15px;
      padding-right: 15px;

      &__p {
        margin-bottom: 10px;
      }
    }
  }
  .counter {
    width: 110px;
    margin-left: 0;
  }
  .sum-n-btn {
    width: 100%;
  }
  .block-tab-sum {
    width: 100%;
    @include justify-content(space-between);
  }
  .service-total-block__show {
    display: inline-block;
  }
  .publication-discount-mob {
    display: inline-block;
    margin-top: 12px;
  }
  .publication-total-price-discount {
    display: none;
  }
  .publication-total-price-mob {
    display: inline-block;
    margin-bottom: 11px;
  }
  .publication-item {
    -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .30%);
    -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .30%);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .30%);
  }
  .nav-tabs-castom-services {
    margin-bottom: 10px;
    padding: 0 15px;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &:before {
      display: none;
    }

    .nav-link {
      border: 1px solid #70A1D6;
      border-radius: 4px;
      margin-right: 10px;
      color: #70A1D6;
      padding: 10px;
      @include flexbox();
      @include align-items(center);
      @include justify-content(space-between);

      &.active {
        border: 1px solid #008AEA;
        color: #008AEA;
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }

    &-fixed {
      position: fixed;
      top: 52px;
      left: 0;
      padding: 10px 15px !important;
      background: #fff;
      z-index: 1031;
      width: 100%;
      -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .30%);
      -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .30%);
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .30%);
    }

    .material-icons {
      display: inline-block;
      font-size: 16px;
      margin-right: 10px;
    }
  }
  .not-authorized {
    &-block {
      .btn {
        line-height: 23px;
        font-size: 12px;
      }
    }
  }
  .block-services-subtitles {
    .services-content-subtitle__sm,
    .services-content-subtitle__md {
      display: inline-block !important;
    }

    .services-content-subtitle__sm.services-content-subtitle__hm,
    .services-content-subtitle__md,
    .services-content-subtitle__lg {
      display: none !important;
    }
  }
  .payment-block-title-btns {
    @include flex-direction(column);
    @include align-items(flex-start);
  }
  .services-payment-block {
    .payment-method-block-services {
      .nav-tabs {
        @include justify-content(space-between);
        width: 100%;
        margin-top: 15px;
      }

      .nav-item {
        margin-right: 20px;

        &:nth-last-child(1) {
          margin-right: 0;
          min-width: 112px;
        }
      }
    }
  }
  .payment-method-block-services {
    .payment-systems {
      padding-left: 33px;
      padding-bottom: 30px;
      @include align-items(flex-start);
    }
  }
  .jobPostingTab-footer,
  .services-packages-p {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (orientation: portrait) {
  .service-card-content {
    padding-left: 0;

    .block-answer-success-fail {
      width: calc(100% - 317px);
    }
  }
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .service-card {
    width: 90%;
    max-width: 288px;
    margin-right: 0;
    margin-bottom: 10px;

    &:nth-child(3n + 3),
    &:nth-child(4n + 4) {
      margin-right: 0;
    }
  }
  .service-card-content {
    padding-left: 0;
    @include flex-direction(column);
    @include align-items(center);

    .block-answer-success-fail {
      width: 100%;
    }

    .service-card {
      margin-right: 0;
    }
  }
  .services-payment-block {
    label {
      margin-left: 30px;
      margin-right: 10px;
    }

    .block-add-new-contact button {
      margin: 10px;
    }

    .tariff-pro-price {
      padding: 10px;

      ul {
        width: 100%;
      }

      li {
        @include flex-direction(row);
      }
    }

    .tariff-name-price {
      @include flex-direction(column);
    }
  }
}

@media only screen and (max-height: 500px) and (orientation: landscape) {
  .service-card {
    width: calc(45% - 9.5px);
  }
  .service-card-content {
    padding-left: 0;

    .block-answer-success-fail {
      width: calc(100% - 317px);
    }
  }
  .tariff-pro-price {
    li {
      @include flex-direction(row);
    }
  }
}

@media only screen and (max-width: 500px) and (orientation: landscape) {
  .service-card-content {
    padding-left: 0;
    @include flex-direction(column);
    @include align-items(center);

    .block-answer-success-fail {
      width: 100%;
    }

    .service-card {
      margin-right: 0;
    }
  }
  .services-payment-block label {
    margin-right: 25px;
  }
}
