.сhange-tariff-block{
	@include flexbox();
	@include align-items(center);
	@include justify-content(center);
	margin-top: 40px;
	margin-bottom: 40px;
	&-btns {
		@include flexbox();
		@include align-items(center);
		@include justify-content(flex-end);
		margin-top: 30px;
		.btn-danger {
			margin-left: 20px;
		}
	}
	.service-card {
		margin: 0;
		width: 282px;
		padding: 25px 17px;
		&-name {
			text-align: center;
			width: 100%;
		}
		li p {
			line-height: 2;
		}
		.service-active{
			font-size: 16px;
			font-weight: 600;
			text-align: center;
			width: 100%;
			margin-bottom: 0;
		}
		.title-content {
			width: 100%;
			@include align-items(flex-start);
		}
	}
	.show-line:before {
		visibility: visible;
	}
}

.сhange-tariff-block-img{
	width: 180px;
	height: auto;
	margin: 0 65px;
}

@media only screen and (max-width: 768px) {
	.сhange-tariff-block {
		@include flex-direction(column);
		@include align-items(center);
		&-img {
			width: 108px;
			margin: 50px 75px;
			@include rotate(90);
		}
	}
}
