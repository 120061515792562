.cv-list-item{
	list-style-type: none;
	border: 1px solid #D6D6D6;
	padding: 12px 30px 13px 30px;
	margin-bottom: 10px;
	&-mob{
		display: none;
		width: 100%;
	}
	&-desctop{
		@include flexbox();
		@include align-items(flex-start);
		@include justify-content(space-between);
	}
	&-photo{
		width: 94px;
		height: 94px;
		border: 1px solid $UILightGray;
		position: relative;
		overflow: hidden;
		.fa-user {
			color: $UILightGray;
			font-size: 45px;
			position: absolute;
			left: 50%;
			top: 50%;
			@include translate(-50%, -50%);
		}
		img{
			max-height: 94px;
			width: auto;
			min-width: 100%;
			height: auto;
			position: absolute;
			left: 50%;
			top: 50%;
			@include translate(-50%, -50%);
		}
	}
	&-text {
		margin-bottom: 5px;
		font-size: 12px;
		color: $textPrimary;
	}
	.cv-description {
		width: calc(100% - 115px);
	}
	.vacancy-title {
		margin-bottom: 10px;
		line-height: 1;
	}
	.vacancies-item-date {
		font-size: 10px;
		color: $secondary;
		margin-bottom: 0;
	}
}

@media only screen and (max-width: 767px) {
	.cv-list-item {
		border-left: none;
		border-right: none;
		padding: 7px 16px 16px 18px;
		position: relative;
		&:before {
			content: '';
			height: calc(100% + 2px);
			width: 5px;
			position: absolute;
			left: -5px;
			top: -1px;
			border-top: 1px solid #D6D6D6;
			border-bottom: 1px solid #D6D6D6;
		}
		&:after {
			content: '';
			height: calc(100% + 2px);
			width: 5px;
			position: absolute;
			right: -5px;
			top: -1px;
			border-top: 1px solid #D6D6D6;
			border-bottom: 1px solid #D6D6D6;
		}
		&-mob {
			display: inline-block;
			.cv-description-img{
				@include flexbox();
				@include align-items(flex-end);
				@include justify-content(space-between);
			}
			.cv-description{
				width: calc(100% - 80px);
			}
		}
		&-desctop {
			display: none;
		}
		&-photo{
			width: 63px;
			height: 63px;
			.fa-user {
				font-size: 30px;
			}
			img{
				max-height: 63px;
			}
		}
		.vacancy-title {
			margin-bottom: 5px;
		}
		&-text {
			margin-bottom: 6px;
		}
	}
}