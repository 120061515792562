.response-row{
	@include flexbox();
	@include align-items(flex-start);
	margin-bottom: 10px;
	p:nth-child(1){
		min-width: 110px;
	}
	p{
		margin-bottom: 0;
	}
}

.response-page-contact-info{
	@include align-items(flex-start);
	@include justify-content(space-between);
	margin: 30px 0 20px;
	padding-bottom: 5px;
	border-bottom: 1px solid $UILightGray;
}

.response-add-file {
	margin: 30px 0 30px;
}

@media only screen and (max-width: 767px) {
	.response-page-contact-info {
		@include flex-direction(column-reverse);
		margin: 20px 0 15px;
		padding-bottom: 15px;
	}
	.response-row{
		@include flex-direction(column);
		margin-bottom: 0;
		p {
			margin-bottom: 3px;
		}
	}
	.response-add-file {
		margin: 15px 0 20px;
	}
}