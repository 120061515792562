<template>
	
	<div>
		<p class="subscriptions-list__title" v-if="agents.length > 0">
			{{ title }}
		</p>

		<div class="subscription-block" v-for="agent in agents">
			<label class="custom-checkbox custom-checkbox__sm" :for="agent.id">
				<input type="checkbox" 
					v-on:change="toggleStatus(agent.id, agent.token)"
					   class="custom-control-input" 
					   v-model="agent.isActive"
					   :id="agent.id"
				   >
				<i></i>
			</label>
			<div class="subscription-content">
				<p>
					<span v-for="sector in agent.sectors">
						{{ sector.trim() }}
					</span>

					<span v-if="agent.sectors.length > 0" class="divider"></span> 
					<span v-if="agent.salary.length > 0">{{ agent.salary }} &euro;</span> 
				</p>
				
				<p>
					<span v-for="location, key in agent.locations">
						{{ getLocation(location.trim()) }}<span v-if="key < (agent.locations.length - 1)">, </span>
					</span>
				</p>

				<p>
					<span v-for="language, key in agent.languages">
						{{ getLanguage(language.trim()) }}<span v-if="language.length > 0 && key < (agent.languages.length - 1)">, </span>
					</span>
				</p>
			</div>
			<button class="btn-vacancy-toggle" type="button" id="dropdownMenuButton"
					data-toggle="dropdown" aria-haspopup="true"
					aria-expanded="false">
				<div></div>
				<div></div>
				<div></div>
			</button>
			<div class="dropdown-menu dropdown-menu-right vacancy-list"
				 aria-labelledby="dropdownMenuButton">
				<!-- <a class="dropdown-item"
				   href="#">
					Preview.label
				</a> -->
				<a class="dropdown-item"
				   
				   @click="unsubscribe(agent.id, agent.token)"
				>
					{{ labelUnsubscribe }}
				</a>
				<a class="dropdown-item"
					@click="toggleStatus(agent.id, agent.token)"
			   	>
					<span v-if="agent.isActive">{{ labelDisable }}</span>
					<span v-if="agent.isActive === false">{{ labelEnable }}</span>
					<!-- <span>Disable</span> -->
				</a>
			</div>
		</div>
		<Pagination v-bind:pagination-data="pagination"
		            v-if="agents && pagination.pageCount > 1" />
	</div>
</template>

<script>
	import * as axios from 'axios';
	import NoData from "./SubscriptionsNoData"
	import Pagination from "../../pagination/Pagination";

	export default {

		name: 'List',
		components: {
			NoData,
			Pagination
		},
		
		props: {
			type: String,
			locations: Object,
			languages: Object,
			labelPreview: String,
			labelUnsubscribe: String,
			labelDisable: String,
			labelEnable: String,
			labelConfirm: String,
			title: String,
			subscriptionToken: String
		},

		data () {
			return {
				agents: [],
				pagination: [],
				page: 1,
				showSpinner: false,
			}
		},

		mounted() {

			this.$root.$on('paginate', (page) => {
				this.paginate(page);
			});

			this.showSpinner = true;
			this.getList();
		},

		methods: {
			getList: function() {
				let self = this;

				let queryParam = self.subscriptionToken ? ('/' + self.subscriptionToken) : '';

				axios.get('/api/subscriptions/' + this.type + '/' + this.page + queryParam)
					.then(function(response) {	
						self.setAgents(response.data.subscriptions);
						self.pagination = response.data.pagination;
						
						self.showSpinner = false;
						self.$root.$emit('onDataLoaded', self.type, response.data.subscriptions.length);
					})
			},

			setAgents(data) {
				let self = this;

				self.agents = [];

				data.forEach(function(value) {
					let meta = JSON.parse(value.meta);

					self.agents.push({
						'id' : value.id,
						'isActive': value.isActive,
						'token' : value.token,
						'locations': meta['locations'] ? meta['locations'].split(',') : '',
						'languages': meta['language'] ? meta['language'].split(',') : '',
						'sectors' : meta['sectors'] ? meta['sectors'].split(',') : '',
						'salary': meta['salary'] ? $meta['salary'] : ''
					})
				})
			},

			getLocation: function(code) {

				if(this.locations.length <= 0) {
					return code;
				}

				let locations = this.swapLocations();

				return locations[code] ?? code;
			},

			swapLocations: function() {
				let locations = this.locations;

				return Object.keys(locations).reduce((ret, key) => {
					ret[locations[key]] = key;

					return ret;
				}, {});
			},

			getLanguage: function(code) {
				if(this.languages.length <= 0) {
					return code;
				}

				return this.languages[code];
			},

			unsubscribe: function(id, token) {
				
				let self = this;

				axios.get('/api/subscription/' +id + '/unsubscribe/' + token)
					.then(function(response) {
						self.getList();

						self.$root.$emit('showToast', 'success', 'You have successfully unsubscribed');
					});
			},

			toggleStatus: function(id, token) {
				let self = this;

				axios.get('/api/subscription/' + id + '/change-status/' + token)
					.then(function(response) {
						self.getList();

						self.$root.$emit('showToast', 'success', 'Status updated successfully');
					});
			},
			paginate: function (page) {
				this.page = page;
				
				this.getList(page);
			},

		}
	}
</script>

<style lang="css" scoped>
	.animate {
		-webkit-animation: animation-button 1500ms infinite;
		-moz-animation: animation-button 1500ms infinite;
		-o-animation: animation-button 1500ms infinite;
		animation: animation-button 1500ms infinite;
	}
	
	@-webkit-keyframes animation-button {
		0%   {color: #ffffff; }
		50% { color: #EFD031; }
		100%   {color: #ffffff; }
	}
	@keyframes animation-button {
		0%   {color: #ffffff; }
		50% { color: #EFD031; }
		100%   {color: #ffffff; }
	}
</style>