.form-control,
.btn {
  border-radius: 0;
}

.text-info {
  color: $primary !important;
}

.border-info {
  border-color: #BDE4FF !important;
}

.form-control {
  padding-top: 0;
}

.form-control {
  border-color: $UILightGray;

  &::-webkit-input-placeholder {
    background-color: $textForDarkElements;
  }

  &::-moz-placeholder {
    background-color: $textForDarkElements;
  }

  &:-ms-input-placeholder {
    background-color: $textForDarkElements;
  }

  &:-moz-placeholder {
    background-color: $textForDarkElements;
  }
}

.block-dropdown {
  margin-bottom: 10px;

  &-title {
    @include flexbox();
    @include align-items(center);
    @include justify-content(space-between);
    cursor: pointer;

    .fas {
      color: #419DE4;
    }
  }

  .block-dropdown-title-icon {
    display: none;
  }

  &-content {
    display: none;
  }

  &.is-open {
    .block-dropdown-title .fas {
      @include transform(rotate(180deg));
    }

    .block-dropdown-content {
      display: block;
    }
  }
}

.block-dropdown-title {
  .block-dropdown-title-icon {
    display: inline-block;
  }
}

.form-block-dropdown-content {
  .fa-info-circle {
    font-size: 10px;
    color: $primary;
    margin-bottom: 10px;
    margin-left: 3px;
  }
}

.new-job-add-page {
  min-height: calc(100vh - 415px);

  .step-title {
    padding: 17px 20px;
    background-color: #DAF0FF;
  }

  .btn-primary {
    padding: 0.27rem 0.72rem;
  }

  .big-btn {
    padding: 0.375rem 0.72rem;
  }

  .was-validated .form-control:invalid, .form-control.is-invalid {
    background-image: none;
  }

  .was-validated .form-control:valid, .form-control.is-valid {
    background-image: none;
    border-color: $success;
  }

  .block-title-blue {
    font-size: 18px;
    font-weight: 600;
    color: $primary;
    margin-bottom: 0;
  }

  &-content {
    width: 100%;

    &-left {
      .choices__inner {
        background-color: $textForDarkElements;
        border-color: $UILightGray;
      }
    }
  }

  .vacancy-description-text {
    font-size: 10px;
  }

  .form-field-title {
    font-size: 14px;
    color: $textPrimary;
    font-weight: 500;
    margin-bottom: 0;
    line-height: 20px;

    &__mob {
      display: none;
    }

    span {
      font-weight: 400;
    }
  }

  .block-file-field .btn-add-files {
    height: 100%;
  }

  .img-list,
  .video-list {
    display: inline;
  }

  .loading-conditions {
    position: absolute;
    left: 0;
    top: 100%;
    font-size: 7px;
    padding-top: 10px;
    margin-bottom: 0;
  }

  .block-file-field {
    > div {
      margin-bottom: 60px;
    }
  }

  .rate {
    &-name {
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
      margin-top: 30px;
    }

    &-description {
      font-size: 14px;

      a {
        color: $primary;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .last-block {
    margin-bottom: 40px;
  }

  .file-max-size {
    font-size: 10px;
    margin-bottom: 0;
  }

  .help-text {
    font-size: 12px;
    margin-bottom: 0;
  }

  .block-content-filter .locations-select-container {
    .choices[data-type*="select-one"] .choices__inner {
      padding-left: 11px;
      padding-bottom: 4.5px;
    }

    .choices__list--single .choices__item {
      color: $textPrimary;
    }

    .choices[data-type*="select-one"]:after {
      content: "";
      background: url("/images/select-icon.svg") #fff no-repeat;
      background-size: 100% 100%;
      width: 9px;
      height: 11px;
      border: none;
      position: absolute;
      right: 7px;
      top: 50%;
      @include translate(0, -50%);
      margin-top: 0;
    }
  }

  .is-invalid-block {
    label {
      color: #DC3545 !important;
    }

    .invalid-feedback {
      display: inline-block;
    }

    .wrap-form-control,
    .choices__inner {
      border-color: #DC3545 !important;
    }

    .ql-toolbar {
      border-left-color: #DC3545;
      border-right-color: #DC3545;
      border-top-color: #DC3545;
    }

    .ql-container {
      border-left-color: #DC3545;
      border-right-color: #DC3545;
      border-bottom-color: #DC3545;
    }
  }

  .new-job-add-block-wrap {
    border: 1px solid $UILightGray;
    padding: 17px 20px 4px;
    margin-bottom: 20px;

    &__active {
      border: 1px solid $disabledSecondary;

      .additional-information-block-title,
      .btn-show-additional-information-block .fa-chevron-down {
        color: $textPrimary;
      }
    }

    &__new {
      padding: 27px 20px 30px;
    }

    &-title {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 20px;
      margin-top: 3px;
    }

    &-row {
      @include flexbox();
      @include align-items(flex-start);
      @include justify-content(space-between);

      &-left {
        width: 31%;
      }

      &-right {
        width: 68%;

        textarea {
          height: 85px;
          resize: none;
          margin-top: 0;
          padding-top: 7px;
          margin-top: 0 !important;
        }
      }

      .wrap-form-control {
        border: 1px solid $UILightGray;

        .form-control {
          border: none;
          width: calc(100% - 7px);
          color: $textPrimary;
          background: url("/images/select-icon.svg") no-repeat right 50% #fff;
          background-size: 9px 11px;
          -webkit-appearance: none;
          -moz-appearance: none;
          -ms-appearance: none;
          appearance: none;
        }
      }
    }
  }

  .offer-title {
    font-size: 24px;
    margin: 18px 0 20px;
  }

  .add-offer-desc {
    margin-bottom: 20px;
  }

  .additional-information-title {
    font-size: 20px;
    margin-bottom: 10px;
    color: $secondary;
    font-weight: 500;

    &__active {
      color: $textPrimary;
    }
  }

  .additional-information-block-title {
    font-size: 14px;
    color: $disabledSecondary;
    margin: 0;
  }

  .btn-show-additional-information-block {
    border: none;
    padding: 0;
    background: none;
    margin-bottom: 12px;

    .fa-chevron-down {
      font-size: 12px;
      color: $disabledSecondary;
    }
  }

  .btn-edit-contacts {
    color: $primary;
    margin-top: 15px;
    cursor: pointer;
    display: inline-block;

    &.btn-edit-contacts__is-edit {
      .btn-edit-contacts__on {
        display: none;
      }

      .btn-edit-contacts__off {
        display: inline-block;
      }
    }

    .btn-edit-contacts__off {
      display: none;
      border-bottom: 1px dotted;
    }

    .btn-edit-contacts__on {
      display: inline-block;
      border-bottom: 1px dotted;
    }
  }
}

.list-downloaded-files {
  @include flexbox();
  @include align-items(center);
  @include justify-content(flex-start);
  @include flex-wrap(wrap);

  .uploader {
    outline: 1px solid #B2D6EE;
    color: $primary;
    width: 80px;
    height: 80px;
    position: relative;
    cursor: pointer;
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
    margin-right: 20px;
    overflow: hidden;

    .fa-image {
      font-size: 26px;
      color: #B2D6EE;
    }

    &:hover {
      outline: 1px solid #69C1EE;
    }
  }

  .uploader input,
  .uploader img,
  .uploader .icon {
    display: none;
  }

  .uploader,
  .uploader .icon {
    -webkit-transition: all 100ms ease-in;
    -moz-transition: all 100ms ease-in;
    -ms-transition: all 100ms ease-in;
    -o-transition: all 100ms ease-in;
    transition: all 100ms ease-in;
  }

  .uploader .icon {
    color: #eee;
    color: rgba(0, 0, 0, 0.2);
    font-size: 5em;
  }

  .uploader.dragging {
    outline-color: orangered;
  }

  .uploader.dragging .icon {
    color: orangered;
  }

  .uploader.loaded {
    .icon {
      color: rgba(255, 255, 255, 0.5);
    }

    .fa-image {
      display: none;
    }

    .btn-remove-img {
      @include flexbox();
    }
  }

  .uploader img {
    opacity: 0;
    position: absolute;
    max-height: 80px;
    width: auto;
    height: auto;
    left: 50%;
    top: 50%;
    @include translate(-50%, -50%);
    z-index: -1;
  }

  .uploader img.loaded {
    opacity: 1;
    display: inline-block;
    z-index: 0;
  }

  .btn-remove-img {
    width: 19px;
    height: 19px;
    position: absolute;
    right: 0;
    top: 0;
    border: none;
    background-color: #5E5E5E;
    color: $textForDarkElements;
    font-size: 12px;
    @include align-items(center);
    @include justify-content(center);
    display: none;
  }
}

.block-downloaded-files p {
  margin-bottom: 0;
  color: $secondary;
}

.block-content-filter {
  @include flexbox();
  @include align-items(flex-start);
  @include justify-content(space-between);

  .left-part {
    width: calc(100% - 300px);

    .is-invalid-block {
      label {
        color: #DC3545;
      }

      .invalid-feedback {
        display: inline-block;
        font-size: 12px;
      }
    }

    .choices {
      margin-bottom: 0;
    }

    > p:nth-child(1) {
      margin-top: 0;
    }
  }
}

.files-add-btn-wrap {
  position: relative;
  display: inline-block;

  span.label {
    background: $textForDarkElements;
    border: 1px solid #B2D6EE;
    padding: 10px 0;
    color: $primary;
    width: 117px;
    @include flexbox();
    @include align-items(center);
    @include flex-direction(column);

    span {
      font-size: 50px;
      font-weight: 600;
    }
  }
}

.block-contacts-list {
  @include flexbox();
  @include flex-wrap(wrap);

  &-item {
    width: calc(50% - 15px);
    max-width: 372px;
    margin-right: 15px;
    margin-bottom: 20px;

    .form-control {
      padding-left: 40px;
      margin-top: 16px;
    }

    .form-group {
      position: relative;
      margin-bottom: 5px !important;

      i {
        position: absolute;
        top: 9px;
        left: 12px;
        color: #707070;
      }
    }
  }

  .block-communication-method {
    @include flexbox();
    @include align-items(flex-start);
    @include flex-direction(column);
    margin-bottom: 0 !important;

    .btn-communication-method {
      border: none;
      background-color: $disabledSecondary;
      color: $textForDarkElements;
      line-height: 30px;
      padding: 0 20px;
      cursor: pointer;
      @include flexbox();
      @include align-items(center);
      margin-bottom: 0;

      &.active {
        background-color: #00B955;

        img {
          display: block;
        }
      }

      img {
        margin-left: 20px;
        display: none;
        width: 13px;
        height: auto;
      }
    }

    > div {
      width: 100%;
      @include flexbox();
      @include align-items(center);
      @include justify-content(space-between);
    }
  }

  .rotate {
    @include rotate(90);
  }

  .btn-del {
    margin-bottom: 0;
    color: $primary;
    cursor: pointer;
  }

  .btn-del-contact {
    margin-bottom: 0;
    color: $primary;
    cursor: pointer;
  }
}

.block-add-new-contact {
  width: 100%;
  text-align: right;
  margin-bottom: 30px;

  button {
    margin-left: 30px;
  }
}

.new-job-add-page {
  .block-add-new-contact {
    width: calc(100% - 300px);
  }
}

.btn-gray {
  background-color: #C3C3C3;
  border-color: #C3C3C3;
  color: $textForDarkElements;
}

.tariff-pro-price {
  padding-top: 20px;

  &-title {
    font-weight: 600;
    margin-bottom: 10px;
  }

  ul {
    padding: 0;
  }

  li {
    @include flexbox();
    @include align-items(center);
    @include justify-content(space-between);

    p {
      margin-bottom: 0;
    }

    > p {
      width: 65%;
    }

    div {
      width: 29%;
      @include flexbox();
      @include align-items(center);
      @include justify-content(space-between);

      > p:nth-last-child(1) {
        text-align: right;
        min-width: 35%;
      }
    }
  }

  .amount-to-paid {
    @include flexbox();
    @include align-items(center);
    @include justify-content(flex-end);

    p {
      font-weight: 600;
      padding-left: 30px;
    }
  }
}

.payment-method {
  &-title {
    font-weight: 600;
    margin-bottom: 25px;
  }

  &-list {
    padding: 0;

    li {
      margin-bottom: 10px;
    }

    label {
      margin-left: 13px;
    }
  }

  &-content {
    margin: 10px 0 20px;
    @include align-items(center);
    @include justify-content(space-between);
    display: none;
  }

  &-active {
    .payment-method-content {
      @include flexbox();
    }
  }
}

.payment-systems {
  @include flexbox();
  @include align-items(center);

  div {
    @include flexbox();
    @include align-items(center);
  }

  img {
    height: 16px;
    width: auto;
    margin-right: 5px;
  }

  p {
    margin-bottom: 0;
  }
}

.form-invoice {
  margin-top: 20px;
  width: 100%;

  > div {
    width: 100%;
  }

  .block-form-invoice-fields {
    @include flexbox();
    @include align-items(flex-start);
    @include justify-content(space-between);

    > div {
      width: calc(50% - 15px);

      > p {
        color: $secondary;
      }
    }
  }
}

.field-add-file {
  width: 100%;

  p {
    font-size: 10px;
    margin-bottom: 0;
  }

  .btn-delete-file {
    width: 40px;
    height: 33px;
    border: 1px solid $primary;
    margin-left: 16px;
    background: none;

    i {
      color: $primary;
    }
  }

  > div {
    @include flexbox();
    @include align-items(center);
  }
}

.fileUpload {
  position: relative;
  overflow: hidden;
}

.fileUpload input.upload {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}

.btn--browse {
  color: black;
  height: 33px;
  width: 40px;
  background: url(/images/upload-file.svg) center center $primary no-repeat;
  background-size: 13px;
}

.f-input {
  height: 33px;
  border: 1px solid $UILightGray;
  width: calc(100% - 54px);
  float: left;
  padding: 0 14px;
  margin-right: 0;

  &:disabled {
    background-color: $textForDarkElements;
  }
}

.phone-field,
.email-field {
  display: none;
}

.show-field-phone {
  .phone-field {
    display: block;
  }
}

.show-field-email {
  .email-field {
    display: block;
  }
}

.castome-radio-btn {
  margin-top: 0.2rem;
}

.castome-radio-btn:after {
  content: '';
  width: 20px;
  height: 20px;
  border-radius: 50%;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #F0F0F0;
  display: inline-block;
  visibility: visible;
  border: 1px solid #D5D5D5;
}

.castome-radio-btn:checked:before {
  content: '';
  width: 20px;
  height: 20px;
  border-radius: 50%;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #F0F0F0;
  display: inline-block;
  visibility: visible;
  border: 1px solid #D5D5D5;
}

.castome-radio-btn:checked:after {
  content: '';
  width: 12px;
  height: 12px;
  border-radius: 50%;
  top: 2px;
  left: 3px;
  position: absolute;
  background-color: $primary;
  display: inline-block;
  visibility: visible;
  border: none;
  z-index: 1;
}

.tooltips {
  position: relative;

  .tooltiptext {
    width: 90vw;
    color: #000;
    text-align: center;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    @include translate(-50%, 0);
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 10px;
  }

  &:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
}

.block-add-contact {
  text-align: left;

  .btn {
    margin-left: 0;
  }
}

.wrap-for-form-field {
  @include flexbox();
  @include align-items(center);
  border: 1px solid $UILightGray;

  input {
    border: none;
    padding-left: 0;
    line-height: 3;
  }

  i {
    color: #868686;
  }

  div {
    width: 30px;
    height: 100%;
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
  }
}

.tariff-notice-block {
  background-color: #F3FAFF;
  padding: 18px 20px 18px 25px;
  @include flexbox();
  @include align-items(center);
  @include justify-content(space-between);
  width: calc(100% - 300px);

  &-text {
    @include flexbox();
    @include align-items(center);

    i {
      color: $yellowColorButtonHeader;
      font-size: 19px;
      margin-right: 20px;
    }
  }
}

.list-applied-services {
  padding-left: 33%;

  .custom-checkbox .custom-control-label {
    &::before {
      border-radius: 0;
      width: 20px;
      height: 20px;
      left: -30px;
      top: 0;
    }

    &::after {
      width: 20px;
      height: 20px;
      left: -30px;
      top: 0;
    }
  }

  .custom-control {
    margin-bottom: 10px;
    padding-left: 27px;

    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }

  .custom-control-label,
  p {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
  }
}

.service-ischecked-block {
  &:nth-last-child(1) {
    margin-bottom: 0;
  }

  .fa-check-circle {
    font-size: 18px;
    color: #28A745;
    margin-right: 9px;
  }
}

@media only screen and (max-width: 1199px) {
  .block-contacts-list {
    &-item {
      width: calc(50% - 5px);
      margin-right: 5px;
      margin-bottom: 30px;

      &:nth-child(2n + 2) {
        margin-left: 5px;
      }
    }

    .block-communication-method .btn-communication-method {
      font-size: 11px;
      line-height: 28px;

      img {
        margin-left: 10px;
      }
    }
  }
  .tariff-pro-price li {
    > p {
      width: 65%;
    }

    div {
      width: 47%;
    }
  }
  .payment-method-content {
    @include align-items(flex-start);
    @include flex-direction(column);
  }
  .form-invoice {
    .block-form-invoice-fields {
      > div {
        width: calc(50% - 5px);
      }

      .form-control {
        font-size: 12px;
      }
    }
  }
  .indent-mob {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 1024px) {
  .new-job-add-page .block-add-new-contact,
  .block-content-filter .left-part {
    width: calc(100% - 250px);
  }
  .new-job-add-sitebar {
    width: 240px;
  }
}

@media only screen and (max-width: 991px) {
  .block-content-filter {
    .left-part {
      width: calc(100% - 250px);
    }

    .left-part {
      width: 100%;
    }

    #manage_form_title_help {
      display: none;
    }
  }
  .new-job-add-page {
    .new-job-add-block-wrap {
      &__new {
        padding: 17px 20px 20px;
      }

      &-row {
        @include flex-direction(column);

        &-left,
        &-right {
          width: 100%;
        }

        &__flex {
          @include flex-direction(row);

          &-left {
            width: 31%;
          }

          &-right {
            width: 68%;
          }
        }
      }
    }

    .on-mob-text-normal {
      font-weight: 400;
      margin-bottom: 1rem;
    }

    .font-weight-normal {
      font-weight: 400 !important;
    }

    .offer-title {
      margin: 6px 0 20px;
    }
  }
  .tariff-notice-block {
    margin: 0;
    padding: 18px 12px;
    width: 100%;

    &-text {
      width: 100%;
    }
  }
  .block-add-new-contact {
    width: calc(100% - 250px);
  }
  .list-applied-services {
    padding-left: 0;

    .custom-checkbox .custom-control-label {
      &::before {
        left: -27px;
      }

      &::after {
        left: -27px;
      }
    }
  }
}

@media only screen and (max-width: 850px) {
  .block-content-filter {
    padding-top: 0;
    @include flex-direction(column);

    .left-part {
      width: 100%;
    }

    .professions-autocomplete .form-text {
      margin: 0;
    }
  }
  .new-job-add-page {
    .container {
      position: relative;
    }

    h2 {
      font-size: 18px;
      margin-bottom: 0;
    }

    &-content {
      .form-text-mob {
        margin: 0 0 5px;
      }
    }

    .form-field-title {
      margin-bottom: 7px !important;
    }

    .btn--browse,
    .f-input {
      height: 35px;
    }

    .btn-primary {
      padding: 0 20px;
      line-height: 35px;
      margin: 0;
    }

    .block-add-new-contact {
      width: 100%;
    }
  }
  .new-job-add-filter textarea {
    padding-top: 1px;
  }
  .field-add-file {
    p {
      font-size: 12px;
    }
  }
  .block-add-new-contact,
  .list-downloaded-files {
    margin-bottom: 0;
  }
  .block-add-contact {
    margin-bottom: 18px;
  }
  .block-contacts-list-item {
    .form-control {
      margin-top: 0;
    }

    .form-group i {
      top: 12px;
      left: 14px;
      font-size: 12px;
    }
  }
  .block-contacts-list .block-communication-method {
    margin-top: 15px;

    > p {
      display: none;
    }

    > div {
      @include align-items(flex-start);
      @include flex-direction(column);
    }

    .btn-communication-method {
      font-size: 14px;
      line-height: 30px;
    }

    .form-group {
      margin-bottom: 20px !important;
    }
  }
  .show-field-email .email-field,
  .show-field-phone .phone-field {
    margin-bottom: 20px !important;
  }
}

@media only screen and (max-width: 767px) {
  .step-title {
    padding: 11px 14px;
  }
  .new-job-add-page {
    .block-title-blue {
      font-size: 12px;
    }

    .offer-title {
      margin: 0 0 20px;
      font-size: 18px;
    }

    .add-offer-desc {
      margin-bottom: 20px;
    }

    .new-job-add-block-wrap {
      border: none;
      padding: 0;
      margin-bottom: 0;

      &__new {
        padding: 0 0 16px;
      }

      &-title {
        //font-size: 16px;
        //margin-bottom: 10px;
        //margin-top: 0;
        display: none;
      }

      &-row__flex {
        padding: 14px 10px 2px;
        border: 1px solid $UILightGray;
        margin-bottom: 10px;

        .new-job-add-block-wrap-row-left {
          width: 80%;
        }

        .new-job-add-block-wrap-row-right {
          width: 20%;
        }
      }
    }

    .btn-save-mob {
      margin: 20px 0 0;
    }

    .for-line-mob {
      padding-bottom: 20px;
      position: relative;

      &:before {
        content: '';
        height: 1px;
        width: calc(100% + 30px);
        background-color: $UILightGray;
        position: absolute;
        left: -15px;
        bottom: 0;
      }
    }

    .additional-information-title {
      font-size: 16px;
      margin-top: 20px;
      font-weight: 400;
    }

    .knowing-languages-mob {
      margin-bottom: 20px;
    }

    .uploads-gallery-block {
      margin-bottom: 8px !important;
    }
    .form-field-title__mob {
      display: inline-block;
    }
  }
  .list-downloaded-files .uploader {
    width: 94px;
    height: 94px;
  }
  .block-contacts-list {
    @include flex-direction(column);

    &-item {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
  .rate-card-list,
  .form-invoice .block-form-invoice-fields,
  .payment-systems,
  .tariff-pro-price li {
    @include flex-direction(column);
  }
  .rate-card-small,
  .rate-card {
    width: 100%;
    max-width: 345px;
    margin-bottom: 20px;
  }
  .form-invoice .block-form-invoice-fields > div {
    width: 100%;
  }
  .tariff-pro-price li div,
  .tariff-pro-price li > p {
    width: 100%;
  }
  .block-add-new-contact {
    button {
      margin: auto 10px;
    }
  }
  .block-file-field {
    margin-bottom: 80px;
  }
  .payment-systems div {
    margin-left: 0;
    @include align-items(flex-start);
  }
  .block-site-video-links {
    @include flex-direction(column);
  }
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .tariff-notice-block {
    @include flex-direction(column);
    @include align-items(flex-end);

    &-text {
      margin-bottom: 15px;
    }
  }
}
