.home-employers-section {
  padding: 50px 0;
  position: relative;
  border-bottom: 1px solid #D7C253;

  &:nth-child(1) {
    border-bottom: none;
  }

  &__bg {
    width: 44%;
    position: absolute;
    right: 5%;
    top: -92px;
    z-index: -1;
  }

  &__text {
    font-size: 15px;
    margin-bottom: 0;
    position: relative;
    z-index: 1;
    color: $secondary;
  }

  &__first {
    margin-top: -95px;
    padding-top: 70px;
    position: relative;
    border-bottom: 1px solid #D7C253;

    &-container {
      width: 60%;
    }

    .home-employers-section__title {
      margin-bottom: 20px;
    }
  }

  &__look-employees {
    .container {
      @include flexbox();
      @include align-items(center);
      @include justify-content(center);

      > div {
        &:nth-child(1) {
          width: 56%;
          margin-right: 4%;
        }

        &:nth-last-child(1) {
          width: 44%;
          position: relative;
          text-align: center;

          &:before {
            content: '';
            width: 231px;
            height: 483px;
            background: radial-gradient(35% 43.08% at 50.01% 50.01%, rgba(255, 240, 230, 0.72) 16.59%, rgba(214, 255, 162, 0.3) 53.05%, rgba(255, 255, 255, 0) 100%);
            transform: rotate(90.83deg);
            position: absolute;
            left: 149px;
            top: -229px;
          }
        }
      }

      .btn-warning {
        color: $textPrimary;
        background-color: #FFD84F;
        border-color: #FFD84F;
        position: relative;

        &:hover {
          color: $textPrimary;
          background-color: #F3CD47;
          border-color: #F3CD47;
        }
      }
    }
  }

  &__form {
    padding-bottom: 0;
    border-bottom: none;

    &:before {
      display: none;
    }

    .container {
      position: relative;
      background: #FBF9F3;
      padding-top: 40px;
      padding-bottom: 45px;

      &:before {
        content: '';
        height: 100%;
        width: 25px;
        background: #FBF9F3;
        position: absolute;
        top: 0;
        left: -25px;
      }

      &:after {
        content: '';
        height: 100%;
        width: 25px;
        background: #FBF9F3;
        position: absolute;
        top: 0;
        right: -25px;
      }
    }

    .home-employers-section__title {
      width: 58%;
      margin-bottom: 34px;
    }
  }

  &__trust-us {
    padding: 40px 0;

    .container {
      position: relative;
      background: #FBF9F3;
      padding-top: 40px;
      padding-bottom: 85px;

      &:before {
        content: '';
        height: 100%;
        width: 25px;
        background: #FBF9F3;
        position: absolute;
        top: 0;
        left: -25px;
      }

      &:after {
        content: '';
        height: 100%;
        width: 25px;
        background: #FBF9F3;
        position: absolute;
        top: 0;
        right: -25px;
      }
    }
  }

  &__title {
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 0;
    position: relative;
    z-index: 1;

    &__big {
      font-size: 40px;
      font-weight: 500;
      margin-bottom: 30px;
    }
  }

  &__sub-title {
    margin-bottom: 60px;
    color: $textPrimary;
  }
}

.our-advantages {
  @include flexbox();
  @include align-items(flex-start);
  @include justify-content(space-between);
  padding: 0;
  margin-bottom: 75px;
  width: 100%;

  &-item {
    @include flexbox();
    @include align-items(baseline);
    @include justify-content(flex-start);
    width: 28%;

    .fa-check {
      font-size: 14px;
      color: #02B538;
      margin-right: 11px;
    }

    &__p {
      width: 87%;
      margin: 0;
    }
  }
}

.why-workmarket {
  &-list {
    @include flexbox();
    @include align-items(stretch);
    @include justify-content(space-between);
    @include flex-wrap(wrap);
    margin-top: 20px;
    margin-bottom: 30px;
    position: relative;

    &__bg {
      width: 75vw;
      position: absolute;
      left: 55%;
      top: 50%;
      @include translate(-50%, -50%);
      z-index: -1;

      &__mob {
        display: none;
      }

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  &-item {
    width: calc(50% - 10px);
    padding: 20px 30px;
    border: 0.5px solid #E0E0E0;
    margin-bottom: 20px;
    background-color: #fff;
    position: relative;
    z-index: 1;

    &__img {
      @include flexbox();
      @include align-items(center);
      @include justify-content(center);
      width: 62px;
      height: 62px;
      background: url("/images/home-employers/img-wrap.png") center center no-repeat;
      background-size: contain;
      margin-bottom: 10px;

      .material-icons,
      .material-icons-outlined {
        font-size: 26px;
        color: #F4D69B;
      }
    }

    &__title {
      font-weight: 500;
      font-size: 18px;
      line-height: 111%;
      margin-bottom: 10px;
      min-height: 41px;
    }

    &__subtitle {
      font-size: 15px;
      line-height: 167%;
      color: $secondary;
      margin-bottom: 0;
    }
  }
}

.our-services {
  @include flexbox();
  @include align-items(stretch);
  @include justify-content(flex-start);
  @include flex-wrap(wrap);
  margin-top: 20px;

  &-item {
    width: 50%;
    padding: 25px 40px 50px 30px;
    border-bottom: 1px solid #D7C253;

    &:nth-child(2n+2) {
      border-left: 1px solid #D7C253;
    }

    &:nth-last-child(1),
    &:nth-last-child(2) {
      border-bottom: none;
    }

    &__img {
      height: 111px;
      margin-bottom: 22px;
    }

    &__title {
      font-weight: 500;
      font-size: 18px;
      margin-bottom: 15px;
    }

    &__text {
      font-size: 15px;
      line-height: 167%;
      color: $secondary;
      margin-bottom: 0;
    }
  }
}

.form-n-desc {
  @include flexbox();
  @include align-items(stretch);
  @include justify-content(center);

  > div {
    width: 50%;

    &:nth-child(1) {
      border-right: 1px solid #D7C253;
      padding-right: 25px;
    }

    &:nth-child(2) {
      padding-left: 40px;
    }
  }

  .home-employers-section__text {
    margin-bottom: 20px;
    font-size: 14px;
  }
}

.form-desc {
  padding: 0;

  &__item {
    @include flexbox();
    @include align-items(baseline);
    @include justify-content(flex-start);
    margin-bottom: 10px;

    .fa-check {
      font-size: 14px;
      color: #02B538;
      margin-right: 23px;
    }

    p {
      margin-bottom: 0;
      color: $secondary;
    }
  }
}

.home-employer-form {
  &__row {
    @include flexbox();
    @include align-items(baseline);
    @include justify-content(space-between);
    margin-bottom: 25px;

    .form-control {
      border: none;
      border-bottom: 1px solid #D7C253;
      width: 63%;
      height: 25px;
      background: none;
      padding-left: 0;
      cursor: pointer;

      &:hover {
        border-color: #a28908;
      }
    }

    .form-reg-field {
      border: none !important;
      border-bottom: 1px solid #D7C253 !important;
      width: 63%;
      cursor: pointer;

      &:hover {
        border-color: #a28908 !important;
      }
    }

    .input-group input {
      height: 25px;
      padding-left: 0;

      &:focus {
        background: none;
        box-shadow: none;
      }
    }

    label {
      margin-bottom: 0;
      line-height: 1;
    }

    .label-wrap {
      line-height: 1;
    }

    .block-hint {
      width: 63%;
      .form-control {
        width: 100%;
      }
      p {
        font-size: 12px;
        margin-bottom: 0;
      }
    }
  }

  &-mob {
    display: none;
  }

  .btn-warning {
    color: $textPrimary;
    background-color: #FFD84F;
    border-color: #FFD84F;
    position: relative;
    margin-top: 15px;

    &:hover {
      color: $textPrimary;
      background-color: #F3CD47;
      border-color: #F3CD47;
    }
  }

  .btn:focus, .btn.focus {
    box-shadow: none;
  }
}

@media only screen and (max-width: 1199px) {
  .home-employers-section {
    padding: 30px 0 47px;

    &__first {
      padding-top: 30px;

      &-container {
        width: 100%;
      }

      .home-employers-section__title {
        margin-bottom: 10px;
      }
    }

    &__look-employees {
      padding: 30px 0;
      position: relative;

      .container {
        @include flex-direction(column);

        > div {
          &:nth-child(1) {
            width: 100%;
            margin: 0 0 20px;
          }

          &:nth-last-child(1) {
            width: 100%;
            text-align: right;
            position: static;

            &:before {
              content: '';
              width: 483px;
              height: 208px;
              background: radial-gradient(35% 43.08% at 50.01% 50.01%, rgba(255, 240, 230, 0.72) 16.59%, rgba(214, 255, 162, 0.3) 53.05%, rgba(255, 255, 255, 0) 100%);
              transform: rotate(0);
              background-size: 90% 90%;
              left: auto;
              top: auto;
              right: 0;
              bottom: -69px;
              background-position-x: 281%;
              background-repeat: no-repeat;
            }
          }
        }

      }

      .home-employers-section__title {
        margin-bottom: 10px;
      }
    }

    &__form {
      padding: 30px 0 0;

      .container {
        padding-top: 35px;
        padding-bottom: 35px;

        &:before {
          width: 20px;
          left: -20px;
        }

        &:after {
          width: 20px;
          right: -20px;
        }
      }

      .home-employers-section__title {
        width: 100%;
        margin-bottom: 30px;
        line-height: 146%;
      }
    }

    &__bg {
      width: 47%;
      right: 0;
      top: -6px;
    }

    &__title {
      line-height: 167%;
      font-size: 24px;

      &__big {
        width: 63%;
        font-size: 24px;
        line-height: 167%;
        margin-bottom: 10px;
      }
    }

    &__text {
      font-size: 14px;
      line-height: 143%;
    }

    &__sub-title {
      width: 63%;
      margin-bottom: 20px;
    }
  }

  .our-advantages {
    @include align-items(flex-start);
    @include flex-direction(column);
    margin-bottom: 48px;
    width: 292px;

    &-item {
      width: 100%;
      margin-bottom: 20px;

      &__p {
        &:nth-child(1) {
          width: 53%;
        }

        &:nth-child(2) {
          width: 73%;
        }

        &:nth-child(3) {
          width: 91%;
        }
      }
    }
  }

  .why-workmarket {
    &-list {
      margin-top: 18px;
      margin-bottom: 12px;

      &__bg {
        width: 57vw;
        z-index: -1;
      }
    }

    &-item {
      padding: 14px;
      margin-bottom: 10px;

      &__img {
        width: 31px;
        height: 31px;
        margin-bottom: 5px;

        .material-icons,
        .material-icons-outlined {
          font-size: 14px;
        }
      }

      &__title {
        font-size: 15px;
        line-height: 133%;
        min-height: 58px;
        min-height: auto;
      }

      &__subtitle {
        font-size: 14px;
        line-height: 143%;
      }
    }
  }

  .our-services-item {
    padding: 25px 25px 40px;

    &__img {
      height: 70px;
      margin-bottom: 20px;
    }

    &__title {
      font-size: 15px;
      line-height: 167%;
      margin-bottom: 10px;
    }

    &text {
      line-height: 133%;
    }
  }
  .form-n-desc {
    @include flex-direction(column);

    > div {
      width: 100%;

      &:nth-child(1) {
        border-right: none;
        padding-right: 0;
      }

      &:nth-child(2) {
        padding: 0 6%;
      }
    }
  }
  .home-employer-form__row {
    label {
      font-size: 13px;
      font-weight: 400;
    }

    .block-hint,
    .form-control,
    .form-reg-field,
    .form-control {
      width: 70%;
    }
  }
}

@media only screen and (max-width: 992px) {
  .home-employers-section {
    &__bg {
      width: 52%;
      top: 73px;
    }

    &__first {
      padding-top: 66px;
    }
  }

  .why-workmarket {
    &-list {
      &__bg {
        width: 83vw;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .home-employers-section {
    padding: 30px 0 67px;

    &__first {
      margin-top: -50px;
      padding-top: 0;
      padding-bottom: 105px;

      &-container {
        .home-employers-section__text {
          padding-right: 15px;
          padding-left: 15px;
          margin-bottom: 27px;
        }
      }

      .container {
        padding-right: 0;
        padding-left: 0;
        width: 100%;
        position: relative;
      }

      .home-employers-section__title {
        margin-bottom: 15px;
        padding-right: 15px;
        padding-left: 15px;
      }

    }

    &__form {
      .container {
        padding-top: 16px;
        padding-bottom: 30px;

        &:before,
        &:after {
          display: none;
        }
      }
    }

    &__look-employees {
      padding-top: 30px;
      padding-bottom: 30px;

      .home-employers-section__title {
        line-height: 139%;
      }

      .container > div:nth-last-child(1) {
        text-align: center;
      }
    }

    &__trust-us {
      padding: 30px 0;

      .container {
        padding: 20px 0 93px;
        width: 100%;

        &:before,
        &:after {
          display: none;
        }
      }

      .home-employers-section__title {
        line-height: 139%;
        padding: 0 15px;
      }
    }

    &__bg {
      width: 100vw;
      position: relative;
      top: 0;
    }

    &__title {
      line-height: 222%;
      font-size: 18px;

      &__big {
        width: 100%;
        margin-top: 20px;
        font-size: 18px;
        line-height: 222%;
        text-align: center;
        letter-spacing: -0.022em;
      }
    }

    &__sub-title {
      width: 100%;
      margin-bottom: 30px;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .our-advantages {
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 10px;
    margin-bottom: 25px;
    width: 100%;
    -webkit-box-shadow: 0 7px 14px 0 rgba(0, 0, 0, 0.04);
    -moz-box-shadow: 0 7px 14px 0 rgba(0, 0, 0, 0.04);
    box-shadow: 0 7px 14px 0 rgba(0, 0, 0, 0.04);
    z-index: 1;
    position: relative;

    &-item__p:nth-child(2) {
      width: 100%;
    }
  }
  .why-workmarket-list,
  .our-services {
    display: none;
  }
  .form-n-desc > div:nth-child(2) {
    padding: 0;
  }
  .home-employer-form {
    display: none;

    &__row {
      @include align-items(flex-start);
      @include flex-direction(column);
      position: relative;

      label {
        font-size: 14px;
        position: absolute;
        pointer-events: none;
        left: 0;
        top: -9px;
        white-space: nowrap;
        overflow: hidden;
        line-height: 16px;
        transition: .3s;
        font-weight: 400;
        margin-bottom: 0;
      }

      .form-reg-field {
        width: 100%;
      }

      .form-control {
        width: 100%;
        padding: 0;
        border: none;
        border-bottom: 1px solid #EAEAEA;
        font-size: 14px;
        line-height: 26px;

        &:focus {
          outline: none;
        }

        &:focus ~ .email-us-form__label,
        &:not(:focus):valid ~ .email-us-form__label {
          top: -15px;
          font-size: 12px;
        }
      }

      .btn-warning {
        margin-top: 5px;
      }
    }

    &-mob {
      display: inline-block;

      .home-employer-form__row {
        margin-bottom: 0;
      }

      .email-us-form__item {
        margin-bottom: 25px;
      }

      .email-us-form__input {
        width: 100%;
        border-bottom: 1px solid #D7C253;
        font-size: 14px;
        background: inherit;
      }

      .email-us-form__label {
        top: 8px;
        line-height: 14px;
        font-size: 14px;
      }

      .input-group-append {
        position: absolute;
        right: 0;
        bottom: 15px;
      }

      .field-password-wrap .input-group .input-group-append .btn .fa-eye {
        font-size: 18px;
      }
    }
  }
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .why-workmarket-list__bg__mob {
    display: inline-block;
    width: 100vw;
    height: auto;
    position: absolute;
    left: 0;
    bottom: -107px;
  }
}
