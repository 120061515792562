.block-read-more {
  .read-more {
    color: #008AEA;
    cursor: pointer;
  }
  .read-more__close {
    display: none;
  }
  &.block-read-more__close {
    .read-more__open {
      display: none;
    }
    .read-more__close {
      display: inline-block;
    }
  }
}
