.block-already-advertise {
  padding: 55px 0 0;
  h2 {
    text-align: center;
    margin-bottom: 30px;
  }
  ul {
    margin-bottom: 0;
    padding: 0;
    @include flexbox();
    @include align-items(center);
    @include justify-content(space-between);
    @include flex-wrap(wrap);
  }
  li {
    list-style-type: none;
    width: 12.5%;
    padding: 0 20px;
    margin-bottom: 10px;
    text-align: center;
    img {
      width: auto;
      max-width: 100%;
      height: auto;
      margin: auto;
    }
  }
}

@media only screen and (max-width: 1199px) {
  .block-already-advertise {
    h2 {
      margin-bottom: 15px;
    }
    ul {
      @include justify-content(center);
    }
    li {
      width: 20%;
      padding: 0 10px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .block-already-advertise {
    padding: 30px 0 0;
  }
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .block-already-advertise {
    li {
      width: 33%;
      padding: 0 5px;
    }
  }
}