.logos-slider {
  margin-top: 30px;

  .logo-slide {
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
    width: 177px;
    height: 177px;
    background: #fff;
    margin: auto;

    &__img {
      width: 120px;
      height: auto;
      max-height: 120px;
    }
  }

  .slick-prev {
    top: auto;
    bottom: -89px;
    left: calc(50% - 60px);
  }

  .slick-next {
    top: auto;
    bottom: -68px;
    right: calc(50% - 60px);
  }
}

.why-workmarket-slider,
.our-services-slider {
  display: none;
}

.slider-employer-home {
  .slick-dots {
    bottom: -35px;
    left: 50%;
    @include translate(-50%, 0);
    width: auto;
  }

  .slick-dots li.slick-active button {
    position: relative;
  }

  .slick-dots li.slick-active button:before {
    background: $secondary;
  }

  .slick-dots li button::before {
    content: '';
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: $UILightGray;
    opacity: 1;
  }
}

.slick-arrow {
  width: 45px;
  height: 43px;
  position: absolute;
  z-index: 1;
}

.slick-prev {
  left: 44px;
}

.slick-next {
  right: 44px;
  @include rotate(180);
}

.img-wrap {
  width: 100%;

  img {
    width: 100%;
    height: auto;
  }
}

@media only screen and (max-width: 1199px) {
  .logos-slider {
    margin-top: 10px;

    .logo-slide {
      width: 140px;
      height: 140px;

      &__img {
        width: 90px;
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .logos-slider {
    .logo-slide {
      width: 92px;
      height: 92px;

      &__img {
        width: 62px;
      }
    }
  }
}


@media only screen and (max-width: 767px) {
  .our-services {
    &-slider {
      width: 100%;
      display: inline-block;
      margin-top: 15px;

      .slick-dots {
        bottom: -45px;
      }

      .slick-arrow {
        top: auto;
        bottom: -80px;
        display: inline-block;
      }

      .slick-next {
        bottom: -58px;
      }
    }

    &-item {
      width: 100%;
      min-height: 408px;
      padding: 11px 14px;
      border: 0.5px solid #E0E0E0 !important;
      -webkit-box-shadow: 0 5px 5px -5px rgba(34, 60, 80, 0.6);
      -moz-box-shadow: 0 5px 5px -5px rgba(34, 60, 80, 0.6);
      box-shadow: 0 5px 5px -5px rgba(34, 60, 80, 0.6);

      &__img {
        height: 80px;
        margin-bottom: 11px;
      }

      &__text {
        line-height: 133%;
      }
    }
  }
  .why-workmarket {
    &-slider {
      display: inline-block;
      width: calc(100% - 30px);
      margin: 0 15px;

      .slick-dots {
        bottom: -62px;
      }

      .slick-arrow {
        top: auto;
        bottom: -96px;
        display: inline-block;
      }

      .slick-next {
        bottom: -75px;
      }
    }

    &-item {
      width: 100%;
      padding: 14px 15px 19px;
      margin-bottom: 0;
      min-height: 264px;

      &__img {
        margin-bottom: 10px;
      }

      &__title {
        min-height: auto;
      }

      &__subtitle {
        min-height: 140px;
      }
    }
  }
  .logos-slider {
    width: calc(100% - 15px);
    margin-right: 0;
    margin-left: 15px;

    .logo-slide {
      width: 177px;
      height: 177px;

      .logo-slide__img {
        width: 120px;
      }
    }

    .slick-slide {
      padding: 0 10px;
    }

    .slick-dots {
      bottom: -57px;
    }

    .slick-arrow {
      top: auto;
      bottom: -94px;
      display: inline-block;
    }

    .slick-next {
      bottom: -72px;
    }
  }
  .slider-employer-home {
    .slick-dots li {
      height: 6px;
      width: 6px;
      margin: 0 3px;

      button {
        height: 6px;
        width: 6px;
      }

      button::before {
        width: 6px;
        height: 6px;
      }
    }
  }
  .slick-dotted.slick-slider {
    margin-bottom: 0;
  }
}