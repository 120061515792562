.form-control-lg {
  height: 3rem;
}

label {
  color: #676767;
  font-weight: 500;
}

.input-group {
  select {
    width: 120px !important;
  }

  input {
    width: 300px !important;

    @include media-breakpoint-down(sm) {
      width: 200px !important;
    }
  }
}

input {
  color: $textPrimary;
}

.form-control {
  padding-bottom: 0;
  color: $textPrimary;

  &:disabled,
  &[readonly] {
    background-color: #fff;
  }

  &:focus {
    box-shadow: none;
  }

  &.is-invalid {
    border-color: #DC3545 !important;
  }
}

.choices__list--single {
  padding-bottom: 0;
  padding-top: 6px;
}

.autocomplete .autocomplete-items {
  width: 100%;
}

.choices__input::-webkit-input-placeholder {
  color: $textPrimary;
}
.choices__input::-moz-placeholder {
  color: $textPrimary;
}
.choices__input:-ms-input-placeholder {
  color: $textPrimary;
}
.choices__input:-moz-placeholder {
  color: $textPrimary;
}