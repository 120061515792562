.account-applicant {
  &-header {
    .upload-photo-wrap .account-settings-page {
      @include align-items(center);
    }

    .account-img {
      background-color: #E5E5E5;
      width: 100px;
      height: 100px;
      border: #E5E5E5 1px solid;
      margin-right: 24px;
      overflow: hidden;
      border-radius: 50%;
      position: relative;

      img {
        position: absolute;
        left: 50%;
        top: 50%;
        @include translate(-50%, -50%);
      }

      .no-user-avatar {
        width: 79px;
      }

      .is-user-avatar {
        width: auto;
        height: auto;
        max-height: 50px;
        max-width: 90px;
      }
    }
  }

  &-nav {
    @include flexbox();
    @include align-items(center);
    @include justify-content(flex-start);
    @include flex-wrap(wrap);
    margin-bottom: 10px;
  }

  &-btn {
    @include flexbox();
    @include align-items(center);
    @include justify-content(flex-start);
    padding: 0 20px;

    &:nth-child(1) {
      padding-left: 0;
    }

    &__name {
      width: calc(100% - 38px);
      margin-bottom: 0;
      font-size: 16px;
      line-height: 1.2;
      color: $primary;

      &:hover {
        color: $hoverPrimary;
      }
    }

    .icon-wrap {
      @include flexbox();
      @include align-items(center);
      @include justify-content(center);
      width: 28px;
      height: 28px;
      margin-right: 10px;
    }

    .far,
    .fas {
      color: $primary;
    }

    .fa-tools {
      font-size: 20px;
    }

    .fa-heart,
    .fa-power-off,
    .fa-envelope-open-text {
      font-size: 18px;
    }
  }
}

.applicant-information-blocks {
  width: 100%;

  .employer-information-block {
    padding-bottom: 10px;

    &__empty {
      position: relative;
      padding-bottom: 25px;

      > div {
        width: 61%;
      }

      .employer-information-block__subtitle {
        margin-bottom: 30px;
      }
    }

    &__img {
      height: 232px;
      width: auto;
      position: absolute;
      top: 50%;
      right: 64px;
      @include translate(0, -50%);

      &__mob {
        display: none;
      }
    }
  }

  .employer-information-table {
    &__row {
      &__links {
        margin-top: 22px;
        padding-top: 10px;
        border-top: 0.5px solid #E5E5E5;
      }

      > p:nth-child(1) {
        width: 50%;
      }

      > p:nth-child(2) {
        width: 18%;
      }

      > p:nth-child(3) {
        width: 32%;
      }
    }

    &__header {
      > p:nth-child(1) {
        width: 50%;
      }

      > p:nth-child(2) {
        width: 18%;
      }

      > p:nth-child(3) {
        width: 32%;
      }
    }
  }
}

.show-all-jobs,
.applicant-form-info {
  display: none;
}

.applicant-form {
  &-wrap {
    background-color: #F9FAFC;
    margin-bottom: 20px;
    padding-top: 30px;
    padding-bottom: 30px;

    .form-control {
      color: $secondary;

      &:focus {
        color: $secondary;
        border-color: #E5E5E5;
      }
    }

    .autocomplete .autocomplete-items {
      max-height: 262px;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

      div {
        padding: 15px 20px;

        &:hover {
          .applicant-form-info {
            background-color: #e9e9e9;
          }
        }

        b, strong {
          font-weight: 400;
          color: $textPrimary;
          font-size: 16px;
        }
      }
    }

    .jumbotron-form-field {
      width: 83%;
    }

    .jumbotron-form-btn {
      width: 15%;

      &-icon:before {
        display: none;
      }
    }
  }

  .applicant-form-info {
    @include flexbox();
    @include align-items(center);
    @include justify-content(flex-start);
    @include flex-wrap(wrap);
    padding: 0 !important;
    border-bottom: none !important;

    p {
      @include flexbox();
      @include align-items(center);
      margin-bottom: 0;
      margin-right: 36px;
      color: $secondary;

      .fas {
        font-size: 10px;
        margin-right: 5px;
      }
    }
  }

  .show-all-jobs {
    display: block;
    font-size: 14px;
    color: $primary;
    text-align: center;
    padding: 9px 0;
    margin: 0;
    background-color: #fff;
    cursor: pointer;

    &:hover {
      color: $hoverPrimary;
    }
  }
}

@media only screen and (max-width: 992px) {
  .account-applicant-nav {
    display: none;
  }

  .applicant-information-blocks {
    .employer-information-block {
      &__empty {
        padding-bottom: 20px;

        > div {
          width: 70%;
        }

        .employer-information-block__subtitle {
          margin-bottom: 20px;
        }
      }

      &__img {
        height: 132px;
        right: 8px;
      }
    }

    .employer-information-table {
      &__row {
        &__links {
          margin-top: 20px;
        }

        > p:nth-child(1) {
          width: 51%;
        }

        > p:nth-child(2) {
          width: 28%;
        }

        > p:nth-child(3) {
          width: 21%;
        }
      }

      &__header {
        > p:nth-child(1) {
          width: 51%;
        }

        > p:nth-child(2) {
          width: 28%;
        }

        > p:nth-child(3) {
          width: 21%;
        }
      }
    }
  }

  .applicant-form {
    &-wrap {
      margin-bottom: 10px;
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .applicant-information-blocks {
    .employer-information-block {
      &__empty {
        > div {
          width: 100%;
          text-align: center;
        }

        .employer-information-block__subtitle {
          margin-bottom: 10px;
        }
      }

      &__title,
      &__subtitle {
        text-align: left;
      }

      &__img {
        display: none;

        &__mob {
          display: block;
          height: 132px;
          width: auto;
          margin: 0 auto 10px;
        }
      }
    }

    .employer-information-table {
      &__row {
        > p:nth-child(1) {
          width: 67%;
        }

        > p:nth-child(2) {
          width: auto;
        }
      }

      &__header {
        > p:nth-child(1) {
          width: auto;
        }

        > p:nth-child(2) {
          width: auto;
        }
      }
    }
  }

  .applicant-form {
    &-wrap {
      padding: 20px 16px;

      .jumbotron-form-field {
        width: 100%;
        margin-bottom: 15px;
      }

      .jumbotron-form-btn {
        width: 100%;
      }
    }

    .applicant-form-info p {
      margin-right: 18px;
    }
  }
}
