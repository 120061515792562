.vacancies-content {
	@include flexbox();
	@include align-items(flex-start);
	@include justify-content(space-between);
}

.vacancies-list-pagination {
	width: calc(100% - 295px);
}

.vacancies-list {
	width: 100%;
	padding: 0;
	&-item {
		list-style-type: none;
		border: 1px solid #D6D6D6;
		padding: 13px 30px 15px 33px;
		@include flexbox();
		@include align-items(flex-start);
		@include justify-content(space-between);
		margin-bottom: 10px;
	}
	.paid-tariff {
		background-color: #DBF5FF;
		border: 1px solid #BEE4FF;
	}
	.icon-vip-paid {
		display: none;
		position: absolute;
		left: 0;
		top: 0;
		color: #EA4E00;
		font-size: 24px;
	}
	.vip-paid-tariff {
		position: relative;
		.icon-vip-paid {
			display: inline-block;
		}
	}
}

.vacancy-title {
	color: #14669B;
	font-size: 15px;
	font-weight: 500;
	margin-bottom: 9px;
}

.block-you-need {
	@include flexbox();
	@include align-items(center);
	@include justify-content(flex-start);
	p {
		color: #E96E25;
		font-size: 12px;
		margin: 0 0 0 8px;
	}
	img {
		width: 13px;
		height: auto;
		margin-right: 4px;
	}
}

.vacancy-description {
	@include flexbox();
	@include align-items(center);
	@include justify-content(flex-start);
	p {
		font-size: 12px;
		margin-bottom: 0;
	}
	a {
		color: #14669B;
	}
}

.for-disk {
	position: relative;
	padding-left: 20px;
	margin-left: 18px;
	&:before {
		content: '';
		border-radius: 50%;
		width: 5px;
		height: 5px;
		background-color: #2581BC;
		position: absolute;
		left: 0;
		top: 54%;
		@include translate (0, -50%);
	}
}

.for-disk-hide {
	padding-left: 0;
	&:before {
		display: none;
	}
}

.location-employment-block {
	@include flexbox();
	@include align-items(center);
}

.popular-vacancies {
	a {
		.card-title {
			font-size: 14px;
		}

		&:hover {
			div.card {
				box-shadow: 0 5px 11px rgba(0, 0, 0, .2);
			}
		}
	}
}

.btn-filter-show {
	display: none;
	width: calc(100% - 20px);
	margin: 0 auto 20px;
	.fa-angle-down {
		font-size: 17px;
		margin-left: 10px;
		position: relative;
		top: 2px;
	}
	&.icon-rotate {
		.fa-angle-down {
			@include rotate(180);
		}
	}
}

.tooltip-vip-text {
	width: 195px;
	color: #fff;
	background-color: rgb(6, 35, 64);
	text-align: left;
	padding: 10px 8px;
	position: absolute;
	bottom: calc(100% + 16px);
	left: -86px;
	z-index: 1031;
	p {
		position: relative;
		margin-bottom: 0;
		font-weight: 400;
		font-size: 14px;
		&:before {
			content: '';
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 9px 10px 0 10px;
			border-color: rgb(6, 35, 64) transparent transparent transparent;
			position: absolute;
			bottom: -19px;
			left: 50%;
			@include translate (-50%, 0);
		}
	}
}

@media only screen and (max-width: 991px) {
	.vacancies-list-pagination {
		width: calc(100% - 260px);
	}
	.for-disk {
		margin-left: 9px;
		padding-left: 10px;
	}
	.vacancy-description {
		@include align-items(baseline);
		>p {
			max-width: 65%;
		}
		p {
			font-size: 9px;
		}
	}
	.vacancies-item-date {
		font-size: 12px;
	}
}

@media only screen and (max-width: 767px) {
	.vacancies-content {
		@include flex-direction(column);
	}
	.vacancies-list-pagination {
		width: 100%;
	}
	.for-disk {
		padding-left: 10px;
	}
	.vacancy-description {
		@include flex-direction(column);
		@include align-items(flex-start);
	}
	.for-disk {
		margin-left: 0;
		margin-right: 14px;
	}
	.btn-filter-show {
		display: inline-block;
		width: 100%;
	}
	.vacancies-mob {
		.container {
			padding-left: 0;
			padding-right: 0;
		}
		.number-of-vacancies {
			font-size: 18px;
			font-weight: 500;
			padding-left: 10px;
			padding-right: 10px;
		}
		.vacancies-list-item {
			border-left: none;
			border-right: none;
			@include flex-direction(column-reverse);
			padding: 10px 15px 15px 48px;
			margin-bottom: 10px;
			position: relative;
			&:before {
				content: '';
				height: calc(100% + 2px);
				width: 5px;
				position: absolute;
				left: -5px;
				top: -1px;
				border-top: 1px solid #BEE4FF;
				border-bottom: 1px solid #BEE4FF;
			}
			&:after {
				content: '';
				height: calc(100% + 2px);
				width: 5px;
				position: absolute;
				right: -5px;
				top: -1px;
				border-top: 1px solid #BEE4FF;
				border-bottom: 1px solid #BEE4FF;
			}
		}
		.vacancy-title,
		.vacancies-item-date {
			margin-bottom: 0;
		}
		.vacancy-description a,
		.vacancy-description p {
			font-size: 12px;
			max-width: none;
		}
		.vacancies-list .icon-vip-paid {
			left: 12px;
			font-size: 24px;
		}
		.for-disk-hide {
			padding-left: 0;
			margin-right: 15px;
		}
		.location-employment-block .pl-mob {
			padding-left: 19px;
		}
		.paid-tariff {
			&:before {
				content: '';
				height: calc(100% + 2px);
				width: 5px;
				position: absolute;
				left: -5px;
				top: -1px;
				background-color: #DBF5FF;
				border-top: 1px solid #BEE4FF;
				border-bottom: 1px solid #BEE4FF;
			}
			&:after {
				content: '';
				height: calc(100% + 2px);
				width: 5px;
				position: absolute;
				right: -5px;
				top: -1px;
				background-color: #DBF5FF;
				border-top: 1px solid #BEE4FF;
				border-bottom: 1px solid #BEE4FF;
			}
		}
	}
	.tooltip-vip-text {
		display: none !important;
	}
}

@media only screen and (max-height: 500px) and (orientation: landscape) {
	.vacancies-mob {
		.vacancies-list-item {
			border: 1px solid #D6D6D6;
		}
		.paid-tariff {
			border: 1px solid #BEE4FF;
		}
		.btn-filter-show {
			width: 100%;
		}
	}
}