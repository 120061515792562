.account {
  .offers-list-item {
    line-height: 1.4;

    &.unfinished {
      background-color: #efefef;
      padding-bottom: 0;
    }

    &.unfinished {
      background-color: #FEFF9B;
      padding-bottom: 0;
    }

    &.moderation {
      background-color: lightyellow;
      padding-bottom: 0;
    }

    &.unapproved {
      background-color: #ffe0e0;
      padding-bottom: 0;
    }

    span.unfinished {
      padding: 0;
      color: #505050;
      margin: 0 0 0 15px;
    }

    span.moderation {
      padding: 0;
      color: #cc872b;
      margin: 0 0 0 15px;
    }

    span.unapproved {
      padding: 0;
      color: indianred;
      margin: 0 0 0 15px;
    }
  }
  .offers-list-last {
    position: relative;
    bottom: 0;
  }
}