.fixed-top {
  position: fixed;
  background-color: $primary !important;
  z-index: 1031;

  &.is-hr.bg-hr {
    background: rgb(72, 168, 250) !important;
    background: -o-linear-gradient(23deg, rgba(72, 168, 250, 1) 0%, rgba(27, 124, 209, 1) 26%, rgba(224, 128, 231, 1) 70%, rgba(159, 115, 226, 1) 100%) !important;
    background: linear-gradient(67deg, rgba(72, 168, 250, 1) 0%, rgba(27, 124, 209, 1) 26%, rgba(224, 128, 231, 1) 70%, rgba(159, 115, 226, 1) 100%) !important;
  }

  @include media-breakpoint-up(lg) {
    box-shadow: none;
  }

  $SNOWDROPS_LENGTH: 100;

  .g-snows {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    overflow: hidden;

    > li {
      opacity: 0;
      position: absolute;
      top: 0;
      background: url("/images/snow.png") no-repeat;
      background-size: cover;
      animation-name: snow-drop;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      animation-fill-mode: forwards;

      $snow-size-base: 30;
      $browser-buffer: 50;
      $left-position: 100 + $browser-buffer;
      $animate-speed-base: 10000;
      $minimum-falling-speed: 5000;
      $animate-delay-base: 5000;
      $blur-base: 5;

      @for $i from 1 through $SNOWDROPS_LENGTH {
        $size: abs(random($snow-size-base) - random($snow-size-base)) + 0px;

        &:nth-child(#{$i}) {
          left: abs(random($left-position) - random($left-position)) + 0%;
          width: $size;
          height: $size;
          animation-duration: abs(random($animate-speed-base) - random($animate-speed-base)) + $minimum-falling-speed + 0ms;
          animation-delay: abs(random($animate-delay-base)) + 0ms;
          filter: blur(abs(random($blur-base) - random($blur-base)) + 0px);
        }
      }
    }

    &-big {
      overflow: visible;

      > li {
        animation-name: snow-drop-big;
      }
    }
  }

  @keyframes snow-drop {
    0% {
      transform: translate(0, 0);
      opacity: 0.5;
      margin-left: 0;
    }
    10% {
      margin-left: 15px;
    }
    20% {
      margin-left: 20px;
    }
    25% {
      transform: translate(0, 50px);
      opacity: 0.75;
    }
    30% {
      margin-left: 15px;
    }
    40% {
      margin-left: 0;
    }
    50% {
      transform: translate(0, 70px);
      opacity: 1;
      margin-left: -15px;
    }
    60% {
      margin-left: -20px;
    }
    70% {
      margin-left: -15px;
    }
    75% {
      transform: translate(0, 100px);
      opacity: 0.5;
    }
    80% {
      margin-left: 0;
    }
    100% {
      transform: translate(0, 150px);
      opacity: 0;
    }
  }

  @keyframes snow-drop-big {
    0% {
      transform: translate(0, 0);
      opacity: 0.5;
      margin-left: 0;
    }
    10% {
      margin-left: 15px;
    }
    20% {
      margin-left: 20px;
    }
    25% {
      transform: translate(0, 80px);
      opacity: 0.75;
    }
    30% {
      margin-left: 15px;
    }
    40% {
      margin-left: 0;
    }
    50% {
      transform: translate(0, 161px);
      opacity: 1;
      margin-left: -15px;
    }
    60% {
      margin-left: -20px;
    }
    70% {
      margin-left: -15px;
    }
    75% {
      transform: translate(0, 240px);
      opacity: 0.5;
    }
    80% {
      margin-left: 0;
    }
    100% {
      transform: translate(0, 330px);
      opacity: 0;
    }
  }
}

.no-bg {
  background: transparent !important;
}

.nav-link {
  padding: 0;
}

.navbar-search {
  &.full-search-form {
    display: none !important;
    float: left;
  }
}

#small-search-form-container {
  &.full-search-form {
    display: none;
  }

  &.small-search-form {
    display: block !important;
  }
}

.site {
  &.__scrolled {
    .navbar-default .navbar-collapse {
      border-bottom: none;
    }

    .navbar-wrap {
      display: none;
    }

    .navbar-search {
      display: block;
    }
  }

  &.__translate {
    .navbar-fixed {
      top: 40px;
    }
  }
}

.navbar {
  padding: 0.5rem 1rem 1rem;

  .nav-link {
    color: #fff;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: .8rem;
    margin-right: .8rem;
    position: relative;

    &.pl-0 {
      padding-left: 0 !important;
    }

    &.active {
      border-bottom: 2px solid #fff;
    }
  }

  .navbar-toggler {
    font-size: 80%;
    color: #fff;

    &:focus {
      outline: 0;
    }

    i {
      font-size: 22px;
    }

    .icon-nav {
      width: 24px;
      height: 3px;
      background-color: #fff;
      margin: 2px 0;

      &-block {
        @include flexbox();
        @include flex-direction(column);
      }
    }
  }

  .navbar-brand {

    &.logo-icon img {
      width: 40px;
    }

    img {
      width: 170px;

      @include media-breakpoint-up(md) {
        width: 230px;
      }
    }
  }
}

.navbar-top {
  padding: 10px 0 0;
  z-index: 1;
  position: relative;
  /*
  &:before{
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 330px;
      height: 47px;
      background: url("/images/newYear/garland.svg") repeat-x;
      background-size: contain;
      z-index: -1;
  }
  &:after{
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      width: 245px;
      height: 71px;
      background: url("/images/newYear/garland.svg") no-repeat;
      background-size: cover;
      z-index: -1;
  }
  */

  .container {
    @include flexbox();
    @include align-items(center);
    @include justify-content(space-between);
  }

  .navbar-brand {
    height: 41px;

    img {
      width: auto;
      height: 30px;
    }
  }

  ul {
    margin-bottom: 0;

    > li:nth-child(1) {
      padding-right: 0;
    }

    li {
      padding: 3px 10px;

      &:nth-of-type {
        padding-right: 0;
      }

      a {
        color: #fff;

        &:hover {
          text-decoration: none;
        }
      }

      //.fa-globe {
      //  font-size: 18px;
      //  line-height: 26px;
      //}

      .dropdown-item {
        color: #212529 !important;
      }

      .color-black {
        color: $textPrimary;
      }
    }
  }
}

.link-wrap a {
  color: $primary !important;

  &:hover {
    color: $hoverPrimary !important;
  }
}

.lang-icon {
  .flag-icon {
    width: 24px;
    height: 19px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    background-size: cover;
  }

  .dropdown-menu {
    padding: 0;
    min-width: auto;

    .flag-icon {
      margin-right: 10px;
    }
  }

  .dropdown-item {
    padding: 10px 8px;
    @include flexbox();
    @include align-items(center);
    @include justify-content(flex-start);
  }
}

.btn-open-menu {
  background: none;
  border: none;

  .fa-user {
    width: 18px;
  }

  &:focus {
    outline: none;
  }
}

/* The container <div> - needed to position the dropdown content */
.guest-dropdown {
  position: relative;
  display: inline-block;

  &:before {
    content: "";
    padding: 10px 0;
    position: absolute;
    left: -6px;
    top: -6px;
    width: calc(100% + 12px);
    height: calc(100% + 12px);
  }

  /* Dropdown Button */
  .dropbtn {
    //background-color: #4CAF50;
    //color: white;
    padding: 1px 10px;
    border: none;
    line-height: 26px;
  }

  &:hover .dropdown-content {
    display: block;
    z-index: 1031;
  }

  &:hover .dropbtn {
    background-color: $warning;
  }

  /* Dropdown Content (Hidden by Default) */
  & > .dropdown-content {
    right: 0;
    display: none;
    position: absolute;
    background-color: #fff;
    min-width: 200px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  /* Links inside the dropdown */
  & > .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;

    &:hover {
      background-color: #ddd;
    }
  }

}

.isResponse {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #EFD031;
  position: absolute;
  top: 3px;
  right: 3px;
}

.items-for-mob {
  display: none !important;
}

.dropdown-toggle::after {
  display: none;
}

.btn-login-reg {
  color: #A9FFFC;
  border: 1px solid #A9FFFC;
  font-size: 14px;
  line-height: 26px;
  padding: 0 10px;
  background: none;

  &:focus {
    outline: none;
  }

  .fa-sign-in-alt,
  .fas {
    display: none;
  }

  &-wrap {
    position: relative;
    padding-right: 0 !important;
  }
}

.login-reg-block {
  position: absolute;
  right: 0;
  top: 36px;
  display: none;
  background-color: #fff;
  width: 377px;
  max-height: calc(100vh - 60px);
  overflow: hidden;
  overflow-y: auto;
  padding: 3px 20px 15px;
  -webkit-box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.4);
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    background: #FF0000;
  }

  &__open {
    display: inline-block;
  }

  &-close {
    display: none;
  }

  &-header {
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);

    p {
      width: 50%;
      //padding: 6px 27px;
      font-size: 15px;
      line-height: 50px;
      color: $primary;
      border: none;
      border-bottom: 1px solid $primary;
      margin-bottom: 0;
      text-align: center;
      cursor: pointer;

      &.active {
        color: $textPrimary;
        border-bottom: 4px solid $primary;
        background-color: #fff;
      }
    }
  }

  &-content {
    padding: 20px 0 0;
    width: 100%;
    display: none;

    &__active {
      display: block;
    }

    .btn-primary {
      padding: 0 20px;
      line-height: 36px;
      font-size: 14px;
      margin: 0 auto 0;
      width: 100%;
    }
  }

  .login-reg-form-row {
    margin-bottom: 10px;
    text-align: left;

    .was-validated .form-control:invalid,
    .form-control.is-invalid {
      background: none;
    }

    label {
      font-size: 13px;
      color: $textPrimary;
      margin-bottom: 5px;
    }

    .material-icons-outlined {
      font-size: 16px;
      color: $primary;
      vertical-align: sub;
      cursor: pointer;
    }

    .star-label {
      font-size: 13px;
    }

    .form-control {
      height: 22px;
    }

    .form-control {
      height: 30px;
      font-size: 14px;
    }

    .form-reg-field {
      height: 30px;

      .form-control {
        height: 27px;
      }
    }
  }

  .forgot-pass {
    font-size: 13px;

    a {
      color: #008BEB;
    }
  }

  .form-auth-text {
    color: #6C757D;
    font-size: 12px;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 0;

    span {
      font-weight: bold;
    }

    a {
      color: #008BEB;
    }
  }

  .login-reg-social {
    margin-top: 10px;
    @include flexbox();
    @include align-items(flex-end);
    @include justify-content(center);

    .social-img-wrap {
      height: 19px;
      width: 19px;
      border-radius: 50%;
    }

    .fa-google {
      //line-height: 19px;
      font-size: 15px;
      color: #D80027;
    }

    .fa-facebook-f {
      font-size: 17px;
      color: #4680C2;
    }

    a {
      margin: 0 15px;
      padding: 5px;
      text-align: center;
    }
  }

  .radio-button-block {
    margin-bottom: 20px;
    @include flexbox();
    @include align-items(center);
    @include justify-content(flex-start);

    > li {
      padding: 0;
    }

    .castome-radio-btn {
      visibility: hidden;
      margin-left: 0;

      &:after {
        width: 10px;
        height: 10px;
        top: -2px;
        left: -1px;
      }

      &:checked:before {
        width: 10px;
        height: 10px;
        top: -2px;
        left: -1px;
      }

      &:checked:after {
        width: 6px;
        height: 6px;
        top: 5px;
        left: 1px;
      }
    }
  }

  .already-registered-text {
    font-size: 16px;
    text-align: center;
    margin: 15px auto 0;

    a {
      color: $primary;
    }
  }

  .form-control__desc {
    display: none;
    font-size: 13px;
    line-height: 20px;
    color: $secondary;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    right: .9rem;
    padding-bottom: 0;
  }
}

@media only screen and (max-width: 991px) {
  .navbar-top {
    z-index: 0;
    padding: 6px 0 0;

    &:before,
    &:after {
      display: none;
    }

    .navbar-brand {
      img {
        height: 20.9px;
      }
    }

    ul {
      .lang-icon {
        padding-right: 0;
      }

      li {
        padding: 3px 16px;
      }

      > li:nth-child(1) {
        margin-right: -1px;
        margin-top: -3px;
      }
    }
  }
  .navbar {
    .nav-link {
      color: rgba(94, 94, 94, 0.87);
    }
  }
  //.no-bg {
  //  background-image: linear-gradient(to top, #248bde, #1e8be1, #178be5, #0d8be8, #008beb) !important;
  //}
  .items-for-mob {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .fixed-top {
    .g-snows {
      display: none;
    }
  }
}

@media only screen and (max-width: 767px) {
  .login-reg-block {
    .login-reg-form-row {
      margin-bottom: 10px;
      text-align: left;
    }

    .radio-button-block {
      @include flex-direction(row);

      li {
        width: 50%;
        margin-bottom: 0;
      }
    }

    .form-control__desc {
      display: inline-block;
    }
  }

  .navbar-top ul li {
    padding: 3px 10px;
  }

}

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .login-reg-block {
    width: 100vw;
    top: 51px;
    padding: 0 16px 10px;

    &-close {
      display: inline-block;
      font-size: 14px;
      line-height: 31px;
      color: #008AEA;
      border: none;
      background-color: #fff;
      width: 100%;

      &:focus {
        outline: none;
      }
    }

    &-header {
      p {
        width: 50%;
        padding: 0;
        text-align: center;
        font-size: 14px;
        line-height: 42px;
        border-bottom: 3px solid #D8D8D8;
      }
    }

    &-content {
      padding: 20px 0 16px;

      .btn-primary {
        padding: 0 20px;
        line-height: 35px;
        font-size: 13px;
        margin: 0 auto;
      }
    }

    .radio-button-block {
      @include justify-content(flex-start);
      padding: 0;
      margin-bottom: 20px;

      label {
        font-size: 13px;
      }

      .castome-radio-btn {
        &:after {
          width: 20px;
          height: 20px;
          top: -2px;
          left: -1px;
        }

        &:checked:before {
          width: 20px;
          height: 20px;
          top: -2px;
          left: -1px;
        }

        &:checked:after {
          width: 12px;
          height: 12px;
          top: 2px;
          left: 3px;
        }
      }
    }

    .login-reg-form-row {
      label {
        font-size: 14px;
        margin-bottom: 5px;
      }

      .star-label {
        font-size: 15px;
      }

      .form-control {
        height: 31px;
      }

      .form-control {
        height: 33px;
      }

      .form-reg-field {
        height: 33px;

        .form-control {
          height: 30px;
        }
      }
    }

    .form-auth-text {
      font-size: 11px;
      margin-top: 20px;
    }

    .login-reg-social {
      .social-img-wrap {
        height: 27px;
        width: 27px;
      }

      .fa-google {
        line-height: 27px;
        font-size: 17px;
      }

      .fa-facebook-f {
        font-size: 21px;
      }

      a {
        margin: 12px;
      }
    }

    .custom-control {
      margin-bottom: 15px;
      padding-left: 29px;

      &-label {
        font-size: 14px;

        &::before {
          width: 20px;
          height: 20px;
          left: -29px;
        }
      }
    }

    .custom-control-label::after {
      //top: 0;
      left: -29px;
      //width: 25px;
      //height: 25px;
    }

    .forgot-pass {
      font-size: 14px;
    }
  }

  .btn-login-reg {
    border: none;

    .fa-sign-in-alt,
    .fas {
      display: inline-block;
      font-size: 14px;
      color: #fff;
    }

    span {
      display: none;
    }

    &-wrap {
      position: static;
    }
  }
}
