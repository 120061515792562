.radio-button {
  display: inline-block;
  margin-right: 20px;
}
.radio-button input[type=radio] {
  display: none;
}
.radio-button label {
  display: inline-block;
  cursor: pointer;
  padding: 0 15px;
  border: none;
  user-select: none;
  background: #F5F5F5;
  font-size: 13px;
  line-height: 30px;
  color: $secondary!important;
  font-weight: 400;
  margin-bottom: 0;
}

.radio-button input[type=radio]:checked + label {
  color: #fff!important;
  background: #02B538;
}
