.home-employers-banner {
  overflow: hidden;

  &-gift {
    background-color: #FAEDE5;
    margin-top: 5.6vh;

    .container {
      position: relative;
      padding: 7.4vh 5vh 7.4vh;
    }
  }

  &-tariff {
    background-color: #DCE7FF;
    margin-top: 6.5vh;

    .container {
      position: relative;
      padding: 12.3vh 0 12.3vh 5vh;
    }
  }

  &__img {
    width: auto;
    position: absolute;
  }

  &-gift__img {
    height: 27.3vh;
    right: -10.6vh;
    bottom: 0;
  }

  &-tariff__img {
    height: 51.5vh;
    right: -21.9vh;
    top: 50%;
    @include translate(0, -50%);
  }
}

.banner-text-btn {
  &-tariff {
    @include flexbox();
    @include align-items(center);
    @include justify-content(space-between);
  }

  &__p {
    font-size: 4.6vh;
    width: 60vh;
    margin-bottom: 0;
    >br {
      display: none;
    }
  }

  &__span {
    font-weight: bold;
  }

  &-gift__p {
    margin-bottom: 4.6vh;
  }

  &__btn {
    font-size: 1.85vh;
    padding: 1.7vh 12.2vh;
    z-index: 1;

    &_gold {
      background-color: #FFDB49;
      color: $body-color;

      &:hover {
        text-decoration: none;
        background-color: #c5a939;
        color: $body-color;
      }
    }
  }
}

@media screen and (orientation: landscape) and (min-width: 216vh) {
  .home-employers-banner {
    &-gift {
      .container {
        padding: 7.4vh 25vh 7.4vh;
      }

      &__img {
        height: 32.3vh;
        right: 21.4vh;
      }
    }

    &-tariff .container {
      padding: 12.3vh 0 12.3vh 25vh;
    }
  }
}

@media screen and (orientation: landscape) and (max-width: 135vh) {
  .banner-text-btn__p {
    font-size: 4.3vh;
    width: 51vh;
  }
  .home-employers-banner-tariff__img {
    height: 43.5vh;
    right: -16.9vh;
  }
}

@media only screen and (min-width: 768px) and (orientation: portrait) {
  .home-employers-banner {
    &-gift {
      &__img {
        height: 26.3vw;
        right: -10.6vw;
        bottom: 0;
      }
      .container {
        padding: 4.4vw 10vw 4.4vw;
      }
    }
    &-tariff {
      margin-top: 3.5vh;
      &__img {
        height: 46.5vw;
        right: -10.9vw;
      }
      .container {
        position: relative;
        padding: 12.3vw 17vw 12.3vw 4.4vw;
      }
    }
  }
  .banner-text-btn {
    &__btn {
      font-size: 1.85vw;
      padding: 1.7vw 3.2vw;
    }
    &__p {
      font-size: 3vw;
      width: 61vw;
      > br {
        display: inline-block;
      }
    }
    &-gift__p {
      margin-bottom: 4.6vw;
    }
  }
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .home-employers-banner {
    &-gift {
      background: url("/images/home-employers/banner-gift-bg.jpg") center center no-repeat;
      background-size: cover;
      margin-top: 8.3vw;

      .container {
        padding: 8.3vw 10px 30vw;
      }
    }

    &-tariff {
      background: url("/images/home-employers/banner-tariff-bg.jpg") center center no-repeat;
      background-size: cover;
      margin-top: 2.8vw;

      .container {
        padding: 8.3vw 10px 28.6vw;
      }
    }

    &__img {
      display: none;
    }
  }
  .banner-text-btn {
    &-tariff {
      @include flex-direction(column);
      .banner-text-btn__p {
        text-align: center;
        margin-bottom: 28vw;
      }
    }
    &__p {
      font-size: 5.6vw;
      width: 100%;
      >br {
        display: inline-block;
      }
    }

    &-gift {
      text-align: center;

      &__p {
        margin-bottom: 8.3vw;
        text-align: center;
      }
    }

    &__btn {
      font-size: 3.9vw;
      padding: 2.7vw 6.1vw;
    }
  }
}