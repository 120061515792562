.lazy-load {
	a {
		padding: 0 30%;
	}
}

.gradient {
	animation-duration: 2s;
	animation-fill-mode: forwards;
	animation-iteration-count: 10;
	animation-name: load;
	animation-timing-function: linear;
	background: #f6f7f8;
	background: linear-gradient(to right, #c9c9c9 8%, #f4f4f4 38%, #d5d5d5 54%);
	background-size: 1000px 640px;
	
	position: relative;
	
}

@keyframes load {
	0%{
		background-position: -468px 0
	}
	100%{
		background-position: 468px 0
	}
}