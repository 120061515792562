.page-settings{
	@include flexbox();
	@include justify-content(space-between);
	@include align-items(flex-start);
}

@media only screen and (max-width: 767px) {
	.page-settings{
		@include flex-direction(column-reverse);
	}
}
