.guideline-colors-table{
	width: 919px;
	thead {
		background-color: #F7F7F7;
		th {
			border: none;
			color: #868686;
			padding: 5px 0;
			font-weight: normal;
		}
	}
	tr {
		th:nth-child(1){
			width: 455px;
		}
		th:nth-child(2){
			width: 80px;
		}
		th:nth-child(3){
			width: 135px;
		}
		th:nth-child(4){
			width: 245px;
		}
	}
	td {
		padding: 20px 0 0;
		border: none;
	}
	.color-block{
		width: 25px;
		height: 25px;
		cursor: pointer;
		p{
			display: none;
		}
		&.is-copied {
			position: relative;
			p{
				position: absolute;
				left: 50%;
				top: -15px;
				@include translate(-50%, 0);
				font-size: 10px;
				margin-bottom: 0;
				display: inline-block;
			}
		}
	}
}

@media only screen and (max-width: 1439px) {
	.guideline-colors-table tr th:nth-child(4) {
		width: 180px;
	}
}

@media only screen and (max-width: 991px) {
	.guideline-colors-table {
		width: 704px;
		tr {
			th:nth-child(1) {
				width: 370px;
			}
			th:nth-child(2) {
				width: 50px;
			}
			th:nth-child(3) {
				width: 75px;
			}
			th:nth-child(4) {
				width: 209px;
			}
		}
	}
}