
.file-upload-wrapper {
	position: relative;
	width: 100%;
	height: 30px;
	white-space: nowrap;
	overflow: hidden;

	&:after {
		content: attr(data-text);
		font-size: 15px;
		position: absolute;
		top: 0;
		left: 0;
		background: #fff;
		padding: 11px 15px;
		display: block;
		width: 100%;
		pointer-events: none;
		z-index: 20;
		height: 30px;
		line-height: 5px;
		color: #999;
		border: 1px solid #ccc;
		border-radius: 3px 10px 10px 3px;
		font-weight: 300;
	}

	&:before {
		font: normal normal normal 14px/1;
		font-family: "Font Awesome 5 Free", serif;
		content: "\f093";
		position: absolute;
		top: 0;
		right: 0;
		display: inline-block;
		height: 30px;
		background: $primary;
		color: #fff;
		font-weight: 600;
		z-index: 25;
		font-size: 15px;
		line-height: 30px;
		padding: 0 15px;
		text-transform: uppercase;
		pointer-events: none;
		border-radius: 0 3px 3px 0;
	}

	&:hover:before {
		background: #3d8c63;
	}

	input {
		opacity: 0;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 99;
		height: 30px;
		margin: 0;
		padding: 0;
		display: block;
		cursor: pointer;
		width: 100%;
	}
	&-left {
		width: 80%;
		float: left;
	}
	&-right {
		width: 18%;
		float: right;
		.btn {
			line-height: 18px;
			width: 100%;
		}
	}
}

.attachments .btn.btn-info {
	position: absolute;
	right: 0;
	left: 100%;
	top: 0;
	width: 35px;
	height: 30px;
}

.form-avatar {
	background-color: #dcebf5;
	border-radius: 50%;
	width: 65px;
	height: 65px;
	border: $UILightGray 1px solid;
	cursor: pointer;
	text-align: center;
	display: table;
	position: relative;
	overflow: hidden;
	margin-right: 20px;
	&-text {
		color: $link-color;
		line-height: 20px;
		text-align: center;
		cursor: pointer;
		position: absolute;
		top: calc(50% - 20px);
		width: 100%;
		z-index: 1;
	}
	&-delete {
		display: none;
		position: absolute;
		top: 63%;
		left: 0;
		right: 0;
		bottom: 0;
		background-image: url('/images/bg-black.png');;
		z-index: 1;
		justify-content: center;
		font-size: 20px;
		&-new, &-del {
			color: $primary;
			float: left;
			padding: 5px 12px;
			&:hover {
				cursor: pointer;
				//color: $primary;
			}
		}
	}
	&-img {
		overflow: hidden;
		border-radius: 50%;
		position: relative;
		height: 100%;
		max-width: 65px;
		max-height: 65px;
		.no-user-avatar {
			width: 26px;
			height: auto;
			position: absolute;
			left: 50%;
			top: 50%;
			@include translate(-50%, -50%);
		}
		.is-user-avatar {
			width: auto;
			max-width: 100%;
			height: auto;

			//width: 100px;
			//max-width: 100%;
			//height: 50px;
			//max-height: 100%;
			//position: absolute;
			//left: 50%;
			//top: 50%;
			//@include translate(-50%, -50%);
		}

	}
	&:hover {
		.form-avatar-text {
			text-decoration: underline;
		}
	}
	&._delete {
		.form-avatar-text {
			display: none;
		}
		&:hover {
			.form-avatar-delete {
				display: flex;
			}
		}
		&:hover:after {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			opacity: .2;
			background-color: #000000;
			border-radius: 50%;
			z-index: 0;
		}
		.form-avatar-text {
			color: #ffffff;
		}
	}
}

.account-settings-page {
	padding-left: 15px;
	padding-right: 15px;
	.user-name {
		font-size: 24px;
		margin-bottom: 0;
	}
	.upload-photo-text {
		font-size: 12px;
	}
	&-form {
		margin-top: 7px;
		.block-contacts-list {
			margin-right: 20px;
		}
		.btn-communication-method {
			font-weight: 400;
		}
	}
}

@media only screen and (max-width: 850px) {
	.account-settings-page-form .block-contacts-list .block-communication-method {
		margin-top: 0;
	}
}