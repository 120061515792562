.form-notification-subscription {
  width: 95%;
  max-width: 495px;
  margin: 30px auto;

  .color-btn {
    background-color: #5BC0DE;
    border-color: #5BC0DE;
  }

  .form-group {
    @include flexbox();
    @include align-items(center);
    @include justify-content(space-between);
  }

  &-title {
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
    margin-bottom: 11px;

    img {
      width: 22px;
      height: auto;
      margin-right: 13px;
    }

    p {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 0;
    }
  }
}

@media only screen and (max-width: 768px) {
  .form-notification-subscription .form-group {
    @include flex-direction(column);

    input {
      width: 100%;
      margin-bottom: 10px;
    }

    button {
      margin: 0 !important;
    }
  }
  .form-notification-subscription {
    width: 100%;
    padding: 0 10px;

    .form-check {
      padding-left: 28px;
    }
  }
}