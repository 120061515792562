.cv-page {
    .form-notification-subscription-title p {
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 0;
        text-align: center;
    }
    .vacancy-date{
        padding-right: 10px;
    }
}

.similar-resumes-list{
    li {
        padding: 10px 20px 10px 20px;
        border-bottom: 1px solid #F0F0F0;
        @include flexbox();
        @include align-items(flex-start);
        @include justify-content(space-between);
    }
    li:nth-last-child(1){
        border-bottom: none;
    }
    &-photo {
        width: 61px;
        height: 61px;
        overflow: hidden;
        img{
            width: 100%;
            height: auto;
            max-height: 100%;
        }
    }
}

.similar-resumes-card-content {
    width: calc(100% - 70px);
    .vacancy-tags {
        width: 100%;
    }
}

.date-place-cv{
    margin-top: 10px;
    @include flexbox();
    @include justify-content(flex-start);
    >p{
        margin-bottom: 0;
    }
}
