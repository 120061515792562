.promo-page {
	overflow: hidden;
	&-title {
		font-size: 50px;
		font-weight: 600;
		text-align: center;
		margin-bottom: 15px;
	}
	&-sub-title {
		font-size: 18px;
		text-align: center;
		margin-bottom: 45px;
	}
}

.block-promo-rate {
	@include flexbox();
	@include align-items(flex-start);
	@include justify-content(space-between);
	margin-bottom: 50px;
	&:before {
		display: none;
	}
	&-card {
		width: 435px;
		position: relative;
		.service-card {
			width: 100%;
			max-width: 252px;
			margin: auto;
			padding: 15px 15px 25px;
			background-color: #fff;
			&:before {
				visibility: visible;
			}
			&-name {
				//font-weight: 500;
				text-align: center;
			}
			.title-content,
			ul {
				width: 100%;
			}
			li p {
				margin: 0 0 0 18px;
				font-size: 12px;
				line-height: 26px;
			}
			.title-content{
				display: inline-block!important;
				p {
					margin-bottom: 0;
				}
			}
			.btn {
				font-size: 12px;
				padding: 6px 22px;
			}
		}
		.rate-img {
			position: absolute;
			width: 435px;
			height: auto;
			left: 50%;
			top: 50%;
			@include translate(-50%, -50%);
		}
		.title-content {
			min-height: 307px;
		}
		.card-text {
			font-size: 14px;
			line-height: 26px;
			max-width: 180px;
		}
	}
	&-info {
		width: calc(100% - 480px);
		&-title {
			font-size: 24px;
			font-weight: 400;
			text-align: left;
			margin-bottom: 20px;
		}
		&-sub-title {
			font-size: 14px;
			text-align: left;
			margin-bottom: 20px;
		}
		&-text {
			text-align: left;
			margin-bottom: 0;
			.read-more-text {
				display: none;
			}
			.read-more {
				cursor: pointer;
				color: #008AEA;
				&:hover {
					text-decoration: underline;
				}
			}
		}
		.open-read-more {
			.read-more-text {
				display: inline-block;
			}
		}
	}
	&.color1 {
		.block-promo-rate-info-title {
			color: #40505F;
		}
		.block-promo-rate-info-sub-title {
			color: #40505F;
		}
	}
	&.color2 {
		.block-promo-rate-info-title {
			color: #02B538
		}
		.block-promo-rate-info-sub-title {
			color: #02B538;
		}
	}
	&.color3 {
		.block-promo-rate-info-title {
			color: #FF9E00;
		}
		.block-promo-rate-info-sub-title {
			color: #FF9E00;
		}
	}
	&.color4 {
		.block-promo-rate-info-title {
			color: #0074F0;
		}
		.block-promo-rate-info-sub-title {
			color: #0074F0;
		}
	}
	&-reverse {
		@include flex-direction(row-reverse);
		.block-promo-rate-card {
			width: 252px;
		}
	}
}

.block-tariff-plan {
	padding: 33px 0 50px;
	background: url("/images/promo-page/bg.svg") no-repeat;
	background-size: cover;
	&-title {
		font-size: 70px;
		font-weight: 400;
		text-align: center;
		margin-bottom: 20px;
		color: #fff;
	}
	&-sub-title {
		font-size: 20px;
		text-align: center;
		margin-bottom: 50px;
		color: #fff;
	}
	.btn {
		font-size: 20px;
		padding: 15px 40px;
	}
}

.block-diagrams {
	padding-top: 80px;
	.container {
		@include flexbox();
		@include align-items(flex-start);
		@include justify-content(space-between);
	}
}

.block-diagram {
	max-width: 525px;
	width: 40%;
	&-title {
		font-size: 20px;
		color: #767676;
		text-align: right;
		margin-bottom: 10px;
	}
	.chart {
		height: 301px;
		width: 100%;
		@include flexbox();
		@include align-items(flex-end);
		@include justify-content(space-between);
		background-image: linear-gradient(#ccc 1px, transparent 1px);
		background-size: 20px 50px;
		padding: 0 20px;
		li {
			position: relative;
			height: 100%;
			width: 65px;
		}
		span {
			width: 100%;
			margin: auto;
			display: block;
			animation: draw 1s ease-in-out;
			position: absolute;
			bottom: 0;
			left: 0;
			&:before {
				position: absolute;
				left: 0;
				right: 0;
				top: -36px;
				display: block;
				text-align: center;
				content: attr(title);
				word-wrap: break-word;
				font-size: 16px;
				color: #40505F;
			}
		}
		@keyframes draw {
			0% {
				height: 0;
			}
		}
	}
	.diagram-legend {
		padding: 0 20px;
		width: 100%;
		margin-bottom: 0;
		@include flexbox();
		@include flex-wrap(wrap);
		li {
			@include flexbox();
			@include align-items(center);
			margin-right: 20px;
			.box-color {
				width: 16px;
				height: 16px;
				margin-right: 13px;
			}
			p {
				margin-bottom: 0;
				font-size: 15px;
				color: #40505F;
			}
		}
	}
}

@media only screen and (max-width: 1024px) {
	.block-diagram .chart li {
		width: 58px;
	}
}

@media only screen and (max-width: 991px) {
	.promo-page {
		padding-bottom: 30px;
		&-title {
			font-size: 45px;
			margin-bottom: 5px;
		}
	}
	.block-tariff-plan {
		&-title {
			font-size: 48px;
			margin-bottom: 10px;
		}
		&-sub-title {
			margin-bottom: 33px;
		}
	}
	.block-diagram .chart li {
		width: 41px;
	}
}

@media only screen and (max-width: 767px) {
	.promo-page {
		padding-bottom: 100px;
		&-title {
			font-size: 24px;
			margin-bottom: 16px;
		}
		&-sub-title {
			font-size: 14px;
			margin-bottom: 17px;
		}
	}
	.block-promo-rate {
		@include flex-direction(column-reverse);
		margin-bottom: 35px;
		&-info {
			width: 100%;
			margin-bottom: 20px;
		}
		&-reverse .block-promo-rate-card,
		&-card {
			width: 100%;
		}
	}
	.block-tariff-plan {
		margin-top: 55px;
		&-title {
			font-size: 35px;
			margin-bottom: 15px;
		}
		&-sub-title {
			font-size: 16px;
			margin-bottom: 50px;
		}
	}
	.block-diagrams {
		padding-top: 35px;
		.container {
			@include flex-direction(column);
			@include align-items(center);
		}
		.mob-mb {
			margin-top: 55px;
		}
	}
	.block-diagram {
		width: 100%;
		&-title {
			font-size: 16px;
		}
		.chart {
			padding: 0 12px;
			margin-bottom: 22px;
			height: 201px;
			li {
				width: 40px;
			}
			span:before {
				font-size: 14px;
			}
		}
		.block-diagram .diagram-legend {
			padding: 0 12px;
			li {
				.box-color {
					width: 12px;
					height: 12px;
					margin-right: 10px;
				}
				p {
					font-size: 14px;
				}
			}
		}
	}
}