.reset-select {
	.chosen-container-single {
		a.chosen-single {
			background: none;
			height: 34px;
			border: 1px solid #ccc;
			-webkit-border-radius: 3px;
			-moz-border-radius: 3px;
			border-radius: 3px;
			
			span {
				line-height: 31px;
			}
			
			div > b {
				background-position-y: 6px;
			}
		}
	}

	&.white-bg {
		.chosen-container-single {
			a.chosen-single {
				background: white;
			}
		}
	}
	
}