.account-employers {
  &-nav {
    @include flexbox();
    @include align-items(center);
    @include justify-content(flex-start);
    @include flex-wrap(wrap);
    margin-bottom: 20px;
  }

  &-btn-link {
    width: 16.6%;

    &:hover {
      text-decoration: none;
      color: $hoverPrimary;

      .account-employers-btn {
        border-color: $primary;
      }
    }
  }

  &-btn {
    @include flexbox();
    @include align-items(center);
    @include justify-content(flex-start);
    padding: 10px 15px;
    width: 100%;
    min-height: 80px;
    border: 0.5px solid $UILightGray;

    &__name {
      width: calc(100% - 38px);
      margin-bottom: 0;
      font-size: 16px;
      line-height: 1.2;
      color: $primary;
    }

    .icon-wrap {
      @include flexbox();
      @include align-items(center);
      @include justify-content(center);
      width: 28px;
      height: 28px;
      margin-right: 10px;
    }

    .fas {
      color: $primary;
    }

    .fa-clipboard-list {
      font-size: 23px;
    }

    .fa-tools,
    .fa-file-invoice-dollar {
      font-size: 20px;
    }

    .fa-heart,
    .fa-power-off,
    .fa-envelope-open-text {
      font-size: 18px;
    }
  }

  &-content {
    @include flexbox();
    @include align-items(flex-start);
    @include justify-content(space-between);
  }
}

.employer-detail-block {
  width: 277px;
  padding: 20px;
  border: 0.5px solid $UILightGray;
  position: relative;

  &__row {
    &-name {
      font-weight: 500;
      line-height: 1;
      margin-bottom: 10px;
    }

    &-content {
      line-height: 1;
      margin-bottom: 15px;

      &:nth-last-child(1) {
        margin-bottom: 0;
      }
    }
  }
}

.employer-information-blocks {
  width: calc(100% - 298px);
}

.employer-information-block {
  margin-bottom: 10px;
  border: 0.5px solid $UILightGray;
  padding: 20px;
  width: 100%;

  &__empty {
    @include flexbox();
    @include align-items(center);
    @include justify-content(space-between);

    > div {
      width: calc(100% - 280px);
    }
  }

  &__title {
    font-size: 18px;
    line-height: 133%;
    font-weight: 500;
    margin-bottom: 15px;
  }

  &__subtitle {
    color: $secondary;
    margin-bottom: 0;
  }
}

.employer-information-table {
  &__header {
    @include flexbox();
    @include align-items(center);
    @include justify-content(flex-start);
    border-top: 0.5px solid #E5E5E5;
    border-bottom: 0.5px solid #E5E5E5;
    padding: 10px 0;
    margin-top: 15px;
    margin-bottom: 15px;

    > p {
      font-weight: 500;
      font-size: 15px;
      color: $secondary;
      margin-bottom: 0;
      padding-right: 10px;

      &:nth-child(1) {
        width: 45%;
      }

      &:nth-child(2) {
        width: 24%;

        span {
          display: none;
        }
      }

      &:nth-child(3) {
        width: 31%;
      }
    }
  }

  &__row {
    @include flexbox();
    @include align-items(baseline);
    @include justify-content(flex-start);
    margin-bottom: 10px;

    &:nth-last-child(1) {
      margin-bottom: 0;
    }

    &__btn {
      margin-top: 15px;
      text-align: right;
    }

    &__links {
      margin-top: 15px;
      text-align: left;

      > a {
        margin-right: 45px;
      }
    }

    > p {
      padding-right: 10px;
      margin-bottom: 0;

      &:nth-child(1) {
        width: 45%;
      }

      &:nth-child(2) {
        width: 24%;

        span {
          display: none;
        }
      }

      &:nth-child(3) {
        width: 31%;
      }
    }
  }
}

.company-name-rate {
  @include flexbox();
  @include align-items(baseline);
  @include justify-content(flex-start);
  margin-bottom: 20px;

  > a {
    font-size: 16px;
    margin-right: 5px;
  }

  > p {
    font-weight: bold;
    font-size: 14px;
    color: $primary;
    margin-bottom: 0;
    margin-right: 5px;
  }

  .fa-star {
    font-size: 14px;
    color: #FFDB49;
  }
}

.company-detail-info {
  @include flexbox();
  @include align-items(flex-start);
  @include justify-content(flex-start);

  &__block {
    width: 38%;

    &:nth-child(1) {
      margin-right: 7%;
    }

    p {
      color: $secondary;
      margin-bottom: 0;
    }

    span {
      color: $textPrimary;
    }
  }
}

@media only screen and (max-width: 1199px) {
  .account-employers-btn-link {
    width: 25%;
  }
}

@media only screen and (max-width: 992px) {
  .employer-detail-block,
  .account-employers-nav {
    display: none;
  }
  .employer-information-blocks {
    width: 100%;
  }
  .employer-information-table__header > p,
  .employer-information-table__row > p {
    &:nth-child(1) {
      width: 51%;
    }

    &:nth-child(2) {
      width: 26%;
    }

    &:nth-child(3) {
      width: 23%;
    }
  }
  .company-name-rate {
    margin-bottom: 12px;
  }
  .company-detail-info {
    @include flex-direction(column);

    &__block {
      width: 100%;

      &:nth-child(1) {
        margin-right: 7%;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .account-page .container {
    padding-left: 0;
    padding-right: 0;
  }
  .account-page .account-employers-header {
    padding-right: 10px;
    padding-left: 10px;
  }

  .employer-information-block {
    margin-bottom: 0;
    border-left: none;
    border-right: none;
    border-top: none;
    padding: 15px 10px 15px;
    position: relative;

    &__empty {
      padding-top: 10px;
    }

    &:before {
      content: '';
      height: calc(100% + 2px);
      width: 5px;
      position: absolute;
      left: -5px;
      top: -1px;
      border-top: 1px solid $UILightGray;
      border-bottom: 1px solid $UILightGray;
    }

    &:after {
      content: '';
      height: calc(100% + 2px);
      width: 5px;
      position: absolute;
      right: -5px;
      top: -1px;
      border-top: 1px solid $UILightGray;
      border-bottom: 1px solid $UILightGray;
    }

    &:nth-child(1) {
      border-top: 0.5px solid $UILightGray;
    }

    &__empty {
      @include flex-direction(column);
      @include align-items(flex-end);

      > div {
        width: 100%;
      }
    }

    &__title {
      font-size: 16px;
      margin-bottom: 10px;
    }

    &__subtitle {
      margin-bottom: 15px;
    }
  }
  .employer-information-table__header {
    @include justify-content(space-between);

    > p {
      font-size: 12px;
      padding-right: 10px;

      &:nth-child(1) {
        width: auto;
      }

      &:nth-child(2) {
        width: auto;
        padding-left: 0;

        span {
          display: inline-block;
        }
      }

      &:nth-child(3) {
        display: none;
      }
    }
  }
  .employer-information-table__row {
    @include justify-content(space-between);

    > p {
      &:nth-child(1) {
        width: 67%;
      }

      &:nth-child(2) {
        width: auto;
        padding-left: 0;

        span {
          display: inline-block;
        }
      }

      &:nth-child(3) {
        display: none;
      }
    }
  }
  .company-name-rate {
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
