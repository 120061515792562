.custom-checkbox {
  cursor: pointer;
  margin-bottom: 0;

  > input {
    display: none;
  }

  i {
    display: inline-block;
    width: 30px;
    height: 15px;
    border-radius: 20px;
    vertical-align: middle;
    transition: .25s .09s;
    position: relative;
    background: $secondary;

    &:after {
      content: " ";
      display: block;
      width: 9px;
      height: 9px;
      top: 3px;
      left: 3px;
      border-radius: 50%;
      background: #fff;
      position: absolute;
      transition: .15s;
    }
  }

  > input:checked + i {
    background: $primary;
  }

  > input:checked + i + span {
    color: #29316b;
  }

  > input:checked + i:after {
    transform: translateX(15px);
  }

  &__sm {
    line-height: 1;

    i {
      width: 20px;
      height: 10px;

      &:after {
        width: 6px;
        height: 6px;
        top: 2px;
        left: 3px;
      }
    }

    > input:checked + i:after {
      transform: translateX(8px);
    }
  }
}

.custom-checkbox-wrapper {
  position: relative;
  display: inline-block;

  .custom-checkbox-wrapper__input {
    display: none;

    ~ .custom-checkbox-wrapper__b {
      display: inline-block;
      position: relative;
      background-color: #ffffff;
      border: 1px solid #D5D5D5;
      font-size: 20px;
      color: #fff;
      cursor: pointer;
      line-height: 1;
      outline: 0;
      text-align: center;
      vertical-align: middle;
      height: 20px;
      width: 20px;
    }

    &:checked {
      ~ .custom-checkbox-wrapper__b {
        background-color: $primary;
        border: 1px solid $primary;

        &:after {
          content: '';
          position: absolute;
          top: 3px;
          left: 3px;
          display: block;
          width: 11px;
          height: 11px;
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
    }

    &:disabled {
      ~ .custom-checkbox-wrapper__b {
        background-color: $disabledPrimary;
        border-color: $disabledPrimary;
      }
    }
  }
}

.custom-checkbox {
  &-input-label {
    @include flexbox();
    @include align-items(baseline);
  }

  &-label {
    margin-left: 10px;
    margin-bottom: 0;

    &__small {
      font-size: 13px;
      font-weight: 400;
    }
  }
}

@media only screen and (max-width: 991px) {
  .custom-checkbox {
    &__sm {
      line-height: 1;

      i {
        width: 30px;
        height: 15px;

        &:after {
          width: 9px;
          height: 9px;
          top: 3px;
          left: 3px;
        }
      }

      > input:checked + i:after {
        transform: translateX(15px);
      }
    }
  }
}
