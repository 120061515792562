.callout {
  padding: 1.25rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  border-left: 1px solid #eee;
  border-left-width: .25rem;

  &-warning {
    border-left-color: $warning;
  }

  &-success {
    border-left-color: $success;
  }

  &-info {
    border-left-color: $info;
  }

  &-danger {
    border-left-color: $danger;
  }

}