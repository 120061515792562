.block-invoices {
	.form-response-vacancy {
		input:nth-child(1) {
			width: calc(34% - 15px);
		}
		input:nth-child(2) {
			width: calc(24% - 15px);
		}
		input:nth-child(3) {
			width: calc(20% - 15px);
		}
	}
	.col1 {
		width: 12.3%;
		p {
			text-align: center;
		}
	}
	.col2 {
		width: 53.7%;
		@include flexbox();
		@include align-items(flex-start);
		@include justify-content(center);
		i {
			font-size: 16px;
			margin-right: 5px;
		}
		.fa-times-circle {
			color: $danger;
		}
		.fa-check-circle {
			color: #00B955;
		}
		.fa-dollar-sign {
			color: $primary;
		}
		.fa-sync-alt {
			color: #EFAD08;
		}
		p {
			width: 67%;
		}
	}
	.col4 {
		width: 11.3%;
	}
}

@media only screen and (max-width: 1199px) {
	.block-invoices .form-response-vacancy {
		input:nth-child(1) {
			width: calc(20% - 7.5px);
		}
		input:nth-child(2) {
			width: calc(36% - 7.5px);
		}
		input:nth-child(3) {
			width: calc(22% - 7.5px);
		}
	}
}

@media only screen and (max-width: 991px) {
	.block-invoices .form-response-vacancy {
		input:nth-child(2) {
			width: calc(32% - 7.5px);
		}
		input:nth-child(3) {
			width: calc(26% - 7.5px);
		}
	}
}

@media only screen and (max-width: 767px) {
	.block-invoices {
		.form-response-vacancy-show {
			@include flex-direction(column);
			input:nth-child(1),
			input:nth-child(2),
			input:nth-child(3) {
				width: 100%;
			}
			> div {
				width: 100%;
			}
		}
		.col1 {
			width: 50%;
			order: 1;
			p {
				text-align: left;
			}
		}
		.col2 {
			width: 100%;
			order: 3;
			@include justify-content(flex-start);
			margin-top: 5px;
			p {
				width: calc(100% - 31px);
			}
		}
		.col3 {
			width: 50%;
			order: 2;
			text-align: right;
		}
		.col4 {
			order: 4;
			width: 100%;
			display: block;
			text-align: right;
			.btn-vacancy-toggle {
				margin-right: 0;
			}
		}
		.table-response-vacancy-row {
			padding-bottom: 0;
		}
	}
}