.jumbotron {
  height: 100vh;
  border-radius: 0;
  padding-bottom: 0;
  margin-bottom: 0;
  background: $primary;

  &.employers-bg {
    background: rgb(72, 168, 250);
    background: -o-linear-gradient(23deg, rgba(72, 168, 250, 1) 0%, rgba(27, 124, 209, 1) 26%, rgba(224, 128, 231, 1) 70%, rgba(159, 115, 226, 1) 100%);
    background: linear-gradient(67deg, rgba(72, 168, 250, 1) 0%, rgba(27, 124, 209, 1) 26%, rgba(224, 128, 231, 1) 70%, rgba(159, 115, 226, 1) 100%);
  }

  //background: rgb(72,168,250);
  //background: linear-gradient(67deg, rgba(72,168,250,1) 0%, rgba(27,124,209,1) 26%, rgba(224,128,231,1) 70%, rgba(159,115,226,1) 100%);

  /*
  position: relative;
  &:before{
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 511px;
      height: 107px;
      background: url("/images/newYear/snow1.svg") no-repeat;
      background-size: cover;
  }
  &:after{
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      width: 342px;
      height: 98px;
      background: url("/images/newYear/snow2.svg") no-repeat;
      background-size: cover;
  }
  */

  @include media-breakpoint-up(md) {
    height: 288px;
  }

  @include media-breakpoint-only(sm) {
    height: 450px;
  }

  h1 {
    color: #b9ebfe;
    font-weight: 800;
    font-size: 24px;
    /*position: relative;
    display: inline-block;
    &:before{
        content: '';
        position: absolute;
        left: -38px;
        top: -42px;
        width: 122px;
        height: 46px;
        background: url("/images/newYear/santa.svg") no-repeat;
        background-size: cover;
    }*/
  }

  input {
    margin-bottom: 1rem;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }

  .choices__input {
    margin-bottom: 0;
  }

  .tab-content {
    border-top: none;
    padding: 40px 0 0;
    /*

    z-index: 1;
    position: relative;

    */
  }

  .choices {
    margin-bottom: 0;
  }

  .main-search {
    margin-top: 0 !important;
  }

  .search-tabs {
    display: none;
  }

  &-form {
    &-row {
      padding: 0 15px;
      @include justify-content(space-between);
    }
    &-field {
      width: 40%;
      .choices__input::-webkit-input-placeholder {
        color: #8B9192;
      }
      .choices__input::-moz-placeholder {
        color: #8B9192;
      }
      .choices__input:-ms-input-placeholder {
        color: #8B9192;
      }
      .choices__input:-moz-placeholder {
        color: #8B9192;
      }
    }
    &-btn {
      width: 16.6%;
      &-icon {
        position: relative;
        &:before {
          content: '';
          background: url("/images/menu/search-icon.svg") no-repeat;
          background-size: cover;
          height: 17px;
          width: 17px;
          position: absolute;
          top: 50%;
          left: 1.5vw;
          @include translate(0, -50%)
        }
      }
    }
  }

}

.nav-pills .nav-item {
  padding: 0 !important;
  //font-size: 12px;
}

/*@media only screen and (min-width: 1025px) and (max-height: 900px) {
    .jumbotron {
        &:before {
            width: 390px;
            height: 81px;
        }
        &:after {
            width: 290px;
            height: 80px;
        }
    }
}

@media only screen and (min-width: 1025px) and (max-height: 800px) {
    .jumbotron {
        &:before {
            width: 270px;
            height: 51px;
        }
    }
}

@media only screen and (max-width: 991px) {
    .jumbotron {
        &:before,
        &:after{
            display: none;
        }
    }
}*/

@media only screen and (max-width: 1023px) {
  .jumbotron-form-btn {
    .btn {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
@media only screen and (max-width: 992px) {
  .jumbotron {
    &-form{
      &-field {
        width:36%;
      }
      &-btn {
        width: 23%;
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .jumbotron {
    height: auto;
    padding: 70px 0 0;

    .container {
      padding: 0;
    }

    h1 {
      font-size: 20px;

      /*            &:before {
                      display: none;
                  }*/

    }

    .btn.btn-success {
      margin-top: 16px;
    }
    .tab-content {
      padding: 30px 0 20px;
    }
    &-form{
      &-row {
        padding: 0;
        margin: auto;
        @include flex-direction(column);
        @include align-items(center);
      }
      &-btn,
      &-field {
        width: calc(100% - 20px);
      }
      &-btn {
        &-icon {
          &:before {
            left: 26vw;
          }
        }
      }
    }

  }
  .nav-pills .nav-item {
    padding: 22px 0 0 !important;
  }
}
