.company-page {
  &-header {
    @include flexbox();
    @include align-items(flex-start);
    @include justify-content(space-between);
    margin-bottom: 20px;

    > div {
      @include flexbox();
      @include align-items(center);
      width: 100%
    }
  }

  .logo-company {
    margin-left: 75px;
    margin-right: 100px;
    max-width: 120px;
    width: 120px;
    height: 120px;
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);

    img {
      max-width: 100%;
      width: auto;
      height: auto;
    }
  }

  .vacancies-list-item {
    padding: 14px 44px 16px 40px;
  }

  .vacancy-title {
    font-size: 14px;
  }

  .for-disk {
    margin-left: 6px;
  }

  .show-comments {
    color: $primary;
  }

  .vote-wrap {
    @include flexbox();
    @include align-items(center);
    position: absolute;
    right: 30px;
  }

  .block-padding {
    padding: 0 30px;
  }

  .review-footer {
    margin-bottom: 0;
    font-size: 12px;
    color: $secondary;
  }

  .vacancy-description p {
    font-size: 14px;
  }

  .comment-list-wrap {
    width: 100%;
    @include flex-direction(column);
    padding-top: 30px;
  }

  .toggle-comments {
    position: absolute;
    top: 0;
    right: 301px;
    cursor: pointer;
  }
}

.company-desk-tabs {
  .nav-tabs {
    border-bottom: none;
    position: relative;
    margin-bottom: 30px;

    &:before {
      content: '';
      width: 100%;
      height: 1px;
      background-color: $primary;
      position: absolute;
      left: 0;
      bottom: .5px;
      z-index: -1;
    }

    .nav-link {
      padding: 0 10px 5px;
      margin-right: 30px;
    }

    .nav-link.active, .nav-item.show .nav-link {
      color: $primary;
    }
  }

  .tab-content {
    border-top: none;
  }

  #tabsAboutCompany {
    @include flexbox();
    @include align-items(flex-start);
    @include justify-content(space-between);

    > div {
      background: #FBFAFA;

      &:nth-child(1) {
        padding: 16px;
        width: 57%;
      }

      &:nth-child(2) {
        padding: 16px 55px 16px 20px;
        width: 40%;
      }
    }
  }
}

.offers-list-navbar-container {
  padding-left: 0 !important;

  & div.choices {
    margin-bottom: 0 !important;
  }
}

.company-description-block {
  @include flexbox();
  @include align-items(flex-start);
  @include justify-content(space-between);
  width: calc(100% - 295px);

  > div {
    width: 70%;
  }

  .company-name {
    font-size: 18px;
    font-weight: 500;
    color: #14669B;
    margin-bottom: 10px;
  }

  .verified-block {
    width: 27px;
    height: auto;
    margin-left: 3px;
  }

  .block-rating {
    font-size: 14px;
    color: #707070;
    font-weight: 400;
    margin-bottom: 6px;
    @include flexbox();
    @include align-items(center);

    p {
      margin-bottom: 0;
    }

    span {
      color: #2581BC;
      margin-right: 10px;
    }

    .fa-star {
      color: $textForDarkElements;
      font-size: 6px;
      padding: 3px;
      background-color: #2581BC;
    }
  }

  .company-description-text {
    color: #707070;
    margin-bottom: 6px;

    span {
      color: $textPrimary;
    }
  }
}

.block-for-employer {
  background-color: #F5FBFF;
  padding: 30px 35px;
  margin-bottom: 45px;
  @include flexbox();
  @include align-items(center);
  @include justify-content(space-between);

  p {
    font-size: 20px;
    font-weight: 600;
    color: #60A9E9;
    margin-bottom: 0;
    width: calc(100% - 175px);
  }

  button {
    width: 167px;
  }
}

.about-company-characteristics {
  padding: 0;
  max-width: 483px;
  width: 100%;
  margin-bottom: 19px;

  > li {
    margin-bottom: 5px;
    @include flexbox();
    @include align-items(center);
    @include justify-content(space-between);

    &:nth-last-child(1) {
      margin-bottom: 0;
    }

    > div {
      @include flexbox();
      @include align-items(center);
      width: 36%;
    }
  }

  p {
    margin-bottom: 0;
    color: #707070;
    width: 64%;
  }

  .rating-indicator {
    font-size: 12px;
    color: #5E5E5E;
  }

  .rating-list-icons {
    padding: 0;
    @include flexbox();
    @include align-items(center);
    margin: 0 5px 0 12px;

    li {
      margin-right: 5px;
      background-color: #D5D5D5;
      color: $textForDarkElements;
      width: 17px;
      height: 17px;
      font-size: 13px;
      @include flexbox();
      @include align-items(center);
      @include justify-content(center);
    }

    .active-icon {
      background-color: #2581BC;
    }
  }

  &-subtitle {
    font-size: 16px;
    margin-bottom: 10px;
    color: $textPrimary;
    //font-weight: 500;
  }
}

.block-recommendation {
  max-width: 483px;
  width: 100%;
  margin-bottom: 0;
  @include flexbox();
  @include align-items(center);
  @include justify-content(space-between);

  p {
    margin-bottom: 0;

    &:nth-child(2) {
      width: 36%;
      padding-left: 12px;
    }
  }
}

.red {
  color: #D80614;
}

.green {
  color: #0CAA41;
}

.dropdown-title-about {
  cursor: pointer;

  i {
    @include transform(rotate(-90deg));
    margin-left: 15px;
  }

  &.active-el {
    i {
      @include transform(rotate(90deg));
    }
  }
}

.last-item {
  margin-bottom: 0;
}

.photo-gallery-about-us {
  margin-bottom: 35px;
  padding: 0;
  @include flexbox();
  @include flex-wrap(wrap);

  li {
    width: 24%;
    margin-bottom: 1%;
    margin-right: 1%;
  }
}

.video-about-company {
  margin: auto;
  display: block;
  width: 560px;
  height: 315px;
}

.form-company-page-vacancy {
  padding-top: 0;
  margin-bottom: 30px;
  @include flexbox();
  @include align-items(center);
  @include justify-content(space-between);

  .btn-info {
    background-color: #5BC0DE;
    border-color: #5BC0DE;

    &:hover {
      background-color: #1b9bc1;
      border-color: #1b9bc1;
    }
  }

  .form-control {
    width: 100%;
    color: #000000;
  }

  .offers-list-navbar-container {
    width: 45%;
  }
}

.list-reviews-сompany {
  padding: 35px 0 0;
}

.reviews-company-item-header {
  background-color: #ECF5FC;
  border: 1px solid #E6F3FF;
  border-bottom: none;
  margin-bottom: 30px;

  p {
    margin-bottom: 0;
  }

  > div {
    border-bottom: 1px solid #E6F3FF;
    padding: 11px 30px 13px;
    @include flexbox();
    @include align-items(center);
    @include justify-content(space-between);
  }

  .dropdown-reviews-company {
    display: none;
  }

  .rating-date {
    @include flexbox();
  }

  .rating-list-icons {
    padding: 0;
    @include flexbox();
    @include align-items(center);
    margin: 0 20px 0 8px;

    li {
      margin-right: 5px;
      background-color: #D5D5D5;
      color: $textForDarkElements;
      width: 17px;
      height: 17px;
      font-size: 13px;
      @include flexbox();
      @include align-items(center);
      @include justify-content(center);
    }

    .active-icon {
      background-color: #14669B;
    }
  }

  .about-company-characteristics {
    padding: 0;
    margin-bottom: 0;
  }

  &.active-el {
    .fa-angle-left {
      @include transform(rotate(90deg));
    }

    .dropdown-reviews-company {
      display: flex;
      margin-bottom: 20px;
      padding-top: 20px;
      padding-bottom: 30px;
    }
  }

  .review-name-mob {
    display: none;
  }
}

.review-rating-detail {
  color: #14669B;
  margin-right: 20px;
  cursor: pointer;

  i {
    margin-left: 15px;
    @include transform(rotate(-90deg));
  }
}

.review-company-page {
  margin-bottom: 20px;
  position: relative;
}

.review-good {
  padding: 10px 0 10px 20px;

  &:before {
    content: '';
    height: 100%;
    width: 3px;
    background-color: #0CAA41;
    position: absolute;
    left: 0;
    top: 0;
  }
}

.review-bed {
  padding: 0 0 20px 20px;

  &:before {
    content: '';
    height: 100%;
    width: 3px;
    background-color: #D80614;
    position: absolute;
    left: 0;
    top: 0;
  }
}

.reviews-сompany-item-footer {
  &.footer-active {
    .reviews-footer-bottom {
      display: inline-block;
      background-color: #ECF5FC;
    }
  }

  .reviews-footer-top {
    margin-bottom: 30px;
    padding: 0 30px;
    @include flexbox();
    @include align-items(baseline);
    @include justify-content(space-between);
    position: relative;

    p {
      margin-bottom: 0;
    }

    ul {
      padding: 0;
      margin-left: 15px;
      @include flexbox();
      @include align-items(center);
      @include justify-content(center);

      li {
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);
        padding: 0;
        color: #0CAA41;

        &:nth-child(1) {
          padding-right: 10px;
        }

        &:nth-last-child(1) {
          padding-left: 10px;
        }

        i {
          margin-right: 10px;
        }
      }

      li:nth-last-child(1) {
        border-left: 1px solid #707070;
        color: #D80614;

        i {
          margin-right: 10px;
        }
      }
    }

    > div {
      @include flexbox();
      @include align-items(center);
      @include justify-content(center);
    }
  }

  .reviews-footer-bottom {
    width: 100%;
    padding-bottom: 30px;
    display: none;

    textarea {
      resize: none;
      width: 100%;
      height: 160px;
      border: 1px solid #D5D5D5;
      margin-bottom: 15px;
      padding: 13px 16px;
    }
  }

  .toggle-comments {
    .toggle-comments-icon {
      display: none;
    }
  }
}

.btn-leave-comment {
  cursor: pointer;
  position: relative;
  padding-left: 30px;
  margin: 0;
  color: $primary;
  position: absolute;
  left: 30px;

  &:before {
    content: '';
    width: 20px;
    height: 20px;
    background: url(/images/company/icon-reviews.svg) no-repeat;
    position: absolute;
    left: 0;
    top: 50%;
    @include translate(0, -50%);
  }

  .show-comments-icon {
    display: none;
  }
}

.block-more-reviews {
  padding: 0 20px 0 30px;

  p {
    margin: 20px 0 0;
  }

  p:nth-child(1) {
    text-align: right;
  }

  p:nth-last-child(1) {
    text-align: left;
  }

  > div {
    margin: 35px 0 20px;
    width: 100%;
    text-align: right;
  }
}

.block-reviews-media {
  padding: 0;
  @include flexbox();
  @include align-items(center);
  @include flex-wrap(wrap);

  li {
    width: calc(16.6% - 12.5px);
    margin-right: 15px;
    margin-bottom: 15px;
    max-height: 93px;
  }

  li:nth-child(6n + 6) {
    margin-right: 0;
  }

  iframe {
    width: 100%;
    height: auto;
    max-height: 93px;
  }
}

.company-banner {
  width: 100%;
  height: auto;
  margin-top: 75px;
}

@media only screen and (max-width: 991px) {
  .block-for-employer p {
    font-size: 12px;
  }
  .reviews-footer-top {
    @include flex-direction(column);
    @include align-items(flex-end);
    width: 100%;

    .toggle-comments {
      margin: 0 !important;
      right: 30px;

      span {
        display: none;
      }

      .toggle-comments-icon {
        display: inline-block;
        color: $primary;
        font-size: 15px;
        transform: scale(-1, 1);
      }
    }
  }
  .reviews-сompany-item-footer .reviews-footer-top {
    .vote-wrap {
      @include justify-content(space-between);
      width: 100%;
      margin-top: 18px;
      position: relative;
      right: 0;

      p {
        font-size: 12px;
        text-align: left;
        color: $secondary;
      }
    }

    ul li {
      &:nth-last-child(1) i {
        margin-right: 4px;
      }

      i {
        margin-right: 3px;
      }
    }

    > div > div {
      .no-comments-title {
        color: #707070;
        padding-top: 16px;
      }
    }
  }
  .reviews-сompany-item-footer.footer-active .reviews-footer-bottom > div {
    @include flexbox();
    @include align-items(center);
    @include flex-direction(column);

    button {
      margin: 0 auto 10px !important;
    }
  }
  .company-page {
    .logo-company {
      margin-left: 0;
      margin-right: 28px;
    }

    .btn-leave-comment {
      padding-left: 0;

      &:before,
      span {
        display: none;
      }

      .show-comments-icon {
        display: inline-block;
        font-size: 14px;
        color: $primary;
      }
    }

    .comment-list-wrap {
      padding-top: 17px;
    }
  }
  .company-description-block {
    @include flex-direction(column);
    width: 100%;

    .block-rating {
      margin-bottom: 0;
    }
  }
  .company-desk-tabs {
    #tabsAboutCompany {
      @include flex-direction(column);

      > div {
        &:nth-child(1),
        &:nth-child(2) {
          padding: 16px 36px 16px 16px;
          width: 100%;
          margin-bottom: 10px;
        }
      }
    }

    .nav-tabs {
      margin-bottom: 10px;
    }
  }
  .company-banner {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .block-for-employer {
    @include flex-direction(column);
    @include align-items(center);

    p {
      width: 100%;
    }

    button {
      margin: 10px auto 0;
    }
  }
  .about-company-characteristics .rating-list-icons {
    margin: 0 5px;
  }
  .video-about-company {
    max-width: 100%;
    height: 200px;
  }
  .photo-gallery-about-us li {
    width: 32%;
  }
  .reviews-company-item-header {
    margin-bottom: 20px;

    > div {
      @include flex-direction(column);
      padding: 8px 10px 11px;
    }

    .review-name-desc {
      display: none;
    }

    .rating-date {
      @include flex-wrap(wrap);
      @include justify-content(space-between);
      width: 100%;

      p {
        width: 50%;
        text-align: center;
      }

      .rating-indicator {
        display: none;
      }

      .review-name-mob {
        display: inline-block;
        order: 1;
        text-align: left;
      }

      .review-date {
        order: 2;
        text-align: right;
        margin-bottom: 5px;
      }
    }

    .review-rating-detail {
      margin: 0;
      order: 4;

      i {
        display: none;
      }
    }

    .rating-list-icons {
      order: 3;
      margin: 0;
    }
  }
  .reviews-footer-top {
    .toggle-comments {
      right: 10px;
    }
  }
  .block-reviews-media li {
    width: 30%;
    height: 75px;

    img {
      max-height: 100%;
    }

    iframe {
      max-height: 70px;
    }
  }
  .company-page {
    &-header {
      @include flex-direction(column);
      @include align-items(center);

      > div {
        width: 100%;
      }
    }

    .vacancy-description p {
      font-size: 12px;
    }

    .block-padding {
      padding: 0 10px;
    }

    .btn-leave-comment {
      left: 10px;
    }
  }
  .reviews-сompany-item-header .rating-list-icons {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .reviews-сompany-item-footer .reviews-footer-top {
    padding: 0 10px;

    ul {
      margin-left: 10px;
    }
  }
  .form-company-page-vacancy {
    @include flex-direction(column);

    .form-control {
      margin-bottom: 10px;
      width: 100%;
      max-width: 800px;
    }

    .btn-info {
      width: 100%;
    }
  }
  .company-description-block .company-name {
    margin-bottom: 5px;
  }
  .company-banner {
    margin-top: 8px;
  }
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .company-page {
    &-header {
      > div {
        @include flex-direction(column);
      }
    }

    .logo-company {
      margin: 0 auto 12px;
    }

    .vacancies-list-item {
      padding: 10px;
      @include flex-direction(column);
      @include align-items(flex-start);

      > div {
        order: 2;
        width: 100%;
        font-size: 12px;
      }
    }

    .vacancies-item-date {
      font-size: 12px;
      order: 1;
      margin-bottom: 3px;
    }

    .vacancy-title {
      margin-bottom: 5px;
    }

    .vacancy-description {
      @include flex-direction(row);
    }
  }
  .company-description-block {
    width: 100%;

    .btn-primary {
      margin: 20px auto 0;
    }

    > div {
      width: 100%;
    }

    .company-description-text {
      margin-bottom: 0;
    }
  }
  .block-for-employer {
    padding: 15px 10px;
  }
  .company-desk-tabs {
    .nav-tabs {
      @include justify-content(space-between);
      //padding: 0 10px;
      //margin-bottom: 8px;
      margin: 0 10px 8px;

      .nav-link {
        padding: 0;
        margin-right: 0;
      }

      /*      .nav-item {
              margin-right: 20px;
            }*/
    }

    #tabsAboutCompany {
      > div {
        &:nth-child(1),
        &:nth-child(2) {
          padding: 10px 11px;
        }
      }
    }
  }
  .block-recommendation,
  .form-company-page-vacancy {
    @include flex-direction(column);
    @include align-items(center);

    p {
      width: 100%;
      color: $textPrimary;
      margin-bottom: 10px;

      &:nth-child(2) {
        width: 100%;
        padding-left: 0;
      }
    }
  }
  .form-company-page-vacancy {
    padding-top: 20px;
    margin-bottom: 20px;

    > div,
    .offers-list-navbar-container {
      width: 100%;
      margin-bottom: 10px;
    }

    p:nth-last-child(1) {
      text-align: center;
    }

    .form-control {
      margin-bottom: 0;
    }
  }
  .about-company-characteristics {
    > li {
      @include flex-direction(column);
      @include align-items(flex-start);
      margin-bottom: 15px;

      > div {
        width: 100%;
      }

      p {
        width: 100%;
        margin-bottom: 10px;
      }
    }

    .rating-indicator {
      width: 100%;
      font-size: 14px;
      color: $secondary;
      margin-bottom: 0;
    }

    .rating-list-icons {
      margin: 0 13px 0 0;
    }
  }
}

@media only screen and (max-width: 600px) and (orientation: portrait) {
  .photo-gallery-about-us li {
    width: 49%;
  }
  .block-reviews-media li {
    width: 44%;
  }
}