.sign-form {
  &.registration {
    input.radio-button {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      margin: 0;
      cursor: pointer;
    }

    .radio-tile-group {
      @include flexbox();
      @include align-items(center);
      @include justify-content(flex-start);
      margin: 15px 0 20px;
      position: relative;

      &:before {
        content: '';
        height: 1px;
        width: 100%;
        background: $primary;
        position: absolute;
        left: 0;
        bottom: 1px;
      }

      .input-container {
        position: relative;
        margin: 0 40px 0 0;

        .radio-tile {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          transition: transform .3s ease;

          label {
            color: $primary !important;
            margin: 0;
            padding: 6px 13px;
            line-height: 38px;
            font-size: 16px;
            font-weight: 400;
            width: 100%;
            text-align: center;
          }
        }
      }
    }

    .radio-tile-group .input-container .radio-button:checked + .radio-tile .radio-tile-label {
      color: $textPrimary !important;
      border-bottom: 4px solid $primary;
    }
  }
}

@media only screen and (max-width: 767px) {
  .sign-form.registration {
    .radio-tile-group {
      margin: 6px 0 11px;
      .input-container {
        margin: 0;
        width: 50%;

        .radio-tile label {
          padding: 6px 13px;
          line-height: 42px;
          font-size: 14px;
        }
      }
    }
  }


}