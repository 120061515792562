.popular-countries-list{
  padding: 0 15px;
  @include flexbox();
  @include flex-wrap(wrap);
  min-width: 50%;
  width: 100%;
  li{
    width: 25%;
  }
  .flag-icon {
    height: 14px;
    width: 19px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    margin-right: 3px;
    background-size: cover;
  }
}

.popular-countries-block{
  padding-top: 35px;
  &-title {
    margin-bottom: 20px;
  }
}

@media (max-width: 991px) {
  .popular-countries-list{
    @include justify-content(right);
    li{
      width: calc(33.3% - 5px);
    }
  }
}

@media (max-width: 767px) {
  .popular-countries-block{
    padding-bottom: 0;
    padding-top: 20px;
    &-title {
      margin-bottom: 18px;
      font-size: 18px;
    }
    .popular-countries-list{
      margin-bottom: 0;
      @include justify-content(space-between);
      li{
        width: calc(50% - 5px);
      }
    }
  }
}
