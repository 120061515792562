.account-employers {
  &-header {
    margin-bottom: 15px;
  }
}

.upload-photo-wrap {
  .account-settings-page {
    @include flexbox();
    @include align-items(center);
    @include justify-content(flex-start);
    padding: 0;

    .user-name {
      font-size: 35px;
      line-height: 1.2;
      font-weight: 500;
    }

    .user-email {
      font-size: 16px;
      line-height: 1;
      color: #333333;
      margin: 10px 0 8px;
      display: none;
    }

    .remove-photo {
      color: $primary;
      cursor: pointer;
      margin-bottom: 0;

      &:hover {
        color: #005e9f;
        text-decoration: underline;
      }
    }

    .form-avatar {
      background-color: #E5E5E5;
      width: 100px;
      height: 100px;
      border: #E5E5E5 1px solid;
      margin-right: 24px;

      &-img {
        max-width: 100%;
        max-height: 100%;

        .no-user-avatar {
          width: 79px;
        }

        .is-user-avatar {
          width: auto;
          height: auto;
          max-height: 50px;
          max-width: 90px;
          position: absolute;
          left: 50%;
          top: 50%;
          @include translate(-50%, -50%);
        }

      }

      &:hover {
        border-color: #E5E5E5;
      }
    }
    .company-name {
      font-size: 16px;
      line-height: 1;
      &:hover {
        text-decoration: none;
        color: $hoverPrimary;
      }
    }
  }
}

.edit-icon {
  position: absolute;
  top: 18px;
  right: 18px;

  &__header {
    display: none;
  }

  .fa-edit {
    font-size: 19px;
    color: $primary;
  }

  a,
  a:hover {
    text-decoration: none;
  }
}

@media only screen and (max-width: 992px) {
  .upload-photo-wrap {
    .form-avatar {
      width: 65px;
      height: 65px;
      margin-right: 12px;

      &-img {
        .no-user-avatar {
          width: 37px;
        }

        .is-user-avatar {
          max-height: 45px;
          max-width: 55px;
        }
      }
    }

    .account-settings-page {
      @include align-items(flex-start);

      .user-name {
        margin-top: 5px;
        font-size: 24px;
      }

      .user-email {
        display: inline-block;
      }
    }
  }
  .account-employers-header {
    margin-bottom: 20px;
    position: relative;

    .upload-photo-wrap {
      max-width: calc(100% - 40px);
    }
  }
  .edit-icon {
    top: 6px;
    right: 0;
    &__header {
      display: inline-block;
    }
    .fa-edit {
      font-size: 21px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .upload-photo-wrap .account-settings-page {
    .user-name {
      font-size: 16px;
      margin-top: 12px;
    }

    .user-email {
      font-size: 14px;
    }
  }
  .account-employers-header {
    margin-bottom: 15px;
  }
  .edit-icon {
    right: 15px;
  }
}