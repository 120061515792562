.home-employers-video{
  width: 76.4vh;
  height: 40vh;
  -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .home-employers-video{
    width: 100%;
    height: 65vw;
  }
}