.block-favorites {
  margin-top: 20px;

  &-title {
    margin-bottom: 20px;
  }

  &-tabs {
    z-index: 1;
    position: relative;

    .btn {
      margin: 0 -2px;
    }
  }

  .table-response-vacancy-body {
    border-top: 1px solid #E0E0E0;

    .col1 {
      width: 90%;
    }

    .col4 {
      text-align: right;
      padding: 0;

      .btn-outline-info,
      .btn-info {
        width: 30px;
        padding: 0;
        line-height: 30px;
      }
    }
  }

  .table-response-vacancy-row {
    margin-bottom: 0;
    border: none;
    border-bottom: 1px solid #E0E0E0;
    padding: 15px 25px 15px 40px;
  }
}

.no-favorites {
  @include flexbox();
  @include align-items(center);
  @include justify-content(center);
  margin-top: -42px;

  &__img {
    height: 342px;
    width: auto;
  }

  &__text {
    margin-bottom: 6px;
  }
}

@media only screen and (max-width: 991px) {
  .block-favorites {
    margin-top: 15px;

    &-title {
      font-size: 16px;
    }

    .table-response-vacancy-row {
      padding: 15px 14px 15px 27px;
    }
  }

  .no-favorites {
    margin-top: -61px;

    &__img {
      height: 276px;
    }

    &__text {
      margin-bottom: 5px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .block-favorites {

    .table-response-vacancy-row {
      padding: 10px 16px;
      width: 100%;
      margin-left: 0 !important;
    }

    .table-response-vacancy-body {
      .col4 {
        width: auto;
        top: 50%;
        transform: translate(0%, -50%);
        margin: 0;
        right: 21px;

        .btn-outline-info,
        .btn-info {
          width: 21px;
          line-height: 21px;
          font-size: 12px;
        }
      }
    }
  }

  .no-favorites {
    @include flex-direction(column);
    margin-top: 5px;

    &__img {
      height: 246px;
      margin-top: -50px;
    }
    >div {
      width: 100%;
      padding-right: 10px;
      padding-left: 10px;
    }
  }
}
