.block-job-more-info {
  background-color: #FBFAFA;
  text-align: center;
  padding: 60px 0 55px;
  h2{
    font-weight: 500;
    text-align: center;
    color: #419DE4;
    margin-bottom: 40px;
  }
  a {
    border: 1px solid #14669B;
    border-radius: 3px;
    padding: 7px 25px;
    &:hover{
      text-decoration: none;
    }
  }
}