.autocomplete {
  /*the container must be positioned relative:*/
  position: relative;
  //display: inline-block;


  .autocomplete-items {
    position: absolute;
    border: 1px solid #d4d4d4;
    border-bottom: none;
    border-top: none;
    z-index: 99;
    /*position the autocomplete items to be the same width as the container:*/
    //top: 40px;
    //left: 15px;
    //right: 15px;
    max-height: 15rem;
    overflow: auto;
  }

  .autocomplete-items div {
    padding: 10px;
    cursor: pointer;
    background-color: $textForDarkElements;
    border-bottom: 1px solid #d4d4d4;
  }

  .autocomplete-items div:hover {
    /*when hovering an item:*/
    background-color: #e9e9e9;
  }

  .autocomplete-active {
    /*when navigating through the items using the arrow keys:*/
    background-color: DodgerBlue !important;
    color: $textForDarkElements;
  }

  .fa-spin {
    position: absolute;
    right: 5%;
    top: 32%;
  }

}

.vacancies-filter {
  .autocomplete {
    .fa-spin {
      top: 60%;
    }
  }
}


.offer-manage-form {
  .autocomplete {
    .fa-spin {
      top: 85%;
    }
  }
}
