.block-tariff-information{
	background-color: #2B73B4;
	width: 100%;
	padding: 5px 25px;
	p{
		margin-bottom: 0;
		text-align: left;
		color: $textForDarkElements;
	}
	&-link:hover{
		text-decoration: none;
	}
	&-mob {
		padding: 8px 15px;
		background-color: #FFF77D;
		p{
			color: $textPrimary;
			font-size: 12px;
		}
	}
}

@media only screen and (max-width: 767px) and (orientation: landscape) {
	.block-tariff-information-mob {
		padding: 8px 14px;
	}
}