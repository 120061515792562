.user-media {
	.el-add-media {
		border: 1px dashed #727272;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 80px;
		padding-bottom: 0;
		-webkit-border-radius: 5px;
		-moz-border-radius: 5px;
		border-radius: 3px;
		margin: auto;
		
		&:hover {
			background: #e4e4e4;
			cursor: pointer;
		}
		
		&.with-media {
			border: none;
			
			img {
				height: 100%;
			}
		}
	}
	
	.icon-upload {
		font-size: 24px;
	}
}

.el-switch {
	position: relative;
	display: inline-block;
	margin-top: 16px;
	height: 18px;
	width: 100%;
	padding-left: 42px;
	input {
		display:none;
	}
	
	&-slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #606161;
		-webkit-transition: .4s;
		transition: .4s;
		border-radius: 8px;
		border: #cccccc 1px solid;
		width: 32px;
		&:before {
			position: absolute;
			content: "";
			height: 16px;
			width: 16px;
			left: 0;
			bottom: 0;
			background-color: white;
			-webkit-transition: .4s;
			transition: .4s;
			border-radius: 50%;
		}
	}
}

input:checked + .el-switch-slider {
	background-color: #2581bc;
}

input:focus + .el-switch-slider {
	box-shadow: 0 0 1px #2581bc;
}

input:checked + .el-switch-slider:before {
	-webkit-transform: translateX(14px);
	-ms-transform: translateX(14px);
	transform: translateX(14px);
}

.el-userpic {
	display: flex;
	justify-content: center;
	align-items: center;
	&-img {
		width: 150px;
		height: 150px;
		border-radius: 50%;
		overflow: hidden;
		img {
			width: 100%;
		}
	}
}

.el-rightbutton {
	overflow: hidden;
	.form-group {
		width: 80%;
		float: left;
		input {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
			border-right-color: #5bc0de;
		}
	}
	.btn {
		width: 20%;
		float: left;
		margin-right: 0;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		padding: 7px 12px;
		background-color: #5bc0de;
		border: 1px solid #ccc;
		border-left-color: #5bc0de;
		margin-top: 23px;
	}
}

.el-colors {
	&-item {
		float: left;
		border: #e8e8e8 1px solid;
		border-radius: 3px;
		width: 19px;
		height: 19px;
		margin-right: 2px;
		color: #ffffff;
		text-align: center;
		cursor: pointer;
		i {
			opacity: 0;
		}
		&.__picked {
			i {
				opacity: 1;
			}
		}
		&._color-white {
			background-color: #ffffff;
			color: #e8e8e8;
		}
		&._color-yellow {
			background-color: #ffea00;
			&:hover {
				border-color: #ffea00;
			}
		}
		&._color-red {
			background-color: #ff0000;
			&:hover {
				border-color: #ff0000;
			}
		}
		&._color-green {
			background-color: #00ad5d;
			&:hover {
				border-color: #00ad5d;
			}
		}
		&._color-blue {
			background-color: #2579bf;
			&:hover {
				border-color: #2579bf;
			}
		}
		&._color-black {
			background-color: #000000;
			&:hover {
				border-color: #000000;
			}
		}
		&._color-pick {
			background-color: #e8e8e8;
			color: #000000;
			border-color: #595959;
			i {
				opacity: 1;
			}
		}
	}
}

.el-video {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.el-add-media {
	width: 100%;
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	height: 0;
	overflow: hidden;
	position: relative;
	img {
		width: 100%;
	}
	&-close {
		width: 18px;
		height: 15px;
		position: absolute;
		right: 0;
		top: 0;
		border: #606060 1px solid;
		&:before {
			content: '';
			display: block;
			width: 100%;
			height: 100%;
			background-color: #5E5E5E;
			opacity: .87;
			position: absolute;
		}
		&:after {
			content: '';
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			background-image: url('/front/images/icon-close.png');
			background-position: center;
			background-repeat: no-repeat;
		}
	}
	&-play {
		display: block;
		width: 22px;
		height: 22px;
		background-image: url('/front/images/icon-play-black.png');
		background-size: 100%;
		background-repeat: no-repeat;
		position: absolute;
		left: 50%;
		margin-left: -11px;
		top: 25%;
	}
}

.el-sep {
	width: 100%;
	height: 1px;
	background-color: #D5D5D5;
}

.el-upload-photo {
	display: block;
	width: 115px;
	height: 105px;
	background-image: url('/front/images/icon-camera.png');
	background-repeat: no-repeat;
	background-position: center;
	background-size: 50px;
	padding-top: 85px;
	text-align: center;
}

.el-upload-video {
	display: block;
	width: 115px;
	height: 105px;
	background-image: url('/front/images/icon-play.png');
	background-repeat: no-repeat;
	background-position: center;
	background-size: 50px;
	padding-top: 85px;
	text-align: center;
}

.caret {
	border-top: 6px dashed;
	border-top: 4px solid \9;
	border-right: 3px solid transparent;
	border-left: 3px solid transparent;
}

.captcha {
	.grecaptcha-badge {
		margin: 0 auto;
	}
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
	.el-add-media-play {
		top: 50%;
		width: 44px;
		height: 44px;
		margin-top: -22px;
		margin-left: -22px;
	}
	.el-upload-photo, .el-upload-video {
		height: 50px;
		padding-top: 50px;
		margin: 30px auto 0;
	}
}


@media only screen and (min-width: 320px) and (max-width: 767px) {
	.user-media {
		.el-add-media {
			height: 50px;
			width: 50%;
		}
	}
}