/*
.separator {
	margin-top: 20px;
	margin-bottom: 20px;
	border-bottom: 1px solid #d2d2d1;
}
*/

.step-inactive-color {
	color: #d2d2d1;
}

.step-active-color {
	color: #2581bc;
}

.step-inactive-border {
	border: 2px solid #d2d2d1;
}

.step-active-border {
	border: 2px solid #2581bc;
}

.step-icon-container {
	padding-left: 11px;
	padding-top: 11px;
	width: 5em;
	height: 5em;
	line-height: 3;
	border-radius: 50%;
	background: #fff;
	float: left;
}

.steps-container {
	
	margin-left: 2em;
}

.step {
	margin-left: -3.7em;
	padding-left: 1em;
}

.step-last {
	margin-left: -3.7em;
	padding-left: 19px;
}

.step-wrapper {
	border-left: 4px dotted #d2d2d1;
}

.step-content h2 {
	margin: 0 0 0 16px;
	line-height: 2.2em;
	text-transform: uppercase;
	border-bottom: 1px solid #d2d2d1;
}

.step-content {
	padding-left: 6em;
	padding-top: 1em;
}

.step-icon {
	font-size:28px;
	margin-left: 8px;
	margin-top: 5px;
	
}

.step-wrapper:nth-child(2) .step-icon {
	margin-top: 9px;
	margin-left: 7px;
	font-size: 25px;
}

.step-header {
	font-weight: bold;
	text-transform: uppercase;
	font-size: 15px;
	border-bottom: 1px solid #D2D2D1;
}

.pricing-type {
	list-style-position: inside;
	padding: 0;
	list-style-type: square;
	li {
		margin-bottom: 20px;
	}
}

#pricing-params {
	width: 100%;
}

#pricing-days {
	width: 70px;
}

#price-value {
	width: 90px;
	text-align: center;
	padding: 7px;
	border: 1px solid #d2d2d1;
	border-radius: 3px;
	color: #409C09;
	font-weight: bold;
	background-color: #DFF0D8;
}

#preview-button {
	text-align: center;
	padding: 8px;
	border-radius: 4px;
	border: 1px solid #d2d2d1;
}

.star-rating{
	font-size: 0;
}

.star-rating__wrap{
	display: flex;
	font-size: 1rem;
	border: 1px solid #d2d2d1;
	border-radius: 3px;
	padding: 8px 0 0;
	width: 118px;
	display: flex;
	justify-content: center;
	flex-direction: row-reverse;
}

.star-rating__wrap:after{
	content: "";
	display: table;
	clear: both;
}

.star-rating__ico{
	float: right;
	padding-left: 2px;
	cursor: pointer;
	color: #FFB300;
}

.star-rating__ico:last-child{
	padding-left: 0;
}

.star-rating__input{
	display: none;
}

.star-rating__ico:hover:before,
.star-rating__ico:hover ~ .star-rating__ico:before,
.star-rating__input:checked ~ .star-rating__ico:before
{
	content: "\f005";
	font-weight: 900;
}

#label-star-rating-1, #label-star-rating-2,
#label-star-rating-3, #label-star-rating-4,
#label-star-rating-5 {
	font-size: 18px;
}

.offers-list-rating {
	color: #FFC80E;
	font-size: 10px;
}

.required-field-label {
	margin-bottom: 3px;
}

#paymentType {
	.insufficient {
		display: none;
		color: #B91912;
		font-size: 11px;
		margin-left: 6px;
		
		i.fa {
			color: #B91912;
			font-size: 11px;
		}
	}
}

.payOnBehalf {
	display: none;
}

.invoiceFields {
	display: none;
	clear: both;
	
	input[name=zip] {
		margin-bottom: 18px;
	}
}

.custom-pricing {
	&-pic {
		width: 100%;
		height: 170px;
		background: url('/front/images/pricing-form.png') center no-repeat;
		position: relative;
		padding-top: 30px;
	}
	&-wantmore {
		font-size: 16px;
		font-weight: 600;
		color: #ffffff;
		background: #116da8;
		padding: 4px 14px;
	}
	&-text {
		color: #2581bc;
		font-size: 20px;
	}
}