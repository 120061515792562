.you-post-we-promote {
  &-wrap {
    @include flexbox();
    @include align-items(flex-start);
    @include justify-content(space-between);
    padding: 0;
    margin-bottom: 0;
  }

  &-item {
    background-color: #F6F6F6;
    padding: 2.7vh 2.3vh;
    width: calc(33.3% - 16px);
    @include flexbox();
    @include align-items(center);
    @include flex-direction(column);

    &__img {
      width: 11.3vh;
      height: 11.3vh;
      border-radius: 50%;
      background-color: #fff;
      @include flexbox();
      @include align-items(center);
      @include justify-content(center);
      margin-bottom: 2.6vh;

      i {
        color: #008BEB;
        font-size: 6.2vh;
      }
    }

    &__title {
      text-transform: uppercase;
      margin-bottom: 1.1vh;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
    }

    &__text {
      text-align: center;
      min-height: 5.8vh;
      margin-bottom: 0;
    }
  }
}

@media screen and (orientation: landscape) and (min-width: 160vh) {
  .you-post-we-promote-item__text {
    min-height: 8vh;
  }
}

@media screen and (orientation: landscape) and (min-width: 175vh) and (max-width: 991px) {
  .you-post-we-promote-item {
    &__text {
      min-height: 23vh;
      font-size: 13px;
    }

    &__title {
      min-height: 13vh;
    }
  }
}

@media screen and (orientation: landscape) and (min-width: 200vh) {
  .you-post-we-promote-item__text {
    min-height: 10vh;
  }
}

@media screen and (orientation: landscape) and (min-width: 216vh) {
  .you-post-we-promote-item {
    &__text {
      min-height: 23vh;
    }

    &__title {
      min-height: 13vh;
    }
  }
}

@media screen and (orientation: landscape) and (max-width: 150vh) {
  .you-post-we-promote {
    &-wrap {
      @include flex-direction(column);
      @include align-items(center);
    }

    &-item {
      padding: 2.7vh 2.3vh 5vh;
      width: calc(64% - 16px);
      margin-bottom: 4vh;
    }
  }
}

@media only screen and (min-width: 768px) and (orientation: portrait) {
  .you-post-we-promote-item {
    padding: 2.7vh 1vh;

    &__img {
      width: 8.3vh;
      height: 8.3vh;
      i {
        font-size: 4.2vh;
      }
    }

    &__title {
      font-size: 13px;
    }
    &__text {
      font-size: 12px;
      min-height: 6.8vh;
    }
  }
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .you-post-we-promote {
    &-wrap {
      @include flex-direction(column);
      @include align-items(center);
    }

    &-item {
      padding: 6.9vw 2px 12.7vw;
      width: 77.7vw;
      margin-bottom: 5.6vw;

      &__img {
        width: 25vw;
        height: 25vw;
        margin-bottom: 8vw;

        i {
          font-size: 14.4vw;
        }
      }

      &__title {
        margin-bottom: 3.3vw;
        font-size: 4.2vw;
      }

      &__text {
        min-height: auto;
        font-size: 3.9vw;
      }
    }
  }
}
