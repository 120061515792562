<template>
	<div>
<!--		<span v-if="notifications.length > 0" class="notify material-icons animate" v-on:click="toggleList">notifications_active</span>-->
<!--		<span v-else class="notify material-icons"  v-on:click="toggleList">notifications</span>-->
		
		<div v-on:click="toggleList">
			<i class="fas fa-bell" v-bind:class="[notifications.length > 0 ? 'animate' : '']"></i>
		</div>
		
		<div v-if="notifications.length > 0" class="notifications-icon rounded-circle"></div>
		
		<div class="notification-block-list" v-bind:class="dropDownsClasses">
			
			<ul>
				<li class="notification-item" v-for="message in notifications">
					<i class="fas fa-info-circle info-icon text-info notification-type-icon"> </i>
					<p v-on:click="notificationClick(message.id, message.url)" v-bind:class="[message.url ? 'link text-primary' : '']">
						{{ message.message }}
					</p>
					<i class="fas fa-times mark-as-read" v-on:click="markAsRead(message.id)"> </i>
				</li>
			</ul>
			<div class="btn-close-all">
				<a href="#" v-on:click="markAllAsRead">{{ markAllReadText }}</a>
			</div>
			
			<p class="no-notification-text" v-if="notifications.length <= 0">
				{{ noItemsText }}
			</p>
		</div>
	</div>
</template>

<script>
	import * as axios from 'axios';
	
	export default {
		name: "Notifications",
		props: {
			noItemsText: String,
			markAllReadText: String,
		},
		data() {
			return {
				notifications: [],
				toggleDropdown: false,
				timer: null,
			}
		},
		computed: {
			dropDownsClasses: function () {
				let self = this;
				
				return {
					'd-none': !self.toggleDropdown,
					'no-notification': self.notifications.length <= 0
				}
			},
		},
		methods: {
			fetchNotifications: function () {
				
				console.log('Fetching...');
				
				let self = this;
				
				axios.get('/api/messages/user/5')
					.then(function (response) {
						self.notifications = response.data.splice(0, 10);
					})
			},
			cancelAutoUpdate: function () {
				clearInterval(this.timer)
			},
			markAsRead: function (id) {
				let self = this;
				
				axios.get('/api/messages/user/' + id + '/mark-as-read')
					.then(function (response) {
						self.fetchNotifications();
					})
			},
			markAllAsRead: function () {
				let self = this;
				
				axios.get('/api/messages/user/mark-all-read')
					.then(function(response) {
						self.fetchNotifications();
					})
			},
			notificationClick: function (id, url) {
				if(url) {
					this.markAsRead(id);
					window.location.href = url;
				}
			},
			toggleList: function() {
				this.toggleDropdown = !this.toggleDropdown;
			},
		},
		created() {
			this.fetchNotifications();
			this.timer = setInterval(this.fetchNotifications, 60000)
		},
		beforeDestroy() {
			this.cancelAutoUpdate();
		}
	}
</script>

<style scoped>
	p.link:hover,
	.notify:hover {
		cursor: pointer;
	}
	
	.animate {
		-webkit-animation: animation-button 1500ms infinite;
		-moz-animation: animation-button 1500ms infinite;
		-o-animation: animation-button 1500ms infinite;
		animation: animation-button 1500ms infinite;
	}
	
	@-webkit-keyframes animation-button {
		0%   {color: #ffffff; }
		50% { color: #EFD031; }
		100%   {color: #ffffff; }
	}
	@keyframes animation-button {
		0%   {color: #ffffff; }
		50% { color: #EFD031; }
		100%   {color: #ffffff; }
	}
</style>