.alert-primary {
  background-color: #F5FBFF;
  border-color: #E6F3FF;
}

.alert-success {
  background-color: #EBFEEE;
}

.alert-danger {
  background-color: #FEECEB;
}

.alert-dismissible .close {
  top: 50%;
  right: 15px;
  @include translate(0, -50%);
  padding: 0;
  background: none;
}

.alert-personal-data-warning {
  color: #857226;
  background-color: #fff8db;
  border-color: #fff5cc;
}