.mailing-list {
	border: #f0f0f0 1px solid;
	font-size: 12px;
	border-bottom: none;
	border-right: none;
	&-item {
		display: flex;
		flex-flow: row wrap;
		border-bottom: none;
		&:last-child {
		}
		&-left {
			border-right: #f0f0f0 1px solid;
			padding: 0;
			text-align: center;
			flex-grow: 1;
			display: flex;
			justify-content: center;
			border-bottom: #f0f0f0 1px solid;
			.el-switch {
				width: initial;
			}
		}
		&-center {
			padding: 5px 15px 10px;
			flex-grow: 5;
			border-bottom: #f0f0f0 1px solid;
			border-right: #f0f0f0 1px solid;
		}
		&-right {
			padding: 0;
			flex-grow: 1;
			border-bottom: #f0f0f0 1px solid;
			padding: 0 5px 15px;
			border-right: #f0f0f0 1px solid;
		}
		&-date {
			position: absolute;
			top: 0;
			right: 10px;
		}
	}
}