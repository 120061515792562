.list-companies {
  padding: 0 0 85px;
  &-content {
    @include flexbox();
    @include align-items(flex-start);
    @include justify-content(space-between);
  }
  &-tabs {
    width: calc(100% - 297px);
  }
  .block-rating {
    font-weight: 400;
  }
}

.block-recruitment-agencies {
  text-align: center;
}

.recruitment-agencies-title {
  font-size: 36px;
  color: #419DE4;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 25px;
}

.recruitment-agencies-subtitle {
  color: #419DE4;
  margin-bottom: 30px;
}

.agencies-advantages {
  @include flexbox();
  @include align-items(center);
  @include justify-content(center);
  margin: 0 auto 35px;
  > div:nth-last-child(1) {
    border-left: 1px solid #419DE4;
  }
  > div {
    padding: 25px 90px;
  }
  p {
    margin: 0;
    text-align: left;
    color: #419DE4;
  }
}

.btn-header {
  background-color: #419DE4;
  border-color: #419DE4;
  padding-left: 40px;
  padding-right: 40px;
  .fab {
    margin-right: 20px;
  }
}

.block-advertising {
  margin-top: 30px;
  border: 1px solid #F0F0F0;
  padding: 5px;
  p {
    color: #14669B;
    text-align: right;
    margin-bottom: 5px;
  }
  img {
    width: 100%;
    height: auto;
  }
}

.block-company-list {
  padding: 0;
  margin-bottom: 45px;
  &-item {
    border: 1px solid #F0F0F0;
    background-color: #FBFAFA;
    padding: 15px 26px 15px 22px;
    margin-bottom: 10px;
    @include flexbox();
    @include align-items(center);
    @include justify-content(space-between);
    &-pb {
      padding-bottom: 19px;
    }
  }
  .block-rating {
    @include flexbox();
    @include align-items(center);
    p {
      color: #707070;
      margin-right: 15px;
      margin-bottom: 0;
      span {
        color: #2581BC;
      }
    }
    .fa-star {
      color: #fff;
      font-size: 10px;
      padding: 3px;
      background-color: #2581BC;
    }
  }
  .company-item-description {
    width: calc(100% - 155px);
  }
  .block-rating-company-name {
    @include flexbox();
    @include align-items(center);
    @include justify-content(space-between);
    width: 100%;
    margin-bottom: 7px;
  }
  .company-name {
    font-weight: 500;
    font-size: 18px;
    color: #14669B;
    margin-bottom: 0;
    display: inline-block;
    position: relative;
    &-link {
      @include flexbox();
      @include align-items(center);
    }
  }
  .company-icon {
    width: 18px;
    height: auto;
    margin-left: 17px;
  }
  .company-confirmed {
    &:before {
      content: '';
      width: 27px;
      height: 27px;
      position: absolute;
      top: 0;
      right: -30px;
      background: url(/images/company/check.png) no-repeat;
    }
  }
  .block-jobs-reviews {
    @include flexbox();
    @include align-items(flex-start);
    margin-bottom: 6px;
    div {
      min-width: 99px;
      text-align: left;
      line-height: 16px;
    }
    > div:nth-last-child(1) {
      border-left: 1px solid $primary;
      padding-left: 20px;
      line-height: 16px;
    }
  }
  .company-description-text {
    color: #707070;
    margin-bottom: 0;
    width: 88%;
    span {
      color: #062336;
    }
  }
}

.company-logo-wrap {
  width: 120px;
  height: 120px;
  margin: auto 25px auto 0;
  @include flexbox();
  @include align-items(center);
  @include justify-content(center);
  img {
    max-width: 100%;
    width: auto;
    max-height: 100%;
    height: auto;
  }
}

@media only screen and (max-width: 991px) {
  .agencies-advantages > div {
    padding: 15px 20px;
  }
  .list-companies-content {
    @include flex-direction(column);
    .vacancies-filter {
      width: 100%;
      margin-bottom: 10px;
    }
    .nav-tabs .nav-item {
      width: 50%;
      margin-top: 20px;
    }
  }
  .filter-advertising,
  .list-companies-tabs {
    width: 100%;
  }
  .list-companies-tabs.nav-tabs .nav-link {
    padding: 0 2rem 1rem;
  }
  .block-company-list .company-description-text {
    width: 100%;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  .company-logo-wrap {
    width: 65px;
    margin: auto 10px auto 0;
  }
  .block-company-list-item {
    padding: 10px;
    .block-rating p {
      margin-right: 5px;
    }
    .company-item-description {
      width: calc(100% - 75px);
    }
  }
}

@media only screen and (max-width: 767px) {
  .agencies-advantages > div {
    padding: 15px 10px;
  }
  .recruitment-agencies-title {
    font-size: 23px;
  }
  .list-companies-content {
    padding-top: 8px;
    .vacancies-filter {
      display: none;
    }
  }
  .block-company-list .block-jobs-reviews div {
    min-width: auto;
    padding-right: 20px;
  }
}

@media only screen and (max-width: 600px) and (orientation: portrait) {
  .block-recruitment-agencies {
    margin-bottom: 15px;
  }
  .block-company-list-item {
    padding: 10px 10px 20px;
    @include flex-direction(column);
    @include align-items(center);
    .company-item-description {
      width: 100%;
      margin-bottom: 0;
    }
  }
  .company-logo-wrap {
    width: 80px;
    margin: 0 auto 10px;
    @include align-items(center);
    @include flexbox();
    @include justify-content(center);
  }
  .block-company-list {
    .block-rating {
      @include align-items(center);
      @include justify-content(center);
      width: 100%;
      margin-bottom: 10px;
    }
    .block-rating-company-name {
      @include flex-direction(column-reverse);
      @include align-items(flex-start);
    }
  }
  .agencies-advantages {
    @include flex-direction(column);
    @include align-items(flex-start);
    > div:nth-last-child(1) {
      border-left: none;
      border-top: 1px solid #419DE4;
    }
    p {
      text-align: center;
    }
  }
}