.create-company {
  &-page {
    padding-top: 24px;

    &-content {
      margin-bottom: 20px;

      &__left {
        width: calc(100% - 495px);
      }

      &__right {
        padding: 0;
        width: 473px;
        background: none;

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  &-links-list {
    padding: 20px 0;
  }

  &-link {
    width: 33%;
  }
}

@media only screen and (max-width: 1024px) {
  .create-company {
    &-page {
      &-content {
        &__left {
          width: calc(100% - 370px);
        }

        &__right {
          width: 350px;
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .create-company {
    &-page {
      padding-top: 0;

      &-content {
        &__left {
          width: calc(100% - 225px);
        }

        &__right {
          width: 214px;
        }
      }
    }
    &-links-list {
      padding: 20px 0 0;
    }
    &-link {
      width: 50%;
    }
  }
}

@media only screen and (max-width: 767px) {
  .create-company {
    &-page {
      &-content {
        &__left {
          width: 100%;
          margin-bottom: 20px;
          .reg-employer-subtitle {
            margin-bottom: 20px;
          }
        }

        &__right {
          width: 100%;
          display: inline-block;
          text-align: center;
        }
      }
    }

    &-link {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 767px) and (orientation: landscape) {
  .create-company-page-content__right img {
    width: 60%;
  }
}
