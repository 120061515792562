.aboutus {
    padding-bottom: 10px;
}

.aboutus-wrapper {
    max-width: 768px;
    margin: 0 auto;
}

.aboutus-title {
    font-weight: bold;
    font-size: 2.1875rem;
    color: $textForDarkElements;
}

.aboutus-header {
    background-color: $primary;
    padding: 75px 0 30px;
}

.aboutus-subtitle {
    font-size: 1rem;
    color: #96CBF2;
    font-weight: 600;
    margin-bottom: 5px;
}

.margin-bottom-ten {
    margin-bottom: 10px;
}

.text-white {
    color: $textForDarkElements;
    font-size: 0.875rem;
    span {
        font-weight: bold;
    }
}

.blue-block {
    position: relative;
    padding-bottom: 35px;
    margin-bottom: 15px;
    &:before {
        content: '';
        width: 100%;
        height: 55px;
        background-color: $primary;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 0;
    }
}

.aboutus-blocks {
    @include flexbox();
    .aboutus-block:nth-last-child(1) {
        margin-right: 0;
    }
}

.aboutus-block {
    margin-bottom: 20px;
    width: calc(25% - 12px);
    margin-right: 12px;
    border-top: 5px solid #96CBF2;
    background-color: $textForDarkElements;
    padding: 18px 0 15px;
    z-index: 55;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .1);
    .img-wrap {
        min-height: 43px;
        margin: auto;
    }
}

.aboutus-block-num {
    color: $textPrimary;
    font-weight: bold;
    font-size: 1.125rem;
    text-align: center;
    margin-bottom: 7px;
}

.aboutus-block-text {
    font-size: 0.875rem;
    text-align: center;
    color: $textPrimary;
}

.aboutus-block-pic-first {
    width: 38px;
}

.aboutus-block-pic-second {
    width: 46px;
}

.aboutus-block-pic-third {
    width: 29px;
}

.aboutus-block-pic-fourth {
    width: 42px;
}

.about-us-text {
    font-size: 0.875rem;
    color: $textPrimary;
    span {
        font-weight: bold;
    }
}

.aboutus-block-title {
    font-size: 1.375rem;
    color: #2F84BC;
    font-weight: 600;
    margin-bottom: 18px;
}

.aboutus-sep {
    width: 74px;
    height: 4px;
    background-color: #96CBF2;
    margin: 0 auto 1rem;
}

.img-wrap {
    img {
        width: 100%;
        height: auto;
    }
}

.block-bluish {
    background-color: #F3F6F9;
}

.aboutus-list {
    @include flexbox();
    @include flex-wrap(wrap);
    margin-bottom: 20px;
    &-item {
        margin-bottom: 10px;
        padding-left: 10px;
        width: 50%;
        min-height: 41px;
        @include flexbox();
    }
    &-item-number {
        border: 1px solid #96CBF2;
        border-radius: 50%;
        @include flexbox();
        @include justify-content(center);
        @include align-items(center);
        margin-right: 10px;
        width: 24px;
        height: 24px;
        p {
            color: #96CBF2;
            font-size: 1rem;
            font-weight: bold;
            margin-bottom: 0;
            line-height: 1;
        }
    }
    &-text {
        color: $textPrimary;
        font-size: 0.875rem;
        text-align: left;
        width: calc(100% - 34px);
        margin-bottom: 0;
    }
}

.aboutus-bee {
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
}

.aboutus-bee-img {
    width: 49px;
    margin-right: 10px;
}

.aboutus-bee-text {
    color: $textForDarkElements;
    background-color: #2581BC;
    font-size: 0.875rem;
    font-weight: 600;
    padding: 0 20px;
    margin-bottom: 0;
}

.padding-top-thirteen {
    padding-top: 35px;
}

.small-text-wrap {
    max-width: 620px;
    width: 100%;
    margin: auto;
}

@media (max-width: 991px) {
    .aboutus-blocks {
        @include flex-wrap(wrap);
        flex-wrap: wrap;
    }
    .aboutus-block {
        margin-bottom: 20px;
        width: calc(50% - 7px);
        margin-right: 14px;
    }
    .aboutus-blocks .aboutus-block:nth-last-child(1) {
        margin-right: 14px;
    }
    .aboutus-blocks .aboutus-block:nth-child(even) {
        margin-right: 0;
    }
    .aboutus-list-item {
        width: 100%;
        min-height: auto;
    }
    .aboutus-blocks .aboutus-block-text {
        font-size: .7rem;
    }
    .aboutus-bee-text {
        font-size: .55rem;
        padding: 5px 8px;
    }
}