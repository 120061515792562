.tooltip-block{
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.tooltip-wrap{
  position: absolute;
  bottom: calc(100% + 11px);
  left: 0;
  padding: 20px;
  width: 280px;
  background: #fff;
  border: 0.5px solid #E0E0E0;
  box-shadow: 0 4px 20px rgba(125, 125, 125, 0.25);
  font-size: 14px;
  color: $secondary;
  .triangle{
    width: 16px;
    height: 16px;
    background: #fff;
    border: 0.5px solid #E0E0E0;
    border-top: none;
    border-left: none;
    position: absolute;
    bottom: -9px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
  }
}
