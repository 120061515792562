.vacancies-modal {
  width: 450px;
  position: fixed;
  right: 0;
  top: 100%;
  transform: translateY(-100%);
  background-color: #fff;
  z-index: 1031;
  transition: 1s;
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);

  &-header {
    padding: 25px 0 36px;
    margin-bottom: 2px;
    position: relative;
    background: url("/images/vacancies-modal/header-bg.svg") top center no-repeat;
    background-size: cover;

    &__text {
      font-size: 20px;
      font-weight: 500;
      color: #fff;
      text-align: center;
      margin-bottom: 0;
      cursor: pointer;
    }

    &__img {
      height: 91px;
      position: absolute;
      left: 0;
      top: 0;
    }

    .material-icons {
      font-size: 22px;
      color: #fff;
      position: absolute;
      right: 16px;
      top: 16px;
      cursor: pointer;
    }
  }

  &-form {
    width: 100%;
    padding: 0 24px 30px;

    &__title {
      font-size: 14px;
      text-align: left;
      margin-bottom: 20px;
    }
  }

  &__move {
    top: calc(100% - 90px);
    transform: translateY(0);
  }
}

.btn-vacancies-modal {
  width: 100%;
  background-color: #008AEA;
  border: none;
  text-align: center;
  overflow: hidden;
  transition: .7s;

  .before-pay,
  .processing,
  .after-pay {
    width: 100%;
    height: 100%;
    position: relative;
    display: none;
    @include align-items(center);
    @include justify-content(center);
  }

  &.if-not-paid {
    .before-pay {
      @include flexbox();
      color: #fff;

      &__text {
        font-size: 14px;
        margin-bottom: 0;
      }
    }
  }

  &.if-processing {
    .processing {
      @include flexbox();
      color: #fff;

      &__text {
        font-size: 14px;
        margin-bottom: 0;
      }

      .spinner-wrap {
        position: absolute;
        top: 50%;
        right: 15px;
        @include translate(0, -50%);
      }

      .spinner-border {

        width: 18px;
        height: 18px;
      }
    }
  }

  &.if-paid {
    background-color: #28A745;

    .after-pay {
      @include flexbox();
      color: #fff;
      animation: move 1s ease;

      &__text {
        font-size: 14px;
        margin-bottom: 0;
      }

      .material-icons {
        font-size: 18px;
        margin-right: 10px;
      }
      @keyframes move {
        0% {
          left: 40px;
        }
        100% {
          left: 0;
        }
      }
    }
  }
}
@media only screen and (max-width: 1500px) {
  .vacancies-modal {
    width: 390px;
  }
  .vacancies-modal-header__img {
    height: 65px;
    top: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .vacancies-modal {
    display: none;
  }
}
