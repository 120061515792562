.block-list-of-employees {
	.form-response-vacancy {
		margin-bottom: 45px;
		input {
			width: calc(82.5% - 20px);
		}
		> div {
			@include flexbox();
			@include align-items(center);
			@include justify-content(space-between);
			width: 17.5%;
			button, a {
				width: calc(50% - 10px);
				padding: .375rem 0;
			}
		}
	}
	.nav-tabs .nav-link {
		i {
			display: none;
			font-size: 22px;
		}
	}
	.invitations-tabs-title .nav-link {
		i {
			display: none;
			font-size: 20px;
		}
	}
}

.invite-user {
	position: absolute;
	right: 0;
	top: 0;
	i {
		margin-right: 5px;
	}
}

.list-employees-tabs-header {
	position: relative;
	margin-bottom: 20px;
	.nav-tabs {
		.nav-link {
			padding: 0 0 10px;
			margin-right: 40px;
			&.active {
				border-bottom: 5px solid $primary;
			}
		}
	}
}

.company-user-toggle {
	cursor: pointer;
}

.users-list {
	padding: 0;
	&-item {
		margin-bottom: 10px;
		&.toggle-content {
			.users-list-item-header {
				background-color: #EBF7FF;
			}
			.users-list-item-content {
				display: inline-block;
			}
		}
		&-header {
			padding: 20px 20px 20px 30px;
			border: 1px solid $UILightGray;
			background-color: #FBFAFA;
			@include flexbox();
			@include align-items(center);
			@include justify-content(space-between);
			position: relative;
			p {
				margin-bottom: 0;
				color: $textPrimary;
			}
		}
		.item-header-name {
			width: 19%;
			color: $primary;
			cursor: pointer;
		}
		.item-header-phone {
			width: 15%;
		}
		.icon-trash {
			width: 12px;
		}
		.item-header-email {
			width: calc(66% - 12px);
		}
		&-content {
			border: 1px solid $UILightGray;
			border-top: none;
			padding: 15px 20px 30px 30px;
			width: 100%;
			display: none;
			label {
				font-weight: 600;
			}
			.block-phone-mail {
				@include flexbox();
				@include align-items(flex-end);
				margin-bottom: 10px;
				.form-group {
					margin-bottom: 0;
					width: 38%;
					margin-right: 50px;
				}
			}
			.block-mail {
				@include flexbox();
				@include align-items(flex-end);
				margin-bottom: 8px;
				p {
					margin-bottom: 0;
				}
				p:nth-child(1) {
					font-weight: 600;
					margin-right: 20px;
				}
			}
			.block-buttons {
				text-align: right;
				button:nth-last-child(1) {
					margin-left: 20px;
				}
			}
		}
	}
}

.invitations-tabs-title {
	background-color: #DEEFFA;
	border-bottom: none;
	margin-bottom: 10px;
	.nav-link {
		border-radius: 0;
		padding: .5rem 1rem;
	}
	.nav-link.active,
	.nav-item.show .nav-link {
		color: $textForDarkElements;
		background-color: $primary;
		border-color: $primary;
	}
	.nav-link.active {
		border-bottom: none;
	}
}

.invitations-all-header {
	.invitations-header-date {
		width: calc(16% - 15px);
	}
	.invitations-header-email {
		width: 30%;
	}
}

.invitations-header {
	padding: 19px 20px 19px 30px;
	&-name {
		color: $textPrimary;
		width: 19%;
	}
	&-phone {
		width: 15%;
	}
	&-email {
		width: 56%;
	}
	&-date {
		width: 10%;
	}
	.list-empty {
		margin: auto;
	}
}

.inquiries-list {
	.invitations-header-email {
		width: 31%;
	}
	.invitations-header-date {
		width: 12%;
	}
	.block-btns {
		@include flexbox();
		@include align-items(center);
		@include justify-content(space-between);
		width: 23%;
		.btn {
			width: calc(50% - 8px);
			line-height: 16px;
		}
	}
	.block-msg {
		margin-bottom: 0;
		width: 23%;
		text-align: center;
		.fas {
			margin-right: 10px;
		}
		.fa-check-circle {
			color: #00B955;
		}
	}
	.application-accepted {
		.block-btns {
			display: none;
		}
		.block-msg-accepted {
			display: inline-block !important;
		}
	}
	.application-reject {
		.block-btns {
			display: none;
		}
		.block-msg-reject {
			display: inline-block !important;
		}
	}
}

.modal-invite-user {
	.modal-header {
		margin: auto;
		border: none;
		padding-bottom: 0;
	}
	.modal-body {
		padding-top: 25px;
		padding-bottom: 0;
	}
	label {
		text-align: left;
		width: 100%;
		font-weight: 600;
	}
	.modal-footer {
		padding: 4px 1rem 1rem;
	}
	sup {
		color: #FF0000;
	}
}

.block-application {
	-webkit-box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.18);
	-moz-box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.18);
	box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.18);
	padding: 23px 50px;
	border: none;
	border-radius: 0;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 1030;
	background-color: $textForDarkElements;
	@include align-items(baseline);
	display: none;
	i {
		font-size: 16px;
		margin-right: 10px;
	}
	.fa-check-circle {
		color: #00B955;
	}
	.fa-user-times {

	}
	button.close {
		background: none;
	}
	p {
		margin-bottom: 0;
	}
}

@media only screen and (max-width: 991px) {
	.block-list-of-employees {
		.nav-tabs .nav-link {
			padding: 0 1rem 1rem;
		}
		.invitations-tabs-title .nav-link {
			padding: 0.5rem 1rem;
		}
		.form-response-vacancy {
			input {
				width: calc(78% - 10px);
			}
			> div {
				width: 22%;
				button, a {
					width: calc(50% - 5px);
				}
			}
		}

	}
	.users-list {
		&-item {
			.item-header-name {
				width: 25%;
			}
			.item-header-phone {
				width: 25%;
			}
			.item-header-email {
				width: calc(50% - 12px);
			}
		}
	}
	.invitations-all-header {
		.invitations-header-date {
			width: calc(21% - 15px);
		}
		.invitations-header-email {
			width: 30%;
		}
	}

	.invitations-header {
		&-name {
			width: 30%;
		}
		&-phone {
			width: 20%;
		}
		&-email {
			width: 30%;
		}
		&-date {
			width: 20%;
		}
	}
	.inquiries-list {
		.users-list-item-header {
			padding: 15px 10px 15px 20px;
		}
		.invitations-all-header {
			.invitations-header-date {
				width: 11%;
				text-align: center;
			}
		}
		.block-btns {
			@include flex-direction(column);
			width: 18%;
			.btn {
				width: 100%;
				margin: 3px auto;
			}
		}
		.block-msg {
			width: 18%;
		}
	}
}

@media only screen and (max-width: 767px) {
	.users-list {
		&-item {
			.item-header-name {
				width: auto;
			}
			.item-header-phone {
				width: auto;
			}
			.item-header-email {
				width: auto;
			}
			&-header {
				padding: 13px 10px 15px 10px;
				-webkit-box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
				-moz-box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
				box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
				border: none;
				@include flex-direction(column);
				@include align-items(flex-start);
			}
			.icon-trash {
				position: absolute;
				right: 10px;
				bottom: 15px;
			}
			&-content {
				padding: 10px 18px 20px 18px;
				.block-phone-mail {
					@include flex-direction(column);
					@include align-items(flex-start);
					.form-group {
						margin-bottom: 1rem;
						width: 100%;
						margin-right: 0;
					}
				}
				.block-buttons {
					text-align: center;
				}
			}
			&.toggle-content {
				.users-list-item-header {
					-webkit-box-shadow: none;
					-moz-box-shadow: none;
					box-shadow: none;
					border: 1px solid $UILightGray;
				}
			}
		}
	}
	.invite-user {
		position: relative;
		padding-right: 0;
		margin-bottom: 25px;
	}
	.list-employees-tabs-header {
		@include flexbox();
		@include flex-direction(column-reverse);
		@include align-items(center);
	}
	.invitations-all-header {
		@include flex-wrap(wrap);
		@include flex-direction(row);
		.invitations-header-date {
			width: 31%;
			order: 2;
		}
		.invitations-header-name {
			width: 69%;
			order: 1;
		}
		.invitations-header-phone {
			width: 100%;
			order: 3;
		}
		.invitations-header-email {
			width: 100%;
			order: 4;
		}
	}
	.invitations-header {
		@include flex-wrap(wrap);
		@include flex-direction(row);
		.invitations-header-date {
			width: 31%;
			order: 2;
		}
		.invitations-header-name {
			width: 69%;
			order: 1;
		}
		.invitations-header-phone {
			width: 100%;
			order: 3;
		}
		.invitations-header-email {
			width: 100%;
			order: 4;
		}
	}
	.block-list-of-employees {
		.form-response-vacancy {
			margin-bottom: 0;
			> div {
				width: 100%;
			}
		}
		.nav-tabs {
			border-bottom: none;
			.nav-link {
				padding: 0 12px 10px;
				margin: 0;
				i {
					display: inline-block;
				}
				span {
					display: none;
				}
				& .active {
					border-bottom: 5px solid #4e8bbf;
				}
			}
			.nav-item {
				margin: 0 30px 1px;
			}
		}
		.invitations-tabs-title {
			@include justify-content(center);
			.nav-link {
				padding: 20px 23px;
				i {
					display: inline-block;
				}
				span {
					display: none;
				}
			}
			.nav-link.active,
			.nav-item.show .nav-link {
				color: #000;
				background: none;
				border: none;
			}
			.nav-item {
				margin: 0 0 1px;
			}
		}
	}
	.inquiries-list {
		.users-list-item-header {
			@include flex-direction(row);
			@include flex-wrap(wrap);
		}
		.block-btns {
			width: 100%;
			@include flex-direction(row);
			order: 5;
			.btn {
				width: calc(40% - 8px);
				line-height: 16px;
			}
		}
		.block-msg {
			width: 90%;
			order: 5;
		}
		.invitations-header-name {
			width: 69%;
			order: 1;
		}
		.invitations-all-header {
			.invitations-header-email {
				width: 100%;
				margin-bottom: 15px;
				order: 4;
			}
			.invitations-header-date {
				display: inline-block;
				width: 31%;
				order: 2;
			}
			.invitations-header-phone {
				order: 3;
			}
		}
	}
	.accept-application .block-accept-application {
		@include flexbox();
	}
	.reject-application .block-reject-application {
		@include flexbox();
	}
}