.contact-us{
  .form-group.has-error {
    input.form-control,
    textarea.form-control
    {
      border-color: $danger;
    }
    label {
      color: $danger;
    }
  }
  input.form-control {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    height: 2.5rem;
  }
  textarea.form-control {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    resize: none;
  }
  input.form-control:focus,
  textarea.form-control:focus {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  label.required::after {
    content: "*";
    color: red;
    padding-left: 5px;
  }
}