.replenish-account-page {
	.form-check-label {
		margin-left: 13px;
	}
	.custom-control {
		padding-left: 1.3rem;
		margin-bottom: 10px;
	}
	.total-payable {
		font-size: 18px;
	}
}

.text-semibold {
	font-weight: 500;
}

.block-selection-payment-amount {
	@include flexbox();
	@include align-items(flex-start);
	@include justify-content(space-between);
	.left-col {
		width: 100%;
		max-width: 550px;
		padding: 40px 0 0;
	}
	.selection-payment-title {
		font-size: 18px;
		width: 45%;
		margin-bottom: 0;
	}
	.block-currency {
		width: 55%;
		.form-control {
			width: calc(100% - 30px);
		}
	}
	.right-col {
		max-width: 300px;
		padding: 0;

		.table {
			margin-bottom: 0;
			thead th {
				border-bottom: none;
			}
			th, td {
				border-top: none;
				text-align: center;
				padding: 3px 0;
			}
		}
	}
}

.invoice-payment-sheet {
	padding: 0;
	&-item {
		background-color: #FBFAFA;
		border: 1px solid $UILightGray;
		margin-bottom: 12px;
		padding: 20px 115px 20px 25px;
		@include align-items(center);
		@include justify-content(space-between);
		&:nth-last-child(1) {
			margin-bottom: 0;
		}
		>p {
			margin-bottom: 0;
			width: 25%;
			text-align: center;
			&:nth-child(1) {
				text-align: left;
			}
			&:nth-last-child(1) {
				text-align: right;
			}
		}
		>div {
			width: 50%;
			p{
				text-align: left;
				width: 100%;
				margin-bottom: 0;
			}
		}
	}
}

.block-budget-info {
	margin: 20px auto 30px;
	background-color: #ECF6FF;
	padding: 25px 30px 20px 20px;
	&-title {
		font-size: 18px;
	}
	&-content {
		@include align-items(center);
		@include justify-content(space-between);
		>div {
			@include flexbox();
			@include align-items(center);
			@include justify-content(flex-start);
		}
		p{
			margin-right: 35px;
			margin-bottom: 0;
		}
	}
}

.alert-info {
	background-color: #D9EDF7;
	border-color: #CDE2F5;
}

.alert {
	button.close {
		background: none;
		top: 50%;
		right: 1rem;
		@include translate(0, -50%);
		@include flexbox();
		@include align-items(center);
		@include justify-content(center);
		color: #707070;
	}
}

@media only screen and (max-width: 1199px) {
	.block-budget-info {
		&-title {
			margin-bottom: 10px;
		}
		&-content {
			@include flex-direction(column);
			>div{
				@include flex-direction(column);
				@include align-items(flex-start);
				width: 100%;
			}
			p{
				margin-right: 0;
				margin-bottom: 10px;
				text-align: left;
			}
		}
	}
}

@media only screen and (max-width: 991px) {
	.block-selection-payment-amount {
		@include flex-direction(column);
		@include align-items(center);
		.left-col {
			max-width: 100%;
		}
	}
	.invoice-payment-sheet-item {
		margin-bottom: 10px;
		padding: 15px;
	}
}

@media only screen and (max-width: 767px) {
	.block-selection-payment-amount {
		.selection-payment-title {
			font-size: 14px;
		}
		.left-col {
			> div:nth-child(1) {
				@include flex-direction(column);
				@include align-items(flex-start);
				margin-bottom: 16px !important;
				.selection-payment-title {
					width: 100%;
					margin-bottom: 10px;
				}
				.block-currency {
					width: 100%;
				}
				p {
					width: 40px;
				}
			}
		}
		.right-col .table {
			margin-bottom: 10px;
		}
	}
	.replenish-account-page .total-payable {
		font-size: 14px;
	}
	.invoice-payment-sheet-item {
		-webkit-box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
		-moz-box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
		box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
		padding: 10px;
		@include flex-wrap(wrap);
		border-radius: 5px;
		>p {
			margin-bottom: 0;
			width: 50%;
			text-align: left;
			&:nth-child(2) {
				text-align: right;
			}
			&:nth-child(3) {
				width: 100%;
			}
			&:nth-last-child(1) {
				width: 100%;
				text-align: left;
			}
		}
		>div {
			width: 100%;
		}
	}
}