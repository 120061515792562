.page-error-wrap {
	padding: 32px 0 0;
	margin-bottom: -100px;
	&-img {
		width: 50%;
		//margin-top: 85px;
		margin-top: 16.5vh;
		img {
			width: 100%;
			height: auto;
		}
	}
	.container {
		@include flexbox();
		>div:nth-child(1){
			width: 50%;
		}
	}
}

@media only screen and (min-width: 1025px) and (max-height: 800px) {
	.page-error-wrap {
		&-img {
			margin-top: 37px;
		}
	}
}

@media screen and (max-width: 1024px) {
	.page-error-wrap {
		&-img {
			margin-top: 70px;
		}
	}
}

@media only screen and  (max-width: 1024px) and (orientation: portrait) {
	.page-error-wrap {
		padding: 15vw 0 0;
		&-img {
			margin-top: 39vw;
		}
	}
}

@media screen and (max-width: 992px) {
	.page-error-wrap {
		padding: 35px 0 0;
		margin-bottom: -39px;
	}
}

@media only screen and  (max-width: 767px) and (orientation: portrait) {
	.page-error-wrap {
		padding: 15px 0 0;
		text-align: center;
		&-img {
			margin-top: 11.5vw;
		}
		.container {
			@include flex-direction(column);
			@include align-items(center);
			> div:nth-child(1) {
				width: 100%;
			}
		}
		.page-error-wrap-img {
			width: 87%;
		}

	}
}

@media only screen and  (max-width: 767px) {
	.page-error-wrap-img {
		margin-top: 20px;
	}
}