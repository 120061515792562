.block-questions {
  padding: 70px 0 0;
  text-align: center;
  h2 {
    font-size: 25px;
    font-weight: 500;
    margin-bottom: 16px;
  }
  .open-form {
    margin-bottom: 30px;
    font-size: 20px;
    cursor: pointer;
    .fas {
      margin-left: 35px;
    }
  }
  &-form {
    width: 100%;
    max-width: 610px;
    margin: auto;
    .form-group {
      text-align: left;
      margin-bottom: 20px;
    }
    sup{
      color: #FF0000;
    }
    textarea{
      resize: none;
      margin-bottom: 45px;
    }
    button{
      min-width: 143px;
      margin: 0 10px;
      border-radius: 3px;
    }
  }
}

.btn-blue {
  background-color: #14669B;
  border-color: #14669B;
  color: $textForDarkElements;
  &:hover{
    background-color: $textForDarkElements;
    color: #14669B;
  }
}

.btn-blue-outl {
  border-color: #14669B;
  background-color: $textForDarkElements;
  color: #14669B;
  &:hover{
    background-color: #14669B;
    color: $textForDarkElements;
  }
}

@media only screen and (max-width: 992px) {
  .block-questions {
    padding: 50px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .block-questions {
    h2 {
      font-size: 20px;
    }
    .open-form {
      margin-bottom: 15px;
      font-size: 13px;
      .fas {
        margin-left: 10px;
      }
    }
  }
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .block-questions {
    h2 {
      font-size: 17px;
      margin-bottom: 10px;
    }
    .open-form {
      margin-bottom: 15px;
      font-size: 9px;
      .fas {
        margin-left: 7px;
      }
    }
    &-form {
      button{
        width:100%;
        margin: 0 0 10px;
      }
      .btn-blue-outl{
        margin: 0;
      }
      .btn-group {
        width:100%;
        @include flex-direction(column);
      }
    }
  }
}