.navbar-top ul li.packages-dropdown {
  padding-right: 0;

  .material-icons {
    font-size: 22px;
    color: #fff;
    padding-top: 6px;
  }

  .dropdown-item {
    padding: 0;

    &:hover,
    &:focus {
      background: none;
    }
  }

  .packages-list-title {
    font-size: 12px;
    font-weight: 500;
    color: $textPrimary !important;
    padding: 9px 15px;
    background: #F7F7F7;
    margin-bottom: 0;
  }

  .dropdown-menu {
    padding: 0;
    margin: 0;
    font-size: 14px;
    border: none;
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.1);
  }

  .packages-dropdown-item {
    @include flexbox();
    @include align-items(flex-start);
    @include justify-content(space-between);
    padding: 10px 15px;
    font-weight: 400;
    color: $secondary !important;
    white-space: pre-line;
    border-top: 0.5px solid #BCBCBC;

    > p {
      margin-bottom: 0;
    }

    .name-service {
      width: 200px;
    }

    .count-service {
      width: 30px;
      text-align: right;
    }
  }

  .packages-dropdown-empty {
    @include flexbox();
    @include flex-direction(column);
    @include align-items(center);
    padding: 20px 35px;

    &:hover,
    &:focus {
      background: none;
    }

    p {
      font-size: 12px;
      margin-bottom: 20px;
      text-align: center;
    }
  }
}

.packages-dropdown-mob {
  border-bottom: 1px solid #B3D6FC;
  padding: 10px 0;
  display: none;

  &__row {
    @include flexbox();
    @include align-items(baseline);
    @include justify-content(space-between);
    padding: 0 27px 0 66px;
    margin: 10px 0 0;

    &:nth-last-child(1) {
      margin-bottom: 7px;
    }

    > p {
      margin-bottom: 0;
      color: #fff;

      &:nth-child(1) {
        width: 85%;
      }

      &:nth-child(2) {
        width: 15%;
        text-align: right;
      }
    }
  }

  &-empty {
    padding: 5px 25px;

    p {
      margin-bottom: 10px;
      color: #fff;
    }
  }
}

.packages-dropdown-title {
  @include flexbox();
  @include align-items(center);
  @include justify-content(flex-start);
  padding: 0 20px;

  &__open {
    .material-icons {
      @include rotate(180);
    }
  }

  .material-icons {
    font-size: 23px;
    color: #fff;
    margin-right: 23px;
  }

  > p {
    margin-bottom: 0;
    color: #fff;
  }
}

@media only screen and (max-width: 767px) {
  .navbar-top ul li.packages-dropdown {
    display: none;
  }
  .packages-dropdown-mob {
    display: inline-block;
    width: 100%;
  }
}
