.new-job-add-sitebar {
	width: 285px;
	padding: 0;
	position: sticky;
	right: 0;
	top: 162px;
	li {
		width: 100%;
		background-color: #F3FAFF;
		margin-bottom: 10px;
		position: relative;
		&.active-item:before {
			content: '';
			width: 5px;
			height: 100%;
			background-color: #BDE4FF;
			position: absolute;
			left: 0;
			top: 0;
		}
		&:hover:before {
			content: '';
			width: 5px;
			height: 100%;
			background-color: #BDE4FF;
			position: absolute;
			left: 0;
			top: 0;
		}
		div {
			padding: 10px 20px 10px;
			width: 100%;
		}
		p {
			margin-bottom: 0;
			line-height: 17px;
		}
		a:hover {
			text-decoration: none;
		}
	}
	.video-block {
		border: 1px solid $primary;
		background-color: #fff;
		div{
			padding: 10px 27px 10px 12px;
			@include flexbox();
			@include align-items(center);
			@include justify-content(space-between);
		}
		.fa-play-circle{
			font-size: 30px;
			margin-right: 10px;
		}
		&:hover:before {
			display: none;
		}
	}
}

@media only screen and (max-width: 991px) {
	.new-job-add-sitebar {
		display: none;
	}
}