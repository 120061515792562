.account-setting {
  &-wrap {
    @include flexbox();
    @include align-items(flex-start);
    @include justify-content(space-between);

    &__user-name {
      line-height: 1;
      font-size: 24px;
      font-weight: 500;
      text-align: center;
      margin-bottom: 10px;
    }

    &__user-email {
      line-height: 1;
      font-size: 12px;
      text-align: center;
      margin-bottom: 10px;
      color: $secondary;
    }

    .form-control.is-invalid {
      background: #fff;
    }
  }

  &-pass {
    margin-bottom: 20px;
    width: 50%;

    .input-group-text {
      background-color: #fff;
      border-left: none;

      .fa-eye {
        font-size: 12px !important;
        color: $secondary !important;
      }

      &:hover {
        .fa-eye {
          color: $hoverSecondary !important;
        }
      }
    }
  }
}

.setting-remove-account {
  font-size: 13px;
  color: $danger;
  text-align: center;

  &:hover {
    color: $hoverDanger;
  }
}

.photo-name-email {
  @include flexbox();
  @include align-items(center);
  @include flex-direction(column);
  width: 32%;
  background: #FFFFFF;
  border: 0.5px solid #E5E5E5;
  box-shadow: 0 4px 8px rgba(205, 205, 205, 0.25);
  padding: 45px 40px 108px;

  .form-avatar {
    margin: 0 auto 14px;
    background-color: #E5E5E5;
    width: 125px;
    height: 125px;
    border: 0.5px solid #E5E5E5;

    &:hover {
      border-color: #008BEB;
    }

    &-img .no-user-avatar {
      width: 79px;
    }

    &-img .is-user-avatar {
      width: auto;
      height: auto;
      max-height: 50px;
      max-width: 100px;
      position: absolute;
      left: 50%;
      top: 50%;
      @include translate(-50%, -50%);
    }
  }

  .form-avatar-img {
    max-width: 125px;
    max-height: 125px;
  }

  .upload-photo-text {
    font-size: 14px;
    text-align: center;
    margin-bottom: 36px;
  }

  .remove-photo {
    font-size: 14px;
    text-align: center;
    margin-bottom: 36px;
    color: $primary;
    cursor: pointer;
  }

  .block-name-email {
    @include flexbox();
    @include align-items(center);
    @include flex-direction(column);
  }
}

.form-account-data {
  width: 66%;

  .custom-checkbox {
    margin-right: 19px;
  }

  .form-control:focus {
    color: $textPrimary;
  }
}

.account-btns {
  margin-top: 30px;
}

.form-group-form-account {
  margin-bottom: 19px;

  label {
    font-size: 14px;
    line-height: 1;
    margin-bottom: 10px;
  }

  &.is-invalid-block {
    label {
      color: $danger !important;
    }
  }
}

.form-group-custom-checkbox {
  margin-top: 19px;
  margin-bottom: 0;
}

.btn-cancel-setting {
  margin-right: 10px;
  .disabled,
  &:disabled:hover,
  &:disabled {
    color: #B4B4B4!important;
    border-color: #B4B4B4!important;
    background-color: transparent!important;
  }
  &:hover {
    color: #fff!important;
    background-color: $primary!important;
    border-color: $primary!important;
  }
}

@media only screen and (max-width: 991px) {
  .account-setting {
    &-wrap {
      @include flex-direction(column);

      &__user-name {
        margin-bottom: 5px;
        order: 1;
      }

      &__user-email {
        order: 2;
      }
    }

    &-pass {
      margin-bottom: 14px;
      width: 100%;
    }
  }
  .form-account-data {
    width: 100%;
  }
  .photo-name-email {
    @include flex-direction(row);
    @include justify-content(flex-start);
    width: 100%;
    border: none;
    box-shadow: none;
    padding: 0;
    margin-bottom: 18px;

    .form-avatar {
      margin: 0 20px 0 0;
      width: 65px;
      height: 65px;

      &-img {
        max-width: 65px;
        max-height: 65px;

        .no-user-avatar {
          width: 36px;
        }

        .is-user-avatar {
          max-height: 45px;
          max-width: 55px;
        }
      }
    }

    .remove-photo,
    .upload-photo-text {
      order: 3;
      margin-bottom: 0;
    }

    .setting-remove-account {
      position: absolute;
      right: 0;
      top: 0;
    }

    .block-name-email {
      @include align-items(flex-start);
    }
  }
  .form-group-form-account {
    margin-bottom: 14px;
  }
  .form-account-data .block-contacts-list .block-communication-method {
    margin-top: 0;
  }
}

@media only screen and (max-width: 767px) {
  .nav-tabs-castom__account {
    padding: 0 !important;
  }
  .account-setting {
    &-pass {
      margin-bottom: 20px;
    }

    &-wrap__user {
      &-name {
        order: 2;

        &-email {
          order: 3;
        }
      }
    }
  }
  .photo-name-email {
    @include flex-direction(column);
    @include justify-content(center);
    margin-bottom: 23px;

    .setting-remove-account {
      position: relative;
      order: 4;
    }

    .remove-photo,
    .upload-photo-text {
      order: 1;
      margin-bottom: 20px;
    }

    .form-avatar {
      margin: 0;
    }

    .block-name-email {
      @include align-items(center);
    }
  }
}
