.ads-welcome-section {
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
  min-height: calc(100vh - 97px);
  background: url("/images/company-1.jpg") center top no-repeat;
  background-size: cover;
  position: relative;

  &-title {
    text-align: center;
    text-shadow: 6px 6px 0 rgba(0, 0, 0, .2);
    margin: 0 auto 20px;
    font-size: 90px;
    color: #fff;
    font-weight: 700;
    max-width: 800px;
  }

  &-subtitle {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    color: #fff;
    max-width: 800px;
    margin: 0 auto;
  }
}

.ads-steps {
  &-section {
    padding-top: 30px;

    .ads-step {
      @include flexbox();
      @include justify-content(flex-start);
      @include align-items(center);
      @include flex-direction(column);
      margin-bottom: 45px;

      .material-icons {
        font-size: 35px;
        color: $primary;
        margin-bottom: 15px;
      }

      p {
        font-size: 20px;
        color: $primary;
        margin-bottom: 0;
        text-align: center;
        text-transform: uppercase;
      }
    }
  }
}

.ads-trust-us-section {
  padding: 48px 0 80px;
  background-color: $primary;
}

.ads-trust-us-title {
  text-shadow: 2px 8px 6px rgba(0, 0, 0, .2), 0 -5px 35px rgba(0, 0, 0, .3);
  font-size: 30px;
  color: #fff;
  font-weight: 700;
  text-align: center;
  margin: 0 auto 70px;
}

.trust-us {
  &-item {
    padding-left: 102.5px;
    margin-bottom: 30px;
    position: relative;

    &__img {
      width: 175px;
      height: 116px;
      position: absolute;
      left: 15px;
      top: 50%;
      @include translate(0, -50%);
    }
  }

  &-content {
    padding: 50px 40px 50px 118px;
    background-color: #fff;
    height: 100%;

    &__title {
      font-size: 20px;
      margin-bottom: 20px;
      text-align: left;
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: .28571rem;
    }

    &__text {
      font-size: 15px;
      margin-bottom: 20px;
      text-align: left;
      line-height: 1.6;
      font-weight: 500;
    }
  }
}

.ads-trust-cards-section {
  background-color: #fff;
  padding: 60px 0 0;
}

.ad2s-card {
  padding: 25px 25px 20px;
  text-align: center;
  margin-bottom: 30px;

  &__yellow {
    border: 1px solid #ebc71d;
  }

  &__purple {
    border: 1px solid #9a69cb;
  }

  &__green {
    border: 1px solid #72c02c;
  }

  &__title {
    line-height: 1.4;
    font-size: 28px;
    margin-bottom: 7px;
    text-align: center;
    font-weight: 500;
    letter-spacing: .28571rem;
  }

  &__text {
    font-size: 14px;
    text-align: left;
    line-height: 1.6;
    font-weight: 300;
    padding-bottom: 45px;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    margin-bottom: 20px;
  }
}

.ads-gallery {
  &-cards-section {
    padding: 0 15px;
  }

  &-img {
    height: auto;
    width: 100%;
  }
}

.ads-contact-us {
  &-section {
    padding: 40px 0 55px;
    background-color: $primary;
    margin-bottom: -100px;
  }

  &__title {
    text-shadow: 0 4px 3px rgba(0, 0, 0, .4), 0 8px 13px rgba(0, 0, 0, .1), 0 18px 23px rgba(0, 0, 0, .1);
    line-height: 1.5;
    font-size: 20px;
    color: #fff;
    text-align: center;
    margin-bottom: 0;
  }

  &__btn {
    justify-content: center;
  }
}

@media only screen and (max-width: 1199px) {
  .ads-steps-section .ads-step p {
    font-size: 17px;
  }
  .ad2s-card__title {
    font-size: 28px;
  }
  .ads-gallery-img {
    height: 227px;
  }
}

@media only screen and (max-width: 991px) {
  .ads-welcome-section {
    min-height: calc(100vh - 51px);
  }
  .ads-steps-section {
    padding: 30px 0 20px;
  }
  .ads-trust-us-section {
    padding: 60px 0 80px;
  }
  .ads-gallery-img {
    height: 171px;
  }
  .ads-contact-us-section {
    margin-bottom: -40px;
  }
}

@media only screen and (max-width: 767px) {
  .ads-welcome-section-title {
    font-size: 39.9px;
  }
  .ads-welcome-section-subtitle {
    font-size: 14px;
  }
  .ads-steps-section .ads-step p {
    font-size: 19px;
  }
  .ads-steps-section .ads-step {
    margin-bottom: 85px;
  }
  .trust-us {
    &-item {
      padding-left: 15px;
      margin-bottom: 30px;

      &__img {
        left: 50%;
        top: 0;
        @include translate(-50%, 0);
      }
    }

    &-content {
      padding: 166px 40px 50px;
    }
  }
  .ads-gallery-cards-section {
    display: none;
  }
  .ads-contact-us__btn {
    margin-top: 15px;
  }
}
