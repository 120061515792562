.popular-questions-home {
  padding: 0;
  border-top: 1px solid #E8E8E8;
  margin: 0;
}

.popular-question-home {
  padding: 1.6vh 1.2vh 1.7vh 2.4vh;
  cursor: pointer;
  position: relative;
  border-bottom: 1px solid #E8E8E8;

  i {
    color: $primary;
    font-size: 15px;
    position: absolute;
    top: 2.2vh;
    right: 1.2vh;
  }

  &__title {
    color: $primary;
    font-size: 16px;
    width: 93%;
    margin-bottom: 0;
  }

  &__text {
    margin-bottom: 0;
    display: none;
    width: 93%;
    margin-top: 1.85vh;
  }

  &__open {
    .popular-question-home__text{
      display: inline-block;
    }
    i {
      @include rotate(180);
    }
  }
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .popular-question-home {
    padding: 5vw 2.2vw 5.5vw;

    i {
      font-size: 17px;
      top: 6.7vw;
      right: 1.7vw;
    }

    &__title {
      font-size: 4.4vw;
      width: 93%;
    }

    &__text {
      width: 100%;
      margin-top: 5.6vw;
      font-size: 3.9vw;
    }
  }
}