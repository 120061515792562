<template>
	<div>
		<form novalidate>
			<div v-if="showForm">
				<p id="blockEducation" class="new-job-add-block-wrap-title">
					{{ labels.title }} </p>
				
				<div class="form-group ">
					<div class="new-job-add-block-wrap-row">
						<div class="new-job-add-block-wrap-row-left">
							<label for="cv_education_level">{{ labels.educationLevel }}</label>
						</div>
						<div class="new-job-add-block-wrap-row-right">
							<div class="wrap-form-control">
								<select id="cv_education_level" name="cv_education[level]" class="form-control"
								        v-model="educationLevel">
									<option value="">{{ labels.educationLevel }}</option>
									
									<option v-for="level in educationLevels" :value="level.slug">
										{{ level.level }}
									</option>
								
								</select>
							</div>
						</div>
					</div>
				</div>
				<div class="form-group">
					<div class="new-job-add-block-wrap-row">
						<div class="new-job-add-block-wrap-row-left">
							<label for="cv_education_knowingLanguages" class="knowing-languages-mob">{{ labels.languageLevel }}</label>
						</div>
						<div class="new-job-add-block-wrap-row-right">
							<div class="jobseeker-lang-row mb-3" v-for="(languageList, key, index) in languages">
								
								<select id="cv_education_knowingLanguages" name="cv_education[knowingLanguages]"
								        class="form-control wrap-form-lang-select" v-model="languageList.selectedUuid">
									
									<option value="">{{ labels.languageLevel }}</option>
									
									<option v-for="language in languageList.list"
									        :value="language.uuid">
										{{ language.code }}
									</option>
								</select>
								
								<select class="form-control wrap-form-lang-select" name="cv_education[language_level]"
								        v-model="languageList.selectedLevel">
									<option value="">{{ placeholders.languageLevel }}</option>
									
									<option v-for="level in languageLevels" :value="level.slug">
										{{ level.level }}
									</option>
								
								</select>

								<div v-if="key === Object.keys(languages).length - 1" class="border border-success btn-control-lang">
									<a type="button" v-on:click="onAddLanguage">
										<i class="fas fa-plus text-success"></i>
									</a>
								</div>
								<div v-else class="border border-danger btn-control-lang">
									<a type="button" v-on:click="onRemoveLanguage(key)"><i
											class="fas fa-times text-danger"></i> </a>
								</div>
								<!--<select name="cv_education[knowingLanguages_level][]" :disabled="!selectedLanguage"
										class="form-control wrap-form-lang-select"
										@change="onSelectLanguageLevel" v-model="selectedLanguageLevel"
								>
									<option value="native">Native</option>
									<option value="beginner">Beginner</option>
								</select>-->
							</div>
						</div>
					</div>
					<div class="form-group">
						<div class="new-job-add-block-wrap-row">
							<div class="new-job-add-block-wrap-row-left"></div>
							<div class="new-job-add-block-wrap-row-right for-line-mob">
								
								<div class="alert alert-success" role="alert" v-if="showSuccess">
									Your changes has been saved!
								</div>
								
								<div class="alert alert-danger" role="alert" v-if="showError">
									Your changes has not been saved!
								</div>
								
								<button type="button" class="btn btn-outline-success" v-on:click="onSaveEducation">
									{{ labels.save }}
								</button>
								<button type="button" class="btn btn-outline-grey ml-4" v-on:click="onToggleForm">
									{{ labels.cancel }}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="form-group mb-0" v-if="!showForm">
				<div class="new-job-add-block-wrap-row new-job-add-block-wrap-row__flex">
					<div class="new-job-add-block-wrap-row-left">
						<p class="additional-information-block-title" v-if="!showForm":disabled="!cv">
							<span v-if="!showLoader">{{ labels.addTitle }}</span>
							<i v-if="showLoader" class="fa fa-fw fa-spin fa-spinner"></i>
						</p>
					</div>
					<div class="new-job-add-block-wrap-row-right text-right">
						<button class="btn-show-additional-information-block" type="button" v-on:click="onToggleForm" v-if="!showForm"
						        :disabled="!cv">
							<i class="fas fa-chevron-down"></i>
						</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
	import * as axios from "axios";
	
	export default {
		name: "Education",
		data() {
			return {
				showForm: false,
				educationLevel: '',
				/*'languages': [
					{
						list: [
							{
								'uuid': '586fd2c5-ee7a-4ed2-a636-1b7d229451f3',
								'code': 'af'
							},
							{
								'uuid': '7532e7e5-e3d7-4c5c-afd6-5d734d5e3df6',
								'code': 'am'
							},
							{
								'uuid': '7532e7e5-e3d7-4c5c-afd6-5d734d5e3df2',
								'code': 'ru'
							},
						],
						selectedUuid: null,
						selectedLevel: null
					}
				],*/
				'languages': [],
				'knowingLanguages': [],
				'canAddLanguage': false,
				languageLevels: [],
				educationLevels: [],
				showSuccess: false,
				showError: false,
				showLoader: false,
			}
		},
		mounted() {
			let self = this;
			
			this.getLanguages();
			this.getLanguageLevels();
			this.getEducationLevels();
			
			
			this.getEducationInfo();
		},
		created() {
			let self = this;
		},
		props: {
			cv: String,
			languagesString: String,
			languageLevelsString: String,
			educationLevelsString: String,
			fields: Object,
			labels: Object,
			placeholders: Object
		},
		methods: {
			onSelectLanguage(event) {
				let self = this;
				
				self.canAddLanguage = true;
				
				// self.knowingLanguages.splice(self.knowingLanguages.length-1, 1, self.selectedLanguage)
			},
			onAddLanguage() {
				let self = this;
				self.languages.push({...self.languages[0]});
			},
			onRemoveLanguage(key) {
				let self = this;
				
				self.languages.splice(key, 1);
			},
			onSaveEducation() {
				let self = this,
					levels = [];
				
				self.languages.forEach(function (item, index) {
					if (item.selectedLevel && item.selectedUuid) {
						levels.push({
							uuid: item.selectedUuid,
							level: item.selectedLevel
						});
					}
				});

				let postData = {
					cv: self.cv,
					education: self.educationLevel ? self.educationLevel : null,
					languages: levels.length > 0 ? levels : null
				};
				
				axios.post('/api/cv/save-education', {
					'cv_education': postData
				}).then(function (response) {
					
					self.showSuccess = true;
					
					setTimeout(function () {
						self.showSuccess = false;
						
					}, 2500);
				}).catch(function (response) {
						self.showSuccess = false;
						self.showError = true
					});
				// }
			},
			onToggleForm: function () {
				this.showForm = !this.showForm;
			},
			getLanguages: function () {
				let languagesParsed = this.languagesString ? JSON.parse(this.languagesString) : {},
					languageList = [];
				
				for (const [uuid, language] of Object.entries(languagesParsed)) {
					languageList.push({
						'uuid': uuid,
						'code': language
					});
				}
				
				this.languages.push({
					'list': languageList,
					'selectedUuid': null,
					'selectedLevel': null,
				});
			},
			getLanguageLevels: function () {
				let levelsParsed = this.languageLevelsString ? JSON.parse(this.languageLevelsString) : {},
					list = [];
				
				for (const [slug, level] of Object.entries(levelsParsed)) {
					list.push({
						'slug': slug,
						'level': level,
					})
				}
				
				this.languageLevels = list;
			},
			getEducationLevels: function () {
				let levels = this.educationLevelsString ? JSON.parse(this.educationLevelsString) : {},
					list = [];
				
				for (const [slug, level] of Object.entries(levels)) {
					list.push({
						'slug': slug,
						'level': level,
					})
				}
				
				this.educationLevels = list;
			},
			getEducationInfo: function() {
				
				let self = this;
				
				if (self.cv) {
					self.showLoader = true;
					
					axios.get('/api/cv/' + self.cv + '/educations')
						.then(function (response) {
							if (response.data) {
								self.educationLevel = response.data.eduLevel;
								
								response.data.langLevels.forEach(function (item, index) {
									
									self.languages[index]['selectedUuid'] = item.langUuid;
									self.languages[index]['selectedLevel'] = item.level;
									
									if (index < (response.data.langLevels.length - 1)) {
										self.languages.push({...self.languages[index]});
									}
								});
								
								if (self.educationLevel || response.data.langLevels.length > 0) {
									self.showForm = true;
								}
								
								self.showLoader = false;
							}
						})
					/*if(self.knowingLanguages.length <= 0) {
						self.knowingLanguages = [self.languages[0]];
					}*/
				}
			}
		}
	}
</script>

<style scoped>
	.new-cv-add-page .wrap-form-lang-select {
		width: calc(50% - 25px);
	}
	@media only screen and (max-width: 991px) {
		.new-cv-add-page .wrap-form-lang-select {
			width: calc(50% - 40px);
		}
	}
	@media only screen and (max-width: 767px) {
		.new-cv-add-page .wrap-form-lang-select {
			width: calc(50% - 34px);
		}
	}
</style>