.btn-info-light {
	background-color: #5BC0DE;
	color: $textForDarkElements;

	&:hover {
		background-color: #4ab1d0;
		color: $textForDarkElements;
	}
}

.btn-outline-white {
	border: 1px solid $textForDarkElements;
	color: $textForDarkElements;

	&:hover {
		background-color: $textForDarkElements;
		color: $body-color;
	}
}

.btn-info {
	 color: $textForDarkElements;
	 background-color: $primary;
	 border-color: $primary;
 }

.btn-outline-info {
	border-color: $primary;
	color: $primary;
}

.btn-outline-grey{
	border: 1px solid #999999;
	color: #999999;

	&:hover {
		background-color: #999999;
		color: $textForDarkElements;
	}
}

.btn-primary {
	background-color: $primary;
	border-color: $primary;
	color: $textForDarkElements;
	:hover {
		background-color: $hoverPrimary;
		border-color: $hoverPrimary;
	}
}

.btn-warning {
	background-color: $yellowColorButtonHeader;
	border-color: $yellowColorButtonHeader;
}

.btn {
	padding-left: 20px;
	padding-right: 20px;
	-webkit-appearance: none;
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
	color: $disabledSecondary;
	border-color: $disabledSecondary;
	background-color: transparent;
}

.btn.disabled, .btn:disabled {
	opacity: 1;
}

.btn-outline-primary:hover {
	color: $textPrimary;
	border-color: $textPrimary;
}
