.block-promo-banner {
	position: fixed;
	bottom: 45px;
	right: 55px;
	width: 375px;
	-webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0.5);
	-moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
	background-color: $textForDarkElements;
	z-index: 1;
	display: none;
	.icon-close-banner {
		position: absolute;
		top: 10px;
		right: 10px;
		font-size: 12px;
		color: #ADB5BD;
		cursor: pointer;
	}
	.container {
		padding-left: 0;
	}
	&-title {
		font-size: 15px;
		color: #008AEA;
		margin-bottom: 15px;
		text-align: left;
	}
	&-text {
		margin-bottom: 0;
	}
	p {
		margin-bottom: 0;
	}
	&-mob {
		padding: 25px 5px 37px 20px;
		position: relative;
		.promo-banner-img {
			position: absolute;
			width: 100%;
			height: auto;
			right: 0;
			bottom: 0;
		}
		.btn-outline-primary {
			margin-top: 20px;
			z-index: 1;
			position: relative;
		}
	}
	&.show-banner {
		display: block;
		animation: block-promo-banner-animation 3s;
	}
}

@keyframes block-promo-banner-animation {
	0% {
		opacity: 0
	}
	100% {
		opacity: 1
	}
}

@media only screen and (max-width: 1024px) {
	.block-promo-banner {
		position: relative;
		width: 100%;
		bottom: 0;
		right: 0;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		box-shadow: none;
		display: block;
		&.show-banner {
			animation: none;
		}
		.icon-close-banner {
			display: none;
		}
		&-mob {
			padding: 10px 0 35px;
			.promo-banner-img {
				width: 45%;
			}
		}
		.container {
			padding-left: 15px;
		}
	}
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
	.block-promo-banner {
		&-mob {
			.promo-banner-img {
				width: 100%;
			}
		}
	}
}

@media only screen and (max-width: 330px) and (orientation: portrait) {
	.block-promo-banner-mob {
		padding-bottom: 45px;
	}
}

@media only screen and (max-width: 1024px) and (orientation: landscape) {
	.block-promo-banner {
		&-title {
			margin-bottom: 20px;
		}
		&-mob {
			padding-bottom: 20px;
			.promo-banner-img {
				width: auto;
				height: 123px;
			}
		}
	}
}

@media only screen and (max-width: 660px) and (orientation: landscape) {
	.block-promo-banner {
		&-mob {
			.promo-banner-img {
				height: 99px;
			}
		}
	}
}