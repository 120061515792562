.w-half {
  @include media-breakpoint-down(sm) {
    width: 50%;
  }
}

.c-pointer {
  cursor: pointer;
}

.rating {
  .fa-star {
    background-color: #D5D5D5;

    &.checked {
      background-color: $info;
    }
  }
}

.border-left-solid {
  border-left: 3px solid;
}

button.close {
  position: absolute;
  top: 0;
  right: 0;
  background-color: $light;
  width: 20px;
  height: 20px;
  opacity: 1;
}