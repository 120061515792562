.my-jobs {
  .nav-tabs-castom {
    margin-bottom: 32px;
  }

  .table-response-vacancy-row {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .icon-vip-paid {
    left: 9px;
  }

  .block-my-vacancies {
    .col1 {
      width: 49.9%;
      padding-right: 12%;
    }

    .col2 {
      width: 15.7%;

      .svg-inline--fa {
        display: none;
      }
    }

    .col3 {
      width: 13.8%;
    }

    .col5 {
      width: 13.8%;
      text-align: center;
      position: relative;
    }
  }

  .service {
    &-not-active {
      margin-bottom: 0;
      color: $primary;
      border-bottom: 1px solid $primary;
      display: inline-block;
      cursor: pointer;

      &:hover {
        border-bottom: none;
      }
    }

    &-active {
      &-title {
        background: none;
        color: #28A745;
        border: none;
        border-bottom: 1px dashed #28A745;
        padding-left: 0;
        padding-right: 0;

        &:focus {
          outline: none;
        }
      }

      &-hover-block {
        top: calc(100% + 10px) !important;
        border: 1px solid #e0e0e0;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .30%);
        padding: 12px 12px 12px 20px;
        background: #fff;

        ul {
          padding: 0;
          color: #40505F;
          width: 187px;
          background-color: #fff;
          position: relative;

          &:before {
            content: '';
            position: absolute;
            left: 50%;
            top: -18px;
            transform: translate(-50%, 0) rotate(45deg);
            width: 11px;
            height: 11px;
            border-left: 1px solid #e0e0e0;
            border-top: 1px solid #e0e0e0;
            background: #fff;
          }

          li {
            list-style: disc;
            text-align: left;
            margin-left: 10px;
          }
        }
      }
    }
  }
}

.dropdown-menu-center {
  left: 50% !important;
  -webkit-transform: translate(-50%, 0) !important;
  -moz-transform: translate(-50%, 0) !important;
  -ms-transform: translate(-50%, 0) !important;
}

.services-setting-form {
  &__desc {
    margin-bottom: 20px;
    color: $secondary;
  }

  &__btn {
    &:disabled,
    &[disabled] {
      background: $disabledSuccess;
      border-color: $disabledSuccess;
    }

    &-wrap {
      text-align: right;
    }
  }
}

.services-setting {
  &-blocks {
    @include flexbox();
    @include align-items(flex-start);
    @include justify-content(space-between);
    margin-bottom: 15px;
    padding-bottom: 5px;
    padding-top: 15px;
    border: 1px solid #E0E0E0;

    &__desc {
      border: none;
      margin-bottom: 0;
      padding-bottom: 0;
      padding-top: 0;

      .services-setting-block__title {
        width: 50%;
        padding-left: 15px;
      }
    }
  }

  &-block {
    padding-left: 15px;
    padding-right: 5px;
    width: 50%;

    &__title {
      font-weight: 500;
      color: $secondary;
      margin-bottom: 10px;

      &__mob {
        display: none;
      }
    }

    .custom-checkbox {
      margin-bottom: 7px;

      .custom-control-label::before {
        border-radius: 0;
      }
    }

    .custom-control-label {
      font-weight: 400;
    }
  }
}

.service-ischecked-block {
  margin-bottom: 10px;
  @include flexbox();
  @include align-items(baseline);
  @include justify-content(flex-start);

  .fas {
    color: #00B955;
    font-size: 15px;
    margin-right: 12px;
  }

  p {
    margin-bottom: 0;
    width: calc(100% - 27px);
  }
}

.badge-draft {
  color: $secondary;
  border: 1px solid $secondary;
}

@media only screen and (max-width: 991px) {
  .my-jobs {
    .nav-tabs-castom {
      margin-bottom: 22px;
    }

    .table-response-vacancy-header,
    .table-response-vacancy-row {
      padding-left: 23px;
    }

    .icon-vip-paid {
      left: 5px;
      top: 2px;
      font-size: 13px;
    }

    .table-response-vacancy-header p {
      font-size: 12px;
    }

    .block-my-vacancies {
      .col1 {
        width: 44.2%;
        padding-right: 25px;
      }

      .col2 {
        width: 12.2%;

        span {
          display: none;
        }

        .svg-inline--fa {
          display: inline-block;
        }

        .fa-check-circle {
          color: #00B955;
          font-size: 16px;
        }

        .fa-eye-slash {
          color: #707070;
          font-size: 9px;
        }

        .fa-clock {
          color: #FFDB49;
          font-size: 10px;
        }
      }

      .col3 {
        width: 16.7%;
      }

      .col4 {
        width: 11%;
      }

      .col5 {
        width: 15.9%;
      }
    }
  }
  .services-setting-block {
    &__title__tab {
      display: none;
    }

    &__first {
      width: 59%;
    }

    &__second {
      width: 41%;
    }
  }
}

@media only screen and (max-width: 767px) {
  .my-jobs {
    h2 {
      padding: 0 10px;
    }

    .nav-tabs-castom {
      margin-left: -5px;
      margin-right: -5px;
    }

    .table-response-vacancy-row {
      padding-left: 16px;
      padding-top: 10px;
      padding-bottom: 10px;
      @include flex-direction(column);
      margin-left: -5px;
    }

    .vip-paid-tariff {
      .col3 {
        padding-left: 23px;
      }
    }

    .icon-vip-paid {
      left: 8px;
      top: 0;
      font-size: 24px;
    }

    .block-my-vacancies {
      .col1 {
        width: 100%;
        padding-right: 68px;
        margin-bottom: 5px;
      }

      .col2 {
        width: auto;
        position: absolute;
        right: 25px;
        top: 46px;
        padding: 0;

        .fa-eye-slash {
          font-size: 12px;
        }
      }

      .col3 {
        width: 100%;
        padding-right: 68px;
        margin-bottom: 5px;
      }

      .col4 {
        width: 66px;
        padding: 0 10px 0 0;
      }

      .col5 {
        width: 100%;
        padding-right: 68px;
        padding-left: 0;
        text-align: left;
        order: 3;
      }
    }
  }

  .services-setting {
    &-form {
      padding-left: 10px;
      padding-right: 10px;
    }

    &-block {
      width: 100%;
      padding-left: 0;
      padding-right: 0;

      &__title__mob {
        display: inline-block;
      }

      &__second {
        padding-top: 20px;
      }
    }

    &-blocks {
      margin-bottom: 5px;
      padding: 0;
      border: none;
      @include flex-direction(column);

      &__desc {
        display: none;
      }
    }
  }
}