.section-top {
    padding-top: 3rem;
}

.section-sm-top {
    padding-top: 1rem;
}

.section {
    padding-top: 3rem;
    padding-bottom: 3rem;
}
