.pagination {

  .page-link {
    color: $body-color;
    padding: 0.5rem .9rem;
    margin-right: 10px;
    border-radius: 3px;

    &:hover {
      color: $secondary;
      cursor: pointer;
      background-color: $light;
    }
  }

  .page-item.active .page-link {
    border-radius: 3px;
  }

  .next, .prev {
    padding-top: 6px;
  }

  .btn-width{
    width: 66px;
    text-align: center;
    color: #D5D5D5!important;
    font-weight: 500;
  }
}

@media only screen and (max-width: 768px) {
  .pagination .btn-width {
    width: 37px;
  }
  .pagination .page-link {
    margin-right: 5px;
  }
  .pagination {
    padding-left: 10px;
  }
}
