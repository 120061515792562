.block-my-vacancies {
  .col1 {
    width: 45%;
    padding-left: 0;

    p {
      text-align: left;
      //@include flexbox();
      //@include align-items(center);
      margin-bottom: 0;
    }
  }

  .col2 {
    width: 19.5%;
  }

  .col3 {
    width: 26.7%;
  }

  .col4 {
    width: 6.8%;
  }

  .table-response-vacancy-row {
    @include flexbox();
    @include align-items(center);
    @include justify-content(space-between);
    position: relative;
    margin-bottom: 10px;
    padding-left: 43px;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid $UILightGray;

    &-small {
      padding-top: 18px;
      padding-bottom: 18px;
      padding-left: 21px;
      &.vip-paid-tariff {
        padding-top: 28px;
      }

      .icon-vip-paid {
        left: 70px;
        font-size: 20px;
      }

      .col1 {
        @include flexbox();
        @include align-items(center);
        @include justify-content(flex-start);
        p{
          margin-left: 27px;
        }
      }

      .checkbox-vacancy label {
        padding-right: 0;

        &:before {
          margin-right: 0;
        }
      }
    }

    .fa-star {
      display: none;
    }
  }

  .paid-tariff {
    background-color: #DBF5FF;
  }

  .icon-vip-paid {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    color: #EA4E00;
    font-size: 24px;
  }

  .vip-paid-tariff {
    position: relative;

    .icon-vip-paid {
      display: inline-block;
    }
  }

  .form-response-vacancy {
    input:nth-child(1) {
      width: calc(41% - 15px);
    }

    input:nth-child(2) {
      width: calc(24% - 15px);
    }

    input:nth-child(3) {
      width: calc(13% - 15px);
      background: none;
    }

    > div {
      @include flexbox();
      @include align-items(center);
      @include justify-content(space-between);
      width: calc(22% - 15px);

      button, a {
        width: calc(50% - 10px);
        padding: .375rem 0;
      }
    }
  }

  .table-response-vacancy {
    border-bottom: none;
    margin-bottom: 0;

    &-header {
      padding-left: 43px;
      border-bottom: none;

      .col2,
      .col3 {
        color: $textPrimary;
      }

      p {
        font-weight: 400;
        font-size: 14px;
      }
    }
  }

  .table-response-my-vacancy-header {
    padding-left: 22px;
  }
}

.block-my-vacancies-container {
  .block-my-vacancies.account-my-vacancies .col1 {
      padding-right: 2%;
    }
}

.btn-vacancy-toggle {
  background: none;
  width: 43px;
  height: 43px;
  margin: auto;
  border: none;
  @include flexbox();
  @include align-items(center);
  @include justify-content(center);
  border-radius: 50%;

  > div {
    width: 4px;
    height: 4px;
    background-color: #676767;
    margin: 3px;
    border-radius: 50%;
  }

  &:focus {
    outline: none;
    background: #F4F4F4;

    > div {
      background-color: $primary;
    }
  }

  &:hover {
    background: #F4F4F4;

    > div {
      background-color: $primary;
    }
  }
}

.vacancy-list {
  border: 1px solid $UILightGray;
  border-radius: 0;
  width: 170px;
  padding: 0;
  background-color: $textForDarkElements;
  z-index: 10;

  a {
    color: $textPrimary;
    width: 100%;
    padding-left: 10px;
    line-height: 36px;
    display: inline-block;

    &:hover {
      color: #fff;
      background-color: $primary;
      text-decoration: none;
    }
  }
}

.dropdown-menu.show {
  z-index: 1030;
}

.number-applications-mob {
  color: $primary;
  display: none;
}

button.close {
  padding: 0;
  margin: 0;
  z-index: 5;
  right: 2px;
  top: 2px;
}

.modal-body {
  text-align: center;
  padding-top: 3rem;
}

.modal-footer {
  @include justify-content(center);
  padding: 1rem 1rem 3rem;
  border: none;

  .btn {
    padding: 0.375rem 1rem;
  }
}

.modal-dialog {
  margin: 25vh auto 0;

  button.close {
    background: none;
    color: #908d8d;
    opacity: 1;

    &:hover {
      color: #000;
      text-decoration: none;
    }
  }
}

.modal-content {
  border-radius: 0;
}

.modal-footer > :not(:last-child) {
  margin-right: 1rem;
}

.block-starts {
  @include flexbox();
  @include align-items(center);
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 430px;
  }
}

@media only screen and (max-width: 1199px) {
  .block-my-vacancies .form-response-vacancy {
    input:nth-child(1) {
      width: calc(38% - 7.5px);
    }

    input:nth-child(2) {
      width: calc(24% - 7.5px);
    }

    input:nth-child(3) {
      width: calc(16% - 7.5px);
    }

    > div {
      @include flexbox();
      @include align-items(center);
      @include justify-content(space-between);
      width: calc(22% - 7.5px);

      button {
        width: calc(50% - 5px);
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .number-applications-mob {
    display: inline-block;
    @include flexbox();
    @include align-items(center);

    .fa-star {
      color: $textPrimary;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .block-my-vacancies .table-response-vacancy {
    .table-response-vacancy-body .table-response-vacancy-row-small {
      padding: 28px 21px 15px 10px;

      .icon-vip-paid {
        left: 40px;
      }

      //.checkbox-vacancy label:before {
      //  margin-right: 10px;
      //}

      .col1 {
        width: 56%;
        p{
          margin-left: 10px;
        }
      }
      .col2 {
        width: 17%;
      }
      .col3 {
        width: 19%;
      }
      .col4 {
        width: 8%;
      }
    }
    .table-response-my-vacancy-header {
      padding: 0 21px 0 10px;
      margin-bottom: 10px;
      .col1 {
        width: 56%;
      }
      .col2 {
        width: 17%;
      }
      .col3 {
        width: 19%;
      }
      .col4 {
        width: 8%;
      }
      p{
        font-size: 14px;
      }
    }
  }

  .block-my-vacancies {
    .form-response-vacancy {
      > div button,
      a,
      input {
        font-size: .72rem;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .block-response-vacancy .form-response-vacancy-show .btn-close {
    margin-top: 0;
  }
  .form-response-vacancy-show {
    padding-left: 15px;
  }
  .block-my-vacancies {
    .form-response-vacancy-show {
      @include flex-direction(column);

      input:nth-child(1),
      input:nth-child(2),
      input:nth-child(3) {
        width: 100%;
      }

      > div {
        width: 100%;
      }
    }

    .table-response-vacancy-row {
      @include flex-wrap(wrap);
      padding: 10px 10px 15px 48px;
      border: 1px solid #D6D6D6;
      border-left: none;
      border-right: none;
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    .col1 {
      width: 88%;
      order: 2;
      padding-left: 0;

      a {
        display: block;
        font-size: 15px;
      }
    }

    .col2 {
      display: block;
      width: 100%;
      order: 3;
      padding-left: 0;

      p {
        text-align: left;

        span {
          border: none !important;
          padding: 0 !important;
        }
      }
    }

    .col3 {
      width: 100%;
      order: 1;
      text-align: left;
      padding-left: 0;
      margin-bottom: 1px;
      font-size: 12px;
    }

    .col4 {
      position: absolute;
      right: 0;
      top: 0;
      width: 54.8%;

      .btn-vacancy-toggle {
        margin-right: 0;
      }
    }

    .vip-paid-tariff .icon-vip-paid {
      left: 12px;
    }

    &-container {
      padding-left: 0;
      padding-right: 0;
    }
    .table-response-vacancy-row-small {
      .checkbox-vacancy label {
        top: 50%;
        @include translate(0, -50%);
      }
      .col1 p {
        margin-left: 10px;
      }
      .col3 {
        padding-left: 31px;
      }
      .icon-vip-paid {
        left: 47px;
        font-size: 24px;
      }
    }

  }
  .form-response-vacancy {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: $textForDarkElements;
    z-index: 100;
    margin: 0;
    padding: 15px 10px;
  }
  .modal-footer > :not(:last-child) {
    margin-right: 0;
  }
  .block-starts {
    position: absolute;
    top: 17px;
    left: 57px;
  }
  .my-jobs .block-my-vacancies .table-response-vacancy-row-small {
    .col3 {
      padding-left: 31px;
    }
  }
  .account-my-vacancies {
    @include flex-direction(column);
  }
  .block-my-vacancies-container {
    .block-my-vacancies.account-my-vacancies .col1 {
      padding-right: 68px;
    }
  }
}
