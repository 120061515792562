.vacancy-management-block {
  margin-top: -20px;
  margin-bottom: 15px;
  padding: 17px 20px;
  background-color: #F7F7F7;
  @include flexbox();
  @include align-items(center);

  &-buttons {
    @include flexbox();
    @include align-items(center);
  }

  &__btn {
    @include flexbox();
    @include align-items(center);
    color: $primary;
    margin-right: 30px;
    border: none;
    background: none;

    &:hover {
      color: $hoverPrimary;
    }

    &:disabled {
      cursor: pointer;
      color: $disabledPrimary;
    }

    .material-icons {
      font-size: 24px;
      margin-right: 5px;
    }
  }

  &__select {
    @include flexbox();
    @include align-items(center);
    color: $primary;
    position: relative;

    .form-control {
      border: none;
      background: none;
      color: $primary;
      z-index: 1;
      padding-left: 0;
      width: 310px;
      padding-right: 0;
      text-indent: 25px;

      option {
        color: $textPrimary;
        //padding-left: 50px;
      }
    }

    &__disabled {
      color: $disabledPrimary;

      .form-control {
        color: $disabledPrimary;
      }
    }

    .material-icons {
      font-size: 24px;
      position: absolute;
      left: 0;
    }
  }

  &__dropdown {
    line-height: 10px;
    display: none;

    &.disabled {
      color: $disabledPrimary;
    }
  }

  .checkbox-vacancy {
    border-right: 1px solid $primary;
    margin-right: 20px;

    label {
      margin-left: 30px;

      &:before {
        margin-right: 0;
        position: absolute;
        left: -30px;
        top: 50%;
        @include translate(0, -50%);
      }
    }

    input:checked + label:after {
      left: -26px;
      top: 50%;
      @include translate(0, -50%);
    }
  }
}

.checkbox-vacancy {
  display: inline-block;

  input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;

    &:checked + label:before {
      background-color: $primary;
      border: 1px solid $primary;
    }

    &:checked + label:after {
      content: '';
      position: absolute;
      top: 4px;
      left: 4px;
      display: block;
      width: 11px;
      height: 11px;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  label {
    @include flexbox();
    @include align-items(center);
    position: relative;
    cursor: pointer;
    color: $primary !important;
    font-weight: 400;
    padding-right: 30px;
    margin-bottom: 0;

    &:before {
      content: '';
      -webkit-appearance: none;
      background-color: #fff;
      border: 1px solid $UILightGray;
      padding: 9px;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      margin-right: 10px;
    }
  }
}


@media only screen and (max-width: 991px) {
  .text-for-btn {
    display: none;
  }
  .vacancy-management-block {
    &__btn {
      margin-right: 16px;
    }

    &__select {
      display: none;
    }

    &__dropdown {
      display: inline-block;
    }
  }
}

@media only screen and (max-width: 767px) {
  .vacancy-management-block {
    @include align-items(center);
    @include justify-content(space-between);
    margin-top: -20px;
    margin-bottom: 15px;
    padding: 17px 11px;
    position: relative;

    &:before {
      content: '';
      width: 5px;
      height: 100%;
      background-color: #F7F7F7;
      position: absolute;
      top: 0;
      left: -5px;
    }

    &:after {
      content: '';
      width: 5px;
      height: 100%;
      background-color: #F7F7F7;
      position: absolute;
      top: 0;
      right: -5px;
    }

    &-fixed {
      position: fixed;
      top: 71px;
      left: 0;
      z-index: 4;
      width: 100%;
      -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .30%);
      -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .30%);
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .30%);

      &:before,
      &:after {
        display: none;
      }
    }

    &__btn {
      margin-right: 9px;
    }

    .checkbox-vacancy {
      border-right: none;
      margin-right: 0;
    }
  }
  .checkbox-vacancy label {
    padding-right: 0;

    &:before {
      margin-right: 14px;
    }
  }


}
