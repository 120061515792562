.popular-companies-block {
	padding-top: 35px;
	&-title {
		margin-bottom: 20px;
	}
}

@media only screen and (max-width: 767px) {
	.popular-companies-block {
		padding-top: 22px;
		&-title {
			margin-bottom: 18px;
			font-size: 18px;
		}
	}
}
