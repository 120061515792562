.add-company-review-page {
  padding: 95px 0 65px;
}

.form-add-company-review {
  label {
    font-weight: 400;
  }
  .block-personal-information {
    background-color: #CDE6FF;
    border: 1px solid #CDE6FF;
    padding: 10px 16px;
    margin-bottom: 18px;
  }
  .form-group {
    label {
      color: $textPrimary;
    }
  }
  .block-working-hours {
    margin-bottom: 20px;
    @include flexbox();
    @include justify-content(space-between);
    p {
      margin-bottom: 10px;
    }
    > div {
      width: calc(50% - 8px);
      input {
        width: 100%;
        background: transparent;
      }
      input::placeholder {
        color: #D80614;
      }
    }
  }
  .block-do-you-advise {
    padding: 25px 0 0;
    max-width: 600px;
    width: 100%;
    margin-bottom: 50px;
    @include flexbox();
    @include align-items(center);
    @include justify-content(space-between);
    > div {
      @include flexbox();
      @include align-items(center);
      @include justify-content(center);
    }
    button {
      background-color: #F0F0F0;
      border-radius: 3px;
      border: none;
      margin: 0 5px;
      width: 67px;
    }
    p {
      margin-bottom: 0;
    }
  }
  input[type=checkbox] {
    transform: scale(1.5);
  }
  .form-check {
    margin-bottom: 40px;
    .form-check-label {
      margin-left: 5px;
    }
  }
}

.block-positive-negative-sides {
  margin-bottom: 20px;
  p {
    margin-bottom: 5px;
    color: $textPrimary;
    padding: 10px 16px;
    width: 100%;
  }
  textarea {
    resize: none;
    width: 100%;
    height: 160px;
    padding: 13px 16px;
    border-radius: 3px;
    border: 1px solid #D5D5D5;
  }
  .green {
    background-color: #EBFEEE;
  }
  .red {
    background-color: #FEECEB;
  }
  label {
    color: #fff !important;
    font-weight: 400;
    margin-bottom: 0;
  }
}

.block-file-field{
  .added-img{
    width: calc(16.6% - 12.5px);
    margin: 7px 15px 7px 0;
    display: inline-block;
    position: relative;
    img{
      width: 100%;
      height: auto;
      max-height: 86px;
    }
    button{
      border: none;
      width: 22px;
      height: 18px;
      background: #5E5E5E;
      color: $textForDarkElements;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .img-list,
  .video-list {
    .added-img:nth-child(6n+6){
      margin-right: 0;
    }
  }

  .file-field{
    margin-bottom: 50px;
  }

  .button-wrapper {
    position: relative;
    width: 185px;
    text-align: center;
  }

  .button-wrapper span.label {
    position: relative;
    z-index: 0;
    @include flexbox();
    @include align-items(center);
    @include justify-content(space-between);
    width: 100%;
    background: #2581BC;
    cursor: pointer;
    color: $textForDarkElements;
    padding: 10px 30px 10px 17px;
    text-transform:uppercase;
    font-size:12px;
  }

  .btn-add-files {
    display: inline-block;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 35px;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }
}

.block-btns-img-vid{
  @include flexbox();
  @include align-items(center);
  @include justify-content(flex-start);
  margin-bottom: 55px;
  .button-wrapper {
    margin-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .block-btns-img-vid {
    @include flex-wrap(wrap);
    @include justify-content(center);
    .button-wrapper{
      margin-bottom: 10px;
    }
  }
  .block-file-field .added-img {
    width: 28%;
  }
  .form-add-company-review .block-personal-information {
    background-color: #F8FAFC;
    border: 1px solid #EBF6FE;
  }
  .company-addreview-recommend{
    >div .text-gray {
      margin-bottom: 10px;
    }
  }
}