.popular-vacancies {
	padding-top: 35px;
	.row {
		margin-bottom: 8px;
	}
	&-title {
		margin-bottom: 20px;
	}
}

@media only screen and (max-width: 767px) {
	.popular-vacancies {
		padding-bottom: 20px;
		padding-top: 30px;
		.location,
		.card-text.text-muted {
			display: none;
		}
		&-title {
			margin-bottom: 18px;
			font-size: 18px;
			&-color {
				color: $textPrimary;
			}
		}
		.font-weight-bolder {
			text-decoration: underline;
			&:hover {
				text-decoration: none;
			}
		}
	}
}