@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");
@import url(utils/quill.snow.css);
@import url(utils/toast-notification.css);
:root {
  --tagify-dd-color-primary: rgb(53,149,246);
  --tagify-dd-bg-color: white; }

.tagify {
  --tags-border-color: #DDD;
  --tags-hover-border-color: #CCC;
  --tags-focus-border-color: #3595f6;
  --tag-bg: #E5E5E5;
  --tag-hover: #D3E2E2;
  --tag-text-color: black;
  --tag-text-color--edit: black;
  --tag-pad: 0.3em 0.5em;
  --tag-inset-shadow-size: 1.1em;
  --tag-invalid-color: #D39494;
  --tag-invalid-bg: rgba(211, 148, 148, 0.5);
  --tag-remove-bg: rgba(211, 148, 148, 0.3);
  --tag-remove-btn-color: black;
  --tag-remove-btn-bg: none;
  --tag-remove-btn-bg--hover: #c77777;
  --input-color: inherit;
  --tag--min-width: 1ch;
  --tag--max-width: auto;
  --tag-hide-transition: 0.3s;
  --placeholder-color: rgba(0, 0, 0, 0.4);
  --placeholder-color-focus: rgba(0, 0, 0, 0.25);
  --loader-size: .8em;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  border: 1px solid #DDD;
  border: 1px solid var(--tags-border-color);
  padding: 0;
  line-height: normal;
  cursor: text;
  outline: none;
  position: relative;
  box-sizing: border-box;
  transition: .1s; }

@keyframes tags--bump {
  30% {
    transform: scale(1.2); } }

@keyframes rotateLoader {
  to {
    transform: rotate(1turn); } }
  .tagify:hover {
    border-color: #CCC;
    border-color: var(--tags-hover-border-color); }
  .tagify.tagify--focus {
    transition: 0s;
    border-color: #3595f6;
    border-color: var(--tags-focus-border-color); }
  .tagify[readonly]:not(.tagify--mix) {
    cursor: default; }
    .tagify[readonly]:not(.tagify--mix) > .tagify__input {
      visibility: hidden;
      width: 0;
      margin: 5px 0; }
    .tagify[readonly]:not(.tagify--mix) .tagify__tag > div {
      padding: 0.3em 0.5em;
      padding: var(--tag-pad); }
      .tagify[readonly]:not(.tagify--mix) .tagify__tag > div::before {
        background: linear-gradient(45deg, var(--tag-bg) 25%, transparent 25%, transparent 50%, var(--tag-bg) 50%, var(--tag-bg) 75%, transparent 75%, transparent) 0/5px 5px;
        box-shadow: none;
        filter: brightness(0.95); }
  .tagify[readonly] .tagify__tag__removeBtn {
    display: none; }
  .tagify--loading .tagify__input::before {
    content: none; }
  .tagify--loading .tagify__input::after {
    content: '';
    vertical-align: middle;
    opacity: 1;
    width: .7em;
    height: .7em;
    width: var(--loader-size);
    height: var(--loader-size);
    border: 3px solid;
    border-color: #EEE #BBB #888 transparent;
    border-radius: 50%;
    animation: rotateLoader .4s infinite linear;
    margin: -2px 0 -2px .5em; }
  .tagify--loading .tagify__input:empty::after {
    margin-left: 0; }
  .tagify + input,
  .tagify + textarea {
    display: none !important; }
  .tagify__tag {
    display: inline-flex;
    align-items: center;
    margin: 5px 0 5px 5px;
    position: relative;
    z-index: 1;
    outline: none;
    cursor: default;
    transition: .13s ease-out; }
    .tagify__tag > div {
      vertical-align: top;
      box-sizing: border-box;
      max-width: 100%;
      padding: 0.3em 0.5em;
      padding: var(--tag-pad, 0.3em 0.5em);
      color: black;
      color: var(--tag-text-color, black);
      line-height: inherit;
      border-radius: 3px;
      white-space: nowrap;
      transition: .13s ease-out; }
      .tagify__tag > div > * {
        white-space: pre-wrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        vertical-align: top;
        min-width: 1ch;
        max-width: auto;
        min-width: var(--tag--min-width, 1ch);
        max-width: var(--tag--max-width, auto);
        transition: .8s ease, .1s color; }
        .tagify__tag > div > *[contenteditable] {
          outline: none;
          user-select: text;
          cursor: text;
          margin: -2px;
          padding: 2px;
          max-width: 350px; }
      .tagify__tag > div::before {
        content: '';
        position: absolute;
        border-radius: inherit;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        pointer-events: none;
        transition: 120ms ease;
        animation: tags--bump .3s ease-out 1;
        box-shadow: 0 0 0 1.1em #E5E5E5 inset;
        box-shadow: 0 0 0 var(--tag-inset-shadow-size, 1.1em) var(--tag-bg, #E5E5E5) inset; }
    .tagify__tag:hover:not([readonly]) div::before {
      top: -2px;
      right: -2px;
      bottom: -2px;
      left: -2px;
      box-shadow: 0 0 0 1.1em #D3E2E2 inset;
      box-shadow: 0 0 0 var(--tag-inset-shadow-size, 1.1em) var(--tag-hover, #D3E2E2) inset; }
    .tagify__tag--loading {
      pointer-events: none; }
      .tagify__tag--loading .tagify__tag__removeBtn {
        display: none; }
      .tagify__tag--loading::after {
        --loader-size:  .4em;
        content: '';
        vertical-align: middle;
        opacity: 1;
        width: .7em;
        height: .7em;
        width: var(--loader-size);
        height: var(--loader-size);
        border: 3px solid;
        border-color: #EEE #BBB #888 transparent;
        border-radius: 50%;
        animation: rotateLoader .4s infinite linear;
        margin: 0 .5em 0 -.1em; }
    .tagify__tag--flash div::before {
      animation: none; }
    .tagify__tag--hide {
      width: 0 !important;
      padding-left: 0;
      padding-right: 0;
      margin-left: 0;
      margin-right: 0;
      opacity: 0;
      transform: scale(0);
      transition: 0.3s;
      transition: var(--tag-hide-transition, 0.3s);
      pointer-events: none; }
      .tagify__tag--hide > div > * {
        white-space: nowrap; }
    .tagify__tag.tagify--noAnim > div::before {
      animation: none; }
    .tagify__tag.tagify--notAllowed:not(.tagify__tag--editable) div > span {
      opacity: .5; }
    .tagify__tag.tagify--notAllowed:not(.tagify__tag--editable) div::before {
      box-shadow: 0 0 0 1.1em rgba(211, 148, 148, 0.5) inset !important;
      box-shadow: 0 0 0 var(--tag-inset-shadow-size, 1.1em) var(--tag-invalid-bg, rgba(211, 148, 148, 0.5)) inset !important;
      transition: .2s; }
    .tagify__tag[readonly] .tagify__tag__removeBtn {
      display: none; }
    .tagify__tag[readonly] > div::before {
      background: linear-gradient(45deg, var(--tag-bg) 25%, transparent 25%, transparent 50%, var(--tag-bg) 50%, var(--tag-bg) 75%, transparent 75%, transparent) 0/5px 5px;
      box-shadow: none;
      filter: brightness(0.95); }
    .tagify__tag--editable > div {
      color: black;
      color: var(--tag-text-color--edit, black); }
      .tagify__tag--editable > div::before {
        box-shadow: 0 0 0 2px #D3E2E2 inset !important;
        box-shadow: 0 0 0 2px var(--tag-hover, #D3E2E2) inset !important; }
    .tagify__tag--editable > .tagify__tag__removeBtn {
      pointer-events: none; }
      .tagify__tag--editable > .tagify__tag__removeBtn::after {
        opacity: 0;
        transform: translateX(100%) translateX(5px); }
    .tagify__tag--editable.tagify--invalid > div::before {
      box-shadow: 0 0 0 2px #D39494 inset !important;
      box-shadow: 0 0 0 2px var(--tag-invalid-color, #D39494) inset !important; }
    .tagify__tag__removeBtn {
      order: 5;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      cursor: pointer;
      font: 14px/1 Arial;
      background: none;
      background: var(--tag-remove-btn-bg, none);
      color: black;
      color: var(--tag-remove-btn-color, black);
      width: 14px;
      height: 14px;
      margin-right: 4.66667px;
      margin-left: -4.66667px;
      overflow: hidden;
      transition: .2s ease-out; }
      .tagify__tag__removeBtn::after {
        content: "\00D7";
        transition: .3s, color 0s; }
      .tagify__tag__removeBtn:hover {
        color: white;
        background: #c77777;
        background: var(--tag-remove-btn-bg--hover, #c77777); }
        .tagify__tag__removeBtn:hover + div > span {
          opacity: .5; }
        .tagify__tag__removeBtn:hover + div::before {
          box-shadow: 0 0 0 1.1em rgba(211, 148, 148, 0.3) inset !important;
          box-shadow: 0 0 0 var(--tag-inset-shadow-size, 1.1em) var(--tag-remove-bg, rgba(211, 148, 148, 0.3)) inset !important;
          transition: box-shadow .2s; }
  .tagify:not(.tagify--mix) .tagify__input br {
    display: none; }
  .tagify:not(.tagify--mix) .tagify__input * {
    display: inline;
    white-space: nowrap; }
  .tagify__input {
    flex-grow: 1;
    display: inline-block;
    min-width: 110px;
    margin: 5px;
    padding: 0.3em 0.5em;
    padding: var(--tag-pad, 0.3em 0.5em);
    line-height: inherit;
    position: relative;
    white-space: pre-wrap;
    color: inherit;
    color: var(--input-color, inherit);
    box-sizing: inherit;
    /* Seems firefox newer versions don't need this any more
        @supports ( -moz-appearance:none ){
            &::before{
                line-height: inherit;
                position:relative;
            }
        }
*/
    /*
            in "mix mode" the tags are inside the "input" element
        */ }

@-moz-document url-prefix() {}    .tagify__input:empty::before {
      transition: .2s ease-out;
      opacity: 1;
      transform: none;
      display: inline-block;
      width: auto; }
      .tagify--mix .tagify__input:empty::before {
        display: inline-block; }
    .tagify__input:focus {
      outline: none; }
      .tagify__input:focus::before {
        transition: .2s ease-out;
        opacity: 0;
        transform: translatex(6px);
        /* ALL MS BROWSERS: hide placeholder (on focus) otherwise the caret is places after it, which is weird */
        /* IE10+ CSS styles go here */
        /* IE Edge 12+ CSS styles go here */ }
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          .tagify__input:focus::before {
            display: none; } }
        @supports (-ms-ime-align: auto) {
          .tagify__input:focus::before {
            display: none; } }
      .tagify__input:focus:empty::before {
        transition: .2s ease-out;
        opacity: 1;
        transform: none;
        color: rgba(0, 0, 0, 0.25);
        color: var(--placeholder-color-focus); }

@-moz-document url-prefix() {
  .tagify__input:focus:empty::after {
    display: none; } }
    .tagify__input::before {
      content: attr(data-placeholder);
      height: 1em;
      line-height: 1em;
      margin: auto 0;
      z-index: 1;
      color: rgba(0, 0, 0, 0.4);
      color: var(--placeholder-color);
      white-space: nowrap;
      pointer-events: none;
      opacity: 0;
      position: absolute; }
      .tagify--mix .tagify__input::before {
        display: none;
        position: static;
        line-height: inherit; }
    .tagify__input::after {
      content: attr(data-suggest);
      display: inline-block;
      white-space: pre;
      /* allows spaces at the beginning */
      color: black;
      opacity: .3;
      pointer-events: none;
      max-width: 100px; }
    .tagify__input .tagify__tag {
      margin: 0; }
      .tagify__input .tagify__tag > div {
        padding-top: 0;
        padding-bottom: 0; }
  .tagify--mix {
    display: block; }
    .tagify--mix .tagify__input {
      padding: 5px;
      margin: 0;
      width: 100%;
      height: 100%;
      line-height: 1.5;
      display: block; }
      .tagify--mix .tagify__input::before {
        height: auto; }
      .tagify--mix .tagify__input::after {
        content: none; }
  .tagify--select::after {
    content: '>';
    opacity: .5;
    position: absolute;
    top: 50%;
    right: 0;
    bottom: 0;
    font: 16px monospace;
    line-height: 8px;
    height: 8px;
    pointer-events: none;
    transform: translate(-150%, -50%) scaleX(1.2) rotate(90deg);
    transition: .2s ease-in-out; }
  .tagify--select[aria-expanded=true]::after {
    transform: translate(-150%, -50%) rotate(270deg) scaleY(1.2); }
  .tagify--select .tagify__tag {
    position: absolute;
    top: 0;
    right: 1.8em;
    bottom: 0; }
    .tagify--select .tagify__tag div {
      display: none; }
  .tagify--select .tagify__input {
    width: 100%; }
  .tagify--invalid {
    --tags-border-color: #D39494; }
  .tagify__dropdown {
    position: absolute;
    z-index: 9999;
    transform: translateY(1px);
    overflow: hidden; }
    .tagify__dropdown[placement="top"] {
      margin-top: 0;
      transform: translateY(-100%); }
      .tagify__dropdown[placement="top"] .tagify__dropdown__wrapper {
        border-top-width: 1px;
        border-bottom-width: 0; }
    .tagify__dropdown[position="text"] {
      box-shadow: 0 0 0 3px rgba(var(--tagify-dd-color-primary), 0.1);
      font-size: .9em; }
      .tagify__dropdown[position="text"] .tagify__dropdown__wrapper {
        border-width: 1px; }
    .tagify__dropdown__wrapper {
      max-height: 300px;
      overflow: hidden;
      background: white;
      background: var(--tagify-dd-bg-color);
      border: 1px solid #3595f6;
      border-color: var(--tagify-dd-color-primary);
      border-width: 1.1px;
      border-top-width: 0;
      box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.2);
      transition: 0.25s cubic-bezier(0, 1, 0.5, 1); }
      .tagify__dropdown__wrapper:hover {
        overflow: auto; }
    .tagify__dropdown--initial .tagify__dropdown__wrapper {
      max-height: 20px;
      transform: translateY(-1em); }
    .tagify__dropdown--initial[placement="top"] .tagify__dropdown__wrapper {
      transform: translateY(2em); }
    .tagify__dropdown__item {
      box-sizing: inherit;
      padding: 0.3em 0.5em;
      margin: 1px;
      cursor: pointer;
      border-radius: 2px;
      position: relative;
      outline: none; }
      .tagify__dropdown__item--active {
        background: #3595f6;
        background: var(--tagify-dd-color-primary);
        color: white; }
      .tagify__dropdown__item:active {
        filter: brightness(105%); }

@media (min-width: 0) {
  .card-deck .card {
    flex: 0 0 calc(100% - 30px) !important; } }

@media (min-width: 576px) {
  .card-deck .card {
    flex: 0 0 calc(50% - 30px) !important; } }

@media (min-width: 768px) {
  .card-deck .card {
    flex: 0 0 calc(50% - 30px) !important; } }

@media (min-width: 992px) {
  .card-deck .card {
    flex: 0 0 calc(33.33333% - 30px) !important; } }

@media (min-width: 1200px) {
  .card-deck .card {
    flex: 0 0 calc(25% - 30px) !important; } }

/*!
 * Bootstrap v4.6.1 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #008BEB;
  --secondary: #6C757D;
  --success: #00B955;
  --info: #14669b;
  --warning: #ffdb49;
  --danger: #DC3545;
  --light: #f0f0f0;
  --dark: rgba(94, 94, 94, 0.87);
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Roboto", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #062336;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #008BEB;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #005e9f;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.1875rem; }

h2, .h2 {
  font-size: 1.6rem; }

h3, .h3 {
  font-size: 1.53125rem; }

h4, .h4 {
  font-size: 1.3125rem; }

h5, .h5 {
  font-size: 1.09375rem; }

h6, .h6 {
  font-size: 0.875rem; }

.lead {
  font-size: 1.09375rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.09375rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #062336; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.4rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #062336;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8dff9; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7ac3f5; }

.table-hover .table-primary:hover {
  background-color: #a0d4f7; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #a0d4f7; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #b8ebcf; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #7adba7; }

.table-hover .table-success:hover {
  background-color: #a4e5c2; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #a4e5c2; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bdd4e3; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #85afcb; }

.table-hover .table-info:hover {
  background-color: #abc8db; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abc8db; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fff5cc; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffeca0; }

.table-hover .table-warning:hover {
  background-color: #fff0b3; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #fff0b3; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fbfbfb; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #f7f7f7; }

.table-hover .table-light:hover {
  background-color: #eeeeee; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #eeeeee; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: rgba(218, 218, 218, 0.9636); }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: rgba(182, 182, 182, 0.9324); }

.table-hover .table-dark:hover {
  background-color: rgba(205, 205, 205, 0.9636); }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: rgba(205, 205, 205, 0.9636); }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d1d1d1;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #6C757D;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 139, 235, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none; }

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 0.9rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.76563rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #062336;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.76563rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #00B955; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.76563rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(0, 185, 85, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .valid-tooltip,
  .form-row > [class*="col-"] > .valid-tooltip {
    left: 5px; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #00B955;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2300B955' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #00B955;
    box-shadow: 0 0 0 0.2rem rgba(0, 185, 85, 0.25); }

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #00B955;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2300B955' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #00B955;
    box-shadow: 0 0 0 0.2rem rgba(0, 185, 85, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #00B955; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #00B955; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #00B955; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #00ec6c;
  background-color: #00ec6c; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 185, 85, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #00B955; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #00B955; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #00B955;
  box-shadow: 0 0 0 0.2rem rgba(0, 185, 85, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #DC3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.76563rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .invalid-tooltip,
  .form-row > [class*="col-"] > .invalid-tooltip {
    left: 5px; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #DC3545;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23DC3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23DC3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #DC3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #DC3545;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23DC3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23DC3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #DC3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #DC3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #DC3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #DC3545; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #DC3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #DC3545; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #DC3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #062336;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.72rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #062336;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 139, 235, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #008BEB;
  border-color: #008BEB; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0074c5;
    border-color: #006db8; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #0074c5;
    border-color: #006db8;
    box-shadow: 0 0 0 0.2rem rgba(38, 156, 238, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #008BEB;
    border-color: #008BEB; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #006db8;
    border-color: #0065ab; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 156, 238, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6C757D;
  border-color: #6C757D; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6C757D;
    border-color: #6C757D; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #fff;
  background-color: #00B955;
  border-color: #00B955; }
  .btn-success:hover {
    color: #fff;
    background-color: #009343;
    border-color: #00863e; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #009343;
    border-color: #00863e;
    box-shadow: 0 0 0 0.2rem rgba(38, 196, 111, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #00B955;
    border-color: #00B955; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #00863e;
    border-color: #007938; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 196, 111, 0.5); }

.btn-info {
  color: #fff;
  background-color: #14669b;
  border-color: #14669b; }
  .btn-info:hover {
    color: #fff;
    background-color: #105079;
    border-color: #0e486e; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #105079;
    border-color: #0e486e;
    box-shadow: 0 0 0 0.2rem rgba(55, 125, 170, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #14669b;
    border-color: #14669b; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #0e486e;
    border-color: #0d4163; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(55, 125, 170, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffdb49;
  border-color: #ffdb49; }
  .btn-warning:hover {
    color: #212529;
    background-color: #ffd323;
    border-color: #ffd116; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #ffd323;
    border-color: #ffd116;
    box-shadow: 0 0 0 0.2rem rgba(222, 192, 68, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffdb49;
    border-color: #ffdb49; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffd116;
    border-color: #ffce09; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 192, 68, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #DC3545;
  border-color: #DC3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #DC3545;
    border-color: #DC3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f0f0f0;
  border-color: #f0f0f0; }
  .btn-light:hover {
    color: #212529;
    background-color: #dddddd;
    border-color: #d7d7d7; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #dddddd;
    border-color: #d7d7d7;
    box-shadow: 0 0 0 0.2rem rgba(209, 210, 210, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f0f0f0;
    border-color: #f0f0f0; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #d7d7d7;
    border-color: #d0d0d0; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(209, 210, 210, 0.5); }

.btn-dark {
  color: #fff;
  background-color: rgba(94, 94, 94, 0.87);
  border-color: rgba(94, 94, 94, 0.87); }
  .btn-dark:hover {
    color: #fff;
    background-color: rgba(75, 75, 75, 0.87);
    border-color: rgba(69, 69, 69, 0.87); }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: rgba(75, 75, 75, 0.87);
    border-color: rgba(69, 69, 69, 0.87);
    box-shadow: 0 0 0 0.2rem rgba(124, 124, 124, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: rgba(94, 94, 94, 0.87);
    border-color: rgba(94, 94, 94, 0.87); }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: rgba(69, 69, 69, 0.87);
    border-color: rgba(62, 62, 62, 0.87); }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(124, 124, 124, 0.5); }

.btn-outline-primary {
  color: #008BEB;
  border-color: #008BEB; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #008BEB;
    border-color: #008BEB; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 139, 235, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #008BEB;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #008BEB;
    border-color: #008BEB; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 139, 235, 0.5); }

.btn-outline-secondary {
  color: #6C757D;
  border-color: #6C757D; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6C757D;
    border-color: #6C757D; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6C757D;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6C757D;
    border-color: #6C757D; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #00B955;
  border-color: #00B955; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #00B955;
    border-color: #00B955; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 185, 85, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #00B955;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #00B955;
    border-color: #00B955; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 185, 85, 0.5); }

.btn-outline-info {
  color: #14669b;
  border-color: #14669b; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #14669b;
    border-color: #14669b; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(20, 102, 155, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #14669b;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #14669b;
    border-color: #14669b; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(20, 102, 155, 0.5); }

.btn-outline-warning {
  color: #ffdb49;
  border-color: #ffdb49; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffdb49;
    border-color: #ffdb49; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 219, 73, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffdb49;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffdb49;
    border-color: #ffdb49; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 219, 73, 0.5); }

.btn-outline-danger {
  color: #DC3545;
  border-color: #DC3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #DC3545;
    border-color: #DC3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #DC3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #DC3545;
    border-color: #DC3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f0f0f0;
  border-color: #f0f0f0; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f0f0f0;
    border-color: #f0f0f0; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(240, 240, 240, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f0f0f0;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f0f0f0;
    border-color: #f0f0f0; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(240, 240, 240, 0.5); }

.btn-outline-dark {
  color: rgba(94, 94, 94, 0.87);
  border-color: rgba(94, 94, 94, 0.87); }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: rgba(94, 94, 94, 0.87);
    border-color: rgba(94, 94, 94, 0.87); }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(94, 94, 94, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: rgba(94, 94, 94, 0.87);
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: rgba(94, 94, 94, 0.87);
    border-color: rgba(94, 94, 94, 0.87); }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(94, 94, 94, 0.5); }

.btn-link {
  font-weight: 400;
  color: #008BEB;
  text-decoration: none; }
  .btn-link:hover {
    color: #005e9f;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.76563rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  color: #062336;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #e9ecef; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #008BEB; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.76563rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.54rem;
  padding-left: 0.54rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
  .input-group:not(.has-validation) > .form-control:not(:last-child),
  .input-group:not(.has-validation) > .custom-select:not(:last-child),
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group.has-validation > .form-control:nth-last-child(n + 3),
  .input-group.has-validation > .custom-select:nth-last-child(n + 3),
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label,
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #d1d1d1;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.76563rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.3125rem;
  padding-left: 1.5rem;
  color-adjust: exact; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.15625rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #008BEB;
    background-color: #008BEB; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 139, 235, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #6C757D; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #9fd8ff;
    border-color: #9fd8ff; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.15625rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.15625rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: 50% / 50% 50% no-repeat; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #008BEB;
  background-color: #008BEB; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 139, 235, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 139, 235, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 139, 235, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.15625rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 139, 235, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #d1d1d1;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #6C757D;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 139, 235, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.76563rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 0.9rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #6C757D;
    box-shadow: 0 0 0 0.2rem rgba(0, 139, 235, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #d1d1d1;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: 0; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 139, 235, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 139, 235, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 139, 235, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #008BEB;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #9fd8ff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #008BEB;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #9fd8ff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #008BEB;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #9fd8ff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #008BEB; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.33594rem;
  padding-bottom: 0.33594rem;
  margin-right: 1rem;
  font-size: 1.09375rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.09375rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat; }

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #e4e5e8; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #e4e5e8; }

.navbar-dark .navbar-nav .nav-link {
  color: #e4e5e8; }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: #008BEB; }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #e4e5e8; }

.navbar-dark .navbar-toggler {
  color: #e4e5e8;
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='%23e4e5e8' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: #e4e5e8; }
  .navbar-dark .navbar-text a {
    color: #e4e5e8; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #e4e5e8; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #f0f0f0;
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.5rem; }

.card-title {
  margin-bottom: 1.5rem; }

.card-subtitle {
  margin-top: -0.75rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.5rem; }

.card-header {
  padding: 1.5rem 1.5rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid #f0f0f0; }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }

.card-footer {
  padding: 1.5rem 1.5rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid #f0f0f0; }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.75rem;
  margin-bottom: -1.5rem;
  margin-left: -0.75rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.75rem;
  margin-left: -0.75rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 1.5rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion {
  overflow-anchor: none; }
  .accordion > .card {
    overflow: hidden; }
    .accordion > .card:not(:last-of-type) {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    .accordion > .card:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .accordion > .card > .card-header {
      border-radius: 0;
      margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: transparent;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #008BEB; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #008BEB;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #005e9f;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 139, 235, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #6C757D;
  border-color: #6C757D; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.09375rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.76563rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #008BEB; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #006db8; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 139, 235, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #6C757D; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #545b62; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.badge-success {
  color: #fff;
  background-color: #00B955; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #00863e; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 185, 85, 0.5); }

.badge-info {
  color: #fff;
  background-color: #14669b; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #0e486e; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(20, 102, 155, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffdb49; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #ffd116; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 219, 73, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #DC3545; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f0f0f0; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #d7d7d7; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(240, 240, 240, 0.5); }

.badge-dark {
  color: #fff;
  background-color: rgba(94, 94, 94, 0.87); }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: rgba(69, 69, 69, 0.87); }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(94, 94, 94, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 3.8125rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #00487a;
  background-color: #cce8fb;
  border-color: #b8dff9; }
  .alert-primary hr {
    border-top-color: #a0d4f7; }
  .alert-primary .alert-link {
    color: #002a47; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #00602c;
  background-color: #ccf1dd;
  border-color: #b8ebcf; }
  .alert-success hr {
    border-top-color: #a4e5c2; }
  .alert-success .alert-link {
    color: #002d15; }

.alert-info {
  color: #0a3551;
  background-color: #d0e0eb;
  border-color: #bdd4e3; }
  .alert-info hr {
    border-top-color: #abc8db; }
  .alert-info .alert-link {
    color: #041724; }

.alert-warning {
  color: #857226;
  background-color: #fff8db;
  border-color: #fff5cc; }
  .alert-warning hr {
    border-top-color: #fff0b3; }
  .alert-warning .alert-link {
    color: #5d501b; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #7d7d7d;
  background-color: #fcfcfc;
  border-color: #fbfbfb; }
  .alert-light hr {
    border-top-color: #eeeeee; }
  .alert-light .alert-link {
    color: #646464; }

.alert-dark {
  color: rgba(43, 43, 43, 0.9324);
  background-color: rgba(229, 229, 229, 0.974);
  border-color: rgba(218, 218, 218, 0.9636); }
  .alert-dark hr {
    border-top-color: rgba(205, 205, 205, 0.9636); }
  .alert-dark .alert-link {
    color: rgba(18, 18, 18, 0.9324); }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.65625rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #008BEB;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #062336;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #008BEB;
    border-color: #008BEB; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #00487a;
  background-color: #b8dff9; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #00487a;
    background-color: #a0d4f7; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #00487a;
    border-color: #00487a; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #00602c;
  background-color: #b8ebcf; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #00602c;
    background-color: #a4e5c2; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #00602c;
    border-color: #00602c; }

.list-group-item-info {
  color: #0a3551;
  background-color: #bdd4e3; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0a3551;
    background-color: #abc8db; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0a3551;
    border-color: #0a3551; }

.list-group-item-warning {
  color: #857226;
  background-color: #fff5cc; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #857226;
    background-color: #fff0b3; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #857226;
    border-color: #857226; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #7d7d7d;
  background-color: #fbfbfb; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #7d7d7d;
    background-color: #eeeeee; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #7d7d7d;
    border-color: #7d7d7d; }

.list-group-item-dark {
  color: rgba(43, 43, 43, 0.9324);
  background-color: rgba(218, 218, 218, 0.9636); }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: rgba(43, 43, 43, 0.9324);
    background-color: rgba(205, 205, 205, 0.9636); }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: rgba(43, 43, 43, 0.9324);
    border-color: rgba(43, 43, 43, 0.9324); }

.close {
  float: right;
  font-size: 1.3125rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: min-content; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.76563rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.76563rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #062336; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: .75s linear infinite spinner-border; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: .75s linear infinite spinner-grow; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #008BEB !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #006db8 !important; }

.bg-secondary {
  background-color: #6C757D !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #00B955 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #00863e !important; }

.bg-info {
  background-color: #14669b !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #0e486e !important; }

.bg-warning {
  background-color: #ffdb49 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #ffd116 !important; }

.bg-danger {
  background-color: #DC3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f0f0f0 !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #d7d7d7 !important; }

.bg-dark {
  background-color: rgba(94, 94, 94, 0.87) !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: rgba(69, 69, 69, 0.87) !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #008BEB !important; }

.border-secondary {
  border-color: #6C757D !important; }

.border-success {
  border-color: #00B955 !important; }

.border-info {
  border-color: #14669b !important; }

.border-warning {
  border-color: #ffdb49 !important; }

.border-danger {
  border-color: #DC3545 !important; }

.border-light {
  border-color: #f0f0f0 !important; }

.border-dark {
  border-color: rgba(94, 94, 94, 0.87) !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3.5rem rgba(150, 157, 186, 0.75) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #008BEB !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #005e9f !important; }

.text-secondary {
  color: #6C757D !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #00B955 !important; }

a.text-success:hover, a.text-success:focus {
  color: #006d32 !important; }

.text-info {
  color: #14669b !important; }

a.text-info:hover, a.text-info:focus {
  color: #0b3957 !important; }

.text-warning {
  color: #ffdb49 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #fcca00 !important; }

.text-danger {
  color: #DC3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f0f0f0 !important; }

a.text-light:hover, a.text-light:focus {
  color: #cacaca !important; }

.text-dark {
  color: rgba(94, 94, 94, 0.87) !important; }

a.text-dark:hover, a.text-dark:focus {
  color: rgba(56, 56, 56, 0.87) !important; }

.text-body {
  color: #062336 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

/*===============================
=            Choices            =
===============================*/
.choices {
  position: relative;
  margin-bottom: 24px;
  font-size: 16px; }

.choices:focus {
  outline: none; }

.choices:last-child {
  margin-bottom: 0; }

.choices.is-disabled .choices__inner,
.choices.is-disabled .choices__input {
  background-color: #EAEAEA;
  cursor: not-allowed;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.choices.is-disabled .choices__item {
  cursor: not-allowed; }

.choices[data-type*="select-one"] {
  cursor: pointer; }

.choices[data-type*="select-one"] .choices__inner {
  padding-bottom: 7.5px; }

.choices[data-type*="select-one"] .choices__input {
  display: block;
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid #DDDDDD;
  background-color: #FFFFFF;
  margin: 0; }

.choices[data-type*="select-one"] .choices__button {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==);
  padding: 0;
  background-size: 8px;
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -10px;
  margin-right: 25px;
  height: 20px;
  width: 20px;
  border-radius: 10em;
  opacity: .5; }

.choices[data-type*="select-one"] .choices__button:hover, .choices[data-type*="select-one"] .choices__button:focus {
  opacity: 1; }

.choices[data-type*="select-one"] .choices__button:focus {
  box-shadow: 0px 0px 0px 2px #00BCD4; }

.choices[data-type*="select-one"]:after {
  content: "";
  height: 0;
  width: 0;
  border-style: solid;
  border-color: #333333 transparent transparent transparent;
  border-width: 5px;
  position: absolute;
  right: 11.5px;
  top: 50%;
  margin-top: -2.5px;
  pointer-events: none; }

.choices[data-type*="select-one"].is-open:after {
  border-color: transparent transparent #333333 transparent;
  margin-top: -7.5px; }

.choices[data-type*="select-one"][dir="rtl"]:after {
  left: 11.5px;
  right: auto; }

.choices[data-type*="select-one"][dir="rtl"] .choices__button {
  right: auto;
  left: 0;
  margin-left: 25px;
  margin-right: 0; }

.choices[data-type*="select-multiple"] .choices__inner,
.choices[data-type*="text"] .choices__inner {
  cursor: text; }

.choices[data-type*="select-multiple"] .choices__button,
.choices[data-type*="text"] .choices__button {
  position: relative;
  display: inline-block;
  margin-top: 0;
  margin-right: -4px;
  margin-bottom: 0;
  margin-left: 8px;
  padding-left: 16px;
  border-left: 1px solid #008fa1;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==);
  background-size: 8px;
  width: 8px;
  line-height: 1;
  opacity: .75;
  border-radius: 0; }

.choices[data-type*="select-multiple"] .choices__button:hover, .choices[data-type*="select-multiple"] .choices__button:focus,
.choices[data-type*="text"] .choices__button:hover,
.choices[data-type*="text"] .choices__button:focus {
  opacity: 1; }

.choices__inner {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  background-color: #f9f9f9;
  padding: 7.5px 7.5px 3.75px;
  border: 1px solid #DDDDDD;
  border-radius: 2.5px;
  font-size: 14px;
  min-height: 44px;
  overflow: hidden; }

.is-focused .choices__inner,
.is-open .choices__inner {
  border-color: #b7b7b7; }

.is-open .choices__inner {
  border-radius: 2.5px 2.5px 0 0; }

.is-flipped.is-open .choices__inner {
  border-radius: 0 0 2.5px 2.5px; }

.choices__list {
  margin: 0;
  padding-left: 0;
  list-style: none; }

.choices__list--single {
  display: inline-block;
  padding: 4px 16px 4px 4px;
  width: 100%; }

[dir="rtl"] .choices__list--single {
  padding-right: 4px;
  padding-left: 16px; }

.choices__list--single .choices__item {
  width: 100%; }

.choices__list--multiple {
  display: inline; }

.choices__list--multiple .choices__item {
  display: inline-block;
  vertical-align: middle;
  border-radius: 20px;
  padding: 4px 10px;
  font-size: 12px;
  font-weight: 500;
  margin-right: 3.75px;
  margin-bottom: 3.75px;
  background-color: #00BCD4;
  border: 1px solid #00a5bb;
  color: #FFFFFF;
  word-break: break-all; }

.choices__list--multiple .choices__item[data-deletable] {
  padding-right: 5px; }

[dir="rtl"] .choices__list--multiple .choices__item {
  margin-right: 0;
  margin-left: 3.75px; }

.choices__list--multiple .choices__item.is-highlighted {
  background-color: #00a5bb;
  border: 1px solid #008fa1; }

.is-disabled .choices__list--multiple .choices__item {
  background-color: #aaaaaa;
  border: 1px solid #919191; }

.choices__list--dropdown {
  display: none;
  z-index: 1;
  position: absolute;
  width: 100%;
  background-color: #FFFFFF;
  border: 1px solid #DDDDDD;
  top: 100%;
  margin-top: -1px;
  border-bottom-left-radius: 2.5px;
  border-bottom-right-radius: 2.5px;
  overflow: hidden;
  word-break: break-all; }

.choices__list--dropdown.is-active {
  display: block; }

.is-open .choices__list--dropdown {
  border-color: #b7b7b7; }

.is-flipped .choices__list--dropdown {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: -1px;
  border-radius: .25rem .25rem 0 0; }

.choices__list--dropdown .choices__list {
  position: relative;
  max-height: 300px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  will-change: scroll-position; }

.choices__list--dropdown .choices__item {
  position: relative;
  padding: 10px;
  font-size: 14px; }

[dir="rtl"] .choices__list--dropdown .choices__item {
  text-align: right; }

@media (min-width: 640px) {
  .choices__list--dropdown .choices__item--selectable {
    padding-right: 100px; }
  .choices__list--dropdown .choices__item--selectable:after {
    content: attr(data-select-text);
    font-size: 12px;
    opacity: 0;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%); }
  [dir="rtl"] .choices__list--dropdown .choices__item--selectable {
    text-align: right;
    padding-left: 100px;
    padding-right: 10px; }
  [dir="rtl"] .choices__list--dropdown .choices__item--selectable:after {
    right: auto;
    left: 10px; } }

.choices__list--dropdown .choices__item--selectable.is-highlighted {
  background-color: #f2f2f2; }

.choices__list--dropdown .choices__item--selectable.is-highlighted:after {
  opacity: .5; }

.choices__item {
  cursor: default; }

.choices__item--selectable {
  cursor: pointer; }

.choices__item--disabled {
  cursor: not-allowed;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  opacity: .5; }

.choices__heading {
  font-weight: 600;
  font-size: 12px;
  padding: 10px;
  border-bottom: 1px solid #f7f7f7;
  color: gray; }

.choices__button {
  text-indent: -9999px;
  -webkit-appearance: none;
  appearance: none;
  border: 0;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer; }

.choices__button:focus {
  outline: none; }

.choices__input {
  display: inline-block;
  vertical-align: baseline;
  background-color: #f9f9f9;
  font-size: 14px;
  margin-bottom: 5px;
  border: 0;
  border-radius: 0;
  max-width: 100%;
  padding: 4px 0 4px 2px; }

.choices__input:focus {
  outline: 0; }

[dir="rtl"] .choices__input {
  padding-right: 2px;
  padding-left: 0; }

.choices__placeholder {
  opacity: .5; }

.choices__input.is-hidden,
.choices[data-type*="select-one"] .choices__input.is-hidden,
.choices[data-type*="select-multiple"] .choices__input.is-hidden {
  display: none; }

/*=====  End of Choices  ======*/
.flag-icon-background, .flag-icon {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat; }

.flag-icon {
  position: relative;
  display: inline-block;
  width: 1.33333em;
  line-height: 1em; }
  .flag-icon:before {
    content: '\00a0'; }
  .flag-icon.flag-icon-squared {
    width: 1em; }

.flag-icon-ad {
  background-image: url(~flag-icon-css/flags/4x3/ad.svg); }
  .flag-icon-ad.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ad.svg); }

.flag-icon-ae {
  background-image: url(~flag-icon-css/flags/4x3/ae.svg); }
  .flag-icon-ae.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ae.svg); }

.flag-icon-af {
  background-image: url(~flag-icon-css/flags/4x3/af.svg); }
  .flag-icon-af.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/af.svg); }

.flag-icon-ag {
  background-image: url(~flag-icon-css/flags/4x3/ag.svg); }
  .flag-icon-ag.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ag.svg); }

.flag-icon-ai {
  background-image: url(~flag-icon-css/flags/4x3/ai.svg); }
  .flag-icon-ai.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ai.svg); }

.flag-icon-al {
  background-image: url(~flag-icon-css/flags/4x3/al.svg); }
  .flag-icon-al.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/al.svg); }

.flag-icon-am {
  background-image: url(~flag-icon-css/flags/4x3/am.svg); }
  .flag-icon-am.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/am.svg); }

.flag-icon-ao {
  background-image: url(~flag-icon-css/flags/4x3/ao.svg); }
  .flag-icon-ao.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ao.svg); }

.flag-icon-aq {
  background-image: url(~flag-icon-css/flags/4x3/aq.svg); }
  .flag-icon-aq.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/aq.svg); }

.flag-icon-ar {
  background-image: url(~flag-icon-css/flags/4x3/ar.svg); }
  .flag-icon-ar.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ar.svg); }

.flag-icon-as {
  background-image: url(~flag-icon-css/flags/4x3/as.svg); }
  .flag-icon-as.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/as.svg); }

.flag-icon-at {
  background-image: url(~flag-icon-css/flags/4x3/at.svg); }
  .flag-icon-at.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/at.svg); }

.flag-icon-au {
  background-image: url(~flag-icon-css/flags/4x3/au.svg); }
  .flag-icon-au.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/au.svg); }

.flag-icon-aw {
  background-image: url(~flag-icon-css/flags/4x3/aw.svg); }
  .flag-icon-aw.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/aw.svg); }

.flag-icon-ax {
  background-image: url(~flag-icon-css/flags/4x3/ax.svg); }
  .flag-icon-ax.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ax.svg); }

.flag-icon-az {
  background-image: url(~flag-icon-css/flags/4x3/az.svg); }
  .flag-icon-az.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/az.svg); }

.flag-icon-ba {
  background-image: url(~flag-icon-css/flags/4x3/ba.svg); }
  .flag-icon-ba.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ba.svg); }

.flag-icon-bb {
  background-image: url(~flag-icon-css/flags/4x3/bb.svg); }
  .flag-icon-bb.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/bb.svg); }

.flag-icon-bd {
  background-image: url(~flag-icon-css/flags/4x3/bd.svg); }
  .flag-icon-bd.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/bd.svg); }

.flag-icon-be {
  background-image: url(~flag-icon-css/flags/4x3/be.svg); }
  .flag-icon-be.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/be.svg); }

.flag-icon-bf {
  background-image: url(~flag-icon-css/flags/4x3/bf.svg); }
  .flag-icon-bf.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/bf.svg); }

.flag-icon-bg {
  background-image: url(~flag-icon-css/flags/4x3/bg.svg); }
  .flag-icon-bg.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/bg.svg); }

.flag-icon-bh {
  background-image: url(~flag-icon-css/flags/4x3/bh.svg); }
  .flag-icon-bh.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/bh.svg); }

.flag-icon-bi {
  background-image: url(~flag-icon-css/flags/4x3/bi.svg); }
  .flag-icon-bi.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/bi.svg); }

.flag-icon-bj {
  background-image: url(~flag-icon-css/flags/4x3/bj.svg); }
  .flag-icon-bj.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/bj.svg); }

.flag-icon-bl {
  background-image: url(~flag-icon-css/flags/4x3/bl.svg); }
  .flag-icon-bl.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/bl.svg); }

.flag-icon-bm {
  background-image: url(~flag-icon-css/flags/4x3/bm.svg); }
  .flag-icon-bm.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/bm.svg); }

.flag-icon-bn {
  background-image: url(~flag-icon-css/flags/4x3/bn.svg); }
  .flag-icon-bn.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/bn.svg); }

.flag-icon-bo {
  background-image: url(~flag-icon-css/flags/4x3/bo.svg); }
  .flag-icon-bo.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/bo.svg); }

.flag-icon-bq {
  background-image: url(~flag-icon-css/flags/4x3/bq.svg); }
  .flag-icon-bq.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/bq.svg); }

.flag-icon-br {
  background-image: url(~flag-icon-css/flags/4x3/br.svg); }
  .flag-icon-br.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/br.svg); }

.flag-icon-bs {
  background-image: url(~flag-icon-css/flags/4x3/bs.svg); }
  .flag-icon-bs.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/bs.svg); }

.flag-icon-bt {
  background-image: url(~flag-icon-css/flags/4x3/bt.svg); }
  .flag-icon-bt.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/bt.svg); }

.flag-icon-bv {
  background-image: url(~flag-icon-css/flags/4x3/bv.svg); }
  .flag-icon-bv.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/bv.svg); }

.flag-icon-bw {
  background-image: url(~flag-icon-css/flags/4x3/bw.svg); }
  .flag-icon-bw.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/bw.svg); }

.flag-icon-by {
  background-image: url(~flag-icon-css/flags/4x3/by.svg); }
  .flag-icon-by.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/by.svg); }

.flag-icon-bz {
  background-image: url(~flag-icon-css/flags/4x3/bz.svg); }
  .flag-icon-bz.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/bz.svg); }

.flag-icon-ca {
  background-image: url(~flag-icon-css/flags/4x3/ca.svg); }
  .flag-icon-ca.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ca.svg); }

.flag-icon-cc {
  background-image: url(~flag-icon-css/flags/4x3/cc.svg); }
  .flag-icon-cc.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/cc.svg); }

.flag-icon-cd {
  background-image: url(~flag-icon-css/flags/4x3/cd.svg); }
  .flag-icon-cd.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/cd.svg); }

.flag-icon-cf {
  background-image: url(~flag-icon-css/flags/4x3/cf.svg); }
  .flag-icon-cf.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/cf.svg); }

.flag-icon-cg {
  background-image: url(~flag-icon-css/flags/4x3/cg.svg); }
  .flag-icon-cg.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/cg.svg); }

.flag-icon-ch {
  background-image: url(~flag-icon-css/flags/4x3/ch.svg); }
  .flag-icon-ch.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ch.svg); }

.flag-icon-ci {
  background-image: url(~flag-icon-css/flags/4x3/ci.svg); }
  .flag-icon-ci.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ci.svg); }

.flag-icon-ck {
  background-image: url(~flag-icon-css/flags/4x3/ck.svg); }
  .flag-icon-ck.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ck.svg); }

.flag-icon-cl {
  background-image: url(~flag-icon-css/flags/4x3/cl.svg); }
  .flag-icon-cl.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/cl.svg); }

.flag-icon-cm {
  background-image: url(~flag-icon-css/flags/4x3/cm.svg); }
  .flag-icon-cm.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/cm.svg); }

.flag-icon-cn {
  background-image: url(~flag-icon-css/flags/4x3/cn.svg); }
  .flag-icon-cn.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/cn.svg); }

.flag-icon-co {
  background-image: url(~flag-icon-css/flags/4x3/co.svg); }
  .flag-icon-co.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/co.svg); }

.flag-icon-cr {
  background-image: url(~flag-icon-css/flags/4x3/cr.svg); }
  .flag-icon-cr.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/cr.svg); }

.flag-icon-cu {
  background-image: url(~flag-icon-css/flags/4x3/cu.svg); }
  .flag-icon-cu.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/cu.svg); }

.flag-icon-cv {
  background-image: url(~flag-icon-css/flags/4x3/cv.svg); }
  .flag-icon-cv.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/cv.svg); }

.flag-icon-cw {
  background-image: url(~flag-icon-css/flags/4x3/cw.svg); }
  .flag-icon-cw.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/cw.svg); }

.flag-icon-cx {
  background-image: url(~flag-icon-css/flags/4x3/cx.svg); }
  .flag-icon-cx.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/cx.svg); }

.flag-icon-cy {
  background-image: url(~flag-icon-css/flags/4x3/cy.svg); }
  .flag-icon-cy.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/cy.svg); }

.flag-icon-cz {
  background-image: url(~flag-icon-css/flags/4x3/cz.svg); }
  .flag-icon-cz.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/cz.svg); }

.flag-icon-de {
  background-image: url(~flag-icon-css/flags/4x3/de.svg); }
  .flag-icon-de.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/de.svg); }

.flag-icon-dj {
  background-image: url(~flag-icon-css/flags/4x3/dj.svg); }
  .flag-icon-dj.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/dj.svg); }

.flag-icon-dk {
  background-image: url(~flag-icon-css/flags/4x3/dk.svg); }
  .flag-icon-dk.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/dk.svg); }

.flag-icon-dm {
  background-image: url(~flag-icon-css/flags/4x3/dm.svg); }
  .flag-icon-dm.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/dm.svg); }

.flag-icon-do {
  background-image: url(~flag-icon-css/flags/4x3/do.svg); }
  .flag-icon-do.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/do.svg); }

.flag-icon-dz {
  background-image: url(~flag-icon-css/flags/4x3/dz.svg); }
  .flag-icon-dz.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/dz.svg); }

.flag-icon-ec {
  background-image: url(~flag-icon-css/flags/4x3/ec.svg); }
  .flag-icon-ec.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ec.svg); }

.flag-icon-ee {
  background-image: url(~flag-icon-css/flags/4x3/ee.svg); }
  .flag-icon-ee.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ee.svg); }

.flag-icon-eg {
  background-image: url(~flag-icon-css/flags/4x3/eg.svg); }
  .flag-icon-eg.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/eg.svg); }

.flag-icon-eh {
  background-image: url(~flag-icon-css/flags/4x3/eh.svg); }
  .flag-icon-eh.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/eh.svg); }

.flag-icon-er {
  background-image: url(~flag-icon-css/flags/4x3/er.svg); }
  .flag-icon-er.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/er.svg); }

.flag-icon-es {
  background-image: url(~flag-icon-css/flags/4x3/es.svg); }
  .flag-icon-es.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/es.svg); }

.flag-icon-et {
  background-image: url(~flag-icon-css/flags/4x3/et.svg); }
  .flag-icon-et.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/et.svg); }

.flag-icon-fi {
  background-image: url(~flag-icon-css/flags/4x3/fi.svg); }
  .flag-icon-fi.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/fi.svg); }

.flag-icon-fj {
  background-image: url(~flag-icon-css/flags/4x3/fj.svg); }
  .flag-icon-fj.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/fj.svg); }

.flag-icon-fk {
  background-image: url(~flag-icon-css/flags/4x3/fk.svg); }
  .flag-icon-fk.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/fk.svg); }

.flag-icon-fm {
  background-image: url(~flag-icon-css/flags/4x3/fm.svg); }
  .flag-icon-fm.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/fm.svg); }

.flag-icon-fo {
  background-image: url(~flag-icon-css/flags/4x3/fo.svg); }
  .flag-icon-fo.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/fo.svg); }

.flag-icon-fr {
  background-image: url(~flag-icon-css/flags/4x3/fr.svg); }
  .flag-icon-fr.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/fr.svg); }

.flag-icon-ga {
  background-image: url(~flag-icon-css/flags/4x3/ga.svg); }
  .flag-icon-ga.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ga.svg); }

.flag-icon-gb {
  background-image: url(~flag-icon-css/flags/4x3/gb.svg); }
  .flag-icon-gb.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/gb.svg); }

.flag-icon-gd {
  background-image: url(~flag-icon-css/flags/4x3/gd.svg); }
  .flag-icon-gd.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/gd.svg); }

.flag-icon-ge {
  background-image: url(~flag-icon-css/flags/4x3/ge.svg); }
  .flag-icon-ge.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ge.svg); }

.flag-icon-gf {
  background-image: url(~flag-icon-css/flags/4x3/gf.svg); }
  .flag-icon-gf.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/gf.svg); }

.flag-icon-gg {
  background-image: url(~flag-icon-css/flags/4x3/gg.svg); }
  .flag-icon-gg.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/gg.svg); }

.flag-icon-gh {
  background-image: url(~flag-icon-css/flags/4x3/gh.svg); }
  .flag-icon-gh.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/gh.svg); }

.flag-icon-gi {
  background-image: url(~flag-icon-css/flags/4x3/gi.svg); }
  .flag-icon-gi.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/gi.svg); }

.flag-icon-gl {
  background-image: url(~flag-icon-css/flags/4x3/gl.svg); }
  .flag-icon-gl.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/gl.svg); }

.flag-icon-gm {
  background-image: url(~flag-icon-css/flags/4x3/gm.svg); }
  .flag-icon-gm.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/gm.svg); }

.flag-icon-gn {
  background-image: url(~flag-icon-css/flags/4x3/gn.svg); }
  .flag-icon-gn.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/gn.svg); }

.flag-icon-gp {
  background-image: url(~flag-icon-css/flags/4x3/gp.svg); }
  .flag-icon-gp.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/gp.svg); }

.flag-icon-gq {
  background-image: url(~flag-icon-css/flags/4x3/gq.svg); }
  .flag-icon-gq.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/gq.svg); }

.flag-icon-gr {
  background-image: url(~flag-icon-css/flags/4x3/gr.svg); }
  .flag-icon-gr.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/gr.svg); }

.flag-icon-gs {
  background-image: url(~flag-icon-css/flags/4x3/gs.svg); }
  .flag-icon-gs.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/gs.svg); }

.flag-icon-gt {
  background-image: url(~flag-icon-css/flags/4x3/gt.svg); }
  .flag-icon-gt.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/gt.svg); }

.flag-icon-gu {
  background-image: url(~flag-icon-css/flags/4x3/gu.svg); }
  .flag-icon-gu.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/gu.svg); }

.flag-icon-gw {
  background-image: url(~flag-icon-css/flags/4x3/gw.svg); }
  .flag-icon-gw.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/gw.svg); }

.flag-icon-gy {
  background-image: url(~flag-icon-css/flags/4x3/gy.svg); }
  .flag-icon-gy.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/gy.svg); }

.flag-icon-hk {
  background-image: url(~flag-icon-css/flags/4x3/hk.svg); }
  .flag-icon-hk.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/hk.svg); }

.flag-icon-hm {
  background-image: url(~flag-icon-css/flags/4x3/hm.svg); }
  .flag-icon-hm.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/hm.svg); }

.flag-icon-hn {
  background-image: url(~flag-icon-css/flags/4x3/hn.svg); }
  .flag-icon-hn.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/hn.svg); }

.flag-icon-hr {
  background-image: url(~flag-icon-css/flags/4x3/hr.svg); }
  .flag-icon-hr.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/hr.svg); }

.flag-icon-ht {
  background-image: url(~flag-icon-css/flags/4x3/ht.svg); }
  .flag-icon-ht.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ht.svg); }

.flag-icon-hu {
  background-image: url(~flag-icon-css/flags/4x3/hu.svg); }
  .flag-icon-hu.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/hu.svg); }

.flag-icon-id {
  background-image: url(~flag-icon-css/flags/4x3/id.svg); }
  .flag-icon-id.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/id.svg); }

.flag-icon-ie {
  background-image: url(~flag-icon-css/flags/4x3/ie.svg); }
  .flag-icon-ie.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ie.svg); }

.flag-icon-il {
  background-image: url(~flag-icon-css/flags/4x3/il.svg); }
  .flag-icon-il.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/il.svg); }

.flag-icon-im {
  background-image: url(~flag-icon-css/flags/4x3/im.svg); }
  .flag-icon-im.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/im.svg); }

.flag-icon-in {
  background-image: url(~flag-icon-css/flags/4x3/in.svg); }
  .flag-icon-in.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/in.svg); }

.flag-icon-io {
  background-image: url(~flag-icon-css/flags/4x3/io.svg); }
  .flag-icon-io.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/io.svg); }

.flag-icon-iq {
  background-image: url(~flag-icon-css/flags/4x3/iq.svg); }
  .flag-icon-iq.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/iq.svg); }

.flag-icon-ir {
  background-image: url(~flag-icon-css/flags/4x3/ir.svg); }
  .flag-icon-ir.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ir.svg); }

.flag-icon-is {
  background-image: url(~flag-icon-css/flags/4x3/is.svg); }
  .flag-icon-is.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/is.svg); }

.flag-icon-it {
  background-image: url(~flag-icon-css/flags/4x3/it.svg); }
  .flag-icon-it.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/it.svg); }

.flag-icon-je {
  background-image: url(~flag-icon-css/flags/4x3/je.svg); }
  .flag-icon-je.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/je.svg); }

.flag-icon-jm {
  background-image: url(~flag-icon-css/flags/4x3/jm.svg); }
  .flag-icon-jm.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/jm.svg); }

.flag-icon-jo {
  background-image: url(~flag-icon-css/flags/4x3/jo.svg); }
  .flag-icon-jo.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/jo.svg); }

.flag-icon-jp {
  background-image: url(~flag-icon-css/flags/4x3/jp.svg); }
  .flag-icon-jp.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/jp.svg); }

.flag-icon-ke {
  background-image: url(~flag-icon-css/flags/4x3/ke.svg); }
  .flag-icon-ke.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ke.svg); }

.flag-icon-kg {
  background-image: url(~flag-icon-css/flags/4x3/kg.svg); }
  .flag-icon-kg.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/kg.svg); }

.flag-icon-kh {
  background-image: url(~flag-icon-css/flags/4x3/kh.svg); }
  .flag-icon-kh.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/kh.svg); }

.flag-icon-ki {
  background-image: url(~flag-icon-css/flags/4x3/ki.svg); }
  .flag-icon-ki.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ki.svg); }

.flag-icon-km {
  background-image: url(~flag-icon-css/flags/4x3/km.svg); }
  .flag-icon-km.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/km.svg); }

.flag-icon-kn {
  background-image: url(~flag-icon-css/flags/4x3/kn.svg); }
  .flag-icon-kn.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/kn.svg); }

.flag-icon-kp {
  background-image: url(~flag-icon-css/flags/4x3/kp.svg); }
  .flag-icon-kp.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/kp.svg); }

.flag-icon-kr {
  background-image: url(~flag-icon-css/flags/4x3/kr.svg); }
  .flag-icon-kr.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/kr.svg); }

.flag-icon-kw {
  background-image: url(~flag-icon-css/flags/4x3/kw.svg); }
  .flag-icon-kw.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/kw.svg); }

.flag-icon-ky {
  background-image: url(~flag-icon-css/flags/4x3/ky.svg); }
  .flag-icon-ky.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ky.svg); }

.flag-icon-kz {
  background-image: url(~flag-icon-css/flags/4x3/kz.svg); }
  .flag-icon-kz.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/kz.svg); }

.flag-icon-la {
  background-image: url(~flag-icon-css/flags/4x3/la.svg); }
  .flag-icon-la.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/la.svg); }

.flag-icon-lb {
  background-image: url(~flag-icon-css/flags/4x3/lb.svg); }
  .flag-icon-lb.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/lb.svg); }

.flag-icon-lc {
  background-image: url(~flag-icon-css/flags/4x3/lc.svg); }
  .flag-icon-lc.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/lc.svg); }

.flag-icon-li {
  background-image: url(~flag-icon-css/flags/4x3/li.svg); }
  .flag-icon-li.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/li.svg); }

.flag-icon-lk {
  background-image: url(~flag-icon-css/flags/4x3/lk.svg); }
  .flag-icon-lk.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/lk.svg); }

.flag-icon-lr {
  background-image: url(~flag-icon-css/flags/4x3/lr.svg); }
  .flag-icon-lr.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/lr.svg); }

.flag-icon-ls {
  background-image: url(~flag-icon-css/flags/4x3/ls.svg); }
  .flag-icon-ls.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ls.svg); }

.flag-icon-lt {
  background-image: url(~flag-icon-css/flags/4x3/lt.svg); }
  .flag-icon-lt.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/lt.svg); }

.flag-icon-lu {
  background-image: url(~flag-icon-css/flags/4x3/lu.svg); }
  .flag-icon-lu.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/lu.svg); }

.flag-icon-lv {
  background-image: url(~flag-icon-css/flags/4x3/lv.svg); }
  .flag-icon-lv.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/lv.svg); }

.flag-icon-ly {
  background-image: url(~flag-icon-css/flags/4x3/ly.svg); }
  .flag-icon-ly.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ly.svg); }

.flag-icon-ma {
  background-image: url(~flag-icon-css/flags/4x3/ma.svg); }
  .flag-icon-ma.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ma.svg); }

.flag-icon-mc {
  background-image: url(~flag-icon-css/flags/4x3/mc.svg); }
  .flag-icon-mc.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/mc.svg); }

.flag-icon-md {
  background-image: url(~flag-icon-css/flags/4x3/md.svg); }
  .flag-icon-md.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/md.svg); }

.flag-icon-me {
  background-image: url(~flag-icon-css/flags/4x3/me.svg); }
  .flag-icon-me.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/me.svg); }

.flag-icon-mf {
  background-image: url(~flag-icon-css/flags/4x3/mf.svg); }
  .flag-icon-mf.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/mf.svg); }

.flag-icon-mg {
  background-image: url(~flag-icon-css/flags/4x3/mg.svg); }
  .flag-icon-mg.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/mg.svg); }

.flag-icon-mh {
  background-image: url(~flag-icon-css/flags/4x3/mh.svg); }
  .flag-icon-mh.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/mh.svg); }

.flag-icon-mk {
  background-image: url(~flag-icon-css/flags/4x3/mk.svg); }
  .flag-icon-mk.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/mk.svg); }

.flag-icon-ml {
  background-image: url(~flag-icon-css/flags/4x3/ml.svg); }
  .flag-icon-ml.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ml.svg); }

.flag-icon-mm {
  background-image: url(~flag-icon-css/flags/4x3/mm.svg); }
  .flag-icon-mm.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/mm.svg); }

.flag-icon-mn {
  background-image: url(~flag-icon-css/flags/4x3/mn.svg); }
  .flag-icon-mn.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/mn.svg); }

.flag-icon-mo {
  background-image: url(~flag-icon-css/flags/4x3/mo.svg); }
  .flag-icon-mo.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/mo.svg); }

.flag-icon-mp {
  background-image: url(~flag-icon-css/flags/4x3/mp.svg); }
  .flag-icon-mp.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/mp.svg); }

.flag-icon-mq {
  background-image: url(~flag-icon-css/flags/4x3/mq.svg); }
  .flag-icon-mq.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/mq.svg); }

.flag-icon-mr {
  background-image: url(~flag-icon-css/flags/4x3/mr.svg); }
  .flag-icon-mr.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/mr.svg); }

.flag-icon-ms {
  background-image: url(~flag-icon-css/flags/4x3/ms.svg); }
  .flag-icon-ms.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ms.svg); }

.flag-icon-mt {
  background-image: url(~flag-icon-css/flags/4x3/mt.svg); }
  .flag-icon-mt.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/mt.svg); }

.flag-icon-mu {
  background-image: url(~flag-icon-css/flags/4x3/mu.svg); }
  .flag-icon-mu.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/mu.svg); }

.flag-icon-mv {
  background-image: url(~flag-icon-css/flags/4x3/mv.svg); }
  .flag-icon-mv.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/mv.svg); }

.flag-icon-mw {
  background-image: url(~flag-icon-css/flags/4x3/mw.svg); }
  .flag-icon-mw.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/mw.svg); }

.flag-icon-mx {
  background-image: url(~flag-icon-css/flags/4x3/mx.svg); }
  .flag-icon-mx.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/mx.svg); }

.flag-icon-my {
  background-image: url(~flag-icon-css/flags/4x3/my.svg); }
  .flag-icon-my.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/my.svg); }

.flag-icon-mz {
  background-image: url(~flag-icon-css/flags/4x3/mz.svg); }
  .flag-icon-mz.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/mz.svg); }

.flag-icon-na {
  background-image: url(~flag-icon-css/flags/4x3/na.svg); }
  .flag-icon-na.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/na.svg); }

.flag-icon-nc {
  background-image: url(~flag-icon-css/flags/4x3/nc.svg); }
  .flag-icon-nc.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/nc.svg); }

.flag-icon-ne {
  background-image: url(~flag-icon-css/flags/4x3/ne.svg); }
  .flag-icon-ne.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ne.svg); }

.flag-icon-nf {
  background-image: url(~flag-icon-css/flags/4x3/nf.svg); }
  .flag-icon-nf.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/nf.svg); }

.flag-icon-ng {
  background-image: url(~flag-icon-css/flags/4x3/ng.svg); }
  .flag-icon-ng.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ng.svg); }

.flag-icon-ni {
  background-image: url(~flag-icon-css/flags/4x3/ni.svg); }
  .flag-icon-ni.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ni.svg); }

.flag-icon-nl {
  background-image: url(~flag-icon-css/flags/4x3/nl.svg); }
  .flag-icon-nl.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/nl.svg); }

.flag-icon-no {
  background-image: url(~flag-icon-css/flags/4x3/no.svg); }
  .flag-icon-no.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/no.svg); }

.flag-icon-np {
  background-image: url(~flag-icon-css/flags/4x3/np.svg); }
  .flag-icon-np.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/np.svg); }

.flag-icon-nr {
  background-image: url(~flag-icon-css/flags/4x3/nr.svg); }
  .flag-icon-nr.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/nr.svg); }

.flag-icon-nu {
  background-image: url(~flag-icon-css/flags/4x3/nu.svg); }
  .flag-icon-nu.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/nu.svg); }

.flag-icon-nz {
  background-image: url(~flag-icon-css/flags/4x3/nz.svg); }
  .flag-icon-nz.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/nz.svg); }

.flag-icon-om {
  background-image: url(~flag-icon-css/flags/4x3/om.svg); }
  .flag-icon-om.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/om.svg); }

.flag-icon-pa {
  background-image: url(~flag-icon-css/flags/4x3/pa.svg); }
  .flag-icon-pa.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/pa.svg); }

.flag-icon-pe {
  background-image: url(~flag-icon-css/flags/4x3/pe.svg); }
  .flag-icon-pe.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/pe.svg); }

.flag-icon-pf {
  background-image: url(~flag-icon-css/flags/4x3/pf.svg); }
  .flag-icon-pf.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/pf.svg); }

.flag-icon-pg {
  background-image: url(~flag-icon-css/flags/4x3/pg.svg); }
  .flag-icon-pg.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/pg.svg); }

.flag-icon-ph {
  background-image: url(~flag-icon-css/flags/4x3/ph.svg); }
  .flag-icon-ph.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ph.svg); }

.flag-icon-pk {
  background-image: url(~flag-icon-css/flags/4x3/pk.svg); }
  .flag-icon-pk.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/pk.svg); }

.flag-icon-pl {
  background-image: url(~flag-icon-css/flags/4x3/pl.svg); }
  .flag-icon-pl.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/pl.svg); }

.flag-icon-pm {
  background-image: url(~flag-icon-css/flags/4x3/pm.svg); }
  .flag-icon-pm.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/pm.svg); }

.flag-icon-pn {
  background-image: url(~flag-icon-css/flags/4x3/pn.svg); }
  .flag-icon-pn.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/pn.svg); }

.flag-icon-pr {
  background-image: url(~flag-icon-css/flags/4x3/pr.svg); }
  .flag-icon-pr.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/pr.svg); }

.flag-icon-ps {
  background-image: url(~flag-icon-css/flags/4x3/ps.svg); }
  .flag-icon-ps.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ps.svg); }

.flag-icon-pt {
  background-image: url(~flag-icon-css/flags/4x3/pt.svg); }
  .flag-icon-pt.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/pt.svg); }

.flag-icon-pw {
  background-image: url(~flag-icon-css/flags/4x3/pw.svg); }
  .flag-icon-pw.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/pw.svg); }

.flag-icon-py {
  background-image: url(~flag-icon-css/flags/4x3/py.svg); }
  .flag-icon-py.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/py.svg); }

.flag-icon-qa {
  background-image: url(~flag-icon-css/flags/4x3/qa.svg); }
  .flag-icon-qa.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/qa.svg); }

.flag-icon-re {
  background-image: url(~flag-icon-css/flags/4x3/re.svg); }
  .flag-icon-re.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/re.svg); }

.flag-icon-ro {
  background-image: url(~flag-icon-css/flags/4x3/ro.svg); }
  .flag-icon-ro.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ro.svg); }

.flag-icon-rs {
  background-image: url(~flag-icon-css/flags/4x3/rs.svg); }
  .flag-icon-rs.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/rs.svg); }

.flag-icon-ru {
  background-image: url(~flag-icon-css/flags/4x3/ru.svg); }
  .flag-icon-ru.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ru.svg); }

.flag-icon-rw {
  background-image: url(~flag-icon-css/flags/4x3/rw.svg); }
  .flag-icon-rw.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/rw.svg); }

.flag-icon-sa {
  background-image: url(~flag-icon-css/flags/4x3/sa.svg); }
  .flag-icon-sa.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/sa.svg); }

.flag-icon-sb {
  background-image: url(~flag-icon-css/flags/4x3/sb.svg); }
  .flag-icon-sb.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/sb.svg); }

.flag-icon-sc {
  background-image: url(~flag-icon-css/flags/4x3/sc.svg); }
  .flag-icon-sc.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/sc.svg); }

.flag-icon-sd {
  background-image: url(~flag-icon-css/flags/4x3/sd.svg); }
  .flag-icon-sd.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/sd.svg); }

.flag-icon-se {
  background-image: url(~flag-icon-css/flags/4x3/se.svg); }
  .flag-icon-se.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/se.svg); }

.flag-icon-sg {
  background-image: url(~flag-icon-css/flags/4x3/sg.svg); }
  .flag-icon-sg.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/sg.svg); }

.flag-icon-sh {
  background-image: url(~flag-icon-css/flags/4x3/sh.svg); }
  .flag-icon-sh.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/sh.svg); }

.flag-icon-si {
  background-image: url(~flag-icon-css/flags/4x3/si.svg); }
  .flag-icon-si.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/si.svg); }

.flag-icon-sj {
  background-image: url(~flag-icon-css/flags/4x3/sj.svg); }
  .flag-icon-sj.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/sj.svg); }

.flag-icon-sk {
  background-image: url(~flag-icon-css/flags/4x3/sk.svg); }
  .flag-icon-sk.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/sk.svg); }

.flag-icon-sl {
  background-image: url(~flag-icon-css/flags/4x3/sl.svg); }
  .flag-icon-sl.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/sl.svg); }

.flag-icon-sm {
  background-image: url(~flag-icon-css/flags/4x3/sm.svg); }
  .flag-icon-sm.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/sm.svg); }

.flag-icon-sn {
  background-image: url(~flag-icon-css/flags/4x3/sn.svg); }
  .flag-icon-sn.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/sn.svg); }

.flag-icon-so {
  background-image: url(~flag-icon-css/flags/4x3/so.svg); }
  .flag-icon-so.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/so.svg); }

.flag-icon-sr {
  background-image: url(~flag-icon-css/flags/4x3/sr.svg); }
  .flag-icon-sr.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/sr.svg); }

.flag-icon-ss {
  background-image: url(~flag-icon-css/flags/4x3/ss.svg); }
  .flag-icon-ss.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ss.svg); }

.flag-icon-st {
  background-image: url(~flag-icon-css/flags/4x3/st.svg); }
  .flag-icon-st.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/st.svg); }

.flag-icon-sv {
  background-image: url(~flag-icon-css/flags/4x3/sv.svg); }
  .flag-icon-sv.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/sv.svg); }

.flag-icon-sx {
  background-image: url(~flag-icon-css/flags/4x3/sx.svg); }
  .flag-icon-sx.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/sx.svg); }

.flag-icon-sy {
  background-image: url(~flag-icon-css/flags/4x3/sy.svg); }
  .flag-icon-sy.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/sy.svg); }

.flag-icon-sz {
  background-image: url(~flag-icon-css/flags/4x3/sz.svg); }
  .flag-icon-sz.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/sz.svg); }

.flag-icon-tc {
  background-image: url(~flag-icon-css/flags/4x3/tc.svg); }
  .flag-icon-tc.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/tc.svg); }

.flag-icon-td {
  background-image: url(~flag-icon-css/flags/4x3/td.svg); }
  .flag-icon-td.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/td.svg); }

.flag-icon-tf {
  background-image: url(~flag-icon-css/flags/4x3/tf.svg); }
  .flag-icon-tf.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/tf.svg); }

.flag-icon-tg {
  background-image: url(~flag-icon-css/flags/4x3/tg.svg); }
  .flag-icon-tg.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/tg.svg); }

.flag-icon-th {
  background-image: url(~flag-icon-css/flags/4x3/th.svg); }
  .flag-icon-th.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/th.svg); }

.flag-icon-tj {
  background-image: url(~flag-icon-css/flags/4x3/tj.svg); }
  .flag-icon-tj.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/tj.svg); }

.flag-icon-tk {
  background-image: url(~flag-icon-css/flags/4x3/tk.svg); }
  .flag-icon-tk.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/tk.svg); }

.flag-icon-tl {
  background-image: url(~flag-icon-css/flags/4x3/tl.svg); }
  .flag-icon-tl.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/tl.svg); }

.flag-icon-tm {
  background-image: url(~flag-icon-css/flags/4x3/tm.svg); }
  .flag-icon-tm.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/tm.svg); }

.flag-icon-tn {
  background-image: url(~flag-icon-css/flags/4x3/tn.svg); }
  .flag-icon-tn.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/tn.svg); }

.flag-icon-to {
  background-image: url(~flag-icon-css/flags/4x3/to.svg); }
  .flag-icon-to.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/to.svg); }

.flag-icon-tr {
  background-image: url(~flag-icon-css/flags/4x3/tr.svg); }
  .flag-icon-tr.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/tr.svg); }

.flag-icon-tt {
  background-image: url(~flag-icon-css/flags/4x3/tt.svg); }
  .flag-icon-tt.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/tt.svg); }

.flag-icon-tv {
  background-image: url(~flag-icon-css/flags/4x3/tv.svg); }
  .flag-icon-tv.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/tv.svg); }

.flag-icon-tw {
  background-image: url(~flag-icon-css/flags/4x3/tw.svg); }
  .flag-icon-tw.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/tw.svg); }

.flag-icon-tz {
  background-image: url(~flag-icon-css/flags/4x3/tz.svg); }
  .flag-icon-tz.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/tz.svg); }

.flag-icon-ua {
  background-image: url(~flag-icon-css/flags/4x3/ua.svg); }
  .flag-icon-ua.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ua.svg); }

.flag-icon-ug {
  background-image: url(~flag-icon-css/flags/4x3/ug.svg); }
  .flag-icon-ug.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ug.svg); }

.flag-icon-um {
  background-image: url(~flag-icon-css/flags/4x3/um.svg); }
  .flag-icon-um.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/um.svg); }

.flag-icon-us {
  background-image: url(~flag-icon-css/flags/4x3/us.svg); }
  .flag-icon-us.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/us.svg); }

.flag-icon-uy {
  background-image: url(~flag-icon-css/flags/4x3/uy.svg); }
  .flag-icon-uy.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/uy.svg); }

.flag-icon-uz {
  background-image: url(~flag-icon-css/flags/4x3/uz.svg); }
  .flag-icon-uz.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/uz.svg); }

.flag-icon-va {
  background-image: url(~flag-icon-css/flags/4x3/va.svg); }
  .flag-icon-va.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/va.svg); }

.flag-icon-vc {
  background-image: url(~flag-icon-css/flags/4x3/vc.svg); }
  .flag-icon-vc.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/vc.svg); }

.flag-icon-ve {
  background-image: url(~flag-icon-css/flags/4x3/ve.svg); }
  .flag-icon-ve.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ve.svg); }

.flag-icon-vg {
  background-image: url(~flag-icon-css/flags/4x3/vg.svg); }
  .flag-icon-vg.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/vg.svg); }

.flag-icon-vi {
  background-image: url(~flag-icon-css/flags/4x3/vi.svg); }
  .flag-icon-vi.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/vi.svg); }

.flag-icon-vn {
  background-image: url(~flag-icon-css/flags/4x3/vn.svg); }
  .flag-icon-vn.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/vn.svg); }

.flag-icon-vu {
  background-image: url(~flag-icon-css/flags/4x3/vu.svg); }
  .flag-icon-vu.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/vu.svg); }

.flag-icon-wf {
  background-image: url(~flag-icon-css/flags/4x3/wf.svg); }
  .flag-icon-wf.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/wf.svg); }

.flag-icon-ws {
  background-image: url(~flag-icon-css/flags/4x3/ws.svg); }
  .flag-icon-ws.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ws.svg); }

.flag-icon-ye {
  background-image: url(~flag-icon-css/flags/4x3/ye.svg); }
  .flag-icon-ye.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ye.svg); }

.flag-icon-yt {
  background-image: url(~flag-icon-css/flags/4x3/yt.svg); }
  .flag-icon-yt.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/yt.svg); }

.flag-icon-za {
  background-image: url(~flag-icon-css/flags/4x3/za.svg); }
  .flag-icon-za.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/za.svg); }

.flag-icon-zm {
  background-image: url(~flag-icon-css/flags/4x3/zm.svg); }
  .flag-icon-zm.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/zm.svg); }

.flag-icon-zw {
  background-image: url(~flag-icon-css/flags/4x3/zw.svg); }
  .flag-icon-zw.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/zw.svg); }

.flag-icon-es-ca {
  background-image: url(~flag-icon-css/flags/4x3/es-ca.svg); }
  .flag-icon-es-ca.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/es-ca.svg); }

.flag-icon-es-ga {
  background-image: url(~flag-icon-css/flags/4x3/es-ga.svg); }
  .flag-icon-es-ga.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/es-ga.svg); }

.flag-icon-eu {
  background-image: url(~flag-icon-css/flags/4x3/eu.svg); }
  .flag-icon-eu.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/eu.svg); }

.flag-icon-gb-eng {
  background-image: url(~flag-icon-css/flags/4x3/gb-eng.svg); }
  .flag-icon-gb-eng.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/gb-eng.svg); }

.flag-icon-gb-nir {
  background-image: url(~flag-icon-css/flags/4x3/gb-nir.svg); }
  .flag-icon-gb-nir.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/gb-nir.svg); }

.flag-icon-gb-sct {
  background-image: url(~flag-icon-css/flags/4x3/gb-sct.svg); }
  .flag-icon-gb-sct.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/gb-sct.svg); }

.flag-icon-gb-wls {
  background-image: url(~flag-icon-css/flags/4x3/gb-wls.svg); }
  .flag-icon-gb-wls.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/gb-wls.svg); }

.flag-icon-un {
  background-image: url(~flag-icon-css/flags/4x3/un.svg); }
  .flag-icon-un.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/un.svg); }

.flag-icon-xk {
  background-image: url(~flag-icon-css/flags/4x3/xk.svg); }
  .flag-icon-xk.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/xk.svg); }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("~slick-carousel/slick/ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("~slick-carousel/slick/fonts/slick.eot");
  src: url("~slick-carousel/slick/fonts/slick.eot?#iefix") format("embedded-opentype"), url("~slick-carousel/slick/fonts/slick.woff") format("woff"), url("~slick-carousel/slick/fonts/slick.ttf") format("truetype"), url("~slick-carousel/slick/fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

.flatpickr-calendar {
  background: transparent;
  opacity: 0;
  display: none;
  text-align: center;
  visibility: hidden;
  padding: 0;
  -webkit-animation: none;
  animation: none;
  direction: ltr;
  border: 0;
  font-size: 14px;
  line-height: 24px;
  border-radius: 5px;
  position: absolute;
  width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  background: #fff;
  -webkit-box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08);
  box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08); }

.flatpickr-calendar.open,
.flatpickr-calendar.inline {
  opacity: 1;
  max-height: 640px;
  visibility: visible; }

.flatpickr-calendar.open {
  display: inline-block;
  z-index: 99999; }

.flatpickr-calendar.animate.open {
  -webkit-animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1); }

.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px; }

.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px); }

.flatpickr-calendar.static.open {
  z-index: 999;
  display: block; }

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
  -webkit-box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
  box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6; }

.flatpickr-calendar .hasWeeks .dayContainer,
.flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0; }

.flatpickr-calendar.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid #e6e6e6; }

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto; }

.flatpickr-calendar:before,
.flatpickr-calendar:after {
  position: absolute;
  display: block;
  pointer-events: none;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  left: 22px; }

.flatpickr-calendar.rightMost:before,
.flatpickr-calendar.arrowRight:before,
.flatpickr-calendar.rightMost:after,
.flatpickr-calendar.arrowRight:after {
  left: auto;
  right: 22px; }

.flatpickr-calendar.arrowCenter:before,
.flatpickr-calendar.arrowCenter:after {
  left: 50%;
  right: 50%; }

.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px; }

.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px; }

.flatpickr-calendar.arrowTop:before,
.flatpickr-calendar.arrowTop:after {
  bottom: 100%; }

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #e6e6e6; }

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #fff; }

.flatpickr-calendar.arrowBottom:before,
.flatpickr-calendar.arrowBottom:after {
  top: 100%; }

.flatpickr-calendar.arrowBottom:before {
  border-top-color: #e6e6e6; }

.flatpickr-calendar.arrowBottom:after {
  border-top-color: #fff; }

.flatpickr-calendar:focus {
  outline: 0; }

.flatpickr-wrapper {
  position: relative;
  display: inline-block; }

.flatpickr-months {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }

.flatpickr-months .flatpickr-month {
  background: transparent;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9);
  height: 34px;
  line-height: 1;
  text-align: center;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1; }

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  height: 34px;
  padding: 10px;
  z-index: 3;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9); }

.flatpickr-months .flatpickr-prev-month.flatpickr-disabled,
.flatpickr-months .flatpickr-next-month.flatpickr-disabled {
  display: none; }

.flatpickr-months .flatpickr-prev-month i,
.flatpickr-months .flatpickr-next-month i {
  position: relative; }

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  /*
      /*rtl:begin:ignore*/
  /*
      */
  left: 0;
  /*
      /*rtl:end:ignore*/
  /*
      */ }

/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  /*
      /*rtl:begin:ignore*/
  /*
      */
  right: 0;
  /*
      /*rtl:end:ignore*/
  /*
      */ }

/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
  color: #959ea9; }

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #f64747; }

.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  width: 14px;
  height: 14px; }

.flatpickr-months .flatpickr-prev-month svg path,
.flatpickr-months .flatpickr-next-month svg path {
  -webkit-transition: fill 0.1s;
  transition: fill 0.1s;
  fill: inherit; }

.numInputWrapper {
  position: relative;
  height: auto; }

.numInputWrapper input,
.numInputWrapper span {
  display: inline-block; }

.numInputWrapper input {
  width: 100%; }

.numInputWrapper input::-ms-clear {
  display: none; }

.numInputWrapper input::-webkit-outer-spin-button,
.numInputWrapper input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none; }

.numInputWrapper span {
  position: absolute;
  right: 0;
  width: 14px;
  padding: 0 4px 0 2px;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  cursor: pointer;
  border: 1px solid rgba(57, 57, 57, 0.15);
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.numInputWrapper span:hover {
  background: rgba(0, 0, 0, 0.1); }

.numInputWrapper span:active {
  background: rgba(0, 0, 0, 0.2); }

.numInputWrapper span:after {
  display: block;
  content: "";
  position: absolute; }

.numInputWrapper span.arrowUp {
  top: 0;
  border-bottom: 0; }

.numInputWrapper span.arrowUp:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(57, 57, 57, 0.6);
  top: 26%; }

.numInputWrapper span.arrowDown {
  top: 50%; }

.numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(57, 57, 57, 0.6);
  top: 40%; }

.numInputWrapper span svg {
  width: inherit;
  height: auto; }

.numInputWrapper span svg path {
  fill: rgba(0, 0, 0, 0.5); }

.numInputWrapper:hover {
  background: rgba(0, 0, 0, 0.05); }

.numInputWrapper:hover span {
  opacity: 1; }

.flatpickr-current-month {
  font-size: 135%;
  line-height: inherit;
  font-weight: 300;
  color: inherit;
  position: absolute;
  width: 75%;
  left: 12.5%;
  padding: 7.48px 0 0 0;
  line-height: 1;
  height: 34px;
  display: inline-block;
  text-align: center;
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px); }

.flatpickr-current-month span.cur-month {
  font-family: inherit;
  font-weight: 700;
  color: inherit;
  display: inline-block;
  margin-left: 0.5ch;
  padding: 0; }

.flatpickr-current-month span.cur-month:hover {
  background: rgba(0, 0, 0, 0.05); }

.flatpickr-current-month .numInputWrapper {
  width: 6ch;
  width: 7ch\0;
  display: inline-block; }

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: rgba(0, 0, 0, 0.9); }

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: rgba(0, 0, 0, 0.9); }

.flatpickr-current-month input.cur-year {
  background: transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  padding: 0 0 0 0.5ch;
  margin: 0;
  display: inline-block;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  height: auto;
  border: 0;
  border-radius: 0;
  vertical-align: initial;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield; }

.flatpickr-current-month input.cur-year:focus {
  outline: 0; }

.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
  font-size: 100%;
  color: rgba(0, 0, 0, 0.5);
  background: transparent;
  pointer-events: none; }

.flatpickr-current-month .flatpickr-monthDropdown-months {
  appearance: menulist;
  background: transparent;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  height: auto;
  line-height: inherit;
  margin: -1px 0 0 0;
  outline: none;
  padding: 0 0 0 0.5ch;
  position: relative;
  vertical-align: initial;
  -webkit-box-sizing: border-box;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  width: auto; }

.flatpickr-current-month .flatpickr-monthDropdown-months:focus,
.flatpickr-current-month .flatpickr-monthDropdown-months:active {
  outline: none; }

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: rgba(0, 0, 0, 0.05); }

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: transparent;
  outline: none;
  padding: 0; }

.flatpickr-weekdays {
  background: transparent;
  text-align: center;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 28px; }

.flatpickr-weekdays .flatpickr-weekdaycontainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1; }

span.flatpickr-weekday {
  cursor: default;
  font-size: 90%;
  background: transparent;
  color: rgba(0, 0, 0, 0.54);
  line-height: 1;
  margin: 0;
  text-align: center;
  display: block;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-weight: bolder; }

.dayContainer,
.flatpickr-weeks {
  padding: 1px 0 0 0; }

.flatpickr-days {
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 307.875px; }

.flatpickr-days:focus {
  outline: 0; }

.dayContainer {
  padding: 0;
  outline: 0;
  text-align: left;
  width: 307.875px;
  min-width: 307.875px;
  max-width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
  opacity: 1; }

.dayContainer + .dayContainer {
  -webkit-box-shadow: -1px 0 0 #e6e6e6;
  box-shadow: -1px 0 0 #e6e6e6; }

.flatpickr-day {
  background: none;
  border: 1px solid transparent;
  border-radius: 150px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #393939;
  cursor: pointer;
  font-weight: 400;
  width: 14.2857143%;
  -webkit-flex-basis: 14.2857143%;
  -ms-flex-preferred-size: 14.2857143%;
  flex-basis: 14.2857143%;
  max-width: 39px;
  height: 39px;
  line-height: 39px;
  margin: 0;
  display: inline-block;
  position: relative;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center; }

.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  outline: 0;
  background: #e6e6e6;
  border-color: #e6e6e6; }

.flatpickr-day.today {
  border-color: #959ea9; }

.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
  border-color: #959ea9;
  background: #959ea9;
  color: #fff; }

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: #569ff7;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  border-color: #569ff7; }

.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange,
.flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px; }

.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange,
.flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0; }

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  -webkit-box-shadow: -10px 0 0 #569ff7;
  box-shadow: -10px 0 0 #569ff7; }

.flatpickr-day.selected.startRange.endRange,
.flatpickr-day.startRange.startRange.endRange,
.flatpickr-day.endRange.startRange.endRange {
  border-radius: 50px; }

.flatpickr-day.inRange {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
  box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6; }

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.notAllowed.nextMonthDay {
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  border-color: transparent;
  cursor: default; }

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover {
  cursor: not-allowed;
  color: rgba(57, 57, 57, 0.1); }

.flatpickr-day.week.selected {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7;
  box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7; }

.flatpickr-day.hidden {
  visibility: hidden; }

.rangeMode .flatpickr-day {
  margin-top: 1px; }

.flatpickr-weekwrapper {
  float: left; }

.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  -webkit-box-shadow: 1px 0 0 #e6e6e6;
  box-shadow: 1px 0 0 #e6e6e6; }

.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px; }

.flatpickr-weekwrapper span.flatpickr-day,
.flatpickr-weekwrapper span.flatpickr-day:hover {
  display: block;
  width: 100%;
  max-width: none;
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  cursor: default;
  border: none; }

.flatpickr-innerContainer {
  display: block;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden; }

.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.flatpickr-time {
  text-align: center;
  outline: 0;
  display: block;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }

.flatpickr-time:after {
  content: "";
  display: table;
  clear: both; }

.flatpickr-time .numInputWrapper {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 40%;
  height: 40px;
  float: left; }

.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #393939; }

.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #393939; }

.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%; }

.flatpickr-time.time24hr .numInputWrapper {
  width: 49%; }

.flatpickr-time input {
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  height: inherit;
  line-height: inherit;
  color: #393939;
  font-size: 14px;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield; }

.flatpickr-time input.flatpickr-hour {
  font-weight: bold; }

.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
  font-weight: 400; }

.flatpickr-time input:focus {
  outline: 0;
  border: 0; }

.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
  height: inherit;
  float: left;
  line-height: inherit;
  color: #393939;
  font-weight: bold;
  width: 2%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center; }

.flatpickr-time .flatpickr-am-pm {
  outline: 0;
  width: 18%;
  cursor: pointer;
  text-align: center;
  font-weight: 400; }

.flatpickr-time input:hover,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:focus {
  background: #eee; }

.flatpickr-input[readonly] {
  cursor: pointer; }

@-webkit-keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.flatpickr-monthSelect-months {
  margin: 10px 1px 3px 1px;
  flex-wrap: wrap; }

.flatpickr-monthSelect-month {
  background: none;
  border: 0;
  border-radius: 2px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #393939;
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  margin: 0.5px;
  justify-content: center;
  padding: 10px;
  position: relative;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  text-align: center;
  width: 33%; }

.flatpickr-monthSelect-month.disabled {
  color: #eee; }

.flatpickr-monthSelect-month.disabled:hover,
.flatpickr-monthSelect-month.disabled:focus {
  cursor: not-allowed;
  background: none !important; }

.flatpickr-monthSelect-theme-dark {
  background: #3f4458; }

.flatpickr-monthSelect-theme-dark .flatpickr-current-month input.cur-year {
  color: #fff; }

.flatpickr-monthSelect-theme-dark .flatpickr-months .flatpickr-prev-month,
.flatpickr-monthSelect-theme-dark .flatpickr-months .flatpickr-next-month {
  color: #fff;
  fill: #fff; }

.flatpickr-monthSelect-theme-dark .flatpickr-monthSelect-month {
  color: rgba(255, 255, 255, 0.95); }

.flatpickr-monthSelect-month:hover,
.flatpickr-monthSelect-month:focus {
  background: #e6e6e6;
  cursor: pointer;
  outline: 0; }

.flatpickr-monthSelect-theme-dark .flatpickr-monthSelect-month:hover,
.flatpickr-monthSelect-theme-dark .flatpickr-monthSelect-month:focus {
  background: #646c8c;
  border-color: #646c8c; }

.flatpickr-monthSelect-month.selected {
  background-color: #569ff7;
  color: #fff; }

.flatpickr-monthSelect-theme-dark .flatpickr-monthSelect-month.selected {
  background: #80cbc4;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  border-color: #80cbc4; }

.flag-icon-background, .flag-icon {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat; }

.flag-icon {
  position: relative;
  display: inline-block;
  width: 1.33333em;
  line-height: 1em; }
  .flag-icon:before {
    content: '\00a0'; }
  .flag-icon.flag-icon-squared {
    width: 1em; }

.flag-icon-ad {
  background-image: url(~flag-icon-css/flags/4x3/ad.svg); }
  .flag-icon-ad.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ad.svg); }

.flag-icon-ae {
  background-image: url(~flag-icon-css/flags/4x3/ae.svg); }
  .flag-icon-ae.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ae.svg); }

.flag-icon-af {
  background-image: url(~flag-icon-css/flags/4x3/af.svg); }
  .flag-icon-af.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/af.svg); }

.flag-icon-ag {
  background-image: url(~flag-icon-css/flags/4x3/ag.svg); }
  .flag-icon-ag.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ag.svg); }

.flag-icon-ai {
  background-image: url(~flag-icon-css/flags/4x3/ai.svg); }
  .flag-icon-ai.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ai.svg); }

.flag-icon-al {
  background-image: url(~flag-icon-css/flags/4x3/al.svg); }
  .flag-icon-al.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/al.svg); }

.flag-icon-am {
  background-image: url(~flag-icon-css/flags/4x3/am.svg); }
  .flag-icon-am.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/am.svg); }

.flag-icon-ao {
  background-image: url(~flag-icon-css/flags/4x3/ao.svg); }
  .flag-icon-ao.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ao.svg); }

.flag-icon-aq {
  background-image: url(~flag-icon-css/flags/4x3/aq.svg); }
  .flag-icon-aq.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/aq.svg); }

.flag-icon-ar {
  background-image: url(~flag-icon-css/flags/4x3/ar.svg); }
  .flag-icon-ar.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ar.svg); }

.flag-icon-as {
  background-image: url(~flag-icon-css/flags/4x3/as.svg); }
  .flag-icon-as.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/as.svg); }

.flag-icon-at {
  background-image: url(~flag-icon-css/flags/4x3/at.svg); }
  .flag-icon-at.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/at.svg); }

.flag-icon-au {
  background-image: url(~flag-icon-css/flags/4x3/au.svg); }
  .flag-icon-au.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/au.svg); }

.flag-icon-aw {
  background-image: url(~flag-icon-css/flags/4x3/aw.svg); }
  .flag-icon-aw.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/aw.svg); }

.flag-icon-ax {
  background-image: url(~flag-icon-css/flags/4x3/ax.svg); }
  .flag-icon-ax.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ax.svg); }

.flag-icon-az {
  background-image: url(~flag-icon-css/flags/4x3/az.svg); }
  .flag-icon-az.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/az.svg); }

.flag-icon-ba {
  background-image: url(~flag-icon-css/flags/4x3/ba.svg); }
  .flag-icon-ba.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ba.svg); }

.flag-icon-bb {
  background-image: url(~flag-icon-css/flags/4x3/bb.svg); }
  .flag-icon-bb.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/bb.svg); }

.flag-icon-bd {
  background-image: url(~flag-icon-css/flags/4x3/bd.svg); }
  .flag-icon-bd.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/bd.svg); }

.flag-icon-be {
  background-image: url(~flag-icon-css/flags/4x3/be.svg); }
  .flag-icon-be.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/be.svg); }

.flag-icon-bf {
  background-image: url(~flag-icon-css/flags/4x3/bf.svg); }
  .flag-icon-bf.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/bf.svg); }

.flag-icon-bg {
  background-image: url(~flag-icon-css/flags/4x3/bg.svg); }
  .flag-icon-bg.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/bg.svg); }

.flag-icon-bh {
  background-image: url(~flag-icon-css/flags/4x3/bh.svg); }
  .flag-icon-bh.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/bh.svg); }

.flag-icon-bi {
  background-image: url(~flag-icon-css/flags/4x3/bi.svg); }
  .flag-icon-bi.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/bi.svg); }

.flag-icon-bj {
  background-image: url(~flag-icon-css/flags/4x3/bj.svg); }
  .flag-icon-bj.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/bj.svg); }

.flag-icon-bl {
  background-image: url(~flag-icon-css/flags/4x3/bl.svg); }
  .flag-icon-bl.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/bl.svg); }

.flag-icon-bm {
  background-image: url(~flag-icon-css/flags/4x3/bm.svg); }
  .flag-icon-bm.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/bm.svg); }

.flag-icon-bn {
  background-image: url(~flag-icon-css/flags/4x3/bn.svg); }
  .flag-icon-bn.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/bn.svg); }

.flag-icon-bo {
  background-image: url(~flag-icon-css/flags/4x3/bo.svg); }
  .flag-icon-bo.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/bo.svg); }

.flag-icon-bq {
  background-image: url(~flag-icon-css/flags/4x3/bq.svg); }
  .flag-icon-bq.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/bq.svg); }

.flag-icon-br {
  background-image: url(~flag-icon-css/flags/4x3/br.svg); }
  .flag-icon-br.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/br.svg); }

.flag-icon-bs {
  background-image: url(~flag-icon-css/flags/4x3/bs.svg); }
  .flag-icon-bs.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/bs.svg); }

.flag-icon-bt {
  background-image: url(~flag-icon-css/flags/4x3/bt.svg); }
  .flag-icon-bt.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/bt.svg); }

.flag-icon-bv {
  background-image: url(~flag-icon-css/flags/4x3/bv.svg); }
  .flag-icon-bv.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/bv.svg); }

.flag-icon-bw {
  background-image: url(~flag-icon-css/flags/4x3/bw.svg); }
  .flag-icon-bw.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/bw.svg); }

.flag-icon-by {
  background-image: url(~flag-icon-css/flags/4x3/by.svg); }
  .flag-icon-by.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/by.svg); }

.flag-icon-bz {
  background-image: url(~flag-icon-css/flags/4x3/bz.svg); }
  .flag-icon-bz.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/bz.svg); }

.flag-icon-ca {
  background-image: url(~flag-icon-css/flags/4x3/ca.svg); }
  .flag-icon-ca.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ca.svg); }

.flag-icon-cc {
  background-image: url(~flag-icon-css/flags/4x3/cc.svg); }
  .flag-icon-cc.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/cc.svg); }

.flag-icon-cd {
  background-image: url(~flag-icon-css/flags/4x3/cd.svg); }
  .flag-icon-cd.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/cd.svg); }

.flag-icon-cf {
  background-image: url(~flag-icon-css/flags/4x3/cf.svg); }
  .flag-icon-cf.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/cf.svg); }

.flag-icon-cg {
  background-image: url(~flag-icon-css/flags/4x3/cg.svg); }
  .flag-icon-cg.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/cg.svg); }

.flag-icon-ch {
  background-image: url(~flag-icon-css/flags/4x3/ch.svg); }
  .flag-icon-ch.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ch.svg); }

.flag-icon-ci {
  background-image: url(~flag-icon-css/flags/4x3/ci.svg); }
  .flag-icon-ci.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ci.svg); }

.flag-icon-ck {
  background-image: url(~flag-icon-css/flags/4x3/ck.svg); }
  .flag-icon-ck.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ck.svg); }

.flag-icon-cl {
  background-image: url(~flag-icon-css/flags/4x3/cl.svg); }
  .flag-icon-cl.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/cl.svg); }

.flag-icon-cm {
  background-image: url(~flag-icon-css/flags/4x3/cm.svg); }
  .flag-icon-cm.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/cm.svg); }

.flag-icon-cn {
  background-image: url(~flag-icon-css/flags/4x3/cn.svg); }
  .flag-icon-cn.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/cn.svg); }

.flag-icon-co {
  background-image: url(~flag-icon-css/flags/4x3/co.svg); }
  .flag-icon-co.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/co.svg); }

.flag-icon-cr {
  background-image: url(~flag-icon-css/flags/4x3/cr.svg); }
  .flag-icon-cr.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/cr.svg); }

.flag-icon-cu {
  background-image: url(~flag-icon-css/flags/4x3/cu.svg); }
  .flag-icon-cu.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/cu.svg); }

.flag-icon-cv {
  background-image: url(~flag-icon-css/flags/4x3/cv.svg); }
  .flag-icon-cv.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/cv.svg); }

.flag-icon-cw {
  background-image: url(~flag-icon-css/flags/4x3/cw.svg); }
  .flag-icon-cw.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/cw.svg); }

.flag-icon-cx {
  background-image: url(~flag-icon-css/flags/4x3/cx.svg); }
  .flag-icon-cx.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/cx.svg); }

.flag-icon-cy {
  background-image: url(~flag-icon-css/flags/4x3/cy.svg); }
  .flag-icon-cy.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/cy.svg); }

.flag-icon-cz {
  background-image: url(~flag-icon-css/flags/4x3/cz.svg); }
  .flag-icon-cz.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/cz.svg); }

.flag-icon-de {
  background-image: url(~flag-icon-css/flags/4x3/de.svg); }
  .flag-icon-de.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/de.svg); }

.flag-icon-dj {
  background-image: url(~flag-icon-css/flags/4x3/dj.svg); }
  .flag-icon-dj.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/dj.svg); }

.flag-icon-dk {
  background-image: url(~flag-icon-css/flags/4x3/dk.svg); }
  .flag-icon-dk.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/dk.svg); }

.flag-icon-dm {
  background-image: url(~flag-icon-css/flags/4x3/dm.svg); }
  .flag-icon-dm.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/dm.svg); }

.flag-icon-do {
  background-image: url(~flag-icon-css/flags/4x3/do.svg); }
  .flag-icon-do.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/do.svg); }

.flag-icon-dz {
  background-image: url(~flag-icon-css/flags/4x3/dz.svg); }
  .flag-icon-dz.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/dz.svg); }

.flag-icon-ec {
  background-image: url(~flag-icon-css/flags/4x3/ec.svg); }
  .flag-icon-ec.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ec.svg); }

.flag-icon-ee {
  background-image: url(~flag-icon-css/flags/4x3/ee.svg); }
  .flag-icon-ee.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ee.svg); }

.flag-icon-eg {
  background-image: url(~flag-icon-css/flags/4x3/eg.svg); }
  .flag-icon-eg.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/eg.svg); }

.flag-icon-eh {
  background-image: url(~flag-icon-css/flags/4x3/eh.svg); }
  .flag-icon-eh.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/eh.svg); }

.flag-icon-er {
  background-image: url(~flag-icon-css/flags/4x3/er.svg); }
  .flag-icon-er.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/er.svg); }

.flag-icon-es {
  background-image: url(~flag-icon-css/flags/4x3/es.svg); }
  .flag-icon-es.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/es.svg); }

.flag-icon-et {
  background-image: url(~flag-icon-css/flags/4x3/et.svg); }
  .flag-icon-et.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/et.svg); }

.flag-icon-fi {
  background-image: url(~flag-icon-css/flags/4x3/fi.svg); }
  .flag-icon-fi.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/fi.svg); }

.flag-icon-fj {
  background-image: url(~flag-icon-css/flags/4x3/fj.svg); }
  .flag-icon-fj.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/fj.svg); }

.flag-icon-fk {
  background-image: url(~flag-icon-css/flags/4x3/fk.svg); }
  .flag-icon-fk.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/fk.svg); }

.flag-icon-fm {
  background-image: url(~flag-icon-css/flags/4x3/fm.svg); }
  .flag-icon-fm.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/fm.svg); }

.flag-icon-fo {
  background-image: url(~flag-icon-css/flags/4x3/fo.svg); }
  .flag-icon-fo.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/fo.svg); }

.flag-icon-fr {
  background-image: url(~flag-icon-css/flags/4x3/fr.svg); }
  .flag-icon-fr.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/fr.svg); }

.flag-icon-ga {
  background-image: url(~flag-icon-css/flags/4x3/ga.svg); }
  .flag-icon-ga.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ga.svg); }

.flag-icon-gb {
  background-image: url(~flag-icon-css/flags/4x3/gb.svg); }
  .flag-icon-gb.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/gb.svg); }

.flag-icon-gd {
  background-image: url(~flag-icon-css/flags/4x3/gd.svg); }
  .flag-icon-gd.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/gd.svg); }

.flag-icon-ge {
  background-image: url(~flag-icon-css/flags/4x3/ge.svg); }
  .flag-icon-ge.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ge.svg); }

.flag-icon-gf {
  background-image: url(~flag-icon-css/flags/4x3/gf.svg); }
  .flag-icon-gf.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/gf.svg); }

.flag-icon-gg {
  background-image: url(~flag-icon-css/flags/4x3/gg.svg); }
  .flag-icon-gg.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/gg.svg); }

.flag-icon-gh {
  background-image: url(~flag-icon-css/flags/4x3/gh.svg); }
  .flag-icon-gh.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/gh.svg); }

.flag-icon-gi {
  background-image: url(~flag-icon-css/flags/4x3/gi.svg); }
  .flag-icon-gi.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/gi.svg); }

.flag-icon-gl {
  background-image: url(~flag-icon-css/flags/4x3/gl.svg); }
  .flag-icon-gl.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/gl.svg); }

.flag-icon-gm {
  background-image: url(~flag-icon-css/flags/4x3/gm.svg); }
  .flag-icon-gm.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/gm.svg); }

.flag-icon-gn {
  background-image: url(~flag-icon-css/flags/4x3/gn.svg); }
  .flag-icon-gn.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/gn.svg); }

.flag-icon-gp {
  background-image: url(~flag-icon-css/flags/4x3/gp.svg); }
  .flag-icon-gp.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/gp.svg); }

.flag-icon-gq {
  background-image: url(~flag-icon-css/flags/4x3/gq.svg); }
  .flag-icon-gq.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/gq.svg); }

.flag-icon-gr {
  background-image: url(~flag-icon-css/flags/4x3/gr.svg); }
  .flag-icon-gr.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/gr.svg); }

.flag-icon-gs {
  background-image: url(~flag-icon-css/flags/4x3/gs.svg); }
  .flag-icon-gs.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/gs.svg); }

.flag-icon-gt {
  background-image: url(~flag-icon-css/flags/4x3/gt.svg); }
  .flag-icon-gt.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/gt.svg); }

.flag-icon-gu {
  background-image: url(~flag-icon-css/flags/4x3/gu.svg); }
  .flag-icon-gu.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/gu.svg); }

.flag-icon-gw {
  background-image: url(~flag-icon-css/flags/4x3/gw.svg); }
  .flag-icon-gw.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/gw.svg); }

.flag-icon-gy {
  background-image: url(~flag-icon-css/flags/4x3/gy.svg); }
  .flag-icon-gy.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/gy.svg); }

.flag-icon-hk {
  background-image: url(~flag-icon-css/flags/4x3/hk.svg); }
  .flag-icon-hk.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/hk.svg); }

.flag-icon-hm {
  background-image: url(~flag-icon-css/flags/4x3/hm.svg); }
  .flag-icon-hm.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/hm.svg); }

.flag-icon-hn {
  background-image: url(~flag-icon-css/flags/4x3/hn.svg); }
  .flag-icon-hn.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/hn.svg); }

.flag-icon-hr {
  background-image: url(~flag-icon-css/flags/4x3/hr.svg); }
  .flag-icon-hr.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/hr.svg); }

.flag-icon-ht {
  background-image: url(~flag-icon-css/flags/4x3/ht.svg); }
  .flag-icon-ht.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ht.svg); }

.flag-icon-hu {
  background-image: url(~flag-icon-css/flags/4x3/hu.svg); }
  .flag-icon-hu.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/hu.svg); }

.flag-icon-id {
  background-image: url(~flag-icon-css/flags/4x3/id.svg); }
  .flag-icon-id.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/id.svg); }

.flag-icon-ie {
  background-image: url(~flag-icon-css/flags/4x3/ie.svg); }
  .flag-icon-ie.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ie.svg); }

.flag-icon-il {
  background-image: url(~flag-icon-css/flags/4x3/il.svg); }
  .flag-icon-il.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/il.svg); }

.flag-icon-im {
  background-image: url(~flag-icon-css/flags/4x3/im.svg); }
  .flag-icon-im.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/im.svg); }

.flag-icon-in {
  background-image: url(~flag-icon-css/flags/4x3/in.svg); }
  .flag-icon-in.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/in.svg); }

.flag-icon-io {
  background-image: url(~flag-icon-css/flags/4x3/io.svg); }
  .flag-icon-io.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/io.svg); }

.flag-icon-iq {
  background-image: url(~flag-icon-css/flags/4x3/iq.svg); }
  .flag-icon-iq.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/iq.svg); }

.flag-icon-ir {
  background-image: url(~flag-icon-css/flags/4x3/ir.svg); }
  .flag-icon-ir.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ir.svg); }

.flag-icon-is {
  background-image: url(~flag-icon-css/flags/4x3/is.svg); }
  .flag-icon-is.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/is.svg); }

.flag-icon-it {
  background-image: url(~flag-icon-css/flags/4x3/it.svg); }
  .flag-icon-it.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/it.svg); }

.flag-icon-je {
  background-image: url(~flag-icon-css/flags/4x3/je.svg); }
  .flag-icon-je.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/je.svg); }

.flag-icon-jm {
  background-image: url(~flag-icon-css/flags/4x3/jm.svg); }
  .flag-icon-jm.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/jm.svg); }

.flag-icon-jo {
  background-image: url(~flag-icon-css/flags/4x3/jo.svg); }
  .flag-icon-jo.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/jo.svg); }

.flag-icon-jp {
  background-image: url(~flag-icon-css/flags/4x3/jp.svg); }
  .flag-icon-jp.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/jp.svg); }

.flag-icon-ke {
  background-image: url(~flag-icon-css/flags/4x3/ke.svg); }
  .flag-icon-ke.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ke.svg); }

.flag-icon-kg {
  background-image: url(~flag-icon-css/flags/4x3/kg.svg); }
  .flag-icon-kg.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/kg.svg); }

.flag-icon-kh {
  background-image: url(~flag-icon-css/flags/4x3/kh.svg); }
  .flag-icon-kh.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/kh.svg); }

.flag-icon-ki {
  background-image: url(~flag-icon-css/flags/4x3/ki.svg); }
  .flag-icon-ki.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ki.svg); }

.flag-icon-km {
  background-image: url(~flag-icon-css/flags/4x3/km.svg); }
  .flag-icon-km.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/km.svg); }

.flag-icon-kn {
  background-image: url(~flag-icon-css/flags/4x3/kn.svg); }
  .flag-icon-kn.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/kn.svg); }

.flag-icon-kp {
  background-image: url(~flag-icon-css/flags/4x3/kp.svg); }
  .flag-icon-kp.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/kp.svg); }

.flag-icon-kr {
  background-image: url(~flag-icon-css/flags/4x3/kr.svg); }
  .flag-icon-kr.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/kr.svg); }

.flag-icon-kw {
  background-image: url(~flag-icon-css/flags/4x3/kw.svg); }
  .flag-icon-kw.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/kw.svg); }

.flag-icon-ky {
  background-image: url(~flag-icon-css/flags/4x3/ky.svg); }
  .flag-icon-ky.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ky.svg); }

.flag-icon-kz {
  background-image: url(~flag-icon-css/flags/4x3/kz.svg); }
  .flag-icon-kz.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/kz.svg); }

.flag-icon-la {
  background-image: url(~flag-icon-css/flags/4x3/la.svg); }
  .flag-icon-la.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/la.svg); }

.flag-icon-lb {
  background-image: url(~flag-icon-css/flags/4x3/lb.svg); }
  .flag-icon-lb.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/lb.svg); }

.flag-icon-lc {
  background-image: url(~flag-icon-css/flags/4x3/lc.svg); }
  .flag-icon-lc.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/lc.svg); }

.flag-icon-li {
  background-image: url(~flag-icon-css/flags/4x3/li.svg); }
  .flag-icon-li.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/li.svg); }

.flag-icon-lk {
  background-image: url(~flag-icon-css/flags/4x3/lk.svg); }
  .flag-icon-lk.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/lk.svg); }

.flag-icon-lr {
  background-image: url(~flag-icon-css/flags/4x3/lr.svg); }
  .flag-icon-lr.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/lr.svg); }

.flag-icon-ls {
  background-image: url(~flag-icon-css/flags/4x3/ls.svg); }
  .flag-icon-ls.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ls.svg); }

.flag-icon-lt {
  background-image: url(~flag-icon-css/flags/4x3/lt.svg); }
  .flag-icon-lt.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/lt.svg); }

.flag-icon-lu {
  background-image: url(~flag-icon-css/flags/4x3/lu.svg); }
  .flag-icon-lu.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/lu.svg); }

.flag-icon-lv {
  background-image: url(~flag-icon-css/flags/4x3/lv.svg); }
  .flag-icon-lv.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/lv.svg); }

.flag-icon-ly {
  background-image: url(~flag-icon-css/flags/4x3/ly.svg); }
  .flag-icon-ly.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ly.svg); }

.flag-icon-ma {
  background-image: url(~flag-icon-css/flags/4x3/ma.svg); }
  .flag-icon-ma.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ma.svg); }

.flag-icon-mc {
  background-image: url(~flag-icon-css/flags/4x3/mc.svg); }
  .flag-icon-mc.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/mc.svg); }

.flag-icon-md {
  background-image: url(~flag-icon-css/flags/4x3/md.svg); }
  .flag-icon-md.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/md.svg); }

.flag-icon-me {
  background-image: url(~flag-icon-css/flags/4x3/me.svg); }
  .flag-icon-me.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/me.svg); }

.flag-icon-mf {
  background-image: url(~flag-icon-css/flags/4x3/mf.svg); }
  .flag-icon-mf.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/mf.svg); }

.flag-icon-mg {
  background-image: url(~flag-icon-css/flags/4x3/mg.svg); }
  .flag-icon-mg.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/mg.svg); }

.flag-icon-mh {
  background-image: url(~flag-icon-css/flags/4x3/mh.svg); }
  .flag-icon-mh.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/mh.svg); }

.flag-icon-mk {
  background-image: url(~flag-icon-css/flags/4x3/mk.svg); }
  .flag-icon-mk.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/mk.svg); }

.flag-icon-ml {
  background-image: url(~flag-icon-css/flags/4x3/ml.svg); }
  .flag-icon-ml.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ml.svg); }

.flag-icon-mm {
  background-image: url(~flag-icon-css/flags/4x3/mm.svg); }
  .flag-icon-mm.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/mm.svg); }

.flag-icon-mn {
  background-image: url(~flag-icon-css/flags/4x3/mn.svg); }
  .flag-icon-mn.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/mn.svg); }

.flag-icon-mo {
  background-image: url(~flag-icon-css/flags/4x3/mo.svg); }
  .flag-icon-mo.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/mo.svg); }

.flag-icon-mp {
  background-image: url(~flag-icon-css/flags/4x3/mp.svg); }
  .flag-icon-mp.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/mp.svg); }

.flag-icon-mq {
  background-image: url(~flag-icon-css/flags/4x3/mq.svg); }
  .flag-icon-mq.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/mq.svg); }

.flag-icon-mr {
  background-image: url(~flag-icon-css/flags/4x3/mr.svg); }
  .flag-icon-mr.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/mr.svg); }

.flag-icon-ms {
  background-image: url(~flag-icon-css/flags/4x3/ms.svg); }
  .flag-icon-ms.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ms.svg); }

.flag-icon-mt {
  background-image: url(~flag-icon-css/flags/4x3/mt.svg); }
  .flag-icon-mt.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/mt.svg); }

.flag-icon-mu {
  background-image: url(~flag-icon-css/flags/4x3/mu.svg); }
  .flag-icon-mu.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/mu.svg); }

.flag-icon-mv {
  background-image: url(~flag-icon-css/flags/4x3/mv.svg); }
  .flag-icon-mv.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/mv.svg); }

.flag-icon-mw {
  background-image: url(~flag-icon-css/flags/4x3/mw.svg); }
  .flag-icon-mw.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/mw.svg); }

.flag-icon-mx {
  background-image: url(~flag-icon-css/flags/4x3/mx.svg); }
  .flag-icon-mx.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/mx.svg); }

.flag-icon-my {
  background-image: url(~flag-icon-css/flags/4x3/my.svg); }
  .flag-icon-my.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/my.svg); }

.flag-icon-mz {
  background-image: url(~flag-icon-css/flags/4x3/mz.svg); }
  .flag-icon-mz.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/mz.svg); }

.flag-icon-na {
  background-image: url(~flag-icon-css/flags/4x3/na.svg); }
  .flag-icon-na.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/na.svg); }

.flag-icon-nc {
  background-image: url(~flag-icon-css/flags/4x3/nc.svg); }
  .flag-icon-nc.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/nc.svg); }

.flag-icon-ne {
  background-image: url(~flag-icon-css/flags/4x3/ne.svg); }
  .flag-icon-ne.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ne.svg); }

.flag-icon-nf {
  background-image: url(~flag-icon-css/flags/4x3/nf.svg); }
  .flag-icon-nf.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/nf.svg); }

.flag-icon-ng {
  background-image: url(~flag-icon-css/flags/4x3/ng.svg); }
  .flag-icon-ng.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ng.svg); }

.flag-icon-ni {
  background-image: url(~flag-icon-css/flags/4x3/ni.svg); }
  .flag-icon-ni.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ni.svg); }

.flag-icon-nl {
  background-image: url(~flag-icon-css/flags/4x3/nl.svg); }
  .flag-icon-nl.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/nl.svg); }

.flag-icon-no {
  background-image: url(~flag-icon-css/flags/4x3/no.svg); }
  .flag-icon-no.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/no.svg); }

.flag-icon-np {
  background-image: url(~flag-icon-css/flags/4x3/np.svg); }
  .flag-icon-np.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/np.svg); }

.flag-icon-nr {
  background-image: url(~flag-icon-css/flags/4x3/nr.svg); }
  .flag-icon-nr.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/nr.svg); }

.flag-icon-nu {
  background-image: url(~flag-icon-css/flags/4x3/nu.svg); }
  .flag-icon-nu.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/nu.svg); }

.flag-icon-nz {
  background-image: url(~flag-icon-css/flags/4x3/nz.svg); }
  .flag-icon-nz.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/nz.svg); }

.flag-icon-om {
  background-image: url(~flag-icon-css/flags/4x3/om.svg); }
  .flag-icon-om.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/om.svg); }

.flag-icon-pa {
  background-image: url(~flag-icon-css/flags/4x3/pa.svg); }
  .flag-icon-pa.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/pa.svg); }

.flag-icon-pe {
  background-image: url(~flag-icon-css/flags/4x3/pe.svg); }
  .flag-icon-pe.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/pe.svg); }

.flag-icon-pf {
  background-image: url(~flag-icon-css/flags/4x3/pf.svg); }
  .flag-icon-pf.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/pf.svg); }

.flag-icon-pg {
  background-image: url(~flag-icon-css/flags/4x3/pg.svg); }
  .flag-icon-pg.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/pg.svg); }

.flag-icon-ph {
  background-image: url(~flag-icon-css/flags/4x3/ph.svg); }
  .flag-icon-ph.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ph.svg); }

.flag-icon-pk {
  background-image: url(~flag-icon-css/flags/4x3/pk.svg); }
  .flag-icon-pk.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/pk.svg); }

.flag-icon-pl {
  background-image: url(~flag-icon-css/flags/4x3/pl.svg); }
  .flag-icon-pl.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/pl.svg); }

.flag-icon-pm {
  background-image: url(~flag-icon-css/flags/4x3/pm.svg); }
  .flag-icon-pm.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/pm.svg); }

.flag-icon-pn {
  background-image: url(~flag-icon-css/flags/4x3/pn.svg); }
  .flag-icon-pn.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/pn.svg); }

.flag-icon-pr {
  background-image: url(~flag-icon-css/flags/4x3/pr.svg); }
  .flag-icon-pr.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/pr.svg); }

.flag-icon-ps {
  background-image: url(~flag-icon-css/flags/4x3/ps.svg); }
  .flag-icon-ps.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ps.svg); }

.flag-icon-pt {
  background-image: url(~flag-icon-css/flags/4x3/pt.svg); }
  .flag-icon-pt.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/pt.svg); }

.flag-icon-pw {
  background-image: url(~flag-icon-css/flags/4x3/pw.svg); }
  .flag-icon-pw.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/pw.svg); }

.flag-icon-py {
  background-image: url(~flag-icon-css/flags/4x3/py.svg); }
  .flag-icon-py.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/py.svg); }

.flag-icon-qa {
  background-image: url(~flag-icon-css/flags/4x3/qa.svg); }
  .flag-icon-qa.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/qa.svg); }

.flag-icon-re {
  background-image: url(~flag-icon-css/flags/4x3/re.svg); }
  .flag-icon-re.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/re.svg); }

.flag-icon-ro {
  background-image: url(~flag-icon-css/flags/4x3/ro.svg); }
  .flag-icon-ro.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ro.svg); }

.flag-icon-rs {
  background-image: url(~flag-icon-css/flags/4x3/rs.svg); }
  .flag-icon-rs.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/rs.svg); }

.flag-icon-ru {
  background-image: url(~flag-icon-css/flags/4x3/ru.svg); }
  .flag-icon-ru.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ru.svg); }

.flag-icon-rw {
  background-image: url(~flag-icon-css/flags/4x3/rw.svg); }
  .flag-icon-rw.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/rw.svg); }

.flag-icon-sa {
  background-image: url(~flag-icon-css/flags/4x3/sa.svg); }
  .flag-icon-sa.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/sa.svg); }

.flag-icon-sb {
  background-image: url(~flag-icon-css/flags/4x3/sb.svg); }
  .flag-icon-sb.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/sb.svg); }

.flag-icon-sc {
  background-image: url(~flag-icon-css/flags/4x3/sc.svg); }
  .flag-icon-sc.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/sc.svg); }

.flag-icon-sd {
  background-image: url(~flag-icon-css/flags/4x3/sd.svg); }
  .flag-icon-sd.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/sd.svg); }

.flag-icon-se {
  background-image: url(~flag-icon-css/flags/4x3/se.svg); }
  .flag-icon-se.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/se.svg); }

.flag-icon-sg {
  background-image: url(~flag-icon-css/flags/4x3/sg.svg); }
  .flag-icon-sg.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/sg.svg); }

.flag-icon-sh {
  background-image: url(~flag-icon-css/flags/4x3/sh.svg); }
  .flag-icon-sh.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/sh.svg); }

.flag-icon-si {
  background-image: url(~flag-icon-css/flags/4x3/si.svg); }
  .flag-icon-si.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/si.svg); }

.flag-icon-sj {
  background-image: url(~flag-icon-css/flags/4x3/sj.svg); }
  .flag-icon-sj.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/sj.svg); }

.flag-icon-sk {
  background-image: url(~flag-icon-css/flags/4x3/sk.svg); }
  .flag-icon-sk.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/sk.svg); }

.flag-icon-sl {
  background-image: url(~flag-icon-css/flags/4x3/sl.svg); }
  .flag-icon-sl.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/sl.svg); }

.flag-icon-sm {
  background-image: url(~flag-icon-css/flags/4x3/sm.svg); }
  .flag-icon-sm.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/sm.svg); }

.flag-icon-sn {
  background-image: url(~flag-icon-css/flags/4x3/sn.svg); }
  .flag-icon-sn.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/sn.svg); }

.flag-icon-so {
  background-image: url(~flag-icon-css/flags/4x3/so.svg); }
  .flag-icon-so.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/so.svg); }

.flag-icon-sr {
  background-image: url(~flag-icon-css/flags/4x3/sr.svg); }
  .flag-icon-sr.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/sr.svg); }

.flag-icon-ss {
  background-image: url(~flag-icon-css/flags/4x3/ss.svg); }
  .flag-icon-ss.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ss.svg); }

.flag-icon-st {
  background-image: url(~flag-icon-css/flags/4x3/st.svg); }
  .flag-icon-st.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/st.svg); }

.flag-icon-sv {
  background-image: url(~flag-icon-css/flags/4x3/sv.svg); }
  .flag-icon-sv.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/sv.svg); }

.flag-icon-sx {
  background-image: url(~flag-icon-css/flags/4x3/sx.svg); }
  .flag-icon-sx.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/sx.svg); }

.flag-icon-sy {
  background-image: url(~flag-icon-css/flags/4x3/sy.svg); }
  .flag-icon-sy.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/sy.svg); }

.flag-icon-sz {
  background-image: url(~flag-icon-css/flags/4x3/sz.svg); }
  .flag-icon-sz.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/sz.svg); }

.flag-icon-tc {
  background-image: url(~flag-icon-css/flags/4x3/tc.svg); }
  .flag-icon-tc.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/tc.svg); }

.flag-icon-td {
  background-image: url(~flag-icon-css/flags/4x3/td.svg); }
  .flag-icon-td.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/td.svg); }

.flag-icon-tf {
  background-image: url(~flag-icon-css/flags/4x3/tf.svg); }
  .flag-icon-tf.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/tf.svg); }

.flag-icon-tg {
  background-image: url(~flag-icon-css/flags/4x3/tg.svg); }
  .flag-icon-tg.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/tg.svg); }

.flag-icon-th {
  background-image: url(~flag-icon-css/flags/4x3/th.svg); }
  .flag-icon-th.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/th.svg); }

.flag-icon-tj {
  background-image: url(~flag-icon-css/flags/4x3/tj.svg); }
  .flag-icon-tj.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/tj.svg); }

.flag-icon-tk {
  background-image: url(~flag-icon-css/flags/4x3/tk.svg); }
  .flag-icon-tk.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/tk.svg); }

.flag-icon-tl {
  background-image: url(~flag-icon-css/flags/4x3/tl.svg); }
  .flag-icon-tl.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/tl.svg); }

.flag-icon-tm {
  background-image: url(~flag-icon-css/flags/4x3/tm.svg); }
  .flag-icon-tm.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/tm.svg); }

.flag-icon-tn {
  background-image: url(~flag-icon-css/flags/4x3/tn.svg); }
  .flag-icon-tn.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/tn.svg); }

.flag-icon-to {
  background-image: url(~flag-icon-css/flags/4x3/to.svg); }
  .flag-icon-to.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/to.svg); }

.flag-icon-tr {
  background-image: url(~flag-icon-css/flags/4x3/tr.svg); }
  .flag-icon-tr.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/tr.svg); }

.flag-icon-tt {
  background-image: url(~flag-icon-css/flags/4x3/tt.svg); }
  .flag-icon-tt.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/tt.svg); }

.flag-icon-tv {
  background-image: url(~flag-icon-css/flags/4x3/tv.svg); }
  .flag-icon-tv.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/tv.svg); }

.flag-icon-tw {
  background-image: url(~flag-icon-css/flags/4x3/tw.svg); }
  .flag-icon-tw.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/tw.svg); }

.flag-icon-tz {
  background-image: url(~flag-icon-css/flags/4x3/tz.svg); }
  .flag-icon-tz.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/tz.svg); }

.flag-icon-ua {
  background-image: url(~flag-icon-css/flags/4x3/ua.svg); }
  .flag-icon-ua.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ua.svg); }

.flag-icon-ug {
  background-image: url(~flag-icon-css/flags/4x3/ug.svg); }
  .flag-icon-ug.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ug.svg); }

.flag-icon-um {
  background-image: url(~flag-icon-css/flags/4x3/um.svg); }
  .flag-icon-um.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/um.svg); }

.flag-icon-us {
  background-image: url(~flag-icon-css/flags/4x3/us.svg); }
  .flag-icon-us.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/us.svg); }

.flag-icon-uy {
  background-image: url(~flag-icon-css/flags/4x3/uy.svg); }
  .flag-icon-uy.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/uy.svg); }

.flag-icon-uz {
  background-image: url(~flag-icon-css/flags/4x3/uz.svg); }
  .flag-icon-uz.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/uz.svg); }

.flag-icon-va {
  background-image: url(~flag-icon-css/flags/4x3/va.svg); }
  .flag-icon-va.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/va.svg); }

.flag-icon-vc {
  background-image: url(~flag-icon-css/flags/4x3/vc.svg); }
  .flag-icon-vc.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/vc.svg); }

.flag-icon-ve {
  background-image: url(~flag-icon-css/flags/4x3/ve.svg); }
  .flag-icon-ve.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ve.svg); }

.flag-icon-vg {
  background-image: url(~flag-icon-css/flags/4x3/vg.svg); }
  .flag-icon-vg.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/vg.svg); }

.flag-icon-vi {
  background-image: url(~flag-icon-css/flags/4x3/vi.svg); }
  .flag-icon-vi.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/vi.svg); }

.flag-icon-vn {
  background-image: url(~flag-icon-css/flags/4x3/vn.svg); }
  .flag-icon-vn.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/vn.svg); }

.flag-icon-vu {
  background-image: url(~flag-icon-css/flags/4x3/vu.svg); }
  .flag-icon-vu.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/vu.svg); }

.flag-icon-wf {
  background-image: url(~flag-icon-css/flags/4x3/wf.svg); }
  .flag-icon-wf.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/wf.svg); }

.flag-icon-ws {
  background-image: url(~flag-icon-css/flags/4x3/ws.svg); }
  .flag-icon-ws.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ws.svg); }

.flag-icon-ye {
  background-image: url(~flag-icon-css/flags/4x3/ye.svg); }
  .flag-icon-ye.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/ye.svg); }

.flag-icon-yt {
  background-image: url(~flag-icon-css/flags/4x3/yt.svg); }
  .flag-icon-yt.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/yt.svg); }

.flag-icon-za {
  background-image: url(~flag-icon-css/flags/4x3/za.svg); }
  .flag-icon-za.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/za.svg); }

.flag-icon-zm {
  background-image: url(~flag-icon-css/flags/4x3/zm.svg); }
  .flag-icon-zm.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/zm.svg); }

.flag-icon-zw {
  background-image: url(~flag-icon-css/flags/4x3/zw.svg); }
  .flag-icon-zw.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/zw.svg); }

.flag-icon-es-ca {
  background-image: url(~flag-icon-css/flags/4x3/es-ca.svg); }
  .flag-icon-es-ca.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/es-ca.svg); }

.flag-icon-es-ga {
  background-image: url(~flag-icon-css/flags/4x3/es-ga.svg); }
  .flag-icon-es-ga.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/es-ga.svg); }

.flag-icon-eu {
  background-image: url(~flag-icon-css/flags/4x3/eu.svg); }
  .flag-icon-eu.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/eu.svg); }

.flag-icon-gb-eng {
  background-image: url(~flag-icon-css/flags/4x3/gb-eng.svg); }
  .flag-icon-gb-eng.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/gb-eng.svg); }

.flag-icon-gb-nir {
  background-image: url(~flag-icon-css/flags/4x3/gb-nir.svg); }
  .flag-icon-gb-nir.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/gb-nir.svg); }

.flag-icon-gb-sct {
  background-image: url(~flag-icon-css/flags/4x3/gb-sct.svg); }
  .flag-icon-gb-sct.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/gb-sct.svg); }

.flag-icon-gb-wls {
  background-image: url(~flag-icon-css/flags/4x3/gb-wls.svg); }
  .flag-icon-gb-wls.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/gb-wls.svg); }

.flag-icon-un {
  background-image: url(~flag-icon-css/flags/4x3/un.svg); }
  .flag-icon-un.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/un.svg); }

.flag-icon-xk {
  background-image: url(~flag-icon-css/flags/4x3/xk.svg); }
  .flag-icon-xk.flag-icon-squared {
    background-image: url(~flag-icon-css/flags/1x1/xk.svg); }

h2 {
  font-weight: 400; }

a {
  color: #14669b; }
  a.no-underline:hover {
    text-decoration: none; }
  a:hover {
    color: #0074f0; }

.text-underline {
  text-decoration: underline; }

.font-weight-bolder {
  font-weight: 500 !important; }

.list-dot li {
  position: relative;
  margin: 0; }
  @media (min-width: 768px) {
    .list-dot li {
      margin: 0 10px; } }
  .list-dot li:first-of-type {
    margin-left: 0; }
  .list-dot li:last-of-type:after {
    display: none; }
  .list-dot li:after {
    content: "•";
    position: absolute;
    margin: 0 8px;
    color: #008BEB; }
    @media (max-width: 767.98px) {
      .list-dot li:after {
        display: none; } }

.list-gap li {
  padding-bottom: .5rem; }
  .list-gap li:last-of-type {
    padding-bottom: 0; }
  .list-gap li i {
    width: 100%;
    text-align: center;
    margin-right: 0; }
    @media (min-width: 576px) {
      .list-gap li i {
        width: 14px;
        text-align: center;
        margin-right: 10px; } }

.text-orange {
  color: #E96E25; }

.text-dotted {
  border-bottom: 1px dotted; }

.display-6 {
  font-size: 1.5rem; }

.display-7 {
  font-size: 1.3rem; }

.display-8 {
  font-size: 1.1rem; }

.display-9 {
  font-size: .9rem; }

label {
  color: #062336 !important;
  font-weight: 600; }

.bg-secondary-light {
  background-color: rgba(65, 157, 228, 0.1); }

.bg-primary-light {
  background-color: #F8FAFC; }

@media (max-width: 767.98px) {
  .w-half {
    width: 50%; } }

.c-pointer {
  cursor: pointer; }

.rating .fa-star {
  background-color: #D5D5D5; }
  .rating .fa-star.checked {
    background-color: #14669b; }

.border-left-solid {
  border-left: 3px solid; }

button.close {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #f0f0f0;
  width: 20px;
  height: 20px;
  opacity: 1; }

@media (min-width: 1200px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1200px; } }

@media only screen and (max-width: 767px) {
  .container-big {
    width: calc(100% - 10px) !important;
    padding-left: 0 !important;
    padding-right: 0 !important; } }

footer {
  color: rgba(94, 94, 94, 0.87);
  background: #1a1a1a; }
  footer .footer-top {
    padding-top: 3rem;
    padding-bottom: 3rem; }
    footer .footer-top h3 {
      font-size: 16px;
      position: relative;
      margin-bottom: 1rem;
      color: #2581BC; }
      footer .footer-top h3:before {
        content: "";
        position: absolute;
        bottom: -5px;
        left: 0;
        width: 100%;
        border-top: 1px solid rgba(94, 94, 94, 0.87); }
      footer .footer-top h3:after {
        content: "";
        position: absolute;
        bottom: -5px;
        left: 0;
        width: 35%;
        border-top: 1px solid #008BEB; }
    footer .footer-top ul {
      padding-left: 17px !important; }
      footer .footer-top ul li {
        padding-bottom: .4rem;
        text-indent: -17px; }
        footer .footer-top ul li:last-of-type {
          padding-bottom: 0; }
        footer .footer-top ul li a {
          color: rgba(94, 94, 94, 0.87);
          margin-left: 5px; }
          footer .footer-top ul li a:hover {
            text-decoration: underline; }
  footer .footer-bottom {
    padding: 1rem 0;
    background-color: #161616; }
    footer .footer-bottom .svg-inline--fa {
      font-size: 20px; }
  footer .footer-brand img {
    width: 147px;
    height: auto; }

@media only screen and (max-width: 991px) {
  footer .footer-brand img {
    width: 102px; } }

.section-top {
  padding-top: 3rem; }

.section-sm-top {
  padding-top: 1rem; }

.section {
  padding-top: 3rem;
  padding-bottom: 3rem; }

.btn-info-light {
  background-color: #5BC0DE;
  color: #fff; }
  .btn-info-light:hover {
    background-color: #4ab1d0;
    color: #fff; }

.btn-outline-white {
  border: 1px solid #fff;
  color: #fff; }
  .btn-outline-white:hover {
    background-color: #fff;
    color: #062336; }

.btn-info {
  color: #fff;
  background-color: #008BEB;
  border-color: #008BEB; }

.btn-outline-info {
  border-color: #008BEB;
  color: #008BEB; }

.btn-outline-grey {
  border: 1px solid #999999;
  color: #999999; }
  .btn-outline-grey:hover {
    background-color: #999999;
    color: #fff; }

.btn-primary {
  background-color: #008BEB;
  border-color: #008BEB;
  color: #fff; }
  .btn-primary :hover {
    background-color: #0074f0;
    border-color: #0074f0; }

.btn-warning {
  background-color: #ffdb49;
  border-color: #ffdb49; }

.btn {
  padding-left: 20px;
  padding-right: 20px;
  -webkit-appearance: none; }

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #B4B4B4;
  border-color: #B4B4B4;
  background-color: transparent; }

.btn.disabled, .btn:disabled {
  opacity: 1; }

.btn-outline-primary:hover {
  color: #062336;
  border-color: #062336; }

.card-list .card {
  margin-bottom: -1px; }

@media (min-width: 768px) {
  .card-deck .card.card-num-2 {
    flex: 0 0 calc(100%/2 - 30px) !important; }
  .card-deck .card.card-num-3 {
    flex: 0 0 calc(100%/3 - 30px) !important; } }

@media (max-width: 991.98px) {
  .card-deck .card.card-num-3 {
    flex: 0 0 calc(100%/2 - 30px) !important; } }

@media (max-width: 991.98px) {
  .card-deck .card.card-num-3.card-3:last-of-type {
    flex: 0 0 calc(100%/1 - 30px) !important; } }

.card-company {
  perspective: 1000px;
  min-height: 113px;
  position: relative; }
  .card-company .card-company:hover .card-front {
    transform: rotateY(-180deg); }
  .card-company .card-content {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    transition: all 1.5s;
    position: absolute;
    top: 0;
    left: 8px;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    border: 1px solid #BDE4FF;
    width: calc(100% - 16px);
    height: 100%;
    background: #fff; }
    .card-company .card-content__text {
      text-align: center;
      font-size: 12px;
      color: #676767;
      margin: 0;
      font-weight: 500; }
    .card-company .card-content img {
      object-fit: cover;
      width: auto;
      max-width: 130px;
      height: auto;
      max-height: 90px; }
  .card-company .card-back {
    transform: rotateY(180deg);
    padding: 0 10px; }

.card-company:hover .card-front {
  transform: rotateY(-180deg); }

.card-company:hover .card-back {
  transform: rotateY(0); }

.form-control-lg {
  height: 3rem; }

label {
  color: #676767;
  font-weight: 500; }

.input-group select {
  width: 120px !important; }

.input-group input {
  width: 300px !important; }
  @media (max-width: 767.98px) {
    .input-group input {
      width: 200px !important; } }

input {
  color: #062336; }

.form-control {
  padding-bottom: 0;
  color: #062336; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #fff; }
  .form-control:focus {
    box-shadow: none; }
  .form-control.is-invalid {
    border-color: #DC3545 !important; }

.choices__list--single {
  padding-bottom: 0;
  padding-top: 6px; }

.autocomplete .autocomplete-items {
  width: 100%; }

.choices__input::-webkit-input-placeholder {
  color: #062336; }

.choices__input::-moz-placeholder {
  color: #062336; }

.choices__input:-ms-input-placeholder {
  color: #062336; }

.choices__input:-moz-placeholder {
  color: #062336; }

.jumbotron {
  height: 100vh;
  border-radius: 0;
  padding-bottom: 0;
  margin-bottom: 0;
  background: #008BEB;
  /*
  position: relative;
  &:before{
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 511px;
      height: 107px;
      background: url("/images/newYear/snow1.svg") no-repeat;
      background-size: cover;
  }
  &:after{
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      width: 342px;
      height: 98px;
      background: url("/images/newYear/snow2.svg") no-repeat;
      background-size: cover;
  }
  */ }
  .jumbotron.employers-bg {
    background: #48a8fa;
    background: -o-linear-gradient(23deg, #48a8fa 0%, #1b7cd1 26%, #e080e7 70%, #9f73e2 100%);
    background: linear-gradient(67deg, #48a8fa 0%, #1b7cd1 26%, #e080e7 70%, #9f73e2 100%); }
  @media (min-width: 768px) {
    .jumbotron {
      height: 288px; } }
  @media (min-width: 576px) and (max-width: 767.98px) {
    .jumbotron {
      height: 450px; } }
  .jumbotron h1 {
    color: #b9ebfe;
    font-weight: 800;
    font-size: 24px;
    /*position: relative;
    display: inline-block;
    &:before{
        content: '';
        position: absolute;
        left: -38px;
        top: -42px;
        width: 122px;
        height: 46px;
        background: url("/images/newYear/santa.svg") no-repeat;
        background-size: cover;
    }*/ }
  .jumbotron input {
    margin-bottom: 1rem; }
    @media (min-width: 768px) {
      .jumbotron input {
        margin-bottom: 0; } }
  .jumbotron .choices__input {
    margin-bottom: 0; }
  .jumbotron .tab-content {
    border-top: none;
    padding: 40px 0 0;
    /*

    z-index: 1;
    position: relative;

    */ }
  .jumbotron .choices {
    margin-bottom: 0; }
  .jumbotron .main-search {
    margin-top: 0 !important; }
  .jumbotron .search-tabs {
    display: none; }
  .jumbotron-form-row {
    padding: 0 15px;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between; }
  .jumbotron-form-field {
    width: 40%; }
    .jumbotron-form-field .choices__input::-webkit-input-placeholder {
      color: #8B9192; }
    .jumbotron-form-field .choices__input::-moz-placeholder {
      color: #8B9192; }
    .jumbotron-form-field .choices__input:-ms-input-placeholder {
      color: #8B9192; }
    .jumbotron-form-field .choices__input:-moz-placeholder {
      color: #8B9192; }
  .jumbotron-form-btn {
    width: 16.6%; }
    .jumbotron-form-btn-icon {
      position: relative; }
      .jumbotron-form-btn-icon:before {
        content: '';
        background: url("/images/menu/search-icon.svg") no-repeat;
        background-size: cover;
        height: 17px;
        width: 17px;
        position: absolute;
        top: 50%;
        left: 1.5vw;
        -webkit-transform: translate(0, -50%);
        -moz-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%); }

.nav-pills .nav-item {
  padding: 0 !important; }

/*@media only screen and (min-width: 1025px) and (max-height: 900px) {
    .jumbotron {
        &:before {
            width: 390px;
            height: 81px;
        }
        &:after {
            width: 290px;
            height: 80px;
        }
    }
}

@media only screen and (min-width: 1025px) and (max-height: 800px) {
    .jumbotron {
        &:before {
            width: 270px;
            height: 51px;
        }
    }
}

@media only screen and (max-width: 991px) {
    .jumbotron {
        &:before,
        &:after{
            display: none;
        }
    }
}*/
@media only screen and (max-width: 1023px) {
  .jumbotron-form-btn .btn {
    padding-left: 10px;
    padding-right: 10px; } }

@media only screen and (max-width: 992px) {
  .jumbotron-form-field {
    width: 36%; }
  .jumbotron-form-btn {
    width: 23%; } }

@media only screen and (max-width: 767px) {
  .jumbotron {
    height: auto;
    padding: 70px 0 0; }
    .jumbotron .container {
      padding: 0; }
    .jumbotron h1 {
      font-size: 20px;
      /*            &:before {
                      display: none;
                  }*/ }
    .jumbotron .btn.btn-success {
      margin-top: 16px; }
    .jumbotron .tab-content {
      padding: 30px 0 20px; }
    .jumbotron-form-row {
      padding: 0;
      margin: auto;
      -webkit-flex-direction: column;
      -moz-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center; }
    .jumbotron-form-btn, .jumbotron-form-field {
      width: calc(100% - 20px); }
    .jumbotron-form-btn-icon:before {
      left: 26vw; }
  .nav-pills .nav-item {
    padding: 22px 0 0 !important; } }

.fixed-top {
  position: fixed;
  background-color: #008BEB !important;
  z-index: 1031; }
  .fixed-top.is-hr.bg-hr {
    background: #48a8fa !important;
    background: -o-linear-gradient(23deg, #48a8fa 0%, #1b7cd1 26%, #e080e7 70%, #9f73e2 100%) !important;
    background: linear-gradient(67deg, #48a8fa 0%, #1b7cd1 26%, #e080e7 70%, #9f73e2 100%) !important; }
  @media (min-width: 992px) {
    .fixed-top {
      box-shadow: none; } }
  .fixed-top .g-snows {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    overflow: hidden; }
    .fixed-top .g-snows > li {
      opacity: 0;
      position: absolute;
      top: 0;
      background: url("/images/snow.png") no-repeat;
      background-size: cover;
      animation-name: snow-drop;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      animation-fill-mode: forwards; }
      .fixed-top .g-snows > li:nth-child(1) {
        left: 105%;
        width: 5px;
        height: 5px;
        animation-duration: 9532ms;
        animation-delay: 3313ms;
        filter: blur(2px); }
      .fixed-top .g-snows > li:nth-child(2) {
        left: 1%;
        width: 6px;
        height: 6px;
        animation-duration: 10713ms;
        animation-delay: 4482ms;
        filter: blur(0px); }
      .fixed-top .g-snows > li:nth-child(3) {
        left: 26%;
        width: 8px;
        height: 8px;
        animation-duration: 5307ms;
        animation-delay: 1027ms;
        filter: blur(3px); }
      .fixed-top .g-snows > li:nth-child(4) {
        left: 67%;
        width: 14px;
        height: 14px;
        animation-duration: 7991ms;
        animation-delay: 538ms;
        filter: blur(4px); }
      .fixed-top .g-snows > li:nth-child(5) {
        left: 71%;
        width: 0px;
        height: 0px;
        animation-duration: 5118ms;
        animation-delay: 3817ms;
        filter: blur(0px); }
      .fixed-top .g-snows > li:nth-child(6) {
        left: 40%;
        width: 7px;
        height: 7px;
        animation-duration: 10937ms;
        animation-delay: 2986ms;
        filter: blur(2px); }
      .fixed-top .g-snows > li:nth-child(7) {
        left: 44%;
        width: 19px;
        height: 19px;
        animation-duration: 7892ms;
        animation-delay: 4277ms;
        filter: blur(4px); }
      .fixed-top .g-snows > li:nth-child(8) {
        left: 4%;
        width: 8px;
        height: 8px;
        animation-duration: 10249ms;
        animation-delay: 920ms;
        filter: blur(4px); }
      .fixed-top .g-snows > li:nth-child(9) {
        left: 38%;
        width: 1px;
        height: 1px;
        animation-duration: 10488ms;
        animation-delay: 2037ms;
        filter: blur(4px); }
      .fixed-top .g-snows > li:nth-child(10) {
        left: 76%;
        width: 10px;
        height: 10px;
        animation-duration: 12359ms;
        animation-delay: 1264ms;
        filter: blur(0px); }
      .fixed-top .g-snows > li:nth-child(11) {
        left: 14%;
        width: 7px;
        height: 7px;
        animation-duration: 9298ms;
        animation-delay: 2135ms;
        filter: blur(2px); }
      .fixed-top .g-snows > li:nth-child(12) {
        left: 17%;
        width: 18px;
        height: 18px;
        animation-duration: 8691ms;
        animation-delay: 2248ms;
        filter: blur(3px); }
      .fixed-top .g-snows > li:nth-child(13) {
        left: 6%;
        width: 13px;
        height: 13px;
        animation-duration: 5884ms;
        animation-delay: 1943ms;
        filter: blur(2px); }
      .fixed-top .g-snows > li:nth-child(14) {
        left: 8%;
        width: 7px;
        height: 7px;
        animation-duration: 6628ms;
        animation-delay: 2460ms;
        filter: blur(1px); }
      .fixed-top .g-snows > li:nth-child(15) {
        left: 128%;
        width: 1px;
        height: 1px;
        animation-duration: 9813ms;
        animation-delay: 2274ms;
        filter: blur(1px); }
      .fixed-top .g-snows > li:nth-child(16) {
        left: 48%;
        width: 21px;
        height: 21px;
        animation-duration: 10646ms;
        animation-delay: 4740ms;
        filter: blur(1px); }
      .fixed-top .g-snows > li:nth-child(17) {
        left: 74%;
        width: 12px;
        height: 12px;
        animation-duration: 6256ms;
        animation-delay: 1149ms;
        filter: blur(0px); }
      .fixed-top .g-snows > li:nth-child(18) {
        left: 32%;
        width: 3px;
        height: 3px;
        animation-duration: 11252ms;
        animation-delay: 4463ms;
        filter: blur(1px); }
      .fixed-top .g-snows > li:nth-child(19) {
        left: 30%;
        width: 7px;
        height: 7px;
        animation-duration: 6708ms;
        animation-delay: 4336ms;
        filter: blur(4px); }
      .fixed-top .g-snows > li:nth-child(20) {
        left: 63%;
        width: 6px;
        height: 6px;
        animation-duration: 8005ms;
        animation-delay: 996ms;
        filter: blur(3px); }
      .fixed-top .g-snows > li:nth-child(21) {
        left: 5%;
        width: 8px;
        height: 8px;
        animation-duration: 7685ms;
        animation-delay: 4085ms;
        filter: blur(0px); }
      .fixed-top .g-snows > li:nth-child(22) {
        left: 24%;
        width: 13px;
        height: 13px;
        animation-duration: 9544ms;
        animation-delay: 4262ms;
        filter: blur(4px); }
      .fixed-top .g-snows > li:nth-child(23) {
        left: 15%;
        width: 21px;
        height: 21px;
        animation-duration: 11131ms;
        animation-delay: 2890ms;
        filter: blur(0px); }
      .fixed-top .g-snows > li:nth-child(24) {
        left: 51%;
        width: 12px;
        height: 12px;
        animation-duration: 10327ms;
        animation-delay: 3741ms;
        filter: blur(1px); }
      .fixed-top .g-snows > li:nth-child(25) {
        left: 30%;
        width: 22px;
        height: 22px;
        animation-duration: 5885ms;
        animation-delay: 4232ms;
        filter: blur(1px); }
      .fixed-top .g-snows > li:nth-child(26) {
        left: 7%;
        width: 0px;
        height: 0px;
        animation-duration: 8008ms;
        animation-delay: 2424ms;
        filter: blur(2px); }
      .fixed-top .g-snows > li:nth-child(27) {
        left: 80%;
        width: 6px;
        height: 6px;
        animation-duration: 9062ms;
        animation-delay: 1067ms;
        filter: blur(0px); }
      .fixed-top .g-snows > li:nth-child(28) {
        left: 10%;
        width: 2px;
        height: 2px;
        animation-duration: 10241ms;
        animation-delay: 4035ms;
        filter: blur(1px); }
      .fixed-top .g-snows > li:nth-child(29) {
        left: 55%;
        width: 20px;
        height: 20px;
        animation-duration: 6653ms;
        animation-delay: 1357ms;
        filter: blur(2px); }
      .fixed-top .g-snows > li:nth-child(30) {
        left: 40%;
        width: 7px;
        height: 7px;
        animation-duration: 7779ms;
        animation-delay: 3649ms;
        filter: blur(1px); }
      .fixed-top .g-snows > li:nth-child(31) {
        left: 38%;
        width: 7px;
        height: 7px;
        animation-duration: 7977ms;
        animation-delay: 4670ms;
        filter: blur(0px); }
      .fixed-top .g-snows > li:nth-child(32) {
        left: 46%;
        width: 17px;
        height: 17px;
        animation-duration: 10747ms;
        animation-delay: 4683ms;
        filter: blur(1px); }
      .fixed-top .g-snows > li:nth-child(33) {
        left: 66%;
        width: 24px;
        height: 24px;
        animation-duration: 10944ms;
        animation-delay: 2089ms;
        filter: blur(0px); }
      .fixed-top .g-snows > li:nth-child(34) {
        left: 14%;
        width: 4px;
        height: 4px;
        animation-duration: 8712ms;
        animation-delay: 4618ms;
        filter: blur(0px); }
      .fixed-top .g-snows > li:nth-child(35) {
        left: 59%;
        width: 9px;
        height: 9px;
        animation-duration: 8049ms;
        animation-delay: 2186ms;
        filter: blur(1px); }
      .fixed-top .g-snows > li:nth-child(36) {
        left: 98%;
        width: 21px;
        height: 21px;
        animation-duration: 8617ms;
        animation-delay: 1654ms;
        filter: blur(0px); }
      .fixed-top .g-snows > li:nth-child(37) {
        left: 121%;
        width: 14px;
        height: 14px;
        animation-duration: 8722ms;
        animation-delay: 343ms;
        filter: blur(1px); }
      .fixed-top .g-snows > li:nth-child(38) {
        left: 34%;
        width: 10px;
        height: 10px;
        animation-duration: 8376ms;
        animation-delay: 348ms;
        filter: blur(3px); }
      .fixed-top .g-snows > li:nth-child(39) {
        left: 55%;
        width: 15px;
        height: 15px;
        animation-duration: 5440ms;
        animation-delay: 4528ms;
        filter: blur(3px); }
      .fixed-top .g-snows > li:nth-child(40) {
        left: 60%;
        width: 22px;
        height: 22px;
        animation-duration: 8884ms;
        animation-delay: 4048ms;
        filter: blur(4px); }
      .fixed-top .g-snows > li:nth-child(41) {
        left: 10%;
        width: 7px;
        height: 7px;
        animation-duration: 6360ms;
        animation-delay: 2605ms;
        filter: blur(3px); }
      .fixed-top .g-snows > li:nth-child(42) {
        left: 69%;
        width: 12px;
        height: 12px;
        animation-duration: 5360ms;
        animation-delay: 3799ms;
        filter: blur(2px); }
      .fixed-top .g-snows > li:nth-child(43) {
        left: 57%;
        width: 1px;
        height: 1px;
        animation-duration: 8550ms;
        animation-delay: 4684ms;
        filter: blur(0px); }
      .fixed-top .g-snows > li:nth-child(44) {
        left: 83%;
        width: 0px;
        height: 0px;
        animation-duration: 11290ms;
        animation-delay: 108ms;
        filter: blur(0px); }
      .fixed-top .g-snows > li:nth-child(45) {
        left: 130%;
        width: 10px;
        height: 10px;
        animation-duration: 6778ms;
        animation-delay: 3024ms;
        filter: blur(2px); }
      .fixed-top .g-snows > li:nth-child(46) {
        left: 65%;
        width: 6px;
        height: 6px;
        animation-duration: 10207ms;
        animation-delay: 1577ms;
        filter: blur(0px); }
      .fixed-top .g-snows > li:nth-child(47) {
        left: 35%;
        width: 13px;
        height: 13px;
        animation-duration: 12594ms;
        animation-delay: 1189ms;
        filter: blur(1px); }
      .fixed-top .g-snows > li:nth-child(48) {
        left: 58%;
        width: 14px;
        height: 14px;
        animation-duration: 9859ms;
        animation-delay: 491ms;
        filter: blur(2px); }
      .fixed-top .g-snows > li:nth-child(49) {
        left: 33%;
        width: 12px;
        height: 12px;
        animation-duration: 5341ms;
        animation-delay: 887ms;
        filter: blur(3px); }
      .fixed-top .g-snows > li:nth-child(50) {
        left: 65%;
        width: 8px;
        height: 8px;
        animation-duration: 8443ms;
        animation-delay: 3022ms;
        filter: blur(3px); }
      .fixed-top .g-snows > li:nth-child(51) {
        left: 38%;
        width: 5px;
        height: 5px;
        animation-duration: 5506ms;
        animation-delay: 2697ms;
        filter: blur(1px); }
      .fixed-top .g-snows > li:nth-child(52) {
        left: 19%;
        width: 6px;
        height: 6px;
        animation-duration: 5766ms;
        animation-delay: 4166ms;
        filter: blur(0px); }
      .fixed-top .g-snows > li:nth-child(53) {
        left: 29%;
        width: 12px;
        height: 12px;
        animation-duration: 5689ms;
        animation-delay: 4256ms;
        filter: blur(2px); }
      .fixed-top .g-snows > li:nth-child(54) {
        left: 84%;
        width: 9px;
        height: 9px;
        animation-duration: 8630ms;
        animation-delay: 3499ms;
        filter: blur(1px); }
      .fixed-top .g-snows > li:nth-child(55) {
        left: 18%;
        width: 2px;
        height: 2px;
        animation-duration: 10930ms;
        animation-delay: 4405ms;
        filter: blur(4px); }
      .fixed-top .g-snows > li:nth-child(56) {
        left: 4%;
        width: 5px;
        height: 5px;
        animation-duration: 11818ms;
        animation-delay: 2522ms;
        filter: blur(2px); }
      .fixed-top .g-snows > li:nth-child(57) {
        left: 16%;
        width: 2px;
        height: 2px;
        animation-duration: 8310ms;
        animation-delay: 4445ms;
        filter: blur(1px); }
      .fixed-top .g-snows > li:nth-child(58) {
        left: 88%;
        width: 5px;
        height: 5px;
        animation-duration: 8020ms;
        animation-delay: 2786ms;
        filter: blur(4px); }
      .fixed-top .g-snows > li:nth-child(59) {
        left: 24%;
        width: 7px;
        height: 7px;
        animation-duration: 8716ms;
        animation-delay: 843ms;
        filter: blur(3px); }
      .fixed-top .g-snows > li:nth-child(60) {
        left: 105%;
        width: 10px;
        height: 10px;
        animation-duration: 7750ms;
        animation-delay: 1794ms;
        filter: blur(0px); }
      .fixed-top .g-snows > li:nth-child(61) {
        left: 116%;
        width: 22px;
        height: 22px;
        animation-duration: 8620ms;
        animation-delay: 4788ms;
        filter: blur(2px); }
      .fixed-top .g-snows > li:nth-child(62) {
        left: 11%;
        width: 1px;
        height: 1px;
        animation-duration: 6285ms;
        animation-delay: 4099ms;
        filter: blur(2px); }
      .fixed-top .g-snows > li:nth-child(63) {
        left: 17%;
        width: 12px;
        height: 12px;
        animation-duration: 6428ms;
        animation-delay: 110ms;
        filter: blur(2px); }
      .fixed-top .g-snows > li:nth-child(64) {
        left: 85%;
        width: 18px;
        height: 18px;
        animation-duration: 7779ms;
        animation-delay: 4991ms;
        filter: blur(3px); }
      .fixed-top .g-snows > li:nth-child(65) {
        left: 61%;
        width: 7px;
        height: 7px;
        animation-duration: 7319ms;
        animation-delay: 327ms;
        filter: blur(2px); }
      .fixed-top .g-snows > li:nth-child(66) {
        left: 46%;
        width: 8px;
        height: 8px;
        animation-duration: 11284ms;
        animation-delay: 4139ms;
        filter: blur(1px); }
      .fixed-top .g-snows > li:nth-child(67) {
        left: 34%;
        width: 4px;
        height: 4px;
        animation-duration: 5170ms;
        animation-delay: 3260ms;
        filter: blur(3px); }
      .fixed-top .g-snows > li:nth-child(68) {
        left: 89%;
        width: 2px;
        height: 2px;
        animation-duration: 8580ms;
        animation-delay: 1977ms;
        filter: blur(0px); }
      .fixed-top .g-snows > li:nth-child(69) {
        left: 53%;
        width: 22px;
        height: 22px;
        animation-duration: 8579ms;
        animation-delay: 1650ms;
        filter: blur(4px); }
      .fixed-top .g-snows > li:nth-child(70) {
        left: 18%;
        width: 0px;
        height: 0px;
        animation-duration: 8019ms;
        animation-delay: 3459ms;
        filter: blur(1px); }
      .fixed-top .g-snows > li:nth-child(71) {
        left: 8%;
        width: 9px;
        height: 9px;
        animation-duration: 7474ms;
        animation-delay: 2770ms;
        filter: blur(0px); }
      .fixed-top .g-snows > li:nth-child(72) {
        left: 75%;
        width: 4px;
        height: 4px;
        animation-duration: 5266ms;
        animation-delay: 4932ms;
        filter: blur(4px); }
      .fixed-top .g-snows > li:nth-child(73) {
        left: 117%;
        width: 10px;
        height: 10px;
        animation-duration: 6616ms;
        animation-delay: 4004ms;
        filter: blur(0px); }
      .fixed-top .g-snows > li:nth-child(74) {
        left: 129%;
        width: 13px;
        height: 13px;
        animation-duration: 5737ms;
        animation-delay: 3512ms;
        filter: blur(1px); }
      .fixed-top .g-snows > li:nth-child(75) {
        left: 49%;
        width: 3px;
        height: 3px;
        animation-duration: 12807ms;
        animation-delay: 4899ms;
        filter: blur(1px); }
      .fixed-top .g-snows > li:nth-child(76) {
        left: 79%;
        width: 7px;
        height: 7px;
        animation-duration: 6566ms;
        animation-delay: 3008ms;
        filter: blur(3px); }
      .fixed-top .g-snows > li:nth-child(77) {
        left: 12%;
        width: 13px;
        height: 13px;
        animation-duration: 5769ms;
        animation-delay: 2503ms;
        filter: blur(3px); }
      .fixed-top .g-snows > li:nth-child(78) {
        left: 34%;
        width: 1px;
        height: 1px;
        animation-duration: 8521ms;
        animation-delay: 2810ms;
        filter: blur(1px); }
      .fixed-top .g-snows > li:nth-child(79) {
        left: 105%;
        width: 9px;
        height: 9px;
        animation-duration: 10863ms;
        animation-delay: 4931ms;
        filter: blur(2px); }
      .fixed-top .g-snows > li:nth-child(80) {
        left: 22%;
        width: 11px;
        height: 11px;
        animation-duration: 8422ms;
        animation-delay: 85ms;
        filter: blur(1px); }
      .fixed-top .g-snows > li:nth-child(81) {
        left: 22%;
        width: 16px;
        height: 16px;
        animation-duration: 7479ms;
        animation-delay: 1692ms;
        filter: blur(2px); }
      .fixed-top .g-snows > li:nth-child(82) {
        left: 2%;
        width: 13px;
        height: 13px;
        animation-duration: 8854ms;
        animation-delay: 2174ms;
        filter: blur(3px); }
      .fixed-top .g-snows > li:nth-child(83) {
        left: 3%;
        width: 5px;
        height: 5px;
        animation-duration: 10396ms;
        animation-delay: 2255ms;
        filter: blur(0px); }
      .fixed-top .g-snows > li:nth-child(84) {
        left: 55%;
        width: 9px;
        height: 9px;
        animation-duration: 6043ms;
        animation-delay: 3790ms;
        filter: blur(1px); }
      .fixed-top .g-snows > li:nth-child(85) {
        left: 15%;
        width: 21px;
        height: 21px;
        animation-duration: 8538ms;
        animation-delay: 646ms;
        filter: blur(2px); }
      .fixed-top .g-snows > li:nth-child(86) {
        left: 121%;
        width: 18px;
        height: 18px;
        animation-duration: 7476ms;
        animation-delay: 560ms;
        filter: blur(3px); }
      .fixed-top .g-snows > li:nth-child(87) {
        left: 140%;
        width: 26px;
        height: 26px;
        animation-duration: 6721ms;
        animation-delay: 2315ms;
        filter: blur(0px); }
      .fixed-top .g-snows > li:nth-child(88) {
        left: 26%;
        width: 16px;
        height: 16px;
        animation-duration: 6849ms;
        animation-delay: 605ms;
        filter: blur(0px); }
      .fixed-top .g-snows > li:nth-child(89) {
        left: 51%;
        width: 2px;
        height: 2px;
        animation-duration: 13278ms;
        animation-delay: 3216ms;
        filter: blur(1px); }
      .fixed-top .g-snows > li:nth-child(90) {
        left: 62%;
        width: 6px;
        height: 6px;
        animation-duration: 10253ms;
        animation-delay: 2070ms;
        filter: blur(3px); }
      .fixed-top .g-snows > li:nth-child(91) {
        left: 86%;
        width: 23px;
        height: 23px;
        animation-duration: 7203ms;
        animation-delay: 4987ms;
        filter: blur(0px); }
      .fixed-top .g-snows > li:nth-child(92) {
        left: 85%;
        width: 5px;
        height: 5px;
        animation-duration: 6680ms;
        animation-delay: 1120ms;
        filter: blur(3px); }
      .fixed-top .g-snows > li:nth-child(93) {
        left: 51%;
        width: 6px;
        height: 6px;
        animation-duration: 11522ms;
        animation-delay: 618ms;
        filter: blur(0px); }
      .fixed-top .g-snows > li:nth-child(94) {
        left: 93%;
        width: 2px;
        height: 2px;
        animation-duration: 7040ms;
        animation-delay: 4195ms;
        filter: blur(1px); }
      .fixed-top .g-snows > li:nth-child(95) {
        left: 42%;
        width: 2px;
        height: 2px;
        animation-duration: 7790ms;
        animation-delay: 2118ms;
        filter: blur(3px); }
      .fixed-top .g-snows > li:nth-child(96) {
        left: 87%;
        width: 16px;
        height: 16px;
        animation-duration: 6005ms;
        animation-delay: 563ms;
        filter: blur(2px); }
      .fixed-top .g-snows > li:nth-child(97) {
        left: 26%;
        width: 19px;
        height: 19px;
        animation-duration: 12324ms;
        animation-delay: 1721ms;
        filter: blur(3px); }
      .fixed-top .g-snows > li:nth-child(98) {
        left: 18%;
        width: 9px;
        height: 9px;
        animation-duration: 8495ms;
        animation-delay: 2607ms;
        filter: blur(1px); }
      .fixed-top .g-snows > li:nth-child(99) {
        left: 34%;
        width: 5px;
        height: 5px;
        animation-duration: 10915ms;
        animation-delay: 1878ms;
        filter: blur(0px); }
      .fixed-top .g-snows > li:nth-child(100) {
        left: 4%;
        width: 4px;
        height: 4px;
        animation-duration: 12650ms;
        animation-delay: 2838ms;
        filter: blur(1px); }
    .fixed-top .g-snows-big {
      overflow: visible; }
      .fixed-top .g-snows-big > li {
        animation-name: snow-drop-big; }

@keyframes snow-drop {
  0% {
    transform: translate(0, 0);
    opacity: 0.5;
    margin-left: 0; }
  10% {
    margin-left: 15px; }
  20% {
    margin-left: 20px; }
  25% {
    transform: translate(0, 50px);
    opacity: 0.75; }
  30% {
    margin-left: 15px; }
  40% {
    margin-left: 0; }
  50% {
    transform: translate(0, 70px);
    opacity: 1;
    margin-left: -15px; }
  60% {
    margin-left: -20px; }
  70% {
    margin-left: -15px; }
  75% {
    transform: translate(0, 100px);
    opacity: 0.5; }
  80% {
    margin-left: 0; }
  100% {
    transform: translate(0, 150px);
    opacity: 0; } }

@keyframes snow-drop-big {
  0% {
    transform: translate(0, 0);
    opacity: 0.5;
    margin-left: 0; }
  10% {
    margin-left: 15px; }
  20% {
    margin-left: 20px; }
  25% {
    transform: translate(0, 80px);
    opacity: 0.75; }
  30% {
    margin-left: 15px; }
  40% {
    margin-left: 0; }
  50% {
    transform: translate(0, 161px);
    opacity: 1;
    margin-left: -15px; }
  60% {
    margin-left: -20px; }
  70% {
    margin-left: -15px; }
  75% {
    transform: translate(0, 240px);
    opacity: 0.5; }
  80% {
    margin-left: 0; }
  100% {
    transform: translate(0, 330px);
    opacity: 0; } }

.no-bg {
  background: transparent !important; }

.nav-link {
  padding: 0; }

.navbar-search.full-search-form {
  display: none !important;
  float: left; }

#small-search-form-container.full-search-form {
  display: none; }

#small-search-form-container.small-search-form {
  display: block !important; }

.site.__scrolled .navbar-default .navbar-collapse {
  border-bottom: none; }

.site.__scrolled .navbar-wrap {
  display: none; }

.site.__scrolled .navbar-search {
  display: block; }

.site.__translate .navbar-fixed {
  top: 40px; }

.navbar {
  padding: 0.5rem 1rem 1rem; }
  .navbar .nav-link {
    color: #fff;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: .8rem;
    margin-right: .8rem;
    position: relative; }
    .navbar .nav-link.pl-0 {
      padding-left: 0 !important; }
    .navbar .nav-link.active {
      border-bottom: 2px solid #fff; }
  .navbar .navbar-toggler {
    font-size: 80%;
    color: #fff; }
    .navbar .navbar-toggler:focus {
      outline: 0; }
    .navbar .navbar-toggler i {
      font-size: 22px; }
    .navbar .navbar-toggler .icon-nav {
      width: 24px;
      height: 3px;
      background-color: #fff;
      margin: 2px 0; }
      .navbar .navbar-toggler .icon-nav-block {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column; }
  .navbar .navbar-brand.logo-icon img {
    width: 40px; }
  .navbar .navbar-brand img {
    width: 170px; }
    @media (min-width: 768px) {
      .navbar .navbar-brand img {
        width: 230px; } }

.navbar-top {
  padding: 10px 0 0;
  z-index: 1;
  position: relative;
  /*
  &:before{
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 330px;
      height: 47px;
      background: url("/images/newYear/garland.svg") repeat-x;
      background-size: contain;
      z-index: -1;
  }
  &:after{
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      width: 245px;
      height: 71px;
      background: url("/images/newYear/garland.svg") no-repeat;
      background-size: cover;
      z-index: -1;
  }
  */ }
  .navbar-top .container {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between; }
  .navbar-top .navbar-brand {
    height: 41px; }
    .navbar-top .navbar-brand img {
      width: auto;
      height: 30px; }
  .navbar-top ul {
    margin-bottom: 0; }
    .navbar-top ul > li:nth-child(1) {
      padding-right: 0; }
    .navbar-top ul li {
      padding: 3px 10px; }
      .navbar-top ul li:nth-of-type {
        padding-right: 0; }
      .navbar-top ul li a {
        color: #fff; }
        .navbar-top ul li a:hover {
          text-decoration: none; }
      .navbar-top ul li .dropdown-item {
        color: #212529 !important; }
      .navbar-top ul li .color-black {
        color: #062336; }

.link-wrap a {
  color: #008BEB !important; }
  .link-wrap a:hover {
    color: #0074f0 !important; }

.lang-icon .flag-icon {
  width: 24px;
  height: 19px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background-size: cover; }

.lang-icon .dropdown-menu {
  padding: 0;
  min-width: auto; }
  .lang-icon .dropdown-menu .flag-icon {
    margin-right: 10px; }

.lang-icon .dropdown-item {
  padding: 10px 8px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: flex-start; }

.btn-open-menu {
  background: none;
  border: none; }
  .btn-open-menu .fa-user {
    width: 18px; }
  .btn-open-menu:focus {
    outline: none; }

/* The container <div> - needed to position the dropdown content */
.guest-dropdown {
  position: relative;
  display: inline-block;
  /* Dropdown Button */
  /* Dropdown Content (Hidden by Default) */
  /* Links inside the dropdown */ }
  .guest-dropdown:before {
    content: "";
    padding: 10px 0;
    position: absolute;
    left: -6px;
    top: -6px;
    width: calc(100% + 12px);
    height: calc(100% + 12px); }
  .guest-dropdown .dropbtn {
    padding: 1px 10px;
    border: none;
    line-height: 26px; }
  .guest-dropdown:hover .dropdown-content {
    display: block;
    z-index: 1031; }
  .guest-dropdown:hover .dropbtn {
    background-color: #ffdb49; }
  .guest-dropdown > .dropdown-content {
    right: 0;
    display: none;
    position: absolute;
    background-color: #fff;
    min-width: 200px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    z-index: 1; }
  .guest-dropdown > .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block; }
    .guest-dropdown > .dropdown-content a:hover {
      background-color: #ddd; }

.isResponse {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #EFD031;
  position: absolute;
  top: 3px;
  right: 3px; }

.items-for-mob {
  display: none !important; }

.dropdown-toggle::after {
  display: none; }

.btn-login-reg {
  color: #A9FFFC;
  border: 1px solid #A9FFFC;
  font-size: 14px;
  line-height: 26px;
  padding: 0 10px;
  background: none; }
  .btn-login-reg:focus {
    outline: none; }
  .btn-login-reg .fa-sign-in-alt,
  .btn-login-reg .fas {
    display: none; }
  .btn-login-reg-wrap {
    position: relative;
    padding-right: 0 !important; }

.login-reg-block {
  position: absolute;
  right: 0;
  top: 36px;
  display: none;
  background-color: #fff;
  width: 377px;
  max-height: calc(100vh - 60px);
  overflow: hidden;
  overflow-y: auto;
  padding: 3px 20px 15px;
  -webkit-box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.4);
  -ms-overflow-style: none;
  scrollbar-width: none; }
  .login-reg-block::-webkit-scrollbar {
    width: 0;
    background: transparent;
    display: none; }
  .login-reg-block::-webkit-scrollbar-thumb {
    background: #FF0000; }
  .login-reg-block__open {
    display: inline-block; }
  .login-reg-block-close {
    display: none; }
  .login-reg-block-header {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center; }
    .login-reg-block-header p {
      width: 50%;
      font-size: 15px;
      line-height: 50px;
      color: #008BEB;
      border: none;
      border-bottom: 1px solid #008BEB;
      margin-bottom: 0;
      text-align: center;
      cursor: pointer; }
      .login-reg-block-header p.active {
        color: #062336;
        border-bottom: 4px solid #008BEB;
        background-color: #fff; }
  .login-reg-block-content {
    padding: 20px 0 0;
    width: 100%;
    display: none; }
    .login-reg-block-content__active {
      display: block; }
    .login-reg-block-content .btn-primary {
      padding: 0 20px;
      line-height: 36px;
      font-size: 14px;
      margin: 0 auto 0;
      width: 100%; }
  .login-reg-block .login-reg-form-row {
    margin-bottom: 10px;
    text-align: left; }
    .login-reg-block .login-reg-form-row .was-validated .form-control:invalid,
    .login-reg-block .login-reg-form-row .form-control.is-invalid {
      background: none; }
    .login-reg-block .login-reg-form-row label {
      font-size: 13px;
      color: #062336;
      margin-bottom: 5px; }
    .login-reg-block .login-reg-form-row .material-icons-outlined {
      font-size: 16px;
      color: #008BEB;
      vertical-align: sub;
      cursor: pointer; }
    .login-reg-block .login-reg-form-row .star-label {
      font-size: 13px; }
    .login-reg-block .login-reg-form-row .form-control {
      height: 22px; }
    .login-reg-block .login-reg-form-row .form-control {
      height: 30px;
      font-size: 14px; }
    .login-reg-block .login-reg-form-row .form-reg-field {
      height: 30px; }
      .login-reg-block .login-reg-form-row .form-reg-field .form-control {
        height: 27px; }
  .login-reg-block .forgot-pass {
    font-size: 13px; }
    .login-reg-block .forgot-pass a {
      color: #008BEB; }
  .login-reg-block .form-auth-text {
    color: #6C757D;
    font-size: 12px;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 0; }
    .login-reg-block .form-auth-text span {
      font-weight: bold; }
    .login-reg-block .form-auth-text a {
      color: #008BEB; }
  .login-reg-block .login-reg-social {
    margin-top: 10px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-end;
    -moz-align-items: flex-end;
    -ms-align-items: flex-end;
    align-items: flex-end;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center; }
    .login-reg-block .login-reg-social .social-img-wrap {
      height: 19px;
      width: 19px;
      border-radius: 50%; }
    .login-reg-block .login-reg-social .fa-google {
      font-size: 15px;
      color: #D80027; }
    .login-reg-block .login-reg-social .fa-facebook-f {
      font-size: 17px;
      color: #4680C2; }
    .login-reg-block .login-reg-social a {
      margin: 0 15px;
      padding: 5px;
      text-align: center; }
  .login-reg-block .radio-button-block {
    margin-bottom: 20px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
    -ms-flex-pack: flex-start; }
    .login-reg-block .radio-button-block > li {
      padding: 0; }
    .login-reg-block .radio-button-block .castome-radio-btn {
      visibility: hidden;
      margin-left: 0; }
      .login-reg-block .radio-button-block .castome-radio-btn:after {
        width: 10px;
        height: 10px;
        top: -2px;
        left: -1px; }
      .login-reg-block .radio-button-block .castome-radio-btn:checked:before {
        width: 10px;
        height: 10px;
        top: -2px;
        left: -1px; }
      .login-reg-block .radio-button-block .castome-radio-btn:checked:after {
        width: 6px;
        height: 6px;
        top: 5px;
        left: 1px; }
  .login-reg-block .already-registered-text {
    font-size: 16px;
    text-align: center;
    margin: 15px auto 0; }
    .login-reg-block .already-registered-text a {
      color: #008BEB; }
  .login-reg-block .form-control__desc {
    display: none;
    font-size: 13px;
    line-height: 20px;
    color: #6C757D; }

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    right: .9rem;
    padding-bottom: 0; } }

@media only screen and (max-width: 991px) {
  .navbar-top {
    z-index: 0;
    padding: 6px 0 0; }
    .navbar-top:before, .navbar-top:after {
      display: none; }
    .navbar-top .navbar-brand img {
      height: 20.9px; }
    .navbar-top ul .lang-icon {
      padding-right: 0; }
    .navbar-top ul li {
      padding: 3px 16px; }
    .navbar-top ul > li:nth-child(1) {
      margin-right: -1px;
      margin-top: -3px; }
  .navbar .nav-link {
    color: rgba(94, 94, 94, 0.87); }
  .items-for-mob {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .fixed-top .g-snows {
    display: none; } }

@media only screen and (max-width: 767px) {
  .login-reg-block .login-reg-form-row {
    margin-bottom: 10px;
    text-align: left; }
  .login-reg-block .radio-button-block {
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row; }
    .login-reg-block .radio-button-block li {
      width: 50%;
      margin-bottom: 0; }
  .login-reg-block .form-control__desc {
    display: inline-block; }
  .navbar-top ul li {
    padding: 3px 10px; } }

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .login-reg-block {
    width: 100vw;
    top: 51px;
    padding: 0 16px 10px; }
    .login-reg-block-close {
      display: inline-block;
      font-size: 14px;
      line-height: 31px;
      color: #008AEA;
      border: none;
      background-color: #fff;
      width: 100%; }
      .login-reg-block-close:focus {
        outline: none; }
    .login-reg-block-header p {
      width: 50%;
      padding: 0;
      text-align: center;
      font-size: 14px;
      line-height: 42px;
      border-bottom: 3px solid #D8D8D8; }
    .login-reg-block-content {
      padding: 20px 0 16px; }
      .login-reg-block-content .btn-primary {
        padding: 0 20px;
        line-height: 35px;
        font-size: 13px;
        margin: 0 auto; }
    .login-reg-block .radio-button-block {
      -webkit-justify-content: flex-start;
      -moz-justify-content: flex-start;
      -ms-justify-content: flex-start;
      justify-content: flex-start;
      -ms-flex-pack: flex-start;
      padding: 0;
      margin-bottom: 20px; }
      .login-reg-block .radio-button-block label {
        font-size: 13px; }
      .login-reg-block .radio-button-block .castome-radio-btn:after {
        width: 20px;
        height: 20px;
        top: -2px;
        left: -1px; }
      .login-reg-block .radio-button-block .castome-radio-btn:checked:before {
        width: 20px;
        height: 20px;
        top: -2px;
        left: -1px; }
      .login-reg-block .radio-button-block .castome-radio-btn:checked:after {
        width: 12px;
        height: 12px;
        top: 2px;
        left: 3px; }
    .login-reg-block .login-reg-form-row label {
      font-size: 14px;
      margin-bottom: 5px; }
    .login-reg-block .login-reg-form-row .star-label {
      font-size: 15px; }
    .login-reg-block .login-reg-form-row .form-control {
      height: 31px; }
    .login-reg-block .login-reg-form-row .form-control {
      height: 33px; }
    .login-reg-block .login-reg-form-row .form-reg-field {
      height: 33px; }
      .login-reg-block .login-reg-form-row .form-reg-field .form-control {
        height: 30px; }
    .login-reg-block .form-auth-text {
      font-size: 11px;
      margin-top: 20px; }
    .login-reg-block .login-reg-social .social-img-wrap {
      height: 27px;
      width: 27px; }
    .login-reg-block .login-reg-social .fa-google {
      line-height: 27px;
      font-size: 17px; }
    .login-reg-block .login-reg-social .fa-facebook-f {
      font-size: 21px; }
    .login-reg-block .login-reg-social a {
      margin: 12px; }
    .login-reg-block .custom-control {
      margin-bottom: 15px;
      padding-left: 29px; }
      .login-reg-block .custom-control-label {
        font-size: 14px; }
        .login-reg-block .custom-control-label::before {
          width: 20px;
          height: 20px;
          left: -29px; }
    .login-reg-block .custom-control-label::after {
      left: -29px; }
    .login-reg-block .forgot-pass {
      font-size: 14px; }
  .btn-login-reg {
    border: none; }
    .btn-login-reg .fa-sign-in-alt,
    .btn-login-reg .fas {
      display: inline-block;
      font-size: 14px;
      color: #fff; }
    .btn-login-reg span {
      display: none; }
    .btn-login-reg-wrap {
      position: static; } }

.carousel {
  position: relative; }
  .carousel h2 {
    margin-bottom: 15px; }
  .carousel p {
    font-size: .8rem;
    line-height: 1.5rem; }
  .carousel .carousel-indicators {
    background-color: #fff;
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    left: 50%;
    margin: 0;
    right: inherit;
    padding: 5px;
    border-radius: 20px; }
    .carousel .carousel-indicators li {
      border-radius: 50%;
      background-color: #e9ebec;
      width: 12px;
      height: 12px; }
      .carousel .carousel-indicators li.active {
        background-color: #008BEB; }
  .carousel .read-more-btn {
    color: #419DE4;
    cursor: pointer; }
    .carousel .read-more-btn:hover {
      text-decoration: underline; }
  .carousel .carousel-more-text {
    display: none; }
  .carousel .active-more-text .carousel-more-text {
    display: inline-block; }
  .carousel .active-more-text .read-more-btn {
    display: none; }

.pagination .page-link {
  color: #062336;
  padding: 0.5rem .9rem;
  margin-right: 10px;
  border-radius: 3px; }
  .pagination .page-link:hover {
    color: #6C757D;
    cursor: pointer;
    background-color: #f0f0f0; }

.pagination .page-item.active .page-link {
  border-radius: 3px; }

.pagination .next, .pagination .prev {
  padding-top: 6px; }

.pagination .btn-width {
  width: 66px;
  text-align: center;
  color: #D5D5D5 !important;
  font-weight: 500; }

@media only screen and (max-width: 768px) {
  .pagination .btn-width {
    width: 37px; }
  .pagination .page-link {
    margin-right: 5px; }
  .pagination {
    padding-left: 10px; } }

.nav-pills {
  margin-bottom: -3px;
  -webkit-flex-wrap: inherit;
  -moz-flex-wrap: inherit;
  -ms-flex-wrap: inherit;
  flex-wrap: inherit;
  white-space: nowrap; }
  @media (min-width: 768px) {
    .nav-pills {
      overflow-x: auto;
      white-space: inherit; } }
  .nav-pills .nav-item {
    padding: 16px 20px 0; }
    @media (min-width: 768px) {
      .nav-pills .nav-item {
        padding: 16px 30px 0; } }
    .nav-pills .nav-item .nav-link {
      padding: 12px 20px 20px;
      border-radius: 0;
      color: #fff;
      position: relative; }
      .nav-pills .nav-item .nav-link.active {
        background: #2B73B4; }

.nav-tabs {
  overflow-x: auto;
  -webkit-flex-wrap: inherit;
  -moz-flex-wrap: inherit;
  -ms-flex-wrap: inherit;
  flex-wrap: inherit;
  white-space: nowrap;
  overflow-y: hidden; }
  @media (min-width: 576px) {
    .nav-tabs {
      overflow-x: inherit;
      overflow-y: inherit;
      white-space: inherit; } }
  .nav-tabs .nav-item {
    margin-bottom: 1px; }
    @media (min-width: 576px) {
      .nav-tabs .nav-item {
        margin-bottom: -1px; } }
  .nav-tabs .nav-link {
    padding: 0 .6rem  1rem;
    border: 0; }
    @media (min-width: 768px) {
      .nav-tabs .nav-link {
        padding: 0 4rem  1rem; } }
    .nav-tabs .nav-link.active {
      border-bottom: 4px solid #008BEB; }

.tab-content {
  border-top: 1px solid #fff; }

@media only screen and (max-width: 767px) {
  .nav-pills .nav-item .nav-link {
    padding: 10px 20px; } }

.breadcrumb {
  padding: 1.5rem 0 0.75rem 0;
  margin-bottom: 10px; }
  .breadcrumb .fa-home {
    color: #008BEB; }

.breadcrumb-item.active,
.breadcrumb-item,
.breadcrumb-item .fas {
  color: #008BEB; }

@media only screen and (max-width: 768px) {
  .breadcrumb {
    padding: 17px 0 20px;
    margin-bottom: 0;
    font-size: 12px; } }

.alert-primary {
  background-color: #F5FBFF;
  border-color: #E6F3FF; }

.alert-success {
  background-color: #EBFEEE; }

.alert-danger {
  background-color: #FEECEB; }

.alert-dismissible .close {
  top: 50%;
  right: 15px;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  background: none; }

.alert-personal-data-warning {
  color: #857226;
  background-color: #fff8db;
  border-color: #fff5cc; }

.mainFilters {
  opacity: 0;
  -webkit-transition: opacity .6s;
  -moz-transition: opacity .6s;
  -o-transition: opacity .6s;
  transition: opacity .6s; }
  .mainFilters.fast {
    -webkit-transition: opacity .3s;
    -moz-transition: opacity .3s;
    -o-transition: opacity .3s;
    transition: opacity .3s; }

.language-filter .chosen-container .chosen-choices {
  width: 320px;
  line-height: 28px; }

.language-filter .chosen-container .chosen-drop {
  width: 320px; }

.chosen-container {
  width: 100% !important;
  font-size: 14px; }
  .chosen-container .chosen-choices {
    list-style: none;
    line-height: 32px;
    border-radius: 4px;
    border: 1px solid #ccc;
    background-image: none;
    box-shadow: none;
    padding: 0px 12px; }
    .chosen-container .chosen-choices:after {
      content: "▾";
      color: #787878;
      position: absolute;
      font-size: 20px;
      top: 1.5em;
      right: 1.5em; }
    .chosen-container .chosen-choices:focus {
      box-shadow: none;
      outline: none; }
  .chosen-container .chosen-results li.highlighted {
    background-image: none;
    background-color: #008BEB; }
  .chosen-container-multi .chosen-choices li.search-choice {
    margin: 5px 5px 3px 0;
    border: none;
    background-image: none;
    background-color: #e8e8e8;
    border-radius: 4px;
    padding: 5px 20px 5px 5px;
    float: left;
    line-height: 13px;
    position: relative; }
    .chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
      top: 6px; }
  .chosen-container-multi .chosen-choices li.search-field input[type="text"] {
    background: transparent !important;
    border: 0 !important;
    color: #55595c;
    margin: 0;
    padding: 0.375rem 0.75rem;
    outline: 0;
    height: 1%; }
  .chosen-container-active .chosen-choices {
    border: #ffdb49 1px solid; }

.chosen-container .chosen-drop {
  background: #fff;
  border: 1px solid #ccc;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  margin-top: -1px;
  position: absolute;
  top: 100%;
  left: -9000px;
  z-index: 1060; }

.chosen-container.chosen-with-drop .chosen-drop {
  left: 0;
  right: 0; }

.chosen-container-multi .chosen-results {
  margin: 0 0 0 0;
  padding: 0; }

.chosen-container .chosen-results {
  color: #55595c;
  margin: 0 4px 4px 0;
  max-height: 240px;
  padding: 0 0 0 4px;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }

.chosen-container-multi .chosen-results {
  margin: 0 0 0 0;
  padding: 0; }

.chosen-container .chosen-results li.active-result {
  cursor: pointer;
  display: list-item; }

.chosen-container .chosen-results li {
  display: none;
  line-height: 1.5;
  list-style: none;
  margin: 0;
  padding: 5px 6px; }

.chosen-container {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none; }

.google-select .chosen-results:after {
  content: '';
  display: block;
  width: 100%;
  height: 32px;
  background-image: url("/images/powered_by_google_on_white_hdpi.png");
  background-position: center right;
  background-size: 130px;
  background-repeat: no-repeat;
  position: sticky;
  bottom: 0;
  background-color: #fff; }

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .chosen-container .chosen-drop {
    width: 166px; } }

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .chosen-container .chosen-choices {
    width: 100% !important; }
  .chosen-container .chosen-drop {
    width: 100% !important; } }

.choices__list--dropdown {
  z-index: 4; }

.bootstrap-tagsinput {
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  padding: 4px 6px;
  color: #555;
  vertical-align: middle;
  border-radius: 4px;
  max-width: 100%;
  line-height: 30px;
  cursor: text; }

.bootstrap-tagsinput input {
  border: none;
  box-shadow: none;
  outline: none;
  background-color: transparent;
  padding: 0 6px;
  margin: 0;
  width: auto;
  max-width: inherit; }

.bootstrap-tagsinput.form-control input::-moz-placeholder {
  color: #777;
  opacity: 1; }

.bootstrap-tagsinput.form-control input:-ms-input-placeholder {
  color: #777; }

.bootstrap-tagsinput.form-control input::-webkit-input-placeholder {
  color: #777; }

.bootstrap-tagsinput input:focus {
  border: none;
  box-shadow: none; }

.bootstrap-tagsinput .tag {
  font-size: 100%;
  font-weight: normal;
  background-color: #e8e8e8;
  margin-right: 2px;
  color: #333; }

.bootstrap-tagsinput .tag [data-role="remove"] {
  font-weight: bold;
  margin-left: 8px;
  cursor: pointer; }

.bootstrap-tagsinput .tag [data-role="remove"]:after {
  content: "x";
  color: #888888;
  padding: 0px 2px;
  font-size: 15px; }

.bootstrap-tagsinput .tag [data-role="remove"]:hover {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05); }

.bootstrap-tagsinput .tag [data-role="remove"]:hover:active {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }

.label {
  display: inline;
  padding: .2em .6em .3em;
  font-size: 75%;
  color: #fff;
  border-radius: .25em; }

.reset-select .chosen-container-single a.chosen-single {
  background: none;
  height: 34px;
  border: 1px solid #ccc;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px; }
  .reset-select .chosen-container-single a.chosen-single span {
    line-height: 31px; }
  .reset-select .chosen-container-single a.chosen-single div > b {
    background-position-y: 6px; }

.reset-select.white-bg .chosen-container-single a.chosen-single {
  background: white; }

.account .offers-list-item {
  line-height: 1.4; }
  .account .offers-list-item.unfinished {
    background-color: #efefef;
    padding-bottom: 0; }
  .account .offers-list-item.unfinished {
    background-color: #FEFF9B;
    padding-bottom: 0; }
  .account .offers-list-item.moderation {
    background-color: lightyellow;
    padding-bottom: 0; }
  .account .offers-list-item.unapproved {
    background-color: #ffe0e0;
    padding-bottom: 0; }
  .account .offers-list-item span.unfinished {
    padding: 0;
    color: #505050;
    margin: 0 0 0 15px; }
  .account .offers-list-item span.moderation {
    padding: 0;
    color: #cc872b;
    margin: 0 0 0 15px; }
  .account .offers-list-item span.unapproved {
    padding: 0;
    color: indianred;
    margin: 0 0 0 15px; }

.account .offers-list-last {
  position: relative;
  bottom: 0; }

.section-banner {
  background: url("/images/banner-bg.jpg") bottom center no-repeat;
  background-size: cover;
  padding: 20px 0 68px;
  position: relative; }

.section-banner-img {
  display: none;
  height: 77px;
  position: absolute;
  right: 27px;
  bottom: 0; }

.section-banner .container {
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex; }

.banner-title {
  font-size: 35px;
  font-weight: 500;
  color: #008BEB;
  margin-bottom: 15px; }

.banner-subtitle {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.1;
  text-align: center;
  color: #6C757D;
  max-width: 570px; }

.choices__inner {
  padding: 2px 7.5px 0;
  min-height: 35px; }

.img-responsive {
  max-width: 100%;
  height: auto; }

.banner-yellow {
  margin-top: 35px; }
  .banner-yellow .container > div {
    position: relative;
    background-color: #FFFFD4;
    padding: 22px 0 24px 28px; }
  .banner-yellow .img-desc {
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    height: 185px;
    width: auto; }
  .banner-yellow .img-desc-mob {
    display: none;
    width: auto;
    height: 159px; }

.banner-grey {
  margin-top: 35px; }
  .banner-grey .container > div {
    position: relative;
    background-color: #F3F4F4;
    padding: 22px 0 24px 28px; }
  .banner-grey .img-desc {
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    height: 190px;
    width: auto; }
  .banner-grey .img-desc-mob {
    display: none;
    width: auto; }

.banner-text {
  font-size: 16px;
  margin-bottom: 20px;
  color: #116CAC;
  font-weight: 500; }
  .banner-text span {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 600;
    color: #EE2181; }

@media only screen and (max-width: 1199px) {
  .banner-grey .img-desc {
    top: 61%;
    height: 143px; }
  .banner-yellow .img-desc {
    height: 148px; }
  .banner-text {
    font-size: 14px; }
    .banner-text span {
      font-size: 18px; } }

@media only screen and (max-width: 991px) {
  .banner-yellow .img-desc,
  .banner-grey .img-desc {
    display: none; }
  .banner-grey {
    margin-top: 43px; }
    .banner-grey .container {
      background-color: #F3F4F4;
      text-align: right; }
      .banner-grey .container > div {
        text-align: left;
        padding: 20px 18px 0;
        width: 100%; }
    .banner-grey .img-desc-mob {
      display: inline-block;
      height: 100px;
      right: -18px;
      position: relative;
      margin-top: -77px; }
  .banner-yellow .container {
    background-color: #FFFFD4;
    text-align: right; }
    .banner-yellow .container > div {
      text-align: left;
      padding: 20px 0 0 18px; }
  .banner-yellow .banner-text {
    margin-bottom: 50px; }
  .banner-yellow .img-desc-mob {
    display: inline-block;
    height: 159px;
    margin-top: -95px;
    position: relative;
    right: -15px; }
  .banner-text {
    font-size: 16px;
    margin-bottom: 20px; }
    .banner-text span {
      font-size: 23px; }
  .section-banner {
    background: url("/images/banner-bg-md.jpg") bottom center no-repeat;
    background-size: cover;
    padding: 22px 0 44px; }
  .banner-title {
    font-size: 24px;
    margin-bottom: 11px; }
  .banner-subtitle {
    font-size: 14px;
    margin-bottom: 15px; } }

@media only screen and (max-width: 767px) {
  .section-banner {
    background: #F9FAFC;
    padding-top: 10px;
    padding-bottom: 36px; }
  .section-banner-img {
    display: inline-block; }
  .banner-title {
    font-size: 16px;
    margin-bottom: 10px; }
  .banner-subtitle {
    font-size: 15px;
    margin-bottom: 14px; }
  .banner-grey {
    margin-top: 22px; }
  .banner-text {
    width: 92%; }
    .banner-text-mob {
      display: none; }
  .banner-grey .img-desc-mob {
    height: 145px;
    right: -9px;
    margin-top: -101px; }
  .banner-yellow {
    margin-top: 22px; }
    .banner-yellow .img-desc-mob {
      right: -10px;
      margin-top: -86px; } }

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .banner-grey {
    padding: 0 10px; }
    .banner-grey .img-desc-mob {
      right: 10px;
      position: relative;
      margin-top: -26px;
      width: calc(100% + 20px);
      height: auto; }
  .banner-yellow {
    padding: 0 10px; }
    .banner-yellow .img-desc-mob {
      right: 10px;
      position: relative;
      margin-top: -62px;
      width: calc(100% + 20px);
      height: auto; } }

.form-notification-subscription {
  width: 95%;
  max-width: 495px;
  margin: 30px auto; }
  .form-notification-subscription .color-btn {
    background-color: #5BC0DE;
    border-color: #5BC0DE; }
  .form-notification-subscription .form-group {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between; }
  .form-notification-subscription-title {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    margin-bottom: 11px; }
    .form-notification-subscription-title img {
      width: 22px;
      height: auto;
      margin-right: 13px; }
    .form-notification-subscription-title p {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 0; }

@media only screen and (max-width: 768px) {
  .form-notification-subscription .form-group {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
    .form-notification-subscription .form-group input {
      width: 100%;
      margin-bottom: 10px; }
    .form-notification-subscription .form-group button {
      margin: 0 !important; }
  .form-notification-subscription {
    width: 100%;
    padding: 0 10px; }
    .form-notification-subscription .form-check {
      padding-left: 28px; } }

.vacancies-filter {
  width: 280px; }
  .vacancies-filter-bg {
    padding: 15px 13px 17px;
    width: 100%;
    margin-bottom: 30px;
    border: 1px solid #F0F0F0; }
  .vacancies-filter .form-group {
    margin-bottom: 28px; }
    .vacancies-filter .form-group label {
      margin-bottom: 7px; }
  .vacancies-filter .btn-outline-primary {
    color: #14669B;
    border-color: #14669B;
    margin: 0 13px; }
    .vacancies-filter .btn-outline-primary:hover {
      background-color: #14669B;
      border-color: #14669B;
      color: #fff; }
  .vacancies-filter input[type=checkbox] {
    transform: scale(1.5); }
  .vacancies-filter .block-buttons {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    -ms-justify-content: flex-end;
    justify-content: flex-end;
    -ms-flex-pack: flex-end; }
  .vacancies-filter .btn-advanced-search {
    cursor: pointer;
    width: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between; }
    .vacancies-filter .btn-advanced-search p {
      color: #008BEB;
      margin: 0; }
    .vacancies-filter .btn-advanced-search i {
      color: #008BEB; }
    .vacancies-filter .btn-advanced-search.active i {
      -webkit-transform: rotate(-180deg);
      -moz-transform: rotate(-180deg);
      -ms-transform: rotate(-180deg);
      transform: rotate(-180deg); }
  .vacancies-filter .form-check-label {
    margin-left: 5px; }

.choices__inner {
  background-color: #fff; }

@media only screen and (max-width: 991px) {
  .vacancies-filter {
    width: 255px; } }

@media only screen and (max-width: 767px) {
  .vacancies-mob .vacancies-content {
    position: relative; }
  .vacancies-mob .vacancies-filter {
    margin-bottom: 20px;
    width: 100%;
    display: none;
    position: absolute;
    background-color: #fff;
    z-index: 1;
    top: 52px;
    border-bottom: 1px solid #D6D6D6;
    padding-bottom: 20px; }
    .vacancies-mob .vacancies-filter .form-group label {
      color: #676767; }
    .vacancies-mob .vacancies-filter-bg {
      padding: 0 10px;
      border: none;
      margin-bottom: 20px; }
    .vacancies-mob .vacancies-filter .choices__inner,
    .vacancies-mob .vacancies-filter .form-control {
      color: rgba(0, 0, 0, 0.38); }
    .vacancies-mob .vacancies-filter .form-group {
      margin-bottom: 10px; }
    .vacancies-mob .vacancies-filter .block-buttons {
      -webkit-justify-content: center !important;
      -moz-justify-content: center !important;
      -ms-justify-content: center !important;
      justify-content: center !important;
      -ms-flex-pack: center !important; }
    .vacancies-mob .vacancies-filter .btn {
      margin: 0 10px; } }

.slider-similar-jobs-title {
  padding: 20px 15px;
  border-top: 1px solid #F0F0F0;
  width: 100%;
  color: #062336;
  font-size: 16px;
  text-align: left;
  font-weight: 500;
  margin-bottom: -20px; }

.slider-similar-jobs-arrows {
  border: 1px solid #D5D5D5;
  border-radius: 3px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  width: 86px;
  position: absolute;
  right: 0;
  bottom: -56px; }

.slider-similar-jobs .carousel-control-next {
  position: relative;
  width: 43px;
  height: 35px; }

.slider-similar-jobs .carousel-control-prev {
  position: relative;
  width: 43px;
  height: 35px;
  border-right: 1px solid #D5D5D5; }
  .slider-similar-jobs .carousel-control-prev img {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg); }

.slider-similar-jobs .slide-title {
  color: #2480B9;
  font-weight: 500;
  font-size: 15px; }

.slider-similar-jobs .slide-company {
  color: #14669B;
  font-size: 15px;
  margin: 15px 0 20px; }

.slider-similar-jobs .slide-deskription {
  color: #062336;
  text-align: left;
  font-size: 14px;
  margin-bottom: 0;
  line-height: 1.3; }
  .slider-similar-jobs .slide-deskription span {
    font-weight: 500;
    display: block; }

.slider-similar-jobs .slide-wrap {
  border: 1px solid #F0F0F0;
  padding: 20px 15px; }

.slider-similar-jobs .list-country-tags {
  padding: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  margin-bottom: 30px; }
  .slider-similar-jobs .list-country-tags li {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    margin-right: 30px; }
  .slider-similar-jobs .list-country-tags p {
    margin: 0; }
  .slider-similar-jobs .list-country-tags i {
    margin-right: 15px;
    color: #14669B; }

.similar-vacancy {
  margin-top: 20px;
  margin-right: 22.5px;
  border-radius: 5px;
  border: 1px solid #D6D6D6;
  width: calc(33.3% - 15px);
  padding: 10px 20px 16px 23px;
  position: relative; }
  .similar-vacancy-wrap {
    width: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
    -ms-flex-pack: flex-start;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .similar-vacancy:nth-child(3n + 3) {
    margin-right: 0; }
  .similar-vacancy:before {
    content: '';
    width: 5px;
    height: 100%;
    background-color: #00B955;
    border-radius: 5px 0 0 5px;
    position: absolute;
    left: 0;
    top: 0; }
  .similar-vacancy .vacancy-title {
    font-weight: 400; }

@media only screen and (max-width: 1199px) {
  .slider-similar-jobs .list-country-tags li {
    margin-right: 15px; }
  .slider-similar-jobs .list-country-tags img {
    margin-right: 10px; } }

@media only screen and (max-width: 992px) {
  .slider-similar-jobs-title {
    padding: 19px 20px 23px; }
  .slider-similar-jobs .list-country-tags {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start; }
  .similar-vacancy {
    margin-right: 28px;
    width: calc(50% - 14px); }
    .similar-vacancy:nth-child(3n + 3) {
      margin-right: 28px; }
    .similar-vacancy:nth-child(2n + 2) {
      margin-right: 0; }
    .similar-vacancy .vacancy-title {
      font-size: 14px;
      margin-bottom: 3px; } }

.cv-slide-title-photo {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between; }
  .cv-slide-title-photo .slide-title {
    width: calc(100% - 70px); }

@media only screen and (max-width: 767px) {
  .similar-vacancy {
    width: 96%;
    margin: 20px auto 0; }
  .similar-vacancy:nth-child(3n + 3),
  .similar-vacancy:nth-child(2n + 2) {
    margin-right: auto; } }

.add-company-review-page {
  padding: 95px 0 65px; }

.form-add-company-review label {
  font-weight: 400; }

.form-add-company-review .block-personal-information {
  background-color: #CDE6FF;
  border: 1px solid #CDE6FF;
  padding: 10px 16px;
  margin-bottom: 18px; }

.form-add-company-review .form-group label {
  color: #062336; }

.form-add-company-review .block-working-hours {
  margin-bottom: 20px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between; }
  .form-add-company-review .block-working-hours p {
    margin-bottom: 10px; }
  .form-add-company-review .block-working-hours > div {
    width: calc(50% - 8px); }
    .form-add-company-review .block-working-hours > div input {
      width: 100%;
      background: transparent; }
    .form-add-company-review .block-working-hours > div input::placeholder {
      color: #D80614; }

.form-add-company-review .block-do-you-advise {
  padding: 25px 0 0;
  max-width: 600px;
  width: 100%;
  margin-bottom: 50px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between; }
  .form-add-company-review .block-do-you-advise > div {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center; }
  .form-add-company-review .block-do-you-advise button {
    background-color: #F0F0F0;
    border-radius: 3px;
    border: none;
    margin: 0 5px;
    width: 67px; }
  .form-add-company-review .block-do-you-advise p {
    margin-bottom: 0; }

.form-add-company-review input[type=checkbox] {
  transform: scale(1.5); }

.form-add-company-review .form-check {
  margin-bottom: 40px; }
  .form-add-company-review .form-check .form-check-label {
    margin-left: 5px; }

.block-positive-negative-sides {
  margin-bottom: 20px; }
  .block-positive-negative-sides p {
    margin-bottom: 5px;
    color: #062336;
    padding: 10px 16px;
    width: 100%; }
  .block-positive-negative-sides textarea {
    resize: none;
    width: 100%;
    height: 160px;
    padding: 13px 16px;
    border-radius: 3px;
    border: 1px solid #D5D5D5; }
  .block-positive-negative-sides .green {
    background-color: #EBFEEE; }
  .block-positive-negative-sides .red {
    background-color: #FEECEB; }
  .block-positive-negative-sides label {
    color: #fff !important;
    font-weight: 400;
    margin-bottom: 0; }

.block-file-field .added-img {
  width: calc(16.6% - 12.5px);
  margin: 7px 15px 7px 0;
  display: inline-block;
  position: relative; }
  .block-file-field .added-img img {
    width: 100%;
    height: auto;
    max-height: 86px; }
  .block-file-field .added-img button {
    border: none;
    width: 22px;
    height: 18px;
    background: #5E5E5E;
    color: #fff;
    position: absolute;
    right: 0;
    top: 0; }

.block-file-field .img-list .added-img:nth-child(6n+6),
.block-file-field .video-list .added-img:nth-child(6n+6) {
  margin-right: 0; }

.block-file-field .file-field {
  margin-bottom: 50px; }

.block-file-field .button-wrapper {
  position: relative;
  width: 185px;
  text-align: center; }

.block-file-field .button-wrapper span.label {
  position: relative;
  z-index: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  width: 100%;
  background: #2581BC;
  cursor: pointer;
  color: #fff;
  padding: 10px 30px 10px 17px;
  text-transform: uppercase;
  font-size: 12px; }

.block-file-field .btn-add-files {
  display: inline-block;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 35px;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer; }

.block-btns-img-vid {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: flex-start;
  margin-bottom: 55px; }
  .block-btns-img-vid .button-wrapper {
    margin-right: 15px; }

@media only screen and (max-width: 767px) {
  .block-btns-img-vid {
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center; }
    .block-btns-img-vid .button-wrapper {
      margin-bottom: 10px; }
  .block-file-field .added-img {
    width: 28%; }
  .form-add-company-review .block-personal-information {
    background-color: #F8FAFC;
    border: 1px solid #EBF6FE; }
  .company-addreview-recommend > div .text-gray {
    margin-bottom: 10px; } }

.complex-advertising-block {
  max-width: 1010px;
  width: 95%;
  margin: auto; }
  .complex-advertising-block h2 {
    max-width: 675px;
    width: 80%;
    margin: 0 auto 75px;
    text-align: center; }

.complex-advertising-list {
  padding: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  .complex-advertising-list li {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start;
    margin-bottom: 60px;
    width: 50%;
    padding: 0 80px 0 0; }
    .complex-advertising-list li > div:nth-last-child(1) {
      width: calc(100% - 92px); }
  .complex-advertising-list li:nth-child(2n + 2) {
    padding: 0 0 0 80px; }

.complex-advertising-img {
  width: auto;
  min-width: 45px;
  max-width: 57px;
  margin-right: 35px; }

.complex-advertising-title {
  font-size: 16px;
  color: #419DE4;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 10px; }

.complex-advertising-description {
  margin-bottom: 0; }

@media only screen and (max-width: 1199px) {
  .complex-advertising-list li {
    margin-bottom: 40px;
    padding: 0 40px 0 0; }
  .complex-advertising-list li:nth-child(2n + 2) {
    padding: 0 0 0 40px; } }

@media only screen and (max-width: 991px) {
  .complex-advertising-list li {
    padding: 0 20px 0 0; }
  .complex-advertising-list li:nth-child(2n + 2) {
    padding: 0 0 0 20px; } }

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .complex-advertising-list {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
    .complex-advertising-list li {
      width: 100%;
      padding: 0; }
    .complex-advertising-list li:nth-child(2n + 2) {
      padding: 0; } }

.block-job-more-info {
  background-color: #FBFAFA;
  text-align: center;
  padding: 60px 0 55px; }
  .block-job-more-info h2 {
    font-weight: 500;
    text-align: center;
    color: #419DE4;
    margin-bottom: 40px; }
  .block-job-more-info a {
    border: 1px solid #14669B;
    border-radius: 3px;
    padding: 7px 25px; }
    .block-job-more-info a:hover {
      text-decoration: none; }

.block-already-advertise {
  padding: 55px 0 0; }
  .block-already-advertise h2 {
    text-align: center;
    margin-bottom: 30px; }
  .block-already-advertise ul {
    margin-bottom: 0;
    padding: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .block-already-advertise li {
    list-style-type: none;
    width: 12.5%;
    padding: 0 20px;
    margin-bottom: 10px;
    text-align: center; }
    .block-already-advertise li img {
      width: auto;
      max-width: 100%;
      height: auto;
      margin: auto; }

@media only screen and (max-width: 1199px) {
  .block-already-advertise h2 {
    margin-bottom: 15px; }
  .block-already-advertise ul {
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center; }
  .block-already-advertise li {
    width: 20%;
    padding: 0 10px; } }

@media only screen and (max-width: 767px) {
  .block-already-advertise {
    padding: 30px 0 0; } }

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .block-already-advertise li {
    width: 33%;
    padding: 0 5px; } }

.block-employers-banner .container {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 30px;
  padding-bottom: 30px; }

.block-employers-banner .img-wrap {
  width: 18%;
  max-width: 211px;
  margin-right: 13%; }

.block-employers-banner h2 {
  width: 47%;
  font-size: 33px;
  color: #419DE4;
  text-align: center; }
  .block-employers-banner h2 span {
    font-weight: 500; }

@media only screen and (max-width: 1199px) {
  .block-employers-banner .img-wrap {
    width: 20%;
    margin-right: 10%; }
  .block-employers-banner h2 {
    font-size: 20px; } }

@media only screen and (max-width: 767px) {
  .block-employers-banner .img-wrap {
    width: 25%; }
  .block-employers-banner h2 {
    font-size: 18px; }
  .block-employers-banner .img-wrap {
    width: 45%;
    margin-right: 0; } }

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .block-employers-banner .container {
    padding-top: 55px;
    padding-bottom: 55px;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
  .block-employers-banner h2 {
    width: 100%; } }

.block-questions {
  padding: 70px 0 0;
  text-align: center; }
  .block-questions h2 {
    font-size: 25px;
    font-weight: 500;
    margin-bottom: 16px; }
  .block-questions .open-form {
    margin-bottom: 30px;
    font-size: 20px;
    cursor: pointer; }
    .block-questions .open-form .fas {
      margin-left: 35px; }
  .block-questions-form {
    width: 100%;
    max-width: 610px;
    margin: auto; }
    .block-questions-form .form-group {
      text-align: left;
      margin-bottom: 20px; }
    .block-questions-form sup {
      color: #FF0000; }
    .block-questions-form textarea {
      resize: none;
      margin-bottom: 45px; }
    .block-questions-form button {
      min-width: 143px;
      margin: 0 10px;
      border-radius: 3px; }

.btn-blue {
  background-color: #14669B;
  border-color: #14669B;
  color: #fff; }
  .btn-blue:hover {
    background-color: #fff;
    color: #14669B; }

.btn-blue-outl {
  border-color: #14669B;
  background-color: #fff;
  color: #14669B; }
  .btn-blue-outl:hover {
    background-color: #14669B;
    color: #fff; }

@media only screen and (max-width: 992px) {
  .block-questions {
    padding: 50px 0 0; } }

@media only screen and (max-width: 767px) {
  .block-questions h2 {
    font-size: 20px; }
  .block-questions .open-form {
    margin-bottom: 15px;
    font-size: 13px; }
    .block-questions .open-form .fas {
      margin-left: 10px; } }

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .block-questions h2 {
    font-size: 17px;
    margin-bottom: 10px; }
  .block-questions .open-form {
    margin-bottom: 15px;
    font-size: 9px; }
    .block-questions .open-form .fas {
      margin-left: 7px; }
  .block-questions-form button {
    width: 100%;
    margin: 0 0 10px; }
  .block-questions-form .btn-blue-outl {
    margin: 0; }
  .block-questions-form .btn-group {
    width: 100%;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; } }

.app-content-wrap {
  padding: 95px 0 100px;
  min-height: calc(100vh - 380px); }

@media only screen and (max-width: 991px) {
  .app-content-wrap {
    padding: 51px 0 100px;
    min-height: calc(100vh - 800px); } }

@media only screen and (max-width: 767px) {
  .app-content-wrap {
    padding: 51px 0 50px; } }

.popular-professions-list {
  padding: 0 15px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  .popular-professions-list li {
    width: 33%;
    padding-right: 30px; }
  .popular-professions-list li:nth-child(3n+3) {
    padding-right: 0; }

.popular-professions-block-title {
  margin-bottom: 20px; }

@media (max-width: 991px) {
  .popular-professions-list li {
    width: 33%;
    padding-right: 15px; } }

.popular-professions-block {
  padding-top: 35px;
  padding-bottom: 9px; }
  .popular-professions-block .popular-professions-list {
    width: 100%; }

@media (max-width: 767px) {
  .popular-professions-list li {
    width: 50%; }
  .popular-professions-list li:nth-child(3n+3) {
    padding-right: 15px; }
  .popular-professions-list li:nth-child(2n+2) {
    padding-right: 0; }
  .popular-professions-block {
    padding-top: 30px; }
    .popular-professions-block-title {
      margin-bottom: 18px;
      font-size: 18px; }
    .popular-professions-block .popular-professions-list {
      width: 100%; } }

@media (max-width: 500px) {
  .popular-professions-list li {
    width: 100%;
    padding-right: 0; } }

.popular-countries-list {
  padding: 0 15px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  min-width: 50%;
  width: 100%; }
  .popular-countries-list li {
    width: 25%; }
  .popular-countries-list .flag-icon {
    height: 14px;
    width: 19px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    margin-right: 3px;
    background-size: cover; }

.popular-countries-block {
  padding-top: 35px; }
  .popular-countries-block-title {
    margin-bottom: 20px; }

@media (max-width: 991px) {
  .popular-countries-list {
    -webkit-justify-content: right;
    -moz-justify-content: right;
    -ms-justify-content: right;
    justify-content: right;
    -ms-flex-pack: right; }
    .popular-countries-list li {
      width: calc(33.3% - 5px); } }

@media (max-width: 767px) {
  .popular-countries-block {
    padding-bottom: 0;
    padding-top: 20px; }
    .popular-countries-block-title {
      margin-bottom: 18px;
      font-size: 18px; }
    .popular-countries-block .popular-countries-list {
      margin-bottom: 0;
      -webkit-justify-content: space-between;
      -moz-justify-content: space-between;
      -ms-justify-content: space-between;
      justify-content: space-between;
      -ms-flex-pack: space-between; }
      .popular-countries-block .popular-countries-list li {
        width: calc(50% - 5px); } }

.callout {
  padding: 1.25rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  border-left: 1px solid #eee;
  border-left-width: .25rem; }
  .callout-warning {
    border-left-color: #ffdb49; }
  .callout-success {
    border-left-color: #00B955; }
  .callout-info {
    border-left-color: #14669b; }
  .callout-danger {
    border-left-color: #DC3545; }

.block-site-video-links {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between; }

.block-sitelinks {
  width: calc(50% - 10px); }
  .block-sitelinks p {
    cursor: pointer;
    margin-bottom: 0;
    text-decoration: underline;
    text-decoration-style: dotted; }
  .block-sitelinks i {
    margin-right: 10px;
    color: #062336; }
  .block-sitelinks .form-group {
    display: none; }
  .block-sitelinks.add-link .form-group {
    display: block; }

.drop-down-box-content {
  display: none;
  margin-top: 16px; }

.icon-info-center {
  border-radius: 50%;
  border: 1px solid #008BEB;
  background-color: #fff;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  width: 17px;
  height: 17px;
  margin-left: 5px; }
  .icon-info-center span {
    font-size: 10px;
    font-weight: 500;
    color: #008BEB; }

.type-employer-list {
  padding: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  margin-bottom: 0; }
  .type-employer-list li {
    width: 50%; }
    .type-employer-list li > p {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      -webkit-justify-content: flex-start;
      -moz-justify-content: flex-start;
      -ms-justify-content: flex-start;
      justify-content: flex-start;
      -ms-flex-pack: flex-start;
      margin-bottom: 0;
      cursor: pointer; }
    .type-employer-list li label {
      margin-left: 11px;
      font-weight: 400; }

.type-employer-icon {
  margin-right: 13px;
  width: 20px;
  height: 20px;
  border: 1px solid #D5D5D5;
  background-color: #F0F0F0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  border-radius: 50%; }
  .type-employer-icon span {
    background-color: #008BEB;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    display: none; }

.type-employer-active .type-employer-icon span {
  display: inline-block; }

@media only screen and (max-width: 991px) {
  .new-job-add-filter {
    width: 245px; }
  .type-employer-list li {
    width: 40%; }
  .block-site-video-links .block-sitelinks {
    width: 100%; }
    .block-site-video-links .block-sitelinks:nth-child(1) {
      margin-bottom: 1rem; } }

@media only screen and (max-width: 767px) {
  .new-job-add-filter {
    margin-bottom: 15px;
    width: 100%; }
  .type-employer-list {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
    .type-employer-list li {
      width: 100%;
      margin-bottom: 1rem; }
      .type-employer-list li:nth-last-child(1) {
        margin-bottom: 0; } }

.rate-card-list {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  padding: 20px 0; }

.rate-card {
  border: .5px solid #E2E2E2;
  text-align: center;
  width: 100%;
  max-width: 345px;
  margin: 0 15px; }
  .rate-card:hover {
    -webkit-box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.12);
    border-color: transparent;
    transition: box-shadow .3s ease,border .2s linear,opacity .3s ease; }
  .rate-card-small {
    max-width: 281px; }
    .rate-card-small .main-text {
      margin-bottom: 45px; }
    .rate-card-small .rate-card-name {
      font-size: 25px; }
    .rate-card-small .rate-card-header {
      margin-bottom: 40px; }
  .rate-card .main-text {
    font-size: 12px;
    line-height: 18px;
    color: #14669B; }
  .rate-card-header {
    padding: 20px 0 6px;
    background: url(/images/rate-card-bg.png) no-repeat;
    background-size: 100% 100%; }
  .rate-card-body {
    padding: 0 30px 30px; }
    .rate-card-body label.btn {
      display: inline; }
  .rate-card-name {
    color: #fff;
    font-size: 30px;
    font-weight: 500; }
  .rate-card-price {
    width: 127px;
    height: 127px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    background-color: #fff;
    border: 1px solid #E2E2E2;
    border-radius: 50%;
    margin: auto; }
    .rate-card-price p {
      margin: 0;
      font-size: 40px;
      color: #039FCF; }
    .rate-card-price span {
      font-weight: bold; }
  .rate-card .pro-advantages {
    padding: 0 0 0 15px;
    margin-bottom: 45px; }
    .rate-card .pro-advantages li {
      text-align: left;
      list-style-type: disc;
      padding-left: 10px;
      color: #14669B; }
  .rate-card label {
    text-align: left;
    width: 100%; }

.active-tariff {
  -webkit-box-shadow: 7px 8px 5px 0px rgba(0, 0, 0, 0.17);
  -moz-box-shadow: 7px 8px 5px 0px rgba(0, 0, 0, 0.17);
  box-shadow: 7px 8px 5px 0px rgba(0, 0, 0, 0.17);
  border-color: #008BEB;
  transition: box-shadow .3s ease,border .2s linear,opacity .3s ease; }
  .active-tariff .btn-primary {
    background-color: #0074c5;
    border-color: #006db8;
    box-shadow: none; }
  .active-tariff:hover {
    -webkit-box-shadow: 7px 8px 5px 0px rgba(0, 0, 0, 0.17);
    -moz-box-shadow: 7px 8px 5px 0px rgba(0, 0, 0, 0.17);
    box-shadow: 7px 8px 5px 0px rgba(0, 0, 0, 0.17);
    border-color: #008BEB;
    transition: box-shadow .3s ease,border .2s linear,opacity .3s ease; }

@media only screen and (max-width: 1199px) {
  .rate-card-list {
    padding: 10px 0; }
  .rate-card-body {
    padding: 0 15px 20px; }
  .rate-card-price {
    width: 100px;
    height: 100px; }
    .rate-card-price p {
      font-size: 30px; }
  .rate-card-name {
    font-size: 25px; }
  .rate-card-small .rate-card-name {
    font-size: 20px; }
  .rate-card-small .rate-card-header {
    margin-bottom: 25px; }
  .rate-card-small .main-text {
    margin-bottom: 20px; }
  .rate-card .pro-advantages {
    padding: 0 0 0 15px;
    margin-bottom: 20px; }
    .rate-card .pro-advantages li {
      padding-left: 0; }
  .rate-card .main-text {
    margin-bottom: 10px; }
  .rate-card .form-control {
    padding: 0.375rem 0.75rem 0.375rem 0.3rem;
    font-size: 10px; } }

.ql-editor {
  min-height: 200px; }

.autocomplete {
  /*the container must be positioned relative:*/
  position: relative; }
  .autocomplete .autocomplete-items {
    position: absolute;
    border: 1px solid #d4d4d4;
    border-bottom: none;
    border-top: none;
    z-index: 99;
    /*position the autocomplete items to be the same width as the container:*/
    max-height: 15rem;
    overflow: auto; }
  .autocomplete .autocomplete-items div {
    padding: 10px;
    cursor: pointer;
    background-color: #fff;
    border-bottom: 1px solid #d4d4d4; }
  .autocomplete .autocomplete-items div:hover {
    /*when hovering an item:*/
    background-color: #e9e9e9; }
  .autocomplete .autocomplete-active {
    /*when navigating through the items using the arrow keys:*/
    background-color: DodgerBlue !important;
    color: #fff; }
  .autocomplete .fa-spin {
    position: absolute;
    right: 5%;
    top: 32%; }

.vacancies-filter .autocomplete .fa-spin {
  top: 60%; }

.offer-manage-form .autocomplete .fa-spin {
  top: 85%; }

.scroll-to-top {
  display: none;
  position: fixed;
  bottom: 45px;
  left: 1rem;
  z-index: 99;
  border: none;
  outline: none;
  cursor: pointer; }

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .broadcast-message-container .broadcast-message-text {
    max-width: 50ch;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; } }

.popular-vacancies {
  padding-top: 35px; }
  .popular-vacancies .row {
    margin-bottom: 8px; }
  .popular-vacancies-title {
    margin-bottom: 20px; }

@media only screen and (max-width: 767px) {
  .popular-vacancies {
    padding-bottom: 20px;
    padding-top: 30px; }
    .popular-vacancies .location,
    .popular-vacancies .card-text.text-muted {
      display: none; }
    .popular-vacancies-title {
      margin-bottom: 18px;
      font-size: 18px; }
      .popular-vacancies-title-color {
        color: #062336; }
    .popular-vacancies .font-weight-bolder {
      text-decoration: underline; }
      .popular-vacancies .font-weight-bolder:hover {
        text-decoration: none; } }

.notification-block-header {
  position: relative; }
  .notification-block-header .fa-bell {
    color: #fff;
    font-size: 18px;
    cursor: pointer;
    line-height: 26px;
    vertical-align: middle; }
  .notification-block-header .notifications-icon {
    display: none;
    background-color: #EFD031;
    width: 7px;
    height: 7px;
    position: absolute;
    right: -2px;
    top: 0; }
  .notification-block-header.there-are-notifications .notifications-icon {
    display: inline-block; }
  .notification-block-header .notification-block-list {
    position: absolute;
    top: 100%;
    right: -6px;
    border: 1px solid #e0e0e0;
    background-color: #fff;
    width: 414px;
    z-index: 2;
    max-height: calc(100vh - 52px);
    overflow-y: scroll; }
    .notification-block-header .notification-block-list ul {
      padding: 0 9px;
      /*.fa-exclamation-triangle {
				color: #DC3545;
				font-size: 23px;
			}
			.fa-question-circle {
				color: #008AEA;
				font-size: 27px;
			}
			.fa-check {
				color: #00B955;
				font-size: 24px;
			}*/ }
      .notification-block-header .notification-block-list ul li {
        border-bottom: 1px solid #e0e0e0;
        padding: 14px 45px 14px 40px;
        position: relative; }
        .notification-block-header .notification-block-list ul li p {
          text-align: left;
          margin-bottom: 0;
          line-height: 18px; }
          .notification-block-header .notification-block-list ul li p a {
            color: #008AEA; }
            .notification-block-header .notification-block-list ul li p a:hover {
              text-decoration: underline; }
      .notification-block-header .notification-block-list ul .fa-times {
        position: absolute;
        right: 0;
        top: 50%;
        -webkit-transform: translate(0, -50%);
        -moz-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
        color: #ADB5BD;
        font-size: 14px;
        cursor: pointer; }
      .notification-block-header .notification-block-list ul .info-icon {
        position: absolute;
        left: 0;
        top: 50%;
        -webkit-transform: translate(0, -50%);
        -moz-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%); }
      .notification-block-header .notification-block-list ul .notification-type-icon {
        font-size: 25px; }
  .notification-block-header .notification-block-list::-webkit-scrollbar {
    width: 0; }
  .notification-block-header .notification-block-list {
    -ms-overflow-style: none; }
  .notification-block-header .btn-close-all {
    text-align: center;
    padding: 10px 0; }
    .notification-block-header .btn-close-all a {
      color: #008AEA; }
      .notification-block-header .btn-close-all a:hover {
        text-decoration: underline; }
  .notification-block-header .no-notification-text {
    margin-bottom: 0;
    padding: 10px 13px;
    text-align: left;
    display: none; }
  .notification-block-header .no-notification > ul,
  .notification-block-header .no-notification .btn-close-all {
    display: none; }
  .notification-block-header .no-notification .no-notification-text {
    display: inline-block; }

@media only screen and (max-width: 991px) {
  .notification-block-header .notification-block-list ul {
    border-bottom: 1px solid #e0e0e0; }
    .notification-block-header .notification-block-list ul li:nth-last-child(1) {
      border-bottom: none; } }

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .notification-block-header {
    margin-right: 0; }
    .notification-block-header .notification-block-list {
      width: calc(100vw - 60px); }
      .notification-block-header .notification-block-list ul li {
        padding: 19px 25px 19px 39px; }
      .notification-block-header .notification-block-list ul .info-icon {
        left: 0; } }

@media only screen and (max-width: 767px) and (orientation: landscape) {
  .notification-block-header .notification-block-list {
    width: 540px;
    right: -33px; } }

@media only screen and (max-width: 576px) and (orientation: landscape) {
  .notification-block-header .notification-block-list {
    width: calc(100vw - 9px);
    right: -32px; } }

@media only screen and (max-height: 500px) and (orientation: landscape) {
  .notification-block-header {
    margin-top: -7px; } }

.block-tariff-information {
  background-color: #2B73B4;
  width: 100%;
  padding: 5px 25px; }
  .block-tariff-information p {
    margin-bottom: 0;
    text-align: left;
    color: #fff; }
  .block-tariff-information-link:hover {
    text-decoration: none; }
  .block-tariff-information-mob {
    padding: 8px 15px;
    background-color: #FFF77D; }
    .block-tariff-information-mob p {
      color: #062336;
      font-size: 12px; }

@media only screen and (max-width: 767px) and (orientation: landscape) {
  .block-tariff-information-mob {
    padding: 8px 14px; } }

.block-promo-banner {
  position: fixed;
  bottom: 45px;
  right: 55px;
  width: 375px;
  -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  background-color: #fff;
  z-index: 1;
  display: none; }
  .block-promo-banner .icon-close-banner {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 12px;
    color: #ADB5BD;
    cursor: pointer; }
  .block-promo-banner .container {
    padding-left: 0; }
  .block-promo-banner-title {
    font-size: 15px;
    color: #008AEA;
    margin-bottom: 15px;
    text-align: left; }
  .block-promo-banner-text {
    margin-bottom: 0; }
  .block-promo-banner p {
    margin-bottom: 0; }
  .block-promo-banner-mob {
    padding: 25px 5px 37px 20px;
    position: relative; }
    .block-promo-banner-mob .promo-banner-img {
      position: absolute;
      width: 100%;
      height: auto;
      right: 0;
      bottom: 0; }
    .block-promo-banner-mob .btn-outline-primary {
      margin-top: 20px;
      z-index: 1;
      position: relative; }
  .block-promo-banner.show-banner {
    display: block;
    animation: block-promo-banner-animation 3s; }

@keyframes block-promo-banner-animation {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@media only screen and (max-width: 1024px) {
  .block-promo-banner {
    position: relative;
    width: 100%;
    bottom: 0;
    right: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    display: block; }
    .block-promo-banner.show-banner {
      animation: none; }
    .block-promo-banner .icon-close-banner {
      display: none; }
    .block-promo-banner-mob {
      padding: 10px 0 35px; }
      .block-promo-banner-mob .promo-banner-img {
        width: 45%; }
    .block-promo-banner .container {
      padding-left: 15px; } }

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .block-promo-banner-mob .promo-banner-img {
    width: 100%; } }

@media only screen and (max-width: 330px) and (orientation: portrait) {
  .block-promo-banner-mob {
    padding-bottom: 45px; } }

@media only screen and (max-width: 1024px) and (orientation: landscape) {
  .block-promo-banner-title {
    margin-bottom: 20px; }
  .block-promo-banner-mob {
    padding-bottom: 20px; }
    .block-promo-banner-mob .promo-banner-img {
      width: auto;
      height: 123px; } }

@media only screen and (max-width: 660px) and (orientation: landscape) {
  .block-promo-banner-mob .promo-banner-img {
    height: 99px; } }

@media only screen and (max-width: 767px) {
  .container {
    padding-right: 10px;
    padding-left: 10px;
    width: calc(100% - 10px); } }

.block-posts {
  padding: 20px 0 100px; }
  .block-posts .container {
    position: relative; }
  .block-posts h2 {
    margin-bottom: 20px; }
  .block-posts .posts-list {
    margin-bottom: 0;
    padding-left: 15px;
    width: 80%; }
    .block-posts .posts-list li {
      list-style-type: disc; }
      .block-posts .posts-list li a:hover {
        text-decoration: none; }
      .block-posts .posts-list li:nth-last-child(1) {
        margin-bottom: 0; }
    .block-posts .posts-list .post-title {
      margin-bottom: 0; }
  .block-posts .show-all {
    position: absolute;
    right: 15px;
    bottom: 0; }

@media only screen and (max-width: 767px) {
  .block-posts {
    padding: 30px 0 40px; }
    .block-posts h2 {
      margin-bottom: 18px;
      font-size: 18px; }
    .block-posts .posts-list {
      margin-bottom: 20px;
      width: 100%; }
    .block-posts .show-all {
      position: relative;
      float: right;
      text-decoration: underline; } }

.field-password-wrap .input-group input {
  width: calc(100% - 36px) !important;
  border: none;
  height: calc(1.5em + 0.75rem - 2px);
  margin-top: 1px;
  margin-bottom: 1px;
  padding-left: .75rem;
  padding-right: .75rem; }
  .field-password-wrap .input-group input:focus {
    color: #495057;
    background-color: #fff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 139, 235, 0.25); }

.field-password-wrap .input-group .input-group-append .btn {
  background: none;
  padding: 0;
  width: 36px;
  border: none;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  .field-password-wrap .input-group .input-group-append .btn .fa-eye {
    font-size: 11px; }

.field-password-wrap.is-invalid-block label {
  color: #E63535 !important; }

.form-reg-field {
  border: 1px solid #e0e0e0 !important; }
  .form-reg-field.is-invalid {
    border-color: #E63535 !important;
    background-image: none; }

.reg-field-border .form-control:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 139, 235, 0.25); }

.auth-page-mob {
  padding-top: 40px; }
  .auth-page-mob .invalid-feedback {
    font-size: 14px; }

.registration-page {
  padding-top: 40px;
  width: 575px;
  margin: auto; }
  .registration-page .reg-title-mob {
    font-size: 25px;
    line-height: 192%;
    margin-bottom: 10px;
    text-align: center; }
  .registration-page .registration-sub-title {
    font-size: 14px;
    line-height: 143%; }
  .registration-page .manage-password,
  .registration-page .reg-field-border {
    margin-bottom: 10px; }
    .registration-page .manage-password label,
    .registration-page .reg-field-border label {
      margin-bottom: 10px; }
  .registration-page .registration-form-btn {
    margin: 15px 0 20px; }
  .registration-page .already-registered-text {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 20px; }
  .registration-page .social-auth {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
    margin-top: 10px; }
  .registration-page .btn-sm, .registration-page .btn-group-sm > .btn {
    padding-left: 30px;
    padding-right: 30px;
    margin-left: 20px; }
  .registration-page .form-control__desc {
    font-size: 13px;
    line-height: 154%;
    color: #6C757D;
    margin-bottom: 0; }
  .registration-page .form-group__first {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: baseline;
    -moz-align-items: baseline;
    -ms-align-items: baseline;
    align-items: baseline;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between; }
    .registration-page .form-group__first .reg-field-border:nth-child(1) {
      width: 61%; }
    .registration-page .form-group__first .reg-field-border:nth-child(2) {
      width: 35%; }
  .registration-page .material-icons-outlined {
    font-size: 16px;
    color: #008BEB;
    vertical-align: sub;
    cursor: pointer; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  background-image: none; }

.is-invalid-block label {
  color: #C82333 !important; }

.tooltip-inner {
  padding: 10px 20px;
  background: #fff;
  font-size: 14px;
  line-height: 143%;
  color: #6C757D;
  text-align: left;
  border: 0.5px solid #E0E0E0;
  box-sizing: border-box;
  box-shadow: 0 4px 20px rgba(125, 125, 125, 0.25); }

.tooltip.bs-tooltip-auto[x-placement^=top] .arrow::before, .tooltip.bs-tooltip-top .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  margin-left: -3px;
  content: "";
  border-width: 5px 5px 0;
  border-top-color: #fff;
  box-shadow: 0 4px 20px rgba(125, 125, 125, 0.25); }

@media only screen and (max-width: 767px) {
  .auth-title-mob {
    font-size: 18px;
    margin: 0 0 18px !important; }
  .reg-title-mob {
    font-size: 18px; }
  .registration-page {
    padding-top: 20px;
    width: 100%; }
    .registration-page .reg-title-mob {
      font-size: 18px;
      line-height: 48px;
      margin-bottom: 5px; }
    .registration-page .form-control__desc {
      margin-top: 5px; }
    .registration-page .form-group__first {
      -webkit-flex-direction: column;
      -moz-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column; }
      .registration-page .form-group__first .reg-field-border:nth-child(1), .registration-page .form-group__first .reg-field-border:nth-child(2) {
        width: 100%; }
    .registration-page .registration-form-btn {
      margin: 10px 0 20px; }
    .registration-page .social-auth {
      -webkit-flex-direction: column;
      -moz-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center; }
      .registration-page .social-auth > div {
        width: 100%;
        text-align: center; }
    .registration-page .btn-sm, .registration-page .btn-group-sm > .btn {
      margin: 20px 20px 0; } }

.guideline-colors-table {
  width: 919px; }
  .guideline-colors-table thead {
    background-color: #F7F7F7; }
    .guideline-colors-table thead th {
      border: none;
      color: #868686;
      padding: 5px 0;
      font-weight: normal; }
  .guideline-colors-table tr th:nth-child(1) {
    width: 455px; }
  .guideline-colors-table tr th:nth-child(2) {
    width: 80px; }
  .guideline-colors-table tr th:nth-child(3) {
    width: 135px; }
  .guideline-colors-table tr th:nth-child(4) {
    width: 245px; }
  .guideline-colors-table td {
    padding: 20px 0 0;
    border: none; }
  .guideline-colors-table .color-block {
    width: 25px;
    height: 25px;
    cursor: pointer; }
    .guideline-colors-table .color-block p {
      display: none; }
    .guideline-colors-table .color-block.is-copied {
      position: relative; }
      .guideline-colors-table .color-block.is-copied p {
        position: absolute;
        left: 50%;
        top: -15px;
        -webkit-transform: translate(-50%, 0);
        -moz-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
        font-size: 10px;
        margin-bottom: 0;
        display: inline-block; }

@media only screen and (max-width: 1439px) {
  .guideline-colors-table tr th:nth-child(4) {
    width: 180px; } }

@media only screen and (max-width: 991px) {
  .guideline-colors-table {
    width: 704px; }
    .guideline-colors-table tr th:nth-child(1) {
      width: 370px; }
    .guideline-colors-table tr th:nth-child(2) {
      width: 50px; }
    .guideline-colors-table tr th:nth-child(3) {
      width: 75px; }
    .guideline-colors-table tr th:nth-child(4) {
      width: 209px; } }

.block-response-vacancy .table-response-replies .table-response-vacancy-row {
  position: relative; }

.block-response-vacancy .table-response-replies .col1 {
  width: 20%; }

.block-response-vacancy .table-response-replies .col2 {
  width: 18%; }

.block-response-vacancy .table-response-replies .col3 {
  width: 19%; }

.block-response-vacancy .table-response-replies .col4 {
  width: 38%; }

.block-response-vacancy .table-response-replies .col5 {
  width: 5%;
  padding: 0 10px; }

.block-response-vacancy .table-response-replies .table-response-vacancy-header p {
  color: #062336; }

@media only screen and (max-width: 768px) {
  .block-response-vacancy .table-response-replies .col1,
  .block-response-vacancy .table-response-replies .col2,
  .block-response-vacancy .table-response-replies .col3,
  .block-response-vacancy .table-response-replies .col4 {
    width: 88%;
    text-align: left;
    padding: 0 15px;
    margin: 0; }
  .block-response-vacancy .table-response-replies .col5 {
    width: auto;
    position: absolute;
    right: 0;
    top: 0;
    padding: 0 7px; }
  .block-response-vacancy .table-response-replies .col3 {
    order: 1; }
  .block-response-vacancy .table-response-replies .col1 {
    order: 2;
    margin: 5px 0; }
  .block-response-vacancy .table-response-replies .col4 {
    order: 3; } }

.block-custome-input .custome-input:checked,
.block-custome-input .custome-input:not(:checked) {
  position: absolute;
  left: -9999px; }

.block-custome-input .custome-label {
  width: 100%;
  position: relative; }

.block-custome-input .custome-input:checked label,
.block-custome-input .custome-input:not(:checked) + label {
  padding-right: 21px;
  cursor: pointer;
  display: inline-block; }

.block-custome-input .custome-input:checked + label:before,
.block-custome-input .custome-input:not(:checked) + label:before {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: #F0F0F0;
  border: 1px solid #D5D5D5; }

.block-custome-input .custome-input:checked + label:after,
.block-custome-input .custome-input:not(:checked) + label:after {
  content: '';
  width: 12px;
  height: 12px;
  background: #008BEB;
  position: absolute;
  top: 4px;
  right: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease; }

.block-custome-input .custome-input:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0); }

.block-custome-input .custome-input:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1); }

.new-job-add-sitebar {
  width: 285px;
  padding: 0;
  position: sticky;
  right: 0;
  top: 162px; }
  .new-job-add-sitebar li {
    width: 100%;
    background-color: #F3FAFF;
    margin-bottom: 10px;
    position: relative; }
    .new-job-add-sitebar li.active-item:before {
      content: '';
      width: 5px;
      height: 100%;
      background-color: #BDE4FF;
      position: absolute;
      left: 0;
      top: 0; }
    .new-job-add-sitebar li:hover:before {
      content: '';
      width: 5px;
      height: 100%;
      background-color: #BDE4FF;
      position: absolute;
      left: 0;
      top: 0; }
    .new-job-add-sitebar li div {
      padding: 10px 20px 10px;
      width: 100%; }
    .new-job-add-sitebar li p {
      margin-bottom: 0;
      line-height: 17px; }
    .new-job-add-sitebar li a:hover {
      text-decoration: none; }
  .new-job-add-sitebar .video-block {
    border: 1px solid #008BEB;
    background-color: #fff; }
    .new-job-add-sitebar .video-block div {
      padding: 10px 27px 10px 12px;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      -webkit-justify-content: space-between;
      -moz-justify-content: space-between;
      -ms-justify-content: space-between;
      justify-content: space-between;
      -ms-flex-pack: space-between; }
    .new-job-add-sitebar .video-block .fa-play-circle {
      font-size: 30px;
      margin-right: 10px; }
    .new-job-add-sitebar .video-block:hover:before {
      display: none; }

@media only screen and (max-width: 991px) {
  .new-job-add-sitebar {
    display: none; } }

.popular-queries {
  padding-top: 35px; }

.popular-queries-title {
  margin-bottom: 24px; }

@media (max-width: 767px) {
  .popular-queries {
    padding-top: 30px; }
    .popular-queries-title {
      margin-bottom: 18px;
      font-size: 18px; }
    .popular-queries .popular-vacancies-title-color {
      color: #008AEA; } }

.offers-banner {
  margin-bottom: 10px; }
  .offers-banner img {
    width: 100%;
    height: auto; }

.popular-companies-block {
  padding-top: 35px; }
  .popular-companies-block-title {
    margin-bottom: 20px; }

@media only screen and (max-width: 767px) {
  .popular-companies-block {
    padding-top: 22px; }
    .popular-companies-block-title {
      margin-bottom: 18px;
      font-size: 18px; } }

.home-employers-section {
  padding: 50px 0;
  position: relative;
  border-bottom: 1px solid #D7C253; }
  .home-employers-section:nth-child(1) {
    border-bottom: none; }
  .home-employers-section__bg {
    width: 44%;
    position: absolute;
    right: 5%;
    top: -92px;
    z-index: -1; }
  .home-employers-section__text {
    font-size: 15px;
    margin-bottom: 0;
    position: relative;
    z-index: 1;
    color: #6C757D; }
  .home-employers-section__first {
    margin-top: -95px;
    padding-top: 70px;
    position: relative;
    border-bottom: 1px solid #D7C253; }
    .home-employers-section__first-container {
      width: 60%; }
    .home-employers-section__first .home-employers-section__title {
      margin-bottom: 20px; }
  .home-employers-section__look-employees .container {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center; }
    .home-employers-section__look-employees .container > div:nth-child(1) {
      width: 56%;
      margin-right: 4%; }
    .home-employers-section__look-employees .container > div:nth-last-child(1) {
      width: 44%;
      position: relative;
      text-align: center; }
      .home-employers-section__look-employees .container > div:nth-last-child(1):before {
        content: '';
        width: 231px;
        height: 483px;
        background: radial-gradient(35% 43.08% at 50.01% 50.01%, rgba(255, 240, 230, 0.72) 16.59%, rgba(214, 255, 162, 0.3) 53.05%, rgba(255, 255, 255, 0) 100%);
        transform: rotate(90.83deg);
        position: absolute;
        left: 149px;
        top: -229px; }
    .home-employers-section__look-employees .container .btn-warning {
      color: #062336;
      background-color: #FFD84F;
      border-color: #FFD84F;
      position: relative; }
      .home-employers-section__look-employees .container .btn-warning:hover {
        color: #062336;
        background-color: #F3CD47;
        border-color: #F3CD47; }
  .home-employers-section__form {
    padding-bottom: 0;
    border-bottom: none; }
    .home-employers-section__form:before {
      display: none; }
    .home-employers-section__form .container {
      position: relative;
      background: #FBF9F3;
      padding-top: 40px;
      padding-bottom: 45px; }
      .home-employers-section__form .container:before {
        content: '';
        height: 100%;
        width: 25px;
        background: #FBF9F3;
        position: absolute;
        top: 0;
        left: -25px; }
      .home-employers-section__form .container:after {
        content: '';
        height: 100%;
        width: 25px;
        background: #FBF9F3;
        position: absolute;
        top: 0;
        right: -25px; }
    .home-employers-section__form .home-employers-section__title {
      width: 58%;
      margin-bottom: 34px; }
  .home-employers-section__trust-us {
    padding: 40px 0; }
    .home-employers-section__trust-us .container {
      position: relative;
      background: #FBF9F3;
      padding-top: 40px;
      padding-bottom: 85px; }
      .home-employers-section__trust-us .container:before {
        content: '';
        height: 100%;
        width: 25px;
        background: #FBF9F3;
        position: absolute;
        top: 0;
        left: -25px; }
      .home-employers-section__trust-us .container:after {
        content: '';
        height: 100%;
        width: 25px;
        background: #FBF9F3;
        position: absolute;
        top: 0;
        right: -25px; }
  .home-employers-section__title {
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 0;
    position: relative;
    z-index: 1; }
    .home-employers-section__title__big {
      font-size: 40px;
      font-weight: 500;
      margin-bottom: 30px; }
  .home-employers-section__sub-title {
    margin-bottom: 60px;
    color: #062336; }

.our-advantages {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  padding: 0;
  margin-bottom: 75px;
  width: 100%; }
  .our-advantages-item {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: baseline;
    -moz-align-items: baseline;
    -ms-align-items: baseline;
    align-items: baseline;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
    -ms-flex-pack: flex-start;
    width: 28%; }
    .our-advantages-item .fa-check {
      font-size: 14px;
      color: #02B538;
      margin-right: 11px; }
    .our-advantages-item__p {
      width: 87%;
      margin: 0; }

.why-workmarket-list {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: stretch;
  -moz-align-items: stretch;
  -ms-align-items: stretch;
  align-items: stretch;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 30px;
  position: relative; }
  .why-workmarket-list__bg {
    width: 75vw;
    position: absolute;
    left: 55%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: -1; }
    .why-workmarket-list__bg__mob {
      display: none; }
    .why-workmarket-list__bg img {
      width: 100%;
      height: auto; }

.why-workmarket-item {
  width: calc(50% - 10px);
  padding: 20px 30px;
  border: 0.5px solid #E0E0E0;
  margin-bottom: 20px;
  background-color: #fff;
  position: relative;
  z-index: 1; }
  .why-workmarket-item__img {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    width: 62px;
    height: 62px;
    background: url("/images/home-employers/img-wrap.png") center center no-repeat;
    background-size: contain;
    margin-bottom: 10px; }
    .why-workmarket-item__img .material-icons, .why-workmarket-item__img .material-icons-outlined, .why-workmarket-item__img .material-icons-round, .why-workmarket-item__img .material-icons-sharp, .why-workmarket-item__img .material-icons-two-tone,
    .why-workmarket-item__img .material-icons-outlined {
      font-size: 26px;
      color: #F4D69B; }
  .why-workmarket-item__title {
    font-weight: 500;
    font-size: 18px;
    line-height: 111%;
    margin-bottom: 10px;
    min-height: 41px; }
  .why-workmarket-item__subtitle {
    font-size: 15px;
    line-height: 167%;
    color: #6C757D;
    margin-bottom: 0; }

.our-services {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: stretch;
  -moz-align-items: stretch;
  -ms-align-items: stretch;
  align-items: stretch;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: flex-start;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 20px; }
  .our-services-item {
    width: 50%;
    padding: 25px 40px 50px 30px;
    border-bottom: 1px solid #D7C253; }
    .our-services-item:nth-child(2n+2) {
      border-left: 1px solid #D7C253; }
    .our-services-item:nth-last-child(1), .our-services-item:nth-last-child(2) {
      border-bottom: none; }
    .our-services-item__img {
      height: 111px;
      margin-bottom: 22px; }
    .our-services-item__title {
      font-weight: 500;
      font-size: 18px;
      margin-bottom: 15px; }
    .our-services-item__text {
      font-size: 15px;
      line-height: 167%;
      color: #6C757D;
      margin-bottom: 0; }

.form-n-desc {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: stretch;
  -moz-align-items: stretch;
  -ms-align-items: stretch;
  align-items: stretch;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  .form-n-desc > div {
    width: 50%; }
    .form-n-desc > div:nth-child(1) {
      border-right: 1px solid #D7C253;
      padding-right: 25px; }
    .form-n-desc > div:nth-child(2) {
      padding-left: 40px; }
  .form-n-desc .home-employers-section__text {
    margin-bottom: 20px;
    font-size: 14px; }

.form-desc {
  padding: 0; }
  .form-desc__item {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: baseline;
    -moz-align-items: baseline;
    -ms-align-items: baseline;
    align-items: baseline;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
    -ms-flex-pack: flex-start;
    margin-bottom: 10px; }
    .form-desc__item .fa-check {
      font-size: 14px;
      color: #02B538;
      margin-right: 23px; }
    .form-desc__item p {
      margin-bottom: 0;
      color: #6C757D; }

.home-employer-form__row {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: baseline;
  -moz-align-items: baseline;
  -ms-align-items: baseline;
  align-items: baseline;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  margin-bottom: 25px; }
  .home-employer-form__row .form-control {
    border: none;
    border-bottom: 1px solid #D7C253;
    width: 63%;
    height: 25px;
    background: none;
    padding-left: 0;
    cursor: pointer; }
    .home-employer-form__row .form-control:hover {
      border-color: #a28908; }
  .home-employer-form__row .form-reg-field {
    border: none !important;
    border-bottom: 1px solid #D7C253 !important;
    width: 63%;
    cursor: pointer; }
    .home-employer-form__row .form-reg-field:hover {
      border-color: #a28908 !important; }
  .home-employer-form__row .input-group input {
    height: 25px;
    padding-left: 0; }
    .home-employer-form__row .input-group input:focus {
      background: none;
      box-shadow: none; }
  .home-employer-form__row label {
    margin-bottom: 0;
    line-height: 1; }
  .home-employer-form__row .label-wrap {
    line-height: 1; }
  .home-employer-form__row .block-hint {
    width: 63%; }
    .home-employer-form__row .block-hint .form-control {
      width: 100%; }
    .home-employer-form__row .block-hint p {
      font-size: 12px;
      margin-bottom: 0; }

.home-employer-form-mob {
  display: none; }

.home-employer-form .btn-warning {
  color: #062336;
  background-color: #FFD84F;
  border-color: #FFD84F;
  position: relative;
  margin-top: 15px; }
  .home-employer-form .btn-warning:hover {
    color: #062336;
    background-color: #F3CD47;
    border-color: #F3CD47; }

.home-employer-form .btn:focus, .home-employer-form .btn.focus {
  box-shadow: none; }

@media only screen and (max-width: 1199px) {
  .home-employers-section {
    padding: 30px 0 47px; }
    .home-employers-section__first {
      padding-top: 30px; }
      .home-employers-section__first-container {
        width: 100%; }
      .home-employers-section__first .home-employers-section__title {
        margin-bottom: 10px; }
    .home-employers-section__look-employees {
      padding: 30px 0;
      position: relative; }
      .home-employers-section__look-employees .container {
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column; }
        .home-employers-section__look-employees .container > div:nth-child(1) {
          width: 100%;
          margin: 0 0 20px; }
        .home-employers-section__look-employees .container > div:nth-last-child(1) {
          width: 100%;
          text-align: right;
          position: static; }
          .home-employers-section__look-employees .container > div:nth-last-child(1):before {
            content: '';
            width: 483px;
            height: 208px;
            background: radial-gradient(35% 43.08% at 50.01% 50.01%, rgba(255, 240, 230, 0.72) 16.59%, rgba(214, 255, 162, 0.3) 53.05%, rgba(255, 255, 255, 0) 100%);
            transform: rotate(0);
            background-size: 90% 90%;
            left: auto;
            top: auto;
            right: 0;
            bottom: -69px;
            background-position-x: 281%;
            background-repeat: no-repeat; }
      .home-employers-section__look-employees .home-employers-section__title {
        margin-bottom: 10px; }
    .home-employers-section__form {
      padding: 30px 0 0; }
      .home-employers-section__form .container {
        padding-top: 35px;
        padding-bottom: 35px; }
        .home-employers-section__form .container:before {
          width: 20px;
          left: -20px; }
        .home-employers-section__form .container:after {
          width: 20px;
          right: -20px; }
      .home-employers-section__form .home-employers-section__title {
        width: 100%;
        margin-bottom: 30px;
        line-height: 146%; }
    .home-employers-section__bg {
      width: 47%;
      right: 0;
      top: -6px; }
    .home-employers-section__title {
      line-height: 167%;
      font-size: 24px; }
      .home-employers-section__title__big {
        width: 63%;
        font-size: 24px;
        line-height: 167%;
        margin-bottom: 10px; }
    .home-employers-section__text {
      font-size: 14px;
      line-height: 143%; }
    .home-employers-section__sub-title {
      width: 63%;
      margin-bottom: 20px; }
  .our-advantages {
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 48px;
    width: 292px; }
    .our-advantages-item {
      width: 100%;
      margin-bottom: 20px; }
      .our-advantages-item__p:nth-child(1) {
        width: 53%; }
      .our-advantages-item__p:nth-child(2) {
        width: 73%; }
      .our-advantages-item__p:nth-child(3) {
        width: 91%; }
  .why-workmarket-list {
    margin-top: 18px;
    margin-bottom: 12px; }
    .why-workmarket-list__bg {
      width: 57vw;
      z-index: -1; }
  .why-workmarket-item {
    padding: 14px;
    margin-bottom: 10px; }
    .why-workmarket-item__img {
      width: 31px;
      height: 31px;
      margin-bottom: 5px; }
      .why-workmarket-item__img .material-icons, .why-workmarket-item__img .material-icons-outlined, .why-workmarket-item__img .material-icons-round, .why-workmarket-item__img .material-icons-sharp, .why-workmarket-item__img .material-icons-two-tone,
      .why-workmarket-item__img .material-icons-outlined {
        font-size: 14px; }
    .why-workmarket-item__title {
      font-size: 15px;
      line-height: 133%;
      min-height: 58px;
      min-height: auto; }
    .why-workmarket-item__subtitle {
      font-size: 14px;
      line-height: 143%; }
  .our-services-item {
    padding: 25px 25px 40px; }
    .our-services-item__img {
      height: 70px;
      margin-bottom: 20px; }
    .our-services-item__title {
      font-size: 15px;
      line-height: 167%;
      margin-bottom: 10px; }
    .our-services-itemtext {
      line-height: 133%; }
  .form-n-desc {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
    .form-n-desc > div {
      width: 100%; }
      .form-n-desc > div:nth-child(1) {
        border-right: none;
        padding-right: 0; }
      .form-n-desc > div:nth-child(2) {
        padding: 0 6%; }
  .home-employer-form__row label {
    font-size: 13px;
    font-weight: 400; }
  .home-employer-form__row .block-hint,
  .home-employer-form__row .form-control,
  .home-employer-form__row .form-reg-field,
  .home-employer-form__row .form-control {
    width: 70%; } }

@media only screen and (max-width: 992px) {
  .home-employers-section__bg {
    width: 52%;
    top: 73px; }
  .home-employers-section__first {
    padding-top: 66px; }
  .why-workmarket-list__bg {
    width: 83vw; } }

@media only screen and (max-width: 767px) {
  .home-employers-section {
    padding: 30px 0 67px; }
    .home-employers-section__first {
      margin-top: -50px;
      padding-top: 0;
      padding-bottom: 105px; }
      .home-employers-section__first-container .home-employers-section__text {
        padding-right: 15px;
        padding-left: 15px;
        margin-bottom: 27px; }
      .home-employers-section__first .container {
        padding-right: 0;
        padding-left: 0;
        width: 100%;
        position: relative; }
      .home-employers-section__first .home-employers-section__title {
        margin-bottom: 15px;
        padding-right: 15px;
        padding-left: 15px; }
    .home-employers-section__form .container {
      padding-top: 16px;
      padding-bottom: 30px; }
      .home-employers-section__form .container:before, .home-employers-section__form .container:after {
        display: none; }
    .home-employers-section__look-employees {
      padding-top: 30px;
      padding-bottom: 30px; }
      .home-employers-section__look-employees .home-employers-section__title {
        line-height: 139%; }
      .home-employers-section__look-employees .container > div:nth-last-child(1) {
        text-align: center; }
    .home-employers-section__trust-us {
      padding: 30px 0; }
      .home-employers-section__trust-us .container {
        padding: 20px 0 93px;
        width: 100%; }
        .home-employers-section__trust-us .container:before, .home-employers-section__trust-us .container:after {
          display: none; }
      .home-employers-section__trust-us .home-employers-section__title {
        line-height: 139%;
        padding: 0 15px; }
    .home-employers-section__bg {
      width: 100vw;
      position: relative;
      top: 0; }
    .home-employers-section__title {
      line-height: 222%;
      font-size: 18px; }
      .home-employers-section__title__big {
        width: 100%;
        margin-top: 20px;
        font-size: 18px;
        line-height: 222%;
        text-align: center;
        letter-spacing: -0.022em; }
    .home-employers-section__sub-title {
      width: 100%;
      margin-bottom: 30px;
      padding-left: 15px;
      padding-right: 15px; }
  .our-advantages {
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 10px;
    margin-bottom: 25px;
    width: 100%;
    -webkit-box-shadow: 0 7px 14px 0 rgba(0, 0, 0, 0.04);
    -moz-box-shadow: 0 7px 14px 0 rgba(0, 0, 0, 0.04);
    box-shadow: 0 7px 14px 0 rgba(0, 0, 0, 0.04);
    z-index: 1;
    position: relative; }
    .our-advantages-item__p:nth-child(2) {
      width: 100%; }
  .why-workmarket-list,
  .our-services {
    display: none; }
  .form-n-desc > div:nth-child(2) {
    padding: 0; }
  .home-employer-form {
    display: none; }
    .home-employer-form__row {
      -webkit-align-items: flex-start;
      -moz-align-items: flex-start;
      -ms-align-items: flex-start;
      align-items: flex-start;
      -webkit-flex-direction: column;
      -moz-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      position: relative; }
      .home-employer-form__row label {
        font-size: 14px;
        position: absolute;
        pointer-events: none;
        left: 0;
        top: -9px;
        white-space: nowrap;
        overflow: hidden;
        line-height: 16px;
        transition: .3s;
        font-weight: 400;
        margin-bottom: 0; }
      .home-employer-form__row .form-reg-field {
        width: 100%; }
      .home-employer-form__row .form-control {
        width: 100%;
        padding: 0;
        border: none;
        border-bottom: 1px solid #EAEAEA;
        font-size: 14px;
        line-height: 26px; }
        .home-employer-form__row .form-control:focus {
          outline: none; }
        .home-employer-form__row .form-control:focus ~ .email-us-form__label,
        .home-employer-form__row .form-control:not(:focus):valid ~ .email-us-form__label {
          top: -15px;
          font-size: 12px; }
      .home-employer-form__row .btn-warning {
        margin-top: 5px; }
    .home-employer-form-mob {
      display: inline-block; }
      .home-employer-form-mob .home-employer-form__row {
        margin-bottom: 0; }
      .home-employer-form-mob .email-us-form__item {
        margin-bottom: 25px; }
      .home-employer-form-mob .email-us-form__input {
        width: 100%;
        border-bottom: 1px solid #D7C253;
        font-size: 14px;
        background: inherit; }
      .home-employer-form-mob .email-us-form__label {
        top: 8px;
        line-height: 14px;
        font-size: 14px; }
      .home-employer-form-mob .input-group-append {
        position: absolute;
        right: 0;
        bottom: 15px; }
      .home-employer-form-mob .field-password-wrap .input-group .input-group-append .btn .fa-eye {
        font-size: 18px; } }

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .why-workmarket-list__bg__mob {
    display: inline-block;
    width: 100vw;
    height: auto;
    position: absolute;
    left: 0;
    bottom: -107px; } }

.home-section-title {
  font-size: 3.2vh;
  font-weight: 500;
  text-align: center;
  margin-bottom: 2.8vh; }

@media screen and (orientation: landscape) and (min-width: 175vh) and (max-width: 991px) {
  .home-section-title {
    font-size: 5.2vh; } }

@media screen and (orientation: landscape) and (max-width: 150vh) {
  .home-section-title {
    font-size: 5.2vh; } }

@media only screen and (min-width: 768px) and (orientation: portrait) {
  .home-section-title {
    font-size: 2.6vw; }
    .home-section-title__big {
      font-size: 3.2vw; } }

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .home-section-title {
    font-size: 5vw;
    margin-bottom: 7.8vw; } }

.img-text-description {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  margin-bottom: 2.8vh; }
  .img-text-description-last {
    margin-bottom: 0; }
  .img-text-description__text {
    width: 52.8vh;
    padding-top: 3.9vh; }
  .img-text-description__p {
    margin-bottom: 0; }
  .img-text-description__title {
    font-size: 24px;
    text-align: center;
    color: #008AEA;
    margin-bottom: 1.9vh; }
  .img-text-description__img {
    width: auto; }
    .img-text-description__img__first {
      height: 23.6vh;
      margin-right: 3.8vh; }
    .img-text-description__img__second {
      height: 23.6vh;
      margin-left: 2.6vh; }
    .img-text-description__img__third {
      height: 23.6vh;
      margin-right: 3.5vh; }

@media screen and (orientation: landscape) and (min-width: 175vh) and (max-width: 991px) {
  .img-text-description__title {
    font-size: 22px; }
  .img-text-description__text {
    width: 78.8vh; }
  .img-text-description__img__first {
    margin-right: 7.8vh;
    height: 44.6vh; }
  .img-text-description__img__second {
    margin-left: 6.6vh;
    height: 40.6vh; }
  .img-text-description__img__third {
    margin-right: 10.5vh;
    height: 37.6vh; } }

@media screen and (orientation: landscape) and (min-width: 200vh) {
  .img-text-description__img__first {
    margin-right: 18.8vh; }
  .img-text-description__img__second {
    margin-left: 15.6vh; }
  .img-text-description__img__third {
    margin-right: 15.5vh; } }

@media screen and (orientation: landscape) and (min-width: 216vh) {
  .img-text-description__text {
    width: 78.8vh; }
  .img-text-description__img__first {
    margin-right: 7.8vh;
    height: 44.6vh; }
  .img-text-description__img__second {
    margin-left: 6.6vh;
    height: 40.6vh; }
  .img-text-description__img__third {
    margin-right: 10.5vh;
    height: 37.6vh; } }

@media screen and (orientation: landscape) and (max-width: 150vh) {
  .img-text-description__title {
    font-size: 16px; }
  .img-text-description__p {
    font-size: 13px; }
  .img-text-description__text {
    width: 56.8vh; }
  .img-text-description__img__first {
    height: 31.6vh; }
  .img-text-description__img__second {
    height: 31.6vh; }
  .img-text-description__img__third {
    height: 29.6vh; } }

@media only screen and (min-width: 768px) and (orientation: portrait) {
  .img-text-description__title {
    font-size: 18px; }
  .img-text-description__img__third,
  .img-text-description__img__second,
  .img-text-description__img__first {
    height: 19.6vh; } }

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .img-text-description {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    margin-bottom: 11.1vw; }
    .img-text-description__text {
      width: 100%;
      padding-top: 0;
      order: 2;
      margin-top: 5vw; }
    .img-text-description__title {
      font-size: 5vw;
      margin-bottom: 5.6vw; }
    .img-text-description__img {
      width: auto; }
      .img-text-description__img__first {
        height: 40.8vw;
        margin-right: 0; }
      .img-text-description__img__second {
        height: 39.2vw;
        margin-left: 0;
        order: 1; }
      .img-text-description__img__third {
        height: 38.3vw;
        margin-right: 0; } }

.home-employers-banner {
  overflow: hidden; }
  .home-employers-banner-gift {
    background-color: #FAEDE5;
    margin-top: 5.6vh; }
    .home-employers-banner-gift .container {
      position: relative;
      padding: 7.4vh 5vh 7.4vh; }
  .home-employers-banner-tariff {
    background-color: #DCE7FF;
    margin-top: 6.5vh; }
    .home-employers-banner-tariff .container {
      position: relative;
      padding: 12.3vh 0 12.3vh 5vh; }
  .home-employers-banner__img {
    width: auto;
    position: absolute; }
  .home-employers-banner-gift__img {
    height: 27.3vh;
    right: -10.6vh;
    bottom: 0; }
  .home-employers-banner-tariff__img {
    height: 51.5vh;
    right: -21.9vh;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%); }

.banner-text-btn-tariff {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between; }

.banner-text-btn__p {
  font-size: 4.6vh;
  width: 60vh;
  margin-bottom: 0; }
  .banner-text-btn__p > br {
    display: none; }

.banner-text-btn__span {
  font-weight: bold; }

.banner-text-btn-gift__p {
  margin-bottom: 4.6vh; }

.banner-text-btn__btn {
  font-size: 1.85vh;
  padding: 1.7vh 12.2vh;
  z-index: 1; }
  .banner-text-btn__btn_gold {
    background-color: #FFDB49;
    color: #062336; }
    .banner-text-btn__btn_gold:hover {
      text-decoration: none;
      background-color: #c5a939;
      color: #062336; }

@media screen and (orientation: landscape) and (min-width: 216vh) {
  .home-employers-banner-gift .container {
    padding: 7.4vh 25vh 7.4vh; }
  .home-employers-banner-gift__img {
    height: 32.3vh;
    right: 21.4vh; }
  .home-employers-banner-tariff .container {
    padding: 12.3vh 0 12.3vh 25vh; } }

@media screen and (orientation: landscape) and (max-width: 135vh) {
  .banner-text-btn__p {
    font-size: 4.3vh;
    width: 51vh; }
  .home-employers-banner-tariff__img {
    height: 43.5vh;
    right: -16.9vh; } }

@media only screen and (min-width: 768px) and (orientation: portrait) {
  .home-employers-banner-gift__img {
    height: 26.3vw;
    right: -10.6vw;
    bottom: 0; }
  .home-employers-banner-gift .container {
    padding: 4.4vw 10vw 4.4vw; }
  .home-employers-banner-tariff {
    margin-top: 3.5vh; }
    .home-employers-banner-tariff__img {
      height: 46.5vw;
      right: -10.9vw; }
    .home-employers-banner-tariff .container {
      position: relative;
      padding: 12.3vw 17vw 12.3vw 4.4vw; }
  .banner-text-btn__btn {
    font-size: 1.85vw;
    padding: 1.7vw 3.2vw; }
  .banner-text-btn__p {
    font-size: 3vw;
    width: 61vw; }
    .banner-text-btn__p > br {
      display: inline-block; }
  .banner-text-btn-gift__p {
    margin-bottom: 4.6vw; } }

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .home-employers-banner-gift {
    background: url("/images/home-employers/banner-gift-bg.jpg") center center no-repeat;
    background-size: cover;
    margin-top: 8.3vw; }
    .home-employers-banner-gift .container {
      padding: 8.3vw 10px 30vw; }
  .home-employers-banner-tariff {
    background: url("/images/home-employers/banner-tariff-bg.jpg") center center no-repeat;
    background-size: cover;
    margin-top: 2.8vw; }
    .home-employers-banner-tariff .container {
      padding: 8.3vw 10px 28.6vw; }
  .home-employers-banner__img {
    display: none; }
  .banner-text-btn-tariff {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
    .banner-text-btn-tariff .banner-text-btn__p {
      text-align: center;
      margin-bottom: 28vw; }
  .banner-text-btn__p {
    font-size: 5.6vw;
    width: 100%; }
    .banner-text-btn__p > br {
      display: inline-block; }
  .banner-text-btn-gift {
    text-align: center; }
    .banner-text-btn-gift__p {
      margin-bottom: 8.3vw;
      text-align: center; }
  .banner-text-btn__btn {
    font-size: 3.9vw;
    padding: 2.7vw 6.1vw; } }

.list-partner-companies {
  margin-bottom: 2.8vh; }

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .list-partner-companies {
    margin-bottom: 0; }
  .list-partner-companies__btn {
    font-size: 3.9vw;
    color: #008BEB;
    background: none;
    border-color: #fff;
    padding: 0; }
    .list-partner-companies__btn:hover {
      color: #008BEB;
      background: none;
      border-color: #fff; } }

.you-post-we-promote-wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  padding: 0;
  margin-bottom: 0; }

.you-post-we-promote-item {
  background-color: #F6F6F6;
  padding: 2.7vh 2.3vh;
  width: calc(33.3% - 16px);
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }
  .you-post-we-promote-item__img {
    width: 11.3vh;
    height: 11.3vh;
    border-radius: 50%;
    background-color: #fff;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    margin-bottom: 2.6vh; }
    .you-post-we-promote-item__img i {
      color: #008BEB;
      font-size: 6.2vh; }
  .you-post-we-promote-item__title {
    text-transform: uppercase;
    margin-bottom: 1.1vh;
    text-align: center;
    font-size: 16px;
    font-weight: 500; }
  .you-post-we-promote-item__text {
    text-align: center;
    min-height: 5.8vh;
    margin-bottom: 0; }

@media screen and (orientation: landscape) and (min-width: 160vh) {
  .you-post-we-promote-item__text {
    min-height: 8vh; } }

@media screen and (orientation: landscape) and (min-width: 175vh) and (max-width: 991px) {
  .you-post-we-promote-item__text {
    min-height: 23vh;
    font-size: 13px; }
  .you-post-we-promote-item__title {
    min-height: 13vh; } }

@media screen and (orientation: landscape) and (min-width: 200vh) {
  .you-post-we-promote-item__text {
    min-height: 10vh; } }

@media screen and (orientation: landscape) and (min-width: 216vh) {
  .you-post-we-promote-item__text {
    min-height: 23vh; }
  .you-post-we-promote-item__title {
    min-height: 13vh; } }

@media screen and (orientation: landscape) and (max-width: 150vh) {
  .you-post-we-promote-wrap {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center; }
  .you-post-we-promote-item {
    padding: 2.7vh 2.3vh 5vh;
    width: calc(64% - 16px);
    margin-bottom: 4vh; } }

@media only screen and (min-width: 768px) and (orientation: portrait) {
  .you-post-we-promote-item {
    padding: 2.7vh 1vh; }
    .you-post-we-promote-item__img {
      width: 8.3vh;
      height: 8.3vh; }
      .you-post-we-promote-item__img i {
        font-size: 4.2vh; }
    .you-post-we-promote-item__title {
      font-size: 13px; }
    .you-post-we-promote-item__text {
      font-size: 12px;
      min-height: 6.8vh; } }

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .you-post-we-promote-wrap {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center; }
  .you-post-we-promote-item {
    padding: 6.9vw 2px 12.7vw;
    width: 77.7vw;
    margin-bottom: 5.6vw; }
    .you-post-we-promote-item__img {
      width: 25vw;
      height: 25vw;
      margin-bottom: 8vw; }
      .you-post-we-promote-item__img i {
        font-size: 14.4vw; }
    .you-post-we-promote-item__title {
      margin-bottom: 3.3vw;
      font-size: 4.2vw; }
    .you-post-we-promote-item__text {
      min-height: auto;
      font-size: 3.9vw; } }

.popular-questions-home {
  padding: 0;
  border-top: 1px solid #E8E8E8;
  margin: 0; }

.popular-question-home {
  padding: 1.6vh 1.2vh 1.7vh 2.4vh;
  cursor: pointer;
  position: relative;
  border-bottom: 1px solid #E8E8E8; }
  .popular-question-home i {
    color: #008BEB;
    font-size: 15px;
    position: absolute;
    top: 2.2vh;
    right: 1.2vh; }
  .popular-question-home__title {
    color: #008BEB;
    font-size: 16px;
    width: 93%;
    margin-bottom: 0; }
  .popular-question-home__text {
    margin-bottom: 0;
    display: none;
    width: 93%;
    margin-top: 1.85vh; }
  .popular-question-home__open .popular-question-home__text {
    display: inline-block; }
  .popular-question-home__open i {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg); }

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .popular-question-home {
    padding: 5vw 2.2vw 5.5vw; }
    .popular-question-home i {
      font-size: 17px;
      top: 6.7vw;
      right: 1.7vw; }
    .popular-question-home__title {
      font-size: 4.4vw;
      width: 93%; }
    .popular-question-home__text {
      width: 100%;
      margin-top: 5.6vw;
      font-size: 3.9vw; } }

.home-employers-video {
  width: 76.4vh;
  height: 40vh;
  -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3); }

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .home-employers-video {
    width: 100%;
    height: 65vw; } }

.email-us-form {
  -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  padding: 3.8vh 4.6vh 4.6vh;
  width: 52.8vh;
  margin: 0 auto;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }
  .email-us-form__title {
    font-size: 3.2vh;
    font-weight: 500;
    text-align: center;
    margin-bottom: 6.6vh; }
  .email-us-form__item {
    display: inline-block;
    vertical-align: top;
    position: relative;
    width: 100%;
    margin-bottom: 6.5vh; }
    .email-us-form__item__margin-b {
      margin-bottom: 4.4vh; }
  .email-us-form__input {
    width: 100%;
    padding: 0;
    border: none;
    border-bottom: 1px solid #EAEAEA;
    font-size: 14px;
    line-height: 26px; }
    .email-us-form__input:focus {
      outline: none; }
    .email-us-form__input:not(:focus):valid ~ .email-us-form__label,
    .email-us-form__input:focus ~ .email-us-form__label {
      top: -15px;
      font-size: 10px; }
  .email-us-form__textarea {
    height: 27px;
    resize: none; }
    .email-us-form__textarea:focus {
      height: 126px; }
  .email-us-form__label {
    position: absolute;
    pointer-events: none;
    left: 0;
    top: -9px;
    white-space: nowrap;
    overflow: hidden;
    line-height: 16px;
    transition: .3s;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0; }

@media screen and (orientation: landscape) and (min-width: 175vh) and (max-width: 991px) {
  .email-us-form {
    width: 108.8vh; }
    .email-us-form__title {
      font-size: 6.2vh; } }

@media screen and (orientation: landscape) and (max-width: 150vh) {
  .email-us-form {
    width: 99.8vh; }
    .email-us-form__title {
      font-size: 6.2vh; } }

@media only screen and (min-width: 768px) and (orientation: portrait) {
  .email-us-form__title {
    font-size: 2.6vh;
    margin-bottom: 3.6vh; } }

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .email-us-form {
    padding: 0 4.2vw;
    width: 100%;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none; }
    .email-us-form__title {
      font-size: 5vw;
      margin-bottom: 9.8vw; }
    .email-us-form__item {
      margin-bottom: 5.5vh; }
      .email-us-form__item__margin-b {
        margin-bottom: 4.4vh; } }

.hot-vacancies-list {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: flex-start;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0;
  width: 100%; }
  .hot-vacancies-list__item {
    width: calc(33.3% - 13.4px);
    margin-right: 20px;
    margin-bottom: 9px;
    border-radius: 3px;
    border: 1px solid #E0E0E0; }
    .hot-vacancies-list__item > a {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex; }
      .hot-vacancies-list__item > a:hover {
        text-decoration: none;
        box-shadow: 0 5px 11px rgba(0, 0, 0, 0.2); }
    .hot-vacancies-list__item:nth-child(3n + 3) {
      margin-right: 0; }
  .hot-vacancies-list__wrap {
    padding: 20px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
    -ms-flex-pack: flex-start; }

.hot-vacancies-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: rgba(0, 138, 234, 0.6);
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  margin-right: 15px; }
  .hot-vacancies-img .fas {
    font-size: 22px;
    color: #fff; }

.hot-vacancies-title {
  margin-bottom: 0;
  color: #008AEA;
  width: calc(100% - 65px); }

@media only screen and (max-width: 991px) {
  .hot-vacancies-list__wrap {
    padding: 15px; }
  .hot-vacancies-list__item {
    width: calc(50% - 10px);
    margin-right: 10px; }
    .hot-vacancies-list__item:nth-child(3n + 3) {
      margin-right: 10px; }
    .hot-vacancies-list__item:nth-child(2n + 2) {
      margin-right: 0; } }

@media only screen and (max-width: 767px) {
  .hot-vacancies-list {
    padding: 0 15px; }
    .hot-vacancies-list__item {
      width: 100%;
      margin-right: 0; }
      .hot-vacancies-list__item:nth-child(3n + 3) {
        margin-right: 0; } }

.icon_select_mate {
  position: absolute;
  top: 6px;
  right: 4px;
  font-size: 16px;
  height: 22px;
  cursor: pointer; }

.select_mate {
  position: relative;
  float: left;
  width: 100%;
  min-height: 35px;
  color: #062336;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  transition: all 375ms ease-in-out; }

.select_mate select {
  position: absolute;
  overflow: hidden;
  height: 0;
  opacity: 0;
  z-index: -1; }

.cont_list_select_mate {
  position: relative;
  float: left;
  width: 100%; }

.cont_select_int {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
  overflow: hidden;
  overflow-y: auto;
  max-height: 200px;
  height: 0;
  width: 100%;
  background-color: #fff;
  padding: 0;
  margin-bottom: 0;
  margin-top: 0;
  transition: all 375ms ease-in-out;
  border: 1px solid #e0e0e0;
  box-shadow: 1px 4px 10px -2px rgba(0, 0, 0, 0.2); }

.cont_select_int li {
  position: relative;
  float: left;
  width: 100%;
  list-style-type: none;
  margin: 0;
  padding: 5px 5px 5px 5px;
  display: block;
  cursor: pointer; }

.cont_select_int li:last-child {
  border-radius: 3px;
  border-bottom: 0; }

.cont_select_int li:hover {
  background-color: #008AEA;
  color: #fff; }

.selecionado_opcion {
  padding: 9px 26px 9px 10px;
  width: 100%;
  display: block;
  margin: 0;
  cursor: pointer; }

.vacancies-modal {
  width: 450px;
  position: fixed;
  right: 0;
  top: 100%;
  transform: translateY(-100%);
  background-color: #fff;
  z-index: 1031;
  transition: 1s;
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3); }
  .vacancies-modal-header {
    padding: 25px 0 36px;
    margin-bottom: 2px;
    position: relative;
    background: url("/images/vacancies-modal/header-bg.svg") top center no-repeat;
    background-size: cover; }
    .vacancies-modal-header__text {
      font-size: 20px;
      font-weight: 500;
      color: #fff;
      text-align: center;
      margin-bottom: 0;
      cursor: pointer; }
    .vacancies-modal-header__img {
      height: 91px;
      position: absolute;
      left: 0;
      top: 0; }
    .vacancies-modal-header .material-icons, .vacancies-modal-header .material-icons-outlined, .vacancies-modal-header .material-icons-round, .vacancies-modal-header .material-icons-sharp, .vacancies-modal-header .material-icons-two-tone {
      font-size: 22px;
      color: #fff;
      position: absolute;
      right: 16px;
      top: 16px;
      cursor: pointer; }
  .vacancies-modal-form {
    width: 100%;
    padding: 0 24px 30px; }
    .vacancies-modal-form__title {
      font-size: 14px;
      text-align: left;
      margin-bottom: 20px; }
  .vacancies-modal__move {
    top: calc(100% - 90px);
    transform: translateY(0); }

.btn-vacancies-modal {
  width: 100%;
  background-color: #008AEA;
  border: none;
  text-align: center;
  overflow: hidden;
  transition: .7s; }
  .btn-vacancies-modal .before-pay,
  .btn-vacancies-modal .processing,
  .btn-vacancies-modal .after-pay {
    width: 100%;
    height: 100%;
    position: relative;
    display: none;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center; }
  .btn-vacancies-modal.if-not-paid .before-pay {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    color: #fff; }
    .btn-vacancies-modal.if-not-paid .before-pay__text {
      font-size: 14px;
      margin-bottom: 0; }
  .btn-vacancies-modal.if-processing .processing {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    color: #fff; }
    .btn-vacancies-modal.if-processing .processing__text {
      font-size: 14px;
      margin-bottom: 0; }
    .btn-vacancies-modal.if-processing .processing .spinner-wrap {
      position: absolute;
      top: 50%;
      right: 15px;
      -webkit-transform: translate(0, -50%);
      -moz-transform: translate(0, -50%);
      -ms-transform: translate(0, -50%);
      transform: translate(0, -50%); }
    .btn-vacancies-modal.if-processing .processing .spinner-border {
      width: 18px;
      height: 18px; }
  .btn-vacancies-modal.if-paid {
    background-color: #28A745; }
    .btn-vacancies-modal.if-paid .after-pay {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      color: #fff;
      animation: move 1s ease; }
      .btn-vacancies-modal.if-paid .after-pay__text {
        font-size: 14px;
        margin-bottom: 0; }
      .btn-vacancies-modal.if-paid .after-pay .material-icons, .btn-vacancies-modal.if-paid .after-pay .material-icons-outlined, .btn-vacancies-modal.if-paid .after-pay .material-icons-round, .btn-vacancies-modal.if-paid .after-pay .material-icons-sharp, .btn-vacancies-modal.if-paid .after-pay .material-icons-two-tone {
        font-size: 18px;
        margin-right: 10px; }

@keyframes move {
  0% {
    left: 40px; }
  100% {
    left: 0; } }

@media only screen and (max-width: 1500px) {
  .vacancies-modal {
    width: 390px; }
  .vacancies-modal-header__img {
    height: 65px;
    top: 14px; } }

@media only screen and (max-width: 767px) {
  .vacancies-modal {
    display: none; } }

.tariff-payment-table {
  margin-bottom: 20px;
  width: 100%; }
  .tariff-payment-table th {
    background-color: #2582BF;
    padding: 12px 20px;
    text-align: center;
    color: #fff;
    font-weight: 400;
    vertical-align: middle !important; }
    .tariff-payment-table th:nth-child(1) {
      width: 54.4%; }
    .tariff-payment-table th:nth-child(2) {
      width: 11.8%; }
    .tariff-payment-table th:nth-child(3) {
      width: 7.8%; }
    .tariff-payment-table th:nth-child(4) {
      width: 7.8%; }
    .tariff-payment-table th:nth-child(5) {
      width: 8.5%; }
    .tariff-payment-table th:nth-child(6) {
      width: 9.7%; }
  .tariff-payment-table th,
  .tariff-payment-table td {
    border: .5px solid #fff !important; }
  .tariff-payment-table tbody td {
    background-color: #EAF3FA;
    padding: 22px 30px;
    text-align: center; }
    .tariff-payment-table tbody td:nth-child(1) {
      text-align: left;
      font-weight: 500;
      width: 54.4%; }
    .tariff-payment-table tbody td:nth-child(2) {
      width: 11.8%; }
    .tariff-payment-table tbody td:nth-child(3) {
      width: 7.8%; }
    .tariff-payment-table tbody td:nth-child(4) {
      width: 7.8%; }
    .tariff-payment-table tbody td:nth-child(5) {
      width: 8.5%; }
    .tariff-payment-table tbody td:nth-child(6) {
      width: 9.7%; }

.mob-tariff-payment-table {
  display: none;
  width: 100%;
  padding: 0; }
  .mob-tariff-payment-table__item {
    width: 100%;
    background-color: #EAF3FA;
    margin-bottom: 1px;
    padding: 15px 0;
    position: relative; }
    .mob-tariff-payment-table__item:before {
      content: '';
      height: 100%;
      width: 15px;
      position: absolute;
      left: -15px;
      top: 0;
      background-color: #EAF3FA; }
    .mob-tariff-payment-table__item:after {
      content: '';
      height: 100%;
      width: 15px;
      position: absolute;
      right: -15px;
      top: 0;
      background-color: #EAF3FA; }

.tariff-payment-table-row {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: baseline;
  -moz-align-items: baseline;
  -ms-align-items: baseline;
  align-items: baseline;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  margin-bottom: 10px; }
  .tariff-payment-table-row__small p {
    font-size: 12px; }
  .tariff-payment-table-row .price-label {
    font-size: 12px; }

.tariff-payment-details {
  margin-top: 12px;
  width: 100%;
  display: none; }

.detail-btn-services-is-checked .tariff-payment-details {
  display: inline-block; }

@media only screen and (max-width: 1200px) {
  .tariff-payment-table {
    margin-bottom: 20px; }
    .tariff-payment-table th {
      padding: 12px 20px; }
      .tariff-payment-table th:nth-child(1) {
        width: 36.1%; }
      .tariff-payment-table th:nth-child(2) {
        width: 17.1%; }
      .tariff-payment-table th:nth-child(3) {
        width: 10.9%; }
      .tariff-payment-table th:nth-child(4) {
        width: 10.9%; }
      .tariff-payment-table th:nth-child(5) {
        width: 11.5%; }
      .tariff-payment-table th:nth-child(6) {
        width: 13.5%; }
    .tariff-payment-table tbody td {
      padding: 22px 30px; }
      .tariff-payment-table tbody td:nth-child(1) {
        width: 36.1%; }
      .tariff-payment-table tbody td:nth-child(2) {
        width: 17.1%; }
      .tariff-payment-table tbody td:nth-child(3) {
        width: 10.9%; }
      .tariff-payment-table tbody td:nth-child(4) {
        width: 10.9%; }
      .tariff-payment-table tbody td:nth-child(5) {
        width: 11.5%; }
      .tariff-payment-table tbody td:nth-child(6) {
        width: 13.5%; } }

@media only screen and (max-width: 992px) {
  .tariff-payment-table th {
    padding: 15px 10px; }
  .tariff-payment-table tbody td {
    padding: 15px; } }

@media only screen and (max-width: 767px) {
  .tariff-payment-table {
    display: none; }
  .mob-tariff-payment-table {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; } }

.tariff-payment-table {
  margin-bottom: 20px;
  width: 100%; }
  .tariff-payment-table th {
    background-color: #2582BF;
    padding: 12px 20px;
    text-align: center;
    color: #fff;
    font-weight: 400;
    vertical-align: middle !important; }
    .tariff-payment-table th:nth-child(1) {
      width: 54.4%; }
    .tariff-payment-table th:nth-child(2) {
      width: 11.8%; }
    .tariff-payment-table th:nth-child(3) {
      width: 7.8%; }
    .tariff-payment-table th:nth-child(4) {
      width: 7.8%; }
    .tariff-payment-table th:nth-child(5) {
      width: 8.5%; }
    .tariff-payment-table th:nth-child(6) {
      width: 9.7%; }
  .tariff-payment-table th,
  .tariff-payment-table td {
    border: .5px solid #fff !important; }
  .tariff-payment-table tbody td {
    background-color: #EAF3FA;
    padding: 22px 30px;
    text-align: center; }
    .tariff-payment-table tbody td:nth-child(1) {
      text-align: left;
      font-weight: 500;
      width: 54.4%; }
    .tariff-payment-table tbody td:nth-child(2) {
      width: 11.8%; }
    .tariff-payment-table tbody td:nth-child(3) {
      width: 7.8%; }
    .tariff-payment-table tbody td:nth-child(4) {
      width: 7.8%; }
    .tariff-payment-table tbody td:nth-child(5) {
      width: 8.5%; }
    .tariff-payment-table tbody td:nth-child(6) {
      width: 9.7%; }

.mob-tariff-payment-table {
  display: none;
  width: 100%;
  padding: 0; }
  .mob-tariff-payment-table__item {
    width: 100%;
    background-color: #EAF3FA;
    margin-bottom: 1px;
    padding: 15px 0;
    position: relative; }
    .mob-tariff-payment-table__item:before {
      content: '';
      height: 100%;
      width: 15px;
      position: absolute;
      left: -15px;
      top: 0;
      background-color: #EAF3FA; }
    .mob-tariff-payment-table__item:after {
      content: '';
      height: 100%;
      width: 15px;
      position: absolute;
      right: -15px;
      top: 0;
      background-color: #EAF3FA; }

.tariff-payment-table-row {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: baseline;
  -moz-align-items: baseline;
  -ms-align-items: baseline;
  align-items: baseline;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  margin-bottom: 10px; }
  .tariff-payment-table-row__small p {
    font-size: 12px; }
  .tariff-payment-table-row .price-label {
    font-size: 12px; }

.tariff-payment-details {
  margin-top: 12px;
  width: 100%;
  display: none; }

.detail-btn-services-is-checked .tariff-payment-details {
  display: inline-block; }

@media only screen and (max-width: 1200px) {
  .tariff-payment-table {
    margin-bottom: 20px; }
    .tariff-payment-table th {
      padding: 12px 20px; }
      .tariff-payment-table th:nth-child(1) {
        width: 36.1%; }
      .tariff-payment-table th:nth-child(2) {
        width: 17.1%; }
      .tariff-payment-table th:nth-child(3) {
        width: 10.9%; }
      .tariff-payment-table th:nth-child(4) {
        width: 10.9%; }
      .tariff-payment-table th:nth-child(5) {
        width: 11.5%; }
      .tariff-payment-table th:nth-child(6) {
        width: 13.5%; }
    .tariff-payment-table tbody td {
      padding: 22px 30px; }
      .tariff-payment-table tbody td:nth-child(1) {
        width: 36.1%; }
      .tariff-payment-table tbody td:nth-child(2) {
        width: 17.1%; }
      .tariff-payment-table tbody td:nth-child(3) {
        width: 10.9%; }
      .tariff-payment-table tbody td:nth-child(4) {
        width: 10.9%; }
      .tariff-payment-table tbody td:nth-child(5) {
        width: 11.5%; }
      .tariff-payment-table tbody td:nth-child(6) {
        width: 13.5%; } }

@media only screen and (max-width: 992px) {
  .tariff-payment-table th {
    padding: 15px 10px; }
  .tariff-payment-table tbody td {
    padding: 15px; } }

@media only screen and (max-width: 767px) {
  .tariff-payment-table {
    display: none; }
  .mob-tariff-payment-table {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; } }

.block-read-more .read-more {
  color: #008AEA;
  cursor: pointer; }

.navbar-top ul li.packages-dropdown {
  padding-right: 0; }
  .navbar-top ul li.packages-dropdown .material-icons, .navbar-top ul li.packages-dropdown .material-icons-outlined, .navbar-top ul li.packages-dropdown .material-icons-round, .navbar-top ul li.packages-dropdown .material-icons-sharp, .navbar-top ul li.packages-dropdown .material-icons-two-tone {
    font-size: 22px;
    color: #fff;
    padding-top: 6px; }
  .navbar-top ul li.packages-dropdown .dropdown-item {
    padding: 0; }
    .navbar-top ul li.packages-dropdown .dropdown-item:hover, .navbar-top ul li.packages-dropdown .dropdown-item:focus {
      background: none; }
  .navbar-top ul li.packages-dropdown .packages-list-title {
    font-size: 12px;
    font-weight: 500;
    color: #062336 !important;
    padding: 9px 15px;
    background: #F7F7F7;
    margin-bottom: 0; }
  .navbar-top ul li.packages-dropdown .dropdown-menu {
    padding: 0;
    margin: 0;
    font-size: 14px;
    border: none;
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.1); }
  .navbar-top ul li.packages-dropdown .packages-dropdown-item {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
    padding: 10px 15px;
    font-weight: 400;
    color: #6C757D !important;
    white-space: pre-line;
    border-top: 0.5px solid #BCBCBC; }
    .navbar-top ul li.packages-dropdown .packages-dropdown-item > p {
      margin-bottom: 0; }
    .navbar-top ul li.packages-dropdown .packages-dropdown-item .name-service {
      width: 200px; }
    .navbar-top ul li.packages-dropdown .packages-dropdown-item .count-service {
      width: 30px;
      text-align: right; }
  .navbar-top ul li.packages-dropdown .packages-dropdown-empty {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    padding: 20px 35px; }
    .navbar-top ul li.packages-dropdown .packages-dropdown-empty:hover, .navbar-top ul li.packages-dropdown .packages-dropdown-empty:focus {
      background: none; }
    .navbar-top ul li.packages-dropdown .packages-dropdown-empty p {
      font-size: 12px;
      margin-bottom: 20px;
      text-align: center; }

.packages-dropdown-mob {
  border-bottom: 1px solid #B3D6FC;
  padding: 10px 0;
  display: none; }
  .packages-dropdown-mob__row {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: baseline;
    -moz-align-items: baseline;
    -ms-align-items: baseline;
    align-items: baseline;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
    padding: 0 27px 0 66px;
    margin: 10px 0 0; }
    .packages-dropdown-mob__row:nth-last-child(1) {
      margin-bottom: 7px; }
    .packages-dropdown-mob__row > p {
      margin-bottom: 0;
      color: #fff; }
      .packages-dropdown-mob__row > p:nth-child(1) {
        width: 85%; }
      .packages-dropdown-mob__row > p:nth-child(2) {
        width: 15%;
        text-align: right; }
  .packages-dropdown-mob-empty {
    padding: 5px 25px; }
    .packages-dropdown-mob-empty p {
      margin-bottom: 10px;
      color: #fff; }

.packages-dropdown-title {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: flex-start;
  padding: 0 20px; }
  .packages-dropdown-title__open .material-icons, .packages-dropdown-title__open .material-icons-outlined, .packages-dropdown-title__open .material-icons-round, .packages-dropdown-title__open .material-icons-sharp, .packages-dropdown-title__open .material-icons-two-tone {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg); }
  .packages-dropdown-title .material-icons, .packages-dropdown-title .material-icons-outlined, .packages-dropdown-title .material-icons-round, .packages-dropdown-title .material-icons-sharp, .packages-dropdown-title .material-icons-two-tone {
    font-size: 23px;
    color: #fff;
    margin-right: 23px; }
  .packages-dropdown-title > p {
    margin-bottom: 0;
    color: #fff; }

@media only screen and (max-width: 767px) {
  .navbar-top ul li.packages-dropdown {
    display: none; }
  .packages-dropdown-mob {
    display: inline-block;
    width: 100%; } }

.account-setting-wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between; }
  .account-setting-wrap__user-name {
    line-height: 1;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 10px; }
  .account-setting-wrap__user-email {
    line-height: 1;
    font-size: 12px;
    text-align: center;
    margin-bottom: 10px;
    color: #6C757D; }
  .account-setting-wrap .form-control.is-invalid {
    background: #fff; }

.account-setting-pass {
  margin-bottom: 20px;
  width: 50%; }
  .account-setting-pass .input-group-text {
    background-color: #fff;
    border-left: none; }
    .account-setting-pass .input-group-text .fa-eye {
      font-size: 12px !important;
      color: #6C757D !important; }
    .account-setting-pass .input-group-text:hover .fa-eye {
      color: #545B62 !important; }

.setting-remove-account {
  font-size: 13px;
  color: #DC3545;
  text-align: center; }
  .setting-remove-account:hover {
    color: #C82333; }

.photo-name-email {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 32%;
  background: #FFFFFF;
  border: 0.5px solid #E5E5E5;
  box-shadow: 0 4px 8px rgba(205, 205, 205, 0.25);
  padding: 45px 40px 108px; }
  .photo-name-email .form-avatar {
    margin: 0 auto 14px;
    background-color: #E5E5E5;
    width: 125px;
    height: 125px;
    border: 0.5px solid #E5E5E5; }
    .photo-name-email .form-avatar:hover {
      border-color: #008BEB; }
    .photo-name-email .form-avatar-img .no-user-avatar {
      width: 79px; }
    .photo-name-email .form-avatar-img .is-user-avatar {
      width: auto;
      height: auto;
      max-height: 50px;
      max-width: 100px;
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); }
  .photo-name-email .form-avatar-img {
    max-width: 125px;
    max-height: 125px; }
  .photo-name-email .upload-photo-text {
    font-size: 14px;
    text-align: center;
    margin-bottom: 36px; }
  .photo-name-email .remove-photo {
    font-size: 14px;
    text-align: center;
    margin-bottom: 36px;
    color: #008BEB;
    cursor: pointer; }
  .photo-name-email .block-name-email {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }

.form-account-data {
  width: 66%; }
  .form-account-data .custom-checkbox {
    margin-right: 19px; }
  .form-account-data .form-control:focus {
    color: #062336; }

.account-btns {
  margin-top: 30px; }

.form-group-form-account {
  margin-bottom: 19px; }
  .form-group-form-account label {
    font-size: 14px;
    line-height: 1;
    margin-bottom: 10px; }
  .form-group-form-account.is-invalid-block label {
    color: #DC3545 !important; }

.form-group-custom-checkbox {
  margin-top: 19px;
  margin-bottom: 0; }

.btn-cancel-setting {
  margin-right: 10px; }
  .btn-cancel-setting .disabled, .btn-cancel-setting:disabled:hover, .btn-cancel-setting:disabled {
    color: #B4B4B4 !important;
    border-color: #B4B4B4 !important;
    background-color: transparent !important; }
  .btn-cancel-setting:hover {
    color: #fff !important;
    background-color: #008BEB !important;
    border-color: #008BEB !important; }

@media only screen and (max-width: 991px) {
  .account-setting-wrap {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
    .account-setting-wrap__user-name {
      margin-bottom: 5px;
      order: 1; }
    .account-setting-wrap__user-email {
      order: 2; }
  .account-setting-pass {
    margin-bottom: 14px;
    width: 100%; }
  .form-account-data {
    width: 100%; }
  .photo-name-email {
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
    -ms-flex-pack: flex-start;
    width: 100%;
    border: none;
    box-shadow: none;
    padding: 0;
    margin-bottom: 18px; }
    .photo-name-email .form-avatar {
      margin: 0 20px 0 0;
      width: 65px;
      height: 65px; }
      .photo-name-email .form-avatar-img {
        max-width: 65px;
        max-height: 65px; }
        .photo-name-email .form-avatar-img .no-user-avatar {
          width: 36px; }
        .photo-name-email .form-avatar-img .is-user-avatar {
          max-height: 45px;
          max-width: 55px; }
    .photo-name-email .remove-photo,
    .photo-name-email .upload-photo-text {
      order: 3;
      margin-bottom: 0; }
    .photo-name-email .setting-remove-account {
      position: absolute;
      right: 0;
      top: 0; }
    .photo-name-email .block-name-email {
      -webkit-align-items: flex-start;
      -moz-align-items: flex-start;
      -ms-align-items: flex-start;
      align-items: flex-start; }
  .form-group-form-account {
    margin-bottom: 14px; }
  .form-account-data .block-contacts-list .block-communication-method {
    margin-top: 0; } }

@media only screen and (max-width: 767px) {
  .nav-tabs-castom__account {
    padding: 0 !important; }
  .account-setting-pass {
    margin-bottom: 20px; }
  .account-setting-wrap__user-name {
    order: 2; }
    .account-setting-wrap__user-name-email {
      order: 3; }
  .photo-name-email {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    margin-bottom: 23px; }
    .photo-name-email .setting-remove-account {
      position: relative;
      order: 4; }
    .photo-name-email .remove-photo,
    .photo-name-email .upload-photo-text {
      order: 1;
      margin-bottom: 20px; }
    .photo-name-email .form-avatar {
      margin: 0; }
    .photo-name-email .block-name-email {
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center; } }

.custom-checkbox {
  cursor: pointer;
  margin-bottom: 0; }
  .custom-checkbox > input {
    display: none; }
  .custom-checkbox i {
    display: inline-block;
    width: 30px;
    height: 15px;
    border-radius: 20px;
    vertical-align: middle;
    transition: .25s .09s;
    position: relative;
    background: #6C757D; }
    .custom-checkbox i:after {
      content: " ";
      display: block;
      width: 9px;
      height: 9px;
      top: 3px;
      left: 3px;
      border-radius: 50%;
      background: #fff;
      position: absolute;
      transition: .15s; }
  .custom-checkbox > input:checked + i {
    background: #008BEB; }
  .custom-checkbox > input:checked + i + span {
    color: #29316b; }
  .custom-checkbox > input:checked + i:after {
    transform: translateX(15px); }
  .custom-checkbox__sm {
    line-height: 1; }
    .custom-checkbox__sm i {
      width: 20px;
      height: 10px; }
      .custom-checkbox__sm i:after {
        width: 6px;
        height: 6px;
        top: 2px;
        left: 3px; }
    .custom-checkbox__sm > input:checked + i:after {
      transform: translateX(8px); }

.custom-checkbox-wrapper {
  position: relative;
  display: inline-block; }
  .custom-checkbox-wrapper .custom-checkbox-wrapper__input {
    display: none; }
    .custom-checkbox-wrapper .custom-checkbox-wrapper__input ~ .custom-checkbox-wrapper__b {
      display: inline-block;
      position: relative;
      background-color: #ffffff;
      border: 1px solid #D5D5D5;
      font-size: 20px;
      color: #fff;
      cursor: pointer;
      line-height: 1;
      outline: 0;
      text-align: center;
      vertical-align: middle;
      height: 20px;
      width: 20px; }
    .custom-checkbox-wrapper .custom-checkbox-wrapper__input:checked ~ .custom-checkbox-wrapper__b {
      background-color: #008BEB;
      border: 1px solid #008BEB; }
      .custom-checkbox-wrapper .custom-checkbox-wrapper__input:checked ~ .custom-checkbox-wrapper__b:after {
        content: '';
        position: absolute;
        top: 3px;
        left: 3px;
        display: block;
        width: 11px;
        height: 11px;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-size: cover; }
    .custom-checkbox-wrapper .custom-checkbox-wrapper__input:disabled ~ .custom-checkbox-wrapper__b {
      background-color: #70A1D6;
      border-color: #70A1D6; }

.custom-checkbox-input-label {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: baseline;
  -moz-align-items: baseline;
  -ms-align-items: baseline;
  align-items: baseline; }

.custom-checkbox-label {
  margin-left: 10px;
  margin-bottom: 0; }
  .custom-checkbox-label__small {
    font-size: 13px;
    font-weight: 400; }

@media only screen and (max-width: 991px) {
  .custom-checkbox__sm {
    line-height: 1; }
    .custom-checkbox__sm i {
      width: 30px;
      height: 15px; }
      .custom-checkbox__sm i:after {
        width: 9px;
        height: 9px;
        top: 3px;
        left: 3px; }
    .custom-checkbox__sm > input:checked + i:after {
      transform: translateX(15px); } }

.account-employers-header {
  margin-bottom: 15px; }

.upload-photo-wrap .account-settings-page {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: flex-start;
  padding: 0; }
  .upload-photo-wrap .account-settings-page .user-name {
    font-size: 35px;
    line-height: 1.2;
    font-weight: 500; }
  .upload-photo-wrap .account-settings-page .user-email {
    font-size: 16px;
    line-height: 1;
    color: #333333;
    margin: 10px 0 8px;
    display: none; }
  .upload-photo-wrap .account-settings-page .remove-photo {
    color: #008BEB;
    cursor: pointer;
    margin-bottom: 0; }
    .upload-photo-wrap .account-settings-page .remove-photo:hover {
      color: #005e9f;
      text-decoration: underline; }
  .upload-photo-wrap .account-settings-page .form-avatar {
    background-color: #E5E5E5;
    width: 100px;
    height: 100px;
    border: #E5E5E5 1px solid;
    margin-right: 24px; }
    .upload-photo-wrap .account-settings-page .form-avatar-img {
      max-width: 100%;
      max-height: 100%; }
      .upload-photo-wrap .account-settings-page .form-avatar-img .no-user-avatar {
        width: 79px; }
      .upload-photo-wrap .account-settings-page .form-avatar-img .is-user-avatar {
        width: auto;
        height: auto;
        max-height: 50px;
        max-width: 90px;
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%); }
    .upload-photo-wrap .account-settings-page .form-avatar:hover {
      border-color: #E5E5E5; }
  .upload-photo-wrap .account-settings-page .company-name {
    font-size: 16px;
    line-height: 1; }
    .upload-photo-wrap .account-settings-page .company-name:hover {
      text-decoration: none;
      color: #0074f0; }

.edit-icon {
  position: absolute;
  top: 18px;
  right: 18px; }
  .edit-icon__header {
    display: none; }
  .edit-icon .fa-edit {
    font-size: 19px;
    color: #008BEB; }
  .edit-icon a,
  .edit-icon a:hover {
    text-decoration: none; }

@media only screen and (max-width: 992px) {
  .upload-photo-wrap .form-avatar {
    width: 65px;
    height: 65px;
    margin-right: 12px; }
    .upload-photo-wrap .form-avatar-img .no-user-avatar {
      width: 37px; }
    .upload-photo-wrap .form-avatar-img .is-user-avatar {
      max-height: 45px;
      max-width: 55px; }
  .upload-photo-wrap .account-settings-page {
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start; }
    .upload-photo-wrap .account-settings-page .user-name {
      margin-top: 5px;
      font-size: 24px; }
    .upload-photo-wrap .account-settings-page .user-email {
      display: inline-block; }
  .account-employers-header {
    margin-bottom: 20px;
    position: relative; }
    .account-employers-header .upload-photo-wrap {
      max-width: calc(100% - 40px); }
  .edit-icon {
    top: 6px;
    right: 0; }
    .edit-icon__header {
      display: inline-block; }
    .edit-icon .fa-edit {
      font-size: 21px; } }

@media only screen and (max-width: 767px) {
  .upload-photo-wrap .account-settings-page .user-name {
    font-size: 16px;
    margin-top: 12px; }
  .upload-photo-wrap .account-settings-page .user-email {
    font-size: 14px; }
  .account-employers-header {
    margin-bottom: 15px; }
  .edit-icon {
    right: 15px; } }

.logos-slider {
  margin-top: 30px; }
  .logos-slider .logo-slide {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    width: 177px;
    height: 177px;
    background: #fff;
    margin: auto; }
    .logos-slider .logo-slide__img {
      width: 120px;
      height: auto;
      max-height: 120px; }
  .logos-slider .slick-prev {
    top: auto;
    bottom: -89px;
    left: calc(50% - 60px); }
  .logos-slider .slick-next {
    top: auto;
    bottom: -68px;
    right: calc(50% - 60px); }

.why-workmarket-slider,
.our-services-slider {
  display: none; }

.slider-employer-home .slick-dots {
  bottom: -35px;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  width: auto; }

.slider-employer-home .slick-dots li.slick-active button {
  position: relative; }

.slider-employer-home .slick-dots li.slick-active button:before {
  background: #6C757D; }

.slider-employer-home .slick-dots li button::before {
  content: '';
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #e0e0e0;
  opacity: 1; }

.slick-arrow {
  width: 45px;
  height: 43px;
  position: absolute;
  z-index: 1; }

.slick-prev {
  left: 44px; }

.slick-next {
  right: 44px;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg); }

.img-wrap {
  width: 100%; }
  .img-wrap img {
    width: 100%;
    height: auto; }

@media only screen and (max-width: 1199px) {
  .logos-slider {
    margin-top: 10px; }
    .logos-slider .logo-slide {
      width: 140px;
      height: 140px; }
      .logos-slider .logo-slide__img {
        width: 90px; } }

@media only screen and (max-width: 992px) {
  .logos-slider .logo-slide {
    width: 92px;
    height: 92px; }
    .logos-slider .logo-slide__img {
      width: 62px; } }

@media only screen and (max-width: 767px) {
  .our-services-slider {
    width: 100%;
    display: inline-block;
    margin-top: 15px; }
    .our-services-slider .slick-dots {
      bottom: -45px; }
    .our-services-slider .slick-arrow {
      top: auto;
      bottom: -80px;
      display: inline-block; }
    .our-services-slider .slick-next {
      bottom: -58px; }
  .our-services-item {
    width: 100%;
    min-height: 408px;
    padding: 11px 14px;
    border: 0.5px solid #E0E0E0 !important;
    -webkit-box-shadow: 0 5px 5px -5px rgba(34, 60, 80, 0.6);
    -moz-box-shadow: 0 5px 5px -5px rgba(34, 60, 80, 0.6);
    box-shadow: 0 5px 5px -5px rgba(34, 60, 80, 0.6); }
    .our-services-item__img {
      height: 80px;
      margin-bottom: 11px; }
    .our-services-item__text {
      line-height: 133%; }
  .why-workmarket-slider {
    display: inline-block;
    width: calc(100% - 30px);
    margin: 0 15px; }
    .why-workmarket-slider .slick-dots {
      bottom: -62px; }
    .why-workmarket-slider .slick-arrow {
      top: auto;
      bottom: -96px;
      display: inline-block; }
    .why-workmarket-slider .slick-next {
      bottom: -75px; }
  .why-workmarket-item {
    width: 100%;
    padding: 14px 15px 19px;
    margin-bottom: 0;
    min-height: 264px; }
    .why-workmarket-item__img {
      margin-bottom: 10px; }
    .why-workmarket-item__title {
      min-height: auto; }
    .why-workmarket-item__subtitle {
      min-height: 140px; }
  .logos-slider {
    width: calc(100% - 15px);
    margin-right: 0;
    margin-left: 15px; }
    .logos-slider .logo-slide {
      width: 177px;
      height: 177px; }
      .logos-slider .logo-slide .logo-slide__img {
        width: 120px; }
    .logos-slider .slick-slide {
      padding: 0 10px; }
    .logos-slider .slick-dots {
      bottom: -57px; }
    .logos-slider .slick-arrow {
      top: auto;
      bottom: -94px;
      display: inline-block; }
    .logos-slider .slick-next {
      bottom: -72px; }
  .slider-employer-home .slick-dots li {
    height: 6px;
    width: 6px;
    margin: 0 3px; }
    .slider-employer-home .slick-dots li button {
      height: 6px;
      width: 6px; }
    .slider-employer-home .slick-dots li button::before {
      width: 6px;
      height: 6px; }
  .slick-dotted.slick-slider {
    margin-bottom: 0; } }

.block-read-more .read-more {
  color: #008AEA;
  cursor: pointer; }

.block-read-more .read-more__close {
  display: none; }

.block-read-more.block-read-more__close .read-more__open {
  display: none; }

.block-read-more.block-read-more__close .read-more__close {
  display: inline-block; }

.radio-button {
  display: inline-block;
  margin-right: 20px; }

.radio-button input[type=radio] {
  display: none; }

.radio-button label {
  display: inline-block;
  cursor: pointer;
  padding: 0 15px;
  border: none;
  user-select: none;
  background: #F5F5F5;
  font-size: 13px;
  line-height: 30px;
  color: #6C757D !important;
  font-weight: 400;
  margin-bottom: 0; }

.radio-button input[type=radio]:checked + label {
  color: #fff !important;
  background: #02B538; }

.tooltip-block {
  position: relative;
  display: inline-block;
  cursor: pointer; }

.tooltip-wrap {
  position: absolute;
  bottom: calc(100% + 11px);
  left: 0;
  padding: 20px;
  width: 280px;
  background: #fff;
  border: 0.5px solid #E0E0E0;
  box-shadow: 0 4px 20px rgba(125, 125, 125, 0.25);
  font-size: 14px;
  color: #6C757D; }
  .tooltip-wrap .triangle {
    width: 16px;
    height: 16px;
    background: #fff;
    border: 0.5px solid #E0E0E0;
    border-top: none;
    border-left: none;
    position: absolute;
    bottom: -9px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg); }

.vacancy-management-block {
  margin-top: -20px;
  margin-bottom: 15px;
  padding: 17px 20px;
  background-color: #F7F7F7;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center; }
  .vacancy-management-block-buttons {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center; }
  .vacancy-management-block__btn {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    color: #008BEB;
    margin-right: 30px;
    border: none;
    background: none; }
    .vacancy-management-block__btn:hover {
      color: #0074f0; }
    .vacancy-management-block__btn:disabled {
      cursor: pointer;
      color: #70A1D6; }
    .vacancy-management-block__btn .material-icons, .vacancy-management-block__btn .material-icons-outlined, .vacancy-management-block__btn .material-icons-round, .vacancy-management-block__btn .material-icons-sharp, .vacancy-management-block__btn .material-icons-two-tone {
      font-size: 24px;
      margin-right: 5px; }
  .vacancy-management-block__select {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    color: #008BEB;
    position: relative; }
    .vacancy-management-block__select .form-control {
      border: none;
      background: none;
      color: #008BEB;
      z-index: 1;
      padding-left: 0;
      width: 310px;
      padding-right: 0;
      text-indent: 25px; }
      .vacancy-management-block__select .form-control option {
        color: #062336; }
    .vacancy-management-block__select__disabled {
      color: #70A1D6; }
      .vacancy-management-block__select__disabled .form-control {
        color: #70A1D6; }
    .vacancy-management-block__select .material-icons, .vacancy-management-block__select .material-icons-outlined, .vacancy-management-block__select .material-icons-round, .vacancy-management-block__select .material-icons-sharp, .vacancy-management-block__select .material-icons-two-tone {
      font-size: 24px;
      position: absolute;
      left: 0; }
  .vacancy-management-block__dropdown {
    line-height: 10px;
    display: none; }
    .vacancy-management-block__dropdown.disabled {
      color: #70A1D6; }
  .vacancy-management-block .checkbox-vacancy {
    border-right: 1px solid #008BEB;
    margin-right: 20px; }
    .vacancy-management-block .checkbox-vacancy label {
      margin-left: 30px; }
      .vacancy-management-block .checkbox-vacancy label:before {
        margin-right: 0;
        position: absolute;
        left: -30px;
        top: 50%;
        -webkit-transform: translate(0, -50%);
        -moz-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%); }
    .vacancy-management-block .checkbox-vacancy input:checked + label:after {
      left: -26px;
      top: 50%;
      -webkit-transform: translate(0, -50%);
      -moz-transform: translate(0, -50%);
      -ms-transform: translate(0, -50%);
      transform: translate(0, -50%); }

.checkbox-vacancy {
  display: inline-block; }
  .checkbox-vacancy input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer; }
    .checkbox-vacancy input:checked + label:before {
      background-color: #008BEB;
      border: 1px solid #008BEB; }
    .checkbox-vacancy input:checked + label:after {
      content: '';
      position: absolute;
      top: 4px;
      left: 4px;
      display: block;
      width: 11px;
      height: 11px;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-size: cover; }
  .checkbox-vacancy label {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    color: #008BEB !important;
    font-weight: 400;
    padding-right: 30px;
    margin-bottom: 0; }
    .checkbox-vacancy label:before {
      content: '';
      -webkit-appearance: none;
      background-color: #fff;
      border: 1px solid #e0e0e0;
      padding: 9px;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      margin-right: 10px; }

@media only screen and (max-width: 991px) {
  .text-for-btn {
    display: none; }
  .vacancy-management-block__btn {
    margin-right: 16px; }
  .vacancy-management-block__select {
    display: none; }
  .vacancy-management-block__dropdown {
    display: inline-block; } }

@media only screen and (max-width: 767px) {
  .vacancy-management-block {
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
    margin-top: -20px;
    margin-bottom: 15px;
    padding: 17px 11px;
    position: relative; }
    .vacancy-management-block:before {
      content: '';
      width: 5px;
      height: 100%;
      background-color: #F7F7F7;
      position: absolute;
      top: 0;
      left: -5px; }
    .vacancy-management-block:after {
      content: '';
      width: 5px;
      height: 100%;
      background-color: #F7F7F7;
      position: absolute;
      top: 0;
      right: -5px; }
    .vacancy-management-block-fixed {
      position: fixed;
      top: 71px;
      left: 0;
      z-index: 4;
      width: 100%;
      -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
      -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3); }
      .vacancy-management-block-fixed:before, .vacancy-management-block-fixed:after {
        display: none; }
    .vacancy-management-block__btn {
      margin-right: 9px; }
    .vacancy-management-block .checkbox-vacancy {
      border-right: none;
      margin-right: 0; }
  .checkbox-vacancy label {
    padding-right: 0; }
    .checkbox-vacancy label:before {
      margin-right: 14px; } }

.sign-form.registration input.radio-button {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  margin: 0;
  cursor: pointer; }

.sign-form.registration .radio-tile-group {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: flex-start;
  margin: 15px 0 20px;
  position: relative; }
  .sign-form.registration .radio-tile-group:before {
    content: '';
    height: 1px;
    width: 100%;
    background: #008BEB;
    position: absolute;
    left: 0;
    bottom: 1px; }
  .sign-form.registration .radio-tile-group .input-container {
    position: relative;
    margin: 0 40px 0 0; }
    .sign-form.registration .radio-tile-group .input-container .radio-tile {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      transition: transform .3s ease; }
      .sign-form.registration .radio-tile-group .input-container .radio-tile label {
        color: #008BEB !important;
        margin: 0;
        padding: 6px 13px;
        line-height: 38px;
        font-size: 16px;
        font-weight: 400;
        width: 100%;
        text-align: center; }

.sign-form.registration .radio-tile-group .input-container .radio-button:checked + .radio-tile .radio-tile-label {
  color: #062336 !important;
  border-bottom: 4px solid #008BEB; }

@media only screen and (max-width: 767px) {
  .sign-form.registration .radio-tile-group {
    margin: 6px 0 11px; }
    .sign-form.registration .radio-tile-group .input-container {
      margin: 0;
      width: 50%; }
      .sign-form.registration .radio-tile-group .input-container .radio-tile label {
        padding: 6px 13px;
        line-height: 42px;
        font-size: 14px; } }

.faq-items {
  padding: 0 20px;
  min-height: 342px; }
  .faq-items-link {
    border-top: #F0F0F0 2px solid;
    display: block;
    position: relative; }
    .faq-items-link:last-child {
      padding-bottom: 0; }
    .faq-items-link:hover, .faq-items-link:focus {
      text-decoration: none;
      color: #008BEB; }
    .faq-items-link-arrow {
      background-image: url("/front/images/icon-arrow.png");
      width: 12px;
      height: 7px;
      background-size: 100%;
      position: absolute;
      right: 10px;
      top: 20px;
      transition: all .5s ease; }
    .faq-items-link[aria-expanded="true"] .faq-items-link-arrow {
      transform: rotate(180deg); }
    .faq-items-link-num {
      font-size: 20px;
      font-weight: 600;
      color: #96CBF2;
      padding-right: 10px; }
  .faq-items-collapse {
    padding: 10px 32px 20px 32px; }

.faq-menu {
  margin: -20px; }
  .faq-menu-link {
    display: block;
    border-top: #F0F0F0 2px solid;
    padding: 20px 34px;
    font-size: 16px;
    display: block;
    width: 100%; }
    .faq-menu-link:hover, .faq-menu-link:focus {
      text-decoration: none;
      color: #008BEB; }
    .faq-menu-link-active {
      color: #008BEB;
      border-left: #96CBF2 8px solid;
      cursor: default;
      padding-left: 26px; }
      .faq-menu-link-active:hover {
        color: #008BEB; }
    .faq-menu-link:first-child {
      border-top: none; }

.faq-page {
  padding: 95px 0 20vh; }
  .faq-page-title {
    font-size: 22px;
    color: #062336;
    text-align: center;
    margin-bottom: 30px; }

.tabs-faq-applicant-company {
  border: none;
  margin-bottom: 30px;
  text-align: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  .tabs-faq-applicant-company .nav-link {
    padding: 0.5rem 4rem;
    border: 1px solid #0570B5;
    color: #0570B5;
    border-radius: 3px;
    margin: 0 5px; }
  .tabs-faq-applicant-company .nav-link.active {
    border: 1px solid #0570B5;
    background-color: #0077C3;
    color: #fff; }

.tab-content .tabs-question-answers-categories {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between; }

.tabs-question-categories {
  width: 100%;
  max-width: 380px;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border: 1px solid #F0F0F0; }
  .tabs-question-categories .nav-link {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    color: #14669B;
    padding: 0 5px 0 0;
    border-bottom: 1px solid #F0F0F0;
    border-radius: 0; }
    .tabs-question-categories .nav-link.active {
      border-bottom: 1px solid #F0F0F0;
      border-top: 1px solid #F0F0F0;
      border-left: none; }
      .tabs-question-categories .nav-link.active .bor-l {
        visibility: visible; }
  .tabs-question-categories .nav-item:nth-last-child(1) .nav-link {
    border-bottom: none; }
  .tabs-question-categories .bor-l {
    display: inline-block;
    height: 65px;
    width: 6px;
    background-color: #96CBF2;
    margin-right: 29px;
    visibility: hidden; }

.tabs-answers-categories {
  width: calc(100% - 405px);
  border: 1px solid #F0F0F0;
  padding: 0 20px; }
  .tabs-answers-categories .tab-title {
    font-weight: 600;
    padding: 22px 0;
    margin: 0; }

.list-question-answers {
  padding: 0;
  list-style-type: none;
  margin: 0; }
  .list-question-answers .item-question-title {
    border-top: 1px solid #F0F0F0;
    cursor: pointer;
    padding: 17px 10px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between; }
    .list-question-answers .item-question-title p {
      margin: 0 5px 0 0;
      color: #14669B;
      font-weight: 600; }
      .list-question-answers .item-question-title p span {
        font-size: 20px;
        font-weight: 600;
        color: #96CBF2;
        margin-right: 20px; }
  .list-question-answers .fa-angle-left {
    color: #14669B;
    font-size: 15px;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg); }
  .list-question-answers .active .fa-angle-left {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg); }
  .list-question-answers .active > p {
    display: inline-block; }
  .list-question-answers li > p {
    margin: 0;
    padding: 25px 10px 25px 47px;
    display: none; }

@media only screen and (max-width: 991px) {
  .tabs-question-categories {
    max-width: 290px; }
  .tabs-answers-categories {
    width: calc(100% - 300px);
    padding: 0 10px; }
  .list-question-answers li > p {
    padding: 10px 10px 5px 37px; }
  .list-question-answers .item-question-title {
    padding: 10px;
    -webkit-align-items: baseline;
    -moz-align-items: baseline;
    -ms-align-items: baseline;
    align-items: baseline; }
    .list-question-answers .item-question-title p span {
      margin-right: 10px;
      font-size: 15px; } }

@media only screen and (max-width: 767px) {
  .tabs-faq-applicant-company .nav-link {
    padding: 0.5rem 3rem; }
  .tab-content .tabs-question-answers-categories {
    -webkit-flex-direction: column-reverse;
    -moz-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center; }
  .tabs-answers-categories,
  .tabs-question-categories {
    width: 100%;
    max-width: 100%; }
  .list-question-answers .item-question-title {
    padding: 10px 0; }
  .list-question-answers li > p {
    padding-left: 0; }
  .faq-page-title {
    font-size: 20px; }
  .tabs-question-categories {
    margin-bottom: 15px; }
    .tabs-question-categories .bor-l {
      height: 45px; }
  .tabs-answers-categories .tab-title {
    padding: 12px 0; } }

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .tabs-faq-applicant-company {
    -webkit-flex-direction: column-reverse;
    -moz-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    margin-bottom: 10px; }
    .tabs-faq-applicant-company .nav-item {
      width: 100%;
      margin-bottom: 10px; } }

.user-menu {
  left: auto;
  right: 20em; }

#navbar-brand-img-bee-small {
  width: 40px;
  display: none; }

.btn-height {
  height: 28px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex; }

.navbar-custom-wallet {
  padding: 0 2px 0 10px;
  line-height: 15px;
  position: relative;
  display: inline-block; }
  .navbar-custom-wallet svg {
    width: 17px;
    position: absolute;
    top: 5px; }
  .navbar-custom-wallet-amount {
    padding: 0 6px 0 10px; }
  .navbar-custom-wallet-add {
    display: inline-block;
    border-left: #ffffff 1px solid;
    padding: 4px 7px 7px; }
  .navbar-custom-wallet:hover {
    text-decoration: none;
    background-color: transparent; }
    .navbar-custom-wallet:hover .navbar-custom-wallet-add {
      text-decoration: underline;
      background-color: transparent; }

.user-menu-dropdown {
  border-radius: 3px;
  background-color: #fff;
  position: absolute;
  left: 50%;
  top: 50px;
  cursor: auto;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  max-width: 454px;
  width: 100%; }
  .user-menu-dropdown-header {
    padding: 8px 15px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between; }
    .user-menu-dropdown-header .img-wrap {
      width: 31px;
      height: 31px;
      margin-right: 23px;
      border-radius: 50%;
      overflow: hidden; }
      .user-menu-dropdown-header .img-wrap img {
        width: 100%;
        height: auto;
        min-height: 100%; }

.block-img-name {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center; }

.user-email {
  color: rgba(94, 94, 94, 0.87);
  font-size: 0.625rem; }

.dropdown-header-user-name {
  font-size: 1rem;
  line-height: 1; }
  .dropdown-header-user-name:hover {
    color: #0074f0; }

.icon-btn-exit {
  margin-right: 5px;
  font-size: 13px; }

.btn-exit {
  border: none;
  background: none;
  color: #2681BD;
  font-size: 0.875rem; }

.list-user-settings {
  width: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0; }
  .list-user-settings li {
    width: 50%;
    border-top: 1px solid #F0F0F0;
    border-right: 1px solid #F0F0F0;
    list-style-type: none;
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center; }
  .list-user-settings li:nth-child(even) {
    border-left: none;
    border-right: none; }
  .list-user-settings-title {
    margin: 0;
    width: auto;
    padding: 15px 0 15px 75px;
    color: rgba(94, 94, 94, 0.87);
    text-decoration: none;
    font-size: 0.875rem; }
    .list-user-settings-title:hover {
      text-decoration: none; }

.list-user-img {
  font-size: 21px;
  position: absolute;
  left: 25px;
  top: 17px;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  color: rgba(94, 94, 94, 0.87); }

.navbar li {
  list-style-type: none; }
  .navbar li .number-response {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: #008BEB;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    margin-left: 5px; }
    .navbar li .number-response p {
      color: #fff;
      font-size: 9px;
      margin-bottom: 0; }

.navbar .nav-item-active {
  position: relative; }
  .navbar .nav-item-active:before {
    content: '';
    height: 1px;
    width: calc(100% - 1.6rem);
    background-color: #fff;
    position: absolute;
    bottom: -2px;
    left: .8rem; }

.navbar .nav-item:nth-child(1):before {
  width: calc(100% - .8rem);
  left: 0; }

.block-account-balance {
  padding: 7px 13px;
  background-color: #EBF6FF;
  width: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between; }

.account-balance-text {
  font-size: 10px;
  color: #2681BD;
  margin-bottom: 0; }
  .account-balance-text span {
    font-size: 14px;
    font-weight: 700; }

.btn-menu-blue {
  font-size: 10px;
  color: #007BFF;
  background: none;
  border: 1px solid #007BFF;
  border-radius: 4px;
  line-height: 24px;
  padding: 0 9px; }

.on-mob-viz {
  display: none !important; }
  .on-mob-viz .user-menu-dropdown-header {
    padding: 5px 25px 20px 10px; }
  .on-mob-viz .block-account-balance {
    padding: 4px 13px; }

.btn-dropdown-menu {
  max-width: 82px; }

.menu-mob {
  width: 100%; }

.search-on-mob {
  width: 100%;
  padding-top: 10px; }
  .search-on-mob .input-group {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
  .search-on-mob .on-mob-100,
  .search-on-mob .dropdown-header-user-name {
    width: 100% !important;
    margin-bottom: 5px; }

.for-mob-landscape {
  display: none !important; }
  .for-mob-landscape .user-email {
    margin-bottom: 0; }
  .for-mob-landscape .list-user-settings-title {
    line-height: 31px; }
  .for-mob-landscape .user-menu-dropdown-header {
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    padding: 4px 15px; }
  .for-mob-landscape .block-account-balance {
    padding: 3px 13px; }
  .for-mob-landscape .list-user-settings li {
    border-left: 1px solid #F0F0F0; }

.bg-white {
  background-color: #fff; }

.menu-desktop {
  right: 16px;
  width: 100%;
  max-width: 525px; }

.no-arrow::after {
  display: none; }

.choices:last-child {
  margin-bottom: 1rem; }

.user-email {
  margin-bottom: 0; }

.dropdown-header-user-name {
  margin-bottom: 5px; }

.dropdown-menu {
  border-radius: 0; }

@media (min-width: 992px) {
  .list-user-settings li:focus, .list-user-settings li:hover {
    background-color: #EBF6FF; }
    .list-user-settings li:focus .list-user-settings-title, .list-user-settings li:hover .list-user-settings-title {
      color: rgba(94, 94, 94, 0.87); } }

@media only screen and (max-width: 991px) {
  .user-menu-dropdown {
    max-width: 310px; }
    .user-menu-dropdown-header .img-wrap {
      width: 42px;
      height: 42px;
      margin-right: 11px; }
  .list-user-settings {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
    .list-user-settings-title {
      line-height: 25px;
      padding-left: 64px;
      padding-top: 0;
      padding-bottom: 0;
      width: 100%; }
      .list-user-settings-title:hover {
        color: #fff !important; }
    .list-user-settings li {
      width: 100%;
      border-left: none;
      padding: 5px 0;
      border-top: none; }
      .list-user-settings li i {
        padding-left: 7px; }
      .list-user-settings li .fa-users {
        left: 13px; }
      .list-user-settings li .fa-building,
      .list-user-settings li .fa-file-alt,
      .list-user-settings li .fa-file-invoice-dollar {
        left: 18px; }
      .list-user-settings li.active-link {
        background-color: #2B73B4; }
      .list-user-settings li:focus, .list-user-settings li:hover {
        background-color: #2B73B4; }
      .list-user-settings li.on-mob-viz {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important; }
  .user-menu-dropdown-header {
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center; }
  .list-user-img {
    left: 15px;
    color: #fff;
    font-size: 16px;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%); }
  .on-mob-hide,
  .on-mob-por-hide {
    display: none !important; }
  .on-mob-viz {
    display: inline-block !important; }
    .on-mob-viz .user-menu-dropdown-header {
      padding: 5px 10px 20px;
      border-bottom: 1px solid #B3D6FC; }
  .link-line {
    border-bottom: 1px solid #B3D6FC;
    margin-bottom: 10px; }
  .navbar-nav {
    background-color: #008BEB;
    max-height: calc(100vh - 51px);
    width: calc(100% + 10px);
    margin-left: -5px;
    overflow: scroll;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-bottom: 15px;
    padding-top: 6px; }
    .navbar-nav.navbar-nav-employer {
      background: #48a8fa;
      background: -o-linear-gradient(23deg, #48a8fa 0%, #1b7cd1 55%, #9a80e7 100%, #9373e2 100%);
      background: linear-gradient(67deg, #48a8fa 0%, #1b7cd1 55%, #9a80e7 100%, #9373e2 100%); }
    .navbar-nav .btn {
      min-width: 120px;
      width: auto;
      padding: 4px 10px;
      border-radius: 0 !important; }
    .navbar-nav .on-mob-viz .btn {
      margin-right: 0; }
    .navbar-nav .nav-item {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: baseline;
      -moz-align-items: baseline;
      -ms-align-items: baseline;
      align-items: baseline;
      position: relative;
      padding: 5px 0 !important;
      width: 100%; }
      .navbar-nav .nav-item .items-for-mob {
        color: #fff;
        font-size: 17px;
        width: 1.25em;
        left: 10px;
        padding-left: 7px;
        position: absolute; }
      .navbar-nav .nav-item .nav-link {
        line-height: 25px;
        padding-left: 64px !important;
        margin: 0;
        width: 100%; }
  .on-mob-width {
    width: 100%; }
  .user-email {
    color: #fff; }
  .navbar .nav-link,
  .btn-exit,
  .dropdown-header-user-name,
  .user-email,
  .list-user-settings-title {
    color: #fff; }
  .dropdown-header-user-name {
    text-decoration: underline; }
    .dropdown-header-user-name:hover {
      color: #fff; }
  .navbar-top > .container {
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    -ms-justify-content: flex-end;
    justify-content: flex-end;
    -ms-flex-pack: flex-end;
    padding-left: 10px;
    padding-right: 10px; }
    .navbar-top > .container .list-unstyled,
    .navbar-top > .container > div {
      width: 33.3%;
      text-align: center; }
  .navbar-top .fa-globe {
    font-size: 20px; }
  .btn-height {
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center; }
  .navbar {
    padding: 0; }
    .navbar .navbar-toggler {
      position: absolute;
      top: -36px;
      padding-left: 10px; }
      .navbar .navbar-toggler .fa-user {
        font-size: 19px; }
    .navbar li .number-response {
      background-color: #fff;
      width: 16px;
      height: 16px; }
      .navbar li .number-response p {
        color: #008AEA;
        font-size: 8px;
        margin-left: -1px; }
  .width-on-mob {
    width: 100%; }
  .btn-exit .icon-btn-exit {
    font-size: 19px; }
  .block-img-name {
    padding-left: 0; } }

@media only screen and (max-width: 991px) and (orientation: portrait) {
  .list-user-settings li {
    border-right: none; } }

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .navbar-top {
    padding: 5px 0 !important; }
    .navbar-top .container > div {
      width: 50%;
      position: relative;
      transform: translateX(-15%); }
    .navbar-top .container .list-unstyled {
      width: auto;
      -webkit-flex-direction: row-reverse;
      -moz-flex-direction: row-reverse;
      -ms-flex-direction: row-reverse;
      flex-direction: row-reverse; }
  .navbar .navbar-toggler {
    top: -41px; }
  .on-mob-portrait p {
    display: none; }
  .on-mob-portrait .btn-height {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    width: 29px;
    min-width: auto;
    padding: 0; }
  .navbar-nav .on-mob-viz .btn {
    margin-right: 1.5px; } }

@media only screen and (max-width: 815px) and (orientation: landscape) {
  .list-user-settings {
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
    .list-user-settings li {
      width: 50%; } }

@media only screen and (max-height: 500px) and (orientation: landscape) {
  .list-user-settings-title {
    padding-left: 54px; }
  .list-user-settings li {
    border-right: none; }
    .list-user-settings li i {
      padding-left: 0; }
    .list-user-settings li .fa-users {
      left: 10px; }
  .link-line {
    width: 100% !important; }
  .navbar .navbar-toggler {
    top: -39px;
    padding-left: 0; }
  .navbar-top {
    height: 50px; }
    .navbar-top .navbar-brand {
      padding-top: 0; }
    .navbar-top .lang-icon {
      padding-top: 0;
      padding-bottom: 0;
      margin-top: -9px; }
    .navbar-top .container {
      padding-left: 0;
      padding-right: 0; }
  .on-mob-viz .user-menu-dropdown-header {
    padding-left: 0;
    padding-right: 0; }
  .navbar-nav .on-mob-viz .btn {
    margin-right: 0.5px; }
  .navbar-nav .nav-item {
    padding-left: 31px; }
    .navbar-nav .nav-item .items-for-mob {
      left: 5px; }
    .navbar-nav .nav-item .nav-link {
      padding-left: 54px !important; } }

@media only screen and (max-height: 320px) and (orientation: landscape) {
  .navbar-nav .on-mob-viz .btn {
    margin-right: 10px; }
  .navbar-nav .on-mob-viz .user-menu-dropdown-header {
    padding-left: 10px; }
  .navbar-top .container {
    padding-left: 4px;
    padding-right: 2px; }
  .navbar .navbar-toggler {
    padding-left: 10px; }
  .navbar-top ul .lang-icon {
    padding-right: 8px; }
  .list-user-settings {
    padding-left: 10px; } }

.mailing-list {
  border: #f0f0f0 1px solid;
  font-size: 12px;
  border-bottom: none;
  border-right: none; }
  .mailing-list-item {
    display: flex;
    flex-flow: row wrap;
    border-bottom: none; }
    .mailing-list-item-left {
      border-right: #f0f0f0 1px solid;
      padding: 0;
      text-align: center;
      flex-grow: 1;
      display: flex;
      justify-content: center;
      border-bottom: #f0f0f0 1px solid; }
      .mailing-list-item-left .el-switch {
        width: initial; }
    .mailing-list-item-center {
      padding: 5px 15px 10px;
      flex-grow: 5;
      border-bottom: #f0f0f0 1px solid;
      border-right: #f0f0f0 1px solid; }
    .mailing-list-item-right {
      padding: 0;
      flex-grow: 1;
      border-bottom: #f0f0f0 1px solid;
      padding: 0 5px 15px;
      border-right: #f0f0f0 1px solid; }
    .mailing-list-item-date {
      position: absolute;
      top: 0;
      right: 10px; }

/*
.separator {
	margin-top: 20px;
	margin-bottom: 20px;
	border-bottom: 1px solid #d2d2d1;
}
*/
.step-inactive-color {
  color: #d2d2d1; }

.step-active-color {
  color: #2581bc; }

.step-inactive-border {
  border: 2px solid #d2d2d1; }

.step-active-border {
  border: 2px solid #2581bc; }

.step-icon-container {
  padding-left: 11px;
  padding-top: 11px;
  width: 5em;
  height: 5em;
  line-height: 3;
  border-radius: 50%;
  background: #fff;
  float: left; }

.steps-container {
  margin-left: 2em; }

.step {
  margin-left: -3.7em;
  padding-left: 1em; }

.step-last {
  margin-left: -3.7em;
  padding-left: 19px; }

.step-wrapper {
  border-left: 4px dotted #d2d2d1; }

.step-content h2 {
  margin: 0 0 0 16px;
  line-height: 2.2em;
  text-transform: uppercase;
  border-bottom: 1px solid #d2d2d1; }

.step-content {
  padding-left: 6em;
  padding-top: 1em; }

.step-icon {
  font-size: 28px;
  margin-left: 8px;
  margin-top: 5px; }

.step-wrapper:nth-child(2) .step-icon {
  margin-top: 9px;
  margin-left: 7px;
  font-size: 25px; }

.step-header {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 15px;
  border-bottom: 1px solid #D2D2D1; }

.pricing-type {
  list-style-position: inside;
  padding: 0;
  list-style-type: square; }
  .pricing-type li {
    margin-bottom: 20px; }

#pricing-params {
  width: 100%; }

#pricing-days {
  width: 70px; }

#price-value {
  width: 90px;
  text-align: center;
  padding: 7px;
  border: 1px solid #d2d2d1;
  border-radius: 3px;
  color: #409C09;
  font-weight: bold;
  background-color: #DFF0D8; }

#preview-button {
  text-align: center;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #d2d2d1; }

.star-rating {
  font-size: 0; }

.star-rating__wrap {
  display: flex;
  font-size: 1rem;
  border: 1px solid #d2d2d1;
  border-radius: 3px;
  padding: 8px 0 0;
  width: 118px;
  display: flex;
  justify-content: center;
  flex-direction: row-reverse; }

.star-rating__wrap:after {
  content: "";
  display: table;
  clear: both; }

.star-rating__ico {
  float: right;
  padding-left: 2px;
  cursor: pointer;
  color: #FFB300; }

.star-rating__ico:last-child {
  padding-left: 0; }

.star-rating__input {
  display: none; }

.star-rating__ico:hover:before,
.star-rating__ico:hover ~ .star-rating__ico:before,
.star-rating__input:checked ~ .star-rating__ico:before {
  content: "\f005";
  font-weight: 900; }

#label-star-rating-1, #label-star-rating-2,
#label-star-rating-3, #label-star-rating-4,
#label-star-rating-5 {
  font-size: 18px; }

.offers-list-rating {
  color: #FFC80E;
  font-size: 10px; }

.required-field-label {
  margin-bottom: 3px; }

#paymentType .insufficient {
  display: none;
  color: #B91912;
  font-size: 11px;
  margin-left: 6px; }
  #paymentType .insufficient i.fa {
    color: #B91912;
    font-size: 11px; }

.payOnBehalf {
  display: none; }

.invoiceFields {
  display: none;
  clear: both; }
  .invoiceFields input[name=zip] {
    margin-bottom: 18px; }

.custom-pricing-pic {
  width: 100%;
  height: 170px;
  background: url("/front/images/pricing-form.png") center no-repeat;
  position: relative;
  padding-top: 30px; }

.custom-pricing-wantmore {
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  background: #116da8;
  padding: 4px 14px; }

.custom-pricing-text {
  color: #2581bc;
  font-size: 20px; }

.company {
  padding: 40px; }
  .company-address {
    line-height: 20px; }
  .company .offers-list-item .offers-list-name {
    font-weight: 400; }
  .company-list-item {
    padding: 16px 26px;
    height: 82px;
    background-color: #FAFAFA;
    border: #FAFAFA 1px solid; }
    .company-list-item > div {
      height: 100%; }
    .company-list-item-center {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center; }
    .company-list-item-icon {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background-color: #F0F0F0;
      border: #D5D5D5 1px solid;
      background-image: url("/images/icon-house.svg");
      background-position: center;
      background-size: 60%;
      background-repeat: no-repeat; }
      .company-list-item-icon.cover {
        background-size: cover; }
    .company-list-item-head {
      color: #008BEB;
      font-weight: 600; }
    .company-list-item-text {
      color: #5E5E5E; }
    .company-list-item-approved {
      cursor: pointer;
      position: relative;
      background-image: url("/images/icon-check-circle.svg");
      background-position: center;
      width: 12px;
      height: 12px;
      display: inline-block;
      background-size: 100%;
      margin-left: 15px; }
      .company-list-item-approved._warning {
        background-image: url("/images/icon-warning.svg");
        height: 11px; }
      .company-list-item-approved-text {
        color: #fff;
        position: absolute;
        top: 16px;
        left: 20px;
        font-size: 10px;
        display: none;
        background-color: #616161;
        opacity: .9;
        padding: 5px 6px;
        font-style: normal;
        font-weight: 400;
        border-radius: 3px; }
      .company-list-item-approved:hover .company-list-item-approved-text {
        display: block; }
    .company-list-item-rating {
      text-align: right; }
    .company-list-item-stars {
      color: #2581bc; }
  .company-rating {
    color: #fff;
    background-color: #2581bc;
    padding: 3px;
    font-size: 9px;
    vertical-align: middle; }
    .company-rating.gray {
      background-color: #D6D6D6; }
  .company-rating-active {
    color: #fff;
    background-color: #2581bc;
    padding: 3px;
    font-size: 9px;
    vertical-align: middle; }
  .company-input-search .input-group-btn .btn {
    padding: 7px 10px 6px;
    background: #fff;
    border: 1px solid #ccc;
    border-left: none; }
    .company-input-search .input-group-btn .btn i {
      padding: 0;
      background: #fff;
      color: #707070;
      font-size: 18px; }
  .company-main-icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #F0F0F0;
    border: #F0F0F0 1px solid;
    margin-right: 10px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center; }
    .company-main-icon .fa-user {
      color: #000;
      font-size: 10px; }
    .company-main-icon.cover {
      background-size: cover; }
  .company-main-approved {
    position: relative;
    background-image: url("/images/icon-check-circle.svg");
    background-position: center;
    width: 18px;
    height: 18px;
    display: inline-block;
    background-size: 100%;
    vertical-align: middle; }
  .company-main-stars {
    cursor: default;
    text-align: left;
    float: left; }
    .company-main-stars-item {
      padding-left: 3px; }
      .company-main-stars-item .company-rating {
        font-size: 12px;
        background-color: #D6D6D6; }
      .company-main-stars-item .company-rating-active {
        font-size: 12px;
        background-color: #2581bc; }
    .company-main-stars > fieldset {
      border: none;
      display: inline-block;
      direction: rtl; }
      .company-main-stars > fieldset:not(:checked) > input {
        position: absolute;
        top: 0px;
        clip: rect(0, 0, 0, 0); }
      .company-main-stars > fieldset:not(:checked) > label {
        float: right;
        width: 24px;
        cursor: pointer; }
        .company-main-stars > fieldset:not(:checked) > label:hover i,
        .company-main-stars > fieldset:not(:checked) > label:hover ~ label i {
          background-color: #2581bc; }
      .company-main-stars > fieldset > input:checked ~ label i {
        background-color: #2581bc; }
      .company-main-stars > fieldset > label:active {
        position: relative;
        top: 2px; }
  .company-main-rates {
    font-size: 12px;
    margin-top: 2px;
    margin-left: 8px;
    float: left; }
  .company-main-add {
    border: #337aa8 1px solid;
    padding: 5px 20px;
    border-radius: 2px;
    float: right; }
    .company-main-add span {
      vertical-align: middle;
      font-size: 14px; }
    .company-main-add:hover {
      text-decoration: none; }
  .company-main-tabs .nav-tabs.nav-justified li {
    border-top: none; }
    .company-main-tabs .nav-tabs.nav-justified li:first-child {
      border-left: none; }
    .company-main-tabs .nav-tabs.nav-justified li:last-child {
      border-right: none; }
    .company-main-tabs .nav-tabs.nav-justified li.active {
      border-top: none;
      border-bottom: #2581BC 4px solid; }
    .company-main-tabs .nav-tabs.nav-justified li a {
      padding: 10px; }
  .company-reviews {
    /*&-leavecomment {
        background-image: url('/images/icon-arrow.svg');
        background-size: 16px;
        background-position: 16px center;
        padding-left: 46px;
        background-repeat: no-repeat;
    }*/
    /*&-report {
        background-image: url('/images/icon-comments.svg');
        padding: 2px 11px;
        margin: 0 10px;
        position: relative;
        &-text {
            display: none;
        }
        &:hover {
            .company-reviews-report-text {
                display: block;
                position: absolute;
                padding: 4px 8px;
                background-color: #616161;
                color: #ffffff;
                font-size: 10px;
                top: -26px;
                left: -56px;
                border-radius: 3px;
            }

        }
    }*/ }
    .company-reviews-spanno {
      background-color: #008BEB;
      color: #fff;
      border-radius: 3px;
      padding: 3px 5px; }
    .company-reviews-spanyes {
      background-color: #DC3545;
      color: #fff;
      border-radius: 3px;
      padding: 3px 5px; }
    .company-reviews-item-header {
      background-color: #EBF6FE;
      padding: 12px; }
      .company-reviews-item-header:hover {
        cursor: pointer; }
      .company-reviews-item-header .company-list-item-stars {
        float: right;
        line-height: 17px;
        font-weight: 800; }
      .company-reviews-item-header .company-rating {
        font-size: 7px;
        vertical-align: top;
        margin-top: 2px;
        margin-right: 1.5px; }
      .company-reviews-item-header .company-rating-active {
        font-size: 7px;
        vertical-align: top;
        margin-top: 2px;
        margin-right: 1.5px; }
      .company-reviews-item-header-time {
        margin-left: 17px; }
    .company-reviews-item-stars {
      float: right; }
    .company-reviews-item._open .company-reviews-item-header {
      background-color: #B8DDF8;
      border: #92C0FF 1px solid; }
    .company-reviews-likes {
      color: #00B955; }
      .company-reviews-likes.disable {
        color: 0.65; }
    .company-reviews-dislikes {
      color: #DC3545; }
      .company-reviews-dislikes.disable {
        color: 0.65; }
    .company-reviews-plus {
      border-left: #00B955 3px solid;
      padding-left: 13px; }
    .company-reviews-minus {
      border-left: #DC3545 3px solid;
      padding-left: 13px; }
    .company-reviews-likes, .company-reviews-dislikes {
      margin: 0 10px;
      cursor: pointer; }
      .company-reviews-likes i, .company-reviews-dislikes i {
        font-size: 17px; }
    .company-reviews .company-main-icon {
      width: 25px;
      height: 25px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 19px; }
    .company-reviews-comment-employer .company-reviews-item-content {
      padding: 0 16px 0 60px; }
    .company-reviews-comment-employer-header {
      padding: 8px 16px;
      line-height: 26px; }
    .company-reviews-comment-user {
      border-top: #D5D5D5 1px solid; }
      .company-reviews-comment-user .company-reviews-item-content {
        padding: 0 0 0 60px; }
      .company-reviews-comment-user-header {
        padding: 26px 16px 0px 60px; }
    .company-reviews-media-item {
      margin-bottom: 5px; }
    .company-reviews-yourcomment {
      background-color: #F0F0F0;
      padding: 14px 28px 8px;
      border: #D5D5D5 1px solid; }
      .company-reviews-yourcomment-close {
        background-image: url("/images/icon-close-dark.svg");
        width: 18px;
        height: 18px;
        float: right; }
      .company-reviews-yourcomment-line {
        width: 100%;
        background-image: url("/images/comment-line.svg");
        padding-bottom: 3%;
        background-repeat: no-repeat;
        background-position: center; }
      .company-reviews-yourcomment-send {
        font-size: 12px;
        padding: 4px 10px;
        min-width: 76px;
        border-radius: 3px; }
  .company-addreview {
    padding: 20px;
    /*		&-buttonyes {
                color: #36C004;
                border-radius: 3px;
                padding: 3px 8px;
                display: inline-block;
                text-align: center;
                border: 1px solid #36C004;

                &-selected {
                    background-color: #F0F0F0;
                    color: #36C004;
                    border-radius: 3px;
                    padding: 3px 8px;
                    display: inline-block;
                    text-align: center;
                }
            }
            &-buttonno {
                color: #FE5E00;
                border-radius: 3px;
                padding: 3px 8px;
                display: inline-block;
                text-align: center;
                border: 1px solid #FE5E00;

                &-selected {
                    background-color: #F0F0F0;
                    color: #FE5E00;
                    border-radius: 3px;
                    padding: 3px 8px;
                    display: inline-block;
                    text-align: center;
                }
            }*/
    /*		&-recommend {
                fieldset {
                    display: inline-block;
                    position: relative;
                    [type="radio"]:checked,
                    [type="radio"]:not(:checked) {
                        position: absolute;
                        top: 0px;
                        clip: rect(0,0,0,0);
                    }

                    [type="radio"]:checked + label,
                    [type="radio"]:not(:checked) + label {
                        position: relative;
                        cursor: pointer;
                        line-height: 20px;
                        display: inline-block;
                    }
                    [type="radio"]:checked + label.company-addreview-buttonyes {
                        color: #ffffff;
                        background-color: #36C004;
                        margin-right: 10px;
                    }
                    [type="radio"]:checked + label.company-addreview-buttonno {
                        color: #ffffff;
                        background-color: #FE5E00;
                        margin-left: 10px;
                    }
                }
            }*/ }
    .company-addreview label {
      font-weight: 400;
      margin-bottom: 1px; }
    .company-addreview .form-group {
      margin-bottom: 12px; }
    .company-addreview-info, .company-addreview-positive, .company-addreview-negative {
      padding: 4px 16px; }
    .company-addreview-info {
      background-color: #EBF6FE; }
    .company-addreview-positive {
      background-color: #EBFEEE; }
    .company-addreview-negative {
      background-color: #FEECEB; }
    .company-addreview-spanyes {
      background-color: #0CAA41;
      color: #fff;
      padding: 3px 7px;
      border-radius: 3px;
      min-width: 46px;
      display: inline-block;
      text-align: center; }
      .company-addreview-spanyes:hover {
        color: #fff;
        text-decoration: none; }
    .company-addreview-spanno {
      color: #fff;
      background-color: #D80614;
      padding: 3px 9px;
      border-radius: 3px;
      min-width: 46px;
      display: inline-block;
      text-align: center; }
      .company-addreview-spanno:hover {
        color: #fff;
        text-decoration: none; }
    .company-addreview-addphoto {
      display: inline-block;
      background-image: url("/images/icon-photo.svg");
      padding: 9px 11px;
      vertical-align: middle; }
    .company-addreview-addvideo {
      display: inline-block;
      background-image: url("/images/icon-video.svg");
      padding: 13px;
      vertical-align: middle; }
    .company-addreview-linkvideo {
      display: inline-block;
      width: 160px;
      vertical-align: middle; }
      .company-addreview-linkvideo .form-control {
        font-size: 10px;
        padding: 0 12px;
        height: 24px; }
      .company-addreview-linkvideo .input-group-append {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin-left: -1px; }
        .company-addreview-linkvideo .input-group-append .input-group-text {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          margin-bottom: 0;
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.5;
          color: #495057;
          text-align: center;
          white-space: nowrap;
          background-color: #e9ecef;
          border: 1px solid #ced4da;
          border-radius: 0 2px 2px 0;
          border-left: none;
          background-image: url("/images/icon-close-dark.svg");
          height: 24px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: 65%;
          padding: 0 12px;
          cursor: pointer; }
    .company-addreview-files-item {
      background-image: url("/images/icon-clip.svg");
      background-color: #F0F0F0;
      border: #D5D5D5 1px solid;
      display: inline-block;
      padding: 3px 6px 3px 21px;
      font-size: 10px;
      background-repeat: no-repeat;
      background-position: 5px center;
      background-size: 11px;
      border-radius: 12px;
      margin-right: 2px;
      cursor: default;
      margin-bottom: 5px; }
      .company-addreview-files-item-close {
        width: 14px;
        height: 14px;
        background-image: url("/images/icon-close-dark.svg");
        display: inline-block;
        vertical-align: middle;
        margin-left: 2px; }
    .company-addreview .btn-grey {
      padding: 5px 12px; }
  .company .modal-dialog {
    width: 430px; }
  .company-userlist-accept, .company-userlist-decline {
    color: #fff;
    width: 25px;
    height: 25px;
    line-height: 25px;
    border-radius: 3px;
    font-size: 18px;
    display: inline-block;
    text-align: center; }
    .company-userlist-accept:hover, .company-userlist-accept:focus, .company-userlist-decline:hover, .company-userlist-decline:focus {
      color: #fff; }
  .company-userlist-accept {
    background-color: #24A804; }
  .company-userlist-accepted {
    color: #24A804; }
  .company-userlist-decline {
    background-color: #D80614; }
  .company-userlist-declined {
    color: #D80614; }
  .company-userlist-item {
    background-color: #FBFAFA;
    border: #F0F0F0 1px solid;
    padding: 8px 16px; }
    .company-userlist-item-open {
      padding: 8px 16px;
      border: #F0F0F0 1px solid;
      position: relative; }
    .company-userlist-item .dropdown {
      line-height: 26px; }
  .company-userlist-plane {
    width: 18px;
    vertical-align: middle;
    margin-left: 5px; }
  .company-userlist._invited .company-userlist-item {
    line-height: 38px; }
  .company-userlist._invited .company-hint-text {
    line-height: 29px;
    white-space: nowrap;
    width: initial;
    padding: 0px 10px;
    left: 45px;
    bottom: 5px; }
  .company-userlist-dropdown {
    left: inherit;
    right: 0;
    padding: 0;
    margin: 0;
    box-shadow: none;
    -webkit-box-shadow: none;
    border-radius: 0;
    border-color: #F0F0F0; }
    .company-userlist-dropdown-toggle {
      min-width: 160px;
      display: inline-block;
      text-align: center;
      font-size: 12px;
      line-height: 26px; }
      .company-userlist-dropdown-toggle i {
        vertical-align: text-top; }
      .company-userlist-dropdown-toggle a {
        color: #000; }
    .company-userlist-dropdown a {
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      padding: 0 10px;
      line-height: 34px;
      display: block;
      color: #000;
      font-size: 12px; }
      .company-userlist-dropdown a:last-child {
        border-bottom: none; }
      .company-userlist-dropdown a:hover {
        text-decoration: none;
        background-color: #EEF3F6; }
  .company-requests {
    background-color: #4291c5;
    color: #fff;
    font-size: 10px;
    padding: 2px 8px;
    border-radius: 8px;
    margin-left: 8px; }
  .company-filtered {
    background-color: #F0F0F0;
    color: #6F6F6F;
    font-size: 10px;
    padding: 2px 8px;
    border-radius: 8px;
    margin-left: 8px; }
  .company-hint {
    cursor: pointer;
    position: relative;
    color: #008BEB; }
    .company-hint-text {
      display: none;
      position: absolute;
      bottom: 20px;
      left: 20px;
      font-size: 10px;
      color: #fff;
      background: url("/images/bg-black.png") repeat;
      padding: 4px 10px;
      width: 195px;
      border-radius: 3px;
      z-index: 1; }
    .company-hint:hover .company-hint-text {
      display: block; }
  .company-logo {
    width: 88px;
    height: 88px;
    border-radius: 50%;
    border: #008BEB 1px solid;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #008BEB;
    cursor: pointer;
    overflow: hidden; }
  .company-sidemenu {
    position: fixed;
    margin-top: 10px; }
    .company-sidemenu-item {
      list-style: none;
      margin-bottom: 10px; }
      .company-sidemenu-item._active {
        position: relative; }
        .company-sidemenu-item._active a {
          color: #008BEB;
          cursor: default; }
          .company-sidemenu-item._active a:hover {
            text-decoration: none; }
        .company-sidemenu-item._active:before {
          content: '';
          display: block;
          position: absolute;
          width: 3px;
          height: 19px;
          background-color: #008BEB;
          left: -32px; }
  .company-suggest {
    background-color: #E6F3FF;
    display: flex;
    padding: 10px;
    border: #F0F0F0 1px solid;
    position: relative; }
    .company-suggest._request {
      background-color: #FBFAFA; }
    .company-suggest-request {
      width: 22px;
      height: 22px;
      background-color: #FDA75A;
      color: #fff;
      position: absolute;
      right: -1px;
      top: -1px;
      padding: 3px; }
      .company-suggest-request-hint {
        display: none;
        background-image: url("/images/bg-black.png");
        padding: 5px;
        font-size: 10px;
        color: #fff;
        position: absolute;
        top: -10px;
        left: 50px;
        white-space: nowrap;
        border-radius: 2px; }
      .company-suggest-request:hover .company-suggest-request-hint {
        display: block; }
    .company-suggest-accept {
      width: 22px;
      height: 22px;
      background-color: #008C3D;
      color: #fff;
      position: absolute;
      right: -1px;
      top: -1px;
      padding: 3px; }
    .company-suggest-icon {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      overflow: hidden;
      border: #D5D5D5 1px solid;
      margin-right: 20px;
      background-size: 100%;
      background-size: cover;
      background-position: center; }
    .company-suggest-text {
      display: flex;
      flex-direction: column;
      justify-content: center; }
    .company-suggest:hover {
      cursor: pointer; }
  .company-collapse {
    text-decoration: none;
    color: #000; }
    .company-collapse .fa-chevron-down:before {
      content: ""; }
    .company-collapse.collapsed .fa-chevron-down:before {
      content: ""; }
    .company-collapse:hover, .company-collapse:focus {
      text-decoration: none;
      color: #000; }
    .company-collapse p {
      line-height: 21px;
      margin-bottom: 20px; }
  .company-btn-cancel {
    border: #95989A 1px solid;
    border-radius: 3px;
    background-color: #fff;
    color: #5E5E5E;
    min-width: 140px; }
  .company-btn-save {
    background-color: #00B955;
    color: #fff;
    margin-left: 18px;
    line-height: 21px;
    border: #00B955 1px solid; }
  .company-verification {
    padding: 0 50px; }
    .company-verification-addfiles-button {
      border: #14669B 1px solid;
      border-radius: 3px;
      background-image: url("/images/icon-clip-blue.svg");
      background-position: 20px center;
      background-repeat: no-repeat;
      background-size: 17px;
      padding: 7px 20px 7px 40px;
      text-align: center;
      margin: 0 10px; }
      .company-verification-addfiles-button:hover {
        text-decoration: none; }
      .company-verification-addfiles-button._disabled {
        color: #707070;
        border: #707070 1px solid; }
    .company-verification-addfiles-items {
      display: flex;
      justify-content: center; }
    .company-verification-addfiles-item {
      border: #D5D5D5 1px solid;
      background-color: #F0F0F0;
      background-image: url("/images/icon-file.svg");
      background-repeat: no-repeat;
      width: 83px;
      height: 67px;
      background-repeat: no-repeat;
      background-size: 28px;
      background-position: center 60%;
      font-size: 6px;
      text-align: left;
      padding: 5px;
      border-radius: 3px;
      position: relative;
      margin: 0 8px; }
      .company-verification-addfiles-item-close {
        width: 12px;
        height: 12px;
        background-color: #fff;
        background-image: url("/images/icon-close-black.svg");
        background-repeat: no-repeat;
        border-radius: 50%;
        cursor: pointer;
        position: absolute;
        right: 5px;
        top: 3px;
        background-size: 4px;
        background-repeat: no-repeat;
        background-position: center; }
  .company-page .comment-card-wrap {
    width: 100%; }
  .company-page .comment-autor-name {
    font-size: 14px;
    color: #062336; }

.view-comments:hover {
  cursor: pointer; }

.full-comments:hover {
  cursor: pointer; }

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .company-list-item {
    height: inherit; }
    .company-list-item-rating {
      text-align: left;
      margin-top: 12px; }
  .company-userlist-item-open-edit {
    position: absolute;
    top: 18px;
    right: 0; }
  .company .items .nav-tabs.nav-justified li {
    border-right: #e8e8e8 1px solid; }
  .company-userlist .flex-center {
    justify-content: left;
    align-items: center;
    flex-wrap: wrap; }
  .company-userlist._invited .company-hint-text {
    left: initial;
    bottom: -35px;
    right: 0; } }

#removeLogo input {
  margin-right: 4px; }

.file-upload-wrapper {
  position: relative;
  width: 100%;
  height: 30px;
  white-space: nowrap;
  overflow: hidden; }
  .file-upload-wrapper:after {
    content: attr(data-text);
    font-size: 15px;
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    padding: 11px 15px;
    display: block;
    width: 100%;
    pointer-events: none;
    z-index: 20;
    height: 30px;
    line-height: 5px;
    color: #999;
    border: 1px solid #ccc;
    border-radius: 3px 10px 10px 3px;
    font-weight: 300; }
  .file-upload-wrapper:before {
    font: normal normal normal 14px/1;
    font-family: "Font Awesome 5 Free", serif;
    content: "\f093";
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    height: 30px;
    background: #008BEB;
    color: #fff;
    font-weight: 600;
    z-index: 25;
    font-size: 15px;
    line-height: 30px;
    padding: 0 15px;
    text-transform: uppercase;
    pointer-events: none;
    border-radius: 0 3px 3px 0; }
  .file-upload-wrapper:hover:before {
    background: #3d8c63; }
  .file-upload-wrapper input {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    height: 30px;
    margin: 0;
    padding: 0;
    display: block;
    cursor: pointer;
    width: 100%; }
  .file-upload-wrapper-left {
    width: 80%;
    float: left; }
  .file-upload-wrapper-right {
    width: 18%;
    float: right; }
    .file-upload-wrapper-right .btn {
      line-height: 18px;
      width: 100%; }

.attachments .btn.btn-info {
  position: absolute;
  right: 0;
  left: 100%;
  top: 0;
  width: 35px;
  height: 30px; }

.form-avatar {
  background-color: #dcebf5;
  border-radius: 50%;
  width: 65px;
  height: 65px;
  border: #e0e0e0 1px solid;
  cursor: pointer;
  text-align: center;
  display: table;
  position: relative;
  overflow: hidden;
  margin-right: 20px; }
  .form-avatar-text {
    color: #008BEB;
    line-height: 20px;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: calc(50% - 20px);
    width: 100%;
    z-index: 1; }
  .form-avatar-delete {
    display: none;
    position: absolute;
    top: 63%;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("/images/bg-black.png");
    z-index: 1;
    justify-content: center;
    font-size: 20px; }
    .form-avatar-delete-new, .form-avatar-delete-del {
      color: #008BEB;
      float: left;
      padding: 5px 12px; }
      .form-avatar-delete-new:hover, .form-avatar-delete-del:hover {
        cursor: pointer; }
  .form-avatar-img {
    overflow: hidden;
    border-radius: 50%;
    position: relative;
    height: 100%;
    max-width: 65px;
    max-height: 65px; }
    .form-avatar-img .no-user-avatar {
      width: 26px;
      height: auto;
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); }
    .form-avatar-img .is-user-avatar {
      width: auto;
      max-width: 100%;
      height: auto; }
  .form-avatar:hover .form-avatar-text {
    text-decoration: underline; }
  .form-avatar._delete .form-avatar-text {
    display: none; }
  .form-avatar._delete:hover .form-avatar-delete {
    display: flex; }
  .form-avatar._delete:hover:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: .2;
    background-color: #000000;
    border-radius: 50%;
    z-index: 0; }
  .form-avatar._delete .form-avatar-text {
    color: #ffffff; }

.account-settings-page {
  padding-left: 15px;
  padding-right: 15px; }
  .account-settings-page .user-name {
    font-size: 24px;
    margin-bottom: 0; }
  .account-settings-page .upload-photo-text {
    font-size: 12px; }
  .account-settings-page-form {
    margin-top: 7px; }
    .account-settings-page-form .block-contacts-list {
      margin-right: 20px; }
    .account-settings-page-form .btn-communication-method {
      font-weight: 400; }

@media only screen and (max-width: 850px) {
  .account-settings-page-form .block-contacts-list .block-communication-method {
    margin-top: 0; } }

.user-media .el-add-media {
  border: 1px dashed #727272;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  padding-bottom: 0;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 3px;
  margin: auto; }
  .user-media .el-add-media:hover {
    background: #e4e4e4;
    cursor: pointer; }
  .user-media .el-add-media.with-media {
    border: none; }
    .user-media .el-add-media.with-media img {
      height: 100%; }

.user-media .icon-upload {
  font-size: 24px; }

.el-switch {
  position: relative;
  display: inline-block;
  margin-top: 16px;
  height: 18px;
  width: 100%;
  padding-left: 42px; }
  .el-switch input {
    display: none; }
  .el-switch-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #606161;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 8px;
    border: #cccccc 1px solid;
    width: 32px; }
    .el-switch-slider:before {
      position: absolute;
      content: "";
      height: 16px;
      width: 16px;
      left: 0;
      bottom: 0;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
      border-radius: 50%; }

input:checked + .el-switch-slider {
  background-color: #2581bc; }

input:focus + .el-switch-slider {
  box-shadow: 0 0 1px #2581bc; }

input:checked + .el-switch-slider:before {
  -webkit-transform: translateX(14px);
  -ms-transform: translateX(14px);
  transform: translateX(14px); }

.el-userpic {
  display: flex;
  justify-content: center;
  align-items: center; }
  .el-userpic-img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden; }
    .el-userpic-img img {
      width: 100%; }

.el-rightbutton {
  overflow: hidden; }
  .el-rightbutton .form-group {
    width: 80%;
    float: left; }
    .el-rightbutton .form-group input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right-color: #5bc0de; }
  .el-rightbutton .btn {
    width: 20%;
    float: left;
    margin-right: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 7px 12px;
    background-color: #5bc0de;
    border: 1px solid #ccc;
    border-left-color: #5bc0de;
    margin-top: 23px; }

.el-colors-item {
  float: left;
  border: #e8e8e8 1px solid;
  border-radius: 3px;
  width: 19px;
  height: 19px;
  margin-right: 2px;
  color: #ffffff;
  text-align: center;
  cursor: pointer; }
  .el-colors-item i {
    opacity: 0; }
  .el-colors-item.__picked i {
    opacity: 1; }
  .el-colors-item._color-white {
    background-color: #ffffff;
    color: #e8e8e8; }
  .el-colors-item._color-yellow {
    background-color: #ffea00; }
    .el-colors-item._color-yellow:hover {
      border-color: #ffea00; }
  .el-colors-item._color-red {
    background-color: #ff0000; }
    .el-colors-item._color-red:hover {
      border-color: #ff0000; }
  .el-colors-item._color-green {
    background-color: #00ad5d; }
    .el-colors-item._color-green:hover {
      border-color: #00ad5d; }
  .el-colors-item._color-blue {
    background-color: #2579bf; }
    .el-colors-item._color-blue:hover {
      border-color: #2579bf; }
  .el-colors-item._color-black {
    background-color: #000000; }
    .el-colors-item._color-black:hover {
      border-color: #000000; }
  .el-colors-item._color-pick {
    background-color: #e8e8e8;
    color: #000000;
    border-color: #595959; }
    .el-colors-item._color-pick i {
      opacity: 1; }

.el-video {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0; }
  .el-video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.el-add-media {
  width: 100%;
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  height: 0;
  overflow: hidden;
  position: relative; }
  .el-add-media img {
    width: 100%; }
  .el-add-media-close {
    width: 18px;
    height: 15px;
    position: absolute;
    right: 0;
    top: 0;
    border: #606060 1px solid; }
    .el-add-media-close:before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-color: #5E5E5E;
      opacity: .87;
      position: absolute; }
    .el-add-media-close:after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      background-image: url("/front/images/icon-close.png");
      background-position: center;
      background-repeat: no-repeat; }
  .el-add-media-play {
    display: block;
    width: 22px;
    height: 22px;
    background-image: url("/front/images/icon-play-black.png");
    background-size: 100%;
    background-repeat: no-repeat;
    position: absolute;
    left: 50%;
    margin-left: -11px;
    top: 25%; }

.el-sep {
  width: 100%;
  height: 1px;
  background-color: #D5D5D5; }

.el-upload-photo {
  display: block;
  width: 115px;
  height: 105px;
  background-image: url("/front/images/icon-camera.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50px;
  padding-top: 85px;
  text-align: center; }

.el-upload-video {
  display: block;
  width: 115px;
  height: 105px;
  background-image: url("/front/images/icon-play.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50px;
  padding-top: 85px;
  text-align: center; }

.caret {
  border-top: 6px dashed;
  border-top: 4px solid \9;
  border-right: 3px solid transparent;
  border-left: 3px solid transparent; }

.captcha .grecaptcha-badge {
  margin: 0 auto; }

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .el-add-media-play {
    top: 50%;
    width: 44px;
    height: 44px;
    margin-top: -22px;
    margin-left: -22px; }
  .el-upload-photo, .el-upload-video {
    height: 50px;
    padding-top: 50px;
    margin: 30px auto 0; } }

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .user-media .el-add-media {
    height: 50px;
    width: 50%; } }

.lazy-load a {
  padding: 0 30%; }

.gradient {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: 10;
  animation-name: load;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #c9c9c9 8%, #f4f4f4 38%, #d5d5d5 54%);
  background-size: 1000px 640px;
  position: relative; }

@keyframes load {
  0% {
    background-position: -468px 0; }
  100% {
    background-position: 468px 0; } }

.card-block {
  padding: 20px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border: #e8e8e8 1px solid; }
  .card-block li.disabled {
    color: #7a7a7a; }
  .card-block.white-bg {
    background-color: #fff; }
  .card-block.grey-bg {
    background-color: #f9f9f9; }
  .card-block .flag-icon {
    margin: -1px 0 0 2px; }
  .card-block .photo {
    margin-bottom: 20px; }
    .card-block .photo span {
      float: left;
      margin-right: 20px; }
    .card-block .photo img {
      width: 200px;
      height: auto;
      border: 1px solid #ddd;
      padding: 2px;
      display: inline; }
  .card-block a {
    word-wrap: break-word; }
  .card-block .alert-warning {
    border-radius: 0;
    background-color: #F7F7F7;
    border-color: #DCDCDC;
    color: #000000;
    margin-bottom: 27px; }
  .card-block #preview {
    left: 20px;
    right: 20px; }
  .card-block #addOfferForm {
    left: 20px;
    right: 20px; }
    .card-block #addOfferForm .small {
      font-size: 11px; }
  .card-block._tabs {
    border-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0; }

.information-block {
  padding: 10px;
  font-weight: 600;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  display: inline; }
  .information-block.warning {
    background-color: #ffd556; }
  .information-block.phone {
    display: none;
    margin-bottom: 0;
    text-align: center;
    vertical-align: middle;
    border: 1px solid transparent;
    padding: 6px 12px;
    font-size: 15px;
    line-height: 17px;
    border-radius: 4px; }

.danger-block {
  display: none;
  color: #DC3545;
  border: #DC3545 1px solid;
  background-color: #DC3545;
  padding: 5px 10px; }

.aboutus {
  padding-bottom: 10px; }

.aboutus-wrapper {
  max-width: 768px;
  margin: 0 auto; }

.aboutus-title {
  font-weight: bold;
  font-size: 2.1875rem;
  color: #fff; }

.aboutus-header {
  background-color: #008BEB;
  padding: 75px 0 30px; }

.aboutus-subtitle {
  font-size: 1rem;
  color: #96CBF2;
  font-weight: 600;
  margin-bottom: 5px; }

.margin-bottom-ten {
  margin-bottom: 10px; }

.text-white {
  color: #fff;
  font-size: 0.875rem; }
  .text-white span {
    font-weight: bold; }

.blue-block {
  position: relative;
  padding-bottom: 35px;
  margin-bottom: 15px; }
  .blue-block:before {
    content: '';
    width: 100%;
    height: 55px;
    background-color: #008BEB;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0; }

.aboutus-blocks {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex; }
  .aboutus-blocks .aboutus-block:nth-last-child(1) {
    margin-right: 0; }

.aboutus-block {
  margin-bottom: 20px;
  width: calc(25% - 12px);
  margin-right: 12px;
  border-top: 5px solid #96CBF2;
  background-color: #fff;
  padding: 18px 0 15px;
  z-index: 55;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1); }
  .aboutus-block .img-wrap {
    min-height: 43px;
    margin: auto; }

.aboutus-block-num {
  color: #062336;
  font-weight: bold;
  font-size: 1.125rem;
  text-align: center;
  margin-bottom: 7px; }

.aboutus-block-text {
  font-size: 0.875rem;
  text-align: center;
  color: #062336; }

.aboutus-block-pic-first {
  width: 38px; }

.aboutus-block-pic-second {
  width: 46px; }

.aboutus-block-pic-third {
  width: 29px; }

.aboutus-block-pic-fourth {
  width: 42px; }

.about-us-text {
  font-size: 0.875rem;
  color: #062336; }
  .about-us-text span {
    font-weight: bold; }

.aboutus-block-title {
  font-size: 1.375rem;
  color: #2F84BC;
  font-weight: 600;
  margin-bottom: 18px; }

.aboutus-sep {
  width: 74px;
  height: 4px;
  background-color: #96CBF2;
  margin: 0 auto 1rem; }

.img-wrap img {
  width: 100%;
  height: auto; }

.block-bluish {
  background-color: #F3F6F9; }

.aboutus-list {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 20px; }
  .aboutus-list-item {
    margin-bottom: 10px;
    padding-left: 10px;
    width: 50%;
    min-height: 41px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex; }
  .aboutus-list-item-number {
    border: 1px solid #96CBF2;
    border-radius: 50%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    margin-right: 10px;
    width: 24px;
    height: 24px; }
    .aboutus-list-item-number p {
      color: #96CBF2;
      font-size: 1rem;
      font-weight: bold;
      margin-bottom: 0;
      line-height: 1; }
  .aboutus-list-text {
    color: #062336;
    font-size: 0.875rem;
    text-align: left;
    width: calc(100% - 34px);
    margin-bottom: 0; }

.aboutus-bee {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center; }

.aboutus-bee-img {
  width: 49px;
  margin-right: 10px; }

.aboutus-bee-text {
  color: #fff;
  background-color: #2581BC;
  font-size: 0.875rem;
  font-weight: 600;
  padding: 0 20px;
  margin-bottom: 0; }

.padding-top-thirteen {
  padding-top: 35px; }

.small-text-wrap {
  max-width: 620px;
  width: 100%;
  margin: auto; }

@media (max-width: 991px) {
  .aboutus-blocks {
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    flex-wrap: wrap; }
  .aboutus-block {
    margin-bottom: 20px;
    width: calc(50% - 7px);
    margin-right: 14px; }
  .aboutus-blocks .aboutus-block:nth-last-child(1) {
    margin-right: 14px; }
  .aboutus-blocks .aboutus-block:nth-child(even) {
    margin-right: 0; }
  .aboutus-list-item {
    width: 100%;
    min-height: auto; }
  .aboutus-blocks .aboutus-block-text {
    font-size: .7rem; }
  .aboutus-bee-text {
    font-size: .55rem;
    padding: 5px 8px; } }

.vacancies-content {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between; }

.vacancies-list-pagination {
  width: calc(100% - 295px); }

.vacancies-list {
  width: 100%;
  padding: 0; }
  .vacancies-list-item {
    list-style-type: none;
    border: 1px solid #D6D6D6;
    padding: 13px 30px 15px 33px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
    margin-bottom: 10px; }
  .vacancies-list .paid-tariff {
    background-color: #DBF5FF;
    border: 1px solid #BEE4FF; }
  .vacancies-list .icon-vip-paid {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    color: #EA4E00;
    font-size: 24px; }
  .vacancies-list .vip-paid-tariff {
    position: relative; }
    .vacancies-list .vip-paid-tariff .icon-vip-paid {
      display: inline-block; }

.vacancy-title {
  color: #14669B;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 9px; }

.block-you-need {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: flex-start; }
  .block-you-need p {
    color: #E96E25;
    font-size: 12px;
    margin: 0 0 0 8px; }
  .block-you-need img {
    width: 13px;
    height: auto;
    margin-right: 4px; }

.vacancy-description {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: flex-start; }
  .vacancy-description p {
    font-size: 12px;
    margin-bottom: 0; }
  .vacancy-description a {
    color: #14669B; }

.for-disk {
  position: relative;
  padding-left: 20px;
  margin-left: 18px; }
  .for-disk:before {
    content: '';
    border-radius: 50%;
    width: 5px;
    height: 5px;
    background-color: #2581BC;
    position: absolute;
    left: 0;
    top: 54%;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%); }

.for-disk-hide {
  padding-left: 0; }
  .for-disk-hide:before {
    display: none; }

.location-employment-block {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center; }

.popular-vacancies a .card-title {
  font-size: 14px; }

.popular-vacancies a:hover div.card {
  box-shadow: 0 5px 11px rgba(0, 0, 0, 0.2); }

.btn-filter-show {
  display: none;
  width: calc(100% - 20px);
  margin: 0 auto 20px; }
  .btn-filter-show .fa-angle-down {
    font-size: 17px;
    margin-left: 10px;
    position: relative;
    top: 2px; }
  .btn-filter-show.icon-rotate .fa-angle-down {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg); }

.tooltip-vip-text {
  width: 195px;
  color: #fff;
  background-color: #062340;
  text-align: left;
  padding: 10px 8px;
  position: absolute;
  bottom: calc(100% + 16px);
  left: -86px;
  z-index: 1031; }
  .tooltip-vip-text p {
    position: relative;
    margin-bottom: 0;
    font-weight: 400;
    font-size: 14px; }
    .tooltip-vip-text p:before {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 9px 10px 0 10px;
      border-color: #062340 transparent transparent transparent;
      position: absolute;
      bottom: -19px;
      left: 50%;
      -webkit-transform: translate(-50%, 0);
      -moz-transform: translate(-50%, 0);
      -ms-transform: translate(-50%, 0);
      transform: translate(-50%, 0); }

@media only screen and (max-width: 991px) {
  .vacancies-list-pagination {
    width: calc(100% - 260px); }
  .for-disk {
    margin-left: 9px;
    padding-left: 10px; }
  .vacancy-description {
    -webkit-align-items: baseline;
    -moz-align-items: baseline;
    -ms-align-items: baseline;
    align-items: baseline; }
    .vacancy-description > p {
      max-width: 65%; }
    .vacancy-description p {
      font-size: 9px; }
  .vacancies-item-date {
    font-size: 12px; } }

@media only screen and (max-width: 767px) {
  .vacancies-content {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
  .vacancies-list-pagination {
    width: 100%; }
  .for-disk {
    padding-left: 10px; }
  .vacancy-description {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start; }
  .for-disk {
    margin-left: 0;
    margin-right: 14px; }
  .btn-filter-show {
    display: inline-block;
    width: 100%; }
  .vacancies-mob .container {
    padding-left: 0;
    padding-right: 0; }
  .vacancies-mob .number-of-vacancies {
    font-size: 18px;
    font-weight: 500;
    padding-left: 10px;
    padding-right: 10px; }
  .vacancies-mob .vacancies-list-item {
    border-left: none;
    border-right: none;
    -webkit-flex-direction: column-reverse;
    -moz-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    padding: 10px 15px 15px 48px;
    margin-bottom: 10px;
    position: relative; }
    .vacancies-mob .vacancies-list-item:before {
      content: '';
      height: calc(100% + 2px);
      width: 5px;
      position: absolute;
      left: -5px;
      top: -1px;
      border-top: 1px solid #BEE4FF;
      border-bottom: 1px solid #BEE4FF; }
    .vacancies-mob .vacancies-list-item:after {
      content: '';
      height: calc(100% + 2px);
      width: 5px;
      position: absolute;
      right: -5px;
      top: -1px;
      border-top: 1px solid #BEE4FF;
      border-bottom: 1px solid #BEE4FF; }
  .vacancies-mob .vacancy-title,
  .vacancies-mob .vacancies-item-date {
    margin-bottom: 0; }
  .vacancies-mob .vacancy-description a,
  .vacancies-mob .vacancy-description p {
    font-size: 12px;
    max-width: none; }
  .vacancies-mob .vacancies-list .icon-vip-paid {
    left: 12px;
    font-size: 24px; }
  .vacancies-mob .for-disk-hide {
    padding-left: 0;
    margin-right: 15px; }
  .vacancies-mob .location-employment-block .pl-mob {
    padding-left: 19px; }
  .vacancies-mob .paid-tariff:before {
    content: '';
    height: calc(100% + 2px);
    width: 5px;
    position: absolute;
    left: -5px;
    top: -1px;
    background-color: #DBF5FF;
    border-top: 1px solid #BEE4FF;
    border-bottom: 1px solid #BEE4FF; }
  .vacancies-mob .paid-tariff:after {
    content: '';
    height: calc(100% + 2px);
    width: 5px;
    position: absolute;
    right: -5px;
    top: -1px;
    background-color: #DBF5FF;
    border-top: 1px solid #BEE4FF;
    border-bottom: 1px solid #BEE4FF; }
  .tooltip-vip-text {
    display: none !important; } }

@media only screen and (max-height: 500px) and (orientation: landscape) {
  .vacancies-mob .vacancies-list-item {
    border: 1px solid #D6D6D6; }
  .vacancies-mob .paid-tariff {
    border: 1px solid #BEE4FF; }
  .vacancies-mob .btn-filter-show {
    width: 100%; } }

.vacancy-page-header {
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  border-bottom: 1px solid #e0e0e0;
  position: relative; }
  .vacancy-page-header-left {
    width: calc(100% - 350px);
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex; }
  .vacancy-page-header .employer-type-mob {
    display: none; }

.vacancy-page .logo-company {
  width: 90px; }
  .vacancy-page .logo-company img {
    width: auto;
    max-width: 100%;
    height: auto; }

.vacancy-page .btn-mr {
  margin-right: 20px !important; }

.vacancy-page .btn-ml {
  margin-left: 20px !important; }

.vacancy-page-form {
  padding: 19px;
  margin-bottom: 20px;
  background-color: #ECF5FC;
  border: 1px solid #ECF5FC;
  border-radius: 0; }
  .vacancy-page-form label {
    font-weight: 500;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex; }
  .vacancy-page-form .fa-asterisk {
    font-size: 9px; }
  .vacancy-page-form .btn-form-group {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between; }

.vacancy-page .modal-dialog {
  margin-top: 0; }

.vacancy-page .modal-body {
  text-align: left; }

.vacancy-page .left-col {
  padding-top: 0; }

.vacancy-page .right-col .form-notification-subscription {
  margin-top: 0; }

.vacancy-page .offer-title {
  font-weight: 400;
  font-size: 24px;
  line-height: 1;
  margin-bottom: 4px; }

.vacancy-page .author-info {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 8px; }
  .vacancy-page .author-info .url {
    font-weight: 500;
    color: #008AEA; }
    .vacancy-page .author-info .url .verified-block {
      height: 27px;
      width: auto; }
  .vacancy-page .author-info .for-disk {
    margin-left: 0; }

.vacancy-page .for-disk {
  padding-left: 12px;
  margin-left: 5px; }

.vacancy-page .offer-info {
  flex: 1 1 auto;
  max-width: 285px; }
  .vacancy-page .offer-info h3.block_header {
    font-size: 16px;
    margin-bottom: 0; }
  .vacancy-page .offer-info .info-title {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0;
    margin-top: 10px; }
  .vacancy-page .offer-info .detail-info-val {
    margin-bottom: 0; }
    .vacancy-page .offer-info .detail-info-val .fa-paperclip {
      font-size: 11px; }

.vacancy-page .offer-content {
  margin-right: 130px;
  width: calc(100% - 350px); }
  .vacancy-page .offer-content p {
    margin-bottom: 0; }
    .vacancy-page .offer-content p span {
      color: #062336 !important; }
  .vacancy-page .offer-content ul {
    margin-bottom: 0; }

.vacancy-page .date-id-views {
  margin-bottom: 20px;
  color: #6C757D !important; }

.vacancy-page .actions-block {
  margin-bottom: 20px; }

.vacancy-page-company-logo {
  width: 128px;
  height: 128px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  .vacancy-page-company-logo img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%; }

.vacancy-page-company-logo-mob {
  display: none; }

.vacancy-page hr {
  margin-top: 10px; }

.vacancy-page .vacancy-video {
  width: 500px;
  height: 300px;
  margin-bottom: 30px; }

.vacancy-page .reply-block-sticky {
  position: fixed;
  top: 96px;
  left: 0;
  padding: 20px 0;
  background: #fff;
  z-index: 3;
  width: 100%;
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
  display: none; }
  .vacancy-page .reply-block-sticky__fixed {
    display: inline-block; }
  .vacancy-page .reply-block-sticky__desc {
    margin-bottom: 0; }
  .vacancy-page .reply-block-sticky .container {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
    -ms-flex-pack: flex-start; }

.color-secondary {
  color: #6C757D !important; }

.dropdown-offer-contacts-title {
  cursor: pointer; }
  .dropdown-offer-contacts-title i {
    margin-left: 5px;
    margin-top: 0;
    font-size: 16px; }
  .dropdown-offer-contacts-title.active-el i {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg); }

.show-phone {
  border-radius: 3px;
  border: none;
  background-color: #F3F3F3;
  color: #14669B;
  line-height: 35px;
  margin-bottom: 25px;
  padding: 0 10px; }
  .show-phone i {
    margin-right: 10px;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg); }
  .show-phone:hover {
    background-color: #E2E2E2; }
  .show-phone.focus {
    outline: none; }
  .show-phone .phone-hide {
    display: inline-block; }
  .show-phone .phone-show {
    display: none; }
  .show-phone.phone-active .phone-hide {
    display: none; }
  .show-phone.phone-active .phone-show {
    display: inline-block; }

.block-logo-btns {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%; }

.block-vacancy-page-btn {
  padding-top: 20px;
  padding-bottom: 30px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: flex-start; }

.block-logo {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: flex-start; }

.vacancy-name-date-place {
  width: calc(100% - 95px); }
  .vacancy-name-date-place .vacancy-name {
    font-size: 18px;
    font-weight: 500;
    color: #008BEB; }
  .vacancy-name-date-place .company-name {
    font-size: 14px;
    color: #008BEB; }

.block-logo-vacancy-name {
  font-size: 18px;
  font-weight: 500;
  color: #008BEB; }

.description-vacancy {
  padding-top: 20px; }

.date-place-tags {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: baseline;
  -moz-align-items: baseline;
  -ms-align-items: baseline;
  align-items: baseline;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: flex-start; }
  .date-place-tags p {
    position: relative;
    padding-left: 10px;
    margin: 0 25px 0 0;
    font-size: 14px;
    color: #062336; }
  .date-place-tags i {
    color: #008BEB; }
  .date-place-tags > div {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: baseline;
    -moz-align-items: baseline;
    -ms-align-items: baseline;
    align-items: baseline;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
    -ms-flex-pack: flex-start;
    padding-bottom: 8px; }

.vacancy-page-content {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  margin-bottom: 35px; }

.left-col {
  width: calc(100% - 400px);
  padding-top: 45px; }

.right-col {
  width: 100%;
  max-width: 380px; }
  .right-col .form-notification-subscription {
    border: 1px solid #F0F0F0;
    width: 100%;
    padding: 30px 20px 25px;
    margin-bottom: 15px; }
  .right-col .form-control,
  .right-col .form-notification-subscription .form-group,
  .right-col .form-notification-subscription-title {
    margin-bottom: 25px; }
  .right-col .form-notification-subscription .form-group {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center; }

.block-similar-jobs {
  border: 1px solid #F0F0F0;
  border-radius: 2px;
  margin-bottom: 5px; }
  .block-similar-jobs .block-title {
    font-size: 16px;
    color: #062336;
    padding: 25px 20px;
    border-bottom: 1px solid #F0F0F0;
    margin: 0; }
  .block-similar-jobs ul {
    padding: 0;
    margin: 0; }
  .block-similar-jobs li {
    padding: 20px 20px 10px;
    border-bottom: 1px solid #F0F0F0; }
  .block-similar-jobs li:nth-last-child(1) {
    border-bottom: 0; }
  .block-similar-jobs .vacancy-title {
    margin-bottom: 0; }
  .block-similar-jobs .type-employment {
    margin-bottom: 5px; }

.date-block-you-need {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: flex-start;
  margin-bottom: 5px; }
  .date-block-you-need p {
    margin: 0 15px 0 0; }

.btn-show-all {
  text-align: right;
  font-size: 13px;
  padding-right: 20px;
  margin: 0;
  position: relative; }
  .btn-show-all:before {
    content: '';
    width: 12px;
    height: 8px;
    background: url("/images/arr-down.png") no-repeat;
    background-size: cover;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translate(0, -50%) rotate(-90deg);
    -moz-transform: translate(0, -50%) rotate(-90deg);
    -ms-transform: translate(0, -50%) rotate(-90deg);
    transform: translate(0, -50%) rotate(-90deg); }

li {
  list-style-type: none; }

.block-recently-watched {
  padding: 35px 35px 30px;
  border: 1px solid #F0F0F0;
  border-radius: 2px;
  margin: 30px 0 10px; }
  .block-recently-watched ul {
    padding: 0;
    margin: 0; }

.post-resume-banner-title {
  color: #14669B;
  font-size: 18px;
  text-align: center;
  margin-bottom: 30px; }

.post-resume-banner-subtitle {
  text-align: center;
  margin-bottom: 40px; }

.block-post-resume-banner {
  padding: 15px 20px 20px;
  border: 1px solid #F0F0F0;
  border-radius: 2px;
  text-align: center; }

.block-rating {
  font-size: 14px;
  font-weight: 500;
  color: #5E5E5E; }

.vacancy-subtitle {
  font-size: 14px;
  color: #062336;
  text-align: left;
  margin-bottom: 25px;
  font-weight: 500; }

.vacancy-text {
  font-size: 14px;
  color: #062336;
  text-align: left;
  margin-bottom: 25px; }

.block-gallery {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: flex-start;
  margin-bottom: 10px;
  margin-top: 11px; }
  .block-gallery-title {
    margin-top: 18px;
    font-size: 16px;
    font-weight: 500; }
  .block-gallery .img-wrap {
    margin-right: 20px;
    width: 80px;
    height: 80px;
    overflow: hidden;
    text-align: center;
    border: none;
    box-shadow: none;
    padding: 0;
    border-radius: 0; }
    .block-gallery .img-wrap img {
      max-height: 100%;
      width: auto;
      min-width: 100%;
      height: auto;
      position: relative;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); }
  .block-gallery .img-wrap:nth-last-child(1) {
    margin-right: 0; }
  .block-gallery .fa-images {
    display: none; }

.ad-code-published-btn {
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }

.ad-code-published {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: flex-start;
  margin-bottom: 20px; }
  .ad-code-published-btn {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between; }
  .ad-code-published p {
    color: #777777;
    font-size: 14px;
    margin-bottom: 6px;
    margin-right: 20px; }
  .ad-code-published p:nth-last-child(1) {
    margin-bottom: 20px; }
  .ad-code-published span {
    font-weight: 500; }
  .ad-code-published a {
    color: #B13737;
    font-size: 14px; }

.cv-edit-btn {
  margin-left: 10px; }

.vacancy-page-mob-show-portrait,
.vacancy-page-mob-show {
  display: none; }

.vacancy-page-dropdown {
  display: none;
  position: absolute;
  right: 0;
  top: -11px; }
  .vacancy-page-dropdown-icon {
    width: 43px;
    height: 43px;
    border-radius: 50%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center; }
    .vacancy-page-dropdown-icon:hover {
      background-color: #F4F4F4; }
    .vacancy-page-dropdown-icon > div {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: #008BEB;
      margin: 0 2px; }

.vacancy-dropdown-menu {
  padding: 0; }
  .vacancy-dropdown-menu .dropdown-item {
    padding: 8px 10px;
    color: #062336; }
    .vacancy-dropdown-menu .dropdown-item:hover, .vacancy-dropdown-menu .dropdown-item:focus {
      color: #fff;
      background-color: #008BEB; }

@media only screen and (max-width: 1199px) {
  .block-logo-btns {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
  .block-logo {
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start;
    margin-bottom: 20px; } }

@media only screen and (max-width: 1024px) {
  .vacancy-page .offer-content {
    margin-right: 90px;
    width: calc(100% - 335px); } }

@media only screen and (max-width: 991px) {
  .vacancy-page-content {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center; }
    .vacancy-page-content .offer-content {
      margin-right: 70px;
      width: calc(100% - 315px); }
  .left-col,
  .right-col {
    width: 100%;
    max-width: 100%; }
  .date-block-you-need {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start; }
  .ad-code-published-btn {
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center; }
    .ad-code-published-btn > div:nth-child(1) {
      text-align: center; }
      .ad-code-published-btn > div:nth-child(1) button {
        margin: 0 10px 10px !important; }
  .right-col .form-notification-subscription,
  .block-recently-watched {
    padding: 10px; }
  .ad-code-published-btn > div:nth-child(1) {
    margin: auto; }
  .block-logo {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
  .vacancy-name-date-place {
    width: 100%; }
  .date-place-tags {
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
    .date-place-tags > div {
      width: 100%;
      margin-bottom: 20px;
      padding-bottom: 0; }
  .vacancy-page .logo-company {
    margin: 20px auto 0; }
  .ad-code-published {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
  .description-vacancy {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    padding-top: 20px; }
  .vacancy-page .offer-content {
    margin-right: 0;
    width: 100%; }
  .vacancy-page .offer-info {
    width: 100%;
    max-width: 245px; }
  .vacancy-page-mob-hide {
    display: none; }
  .vacancy-page-mob-show {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between; }
  .vacancy-page-mob .offer-info {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start;
    max-width: none; }
    .vacancy-page-mob .offer-info .info-title {
      color: #6C757D;
      font-size: 12px;
      margin-top: 0; }
  .vacancy-page-mob .row-content-info {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    margin-bottom: 7px;
    width: 100%; }
    .vacancy-page-mob .row-content-info__contacts {
      -webkit-align-items: flex-start;
      -moz-align-items: flex-start;
      -ms-align-items: flex-start;
      align-items: flex-start;
      -webkit-flex-direction: column;
      -moz-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column; }
    .vacancy-page-mob .row-content-info__small {
      width: 351px; }
      .vacancy-page-mob .row-content-info__small h3 {
        width: 52.8%; }
      .vacancy-page-mob .row-content-info__small p {
        width: 43.2%; }
    .vacancy-page-mob .row-content-info h3 {
      width: 44%;
      min-width: 185px;
      padding-right: 25px; }
    .vacancy-page-mob .row-content-info p {
      width: 56%;
      min-width: 165px;
      font-size: 14px;
      color: #062336;
      margin-bottom: 0; }
    .vacancy-page-mob .row-content-info a, .vacancy-page-mob .row-content-info div {
      width: 100%; }
    .vacancy-page-mob .row-content-info .dropdown-offer-contacts {
      margin-top: 10px; }
  .vacancy-page-mob .block-posted-views-shotr {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
    margin-bottom: 20px;
    margin-top: 20px; }
    .vacancy-page-mob .block-posted-views-shotr p {
      margin-bottom: 0;
      color: #707070; }
    .vacancy-page-mob .block-posted-views-shotr div {
      max-width: 50%; }
  .vacancy-page-mob .offer-title {
    padding-right: 150px;
    margin-bottom: 10px; }
  .vacancy-page-mob .author-info {
    margin-bottom: 15px;
    padding-right: 150px; }
  .vacancy-page-mob .offer-content p {
    margin-bottom: 0; }
  .vacancy-page-mob .field-on-mob {
    resize: none;
    height: 70px; }
  .vacancy-page-mob .vacancy-page-form .btn-form-group {
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
    -ms-flex-pack: flex-start; }
    .vacancy-page-mob .vacancy-page-form .btn-form-group .btn {
      margin-left: 0; }
  .vacancy-page-mob .report-form-mob h5,
  .vacancy-page-mob .report-form-mob label,
  .vacancy-page-mob .report-form-mob p {
    color: rgba(0, 0, 0, 0.5); }
  .vacancy-page-mob .report-form-mob textarea {
    width: 100%;
    height: 70px;
    resize: none;
    border: 1px solid #d1d1d1; }
  .vacancy-page-mob .report-form-mob label {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center; }
  .vacancy-page-mob .report-form-mob .btn {
    margin-right: 20px; }
  .vacancy-page-mob .vacancy-video {
    width: 361px;
    height: 217px;
    margin-bottom: 16px; }
  .vacancy-page-company-logo {
    width: 120px;
    height: 120px;
    position: absolute;
    right: 0;
    top: 0; }
  .vacancy-page .vacancy-page-header-left {
    width: 100%; }
  .vacancy-page .block-gallery {
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 20px; }
    .vacancy-page .block-gallery .block-gallery .img-wrap {
      margin-right: 14px; }
  .vacancy-page .reply-block-sticky__fixed {
    display: none; } }

@media only screen and (max-width: 767px) {
  .vacancy-page-header {
    -webkit-flex-direction: column-reverse;
    -moz-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    border-bottom: none; }
    .vacancy-page-header:before {
      content: '';
      width: 100%;
      height: 1px;
      background-color: #D6D6D6;
      position: absolute;
      bottom: 0;
      left: 0; }
    .vacancy-page-header-left {
      -webkit-flex-direction: row;
      -moz-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row; }
    .vacancy-page-header .btn-info,
    .vacancy-page-header .btn-outline-info {
      width: 25px;
      height: 25px;
      padding: 1px; }
      .vacancy-page-header .btn-info span,
      .vacancy-page-header .btn-outline-info span {
        display: none; }
    .vacancy-page-header .employer-type-mob {
      display: inline-block;
      font-size: 12px;
      color: #6C757D;
      margin-bottom: 0;
      width: calc(100% - 45px); }
    .vacancy-page-header .vacancy-dropdown-menu .btn-info,
    .vacancy-page-header .vacancy-dropdown-menu .btn-outline-info {
      width: 100%;
      height: auto;
      padding: 0;
      background-color: #fff;
      border: none;
      margin: 0;
      -webkit-align-items: flex-start;
      -moz-align-items: flex-start;
      -ms-align-items: flex-start;
      align-items: flex-start; }
      .vacancy-page-header .vacancy-dropdown-menu .btn-info .fa-heart,
      .vacancy-page-header .vacancy-dropdown-menu .btn-outline-info .fa-heart {
        display: none; }
      .vacancy-page-header .vacancy-dropdown-menu .btn-info span,
      .vacancy-page-header .vacancy-dropdown-menu .btn-outline-info span {
        display: inline-block;
        padding: 8px 10px;
        color: #062336;
        font-size: 0.875rem; }
  .vacancy-page-company-logo {
    display: none; }
    .vacancy-page-company-logo-mob {
      display: inline-block;
      width: 50px;
      height: 50px;
      position: absolute;
      right: -23px;
      top: -33px; }
  .vacancy-page .actions-block {
    -webkit-align-items: flex-end;
    -moz-align-items: flex-end;
    -ms-align-items: flex-end;
    align-items: flex-end;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 0; }
  .vacancy-page .offer-title {
    padding-right: 0;
    font-size: 18px;
    line-height: 1.2;
    margin-bottom: 6px;
    width: calc(100% - 45px); }
  .vacancy-page .author-info {
    width: calc(100% - 45px); }
    .vacancy-page .author-info .url {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center; }
      .vacancy-page .author-info .url .verified-block {
        height: 21px;
        margin-left: 5px; }
  .vacancy-page-mob .author-info {
    font-size: 12px;
    padding-right: 0;
    margin-bottom: 0; }
    .vacancy-page-mob .author-info .mob-hide {
      display: none; }
  .vacancy-page-mob .row-content-info__small:nth-last-child(1) {
    margin-bottom: 20px; }
  .vacancy-page-mob .row-content-info h3 {
    width: 42.6%;
    min-width: 120px;
    padding-right: 10px; }
  .vacancy-page-mob .row-content-info p {
    width: 57.4%;
    min-width: 160px; }
  .vacancy-page-mob .block-posted-views-shotr div {
    max-width: 60%; }
  .vacancy-page-mob-show-portrait {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    -ms-justify-content: flex-end;
    justify-content: flex-end;
    -ms-flex-pack: flex-end; }
  .vacancy-page-mob .offer-info {
    position: relative;
    padding-top: 52px; }
    .vacancy-page-mob .offer-info:before {
      content: '';
      width: calc(100% + 25px);
      height: 1px;
      background-color: #D6D6D6;
      position: absolute;
      top: 32px;
      left: 0; }
  .vacancy-page .btn {
    margin: 0 auto 20px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center; }
  .block-logo-btns {
    width: calc(100% - 70px); }
  .vacancy-page-mob-dnone,
  .block-gallery-title {
    display: none; }
  .btn-report,
  .btn-success-mob {
    margin-left: 20px !important;
    padding: 1px 1px;
    width: 54px;
    height: 35px; }
    .btn-report span,
    .btn-success-mob span {
      display: none; }
  .vacancy-page-dropdown {
    display: inline-block; } }

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .block-vacancy-page-btn {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
  .vacancy-page-header:before {
    width: 100vw;
    left: -15px; }
  .vacancy-page .offer-info:before {
    width: 100vw;
    left: -15px; }
  .report-form-footer {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
    -ms-flex-pack: flex-start; }
    .report-form-footer .btn {
      margin-left: 0; } }

@media only screen and (max-width: 450px) and (orientation: portrait) {
  .block-gallery {
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
    -ms-flex-pack: flex-start;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
    .block-gallery .img-wrap {
      width: 100%;
      margin-right: 0; }
  .vacancy-page-form {
    padding: 16px 15px 0;
    width: calc(100% + 30px);
    left: -15px; }
  .btn-form-group {
    margin-top: 25px; }
    .btn-form-group .btn {
      margin-right: 20px; }
  .vacancy-page-mob .img-on-mob {
    display: none; }
  .vacancy-page-mob .block-gallery {
    margin-bottom: 20px;
    position: relative;
    margin-top: 15px; }
    .vacancy-page-mob .block-gallery .img-wrap {
      height: auto;
      max-height: 268px;
      margin-bottom: 0; }
      .vacancy-page-mob .block-gallery .img-wrap img {
        max-height: 268px; }
    .vacancy-page-mob .block-gallery.more-img .fa-images {
      display: inline-block;
      color: #fff;
      font-size: 21px;
      position: absolute;
      top: 5px;
      right: 5px;
      cursor: pointer;
      -webkit-box-shadow: -2px 3px 6px 0 rgba(0, 0, 0, 0.2);
      -moz-box-shadow: -2px 3px 6px 0 rgba(0, 0, 0, 0.2);
      box-shadow: -2px 3px 6px 0 rgba(0, 0, 0, 0.2);
      background-color: rgba(0, 0, 0, 0.05);
      z-index: 1; }
  .vacancy-page-mob .vacancy-video {
    width: 100%;
    height: 204px;
    margin-bottom: 16px; } }

@media (min-width: 576px) {
  .vacancy-page .modal-dialog {
    max-width: 500px; } }

.list-companies {
  padding: 0 0 85px; }
  .list-companies-content {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between; }
  .list-companies-tabs {
    width: calc(100% - 297px); }
  .list-companies .block-rating {
    font-weight: 400; }

.block-recruitment-agencies {
  text-align: center; }

.recruitment-agencies-title {
  font-size: 36px;
  color: #419DE4;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 25px; }

.recruitment-agencies-subtitle {
  color: #419DE4;
  margin-bottom: 30px; }

.agencies-advantages {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  margin: 0 auto 35px; }
  .agencies-advantages > div:nth-last-child(1) {
    border-left: 1px solid #419DE4; }
  .agencies-advantages > div {
    padding: 25px 90px; }
  .agencies-advantages p {
    margin: 0;
    text-align: left;
    color: #419DE4; }

.btn-header {
  background-color: #419DE4;
  border-color: #419DE4;
  padding-left: 40px;
  padding-right: 40px; }
  .btn-header .fab {
    margin-right: 20px; }

.block-advertising {
  margin-top: 30px;
  border: 1px solid #F0F0F0;
  padding: 5px; }
  .block-advertising p {
    color: #14669B;
    text-align: right;
    margin-bottom: 5px; }
  .block-advertising img {
    width: 100%;
    height: auto; }

.block-company-list {
  padding: 0;
  margin-bottom: 45px; }
  .block-company-list-item {
    border: 1px solid #F0F0F0;
    background-color: #FBFAFA;
    padding: 15px 26px 15px 22px;
    margin-bottom: 10px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between; }
    .block-company-list-item-pb {
      padding-bottom: 19px; }
  .block-company-list .block-rating {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center; }
    .block-company-list .block-rating p {
      color: #707070;
      margin-right: 15px;
      margin-bottom: 0; }
      .block-company-list .block-rating p span {
        color: #2581BC; }
    .block-company-list .block-rating .fa-star {
      color: #fff;
      font-size: 10px;
      padding: 3px;
      background-color: #2581BC; }
  .block-company-list .company-item-description {
    width: calc(100% - 155px); }
  .block-company-list .block-rating-company-name {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
    width: 100%;
    margin-bottom: 7px; }
  .block-company-list .company-name {
    font-weight: 500;
    font-size: 18px;
    color: #14669B;
    margin-bottom: 0;
    display: inline-block;
    position: relative; }
    .block-company-list .company-name-link {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center; }
  .block-company-list .company-icon {
    width: 18px;
    height: auto;
    margin-left: 17px; }
  .block-company-list .company-confirmed:before {
    content: '';
    width: 27px;
    height: 27px;
    position: absolute;
    top: 0;
    right: -30px;
    background: url(/images/company/check.png) no-repeat; }
  .block-company-list .block-jobs-reviews {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start;
    margin-bottom: 6px; }
    .block-company-list .block-jobs-reviews div {
      min-width: 99px;
      text-align: left;
      line-height: 16px; }
    .block-company-list .block-jobs-reviews > div:nth-last-child(1) {
      border-left: 1px solid #008BEB;
      padding-left: 20px;
      line-height: 16px; }
  .block-company-list .company-description-text {
    color: #707070;
    margin-bottom: 0;
    width: 88%; }
    .block-company-list .company-description-text span {
      color: #062336; }

.company-logo-wrap {
  width: 120px;
  height: 120px;
  margin: auto 25px auto 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  .company-logo-wrap img {
    max-width: 100%;
    width: auto;
    max-height: 100%;
    height: auto; }

@media only screen and (max-width: 991px) {
  .agencies-advantages > div {
    padding: 15px 20px; }
  .list-companies-content {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
    .list-companies-content .vacancies-filter {
      width: 100%;
      margin-bottom: 10px; }
    .list-companies-content .nav-tabs .nav-item {
      width: 50%;
      margin-top: 20px; }
  .filter-advertising,
  .list-companies-tabs {
    width: 100%; }
  .list-companies-tabs.nav-tabs .nav-link {
    padding: 0 2rem 1rem; }
  .block-company-list .company-description-text {
    width: 100%; } }

@media only screen and (max-width: 800px) and (orientation: landscape) {
  .company-logo-wrap {
    width: 65px;
    margin: auto 10px auto 0; }
  .block-company-list-item {
    padding: 10px; }
    .block-company-list-item .block-rating p {
      margin-right: 5px; }
    .block-company-list-item .company-item-description {
      width: calc(100% - 75px); } }

@media only screen and (max-width: 767px) {
  .agencies-advantages > div {
    padding: 15px 10px; }
  .recruitment-agencies-title {
    font-size: 23px; }
  .list-companies-content {
    padding-top: 8px; }
    .list-companies-content .vacancies-filter {
      display: none; }
  .block-company-list .block-jobs-reviews div {
    min-width: auto;
    padding-right: 20px; } }

@media only screen and (max-width: 600px) and (orientation: portrait) {
  .block-recruitment-agencies {
    margin-bottom: 15px; }
  .block-company-list-item {
    padding: 10px 10px 20px;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center; }
    .block-company-list-item .company-item-description {
      width: 100%;
      margin-bottom: 0; }
  .company-logo-wrap {
    width: 80px;
    margin: 0 auto 10px;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center; }
  .block-company-list .block-rating {
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    width: 100%;
    margin-bottom: 10px; }
  .block-company-list .block-rating-company-name {
    -webkit-flex-direction: column-reverse;
    -moz-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start; }
  .agencies-advantages {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start; }
    .agencies-advantages > div:nth-last-child(1) {
      border-left: none;
      border-top: 1px solid #419DE4; }
    .agencies-advantages p {
      text-align: center; } }

.company-page-header {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  margin-bottom: 20px; }
  .company-page-header > div {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    width: 100%; }

.company-page .logo-company {
  margin-left: 75px;
  margin-right: 100px;
  max-width: 120px;
  width: 120px;
  height: 120px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  .company-page .logo-company img {
    max-width: 100%;
    width: auto;
    height: auto; }

.company-page .vacancies-list-item {
  padding: 14px 44px 16px 40px; }

.company-page .vacancy-title {
  font-size: 14px; }

.company-page .for-disk {
  margin-left: 6px; }

.company-page .show-comments {
  color: #008BEB; }

.company-page .vote-wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  position: absolute;
  right: 30px; }

.company-page .block-padding {
  padding: 0 30px; }

.company-page .review-footer {
  margin-bottom: 0;
  font-size: 12px;
  color: #6C757D; }

.company-page .vacancy-description p {
  font-size: 14px; }

.company-page .comment-list-wrap {
  width: 100%;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-top: 30px; }

.company-page .toggle-comments {
  position: absolute;
  top: 0;
  right: 301px;
  cursor: pointer; }

.company-desk-tabs .nav-tabs {
  border-bottom: none;
  position: relative;
  margin-bottom: 30px; }
  .company-desk-tabs .nav-tabs:before {
    content: '';
    width: 100%;
    height: 1px;
    background-color: #008BEB;
    position: absolute;
    left: 0;
    bottom: .5px;
    z-index: -1; }
  .company-desk-tabs .nav-tabs .nav-link {
    padding: 0 10px 5px;
    margin-right: 30px; }
  .company-desk-tabs .nav-tabs .nav-link.active, .company-desk-tabs .nav-tabs .nav-item.show .nav-link {
    color: #008BEB; }

.company-desk-tabs .tab-content {
  border-top: none; }

.company-desk-tabs #tabsAboutCompany {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between; }
  .company-desk-tabs #tabsAboutCompany > div {
    background: #FBFAFA; }
    .company-desk-tabs #tabsAboutCompany > div:nth-child(1) {
      padding: 16px;
      width: 57%; }
    .company-desk-tabs #tabsAboutCompany > div:nth-child(2) {
      padding: 16px 55px 16px 20px;
      width: 40%; }

.offers-list-navbar-container {
  padding-left: 0 !important; }
  .offers-list-navbar-container div.choices {
    margin-bottom: 0 !important; }

.company-description-block {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  width: calc(100% - 295px); }
  .company-description-block > div {
    width: 70%; }
  .company-description-block .company-name {
    font-size: 18px;
    font-weight: 500;
    color: #14669B;
    margin-bottom: 10px; }
  .company-description-block .verified-block {
    width: 27px;
    height: auto;
    margin-left: 3px; }
  .company-description-block .block-rating {
    font-size: 14px;
    color: #707070;
    font-weight: 400;
    margin-bottom: 6px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center; }
    .company-description-block .block-rating p {
      margin-bottom: 0; }
    .company-description-block .block-rating span {
      color: #2581BC;
      margin-right: 10px; }
    .company-description-block .block-rating .fa-star {
      color: #fff;
      font-size: 6px;
      padding: 3px;
      background-color: #2581BC; }
  .company-description-block .company-description-text {
    color: #707070;
    margin-bottom: 6px; }
    .company-description-block .company-description-text span {
      color: #062336; }

.block-for-employer {
  background-color: #F5FBFF;
  padding: 30px 35px;
  margin-bottom: 45px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between; }
  .block-for-employer p {
    font-size: 20px;
    font-weight: 600;
    color: #60A9E9;
    margin-bottom: 0;
    width: calc(100% - 175px); }
  .block-for-employer button {
    width: 167px; }

.about-company-characteristics {
  padding: 0;
  max-width: 483px;
  width: 100%;
  margin-bottom: 19px; }
  .about-company-characteristics > li {
    margin-bottom: 5px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between; }
    .about-company-characteristics > li:nth-last-child(1) {
      margin-bottom: 0; }
    .about-company-characteristics > li > div {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      width: 36%; }
  .about-company-characteristics p {
    margin-bottom: 0;
    color: #707070;
    width: 64%; }
  .about-company-characteristics .rating-indicator {
    font-size: 12px;
    color: #5E5E5E; }
  .about-company-characteristics .rating-list-icons {
    padding: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    margin: 0 5px 0 12px; }
    .about-company-characteristics .rating-list-icons li {
      margin-right: 5px;
      background-color: #D5D5D5;
      color: #fff;
      width: 17px;
      height: 17px;
      font-size: 13px;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      -webkit-justify-content: center;
      -moz-justify-content: center;
      -ms-justify-content: center;
      justify-content: center;
      -ms-flex-pack: center; }
    .about-company-characteristics .rating-list-icons .active-icon {
      background-color: #2581BC; }
  .about-company-characteristics-subtitle {
    font-size: 16px;
    margin-bottom: 10px;
    color: #062336; }

.block-recommendation {
  max-width: 483px;
  width: 100%;
  margin-bottom: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between; }
  .block-recommendation p {
    margin-bottom: 0; }
    .block-recommendation p:nth-child(2) {
      width: 36%;
      padding-left: 12px; }

.red {
  color: #D80614; }

.green {
  color: #0CAA41; }

.dropdown-title-about {
  cursor: pointer; }
  .dropdown-title-about i {
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    margin-left: 15px; }
  .dropdown-title-about.active-el i {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg); }

.last-item {
  margin-bottom: 0; }

.photo-gallery-about-us {
  margin-bottom: 35px;
  padding: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  .photo-gallery-about-us li {
    width: 24%;
    margin-bottom: 1%;
    margin-right: 1%; }

.video-about-company {
  margin: auto;
  display: block;
  width: 560px;
  height: 315px; }

.form-company-page-vacancy {
  padding-top: 0;
  margin-bottom: 30px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between; }
  .form-company-page-vacancy .btn-info {
    background-color: #5BC0DE;
    border-color: #5BC0DE; }
    .form-company-page-vacancy .btn-info:hover {
      background-color: #1b9bc1;
      border-color: #1b9bc1; }
  .form-company-page-vacancy .form-control {
    width: 100%;
    color: #000000; }
  .form-company-page-vacancy .offers-list-navbar-container {
    width: 45%; }

.list-reviews-сompany {
  padding: 35px 0 0; }

.reviews-company-item-header {
  background-color: #ECF5FC;
  border: 1px solid #E6F3FF;
  border-bottom: none;
  margin-bottom: 30px; }
  .reviews-company-item-header p {
    margin-bottom: 0; }
  .reviews-company-item-header > div {
    border-bottom: 1px solid #E6F3FF;
    padding: 11px 30px 13px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between; }
  .reviews-company-item-header .dropdown-reviews-company {
    display: none; }
  .reviews-company-item-header .rating-date {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex; }
  .reviews-company-item-header .rating-list-icons {
    padding: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    margin: 0 20px 0 8px; }
    .reviews-company-item-header .rating-list-icons li {
      margin-right: 5px;
      background-color: #D5D5D5;
      color: #fff;
      width: 17px;
      height: 17px;
      font-size: 13px;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      -webkit-justify-content: center;
      -moz-justify-content: center;
      -ms-justify-content: center;
      justify-content: center;
      -ms-flex-pack: center; }
    .reviews-company-item-header .rating-list-icons .active-icon {
      background-color: #14669B; }
  .reviews-company-item-header .about-company-characteristics {
    padding: 0;
    margin-bottom: 0; }
  .reviews-company-item-header.active-el .fa-angle-left {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg); }
  .reviews-company-item-header.active-el .dropdown-reviews-company {
    display: flex;
    margin-bottom: 20px;
    padding-top: 20px;
    padding-bottom: 30px; }
  .reviews-company-item-header .review-name-mob {
    display: none; }

.review-rating-detail {
  color: #14669B;
  margin-right: 20px;
  cursor: pointer; }
  .review-rating-detail i {
    margin-left: 15px;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg); }

.review-company-page {
  margin-bottom: 20px;
  position: relative; }

.review-good {
  padding: 10px 0 10px 20px; }
  .review-good:before {
    content: '';
    height: 100%;
    width: 3px;
    background-color: #0CAA41;
    position: absolute;
    left: 0;
    top: 0; }

.review-bed {
  padding: 0 0 20px 20px; }
  .review-bed:before {
    content: '';
    height: 100%;
    width: 3px;
    background-color: #D80614;
    position: absolute;
    left: 0;
    top: 0; }

.reviews-сompany-item-footer.footer-active .reviews-footer-bottom {
  display: inline-block;
  background-color: #ECF5FC; }

.reviews-сompany-item-footer .reviews-footer-top {
  margin-bottom: 30px;
  padding: 0 30px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: baseline;
  -moz-align-items: baseline;
  -ms-align-items: baseline;
  align-items: baseline;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  position: relative; }
  .reviews-сompany-item-footer .reviews-footer-top p {
    margin-bottom: 0; }
  .reviews-сompany-item-footer .reviews-footer-top ul {
    padding: 0;
    margin-left: 15px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center; }
    .reviews-сompany-item-footer .reviews-footer-top ul li {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      -webkit-justify-content: center;
      -moz-justify-content: center;
      -ms-justify-content: center;
      justify-content: center;
      -ms-flex-pack: center;
      padding: 0;
      color: #0CAA41; }
      .reviews-сompany-item-footer .reviews-footer-top ul li:nth-child(1) {
        padding-right: 10px; }
      .reviews-сompany-item-footer .reviews-footer-top ul li:nth-last-child(1) {
        padding-left: 10px; }
      .reviews-сompany-item-footer .reviews-footer-top ul li i {
        margin-right: 10px; }
    .reviews-сompany-item-footer .reviews-footer-top ul li:nth-last-child(1) {
      border-left: 1px solid #707070;
      color: #D80614; }
      .reviews-сompany-item-footer .reviews-footer-top ul li:nth-last-child(1) i {
        margin-right: 10px; }
  .reviews-сompany-item-footer .reviews-footer-top > div {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center; }

.reviews-сompany-item-footer .reviews-footer-bottom {
  width: 100%;
  padding-bottom: 30px;
  display: none; }
  .reviews-сompany-item-footer .reviews-footer-bottom textarea {
    resize: none;
    width: 100%;
    height: 160px;
    border: 1px solid #D5D5D5;
    margin-bottom: 15px;
    padding: 13px 16px; }

.reviews-сompany-item-footer .toggle-comments .toggle-comments-icon {
  display: none; }

.btn-leave-comment {
  cursor: pointer;
  position: relative;
  padding-left: 30px;
  margin: 0;
  color: #008BEB;
  position: absolute;
  left: 30px; }
  .btn-leave-comment:before {
    content: '';
    width: 20px;
    height: 20px;
    background: url(/images/company/icon-reviews.svg) no-repeat;
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%); }
  .btn-leave-comment .show-comments-icon {
    display: none; }

.block-more-reviews {
  padding: 0 20px 0 30px; }
  .block-more-reviews p {
    margin: 20px 0 0; }
  .block-more-reviews p:nth-child(1) {
    text-align: right; }
  .block-more-reviews p:nth-last-child(1) {
    text-align: left; }
  .block-more-reviews > div {
    margin: 35px 0 20px;
    width: 100%;
    text-align: right; }

.block-reviews-media {
  padding: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  .block-reviews-media li {
    width: calc(16.6% - 12.5px);
    margin-right: 15px;
    margin-bottom: 15px;
    max-height: 93px; }
  .block-reviews-media li:nth-child(6n + 6) {
    margin-right: 0; }
  .block-reviews-media iframe {
    width: 100%;
    height: auto;
    max-height: 93px; }

.company-banner {
  width: 100%;
  height: auto;
  margin-top: 75px; }

@media only screen and (max-width: 991px) {
  .block-for-employer p {
    font-size: 12px; }
  .reviews-footer-top {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: flex-end;
    -moz-align-items: flex-end;
    -ms-align-items: flex-end;
    align-items: flex-end;
    width: 100%; }
    .reviews-footer-top .toggle-comments {
      margin: 0 !important;
      right: 30px; }
      .reviews-footer-top .toggle-comments span {
        display: none; }
      .reviews-footer-top .toggle-comments .toggle-comments-icon {
        display: inline-block;
        color: #008BEB;
        font-size: 15px;
        transform: scale(-1, 1); }
  .reviews-сompany-item-footer .reviews-footer-top .vote-wrap {
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
    width: 100%;
    margin-top: 18px;
    position: relative;
    right: 0; }
    .reviews-сompany-item-footer .reviews-footer-top .vote-wrap p {
      font-size: 12px;
      text-align: left;
      color: #6C757D; }
  .reviews-сompany-item-footer .reviews-footer-top ul li:nth-last-child(1) i {
    margin-right: 4px; }
  .reviews-сompany-item-footer .reviews-footer-top ul li i {
    margin-right: 3px; }
  .reviews-сompany-item-footer .reviews-footer-top > div > div .no-comments-title {
    color: #707070;
    padding-top: 16px; }
  .reviews-сompany-item-footer.footer-active .reviews-footer-bottom > div {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
    .reviews-сompany-item-footer.footer-active .reviews-footer-bottom > div button {
      margin: 0 auto 10px !important; }
  .company-page .logo-company {
    margin-left: 0;
    margin-right: 28px; }
  .company-page .btn-leave-comment {
    padding-left: 0; }
    .company-page .btn-leave-comment:before,
    .company-page .btn-leave-comment span {
      display: none; }
    .company-page .btn-leave-comment .show-comments-icon {
      display: inline-block;
      font-size: 14px;
      color: #008BEB; }
  .company-page .comment-list-wrap {
    padding-top: 17px; }
  .company-description-block {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%; }
    .company-description-block .block-rating {
      margin-bottom: 0; }
  .company-desk-tabs #tabsAboutCompany {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
    .company-desk-tabs #tabsAboutCompany > div:nth-child(1), .company-desk-tabs #tabsAboutCompany > div:nth-child(2) {
      padding: 16px 36px 16px 16px;
      width: 100%;
      margin-bottom: 10px; }
  .company-desk-tabs .nav-tabs {
    margin-bottom: 10px; }
  .company-banner {
    margin-top: 20px; } }

@media only screen and (max-width: 767px) {
  .block-for-employer {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center; }
    .block-for-employer p {
      width: 100%; }
    .block-for-employer button {
      margin: 10px auto 0; }
  .about-company-characteristics .rating-list-icons {
    margin: 0 5px; }
  .video-about-company {
    max-width: 100%;
    height: 200px; }
  .photo-gallery-about-us li {
    width: 32%; }
  .reviews-company-item-header {
    margin-bottom: 20px; }
    .reviews-company-item-header > div {
      -webkit-flex-direction: column;
      -moz-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      padding: 8px 10px 11px; }
    .reviews-company-item-header .review-name-desc {
      display: none; }
    .reviews-company-item-header .rating-date {
      -webkit-flex-wrap: wrap;
      -moz-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-justify-content: space-between;
      -moz-justify-content: space-between;
      -ms-justify-content: space-between;
      justify-content: space-between;
      -ms-flex-pack: space-between;
      width: 100%; }
      .reviews-company-item-header .rating-date p {
        width: 50%;
        text-align: center; }
      .reviews-company-item-header .rating-date .rating-indicator {
        display: none; }
      .reviews-company-item-header .rating-date .review-name-mob {
        display: inline-block;
        order: 1;
        text-align: left; }
      .reviews-company-item-header .rating-date .review-date {
        order: 2;
        text-align: right;
        margin-bottom: 5px; }
    .reviews-company-item-header .review-rating-detail {
      margin: 0;
      order: 4; }
      .reviews-company-item-header .review-rating-detail i {
        display: none; }
    .reviews-company-item-header .rating-list-icons {
      order: 3;
      margin: 0; }
  .reviews-footer-top .toggle-comments {
    right: 10px; }
  .block-reviews-media li {
    width: 30%;
    height: 75px; }
    .block-reviews-media li img {
      max-height: 100%; }
    .block-reviews-media li iframe {
      max-height: 70px; }
  .company-page-header {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center; }
    .company-page-header > div {
      width: 100%; }
  .company-page .vacancy-description p {
    font-size: 12px; }
  .company-page .block-padding {
    padding: 0 10px; }
  .company-page .btn-leave-comment {
    left: 10px; }
  .reviews-сompany-item-header .rating-list-icons {
    margin-top: 10px;
    margin-bottom: 10px; }
  .reviews-сompany-item-footer .reviews-footer-top {
    padding: 0 10px; }
    .reviews-сompany-item-footer .reviews-footer-top ul {
      margin-left: 10px; }
  .form-company-page-vacancy {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
    .form-company-page-vacancy .form-control {
      margin-bottom: 10px;
      width: 100%;
      max-width: 800px; }
    .form-company-page-vacancy .btn-info {
      width: 100%; }
  .company-description-block .company-name {
    margin-bottom: 5px; }
  .company-banner {
    margin-top: 8px; } }

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .company-page-header > div {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
  .company-page .logo-company {
    margin: 0 auto 12px; }
  .company-page .vacancies-list-item {
    padding: 10px;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start; }
    .company-page .vacancies-list-item > div {
      order: 2;
      width: 100%;
      font-size: 12px; }
  .company-page .vacancies-item-date {
    font-size: 12px;
    order: 1;
    margin-bottom: 3px; }
  .company-page .vacancy-title {
    margin-bottom: 5px; }
  .company-page .vacancy-description {
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row; }
  .company-description-block {
    width: 100%; }
    .company-description-block .btn-primary {
      margin: 20px auto 0; }
    .company-description-block > div {
      width: 100%; }
    .company-description-block .company-description-text {
      margin-bottom: 0; }
  .block-for-employer {
    padding: 15px 10px; }
  .company-desk-tabs .nav-tabs {
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
    margin: 0 10px 8px;
    /*      .nav-item {
              margin-right: 20px;
            }*/ }
    .company-desk-tabs .nav-tabs .nav-link {
      padding: 0;
      margin-right: 0; }
  .company-desk-tabs #tabsAboutCompany > div:nth-child(1), .company-desk-tabs #tabsAboutCompany > div:nth-child(2) {
    padding: 10px 11px; }
  .block-recommendation,
  .form-company-page-vacancy {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center; }
    .block-recommendation p,
    .form-company-page-vacancy p {
      width: 100%;
      color: #062336;
      margin-bottom: 10px; }
      .block-recommendation p:nth-child(2),
      .form-company-page-vacancy p:nth-child(2) {
        width: 100%;
        padding-left: 0; }
  .form-company-page-vacancy {
    padding-top: 20px;
    margin-bottom: 20px; }
    .form-company-page-vacancy > div,
    .form-company-page-vacancy .offers-list-navbar-container {
      width: 100%;
      margin-bottom: 10px; }
    .form-company-page-vacancy p:nth-last-child(1) {
      text-align: center; }
    .form-company-page-vacancy .form-control {
      margin-bottom: 0; }
  .about-company-characteristics > li {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start;
    margin-bottom: 15px; }
    .about-company-characteristics > li > div {
      width: 100%; }
    .about-company-characteristics > li p {
      width: 100%;
      margin-bottom: 10px; }
  .about-company-characteristics .rating-indicator {
    width: 100%;
    font-size: 14px;
    color: #6C757D;
    margin-bottom: 0; }
  .about-company-characteristics .rating-list-icons {
    margin: 0 13px 0 0; } }

@media only screen and (max-width: 600px) and (orientation: portrait) {
  .photo-gallery-about-us li {
    width: 49%; }
  .block-reviews-media li {
    width: 44%; } }

.contact-us .form-group.has-error input.form-control,
.contact-us .form-group.has-error textarea.form-control {
  border-color: #DC3545; }

.contact-us .form-group.has-error label {
  color: #DC3545; }

.contact-us input.form-control {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  height: 2.5rem; }

.contact-us textarea.form-control {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  resize: none; }

.contact-us input.form-control:focus,
.contact-us textarea.form-control:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none; }

.contact-us label.required::after {
  content: "*";
  color: red;
  padding-left: 5px; }

.form-control,
.btn {
  border-radius: 0; }

.text-info {
  color: #008BEB !important; }

.border-info {
  border-color: #BDE4FF !important; }

.form-control {
  padding-top: 0; }

.form-control {
  border-color: #e0e0e0; }
  .form-control::-webkit-input-placeholder {
    background-color: #fff; }
  .form-control::-moz-placeholder {
    background-color: #fff; }
  .form-control:-ms-input-placeholder {
    background-color: #fff; }
  .form-control:-moz-placeholder {
    background-color: #fff; }

.block-dropdown {
  margin-bottom: 10px; }
  .block-dropdown-title {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
    cursor: pointer; }
    .block-dropdown-title .fas {
      color: #419DE4; }
  .block-dropdown .block-dropdown-title-icon {
    display: none; }
  .block-dropdown-content {
    display: none; }
  .block-dropdown.is-open .block-dropdown-title .fas {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg); }
  .block-dropdown.is-open .block-dropdown-content {
    display: block; }

.block-dropdown-title .block-dropdown-title-icon {
  display: inline-block; }

.form-block-dropdown-content .fa-info-circle {
  font-size: 10px;
  color: #008BEB;
  margin-bottom: 10px;
  margin-left: 3px; }

.new-job-add-page {
  min-height: calc(100vh - 415px); }
  .new-job-add-page .step-title {
    padding: 17px 20px;
    background-color: #DAF0FF; }
  .new-job-add-page .btn-primary {
    padding: 0.27rem 0.72rem; }
  .new-job-add-page .big-btn {
    padding: 0.375rem 0.72rem; }
  .new-job-add-page .was-validated .form-control:invalid, .new-job-add-page .form-control.is-invalid {
    background-image: none; }
  .new-job-add-page .was-validated .form-control:valid, .new-job-add-page .form-control.is-valid {
    background-image: none;
    border-color: #00B955; }
  .new-job-add-page .block-title-blue {
    font-size: 18px;
    font-weight: 600;
    color: #008BEB;
    margin-bottom: 0; }
  .new-job-add-page-content {
    width: 100%; }
    .new-job-add-page-content-left .choices__inner {
      background-color: #fff;
      border-color: #e0e0e0; }
  .new-job-add-page .vacancy-description-text {
    font-size: 10px; }
  .new-job-add-page .form-field-title {
    font-size: 14px;
    color: #062336;
    font-weight: 500;
    margin-bottom: 0;
    line-height: 20px; }
    .new-job-add-page .form-field-title__mob {
      display: none; }
    .new-job-add-page .form-field-title span {
      font-weight: 400; }
  .new-job-add-page .block-file-field .btn-add-files {
    height: 100%; }
  .new-job-add-page .img-list,
  .new-job-add-page .video-list {
    display: inline; }
  .new-job-add-page .loading-conditions {
    position: absolute;
    left: 0;
    top: 100%;
    font-size: 7px;
    padding-top: 10px;
    margin-bottom: 0; }
  .new-job-add-page .block-file-field > div {
    margin-bottom: 60px; }
  .new-job-add-page .rate-name {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    margin-top: 30px; }
  .new-job-add-page .rate-description {
    font-size: 14px; }
    .new-job-add-page .rate-description a {
      color: #008BEB;
      text-decoration: underline; }
      .new-job-add-page .rate-description a:hover {
        text-decoration: none; }
  .new-job-add-page .last-block {
    margin-bottom: 40px; }
  .new-job-add-page .file-max-size {
    font-size: 10px;
    margin-bottom: 0; }
  .new-job-add-page .help-text {
    font-size: 12px;
    margin-bottom: 0; }
  .new-job-add-page .block-content-filter .locations-select-container .choices[data-type*="select-one"] .choices__inner {
    padding-left: 11px;
    padding-bottom: 4.5px; }
  .new-job-add-page .block-content-filter .locations-select-container .choices__list--single .choices__item {
    color: #062336; }
  .new-job-add-page .block-content-filter .locations-select-container .choices[data-type*="select-one"]:after {
    content: "";
    background: url("/images/select-icon.svg") #fff no-repeat;
    background-size: 100% 100%;
    width: 9px;
    height: 11px;
    border: none;
    position: absolute;
    right: 7px;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    margin-top: 0; }
  .new-job-add-page .is-invalid-block label {
    color: #DC3545 !important; }
  .new-job-add-page .is-invalid-block .invalid-feedback {
    display: inline-block; }
  .new-job-add-page .is-invalid-block .wrap-form-control,
  .new-job-add-page .is-invalid-block .choices__inner {
    border-color: #DC3545 !important; }
  .new-job-add-page .is-invalid-block .ql-toolbar {
    border-left-color: #DC3545;
    border-right-color: #DC3545;
    border-top-color: #DC3545; }
  .new-job-add-page .is-invalid-block .ql-container {
    border-left-color: #DC3545;
    border-right-color: #DC3545;
    border-bottom-color: #DC3545; }
  .new-job-add-page .new-job-add-block-wrap {
    border: 1px solid #e0e0e0;
    padding: 17px 20px 4px;
    margin-bottom: 20px; }
    .new-job-add-page .new-job-add-block-wrap__active {
      border: 1px solid #B4B4B4; }
      .new-job-add-page .new-job-add-block-wrap__active .additional-information-block-title,
      .new-job-add-page .new-job-add-block-wrap__active .btn-show-additional-information-block .fa-chevron-down {
        color: #062336; }
    .new-job-add-page .new-job-add-block-wrap__new {
      padding: 27px 20px 30px; }
    .new-job-add-page .new-job-add-block-wrap-title {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 20px;
      margin-top: 3px; }
    .new-job-add-page .new-job-add-block-wrap-row {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: flex-start;
      -moz-align-items: flex-start;
      -ms-align-items: flex-start;
      align-items: flex-start;
      -webkit-justify-content: space-between;
      -moz-justify-content: space-between;
      -ms-justify-content: space-between;
      justify-content: space-between;
      -ms-flex-pack: space-between; }
      .new-job-add-page .new-job-add-block-wrap-row-left {
        width: 31%; }
      .new-job-add-page .new-job-add-block-wrap-row-right {
        width: 68%; }
        .new-job-add-page .new-job-add-block-wrap-row-right textarea {
          height: 85px;
          resize: none;
          margin-top: 0;
          padding-top: 7px;
          margin-top: 0 !important; }
      .new-job-add-page .new-job-add-block-wrap-row .wrap-form-control {
        border: 1px solid #e0e0e0; }
        .new-job-add-page .new-job-add-block-wrap-row .wrap-form-control .form-control {
          border: none;
          width: calc(100% - 7px);
          color: #062336;
          background: url("/images/select-icon.svg") no-repeat right 50% #fff;
          background-size: 9px 11px;
          -webkit-appearance: none;
          -moz-appearance: none;
          -ms-appearance: none;
          appearance: none; }
  .new-job-add-page .offer-title {
    font-size: 24px;
    margin: 18px 0 20px; }
  .new-job-add-page .add-offer-desc {
    margin-bottom: 20px; }
  .new-job-add-page .additional-information-title {
    font-size: 20px;
    margin-bottom: 10px;
    color: #6C757D;
    font-weight: 500; }
    .new-job-add-page .additional-information-title__active {
      color: #062336; }
  .new-job-add-page .additional-information-block-title {
    font-size: 14px;
    color: #B4B4B4;
    margin: 0; }
  .new-job-add-page .btn-show-additional-information-block {
    border: none;
    padding: 0;
    background: none;
    margin-bottom: 12px; }
    .new-job-add-page .btn-show-additional-information-block .fa-chevron-down {
      font-size: 12px;
      color: #B4B4B4; }
  .new-job-add-page .btn-edit-contacts {
    color: #008BEB;
    margin-top: 15px;
    cursor: pointer;
    display: inline-block; }
    .new-job-add-page .btn-edit-contacts.btn-edit-contacts__is-edit .btn-edit-contacts__on {
      display: none; }
    .new-job-add-page .btn-edit-contacts.btn-edit-contacts__is-edit .btn-edit-contacts__off {
      display: inline-block; }
    .new-job-add-page .btn-edit-contacts .btn-edit-contacts__off {
      display: none;
      border-bottom: 1px dotted; }
    .new-job-add-page .btn-edit-contacts .btn-edit-contacts__on {
      display: inline-block;
      border-bottom: 1px dotted; }

.list-downloaded-files {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: flex-start;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  .list-downloaded-files .uploader {
    outline: 1px solid #B2D6EE;
    color: #008BEB;
    width: 80px;
    height: 80px;
    position: relative;
    cursor: pointer;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    margin-right: 20px;
    overflow: hidden; }
    .list-downloaded-files .uploader .fa-image {
      font-size: 26px;
      color: #B2D6EE; }
    .list-downloaded-files .uploader:hover {
      outline: 1px solid #69C1EE; }
  .list-downloaded-files .uploader input,
  .list-downloaded-files .uploader img,
  .list-downloaded-files .uploader .icon {
    display: none; }
  .list-downloaded-files .uploader,
  .list-downloaded-files .uploader .icon {
    -webkit-transition: all 100ms ease-in;
    -moz-transition: all 100ms ease-in;
    -ms-transition: all 100ms ease-in;
    -o-transition: all 100ms ease-in;
    transition: all 100ms ease-in; }
  .list-downloaded-files .uploader .icon {
    color: #eee;
    color: rgba(0, 0, 0, 0.2);
    font-size: 5em; }
  .list-downloaded-files .uploader.dragging {
    outline-color: orangered; }
  .list-downloaded-files .uploader.dragging .icon {
    color: orangered; }
  .list-downloaded-files .uploader.loaded .icon {
    color: rgba(255, 255, 255, 0.5); }
  .list-downloaded-files .uploader.loaded .fa-image {
    display: none; }
  .list-downloaded-files .uploader.loaded .btn-remove-img {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex; }
  .list-downloaded-files .uploader img {
    opacity: 0;
    position: absolute;
    max-height: 80px;
    width: auto;
    height: auto;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: -1; }
  .list-downloaded-files .uploader img.loaded {
    opacity: 1;
    display: inline-block;
    z-index: 0; }
  .list-downloaded-files .btn-remove-img {
    width: 19px;
    height: 19px;
    position: absolute;
    right: 0;
    top: 0;
    border: none;
    background-color: #5E5E5E;
    color: #fff;
    font-size: 12px;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    display: none; }

.block-downloaded-files p {
  margin-bottom: 0;
  color: #6C757D; }

.block-content-filter {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between; }
  .block-content-filter .left-part {
    width: calc(100% - 300px); }
    .block-content-filter .left-part .is-invalid-block label {
      color: #DC3545; }
    .block-content-filter .left-part .is-invalid-block .invalid-feedback {
      display: inline-block;
      font-size: 12px; }
    .block-content-filter .left-part .choices {
      margin-bottom: 0; }
    .block-content-filter .left-part > p:nth-child(1) {
      margin-top: 0; }

.files-add-btn-wrap {
  position: relative;
  display: inline-block; }
  .files-add-btn-wrap span.label {
    background: #fff;
    border: 1px solid #B2D6EE;
    padding: 10px 0;
    color: #008BEB;
    width: 117px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
    .files-add-btn-wrap span.label span {
      font-size: 50px;
      font-weight: 600; }

.block-contacts-list {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  .block-contacts-list-item {
    width: calc(50% - 15px);
    max-width: 372px;
    margin-right: 15px;
    margin-bottom: 20px; }
    .block-contacts-list-item .form-control {
      padding-left: 40px;
      margin-top: 16px; }
    .block-contacts-list-item .form-group {
      position: relative;
      margin-bottom: 5px !important; }
      .block-contacts-list-item .form-group i {
        position: absolute;
        top: 9px;
        left: 12px;
        color: #707070; }
  .block-contacts-list .block-communication-method {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 0 !important; }
    .block-contacts-list .block-communication-method .btn-communication-method {
      border: none;
      background-color: #B4B4B4;
      color: #fff;
      line-height: 30px;
      padding: 0 20px;
      cursor: pointer;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      margin-bottom: 0; }
      .block-contacts-list .block-communication-method .btn-communication-method.active {
        background-color: #00B955; }
        .block-contacts-list .block-communication-method .btn-communication-method.active img {
          display: block; }
      .block-contacts-list .block-communication-method .btn-communication-method img {
        margin-left: 20px;
        display: none;
        width: 13px;
        height: auto; }
    .block-contacts-list .block-communication-method > div {
      width: 100%;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      -webkit-justify-content: space-between;
      -moz-justify-content: space-between;
      -ms-justify-content: space-between;
      justify-content: space-between;
      -ms-flex-pack: space-between; }
  .block-contacts-list .rotate {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg); }
  .block-contacts-list .btn-del {
    margin-bottom: 0;
    color: #008BEB;
    cursor: pointer; }
  .block-contacts-list .btn-del-contact {
    margin-bottom: 0;
    color: #008BEB;
    cursor: pointer; }

.block-add-new-contact {
  width: 100%;
  text-align: right;
  margin-bottom: 30px; }
  .block-add-new-contact button {
    margin-left: 30px; }

.new-job-add-page .block-add-new-contact {
  width: calc(100% - 300px); }

.btn-gray {
  background-color: #C3C3C3;
  border-color: #C3C3C3;
  color: #fff; }

.tariff-pro-price {
  padding-top: 20px; }
  .tariff-pro-price-title {
    font-weight: 600;
    margin-bottom: 10px; }
  .tariff-pro-price ul {
    padding: 0; }
  .tariff-pro-price li {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between; }
    .tariff-pro-price li p {
      margin-bottom: 0; }
    .tariff-pro-price li > p {
      width: 65%; }
    .tariff-pro-price li div {
      width: 29%;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      -webkit-justify-content: space-between;
      -moz-justify-content: space-between;
      -ms-justify-content: space-between;
      justify-content: space-between;
      -ms-flex-pack: space-between; }
      .tariff-pro-price li div > p:nth-last-child(1) {
        text-align: right;
        min-width: 35%; }
  .tariff-pro-price .amount-to-paid {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    -ms-justify-content: flex-end;
    justify-content: flex-end;
    -ms-flex-pack: flex-end; }
    .tariff-pro-price .amount-to-paid p {
      font-weight: 600;
      padding-left: 30px; }

.payment-method-title {
  font-weight: 600;
  margin-bottom: 25px; }

.payment-method-list {
  padding: 0; }
  .payment-method-list li {
    margin-bottom: 10px; }
  .payment-method-list label {
    margin-left: 13px; }

.payment-method-content {
  margin: 10px 0 20px;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  display: none; }

.payment-method-active .payment-method-content {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex; }

.payment-systems {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center; }
  .payment-systems div {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center; }
  .payment-systems img {
    height: 16px;
    width: auto;
    margin-right: 5px; }
  .payment-systems p {
    margin-bottom: 0; }

.form-invoice {
  margin-top: 20px;
  width: 100%; }
  .form-invoice > div {
    width: 100%; }
  .form-invoice .block-form-invoice-fields {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between; }
    .form-invoice .block-form-invoice-fields > div {
      width: calc(50% - 15px); }
      .form-invoice .block-form-invoice-fields > div > p {
        color: #6C757D; }

.field-add-file {
  width: 100%; }
  .field-add-file p {
    font-size: 10px;
    margin-bottom: 0; }
  .field-add-file .btn-delete-file {
    width: 40px;
    height: 33px;
    border: 1px solid #008BEB;
    margin-left: 16px;
    background: none; }
    .field-add-file .btn-delete-file i {
      color: #008BEB; }
  .field-add-file > div {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center; }

.fileUpload {
  position: relative;
  overflow: hidden; }

.fileUpload input.upload {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0); }

.btn--browse {
  color: black;
  height: 33px;
  width: 40px;
  background: url(/images/upload-file.svg) center center #008BEB no-repeat;
  background-size: 13px; }

.f-input {
  height: 33px;
  border: 1px solid #e0e0e0;
  width: calc(100% - 54px);
  float: left;
  padding: 0 14px;
  margin-right: 0; }
  .f-input:disabled {
    background-color: #fff; }

.phone-field,
.email-field {
  display: none; }

.show-field-phone .phone-field {
  display: block; }

.show-field-email .email-field {
  display: block; }

.castome-radio-btn {
  margin-top: 0.2rem; }

.castome-radio-btn:after {
  content: '';
  width: 20px;
  height: 20px;
  border-radius: 50%;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #F0F0F0;
  display: inline-block;
  visibility: visible;
  border: 1px solid #D5D5D5; }

.castome-radio-btn:checked:before {
  content: '';
  width: 20px;
  height: 20px;
  border-radius: 50%;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #F0F0F0;
  display: inline-block;
  visibility: visible;
  border: 1px solid #D5D5D5; }

.castome-radio-btn:checked:after {
  content: '';
  width: 12px;
  height: 12px;
  border-radius: 50%;
  top: 2px;
  left: 3px;
  position: absolute;
  background-color: #008BEB;
  display: inline-block;
  visibility: visible;
  border: none;
  z-index: 1; }

.tooltips {
  position: relative; }
  .tooltips .tooltiptext {
    width: 90vw;
    color: #000;
    text-align: center;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 10px; }
  .tooltips:hover .tooltiptext {
    visibility: visible;
    opacity: 1; }

.block-add-contact {
  text-align: left; }
  .block-add-contact .btn {
    margin-left: 0; }

.wrap-for-form-field {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  border: 1px solid #e0e0e0; }
  .wrap-for-form-field input {
    border: none;
    padding-left: 0;
    line-height: 3; }
  .wrap-for-form-field i {
    color: #868686; }
  .wrap-for-form-field div {
    width: 30px;
    height: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center; }

.tariff-notice-block {
  background-color: #F3FAFF;
  padding: 18px 20px 18px 25px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  width: calc(100% - 300px); }
  .tariff-notice-block-text {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center; }
    .tariff-notice-block-text i {
      color: #ffdb49;
      font-size: 19px;
      margin-right: 20px; }

.list-applied-services {
  padding-left: 33%; }
  .list-applied-services .custom-checkbox .custom-control-label::before {
    border-radius: 0;
    width: 20px;
    height: 20px;
    left: -30px;
    top: 0; }
  .list-applied-services .custom-checkbox .custom-control-label::after {
    width: 20px;
    height: 20px;
    left: -30px;
    top: 0; }
  .list-applied-services .custom-control {
    margin-bottom: 10px;
    padding-left: 27px; }
    .list-applied-services .custom-control:nth-last-child(1) {
      margin-bottom: 0; }
  .list-applied-services .custom-control-label,
  .list-applied-services p {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400; }

.service-ischecked-block:nth-last-child(1) {
  margin-bottom: 0; }

.service-ischecked-block .fa-check-circle {
  font-size: 18px;
  color: #28A745;
  margin-right: 9px; }

@media only screen and (max-width: 1199px) {
  .block-contacts-list-item {
    width: calc(50% - 5px);
    margin-right: 5px;
    margin-bottom: 30px; }
    .block-contacts-list-item:nth-child(2n + 2) {
      margin-left: 5px; }
  .block-contacts-list .block-communication-method .btn-communication-method {
    font-size: 11px;
    line-height: 28px; }
    .block-contacts-list .block-communication-method .btn-communication-method img {
      margin-left: 10px; }
  .tariff-pro-price li > p {
    width: 65%; }
  .tariff-pro-price li div {
    width: 47%; }
  .payment-method-content {
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
  .form-invoice .block-form-invoice-fields > div {
    width: calc(50% - 5px); }
  .form-invoice .block-form-invoice-fields .form-control {
    font-size: 12px; }
  .indent-mob {
    margin-top: 15px; } }

@media only screen and (max-width: 1024px) {
  .new-job-add-page .block-add-new-contact,
  .block-content-filter .left-part {
    width: calc(100% - 250px); }
  .new-job-add-sitebar {
    width: 240px; } }

@media only screen and (max-width: 991px) {
  .block-content-filter .left-part {
    width: calc(100% - 250px); }
  .block-content-filter .left-part {
    width: 100%; }
  .block-content-filter #manage_form_title_help {
    display: none; }
  .new-job-add-page .new-job-add-block-wrap__new {
    padding: 17px 20px 20px; }
  .new-job-add-page .new-job-add-block-wrap-row {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
    .new-job-add-page .new-job-add-block-wrap-row-left, .new-job-add-page .new-job-add-block-wrap-row-right {
      width: 100%; }
    .new-job-add-page .new-job-add-block-wrap-row__flex {
      -webkit-flex-direction: row;
      -moz-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row; }
      .new-job-add-page .new-job-add-block-wrap-row__flex-left {
        width: 31%; }
      .new-job-add-page .new-job-add-block-wrap-row__flex-right {
        width: 68%; }
  .new-job-add-page .on-mob-text-normal {
    font-weight: 400;
    margin-bottom: 1rem; }
  .new-job-add-page .font-weight-normal {
    font-weight: 400 !important; }
  .new-job-add-page .offer-title {
    margin: 6px 0 20px; }
  .tariff-notice-block {
    margin: 0;
    padding: 18px 12px;
    width: 100%; }
    .tariff-notice-block-text {
      width: 100%; }
  .block-add-new-contact {
    width: calc(100% - 250px); }
  .list-applied-services {
    padding-left: 0; }
    .list-applied-services .custom-checkbox .custom-control-label::before {
      left: -27px; }
    .list-applied-services .custom-checkbox .custom-control-label::after {
      left: -27px; } }

@media only screen and (max-width: 850px) {
  .block-content-filter {
    padding-top: 0;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
    .block-content-filter .left-part {
      width: 100%; }
    .block-content-filter .professions-autocomplete .form-text {
      margin: 0; }
  .new-job-add-page .container {
    position: relative; }
  .new-job-add-page h2 {
    font-size: 18px;
    margin-bottom: 0; }
  .new-job-add-page-content .form-text-mob {
    margin: 0 0 5px; }
  .new-job-add-page .form-field-title {
    margin-bottom: 7px !important; }
  .new-job-add-page .btn--browse,
  .new-job-add-page .f-input {
    height: 35px; }
  .new-job-add-page .btn-primary {
    padding: 0 20px;
    line-height: 35px;
    margin: 0; }
  .new-job-add-page .block-add-new-contact {
    width: 100%; }
  .new-job-add-filter textarea {
    padding-top: 1px; }
  .field-add-file p {
    font-size: 12px; }
  .block-add-new-contact,
  .list-downloaded-files {
    margin-bottom: 0; }
  .block-add-contact {
    margin-bottom: 18px; }
  .block-contacts-list-item .form-control {
    margin-top: 0; }
  .block-contacts-list-item .form-group i {
    top: 12px;
    left: 14px;
    font-size: 12px; }
  .block-contacts-list .block-communication-method {
    margin-top: 15px; }
    .block-contacts-list .block-communication-method > p {
      display: none; }
    .block-contacts-list .block-communication-method > div {
      -webkit-align-items: flex-start;
      -moz-align-items: flex-start;
      -ms-align-items: flex-start;
      align-items: flex-start;
      -webkit-flex-direction: column;
      -moz-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column; }
    .block-contacts-list .block-communication-method .btn-communication-method {
      font-size: 14px;
      line-height: 30px; }
    .block-contacts-list .block-communication-method .form-group {
      margin-bottom: 20px !important; }
  .show-field-email .email-field,
  .show-field-phone .phone-field {
    margin-bottom: 20px !important; } }

@media only screen and (max-width: 767px) {
  .step-title {
    padding: 11px 14px; }
  .new-job-add-page .block-title-blue {
    font-size: 12px; }
  .new-job-add-page .offer-title {
    margin: 0 0 20px;
    font-size: 18px; }
  .new-job-add-page .add-offer-desc {
    margin-bottom: 20px; }
  .new-job-add-page .new-job-add-block-wrap {
    border: none;
    padding: 0;
    margin-bottom: 0; }
    .new-job-add-page .new-job-add-block-wrap__new {
      padding: 0 0 16px; }
    .new-job-add-page .new-job-add-block-wrap-title {
      display: none; }
    .new-job-add-page .new-job-add-block-wrap-row__flex {
      padding: 14px 10px 2px;
      border: 1px solid #e0e0e0;
      margin-bottom: 10px; }
      .new-job-add-page .new-job-add-block-wrap-row__flex .new-job-add-block-wrap-row-left {
        width: 80%; }
      .new-job-add-page .new-job-add-block-wrap-row__flex .new-job-add-block-wrap-row-right {
        width: 20%; }
  .new-job-add-page .btn-save-mob {
    margin: 20px 0 0; }
  .new-job-add-page .for-line-mob {
    padding-bottom: 20px;
    position: relative; }
    .new-job-add-page .for-line-mob:before {
      content: '';
      height: 1px;
      width: calc(100% + 30px);
      background-color: #e0e0e0;
      position: absolute;
      left: -15px;
      bottom: 0; }
  .new-job-add-page .additional-information-title {
    font-size: 16px;
    margin-top: 20px;
    font-weight: 400; }
  .new-job-add-page .knowing-languages-mob {
    margin-bottom: 20px; }
  .new-job-add-page .uploads-gallery-block {
    margin-bottom: 8px !important; }
  .new-job-add-page .form-field-title__mob {
    display: inline-block; }
  .list-downloaded-files .uploader {
    width: 94px;
    height: 94px; }
  .block-contacts-list {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
    .block-contacts-list-item {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px; }
  .rate-card-list,
  .form-invoice .block-form-invoice-fields,
  .payment-systems,
  .tariff-pro-price li {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
  .rate-card-small,
  .rate-card {
    width: 100%;
    max-width: 345px;
    margin-bottom: 20px; }
  .form-invoice .block-form-invoice-fields > div {
    width: 100%; }
  .tariff-pro-price li div,
  .tariff-pro-price li > p {
    width: 100%; }
  .block-add-new-contact button {
    margin: auto 10px; }
  .block-file-field {
    margin-bottom: 80px; }
  .payment-systems div {
    margin-left: 0;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start; }
  .block-site-video-links {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; } }

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .tariff-notice-block {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: flex-end;
    -moz-align-items: flex-end;
    -ms-align-items: flex-end;
    align-items: flex-end; }
    .tariff-notice-block-text {
      margin-bottom: 15px; } }

.thanks-page {
  padding-top: 195px;
  text-align: center; }
  .thanks-page .btn {
    margin-top: 65px; }
  .thanks-page-title {
    font-weight: 600;
    margin-bottom: 40px; }
  .thanks-page img {
    width: 193px;
    height: auto;
    margin-bottom: 35px; }

a {
  color: #008BEB; }

@media only screen and (max-width: 767px) {
  .thanks-page {
    padding-top: 95px; }
    .thanks-page-title,
    .thanks-page img {
      margin-bottom: 15px; }
    .thanks-page .btn {
      margin-top: 15px; } }

.types-work-permit {
  margin-bottom: 10px; }
  .types-work-permit label {
    color: #062336; }
  .types-work-permit .form-check-input {
    width: 20px;
    height: 20px;
    margin-top: 0;
    background-color: #F0F0F0;
    border-radius: 0; }

.cv-photo-content {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  margin-bottom: 15px; }

.cv-photo-add {
  width: 100%;
  max-width: 227px; }
  .cv-photo-add p {
    font-size: 10px; }

.cv-photo-wrap {
  width: 100%;
  height: auto;
  margin-bottom: 15px;
  position: relative; }
  .cv-photo-wrap img {
    width: 100%;
    height: auto; }
  .cv-photo-wrap .btn-close-img {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    background-color: rgba(0, 139, 235, 0.3);
    width: 30px;
    height: 30px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center; }
    .cv-photo-wrap .btn-close-img i {
      font-size: 14px;
      color: #fff; }

.cv-content {
  width: calc(100% - 242px); }

.pt-title-js {
  padding-top: 13px; }

.new-cv-add-page {
  position: relative; }
  .new-cv-add-page .form-control {
    color: #062336; }
    .new-cv-add-page .form-control::-webkit-input-placeholder {
      color: #6C757D; }
    .new-cv-add-page .form-control::-moz-placeholder {
      color: #6C757D; }
    .new-cv-add-page .form-control:-ms-input-placeholder {
      color: #6C757D; }
    .new-cv-add-page .form-control:-moz-placeholder {
      color: #6C757D; }
  .new-cv-add-page .wrap-form-lang-select {
    width: calc(50% - 10px); }
    .new-cv-add-page .wrap-form-lang-select .form-control {
      color: #062336; }
  .new-cv-add-page .btn-control-lang {
    padding: 0 10px;
    height: calc(1.5em + 0.75rem + 2px);
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center; }
  .new-cv-add-page .jobseeker-lang-row {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between; }
  .new-cv-add-page .btn-add-languages-block {
    border: none;
    background: none;
    color: #008BEB;
    font-size: 14px; }
    .new-cv-add-page .btn-add-languages-block:focus {
      outline: none; }
  .new-cv-add-page .work-experience-block {
    width: 100%; }
    .new-cv-add-page .work-experience-block textarea {
      height: 100px;
      padding: 10px; }
  .new-cv-add-page .block-month-year {
    width: 100%;
    max-width: 340px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
    margin-bottom: 13px; }
    .new-cv-add-page .block-month-year input {
      width: calc(50% - 10px); }
  .new-cv-add-page .custom-checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0; }
  .new-cv-add-page .custom-checkbox + label::before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid #e0e0e0;
    margin-right: 10px;
    background-color: #F0F0F0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
    top: 4px;
    position: relative; }
  .new-cv-add-page .custom-checkbox:checked + label::before {
    border-color: #0074F0;
    background-color: #0074F0;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
    background-size: 13px 13px; }
  .new-cv-add-page .work-experience-result-block {
    margin-top: -10px; }
  .new-cv-add-page .work-experience-result-wrap {
    border: 1px solid #F3F3F3;
    background-color: #F3F3F3;
    padding: 10px;
    position: relative; }
  .new-cv-add-page .work-experience-result-title {
    font-weight: 500;
    width: 80%;
    margin-bottom: 10px; }
  .new-cv-add-page .work-experience-result-description, .new-cv-add-page .work-experience-result-autor {
    margin-bottom: 10px; }
  .new-cv-add-page .work-experience-result-date {
    margin-bottom: 0; }
  .new-cv-add-page .work-experience-result-btns {
    position: absolute;
    right: 10px;
    top: 10px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center; }
  .new-cv-add-page .fa-times {
    font-size: 16px;
    color: #6C757D;
    cursor: pointer; }
  .new-cv-add-page .fa-pen {
    cursor: pointer;
    font-size: 11px;
    color: #6C757D;
    margin-right: 15px; }
  .new-cv-add-page .form-field-title {
    line-height: 18px; }
  .new-cv-add-page .edit-block {
    border-color: #008BEB; }

.text-normal {
  font-weight: 400; }

@media only screen and (max-width: 991px) {
  .cv-photo-add {
    max-width: 170px; }
  .cv-content {
    width: calc(100% - 180px); }
  .new-cv-add-page .new-job-add-block-wrap-title {
    display: inline-block; }
  .new-cv-add-page .work-experience-result-block {
    margin-top: 0; }
  .new-cv-add-page .btn--browse {
    width: 54px; }
  .new-cv-add-page .field-add-file {
    margin-bottom: 0; }
  .new-cv-add-page .new-cv-pb {
    padding-bottom: 0; }
    .new-cv-add-page .new-cv-pb:before {
      display: none; }
  .new-cv-add-page .hide-mob {
    display: none; } }

@media only screen and (max-width: 767px) and (orientation: landscape) {
  .cv-photo-add {
    max-width: 177px; }
  .cv-content {
    width: calc(100% - 190px); } }

@media only screen and (max-width: 560px) {
  .cv-photo-add {
    max-width: 227px;
    margin: 0 auto 15px; }
  .cv-content {
    width: 100%; }
  .cv-photo-content {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; } }

.cv-page .form-notification-subscription-title p {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 0;
  text-align: center; }

.cv-page .vacancy-date {
  padding-right: 10px; }

.similar-resumes-list li {
  padding: 10px 20px 10px 20px;
  border-bottom: 1px solid #F0F0F0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between; }

.similar-resumes-list li:nth-last-child(1) {
  border-bottom: none; }

.similar-resumes-list-photo {
  width: 61px;
  height: 61px;
  overflow: hidden; }
  .similar-resumes-list-photo img {
    width: 100%;
    height: auto;
    max-height: 100%; }

.similar-resumes-card-content {
  width: calc(100% - 70px); }
  .similar-resumes-card-content .vacancy-tags {
    width: 100%; }

.date-place-cv {
  margin-top: 10px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: flex-start; }
  .date-place-cv > p {
    margin-bottom: 0; }

.form-response-vacancy {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  margin-bottom: 35px; }
  .form-response-vacancy input {
    width: calc(41.25% - 20px); }
  .form-response-vacancy > div {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
    width: 17.5%; }
    .form-response-vacancy > div button, .form-response-vacancy > div a {
      width: calc(50% - 10px);
      padding: .375rem 0; }
  .form-response-vacancy button {
    padding-left: 20px;
    padding-right: 20px; }

.block-response-vacancy .btn-show-filter-mob {
  display: none;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  width: 58px;
  height: 58px;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.4);
  position: fixed;
  bottom: 40px;
  right: 20px;
  background-color: rgba(255, 255, 255, 0.5);
  cursor: pointer; }
  .block-response-vacancy .btn-show-filter-mob i {
    font-size: 28px;
    color: #008BEB; }

.block-response-vacancy .btn-close {
  display: none;
  color: #008BEB;
  border: none;
  background: none; }

.table-response-vacancy {
  margin-bottom: 35px;
  border-bottom: 1px solid #e0e0e0; }
  .table-response-vacancy-header {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 10px; }
    .table-response-vacancy-header p {
      font-weight: 500;
      text-align: center;
      margin-bottom: 0; }
  .table-response-vacancy .col1 {
    width: 16%;
    padding: 0 10px; }
  .table-response-vacancy .col2 {
    width: 26%;
    padding: 0 10px;
    color: #676767; }
  .table-response-vacancy .col3 {
    width: 15%;
    padding: 0 10px;
    color: #676767; }
  .table-response-vacancy .col4 {
    width: 43%;
    padding: 0 10px; }
  .table-response-vacancy .col5 {
    width: 43%;
    padding: 0 10px; }
  .table-response-vacancy-body {
    padding: 0;
    margin-bottom: 0; }
  .table-response-vacancy-row {
    padding: 18px 0;
    border-bottom: 1px solid #e0e0e0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between; }
    .table-response-vacancy-row p {
      margin-bottom: 0;
      text-align: center; }
  .table-response-vacancy-row:nth-last-child(1) {
    border: none; }
  .table-response-vacancy .not-read {
    background-color: #F3FBFF; }
  .table-response-vacancy .when-no-response {
    padding: 30px 0;
    text-align: center;
    margin-bottom: 0; }

@media only screen and (max-width: 991px) {
  .form-response-vacancy input {
    width: calc(39% - 10px); }
  .form-response-vacancy > div {
    width: 22%; }
    .form-response-vacancy > div button {
      width: calc(50% - 5px); }
  .table-response-vacancy .col1 {
    width: 19%; }
  .table-response-vacancy .col2 {
    width: 27%; }
  .table-response-vacancy .col3 {
    width: 11%; } }

@media only screen and (max-width: 767px) {
  .table-response-vacancy-header,
  .col2,
  .form-response-vacancy {
    display: none; }
  .table-response-vacancy {
    border: none; }
    .table-response-vacancy .col1 {
      width: 69%;
      text-align: left; }
    .table-response-vacancy .col3 {
      width: 31%;
      padding-left: 0; }
    .table-response-vacancy .col4 {
      width: 100%; }
    .table-response-vacancy-row {
      -webkit-flex-wrap: wrap;
      -moz-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
      .table-response-vacancy-row:nth-child(1) {
        border-top: 1px solid #e0e0e0; }
      .table-response-vacancy-row .col4 {
        text-align: left;
        margin-top: 1px;
        color: #062336; }
    .table-response-vacancy-row, .table-response-vacancy-row:nth-last-child(1) {
      border-bottom: 1px solid #e0e0e0;
      padding: 10px 0; }
  .block-response-vacancy {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column-reverse;
    -moz-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse; }
    .block-response-vacancy .form-response-vacancy-show {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-wrap: wrap;
      -moz-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      padding: 15px; }
      .block-response-vacancy .form-response-vacancy-show .form-control {
        width: 100%;
        margin-bottom: 20px; }
      .block-response-vacancy .form-response-vacancy-show > div {
        width: 100%;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        -moz-align-items: center;
        -ms-align-items: center;
        align-items: center;
        -webkit-justify-content: center;
        -moz-justify-content: center;
        -ms-justify-content: center;
        justify-content: center;
        -ms-flex-pack: center; }
      .block-response-vacancy .form-response-vacancy-show button {
        margin: 0 10px;
        width: calc(50% - 5px); }
      .block-response-vacancy .form-response-vacancy-show .btn-close {
        display: inline-block;
        margin: 25px auto 0; }
    .block-response-vacancy .btn-show-filter-mob.show {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      z-index: 7; } }

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .table-response-vacancy-row, .table-response-vacancy-row:nth-last-child(1) {
    border-top: 1px solid #e0e0e0;
    margin-bottom: 10px;
    width: 100vw;
    margin-left: -15px; } }

@media only screen and (max-width: 370px) {
  .table-response-vacancy .col3 {
    font-size: 10px;
    line-height: 1.35rem; } }

.block-my-vacancies .col1 {
  width: 45%;
  padding-left: 0; }
  .block-my-vacancies .col1 p {
    text-align: left;
    margin-bottom: 0; }

.block-my-vacancies .col2 {
  width: 19.5%; }

.block-my-vacancies .col3 {
  width: 26.7%; }

.block-my-vacancies .col4 {
  width: 6.8%; }

.block-my-vacancies .table-response-vacancy-row {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  position: relative;
  margin-bottom: 10px;
  padding-left: 43px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 1px solid #e0e0e0; }
  .block-my-vacancies .table-response-vacancy-row-small {
    padding-top: 18px;
    padding-bottom: 18px;
    padding-left: 21px; }
    .block-my-vacancies .table-response-vacancy-row-small.vip-paid-tariff {
      padding-top: 28px; }
    .block-my-vacancies .table-response-vacancy-row-small .icon-vip-paid {
      left: 70px;
      font-size: 20px; }
    .block-my-vacancies .table-response-vacancy-row-small .col1 {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      -webkit-justify-content: flex-start;
      -moz-justify-content: flex-start;
      -ms-justify-content: flex-start;
      justify-content: flex-start;
      -ms-flex-pack: flex-start; }
      .block-my-vacancies .table-response-vacancy-row-small .col1 p {
        margin-left: 27px; }
    .block-my-vacancies .table-response-vacancy-row-small .checkbox-vacancy label {
      padding-right: 0; }
      .block-my-vacancies .table-response-vacancy-row-small .checkbox-vacancy label:before {
        margin-right: 0; }
  .block-my-vacancies .table-response-vacancy-row .fa-star {
    display: none; }

.block-my-vacancies .paid-tariff {
  background-color: #DBF5FF; }

.block-my-vacancies .icon-vip-paid {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  color: #EA4E00;
  font-size: 24px; }

.block-my-vacancies .vip-paid-tariff {
  position: relative; }
  .block-my-vacancies .vip-paid-tariff .icon-vip-paid {
    display: inline-block; }

.block-my-vacancies .form-response-vacancy input:nth-child(1) {
  width: calc(41% - 15px); }

.block-my-vacancies .form-response-vacancy input:nth-child(2) {
  width: calc(24% - 15px); }

.block-my-vacancies .form-response-vacancy input:nth-child(3) {
  width: calc(13% - 15px);
  background: none; }

.block-my-vacancies .form-response-vacancy > div {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  width: calc(22% - 15px); }
  .block-my-vacancies .form-response-vacancy > div button, .block-my-vacancies .form-response-vacancy > div a {
    width: calc(50% - 10px);
    padding: .375rem 0; }

.block-my-vacancies .table-response-vacancy {
  border-bottom: none;
  margin-bottom: 0; }
  .block-my-vacancies .table-response-vacancy-header {
    padding-left: 43px;
    border-bottom: none; }
    .block-my-vacancies .table-response-vacancy-header .col2,
    .block-my-vacancies .table-response-vacancy-header .col3 {
      color: #062336; }
    .block-my-vacancies .table-response-vacancy-header p {
      font-weight: 400;
      font-size: 14px; }

.block-my-vacancies .table-response-my-vacancy-header {
  padding-left: 22px; }

.block-my-vacancies-container .block-my-vacancies.account-my-vacancies .col1 {
  padding-right: 2%; }

.btn-vacancy-toggle {
  background: none;
  width: 43px;
  height: 43px;
  margin: auto;
  border: none;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  border-radius: 50%; }
  .btn-vacancy-toggle > div {
    width: 4px;
    height: 4px;
    background-color: #676767;
    margin: 3px;
    border-radius: 50%; }
  .btn-vacancy-toggle:focus {
    outline: none;
    background: #F4F4F4; }
    .btn-vacancy-toggle:focus > div {
      background-color: #008BEB; }
  .btn-vacancy-toggle:hover {
    background: #F4F4F4; }
    .btn-vacancy-toggle:hover > div {
      background-color: #008BEB; }

.vacancy-list {
  border: 1px solid #e0e0e0;
  border-radius: 0;
  width: 170px;
  padding: 0;
  background-color: #fff;
  z-index: 10; }
  .vacancy-list a {
    color: #062336;
    width: 100%;
    padding-left: 10px;
    line-height: 36px;
    display: inline-block; }
    .vacancy-list a:hover {
      color: #fff;
      background-color: #008BEB;
      text-decoration: none; }

.dropdown-menu.show {
  z-index: 1030; }

.number-applications-mob {
  color: #008BEB;
  display: none; }

button.close {
  padding: 0;
  margin: 0;
  z-index: 5;
  right: 2px;
  top: 2px; }

.modal-body {
  text-align: center;
  padding-top: 3rem; }

.modal-footer {
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  padding: 1rem 1rem 3rem;
  border: none; }
  .modal-footer .btn {
    padding: 0.375rem 1rem; }

.modal-dialog {
  margin: 25vh auto 0; }
  .modal-dialog button.close {
    background: none;
    color: #908d8d;
    opacity: 1; }
    .modal-dialog button.close:hover {
      color: #000;
      text-decoration: none; }

.modal-content {
  border-radius: 0; }

.modal-footer > :not(:last-child) {
  margin-right: 1rem; }

.block-starts {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 430px; } }

@media only screen and (max-width: 1199px) {
  .block-my-vacancies .form-response-vacancy input:nth-child(1) {
    width: calc(38% - 7.5px); }
  .block-my-vacancies .form-response-vacancy input:nth-child(2) {
    width: calc(24% - 7.5px); }
  .block-my-vacancies .form-response-vacancy input:nth-child(3) {
    width: calc(16% - 7.5px); }
  .block-my-vacancies .form-response-vacancy > div {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
    width: calc(22% - 7.5px); }
    .block-my-vacancies .form-response-vacancy > div button {
      width: calc(50% - 5px); } }

@media only screen and (max-width: 991px) {
  .number-applications-mob {
    display: inline-block;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center; }
    .number-applications-mob .fa-star {
      color: #062336; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .block-my-vacancies .table-response-vacancy .table-response-vacancy-body .table-response-vacancy-row-small {
    padding: 28px 21px 15px 10px; }
    .block-my-vacancies .table-response-vacancy .table-response-vacancy-body .table-response-vacancy-row-small .icon-vip-paid {
      left: 40px; }
    .block-my-vacancies .table-response-vacancy .table-response-vacancy-body .table-response-vacancy-row-small .col1 {
      width: 56%; }
      .block-my-vacancies .table-response-vacancy .table-response-vacancy-body .table-response-vacancy-row-small .col1 p {
        margin-left: 10px; }
    .block-my-vacancies .table-response-vacancy .table-response-vacancy-body .table-response-vacancy-row-small .col2 {
      width: 17%; }
    .block-my-vacancies .table-response-vacancy .table-response-vacancy-body .table-response-vacancy-row-small .col3 {
      width: 19%; }
    .block-my-vacancies .table-response-vacancy .table-response-vacancy-body .table-response-vacancy-row-small .col4 {
      width: 8%; }
  .block-my-vacancies .table-response-vacancy .table-response-my-vacancy-header {
    padding: 0 21px 0 10px;
    margin-bottom: 10px; }
    .block-my-vacancies .table-response-vacancy .table-response-my-vacancy-header .col1 {
      width: 56%; }
    .block-my-vacancies .table-response-vacancy .table-response-my-vacancy-header .col2 {
      width: 17%; }
    .block-my-vacancies .table-response-vacancy .table-response-my-vacancy-header .col3 {
      width: 19%; }
    .block-my-vacancies .table-response-vacancy .table-response-my-vacancy-header .col4 {
      width: 8%; }
    .block-my-vacancies .table-response-vacancy .table-response-my-vacancy-header p {
      font-size: 14px; }
  .block-my-vacancies .form-response-vacancy > div button,
  .block-my-vacancies .form-response-vacancy a,
  .block-my-vacancies .form-response-vacancy input {
    font-size: .72rem; } }

@media only screen and (max-width: 767px) {
  .block-response-vacancy .form-response-vacancy-show .btn-close {
    margin-top: 0; }
  .form-response-vacancy-show {
    padding-left: 15px; }
  .block-my-vacancies .form-response-vacancy-show {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
    .block-my-vacancies .form-response-vacancy-show input:nth-child(1),
    .block-my-vacancies .form-response-vacancy-show input:nth-child(2),
    .block-my-vacancies .form-response-vacancy-show input:nth-child(3) {
      width: 100%; }
    .block-my-vacancies .form-response-vacancy-show > div {
      width: 100%; }
  .block-my-vacancies .table-response-vacancy-row {
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 10px 10px 15px 48px;
    border: 1px solid #D6D6D6;
    border-left: none;
    border-right: none;
    -webkit-box-shadow: none;
    box-shadow: none; }
  .block-my-vacancies .col1 {
    width: 88%;
    order: 2;
    padding-left: 0; }
    .block-my-vacancies .col1 a {
      display: block;
      font-size: 15px; }
  .block-my-vacancies .col2 {
    display: block;
    width: 100%;
    order: 3;
    padding-left: 0; }
    .block-my-vacancies .col2 p {
      text-align: left; }
      .block-my-vacancies .col2 p span {
        border: none !important;
        padding: 0 !important; }
  .block-my-vacancies .col3 {
    width: 100%;
    order: 1;
    text-align: left;
    padding-left: 0;
    margin-bottom: 1px;
    font-size: 12px; }
  .block-my-vacancies .col4 {
    position: absolute;
    right: 0;
    top: 0;
    width: 54.8%; }
    .block-my-vacancies .col4 .btn-vacancy-toggle {
      margin-right: 0; }
  .block-my-vacancies .vip-paid-tariff .icon-vip-paid {
    left: 12px; }
  .block-my-vacancies-container {
    padding-left: 0;
    padding-right: 0; }
  .block-my-vacancies .table-response-vacancy-row-small .checkbox-vacancy label {
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%); }
  .block-my-vacancies .table-response-vacancy-row-small .col1 p {
    margin-left: 10px; }
  .block-my-vacancies .table-response-vacancy-row-small .col3 {
    padding-left: 31px; }
  .block-my-vacancies .table-response-vacancy-row-small .icon-vip-paid {
    left: 47px;
    font-size: 24px; }
  .form-response-vacancy {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    z-index: 100;
    margin: 0;
    padding: 15px 10px; }
  .modal-footer > :not(:last-child) {
    margin-right: 0; }
  .block-starts {
    position: absolute;
    top: 17px;
    left: 57px; }
  .my-jobs .block-my-vacancies .table-response-vacancy-row-small .col3 {
    padding-left: 31px; }
  .account-my-vacancies {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
  .block-my-vacancies-container .block-my-vacancies.account-my-vacancies .col1 {
    padding-right: 68px; } }

.block-invoices .form-response-vacancy input:nth-child(1) {
  width: calc(34% - 15px); }

.block-invoices .form-response-vacancy input:nth-child(2) {
  width: calc(24% - 15px); }

.block-invoices .form-response-vacancy input:nth-child(3) {
  width: calc(20% - 15px); }

.block-invoices .col1 {
  width: 12.3%; }
  .block-invoices .col1 p {
    text-align: center; }

.block-invoices .col2 {
  width: 53.7%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  .block-invoices .col2 i {
    font-size: 16px;
    margin-right: 5px; }
  .block-invoices .col2 .fa-times-circle {
    color: #DC3545; }
  .block-invoices .col2 .fa-check-circle {
    color: #00B955; }
  .block-invoices .col2 .fa-dollar-sign {
    color: #008BEB; }
  .block-invoices .col2 .fa-sync-alt {
    color: #EFAD08; }
  .block-invoices .col2 p {
    width: 67%; }

.block-invoices .col4 {
  width: 11.3%; }

@media only screen and (max-width: 1199px) {
  .block-invoices .form-response-vacancy input:nth-child(1) {
    width: calc(20% - 7.5px); }
  .block-invoices .form-response-vacancy input:nth-child(2) {
    width: calc(36% - 7.5px); }
  .block-invoices .form-response-vacancy input:nth-child(3) {
    width: calc(22% - 7.5px); } }

@media only screen and (max-width: 991px) {
  .block-invoices .form-response-vacancy input:nth-child(2) {
    width: calc(32% - 7.5px); }
  .block-invoices .form-response-vacancy input:nth-child(3) {
    width: calc(26% - 7.5px); } }

@media only screen and (max-width: 767px) {
  .block-invoices .form-response-vacancy-show {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
    .block-invoices .form-response-vacancy-show input:nth-child(1),
    .block-invoices .form-response-vacancy-show input:nth-child(2),
    .block-invoices .form-response-vacancy-show input:nth-child(3) {
      width: 100%; }
    .block-invoices .form-response-vacancy-show > div {
      width: 100%; }
  .block-invoices .col1 {
    width: 50%;
    order: 1; }
    .block-invoices .col1 p {
      text-align: left; }
  .block-invoices .col2 {
    width: 100%;
    order: 3;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
    -ms-flex-pack: flex-start;
    margin-top: 5px; }
    .block-invoices .col2 p {
      width: calc(100% - 31px); }
  .block-invoices .col3 {
    width: 50%;
    order: 2;
    text-align: right; }
  .block-invoices .col4 {
    order: 4;
    width: 100%;
    display: block;
    text-align: right; }
    .block-invoices .col4 .btn-vacancy-toggle {
      margin-right: 0; }
  .block-invoices .table-response-vacancy-row {
    padding-bottom: 0; } }

.no-letters {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  -ms-align-items: flex-end;
  align-items: flex-end;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  margin-top: 20px; }
  .no-letters-content {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 18px;
    margin-right: 23px;
    width: 301px; }
    .no-letters-content__p {
      margin-bottom: 0; }
    .no-letters-content__a {
      line-height: 1.2;
      margin-top: 15px; }
      .no-letters-content__a:hover {
        text-decoration: none;
        color: #0074f0; }
  .no-letters__img {
    height: 194px; }

.newsletter-settings-block {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between; }

.newsletter-settings-control {
  width: 675px;
  min-height: 190px;
  padding: 34px 30px;
  background-color: #FBFAFA; }
  .newsletter-settings-control__row {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
    -ms-flex-pack: flex-start;
    margin-bottom: 13px; }
    .newsletter-settings-control__row:nth-last-child(1) {
      margin-bottom: 0; }
    .newsletter-settings-control__row > div {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      -webkit-justify-content: flex-start;
      -moz-justify-content: flex-start;
      -ms-justify-content: flex-start;
      justify-content: flex-start;
      -ms-flex-pack: flex-start;
      margin-left: 20px; }
      .newsletter-settings-control__row > div p {
        line-height: 1;
        margin-bottom: 0; }
        .newsletter-settings-control__row > div p:nth-child(1) {
          width: 257px;
          padding-right: 20px; }
        .newsletter-settings-control__row > div p:nth-child(2) {
          font-size: 12px;
          width: 100px; }

.newsletter-settings-info {
  width: 475px;
  height: 190px;
  padding: 19px 0 25px 27px;
  background-color: #FAFAFA;
  border-top: 4px solid #FFEEB3;
  position: relative;
  text-align: left; }
  .newsletter-settings-info__text {
    color: #6C757D;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding-right: 144px; }
    .newsletter-settings-info__text .big-text {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 12px; }
    .newsletter-settings-info__text .small-text {
      font-size: 12px;
      margin-bottom: 12px; }
      .newsletter-settings-info__text .small-text:nth-last-child(1) {
        margin-bottom: 0; }
      .newsletter-settings-info__text .small-text span {
        font-weight: 500; }
  .newsletter-settings-info__img {
    height: 119px;
    position: absolute;
    top: 34px;
    right: 27px; }

.subscriptions-list__title {
  font-weight: 500;
  font-size: 16px; }

.subscriptions-list .pagination {
  margin-bottom: 40px; }

.subscription-block {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: flex-start;
  border: 1px solid #E0E0E0;
  padding: 22px 10px 22px 20px;
  margin-bottom: 10px;
  position: relative; }
  .subscription-block .custom-checkbox__sm {
    margin-right: 25px; }
  .subscription-block .btn-vacancy-toggle {
    position: absolute;
    right: 10px;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%); }
    .subscription-block .btn-vacancy-toggle:hover, .subscription-block .btn-vacancy-toggle:focus {
      background: #fff; }

.subscription-content {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 60%; }
  .subscription-content p {
    margin-bottom: 0; }
    .subscription-content p:nth-last-child(1) {
      margin-bottom: 0; }
    .subscription-content p .divider {
      position: relative;
      height: 100%;
      margin: 0 9px; }
      .subscription-content p .divider:before {
        content: '';
        position: absolute;
        left: -3px;
        top: 50%;
        -webkit-transform: translate(0, -50%);
        -moz-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: #062336; }

@media only screen and (max-width: 991px) {
  .block-newsletterSetting h2 {
    font-size: 16px; }
  .no-letters__img {
    height: 97px; }
  .no-letters-content {
    margin-bottom: 0; }
    .no-letters-content__p {
      margin-bottom: 5px; }
    .no-letters-content__a {
      margin-top: 10px; }
  .newsletter-settings-control {
    width: 370px;
    min-height: 230px;
    padding: 25px 18px; }
    .newsletter-settings-control__row {
      margin-bottom: 20px; }
      .newsletter-settings-control__row > div p:nth-child(1) {
        width: 171px; }
      .newsletter-settings-control__row > div p:nth-child(2) {
        width: auto; }
  .newsletter-settings-info {
    width: 310px;
    height: 230px;
    padding: 11px 0 20px 15px; }
    .newsletter-settings-info__text {
      padding-right: 20px; }
    .newsletter-settings-info__img {
      display: none; }
  .subscription-block {
    padding: 22px 10px 22px 13px; }
    .subscription-block .custom-checkbox__sm {
      margin-right: 13px; }
  .subscription-content {
    max-width: 81%; } }

@media only screen and (max-width: 767px) {
  .block-newsletterSetting .nav-tabs-castom-services {
    padding-left: 0; }
  .no-letters {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-top: 0; }
    .no-letters__img {
      height: 95px; }
    .no-letters-content {
      width: 100%;
      margin-bottom: 30px;
      margin-right: 0; }
      .no-letters-content__p {
        margin-bottom: 0; }
  .newsletter-settings-block {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center; }
  .newsletter-settings-control {
    width: 100%;
    min-height: auto;
    padding: 18px 15px;
    margin-bottom: 10px; }
    .newsletter-settings-control__row > div {
      -webkit-flex-direction: column;
      -moz-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-align-items: flex-start;
      -moz-align-items: flex-start;
      -ms-align-items: flex-start;
      align-items: flex-start;
      margin-left: 20px; }
      .newsletter-settings-control__row > div p {
        line-height: 1.2; }
        .newsletter-settings-control__row > div p:nth-child(1) {
          width: 100%;
          margin-bottom: 5px;
          padding-right: 0; }
        .newsletter-settings-control__row > div p:nth-child(2) {
          width: 100%; }
  .newsletter-settings-info {
    width: 100%;
    height: 177px;
    padding: 19px 0 15px 15px; }
    .newsletter-settings-info__text {
      padding-right: 18px; }
  .subscription-block {
    padding: 15px 13px;
    border-left: none;
    border-right: none;
    width: 100vw;
    margin-left: -15px; }
  .subscription-content {
    max-width: 70%; } }

.block-create-newsletter {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between; }
  .block-create-newsletter .right-block {
    background-color: rgba(65, 157, 228, 0.03);
    width: 360px;
    padding: 50px 30px; }
    .block-create-newsletter .right-block p {
      margin-bottom: 0; }
    .block-create-newsletter .right-block p:nth-child(1) {
      font-weight: 500; }
  .block-create-newsletter .left-block {
    width: calc(100% - 400px); }
    .block-create-newsletter .left-block label {
      font-weight: 500; }
    .block-create-newsletter .left-block #email {
      width: 100%;
      max-width: 380px; }
    .block-create-newsletter .left-block > p:nth-child(1) {
      font-weight: 500; }
  .block-create-newsletter .form-group {
    margin-bottom: 30px; }
  .block-create-newsletter .block-newsletter-activate {
    margin-bottom: 30px;
    padding-left: 1.8rem; }
    .block-create-newsletter .block-newsletter-activate .form-check-input {
      -webkit-transform: scale(1.6);
      -moz-transform: scale(1.6);
      -ms-transform: scale(1.6);
      transform: scale(1.6);
      margin-left: -1.5rem; }
  .block-create-newsletter input[type='radio']:after {
    content: '';
    width: 20px;
    height: 20px;
    border-radius: 50%;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #F0F0F0;
    display: inline-block;
    visibility: visible;
    border: 1px solid #D5D5D5; }
  .block-create-newsletter input[type='radio']:checked:before {
    content: '';
    width: 20px;
    height: 20px;
    border-radius: 50%;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #F0F0F0;
    display: inline-block;
    visibility: visible;
    border: 1px solid #D5D5D5; }
  .block-create-newsletter input[type='radio']:checked:after {
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 50%;
    top: 2px;
    left: 3px;
    position: absolute;
    background-color: #008BEB;
    display: inline-block;
    visibility: visible;
    border: none;
    z-index: 1; }
  .block-create-newsletter .form-check-input {
    margin-top: 0.2rem; }
  .block-create-newsletter .type-mailing {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
    -ms-flex-pack: flex-start;
    margin-bottom: 1.2rem; }
    .block-create-newsletter .type-mailing .form-check {
      margin-right: 100px; }
      .block-create-newsletter .type-mailing .form-check label {
        margin-left: 12px; }
    .block-create-newsletter .type-mailing .form-check:nth-last-child(1) {
      margin-right: 0; }
  .block-create-newsletter .is-open .choices__inner {
    background-color: #fff;
    border-color: #4e8bbf;
    box-shadow: 0 0 0 0.2rem rgba(0, 139, 235, 0.25); }
  .block-create-newsletter .choices__inner {
    background-color: #fff; }
  .block-create-newsletter .tagify__input {
    width: 79px;
    margin: 0 0 0 5px;
    padding: 0;
    z-index: 0; }
  .block-create-newsletter .tagify-height {
    height: auto;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0;
    min-height: 35px; }

.choices__input {
  background-color: #fff;
  padding: 5px 0 0 2px;
  margin-top: 1px; }

.is-open .choices__inner,
.choices__inner,
.choices__list--dropdown {
  border-radius: 0; }

.choices__list--multiple .choices__item {
  margin: 2px 2.5px;
  border-radius: 0;
  background-color: #228bd7;
  border: 1px solid #228bd7; }

.choices[data-type*="select-multiple"] .choices__button,
.choices[data-type*="text"] .choices__button {
  border-left: 1px solid #fff; }

.choices__inner {
  padding-top: 0; }

@media only screen and (max-width: 991px) {
  .block-create-newsletter {
    -webkit-flex-direction: column-reverse;
    -moz-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse; }
    .block-create-newsletter .right-block,
    .block-create-newsletter .left-block {
      width: 100%; }
    .block-create-newsletter .right-block {
      padding: 10px;
      margin-bottom: 10px; } }

@media only screen and (max-width: 767px) {
  .block-create-newsletter .type-mailing {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start; }
    .block-create-newsletter .type-mailing .form-check {
      margin: 5px 0; } }

.block-list-of-employees .form-response-vacancy {
  margin-bottom: 45px; }
  .block-list-of-employees .form-response-vacancy input {
    width: calc(82.5% - 20px); }
  .block-list-of-employees .form-response-vacancy > div {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
    width: 17.5%; }
    .block-list-of-employees .form-response-vacancy > div button, .block-list-of-employees .form-response-vacancy > div a {
      width: calc(50% - 10px);
      padding: .375rem 0; }

.block-list-of-employees .nav-tabs .nav-link i {
  display: none;
  font-size: 22px; }

.block-list-of-employees .invitations-tabs-title .nav-link i {
  display: none;
  font-size: 20px; }

.invite-user {
  position: absolute;
  right: 0;
  top: 0; }
  .invite-user i {
    margin-right: 5px; }

.list-employees-tabs-header {
  position: relative;
  margin-bottom: 20px; }
  .list-employees-tabs-header .nav-tabs .nav-link {
    padding: 0 0 10px;
    margin-right: 40px; }
    .list-employees-tabs-header .nav-tabs .nav-link.active {
      border-bottom: 5px solid #008BEB; }

.company-user-toggle {
  cursor: pointer; }

.users-list {
  padding: 0; }
  .users-list-item {
    margin-bottom: 10px; }
    .users-list-item.toggle-content .users-list-item-header {
      background-color: #EBF7FF; }
    .users-list-item.toggle-content .users-list-item-content {
      display: inline-block; }
    .users-list-item-header {
      padding: 20px 20px 20px 30px;
      border: 1px solid #e0e0e0;
      background-color: #FBFAFA;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      -webkit-justify-content: space-between;
      -moz-justify-content: space-between;
      -ms-justify-content: space-between;
      justify-content: space-between;
      -ms-flex-pack: space-between;
      position: relative; }
      .users-list-item-header p {
        margin-bottom: 0;
        color: #062336; }
    .users-list-item .item-header-name {
      width: 19%;
      color: #008BEB;
      cursor: pointer; }
    .users-list-item .item-header-phone {
      width: 15%; }
    .users-list-item .icon-trash {
      width: 12px; }
    .users-list-item .item-header-email {
      width: calc(66% - 12px); }
    .users-list-item-content {
      border: 1px solid #e0e0e0;
      border-top: none;
      padding: 15px 20px 30px 30px;
      width: 100%;
      display: none; }
      .users-list-item-content label {
        font-weight: 600; }
      .users-list-item-content .block-phone-mail {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: flex-end;
        -moz-align-items: flex-end;
        -ms-align-items: flex-end;
        align-items: flex-end;
        margin-bottom: 10px; }
        .users-list-item-content .block-phone-mail .form-group {
          margin-bottom: 0;
          width: 38%;
          margin-right: 50px; }
      .users-list-item-content .block-mail {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: flex-end;
        -moz-align-items: flex-end;
        -ms-align-items: flex-end;
        align-items: flex-end;
        margin-bottom: 8px; }
        .users-list-item-content .block-mail p {
          margin-bottom: 0; }
        .users-list-item-content .block-mail p:nth-child(1) {
          font-weight: 600;
          margin-right: 20px; }
      .users-list-item-content .block-buttons {
        text-align: right; }
        .users-list-item-content .block-buttons button:nth-last-child(1) {
          margin-left: 20px; }

.invitations-tabs-title {
  background-color: #DEEFFA;
  border-bottom: none;
  margin-bottom: 10px; }
  .invitations-tabs-title .nav-link {
    border-radius: 0;
    padding: .5rem 1rem; }
  .invitations-tabs-title .nav-link.active,
  .invitations-tabs-title .nav-item.show .nav-link {
    color: #fff;
    background-color: #008BEB;
    border-color: #008BEB; }
  .invitations-tabs-title .nav-link.active {
    border-bottom: none; }

.invitations-all-header .invitations-header-date {
  width: calc(16% - 15px); }

.invitations-all-header .invitations-header-email {
  width: 30%; }

.invitations-header {
  padding: 19px 20px 19px 30px; }
  .invitations-header-name {
    color: #062336;
    width: 19%; }
  .invitations-header-phone {
    width: 15%; }
  .invitations-header-email {
    width: 56%; }
  .invitations-header-date {
    width: 10%; }
  .invitations-header .list-empty {
    margin: auto; }

.inquiries-list .invitations-header-email {
  width: 31%; }

.inquiries-list .invitations-header-date {
  width: 12%; }

.inquiries-list .block-btns {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  width: 23%; }
  .inquiries-list .block-btns .btn {
    width: calc(50% - 8px);
    line-height: 16px; }

.inquiries-list .block-msg {
  margin-bottom: 0;
  width: 23%;
  text-align: center; }
  .inquiries-list .block-msg .fas {
    margin-right: 10px; }
  .inquiries-list .block-msg .fa-check-circle {
    color: #00B955; }

.inquiries-list .application-accepted .block-btns {
  display: none; }

.inquiries-list .application-accepted .block-msg-accepted {
  display: inline-block !important; }

.inquiries-list .application-reject .block-btns {
  display: none; }

.inquiries-list .application-reject .block-msg-reject {
  display: inline-block !important; }

.modal-invite-user .modal-header {
  margin: auto;
  border: none;
  padding-bottom: 0; }

.modal-invite-user .modal-body {
  padding-top: 25px;
  padding-bottom: 0; }

.modal-invite-user label {
  text-align: left;
  width: 100%;
  font-weight: 600; }

.modal-invite-user .modal-footer {
  padding: 4px 1rem 1rem; }

.modal-invite-user sup {
  color: #FF0000; }

.block-application {
  -webkit-box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.18);
  -moz-box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.18);
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.18);
  padding: 23px 50px;
  border: none;
  border-radius: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1030;
  background-color: #fff;
  -webkit-align-items: baseline;
  -moz-align-items: baseline;
  -ms-align-items: baseline;
  align-items: baseline;
  display: none; }
  .block-application i {
    font-size: 16px;
    margin-right: 10px; }
  .block-application .fa-check-circle {
    color: #00B955; }
  .block-application button.close {
    background: none; }
  .block-application p {
    margin-bottom: 0; }

@media only screen and (max-width: 991px) {
  .block-list-of-employees .nav-tabs .nav-link {
    padding: 0 1rem 1rem; }
  .block-list-of-employees .invitations-tabs-title .nav-link {
    padding: 0.5rem 1rem; }
  .block-list-of-employees .form-response-vacancy input {
    width: calc(78% - 10px); }
  .block-list-of-employees .form-response-vacancy > div {
    width: 22%; }
    .block-list-of-employees .form-response-vacancy > div button, .block-list-of-employees .form-response-vacancy > div a {
      width: calc(50% - 5px); }
  .users-list-item .item-header-name {
    width: 25%; }
  .users-list-item .item-header-phone {
    width: 25%; }
  .users-list-item .item-header-email {
    width: calc(50% - 12px); }
  .invitations-all-header .invitations-header-date {
    width: calc(21% - 15px); }
  .invitations-all-header .invitations-header-email {
    width: 30%; }
  .invitations-header-name {
    width: 30%; }
  .invitations-header-phone {
    width: 20%; }
  .invitations-header-email {
    width: 30%; }
  .invitations-header-date {
    width: 20%; }
  .inquiries-list .users-list-item-header {
    padding: 15px 10px 15px 20px; }
  .inquiries-list .invitations-all-header .invitations-header-date {
    width: 11%;
    text-align: center; }
  .inquiries-list .block-btns {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 18%; }
    .inquiries-list .block-btns .btn {
      width: 100%;
      margin: 3px auto; }
  .inquiries-list .block-msg {
    width: 18%; } }

@media only screen and (max-width: 767px) {
  .users-list-item .item-header-name {
    width: auto; }
  .users-list-item .item-header-phone {
    width: auto; }
  .users-list-item .item-header-email {
    width: auto; }
  .users-list-item-header {
    padding: 13px 10px 15px 10px;
    -webkit-box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
    border: none;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start; }
  .users-list-item .icon-trash {
    position: absolute;
    right: 10px;
    bottom: 15px; }
  .users-list-item-content {
    padding: 10px 18px 20px 18px; }
    .users-list-item-content .block-phone-mail {
      -webkit-flex-direction: column;
      -moz-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-align-items: flex-start;
      -moz-align-items: flex-start;
      -ms-align-items: flex-start;
      align-items: flex-start; }
      .users-list-item-content .block-phone-mail .form-group {
        margin-bottom: 1rem;
        width: 100%;
        margin-right: 0; }
    .users-list-item-content .block-buttons {
      text-align: center; }
  .users-list-item.toggle-content .users-list-item-header {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border: 1px solid #e0e0e0; }
  .invite-user {
    position: relative;
    padding-right: 0;
    margin-bottom: 25px; }
  .list-employees-tabs-header {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column-reverse;
    -moz-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center; }
  .invitations-all-header {
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row; }
    .invitations-all-header .invitations-header-date {
      width: 31%;
      order: 2; }
    .invitations-all-header .invitations-header-name {
      width: 69%;
      order: 1; }
    .invitations-all-header .invitations-header-phone {
      width: 100%;
      order: 3; }
    .invitations-all-header .invitations-header-email {
      width: 100%;
      order: 4; }
  .invitations-header {
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row; }
    .invitations-header .invitations-header-date {
      width: 31%;
      order: 2; }
    .invitations-header .invitations-header-name {
      width: 69%;
      order: 1; }
    .invitations-header .invitations-header-phone {
      width: 100%;
      order: 3; }
    .invitations-header .invitations-header-email {
      width: 100%;
      order: 4; }
  .block-list-of-employees .form-response-vacancy {
    margin-bottom: 0; }
    .block-list-of-employees .form-response-vacancy > div {
      width: 100%; }
  .block-list-of-employees .nav-tabs {
    border-bottom: none; }
    .block-list-of-employees .nav-tabs .nav-link {
      padding: 0 12px 10px;
      margin: 0; }
      .block-list-of-employees .nav-tabs .nav-link i {
        display: inline-block; }
      .block-list-of-employees .nav-tabs .nav-link span {
        display: none; }
      .block-list-of-employees .nav-tabs .nav-link .active {
        border-bottom: 5px solid #4e8bbf; }
    .block-list-of-employees .nav-tabs .nav-item {
      margin: 0 30px 1px; }
  .block-list-of-employees .invitations-tabs-title {
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center; }
    .block-list-of-employees .invitations-tabs-title .nav-link {
      padding: 20px 23px; }
      .block-list-of-employees .invitations-tabs-title .nav-link i {
        display: inline-block; }
      .block-list-of-employees .invitations-tabs-title .nav-link span {
        display: none; }
    .block-list-of-employees .invitations-tabs-title .nav-link.active,
    .block-list-of-employees .invitations-tabs-title .nav-item.show .nav-link {
      color: #000;
      background: none;
      border: none; }
    .block-list-of-employees .invitations-tabs-title .nav-item {
      margin: 0 0 1px; }
  .inquiries-list .users-list-item-header {
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .inquiries-list .block-btns {
    width: 100%;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    order: 5; }
    .inquiries-list .block-btns .btn {
      width: calc(40% - 8px);
      line-height: 16px; }
  .inquiries-list .block-msg {
    width: 90%;
    order: 5; }
  .inquiries-list .invitations-header-name {
    width: 69%;
    order: 1; }
  .inquiries-list .invitations-all-header .invitations-header-email {
    width: 100%;
    margin-bottom: 15px;
    order: 4; }
  .inquiries-list .invitations-all-header .invitations-header-date {
    display: inline-block;
    width: 31%;
    order: 2; }
  .inquiries-list .invitations-all-header .invitations-header-phone {
    order: 3; }
  .accept-application .block-accept-application {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex; }
  .reject-application .block-reject-application {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex; } }

.replenish-account-page .form-check-label {
  margin-left: 13px; }

.replenish-account-page .custom-control {
  padding-left: 1.3rem;
  margin-bottom: 10px; }

.replenish-account-page .total-payable {
  font-size: 18px; }

.text-semibold {
  font-weight: 500; }

.block-selection-payment-amount {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between; }
  .block-selection-payment-amount .left-col {
    width: 100%;
    max-width: 550px;
    padding: 40px 0 0; }
  .block-selection-payment-amount .selection-payment-title {
    font-size: 18px;
    width: 45%;
    margin-bottom: 0; }
  .block-selection-payment-amount .block-currency {
    width: 55%; }
    .block-selection-payment-amount .block-currency .form-control {
      width: calc(100% - 30px); }
  .block-selection-payment-amount .right-col {
    max-width: 300px;
    padding: 0; }
    .block-selection-payment-amount .right-col .table {
      margin-bottom: 0; }
      .block-selection-payment-amount .right-col .table thead th {
        border-bottom: none; }
      .block-selection-payment-amount .right-col .table th, .block-selection-payment-amount .right-col .table td {
        border-top: none;
        text-align: center;
        padding: 3px 0; }

.invoice-payment-sheet {
  padding: 0; }
  .invoice-payment-sheet-item {
    background-color: #FBFAFA;
    border: 1px solid #e0e0e0;
    margin-bottom: 12px;
    padding: 20px 115px 20px 25px;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between; }
    .invoice-payment-sheet-item:nth-last-child(1) {
      margin-bottom: 0; }
    .invoice-payment-sheet-item > p {
      margin-bottom: 0;
      width: 25%;
      text-align: center; }
      .invoice-payment-sheet-item > p:nth-child(1) {
        text-align: left; }
      .invoice-payment-sheet-item > p:nth-last-child(1) {
        text-align: right; }
    .invoice-payment-sheet-item > div {
      width: 50%; }
      .invoice-payment-sheet-item > div p {
        text-align: left;
        width: 100%;
        margin-bottom: 0; }

.block-budget-info {
  margin: 20px auto 30px;
  background-color: #ECF6FF;
  padding: 25px 30px 20px 20px; }
  .block-budget-info-title {
    font-size: 18px; }
  .block-budget-info-content {
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between; }
    .block-budget-info-content > div {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      -webkit-justify-content: flex-start;
      -moz-justify-content: flex-start;
      -ms-justify-content: flex-start;
      justify-content: flex-start;
      -ms-flex-pack: flex-start; }
    .block-budget-info-content p {
      margin-right: 35px;
      margin-bottom: 0; }

.alert-info {
  background-color: #D9EDF7;
  border-color: #CDE2F5; }

.alert button.close {
  background: none;
  top: 50%;
  right: 1rem;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  color: #707070; }

@media only screen and (max-width: 1199px) {
  .block-budget-info-title {
    margin-bottom: 10px; }
  .block-budget-info-content {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
    .block-budget-info-content > div {
      -webkit-flex-direction: column;
      -moz-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-align-items: flex-start;
      -moz-align-items: flex-start;
      -ms-align-items: flex-start;
      align-items: flex-start;
      width: 100%; }
    .block-budget-info-content p {
      margin-right: 0;
      margin-bottom: 10px;
      text-align: left; } }

@media only screen and (max-width: 991px) {
  .block-selection-payment-amount {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center; }
    .block-selection-payment-amount .left-col {
      max-width: 100%; }
  .invoice-payment-sheet-item {
    margin-bottom: 10px;
    padding: 15px; } }

@media only screen and (max-width: 767px) {
  .block-selection-payment-amount .selection-payment-title {
    font-size: 14px; }
  .block-selection-payment-amount .left-col > div:nth-child(1) {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start;
    margin-bottom: 16px !important; }
    .block-selection-payment-amount .left-col > div:nth-child(1) .selection-payment-title {
      width: 100%;
      margin-bottom: 10px; }
    .block-selection-payment-amount .left-col > div:nth-child(1) .block-currency {
      width: 100%; }
    .block-selection-payment-amount .left-col > div:nth-child(1) p {
      width: 40px; }
  .block-selection-payment-amount .right-col .table {
    margin-bottom: 10px; }
  .replenish-account-page .total-payable {
    font-size: 14px; }
  .invoice-payment-sheet-item {
    -webkit-box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
    padding: 10px;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    border-radius: 5px; }
    .invoice-payment-sheet-item > p {
      margin-bottom: 0;
      width: 50%;
      text-align: left; }
      .invoice-payment-sheet-item > p:nth-child(2) {
        text-align: right; }
      .invoice-payment-sheet-item > p:nth-child(3) {
        width: 100%; }
      .invoice-payment-sheet-item > p:nth-last-child(1) {
        width: 100%;
        text-align: left; }
    .invoice-payment-sheet-item > div {
      width: 100%; } }

.response-row {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  margin-bottom: 10px; }
  .response-row p:nth-child(1) {
    min-width: 110px; }
  .response-row p {
    margin-bottom: 0; }

.response-page-contact-info {
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  margin: 30px 0 20px;
  padding-bottom: 5px;
  border-bottom: 1px solid #e0e0e0; }

.response-add-file {
  margin: 30px 0 30px; }

@media only screen and (max-width: 767px) {
  .response-page-contact-info {
    -webkit-flex-direction: column-reverse;
    -moz-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    margin: 20px 0 15px;
    padding-bottom: 15px; }
  .response-row {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 0; }
    .response-row p {
      margin-bottom: 3px; }
  .response-add-file {
    margin: 15px 0 20px; } }

.page-settings {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start; }

@media only screen and (max-width: 767px) {
  .page-settings {
    -webkit-flex-direction: column-reverse;
    -moz-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse; } }

.services-content .is-active-tariff .color8:hover:before,
.services-content .is-active-tariff .color7:hover:before,
.services-content .is-active-tariff .color6:hover:before,
.services-content .is-active-tariff .color5:hover:before,
.services-content .is-active-tariff .color1:hover:before,
.services-content .is-active-tariff .color2:hover:before,
.services-content .is-active-tariff .color3:hover:before,
.services-content .is-active-tariff .color4:hover:before {
  visibility: hidden; }

.services-content .is-active-tariff .active:before,
.services-content .is-active-tariff .active:hover:before {
  visibility: visible; }

.services-content .is-active-tariff .text-is-active {
  display: inline-block; }

.services-content .is-active-tariff .btn {
  display: none; }

.services-content-title {
  margin-bottom: 20px; }

.services-content-subtitle {
  margin-bottom: 30px; }
  .services-content-subtitle .read-more {
    color: #008AEA;
    cursor: pointer; }

.block-services-subtitles .services-content-subtitle__hm {
  display: none; }

.block-services-subtitles .read-more-subtitle .services-content-subtitle__hm {
  display: inline-block !important; }

.block-services-subtitles .read-more-subtitle .services-content-subtitle__sm,
.block-services-subtitles .read-more-subtitle .services-content-subtitle__md,
.block-services-subtitles .read-more-subtitle .services-content-subtitle__rm {
  display: none !important; }

.block-services-subtitles .services-content-subtitle__sm,
.block-services-subtitles .services-content-subtitle__md {
  display: none !important; }

.services-list {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  padding: 0;
  margin-bottom: 10px; }
  .services-list .text-is-active {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    display: none; }

.service-card {
  width: calc(25% - 12.75px);
  margin-right: 9.5px;
  margin-bottom: 20px;
  padding: 17px 20px 20px;
  border: 1px solid #D6D6D6;
  border-radius: 10px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3); }
  .service-card:nth-child(4n + 4) {
    margin-right: 0; }
  .service-card .fa-check {
    color: #02B538; }
  .service-card .fa-times {
    color: #FF8484; }
  .service-card ul {
    margin-bottom: 22px;
    padding: 0;
    width: 100%; }
  .service-card li {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: baseline;
    -moz-align-items: baseline;
    -ms-align-items: baseline;
    align-items: baseline;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
    -ms-flex-pack: flex-start; }
  .service-card .service-name-count {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: baseline;
    -moz-align-items: baseline;
    -ms-align-items: baseline;
    align-items: baseline;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
    padding-left: 10px;
    width: calc(100% - 15px); }
    .service-card .service-name-count p:nth-child(1) {
      width: calc(100% - 50px);
      padding-right: 5px;
      margin-bottom: 0; }
      .service-card .service-name-count p:nth-child(1) .fa-medal {
        font-size: 14px;
        color: #EA4E00;
        margin-left: 5px; }
    .service-card .service-name-count p:nth-child(2) {
      max-width: 50px;
      margin-bottom: 0; }
    .service-card .service-name-count .inactive-services {
      font-weight: 300;
      color: #6C757D; }
  .service-card .no-subtitle {
    min-height: 78px; }
  .service-card .title-content {
    height: calc(100% - 35px);
    width: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center; }
    .service-card .title-content .icon-wrap {
      width: 15px;
      text-align: center; }
    .service-card .title-content .svg-inline--fa {
      font-size: 15px; }

.color1 .service-card-name {
  color: #40505F; }

.color1:before {
  background-color: #40505F; }

.color2 .service-card-name {
  color: #02B538; }

.color2:before {
  background-color: #02B538; }

.color3 .service-card-name {
  color: #FF9E00; }

.color3:before {
  background-color: #FF9E00; }

.color4 .service-card-name {
  color: #0074f0; }

.color4:before {
  background-color: #0074f0; }

.color5 .service-card-name {
  color: #296EBF; }

.color5:before {
  background-color: #296ebf; }

.color6 .service-card-name {
  color: #10ACA3; }

.color6:before {
  background-color: #10ACA3; }

.color7 .service-card-name {
  color: #009CDA; }

.color7:before {
  background-color: #009CDA; }

.color8 .service-card-name {
  color: #D2A400; }

.color8:before {
  background-color: #D2A400; }

.color8,
.color7,
.color6,
.color5,
.color1,
.color2,
.color3,
.color4 {
  position: relative; }
  .color8:before,
  .color7:before,
  .color6:before,
  .color5:before,
  .color1:before,
  .color2:before,
  .color3:before,
  .color4:before {
    content: '';
    width: 100%;
    height: 8px;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 10px 10px 0 0;
    visibility: hidden; }
  .color8:hover:before,
  .color7:hover:before,
  .color6:hover:before,
  .color5:hover:before,
  .color1:hover:before,
  .color2:hover:before,
  .color3:hover:before,
  .color4:hover:before {
    visibility: visible; }

.service-card-name {
  font-size: 25px; }
  .service-card-name .currency-icon {
    font-size: 17px; }

.service-card-content {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: flex-start; }
  .service-card-content .service-card {
    width: 100%;
    max-width: 282px;
    margin-right: 35px; }
    .service-card-content .service-card li p {
      line-height: 1.8; }
  .service-card-content .color8:before,
  .service-card-content .color7:before,
  .service-card-content .color6:before,
  .service-card-content .color5:before,
  .service-card-content .color1:before,
  .service-card-content .color2:before,
  .service-card-content .color3:before,
  .service-card-content .color4:before {
    visibility: visible; }
  .service-card-content .block-answer-success-fail {
    width: calc(100% - 397px); }
  .service-card-content .service-not-active,
  .service-card-content .service-active {
    font-size: 16px;
    font-weight: 600;
    text-align: center; }

.block-answer-success {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start; }
  .block-answer-success .block-answer-success-title {
    font-size: 20px;
    color: #02B538; }

.block-answer-fail .block-answer-success-title {
  font-size: 20px;
  color: #E96E25; }

.title-payment-block {
  font-size: 24px;
  margin-bottom: 20px; }

.services-payment-block {
  margin-top: 28px; }
  .services-payment-block .tariff-pro-price {
    background-color: rgba(214, 214, 214, 0.2);
    padding: 15px 40px;
    margin-bottom: 35px; }
    .services-payment-block .tariff-pro-price ul {
      width: 220px; }
    .services-payment-block .tariff-pro-price li > p:nth-child(1) {
      width: 63%;
      text-align: left;
      padding-left: 0; }
    .services-payment-block .tariff-pro-price li > p:nth-last-child(1) {
      width: 37%;
      text-align: right;
      padding-left: 0; }
  .services-payment-block .tariff-name {
    font-size: 20px; }
    .services-payment-block .tariff-name-price {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      -webkit-justify-content: space-between;
      -moz-justify-content: space-between;
      -ms-justify-content: space-between;
      justify-content: space-between;
      -ms-flex-pack: space-between; }
  .services-payment-block .form-check-input {
    margin-left: 0; }
  .services-payment-block label {
    margin-left: 30px;
    margin-right: 140px; }
  .services-payment-block .form-invoice,
  .services-payment-block .payment-method-content {
    margin-top: 0; }
  .services-payment-block .form-check-label {
    margin-left: 0;
    margin-right: 0; }
  .services-payment-block .payment-method-block .marker-nav-link {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: relative;
    background-color: #F0F0F0;
    border: 1px solid #D5D5D5;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    margin-right: 12px; }
    .services-payment-block .payment-method-block .marker-nav-link-active {
      width: 12px;
      height: 12px;
      background-color: #008BEB;
      border-radius: 50%;
      display: none; }
  .services-payment-block .payment-method-block .nav-link {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
    -ms-flex-pack: flex-start;
    padding: 0;
    color: #062336; }
    .services-payment-block .payment-method-block .nav-link.active {
      border-bottom: none;
      margin-bottom: 0;
      color: #062336; }
      .services-payment-block .payment-method-block .nav-link.active .marker-nav-link-active {
        display: inline-block; }
    .services-payment-block .payment-method-block .nav-link input[type="radio"] {
      opacity: 0; }
  .services-payment-block .payment-method-block .nav-item {
    margin-right: 140px; }
  .services-payment-block .payment-method-block .nav-tabs {
    border-bottom: none; }

.nav-tabs-castom-services {
  border-bottom: 1px solid #008AEA;
  margin-bottom: 20px; }
  .nav-tabs-castom-services .nav-link {
    padding: 0 0 10px;
    color: #062336;
    margin-right: 30px;
    line-height: 1; }
    .nav-tabs-castom-services .nav-link.active {
      color: #008AEA; }
  .nav-tabs-castom-services .material-icons, .nav-tabs-castom-services .material-icons-outlined, .nav-tabs-castom-services .material-icons-round, .nav-tabs-castom-services .material-icons-sharp, .nav-tabs-castom-services .material-icons-two-tone {
    display: none; }

.services-table-head {
  border: 1px solid #D6D6D6;
  border-bottom: none;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }

.services-table__col {
  text-align: center;
  font-size: 16px;
  color: #6F7273;
  font-weight: 500;
  padding: 16px 0; }
  .services-table__col__1 {
    width: 60.8%; }
  .services-table__col__2 {
    width: 19.6%; }
  .services-table__col__3 {
    width: 19.6%; }

.services-table-body {
  margin-bottom: 20px; }

.services-table-item {
  border: 1px solid #D6D6D6;
  border-bottom: none;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  .services-table-item__col {
    padding: 20px 0; }
    .services-table-item__col__1 {
      width: 60.8%;
      text-align: left;
      padding: 20px 18px; }
    .services-table-item__col__2 {
      width: 19.6%;
      text-align: center;
      font-size: 16px;
      color: #062336;
      text-transform: uppercase; }
    .services-table-item__col__3 {
      width: 19.6%; }
    .services-table-item__col .services-item-mob {
      display: none; }
  .services-table-item:nth-last-child(1) {
    border-bottom: 1px solid #D6D6D6; }
  .services-table-item__title {
    font-size: 16px;
    color: #062336;
    font-weight: 500;
    margin-bottom: 7px; }
  .services-table-item__subtitle {
    color: #40505F;
    margin-bottom: 0; }

.services-table-footer {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between; }
  .services-table-footer__p {
    margin-bottom: 0;
    text-align: left;
    color: #6F7273; }

.border-block {
  position: relative; }
  .border-block:before {
    content: '';
    width: 1px;
    height: 100%;
    background-color: #D6D6D6;
    position: absolute;
    top: 0;
    left: 60.8%; }
  .border-block:after {
    content: '';
    width: 1px;
    height: 100%;
    background-color: #D6D6D6;
    position: absolute;
    top: 0;
    right: 19.6%; }

.counter {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  border: 1px solid #E0E0E0;
  width: 110px;
  margin: auto; }
  .counter__btn {
    width: 30px;
    height: 30px;
    cursor: pointer;
    color: #062336;
    font-size: 20px;
    text-align: center; }
    .counter__btn:hover {
      background-color: #008AEA;
      color: #fff; }
  .counter__plus {
    margin: 0 1px;
    position: relative; }
    .counter__plus:before {
      content: '';
      width: 1px;
      height: 100%;
      background-color: #E0E0E0;
      position: absolute;
      top: 0;
      left: -1px; }
    .counter__plus:after {
      content: '';
      width: 1px;
      height: 100%;
      background-color: #E0E0E0;
      position: absolute;
      top: 0;
      right: -1px; }
  .counter__field {
    border: none;
    width: 50px;
    padding: 0 10px;
    text-align: right;
    font-size: 16px; }
    .counter__field:focus-visible {
      outline: none; }

.sum-n-btn {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  -ms-align-items: flex-end;
  align-items: flex-end; }

.block-tab-sum {
  border-bottom: 1px solid #40505F;
  color: #062336;
  margin-bottom: 30px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  -ms-align-items: flex-end;
  align-items: flex-end;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  -ms-justify-content: flex-end;
  justify-content: flex-end;
  -ms-flex-pack: flex-end; }
  .block-tab-sum__p {
    font-weight: 500;
    text-align: left;
    margin-bottom: 0; }
  .block-tab-sum__sum {
    text-align: right;
    font-size: 16px;
    margin-bottom: 0;
    margin-left: 5px;
    min-width: 110px; }

.tab-content-castom .tab-pane {
  position: relative; }

.service-total-block {
  display: none;
  margin-bottom: 0; }

.services-publications-container > div {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between; }

.services-publications-footer {
  font-size: 12px;
  color: #6C757D;
  margin-bottom: 0; }

.publications-list {
  padding: 0;
  margin-bottom: 0;
  width: 776px; }

.publication-item {
  padding: 20px 30px 15px 30px;
  border: 1px solid #D6D6D6;
  border-left-width: 4px;
  margin-bottom: 10px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between; }

.block-publications-price {
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex; }
  .block-publications-price__count {
    font-weight: 500;
    margin-bottom: 10px;
    font-size: 16px; }
  .block-publications-price__price {
    line-height: 20px;
    margin-bottom: 0;
    background: #FFF3CD;
    font-size: 12px;
    padding: 0 10px;
    border-radius: 3px; }

.publication-total-price-discount {
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  min-width: 150px; }

.publication-total-price {
  margin-bottom: 10px;
  font-size: 16px; }
  .publication-total-price-mob {
    display: none; }

.publication-discount {
  margin-bottom: 0;
  font-size: 12px;
  color: #28A745; }
  .publication-discount-mob {
    display: none; }

.not-authorized-block {
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  -ms-align-items: flex-end;
  align-items: flex-end;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex; }
  .not-authorized-block .btn {
    line-height: 27px;
    padding-top: 0;
    padding-bottom: 0; }

.not-authorized-info {
  margin-top: 9px;
  margin-bottom: 0;
  font-size: 12px;
  color: #6C757D; }

.jobPostingTab-footer {
  color: #6C757D; }

.publications-banner {
  width: 380px; }
  .publications-banner__img {
    width: 100%;
    height: auto; }
    .publications-banner__img__tab {
      display: none; }
  .publications-banner-tab {
    display: none;
    width: 100%;
    margin-bottom: 20px; }

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url("~material-icons/iconfont/MaterialIcons-Regular.eot");
  /* For IE6-8 */
  src: local("Material Icons"), local("MaterialIcons-Regular"), url("~material-icons/iconfont/MaterialIcons-Regular.woff2") format("woff2"), url("~material-icons/iconfont/MaterialIcons-Regular.woff") format("woff"), url("~material-icons/iconfont/MaterialIcons-Regular.ttf") format("truetype"); }

.material-icons, .material-icons-outlined, .material-icons-round, .material-icons-sharp, .material-icons-two-tone {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }

@font-face {
  font-family: "Material Icons Outlined";
  font-style: normal;
  font-weight: 400;
  src: url("~material-icons/iconfont/MaterialIconsOutlined-Regular.eot");
  /* For IE6-8 */
  src: local("Material Icons Outlined"), local("MaterialIconsOutlined-Regular"), url("~material-icons/iconfont/MaterialIconsOutlined-Regular.woff2") format("woff2"), url("~material-icons/iconfont/MaterialIconsOutlined-Regular.woff") format("woff"), url("~material-icons/iconfont/MaterialIconsOutlined-Regular.otf") format("opentype"); }

.material-icons-outlined {
  font-family: "Material Icons Outlined"; }

@font-face {
  font-family: "Material Icons Round";
  font-style: normal;
  font-weight: 400;
  src: url("~material-icons/iconfont/MaterialIconsRound-Regular.eot");
  /* For IE6-8 */
  src: local("Material Icons Round"), local("MaterialIconsRound-Regular"), url("~material-icons/iconfont/MaterialIconsRound-Regular.woff2") format("woff2"), url("~material-icons/iconfont/MaterialIconsRound-Regular.woff") format("woff"), url("~material-icons/iconfont/MaterialIconsRound-Regular.otf") format("opentype"); }

.material-icons-round {
  font-family: "Material Icons Round"; }

@font-face {
  font-family: "Material Icons Sharp";
  font-style: normal;
  font-weight: 400;
  src: url("~material-icons/iconfont/MaterialIconsSharp-Regular.eot");
  /* For IE6-8 */
  src: local("Material Icons Sharp"), local("MaterialIconsSharp-Regular"), url("~material-icons/iconfont/MaterialIconsSharp-Regular.woff2") format("woff2"), url("~material-icons/iconfont/MaterialIconsSharp-Regular.woff") format("woff"), url("~material-icons/iconfont/MaterialIconsSharp-Regular.otf") format("opentype"); }

.material-icons-sharp {
  font-family: "Material Icons Sharp"; }

@font-face {
  font-family: "Material Icons Two Tone";
  font-style: normal;
  font-weight: 400;
  src: url("~material-icons/iconfont/MaterialIconsTwoTone-Regular.eot");
  /* For IE6-8 */
  src: local("Material Icons Two Tone"), local("MaterialIconsTwoTone-Regular"), url("~material-icons/iconfont/MaterialIconsTwoTone-Regular.woff2") format("woff2"), url("~material-icons/iconfont/MaterialIconsTwoTone-Regular.woff") format("woff"), url("~material-icons/iconfont/MaterialIconsTwoTone-Regular.otf") format("opentype"); }

.material-icons-two-tone {
  font-family: "Material Icons Two Tone"; }

@media only screen and (max-width: 1199px) {
  .service-card {
    width: calc(33.3% - 12.75px); }
    .service-card:nth-child(4n + 4) {
      margin-right: 9.5px; }
    .service-card:nth-child(3n + 3) {
      margin-right: 0; } }

@media only screen and (max-width: 1024px) {
  .publications-banner {
    display: none; }
    .publications-banner__img {
      display: none; }
    .publications-banner__img__tab, .publications-banner-tab {
      display: inline-block; }
  .publication-item {
    padding: 15px 20px;
    border-left: 1px solid #D6D6D6 !important; }
  .publication-total-price {
    font-size: 14px; }
  .publications-list {
    width: 100%; }
  .not-authorized-block .btn {
    line-height: 25px;
    font-size: 13px; }
  .services-publications-container > div {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center; } }

.payment-block-title-btns {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: flex-start;
  margin-bottom: 12px; }
  .payment-block-title-btns > p {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 0;
    margin-right: 30px;
    color: #062336; }

.payment-method-block-services .payment-systems {
  padding-left: 257px;
  padding-bottom: 20px; }

.total-block-services .information {
  padding: 0 0 10px; }
  .total-block-services .information span:nth-child(1) {
    text-align: left;
    width: 200px; }
  .total-block-services .information span:nth-child(2) {
    width: 95px;
    text-align: right; }
  .total-block-services .information-last {
    padding: 10px 0;
    border-bottom: 1px solid #008BEB;
    border-top: 1px solid #008BEB; }
    .total-block-services .information-last span {
      font-size: 16px; }

@media only screen and (max-width: 992px) {
  .services-table__col {
    font-size: 14px;
    padding: 12px 0; }
    .services-table__col__1 {
      width: 60.1%; }
    .services-table__col__2 {
      width: 16.9%; }
    .services-table__col__3 {
      width: 23%; }
  .services-table-item__col__1 {
    width: 60.1%;
    padding: 20px; }
  .services-table-item__col__2 {
    width: 16.9%;
    font-size: 14px; }
  .services-table-item__col__3 {
    width: 23%; }
  .border-block:before {
    left: 60.1%; }
  .border-block:after {
    right: 23%; }
  .counter__field {
    font-size: 14px; }
  .services-payment-block .payment-method-block-services .nav-item {
    margin-right: 139px;
    min-width: 131px; }
    .services-payment-block .payment-method-block-services .nav-item:nth-last-child(1) {
      margin-right: 45px; }
  .block-services-subtitles .services-content-subtitle__md {
    display: inline-block !important; }
  .block-services-subtitles .services-content-subtitle__md.services-content-subtitle__hm,
  .block-services-subtitles .services-content-subtitle__lg {
    display: none !important; }
  .service-card {
    width: calc(43% - 10px);
    margin-right: 20px; }
    .service-card:nth-child(3n + 3), .service-card:nth-child(4n + 4) {
      margin-right: 20px; }
    .service-card:nth-child(2n + 2) {
      margin-right: 0; } }

@media only screen and (max-width: 767px) {
  .services-page .container {
    padding-right: 0;
    padding-left: 0;
    width: 100%; }
  .services-content-title {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 18px; }
  .services-content-subtitle {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 20px; }
  .publications-banner-tab,
  .publications-banner,
  .border-block:before,
  .border-block:after,
  .services-table-head {
    display: none; }
  .nav-tabs-castom {
    border-bottom: none;
    position: relative; }
    .nav-tabs-castom:before {
      content: '';
      width: 100%;
      height: 1px;
      background-color: #008AEA;
      position: absolute;
      bottom: 1px;
      left: 0; }
    .nav-tabs-castom .nav-link {
      padding: 0 20px 11px; }
      .nav-tabs-castom .nav-link .active {
        margin-bottom: -2px; }
  .services-table-item {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start;
    border: none;
    border-bottom: 1px solid #D6D6D6;
    padding: 18px 16px 20px; }
    .services-table-item:nth-child(1) {
      border-top: 1px solid #D6D6D6; }
    .services-table-item__col {
      padding: 0;
      width: 100%;
      text-align: left; }
      .services-table-item__col__2 {
        margin-bottom: 15px; }
      .services-table-item__col__3 {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        -moz-align-items: center;
        -ms-align-items: center;
        align-items: center;
        -webkit-justify-content: space-between;
        -moz-justify-content: space-between;
        -ms-justify-content: space-between;
        justify-content: space-between;
        -ms-flex-pack: space-between; }
    .services-table-item__subtitle {
      margin-bottom: 20px; }
    .services-table-item .services-item-mob {
      display: inline-block;
      font-weight: 500;
      text-transform: none; }
  .services-table-footer {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start;
    padding-left: 15px;
    padding-right: 15px; }
    .services-table-footer__p {
      margin-bottom: 10px; }
  .counter {
    width: 110px;
    margin-left: 0; }
  .sum-n-btn {
    width: 100%; }
  .block-tab-sum {
    width: 100%;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between; }
  .service-total-block__show {
    display: inline-block; }
  .publication-discount-mob {
    display: inline-block;
    margin-top: 12px; }
  .publication-total-price-discount {
    display: none; }
  .publication-total-price-mob {
    display: inline-block;
    margin-bottom: 11px; }
  .publication-item {
    -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3); }
  .nav-tabs-castom-services {
    margin-bottom: 10px;
    padding: 0 15px;
    -ms-overflow-style: none;
    scrollbar-width: none; }
    .nav-tabs-castom-services:before {
      display: none; }
    .nav-tabs-castom-services .nav-link {
      border: 1px solid #70A1D6;
      border-radius: 4px;
      margin-right: 10px;
      color: #70A1D6;
      padding: 10px;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      -webkit-justify-content: space-between;
      -moz-justify-content: space-between;
      -ms-justify-content: space-between;
      justify-content: space-between;
      -ms-flex-pack: space-between; }
      .nav-tabs-castom-services .nav-link.active {
        border: 1px solid #008AEA;
        color: #008AEA; }
    .nav-tabs-castom-services::-webkit-scrollbar {
      display: none; }
    .nav-tabs-castom-services-fixed {
      position: fixed;
      top: 52px;
      left: 0;
      padding: 10px 15px !important;
      background: #fff;
      z-index: 1031;
      width: 100%;
      -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
      -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3); }
    .nav-tabs-castom-services .material-icons, .nav-tabs-castom-services .material-icons-outlined, .nav-tabs-castom-services .material-icons-round, .nav-tabs-castom-services .material-icons-sharp, .nav-tabs-castom-services .material-icons-two-tone {
      display: inline-block;
      font-size: 16px;
      margin-right: 10px; }
  .not-authorized-block .btn {
    line-height: 23px;
    font-size: 12px; }
  .block-services-subtitles .services-content-subtitle__sm,
  .block-services-subtitles .services-content-subtitle__md {
    display: inline-block !important; }
  .block-services-subtitles .services-content-subtitle__sm.services-content-subtitle__hm,
  .block-services-subtitles .services-content-subtitle__md,
  .block-services-subtitles .services-content-subtitle__lg {
    display: none !important; }
  .payment-block-title-btns {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start; }
  .services-payment-block .payment-method-block-services .nav-tabs {
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
    width: 100%;
    margin-top: 15px; }
  .services-payment-block .payment-method-block-services .nav-item {
    margin-right: 20px; }
    .services-payment-block .payment-method-block-services .nav-item:nth-last-child(1) {
      margin-right: 0;
      min-width: 112px; }
  .payment-method-block-services .payment-systems {
    padding-left: 33px;
    padding-bottom: 30px;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start; }
  .jobPostingTab-footer,
  .services-packages-p {
    padding-left: 15px;
    padding-right: 15px; } }

@media only screen and (orientation: portrait) {
  .service-card-content {
    padding-left: 0; }
    .service-card-content .block-answer-success-fail {
      width: calc(100% - 317px); } }

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .service-card {
    width: 90%;
    max-width: 288px;
    margin-right: 0;
    margin-bottom: 10px; }
    .service-card:nth-child(3n + 3), .service-card:nth-child(4n + 4) {
      margin-right: 0; }
  .service-card-content {
    padding-left: 0;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center; }
    .service-card-content .block-answer-success-fail {
      width: 100%; }
    .service-card-content .service-card {
      margin-right: 0; }
  .services-payment-block label {
    margin-left: 30px;
    margin-right: 10px; }
  .services-payment-block .block-add-new-contact button {
    margin: 10px; }
  .services-payment-block .tariff-pro-price {
    padding: 10px; }
    .services-payment-block .tariff-pro-price ul {
      width: 100%; }
    .services-payment-block .tariff-pro-price li {
      -webkit-flex-direction: row;
      -moz-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row; }
  .services-payment-block .tariff-name-price {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; } }

@media only screen and (max-height: 500px) and (orientation: landscape) {
  .service-card {
    width: calc(45% - 9.5px); }
  .service-card-content {
    padding-left: 0; }
    .service-card-content .block-answer-success-fail {
      width: calc(100% - 317px); }
  .tariff-pro-price li {
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row; } }

@media only screen and (max-width: 500px) and (orientation: landscape) {
  .service-card-content {
    padding-left: 0;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center; }
    .service-card-content .block-answer-success-fail {
      width: 100%; }
    .service-card-content .service-card {
      margin-right: 0; }
  .services-payment-block label {
    margin-right: 25px; } }

.rate-detail-content {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start; }
  .rate-detail-content .service-card {
    margin-right: 60px;
    width: 280px; }
    .rate-detail-content .service-card li p {
      line-height: 2; }

.rate-detail-list {
  padding: 0;
  margin-bottom: 96px; }
  .rate-detail-list li {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    margin-bottom: 20px; }
    .rate-detail-list li p {
      margin-bottom: 0; }
  .rate-detail-list .rate-detail-title {
    width: 385px; }

.rates-list-content .table-response-vacancy .col1 {
  width: 32%;
  text-align: left;
  padding-left: 15px; }

.rates-list-content .table-response-vacancy .col2 {
  width: 12%;
  text-align: center;
  color: #062336; }

.rates-list-content .table-response-vacancy .col3 {
  width: 28%;
  color: #062336; }

.rates-list-content .table-response-vacancy .col4 {
  width: 15%;
  color: #062336; }

.rates-list-content .table-response-vacancy .col5 {
  width: 13%;
  text-align: center; }

.rates-list-content .table-response-vacancy-row {
  padding: 25px 0;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center; }
  .rates-list-content .table-response-vacancy-row .col1 {
    padding-left: 20px; }
  .rates-list-content .table-response-vacancy-row .col3,
  .rates-list-content .table-response-vacancy-row .col4 {
    color: #676767; }
    .rates-list-content .table-response-vacancy-row .col3 .text-for-mob,
    .rates-list-content .table-response-vacancy-row .col4 .text-for-mob {
      display: none; }

.rates-list-content .show .btn-rate-toggle {
  border: 1px solid #008AEA;
  color: #008AEA; }

.rates-list-content .btn-rate-toggle {
  border: 1px solid #062336;
  color: #062336;
  background: none;
  padding: 4px 8px; }
  .rates-list-content .btn-rate-toggle:hover, .rates-list-content .btn-rate-toggle:focus {
    border: 1px solid #008AEA;
    color: #008AEA;
    outline: none; }
  .rates-list-content .btn-rate-toggle .fa-caret-down {
    margin-left: 8px; }
  .rates-list-content .btn-rate-toggle-active-status {
    border: 1px solid #008AEA;
    color: #008AEA; }

.rates-list-content .btn-rate-toggle-disabled {
  border: 1px solid #A0A6AB;
  color: #A0A6AB;
  background: none;
  padding: 4px 16px; }
  .rates-list-content .btn-rate-toggle-disabled:hover, .rates-list-content .btn-rate-toggle-disabled:focus {
    border: 1px solid #A0A6AB;
    color: #A0A6AB;
    outline: none; }

.rates-list-content .not-active:hover, .rates-list-content .not-active:focus {
  border: 1px solid #A0A6AB;
  color: #A0A6AB;
  outline: none; }

.rates-label {
  border-radius: 3px;
  border: 1px solid #062336;
  color: #062336;
  padding: 4px 12px;
  display: inline-block; }
  .rates-label-not-paid {
    color: #DC3545;
    border-color: #DC3545; }
  .rates-label-canceled {
    color: #6F7273;
    border-color: #6F7273; }
  .rates-label-active {
    color: #00B955;
    border-color: #00B955; }

.modal-body {
  padding-top: 2rem; }

.modal-footer {
  padding-bottom: 1.5rem; }

@media only screen and (max-width: 1024px) and (orientation: landscape) {
  .rates-list-content .table-response-vacancy .col1 {
    width: 30%;
    padding-left: 10px; }
  .rates-list-content .table-response-vacancy .col2 {
    width: 15%; }
  .rates-list-content .table-response-vacancy .col4 {
    width: 18%; } }

@media only screen and (orientation: portrait) {
  .rate-detail-list .rate-detail-title {
    width: 270px; }
  .rate-detail-content .service-card {
    margin-right: 30px; }
  .rates-list-content .table-response-vacancy .col1 {
    width: 25%;
    padding-left: 10px; }
  .rates-list-content .table-response-vacancy .col2 {
    width: 20%; }
  .rates-list-content .table-response-vacancy .col3 {
    width: 22%; }
  .rates-list-content .table-response-vacancy .col4 {
    width: 23%; } }

@media only screen and (max-width: 767px) {
  .rates-list-content .table-response-vacancy-row {
    -webkit-align-items: baseline;
    -moz-align-items: baseline;
    -ms-align-items: baseline;
    align-items: baseline;
    padding: 25px 10px; }
    .rates-list-content .table-response-vacancy-row .col1 {
      padding-left: 0;
      width: 50%;
      order: 1; }
    .rates-list-content .table-response-vacancy-row .col2 {
      display: inline-block;
      width: 50%;
      padding: 0;
      order: 2;
      margin-bottom: 10px;
      text-align: right; }
    .rates-list-content .table-response-vacancy-row .col3 {
      width: 100%;
      order: 5;
      text-align: left;
      font-size: 13px; }
      .rates-list-content .table-response-vacancy-row .col3 .text-for-mob {
        display: inline-block;
        margin-right: 10px; }
    .rates-list-content .table-response-vacancy-row .col4 {
      width: 50%;
      padding: 0;
      order: 3; }
    .rates-list-content .table-response-vacancy-row .col5 {
      order: 4;
      width: 50%;
      text-align: right; } }

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .rate-detail-content {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center; }
    .rate-detail-content .service-card {
      margin-right: 0;
      width: 100%;
      max-width: 280px; }
  .rate-detail-list {
    margin-bottom: 15px; }
    .rate-detail-list li {
      margin-bottom: 15px; }
    .rate-detail-list .rate-detail-title {
      width: 200px; } }

@media only screen and (min-width: 751px) and (max-width: 850px) and (max-height: 500px) and (orientation: landscape) {
  .rates-list-content .table-response-vacancy .col1 {
    width: 25%;
    padding-left: 10px; }
  .rates-list-content .table-response-vacancy .col2 {
    width: 20%; }
  .rates-list-content .table-response-vacancy .col3 {
    width: 22%; }
  .rates-list-content .table-response-vacancy .col4 {
    width: 20%; }
  .rates-list-content .table-response-vacancy .col5 {
    width: 14%; }
  .rates-list-content .btn-rate-toggle {
    padding: 4px 12px; } }

@media only screen and (max-height: 500px) and (orientation: landscape) {
  .rate-detail-content {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center; }
    .rate-detail-content .service-card {
      margin-right: 0;
      width: 100%;
      max-width: 280px; }
  .rate-detail-list {
    margin-bottom: 15px; }
    .rate-detail-list li {
      margin-bottom: 15px; }
    .rate-detail-list .rate-detail-title {
      width: 200px; } }

.сhange-tariff-block {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  margin-top: 40px;
  margin-bottom: 40px; }
  .сhange-tariff-block-btns {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    -ms-justify-content: flex-end;
    justify-content: flex-end;
    -ms-flex-pack: flex-end;
    margin-top: 30px; }
    .сhange-tariff-block-btns .btn-danger {
      margin-left: 20px; }
  .сhange-tariff-block .service-card {
    margin: 0;
    width: 282px;
    padding: 25px 17px; }
    .сhange-tariff-block .service-card-name {
      text-align: center;
      width: 100%; }
    .сhange-tariff-block .service-card li p {
      line-height: 2; }
    .сhange-tariff-block .service-card .service-active {
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      width: 100%;
      margin-bottom: 0; }
    .сhange-tariff-block .service-card .title-content {
      width: 100%;
      -webkit-align-items: flex-start;
      -moz-align-items: flex-start;
      -ms-align-items: flex-start;
      align-items: flex-start; }
  .сhange-tariff-block .show-line:before {
    visibility: visible; }

.сhange-tariff-block-img {
  width: 180px;
  height: auto;
  margin: 0 65px; }

@media only screen and (max-width: 768px) {
  .сhange-tariff-block {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center; }
    .сhange-tariff-block-img {
      width: 108px;
      margin: 50px 75px;
      -webkit-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      transform: rotate(90deg); } }

.promo-page {
  overflow: hidden; }
  .promo-page-title {
    font-size: 50px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 15px; }
  .promo-page-sub-title {
    font-size: 18px;
    text-align: center;
    margin-bottom: 45px; }

.block-promo-rate {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  margin-bottom: 50px; }
  .block-promo-rate:before {
    display: none; }
  .block-promo-rate-card {
    width: 435px;
    position: relative; }
    .block-promo-rate-card .service-card {
      width: 100%;
      max-width: 252px;
      margin: auto;
      padding: 15px 15px 25px;
      background-color: #fff; }
      .block-promo-rate-card .service-card:before {
        visibility: visible; }
      .block-promo-rate-card .service-card-name {
        text-align: center; }
      .block-promo-rate-card .service-card .title-content,
      .block-promo-rate-card .service-card ul {
        width: 100%; }
      .block-promo-rate-card .service-card li p {
        margin: 0 0 0 18px;
        font-size: 12px;
        line-height: 26px; }
      .block-promo-rate-card .service-card .title-content {
        display: inline-block !important; }
        .block-promo-rate-card .service-card .title-content p {
          margin-bottom: 0; }
      .block-promo-rate-card .service-card .btn {
        font-size: 12px;
        padding: 6px 22px; }
    .block-promo-rate-card .rate-img {
      position: absolute;
      width: 435px;
      height: auto;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); }
    .block-promo-rate-card .title-content {
      min-height: 307px; }
    .block-promo-rate-card .card-text {
      font-size: 14px;
      line-height: 26px;
      max-width: 180px; }
  .block-promo-rate-info {
    width: calc(100% - 480px); }
    .block-promo-rate-info-title {
      font-size: 24px;
      font-weight: 400;
      text-align: left;
      margin-bottom: 20px; }
    .block-promo-rate-info-sub-title {
      font-size: 14px;
      text-align: left;
      margin-bottom: 20px; }
    .block-promo-rate-info-text {
      text-align: left;
      margin-bottom: 0; }
      .block-promo-rate-info-text .read-more-text {
        display: none; }
      .block-promo-rate-info-text .read-more {
        cursor: pointer;
        color: #008AEA; }
        .block-promo-rate-info-text .read-more:hover {
          text-decoration: underline; }
    .block-promo-rate-info .open-read-more .read-more-text {
      display: inline-block; }
  .block-promo-rate.color1 .block-promo-rate-info-title {
    color: #40505F; }
  .block-promo-rate.color1 .block-promo-rate-info-sub-title {
    color: #40505F; }
  .block-promo-rate.color2 .block-promo-rate-info-title {
    color: #02B538; }
  .block-promo-rate.color2 .block-promo-rate-info-sub-title {
    color: #02B538; }
  .block-promo-rate.color3 .block-promo-rate-info-title {
    color: #FF9E00; }
  .block-promo-rate.color3 .block-promo-rate-info-sub-title {
    color: #FF9E00; }
  .block-promo-rate.color4 .block-promo-rate-info-title {
    color: #0074F0; }
  .block-promo-rate.color4 .block-promo-rate-info-sub-title {
    color: #0074F0; }
  .block-promo-rate-reverse {
    -webkit-flex-direction: row-reverse;
    -moz-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse; }
    .block-promo-rate-reverse .block-promo-rate-card {
      width: 252px; }

.block-tariff-plan {
  padding: 33px 0 50px;
  background: url("/images/promo-page/bg.svg") no-repeat;
  background-size: cover; }
  .block-tariff-plan-title {
    font-size: 70px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 20px;
    color: #fff; }
  .block-tariff-plan-sub-title {
    font-size: 20px;
    text-align: center;
    margin-bottom: 50px;
    color: #fff; }
  .block-tariff-plan .btn {
    font-size: 20px;
    padding: 15px 40px; }

.block-diagrams {
  padding-top: 80px; }
  .block-diagrams .container {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between; }

.block-diagram {
  max-width: 525px;
  width: 40%; }
  .block-diagram-title {
    font-size: 20px;
    color: #767676;
    text-align: right;
    margin-bottom: 10px; }
  .block-diagram .chart {
    height: 301px;
    width: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-end;
    -moz-align-items: flex-end;
    -ms-align-items: flex-end;
    align-items: flex-end;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
    background-image: linear-gradient(#ccc 1px, transparent 1px);
    background-size: 20px 50px;
    padding: 0 20px; }
    .block-diagram .chart li {
      position: relative;
      height: 100%;
      width: 65px; }
    .block-diagram .chart span {
      width: 100%;
      margin: auto;
      display: block;
      animation: draw 1s ease-in-out;
      position: absolute;
      bottom: 0;
      left: 0; }
      .block-diagram .chart span:before {
        position: absolute;
        left: 0;
        right: 0;
        top: -36px;
        display: block;
        text-align: center;
        content: attr(title);
        word-wrap: break-word;
        font-size: 16px;
        color: #40505F; }

@keyframes draw {
  0% {
    height: 0; } }
  .block-diagram .diagram-legend {
    padding: 0 20px;
    width: 100%;
    margin-bottom: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
    .block-diagram .diagram-legend li {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      margin-right: 20px; }
      .block-diagram .diagram-legend li .box-color {
        width: 16px;
        height: 16px;
        margin-right: 13px; }
      .block-diagram .diagram-legend li p {
        margin-bottom: 0;
        font-size: 15px;
        color: #40505F; }

@media only screen and (max-width: 1024px) {
  .block-diagram .chart li {
    width: 58px; } }

@media only screen and (max-width: 991px) {
  .promo-page {
    padding-bottom: 30px; }
    .promo-page-title {
      font-size: 45px;
      margin-bottom: 5px; }
  .block-tariff-plan-title {
    font-size: 48px;
    margin-bottom: 10px; }
  .block-tariff-plan-sub-title {
    margin-bottom: 33px; }
  .block-diagram .chart li {
    width: 41px; } }

@media only screen and (max-width: 767px) {
  .promo-page {
    padding-bottom: 100px; }
    .promo-page-title {
      font-size: 24px;
      margin-bottom: 16px; }
    .promo-page-sub-title {
      font-size: 14px;
      margin-bottom: 17px; }
  .block-promo-rate {
    -webkit-flex-direction: column-reverse;
    -moz-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    margin-bottom: 35px; }
    .block-promo-rate-info {
      width: 100%;
      margin-bottom: 20px; }
    .block-promo-rate-reverse .block-promo-rate-card, .block-promo-rate-card {
      width: 100%; }
  .block-tariff-plan {
    margin-top: 55px; }
    .block-tariff-plan-title {
      font-size: 35px;
      margin-bottom: 15px; }
    .block-tariff-plan-sub-title {
      font-size: 16px;
      margin-bottom: 50px; }
  .block-diagrams {
    padding-top: 35px; }
    .block-diagrams .container {
      -webkit-flex-direction: column;
      -moz-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center; }
    .block-diagrams .mob-mb {
      margin-top: 55px; }
  .block-diagram {
    width: 100%; }
    .block-diagram-title {
      font-size: 16px; }
    .block-diagram .chart {
      padding: 0 12px;
      margin-bottom: 22px;
      height: 201px; }
      .block-diagram .chart li {
        width: 40px; }
      .block-diagram .chart span:before {
        font-size: 14px; }
    .block-diagram .block-diagram .diagram-legend {
      padding: 0 12px; }
      .block-diagram .block-diagram .diagram-legend li .box-color {
        width: 12px;
        height: 12px;
        margin-right: 10px; }
      .block-diagram .block-diagram .diagram-legend li p {
        font-size: 14px; } }

.guideline-wrap {
  min-height: 100vh;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex; }
  .guideline-wrap .side-bar {
    border: 1px solid #707070;
    background-color: #062336;
    width: 300px; }
    .guideline-wrap .side-bar-logo {
      padding: 20px 0;
      text-align: center;
      border-bottom: 1px solid #707070;
      position: relative; }
      .guideline-wrap .side-bar-logo img {
        width: 185px;
        height: auto; }
    .guideline-wrap .side-bar .nav-tabs {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
      -moz-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-align-items: flex-start;
      -moz-align-items: flex-start;
      -ms-align-items: flex-start;
      align-items: flex-start;
      border: none;
      padding-top: 34px; }
      .guideline-wrap .side-bar .nav-tabs .nav-link {
        padding: 10px 50px;
        width: 100%;
        font-size: 16px;
        color: #6C757D;
        border-radius: 0; }
        .guideline-wrap .side-bar .nav-tabs .nav-link.active {
          color: #fff;
          background: #394C60;
          border-bottom: none; }
  .guideline-wrap .tab-content {
    width: calc(100% - 300px); }
    .guideline-wrap .tab-content .tab-pane {
      padding: 85px 10px 80px 268px; }
    .guideline-wrap .tab-content-page-title {
      font-size: 30px;
      font-weight: bold;
      color: #6C757D;
      margin-bottom: 50px; }
  .guideline-wrap .logo-template-line {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
    -ms-flex-pack: flex-start;
    margin-bottom: 56px; }
  .guideline-wrap .block-position {
    background-color: #F7F7F7;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    width: 90px;
    height: 90px;
    margin-right: 8px; }
    .guideline-wrap .block-position p {
      margin: 0;
      color: #868686; }
  .guideline-wrap .block-logo-template-mob {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    margin-right: 70px; }
    .guideline-wrap .block-logo-template-mob p {
      margin: 0;
      color: #868686; }
  .guideline-wrap .logo-template-mob {
    width: 188px;
    height: 90px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    margin: 15px 0 10px; }
    .guideline-wrap .logo-template-mob img {
      width: 128px;
      height: auto; }
  .guideline-wrap .block-logo-template-desc {
    margin-right: 0; }
    .guideline-wrap .block-logo-template-desc .logo-template-mob {
      width: 264px; }
      .guideline-wrap .block-logo-template-desc .logo-template-mob img {
        width: 185px; }
  .guideline-wrap .logo-template-line-footer {
    margin-bottom: 0; }
    .guideline-wrap .logo-template-line-footer .logo-template-mob img {
      width: 102px; }
    .guideline-wrap .logo-template-line-footer .block-logo-template-desc .logo-template-mob img {
      width: 147px; }
  .guideline-wrap .desc-invis {
    visibility: hidden; }
  .guideline-wrap .menu-icon {
    position: absolute;
    left: 20px;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding: 0.3rem;
    cursor: pointer;
    color: inherit;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
    z-index: 2;
    display: none; }
    .guideline-wrap .menu-icon:focus {
      outline: none; }
  .guideline-wrap .menu-icon-box {
    width: 26px;
    height: 26px;
    display: inline-block;
    position: relative; }
  .guideline-wrap .menu-icon-inner {
    display: block;
    top: 50%;
    margin-top: -2px; }
  .guideline-wrap .menu-icon-inner,
  .guideline-wrap .menu-icon-inner::before,
  .guideline-wrap .menu-icon-inner::after {
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: #fff; }
  .guideline-wrap .menu-icon-inner::before,
  .guideline-wrap .menu-icon-inner::after {
    content: "";
    display: block; }
  .guideline-wrap .menu-icon-inner::before {
    top: -8px; }
  .guideline-wrap .menu-icon-inner::after {
    bottom: -8px; }

@media only screen and (max-width: 1439px) {
  .guideline-wrap .tab-content .tab-pane {
    padding-left: 50px; } }

@media only screen and (max-width: 1024px) {
  .guideline-wrap .tab-content .tab-pane {
    padding-left: 30px; } }

@media only screen and (max-width: 992px) {
  .guideline-wrap .menu-icon {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex; }
  .guideline-wrap .side-bar {
    border: none; }
    .guideline-wrap .side-bar .nav-tabs {
      position: absolute;
      top: 68px;
      left: 0;
      right: 0;
      overflow-y: auto;
      overflow-x: hidden;
      z-index: 2;
      background-color: #062336;
      -webkit-transform: translate(-100%, 0);
      -moz-transform: translate(-100%, 0);
      -ms-transform: translate(-100%, 0);
      transform: translate(-100%, 0);
      -webkit-transition: -webkit-transform 300ms cubic-bezier(0.2, 0, 0.2, 1);
      transition: -webkit-transform 300ms cubic-bezier(0.2, 0, 0.2, 1);
      -o-transition: transform 300ms cubic-bezier(0.2, 0, 0.2, 1);
      transition: transform 300ms cubic-bezier(0.2, 0, 0.2, 1);
      transition: transform 300ms cubic-bezier(0.2, 0, 0.2, 1), -webkit-transform 300ms cubic-bezier(0.2, 0, 0.2, 1); }
      .guideline-wrap .side-bar .nav-tabs.menu-active {
        -webkit-transform: translate(0, 0);
        -moz-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        transform: translate(0, 0); }
      .guideline-wrap .side-bar .nav-tabs .nav-link {
        padding: 20px 50px; }
  .guideline-wrap .logo-template-line {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start; }
  .guideline-wrap .block-logo-template-mob {
    margin: 0; }
  .guideline-wrap .block-logo-template-desc {
    margin-top: 10px; }
  .guideline-wrap .block-position {
    margin-bottom: 10px; }
  .guideline-wrap .desc-invis {
    visibility: visible; }
  .guideline-wrap .tab-content-page-title {
    margin-bottom: 30px; } }

@media only screen and (max-width: 992px) {
  .guideline-wrap {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
    .guideline-wrap .side-bar,
    .guideline-wrap .tab-content {
      width: 100%; }
    .guideline-wrap .tab-content .tab-pane {
      padding-top: 20px;
      padding-bottom: 15px; }
    .guideline-wrap .side-bar-logo img {
      width: 135px; } }

.block-favorites {
  margin-top: 20px; }
  .block-favorites-title {
    margin-bottom: 20px; }
  .block-favorites-tabs {
    z-index: 1;
    position: relative; }
    .block-favorites-tabs .btn {
      margin: 0 -2px; }
  .block-favorites .table-response-vacancy-body {
    border-top: 1px solid #E0E0E0; }
    .block-favorites .table-response-vacancy-body .col1 {
      width: 90%; }
    .block-favorites .table-response-vacancy-body .col4 {
      text-align: right;
      padding: 0; }
      .block-favorites .table-response-vacancy-body .col4 .btn-outline-info,
      .block-favorites .table-response-vacancy-body .col4 .btn-info {
        width: 30px;
        padding: 0;
        line-height: 30px; }
  .block-favorites .table-response-vacancy-row {
    margin-bottom: 0;
    border: none;
    border-bottom: 1px solid #E0E0E0;
    padding: 15px 25px 15px 40px; }

.no-favorites {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  margin-top: -42px; }
  .no-favorites__img {
    height: 342px;
    width: auto; }
  .no-favorites__text {
    margin-bottom: 6px; }

@media only screen and (max-width: 991px) {
  .block-favorites {
    margin-top: 15px; }
    .block-favorites-title {
      font-size: 16px; }
    .block-favorites .table-response-vacancy-row {
      padding: 15px 14px 15px 27px; }
  .no-favorites {
    margin-top: -61px; }
    .no-favorites__img {
      height: 276px; }
    .no-favorites__text {
      margin-bottom: 5px; } }

@media only screen and (max-width: 767px) {
  .block-favorites .table-response-vacancy-row {
    padding: 10px 16px;
    width: 100%;
    margin-left: 0 !important; }
  .block-favorites .table-response-vacancy-body .col4 {
    width: auto;
    top: 50%;
    transform: translate(0%, -50%);
    margin: 0;
    right: 21px; }
    .block-favorites .table-response-vacancy-body .col4 .btn-outline-info,
    .block-favorites .table-response-vacancy-body .col4 .btn-info {
      width: 21px;
      line-height: 21px;
      font-size: 12px; }
  .no-favorites {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-top: 5px; }
    .no-favorites__img {
      height: 246px;
      margin-top: -50px; }
    .no-favorites > div {
      width: 100%;
      padding-right: 10px;
      padding-left: 10px; } }

.cv-list-item {
  list-style-type: none;
  border: 1px solid #D6D6D6;
  padding: 12px 30px 13px 30px;
  margin-bottom: 10px; }
  .cv-list-item-mob {
    display: none;
    width: 100%; }
  .cv-list-item-desctop {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between; }
  .cv-list-item-photo {
    width: 94px;
    height: 94px;
    border: 1px solid #e0e0e0;
    position: relative;
    overflow: hidden; }
    .cv-list-item-photo .fa-user {
      color: #e0e0e0;
      font-size: 45px;
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); }
    .cv-list-item-photo img {
      max-height: 94px;
      width: auto;
      min-width: 100%;
      height: auto;
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); }
  .cv-list-item-text {
    margin-bottom: 5px;
    font-size: 12px;
    color: #062336; }
  .cv-list-item .cv-description {
    width: calc(100% - 115px); }
  .cv-list-item .vacancy-title {
    margin-bottom: 10px;
    line-height: 1; }
  .cv-list-item .vacancies-item-date {
    font-size: 10px;
    color: #6C757D;
    margin-bottom: 0; }

@media only screen and (max-width: 767px) {
  .cv-list-item {
    border-left: none;
    border-right: none;
    padding: 7px 16px 16px 18px;
    position: relative; }
    .cv-list-item:before {
      content: '';
      height: calc(100% + 2px);
      width: 5px;
      position: absolute;
      left: -5px;
      top: -1px;
      border-top: 1px solid #D6D6D6;
      border-bottom: 1px solid #D6D6D6; }
    .cv-list-item:after {
      content: '';
      height: calc(100% + 2px);
      width: 5px;
      position: absolute;
      right: -5px;
      top: -1px;
      border-top: 1px solid #D6D6D6;
      border-bottom: 1px solid #D6D6D6; }
    .cv-list-item-mob {
      display: inline-block; }
      .cv-list-item-mob .cv-description-img {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: flex-end;
        -moz-align-items: flex-end;
        -ms-align-items: flex-end;
        align-items: flex-end;
        -webkit-justify-content: space-between;
        -moz-justify-content: space-between;
        -ms-justify-content: space-between;
        justify-content: space-between;
        -ms-flex-pack: space-between; }
      .cv-list-item-mob .cv-description {
        width: calc(100% - 80px); }
    .cv-list-item-desctop {
      display: none; }
    .cv-list-item-photo {
      width: 63px;
      height: 63px; }
      .cv-list-item-photo .fa-user {
        font-size: 30px; }
      .cv-list-item-photo img {
        max-height: 63px; }
    .cv-list-item .vacancy-title {
      margin-bottom: 5px; }
    .cv-list-item-text {
      margin-bottom: 6px; } }

.page-error-wrap {
  padding: 32px 0 0;
  margin-bottom: -100px; }
  .page-error-wrap-img {
    width: 50%;
    margin-top: 16.5vh; }
    .page-error-wrap-img img {
      width: 100%;
      height: auto; }
  .page-error-wrap .container {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex; }
    .page-error-wrap .container > div:nth-child(1) {
      width: 50%; }

@media only screen and (min-width: 1025px) and (max-height: 800px) {
  .page-error-wrap-img {
    margin-top: 37px; } }

@media screen and (max-width: 1024px) {
  .page-error-wrap-img {
    margin-top: 70px; } }

@media only screen and (max-width: 1024px) and (orientation: portrait) {
  .page-error-wrap {
    padding: 15vw 0 0; }
    .page-error-wrap-img {
      margin-top: 39vw; } }

@media screen and (max-width: 992px) {
  .page-error-wrap {
    padding: 35px 0 0;
    margin-bottom: -39px; } }

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .page-error-wrap {
    padding: 15px 0 0;
    text-align: center; }
    .page-error-wrap-img {
      margin-top: 11.5vw; }
    .page-error-wrap .container {
      -webkit-flex-direction: column;
      -moz-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center; }
      .page-error-wrap .container > div:nth-child(1) {
        width: 100%; }
    .page-error-wrap .page-error-wrap-img {
      width: 87%; } }

@media only screen and (max-width: 767px) {
  .page-error-wrap-img {
    margin-top: 20px; } }

.landing-page-title {
  font-size: 30px;
  font-weight: 500;
  color: #116CAC;
  margin-bottom: 0;
  text-align: center;
  text-transform: uppercase;
  z-index: 1;
  position: relative; }

.landing-page-subtitle {
  font-size: 28px;
  color: #116CAC;
  margin-bottom: 30px;
  margin-top: -7px;
  text-align: center;
  z-index: 1;
  position: relative; }

.landing-page-header {
  background-color: #E0F2FF;
  position: relative; }
  .landing-page-header:before {
    content: '';
    width: 100%;
    height: 116px;
    background-color: #fff;
    position: absolute;
    left: 0;
    bottom: 0; }
  .landing-page-header-img {
    height: 389px;
    width: auto;
    position: relative;
    left: -52px; }
  .landing-page-header-icon {
    height: 134px;
    width: auto;
    position: absolute;
    right: 215px;
    top: 95px; }
  .landing-page-header .container {
    position: relative; }
    .landing-page-header .container:before {
      content: '';
      width: 845px;
      height: calc(100% - 116px);
      background-color: #fff;
      position: absolute;
      right: 73px;
      top: 0;
      -webkit-transform: skewX(-30deg);
      -moz-transform: skewX(-30deg);
      -ms-transform: skewX(-30deg);
      transform: skewX(-30deg); }

.landing-page-block-info {
  padding-bottom: 50px; }
  .landing-page-block-info .text-description {
    margin: 50px auto 0;
    width: 81%;
    max-width: 947px; }
    .landing-page-block-info .text-description p {
      font-size: 18px;
      color: #444444;
      margin-bottom: 0;
      text-align: center; }

.landing-page-visas-list ul {
  padding: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between; }

.landing-page-visas-list li {
  margin-bottom: 20px;
  width: calc(50% - 10px);
  border: 0.33px solid #008BEB;
  padding: 20px;
  min-height: 320px; }
  .landing-page-visas-list li:nth-child(odd) {
    margin-right: 20px; }

.landing-page-visas-list .visas-list-img {
  margin-bottom: 23px;
  width: 55px;
  height: auto; }

.landing-page-visas-list .visas-list-title {
  margin-bottom: 33px;
  font-size: 28px;
  color: #008BEB; }

.landing-page-visas-list .visas-list-subtitle {
  margin-bottom: 0;
  font-size: 14px; }

.landing-page-field-activity-block {
  padding: 30px 0 25px; }
  .landing-page-field-activity-block ul {
    padding: 0;
    max-width: 732px;
    width: 100%;
    margin: 50px auto 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
    .landing-page-field-activity-block ul li {
      margin-bottom: 30px;
      width: calc(33.3% - 20px);
      height: 149px;
      margin-right: 30px;
      position: relative;
      overflow: hidden; }
      .landing-page-field-activity-block ul li:nth-child(3n+3) {
        margin-right: 0; }
      .landing-page-field-activity-block ul li:hover .hover-block {
        opacity: 1; }
      .landing-page-field-activity-block ul li img {
        width: 100%;
        height: auto;
        max-height: 100%;
        position: absolute;
        left: 0;
        top: 0; }
  .landing-page-field-activity-block .hover-block {
    background-color: rgba(8, 101, 197, 0.49);
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    text-decoration: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0; }

.landing-page-full-information {
  background-color: #E0F2FF;
  position: relative;
  padding: 41px 0 0; }
  .landing-page-full-information:before {
    content: '';
    width: 100%;
    height: 41px;
    background-color: #fff;
    position: absolute;
    left: 0;
    top: 0; }
  .landing-page-full-information .container {
    position: relative;
    padding-top: 50px; }
    .landing-page-full-information .container:before {
      content: '';
      width: 938px;
      height: 100%;
      background-color: #fff;
      position: absolute;
      right: 112px;
      top: 0;
      -webkit-transform: skewX(-20deg);
      -moz-transform: skewX(-20deg);
      -ms-transform: skewX(-20deg);
      transform: skewX(-20deg); }
  .landing-page-full-information img {
    height: 441px;
    width: auto;
    position: absolute;
    left: -15px;
    bottom: 0; }
  .landing-page-full-information .managers-list {
    padding: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    width: 100%;
    max-width: 537px;
    z-index: 1;
    position: relative;
    margin: auto; }
    .landing-page-full-information .managers-list li {
      border: 0.33px solid #008BEB;
      margin: 0 24px 40px;
      padding: 17px 40px; }
  .landing-page-full-information .phone {
    font-weight: 500;
    color: #008BEB;
    font-size: 16px;
    margin-bottom: 0; }
    .landing-page-full-information .phone:hover {
      text-decoration: none; }
  .landing-page-full-information .name {
    font-size: 14px;
    color: #008BEB;
    margin-bottom: 0; }

.landing-page-our-partners {
  padding-top: 50px; }
  .landing-page-our-partners .partners-list {
    margin-top: 50px;
    margin-bottom: 0;
    padding: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
    .landing-page-our-partners .partners-list li {
      border: 0.33px solid #008BEB;
      margin: 0 14px 20px 0;
      padding: 0 10px;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      -webkit-justify-content: center;
      -moz-justify-content: center;
      -ms-justify-content: center;
      justify-content: center;
      -ms-flex-pack: center;
      width: calc(12.5% - 12.3px);
      height: 57px; }
      .landing-page-our-partners .partners-list li:nth-child(8n+8) {
        margin-right: 0; }
      .landing-page-our-partners .partners-list li img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%; }

@media only screen and (max-width: 1400px) {
  .landing-page-title {
    font-size: 25px; }
  .landing-page-header-img {
    height: 315px;
    left: 76px; }
  .landing-page-header-icon {
    height: 95px;
    width: auto;
    position: absolute;
    right: 227px;
    top: 95px; }
  .landing-page-block-info .text-description p {
    font-size: 16px; }
  .landing-page-visas-list-img {
    margin-bottom: 15px;
    width: 50px; }
  .landing-page-visas-list-title {
    margin-bottom: 30px;
    font-size: 25px; }
  .landing-page-visas-list li {
    min-height: 280px; } }

@media only screen and (max-width: 1199px) {
  .landing-page-title {
    font-size: 27px; }
  .landing-page-header-img {
    height: 316px; }
  .landing-page-header-icon {
    height: 92px;
    top: 65px; }
  .landing-page-header .container:before {
    width: 711px; }
  .landing-page-visas-list .visas-list-title {
    margin-bottom: 10px;
    font-size: 20px; }
  .landing-page-visas-list li {
    min-height: 290px; }
  .landing-page-full-information img {
    height: 407px; } }

@media only screen and (max-width: 991px) {
  .landing-page-header {
    background-color: #fff; }
    .landing-page-header-img {
      height: 179px;
      left: 0; }
    .landing-page-header-icon {
      height: 76px;
      right: 0;
      top: -10px;
      position: relative; }
    .landing-page-header .container {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
      -moz-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center; }
      .landing-page-header .container:before {
        display: none; }
  .landing-page-our-partners .partners-list li {
    width: calc(14.2% - 12px); }
    .landing-page-our-partners .partners-list li:nth-child(8n+8) {
      margin-right: 14px; }
    .landing-page-our-partners .partners-list li:nth-child(7n+7) {
      margin-right: 0; }
  .landing-page-full-information {
    background-color: #fff; }
  .landing-page-header .container:before,
  .landing-page-full-information img,
  .landing-page-full-information .container:before,
  .landing-page-full-information:before {
    display: none; } }

@media only screen and (max-width: 767px) {
  .landing-page-title {
    font-size: 20px; }
  .landing-page-subtitle {
    font-size: 16px; }
  .landing-page-block-info {
    padding-bottom: 30px; }
    .landing-page-block-info .text-description {
      margin: 30px auto 0;
      width: 100%; }
      .landing-page-block-info .text-description p {
        font-size: 14px; }
        .landing-page-block-info .text-description p:nth-child(1) {
          margin-bottom: 20px; }
  .landing-page-visas-list {
    padding: 0 0 10px; }
    .landing-page-visas-list .visas-list-img {
      margin-bottom: 20px;
      width: 32px; }
    .landing-page-visas-list .visas-list-title {
      margin-bottom: 0; }
    .landing-page-visas-list ul {
      margin-bottom: 0; }
    .landing-page-visas-list li {
      width: 100%;
      padding: 20px 15px;
      min-height: auto; }
      .landing-page-visas-list li:nth-child(odd) {
        margin-right: 0; }
  .landing-page-field-activity-block {
    padding: 0 0 10px; }
    .landing-page-field-activity-block ul {
      margin-top: 30px; }
      .landing-page-field-activity-block ul li {
        margin-bottom: 20px;
        height: 103px; }
  .landing-page-full-information {
    padding: 0; }
    .landing-page-full-information .container {
      padding-top: 0; }
    .landing-page-full-information-subtitle {
      font-size: 16px;
      margin-top: -7px; }
    .landing-page-full-information .managers-list li {
      margin: 0 5px 10px; }
  .landing-page-our-partners {
    padding-top: 10px; }
    .landing-page-our-partners .partners-list {
      margin-top: 30px; }
      .landing-page-our-partners .partners-list li {
        width: calc(20% - 12px);
        margin-right: 10px; }
        .landing-page-our-partners .partners-list li:nth-child(8n+8) {
          margin-right: 10px; }
        .landing-page-our-partners .partners-list li:nth-child(7n+7) {
          margin-right: 10px; }
        .landing-page-our-partners .partners-list li:nth-child(5n+5) {
          margin-right: 0; } }

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .landing-page-visas-list .container {
    padding-right: 0;
    padding-left: 0;
    width: 100vw; }
  .landing-page-visas-list li {
    border-right: none;
    border-left: none; }
  .landing-page-field-activity-block ul {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center; }
    .landing-page-field-activity-block ul li {
      width: 224px;
      height: 149px;
      margin-right: 0; }
  .landing-page-full-information .managers-list {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
    .landing-page-full-information .managers-list li {
      margin: 0 0 20px;
      padding: 20px 45px; }
  .landing-page-full-information .name,
  .landing-page-full-information .phone {
    text-align: center; }
  .landing-page-our-partners .partners-list {
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center; }
    .landing-page-our-partners .partners-list li {
      width: 125px;
      margin-right: 20px;
      margin-bottom: 10px; }
      .landing-page-our-partners .partners-list li:nth-child(5n+5) {
        margin-right: 20px; }
      .landing-page-our-partners .partners-list li:nth-child(8n+8) {
        margin-right: 20px; }
      .landing-page-our-partners .partners-list li:nth-child(7n+7) {
        margin-right: 20px; } }

.my-jobs .nav-tabs-castom {
  margin-bottom: 32px; }

.my-jobs .table-response-vacancy-row {
  padding-top: 15px;
  padding-bottom: 15px; }

.my-jobs .icon-vip-paid {
  left: 9px; }

.my-jobs .block-my-vacancies .col1 {
  width: 49.9%;
  padding-right: 12%; }

.my-jobs .block-my-vacancies .col2 {
  width: 15.7%; }
  .my-jobs .block-my-vacancies .col2 .svg-inline--fa {
    display: none; }

.my-jobs .block-my-vacancies .col3 {
  width: 13.8%; }

.my-jobs .block-my-vacancies .col5 {
  width: 13.8%;
  text-align: center;
  position: relative; }

.my-jobs .service-not-active {
  margin-bottom: 0;
  color: #008BEB;
  border-bottom: 1px solid #008BEB;
  display: inline-block;
  cursor: pointer; }
  .my-jobs .service-not-active:hover {
    border-bottom: none; }

.my-jobs .service-active-title {
  background: none;
  color: #28A745;
  border: none;
  border-bottom: 1px dashed #28A745;
  padding-left: 0;
  padding-right: 0; }
  .my-jobs .service-active-title:focus {
    outline: none; }

.my-jobs .service-active-hover-block {
  top: calc(100% + 10px) !important;
  border: 1px solid #e0e0e0;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
  padding: 12px 12px 12px 20px;
  background: #fff; }
  .my-jobs .service-active-hover-block ul {
    padding: 0;
    color: #40505F;
    width: 187px;
    background-color: #fff;
    position: relative; }
    .my-jobs .service-active-hover-block ul:before {
      content: '';
      position: absolute;
      left: 50%;
      top: -18px;
      transform: translate(-50%, 0) rotate(45deg);
      width: 11px;
      height: 11px;
      border-left: 1px solid #e0e0e0;
      border-top: 1px solid #e0e0e0;
      background: #fff; }
    .my-jobs .service-active-hover-block ul li {
      list-style: disc;
      text-align: left;
      margin-left: 10px; }

.dropdown-menu-center {
  left: 50% !important;
  -webkit-transform: translate(-50%, 0) !important;
  -moz-transform: translate(-50%, 0) !important;
  -ms-transform: translate(-50%, 0) !important; }

.services-setting-form__desc {
  margin-bottom: 20px;
  color: #6C757D; }

.services-setting-form__btn:disabled, .services-setting-form__btn[disabled] {
  background: #74C687;
  border-color: #74C687; }

.services-setting-form__btn-wrap {
  text-align: right; }

.services-setting-blocks {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  margin-bottom: 15px;
  padding-bottom: 5px;
  padding-top: 15px;
  border: 1px solid #E0E0E0; }
  .services-setting-blocks__desc {
    border: none;
    margin-bottom: 0;
    padding-bottom: 0;
    padding-top: 0; }
    .services-setting-blocks__desc .services-setting-block__title {
      width: 50%;
      padding-left: 15px; }

.services-setting-block {
  padding-left: 15px;
  padding-right: 5px;
  width: 50%; }
  .services-setting-block__title {
    font-weight: 500;
    color: #6C757D;
    margin-bottom: 10px; }
    .services-setting-block__title__mob {
      display: none; }
  .services-setting-block .custom-checkbox {
    margin-bottom: 7px; }
    .services-setting-block .custom-checkbox .custom-control-label::before {
      border-radius: 0; }
  .services-setting-block .custom-control-label {
    font-weight: 400; }

.service-ischecked-block {
  margin-bottom: 10px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: baseline;
  -moz-align-items: baseline;
  -ms-align-items: baseline;
  align-items: baseline;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: flex-start; }
  .service-ischecked-block .fas {
    color: #00B955;
    font-size: 15px;
    margin-right: 12px; }
  .service-ischecked-block p {
    margin-bottom: 0;
    width: calc(100% - 27px); }

.badge-draft {
  color: #6C757D;
  border: 1px solid #6C757D; }

@media only screen and (max-width: 991px) {
  .my-jobs .nav-tabs-castom {
    margin-bottom: 22px; }
  .my-jobs .table-response-vacancy-header,
  .my-jobs .table-response-vacancy-row {
    padding-left: 23px; }
  .my-jobs .icon-vip-paid {
    left: 5px;
    top: 2px;
    font-size: 13px; }
  .my-jobs .table-response-vacancy-header p {
    font-size: 12px; }
  .my-jobs .block-my-vacancies .col1 {
    width: 44.2%;
    padding-right: 25px; }
  .my-jobs .block-my-vacancies .col2 {
    width: 12.2%; }
    .my-jobs .block-my-vacancies .col2 span {
      display: none; }
    .my-jobs .block-my-vacancies .col2 .svg-inline--fa {
      display: inline-block; }
    .my-jobs .block-my-vacancies .col2 .fa-check-circle {
      color: #00B955;
      font-size: 16px; }
    .my-jobs .block-my-vacancies .col2 .fa-eye-slash {
      color: #707070;
      font-size: 9px; }
    .my-jobs .block-my-vacancies .col2 .fa-clock {
      color: #FFDB49;
      font-size: 10px; }
  .my-jobs .block-my-vacancies .col3 {
    width: 16.7%; }
  .my-jobs .block-my-vacancies .col4 {
    width: 11%; }
  .my-jobs .block-my-vacancies .col5 {
    width: 15.9%; }
  .services-setting-block__title__tab {
    display: none; }
  .services-setting-block__first {
    width: 59%; }
  .services-setting-block__second {
    width: 41%; } }

@media only screen and (max-width: 767px) {
  .my-jobs h2 {
    padding: 0 10px; }
  .my-jobs .nav-tabs-castom {
    margin-left: -5px;
    margin-right: -5px; }
  .my-jobs .table-response-vacancy-row {
    padding-left: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-left: -5px; }
  .my-jobs .vip-paid-tariff .col3 {
    padding-left: 23px; }
  .my-jobs .icon-vip-paid {
    left: 8px;
    top: 0;
    font-size: 24px; }
  .my-jobs .block-my-vacancies .col1 {
    width: 100%;
    padding-right: 68px;
    margin-bottom: 5px; }
  .my-jobs .block-my-vacancies .col2 {
    width: auto;
    position: absolute;
    right: 25px;
    top: 46px;
    padding: 0; }
    .my-jobs .block-my-vacancies .col2 .fa-eye-slash {
      font-size: 12px; }
  .my-jobs .block-my-vacancies .col3 {
    width: 100%;
    padding-right: 68px;
    margin-bottom: 5px; }
  .my-jobs .block-my-vacancies .col4 {
    width: 66px;
    padding: 0 10px 0 0; }
  .my-jobs .block-my-vacancies .col5 {
    width: 100%;
    padding-right: 68px;
    padding-left: 0;
    text-align: left;
    order: 3; }
  .services-setting-form {
    padding-left: 10px;
    padding-right: 10px; }
  .services-setting-block {
    width: 100%;
    padding-left: 0;
    padding-right: 0; }
    .services-setting-block__title__mob {
      display: inline-block; }
    .services-setting-block__second {
      padding-top: 20px; }
  .services-setting-blocks {
    margin-bottom: 5px;
    padding: 0;
    border: none;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
    .services-setting-blocks__desc {
      display: none; } }

.ads-welcome-section {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  min-height: calc(100vh - 97px);
  background: url("/images/company-1.jpg") center top no-repeat;
  background-size: cover;
  position: relative; }
  .ads-welcome-section-title {
    text-align: center;
    text-shadow: 6px 6px 0 rgba(0, 0, 0, 0.2);
    margin: 0 auto 20px;
    font-size: 90px;
    color: #fff;
    font-weight: 700;
    max-width: 800px; }
  .ads-welcome-section-subtitle {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    color: #fff;
    max-width: 800px;
    margin: 0 auto; }

.ads-steps-section {
  padding-top: 30px; }
  .ads-steps-section .ads-step {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
    -ms-flex-pack: flex-start;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 45px; }
    .ads-steps-section .ads-step .material-icons, .ads-steps-section .ads-step .material-icons-outlined, .ads-steps-section .ads-step .material-icons-round, .ads-steps-section .ads-step .material-icons-sharp, .ads-steps-section .ads-step .material-icons-two-tone {
      font-size: 35px;
      color: #008BEB;
      margin-bottom: 15px; }
    .ads-steps-section .ads-step p {
      font-size: 20px;
      color: #008BEB;
      margin-bottom: 0;
      text-align: center;
      text-transform: uppercase; }

.ads-trust-us-section {
  padding: 48px 0 80px;
  background-color: #008BEB; }

.ads-trust-us-title {
  text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0 -5px 35px rgba(0, 0, 0, 0.3);
  font-size: 30px;
  color: #fff;
  font-weight: 700;
  text-align: center;
  margin: 0 auto 70px; }

.trust-us-item {
  padding-left: 102.5px;
  margin-bottom: 30px;
  position: relative; }
  .trust-us-item__img {
    width: 175px;
    height: 116px;
    position: absolute;
    left: 15px;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%); }

.trust-us-content {
  padding: 50px 40px 50px 118px;
  background-color: #fff;
  height: 100%; }
  .trust-us-content__title {
    font-size: 20px;
    margin-bottom: 20px;
    text-align: left;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: .28571rem; }
  .trust-us-content__text {
    font-size: 15px;
    margin-bottom: 20px;
    text-align: left;
    line-height: 1.6;
    font-weight: 500; }

.ads-trust-cards-section {
  background-color: #fff;
  padding: 60px 0 0; }

.ad2s-card {
  padding: 25px 25px 20px;
  text-align: center;
  margin-bottom: 30px; }
  .ad2s-card__yellow {
    border: 1px solid #ebc71d; }
  .ad2s-card__purple {
    border: 1px solid #9a69cb; }
  .ad2s-card__green {
    border: 1px solid #72c02c; }
  .ad2s-card__title {
    line-height: 1.4;
    font-size: 28px;
    margin-bottom: 7px;
    text-align: center;
    font-weight: 500;
    letter-spacing: .28571rem; }
  .ad2s-card__text {
    font-size: 14px;
    text-align: left;
    line-height: 1.6;
    font-weight: 300;
    padding-bottom: 45px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 20px; }

.ads-gallery-cards-section {
  padding: 0 15px; }

.ads-gallery-img {
  height: auto;
  width: 100%; }

.ads-contact-us-section {
  padding: 40px 0 55px;
  background-color: #008BEB;
  margin-bottom: -100px; }

.ads-contact-us__title {
  text-shadow: 0 4px 3px rgba(0, 0, 0, 0.4), 0 8px 13px rgba(0, 0, 0, 0.1), 0 18px 23px rgba(0, 0, 0, 0.1);
  line-height: 1.5;
  font-size: 20px;
  color: #fff;
  text-align: center;
  margin-bottom: 0; }

.ads-contact-us__btn {
  justify-content: center; }

@media only screen and (max-width: 1199px) {
  .ads-steps-section .ads-step p {
    font-size: 17px; }
  .ad2s-card__title {
    font-size: 28px; }
  .ads-gallery-img {
    height: 227px; } }

@media only screen and (max-width: 991px) {
  .ads-welcome-section {
    min-height: calc(100vh - 51px); }
  .ads-steps-section {
    padding: 30px 0 20px; }
  .ads-trust-us-section {
    padding: 60px 0 80px; }
  .ads-gallery-img {
    height: 171px; }
  .ads-contact-us-section {
    margin-bottom: -40px; } }

@media only screen and (max-width: 767px) {
  .ads-welcome-section-title {
    font-size: 39.9px; }
  .ads-welcome-section-subtitle {
    font-size: 14px; }
  .ads-steps-section .ads-step p {
    font-size: 19px; }
  .ads-steps-section .ads-step {
    margin-bottom: 85px; }
  .trust-us-item {
    padding-left: 15px;
    margin-bottom: 30px; }
    .trust-us-item__img {
      left: 50%;
      top: 0;
      -webkit-transform: translate(-50%, 0);
      -moz-transform: translate(-50%, 0);
      -ms-transform: translate(-50%, 0);
      transform: translate(-50%, 0); }
  .trust-us-content {
    padding: 166px 40px 50px; }
  .ads-gallery-cards-section {
    display: none; }
  .ads-contact-us__btn {
    margin-top: 15px; } }

.rates-list-content .card {
  border: 1px solid #008BEB; }
  .rates-list-content .card-header {
    padding: 12px 20px;
    background: none;
    border-bottom: none; }
    .rates-list-content .card-header__open {
      background: #F6F6F6; }
    .rates-list-content .card-header .package-name {
      cursor: pointer; }
      .rates-list-content .card-header .package-name u {
        text-decoration: none; }
        .rates-list-content .card-header .package-name u:hover {
          text-decoration: underline; }
  .rates-list-content .card-body {
    padding: 0; }

.rates-list-content .services-order-item {
  padding: 10px 20px; }
  .rates-list-content .services-order-item__title {
    color: #40505F; }
  .rates-list-content .services-order-item__desk {
    font-size: 13px;
    color: #6C757D;
    margin-bottom: 0;
    width: 97%; }
  .rates-list-content .services-order-item > div:nth-child(1) {
    width: calc(100% - 85px); }
  .rates-list-content .services-order-item > div:nth-child(2) {
    width: 85px;
    text-align: right; }

.rates-list-content .services-orders-footer {
  padding: 20px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between; }
  .rates-list-content .services-orders-footer > div {
    width: 70%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    -ms-justify-content: flex-end;
    justify-content: flex-end;
    -ms-flex-pack: flex-end; }
    .rates-list-content .services-orders-footer > div > .btn {
      margin-left: 10px; }

.rates-list-content .btn-services-icon {
  display: none;
  width: auto; }

.rates-list-content .btn-services-payments {
  margin-left: 10px; }

.detail-btn-services {
  margin-bottom: 0;
  color: #6C757D;
  font-size: 12px;
  display: none; }
  .detail-btn-services .fa-angle-down {
    color: #6C757D;
    font-size: 11px;
    margin-left: 3px; }

.edit-service-modal button.close {
  padding: 0;
  margin: 0;
  right: 7px;
  top: 7px;
  color: #E0E0E0; }

.edit-service-modal .modal-body {
  padding: 46px 0 0; }

.edit-service-modal .modal-footer {
  padding: 0 30px 20px; }

.edit-service-modal .service-detail-counter {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  padding: 10px 30px;
  border-bottom: 1px solid #e0e0e0; }
  .edit-service-modal .service-detail-counter .service-total-block {
    min-width: 78px;
    text-align: right; }

.edit-service-modal .service-modal-detail {
  margin-right: 15px;
  width: 290px; }
  .edit-service-modal .service-modal-detail p {
    margin-bottom: 10px;
    font-size: 13px;
    color: #6C757D;
    text-align: left; }
    .edit-service-modal .service-modal-detail p:nth-child(1) {
      margin-bottom: 8px;
      font-size: 14px;
      color: #062336; }

.no-services {
  text-align: center; }
  .no-services img {
    width: 251px;
    margin-bottom: 10px; }
  .no-services p {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px; }

@media only screen and (max-width: 991px) {
  .rates-list-content .card-header {
    padding: 12px 15px; }
    .rates-list-content .card-header[aria-expanded="true"] {
      padding: 17px 15px; }
  .rates-list-content .services-order-item {
    padding: 10px 15px; }
    .rates-list-content .services-order-item__desk {
      width: 94%; }
  .rates-list-content .services-orders-footer {
    padding: 20px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between; }
    .rates-list-content .services-orders-footer > div {
      width: 68%; }
      .rates-list-content .services-orders-footer > div > .btn {
        margin-left: 10px; }
      .rates-list-content .services-orders-footer > div .btn {
        font-size: 12px; } }

@media only screen and (max-width: 767px) {
  .rates-list-content .btn-services {
    padding-left: 5px;
    padding-right: 5px;
    width: 37px; }
    .rates-list-content .btn-services-icon {
      display: inline-block; }
    .rates-list-content .btn-services-info .btn-services-icon {
      height: 15px; }
    .rates-list-content .btn-services-payments .btn-services-icon {
      height: 18px; }
    .rates-list-content .btn-services span {
      display: none; }
  .rates-list-content .services-date-btns {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    webkit-align-items: flex-end !important;
    -moz-align-items: flex-end !important;
    -ms-align-items: flex-end !important;
    align-items: flex-end !important; }
    .rates-list-content .services-date-btns > span {
      font-size: 12px; }
    .rates-list-content .services-date-btns > div {
      margin-top: 10px; }
  .rates-list-content .package-name {
    margin-bottom: 14px; }
  .rates-list-content .card {
    border: none;
    -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3); }
    .rates-list-content .card-header {
      padding: 15px; }
  .rates-list-content .services-orders-footer {
    padding: 15px 16px; }
    .rates-list-content .services-orders-footer > div {
      width: 53%; }
      .rates-list-content .services-orders-footer > div .btn {
        width: 33px;
        padding-left: 0;
        padding-right: 0; }
  .rates-list-content .services-order-item__desk {
    display: none; }
  .detail-btn-services {
    display: inline-block; }
  .detail-btn-services-is-checked .detail-btn-services .fa-angle-down {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg); }
  .detail-btn-services-is-checked .services-order-item__desk {
    display: inline-block;
    margin-top: 8px; }
  .tariff-payment-title {
    padding-left: 0;
    padding-right: 0; }
  .edit-service-modal .modal-dialog {
    width: 91%; }
  .edit-service-modal .modal-footer {
    padding: 0 20px 20px; }
  .edit-service-modal .service-modal-detail {
    padding-right: 0;
    width: 100%; }
  .edit-service-modal div.d-flex.align-items-center.justify-content-between {
    width: 100%; } }

@media (min-width: 576px) {
  .edit-service-modal .modal-dialog {
    max-width: 545px; } }

@media (max-width: 575px) {
  .edit-service-modal button.close span {
    font-size: 15px; }
  .edit-service-modal .modal-body {
    padding: 44px 0 0; }
  .edit-service-modal .service-detail-counter {
    padding: 0 20px 15px;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start; }
  .edit-service-modal .service-modal-detail p {
    display: none; }
  .edit-service-modal .service-modal-detail p:nth-child(1) {
    margin-bottom: 10px;
    display: inline-block; }
  .edit-service-modal .counterwrap-modal {
    width: 100%; } }

.payment-order-logo-n-date {
  margin-top: 37px;
  margin-bottom: 14px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: flex-start; }

.payment-order-logo {
  width: 269px;
  margin-right: 184px; }
  .payment-order-logo img {
    width: auto;
    max-width: 100%;
    height: auto; }

.payment-order-date .payment-order-date__title {
  color: #008BEB;
  font-size: 25px;
  text-decoration: underline;
  font-weight: 500;
  text-align: left;
  margin-bottom: 12px; }

.payment-order-date p {
  color: #062336;
  font-size: 16px;
  margin-bottom: 0;
  text-align: left; }
  .payment-order-date p span {
    font-weight: 500;
    min-width: 140px; }

.payment-order-seller-customer {
  margin-bottom: 31px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: flex-start; }
  .payment-order-seller-customer .payment-order-seller-customer__title {
    margin-bottom: 10px;
    color: #062336;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    text-decoration: underline; }

.customer-info p,
.seller-info p {
  color: #062336;
  font-size: 14px;
  margin-bottom: 0;
  text-align: left; }
  .customer-info p span,
  .seller-info p span {
    font-weight: 500; }

.seller-info {
  width: 255px;
  margin-right: 190px; }

.customer-info {
  background-color: #F5F7F6;
  padding: 20px 18px;
  width: calc(100% - 445px); }

.payment-order-table {
  margin-bottom: 20px;
  width: 100%; }
  .payment-order-table th {
    border: .5px solid #fff;
    text-align: center;
    font-size: 14px;
    color: #fff;
    padding: 13px 18px 10px;
    background-color: #2582BF;
    font-weight: 400; }
    .payment-order-table th:nth-child(1) {
      width: 54.9%; }
    .payment-order-table th:nth-child(2) {
      width: 11.8%; }
    .payment-order-table th:nth-child(3) {
      width: 7.8%; }
    .payment-order-table th:nth-child(4) {
      width: 7.8%; }
    .payment-order-table th:nth-child(5) {
      width: 8.5%; }
    .payment-order-table th:nth-child(6) {
      width: 9.7%; }
  .payment-order-table td {
    border: .5px solid #fff;
    background-color: #EAF3FA;
    padding: 20px 0;
    text-align: center;
    font-size: 14px;
    color: #062336; }
    .payment-order-table td:nth-child(1) {
      width: 54.9%;
      padding: 20px 30px;
      text-align: left;
      font-weight: 500; }
    .payment-order-table td:nth-child(2) {
      width: 11.8%; }
    .payment-order-table td:nth-child(3) {
      width: 7.8%; }
    .payment-order-table td:nth-child(4) {
      width: 7.8%; }
    .payment-order-table td:nth-child(5) {
      width: 8.5%; }
    .payment-order-table td:nth-child(6) {
      width: 9.7%; }
  .payment-order-table-footer {
    width: 100%; }
    .payment-order-table-footer__row {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      -webkit-justify-content: flex-end;
      -moz-justify-content: flex-end;
      -ms-justify-content: flex-end;
      justify-content: flex-end;
      -ms-flex-pack: flex-end;
      margin-bottom: 10px; }
      .payment-order-table-footer__row p {
        margin-bottom: 0;
        font-size: 14px; }
        .payment-order-table-footer__row p:nth-child(1) {
          width: 200px;
          text-align: left; }
        .payment-order-table-footer__row p:nth-last-child(1) {
          width: 112px;
          text-align: right; }
      .payment-order-table-footer__row__last {
        width: 100%;
        padding: 10px 0;
        border-top: 1px solid #008BEB;
        border-bottom: 1px solid #008BEB;
        margin-bottom: 30px; }
        .payment-order-table-footer__row__last p {
          font-size: 16px;
          font-weight: 500; }

.already-paid-block {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between; }
  .already-paid-block > p {
    color: #DC3545;
    font-size: 30px;
    line-height: 55px;
    font-weight: bold;
    display: inline-block;
    border: 1px solid #DC3545;
    padding: 0 171px;
    margin-bottom: 0; }

.stamp-block {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start; }
  .stamp-block > p {
    color: #062336;
    font-size: 14px;
    text-align: left;
    margin-bottom: 15px; }
  .stamp-block > img {
    width: 280px; }

@media only screen and (max-width: 1024px) {
  .payment-order-logo-n-date {
    margin-bottom: 22px; }
  .payment-order-logo {
    width: 206px;
    margin-right: 86px; }
  .payment-order-date .payment-order-date__title {
    font-size: 24px;
    margin-bottom: 10px; }
  .payment-order-date p {
    font-size: 14px; }
  .seller-info {
    width: 260px;
    margin-right: 15px; }
  .customer-info {
    padding: 15px;
    width: calc(100% - 275px); }
  .payment-order-seller-customer {
    margin-bottom: 23px; }
  .payment-order-table th {
    font-size: 12px;
    padding: 13px 10px; }
    .payment-order-table th:nth-child(1) {
      width: 36.3%; }
    .payment-order-table th:nth-child(2) {
      width: 16.7%; }
    .payment-order-table th:nth-child(3) {
      width: 10.9%; }
    .payment-order-table th:nth-child(4) {
      width: 10.9%; }
    .payment-order-table th:nth-child(5) {
      width: 11.7%; }
    .payment-order-table th:nth-child(6) {
      width: 13.5%; }
  .payment-order-table td {
    padding: 15px 0; }
    .payment-order-table td:nth-child(1) {
      width: 36.3%;
      padding: 15px; }
    .payment-order-table td:nth-child(2) {
      width: 16.7%; }
    .payment-order-table td:nth-child(3) {
      width: 10.9%; }
    .payment-order-table td:nth-child(4) {
      width: 10.9%; }
    .payment-order-table td:nth-child(5) {
      width: 11.7%; }
    .payment-order-table td:nth-child(6) {
      width: 13.5%; }
  .payment-order-table-footer__row {
    margin-bottom: 8px; }
    .payment-order-table-footer__row__last {
      margin-bottom: 20px; }
  .already-paid-block > p {
    font-size: 20px;
    line-height: 50px;
    padding: 0 100px; } }

@media only screen and (max-width: 992px) {
  .payment-order-table th:nth-child(1) {
    width: 26.1%; }
  .payment-order-table th:nth-child(2) {
    width: 17.4%; }
  .payment-order-table th:nth-child(3) {
    width: 10.8%; }
  .payment-order-table th:nth-child(4) {
    width: 10.6%; }
  .payment-order-table th:nth-child(5) {
    width: 11.6%; }
  .payment-order-table th:nth-child(6) {
    width: 13.5%; }
  .payment-order-table td {
    font-size: 14px; }
    .payment-order-table td:nth-child(1) {
      width: 26.1%; }
    .payment-order-table td:nth-child(2) {
      width: 17.4%; }
    .payment-order-table td:nth-child(3) {
      width: 10.8%; }
    .payment-order-table td:nth-child(4) {
      width: 10.6%; }
    .payment-order-table td:nth-child(5) {
      width: 11.6%; }
    .payment-order-table td:nth-child(6) {
      width: 13.5%; } }

@media only screen and (max-width: 767px) {
  .customer-info,
  .payment-order-table {
    display: none; }
  .payment-order-logo-n-date {
    margin-top: 0;
    margin-bottom: 20px;
    -webkit-flex-direction: row-reverse;
    -moz-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    -webkit-align-items: flex-end;
    -moz-align-items: flex-end;
    -ms-align-items: flex-end;
    align-items: flex-end;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between; }
  .payment-order-logo {
    width: 108px;
    margin-right: 0; }
  .payment-order-date {
    width: calc(100% - 137px); }
  .seller-info {
    width: 100%;
    margin-right: 0; }
  .payment-order-date .payment-order-date__title {
    font-size: 18px; }
  .payment-order-date p span {
    min-width: 120px; }
  .payment-order-seller-customer {
    margin-bottom: 20px; }
  .already-paid-block {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center; }
  .already-paid-block > p {
    font-size: 18px;
    line-height: 44px;
    padding: 0 58px;
    margin-bottom: 15px; }
  .tariff-payment-table-row {
    overflow: hidden; } }

.cv-detail-page .vacancy-page-header {
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-bottom: 20px; }
  .cv-detail-page .vacancy-page-header .cv-btns-actions {
    width: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start;
    position: relative; }
  .cv-detail-page .vacancy-page-header .btn-print {
    padding-left: 10px;
    padding-right: 10px; }
    .cv-detail-page .vacancy-page-header .btn-print .fa-print {
      color: #008BEB;
      font-size: 18px; }
  .cv-detail-page .vacancy-page-header .cv-edit-btn {
    margin-left: 7px; }

.cv-detail-page .block-logo-btns {
  width: calc(100% - 430px);
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row; }

.cv-detail-page .offer-title {
  margin-bottom: 30px;
  width: 100%; }

.cv-detail-page .cv-detail-left-col {
  width: 280px;
  padding-right: 15px; }
  .cv-detail-page .cv-detail-left-col .bold-title {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 0; }

.cv-detail-page .cv-detail-right-col {
  width: calc(100% - 280px); }
  .cv-detail-page .cv-detail-right-col .cv-detail-link {
    margin-bottom: 15px;
    line-height: 17px; }
    .cv-detail-page .cv-detail-right-col .cv-detail-link:nth-last-child(1) {
      margin-bottom: 0 !important; }
  .cv-detail-page .cv-detail-right-col .ready-to-move {
    margin-bottom: 20px;
    line-height: 17px; }
  .cv-detail-page .cv-detail-right-col p {
    line-height: 20px;
    margin-bottom: 0; }

.cv-detail-page .photo-wrap {
  width: 120px;
  height: 120px;
  border: 1px solid #A0DDFD; }
  .cv-detail-page .photo-wrap img {
    max-height: 100%;
    width: auto;
    min-width: 100%;
    height: auto;
    position: relative;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
  .cv-detail-page .photo-wrap .no-img {
    width: 54px;
    height: auto;
    min-width: auto;
    min-height: auto; }

.cv-detail-page .author-name {
  font-size: 16px;
  margin-bottom: 18px; }

.cv-detail-page .header-cv-info {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  margin-bottom: 3px; }
  .cv-detail-page .header-cv-info p {
    margin-bottom: 0; }
  .cv-detail-page .header-cv-info p:nth-child(1) {
    width: 170px;
    color: #6C757D;
    line-height: 25px; }
  .cv-detail-page .header-cv-info p:nth-child(2) {
    width: calc(100% - 170px);
    line-height: 25px; }

.cv-detail-page .description-vacancy {
  padding-top: 18px; }

.cv-detail-page .cv-content-description {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: baseline;
  -moz-align-items: baseline;
  -ms-align-items: baseline;
  align-items: baseline;
  margin-bottom: 28px; }
  .cv-detail-page .cv-content-description-gallery {
    margin-bottom: 15px; }
  .cv-detail-page .cv-content-description.work-experience {
    margin-bottom: 20px; }

.cv-detail-page .block-gallery .img-wrap {
  width: 80px;
  height: 80px;
  margin-right: 20px; }

.cv-detail-page .fa-paperclip {
  font-size: 11px; }
  .cv-detail-page .fa-paperclip a {
    font-size: 14px; }

.cv-detail-page .block-gallery {
  margin-bottom: 0; }

.cv-detail-page .offer-content {
  margin-right: 0;
  width: 100%; }

.cv-detail-page .cv-detail-page-btns {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  -ms-justify-content: flex-end;
  justify-content: flex-end;
  -ms-flex-pack: flex-end; }

.cv-detail-page .reply-form .text-black-50 {
  color: #062336 !important;
  margin-bottom: 7px !important; }

.cv-detail-page .vacancy-page-form {
  padding: 10px 20px 20px; }

.cv-detail-page .vacancy-page-form .btn-form-group {
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: flex-start; }

.badge-outline-secondary {
  color: #6C757D;
  border: 1px solid #6C757D;
  font-size: 12px;
  font-weight: 400; }

@media only screen and (max-width: 991px) {
  .cv-detail-page .vacancy-page-header .cv-publish-btn,
  .cv-detail-page .vacancy-page-header .cv-edit-btn {
    display: none; }
  .cv-detail-page .cv-detail-left-col {
    width: 155px; }
  .cv-detail-page .cv-detail-right-col {
    width: calc(100% - 155px); } }

@media only screen and (max-width: 767px) {
  .cv-detail-page .offer-title {
    margin-bottom: 20px;
    padding-right: 20%; }
  .cv-detail-page .cv-detail-left-col {
    width: 100%;
    padding-right: 0; }
  .cv-detail-page .cv-detail-right-col {
    width: 100%; }
    .cv-detail-page .cv-detail-right-col .cv-detail-link {
      margin-bottom: 20px; }
  .cv-detail-page .block-logo-btns {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
  .cv-detail-page .header-cv-info p:nth-child(2),
  .cv-detail-page .header-cv-info p:nth-child(1) {
    line-height: 22px; }
  .cv-detail-page .btns-actions {
    position: absolute;
    right: 0;
    top: 0; }
    .cv-detail-page .btns-actions > div > div {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex; }
  .cv-detail-page .photo-wrap {
    margin-bottom: 15px; }
  .cv-detail-page .vacancy-page-header {
    border-bottom: none; }
    .cv-detail-page .vacancy-page-header .btn {
      width: 31px;
      height: 27px; }
  .cv-detail-page .line-on-mob {
    position: relative; }
    .cv-detail-page .line-on-mob:before {
      content: '';
      width: 100vw;
      height: 1px;
      background-color: #D6D6D6;
      position: absolute;
      bottom: 0;
      left: -15px; }
  .cv-detail-page .line-top-on-mob {
    position: relative; }
    .cv-detail-page .line-top-on-mob:after {
      content: '';
      width: 100vw;
      height: 1px;
      background-color: #D6D6D6;
      position: absolute;
      top: 0;
      left: -15px; }
  .cv-detail-page .cv-content-description {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 30px; }
    .cv-detail-page .cv-content-description.work-experience {
      margin-bottom: 15px;
      padding-top: 20px; }
  .cv-detail-page .pb-mob {
    margin-bottom: 0;
    padding-bottom: 20px; }
    .cv-detail-page .pb-mob .cv-detail-left-col {
      margin-bottom: 15px; }
  .cv-detail-page .author-name {
    margin-bottom: 15px; }
  .cv-detail-page .attached-files,
  .cv-detail-page .education-block,
  .cv-detail-page .about-myself-block {
    padding-bottom: 20px;
    margin-bottom: 0; }
  .cv-detail-page .attached-files,
  .cv-detail-page .education-block {
    padding-top: 20px; }
  .cv-detail-page .block-gallery .img-wrap {
    max-height: 268px;
    width: auto;
    min-width: 100%;
    height: auto; }
    .cv-detail-page .block-gallery .img-wrap img {
      left: 0;
      top: 0;
      -webkit-transform: translate(0, 0);
      -moz-transform: translate(0, 0);
      -ms-transform: translate(0, 0);
      transform: translate(0, 0); }
  .cv-detail-page .download-file-block {
    padding-bottom: 20px;
    margin-bottom: 20px; }
  .cv-detail-page .cv-detail-page-btns {
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: 30px; }
    .cv-detail-page .cv-detail-page-btns > div {
      margin-bottom: 30px;
      margin-left: 20px; }
      .cv-detail-page .cv-detail-page-btns > div:nth-child(1) {
        margin-left: 0; }
      .cv-detail-page .cv-detail-page-btns > div .btn {
        margin: 0 auto; }
        .cv-detail-page .cv-detail-page-btns > div .btn span {
          display: none; }
    .cv-detail-page .cv-detail-page-btns .btn-info,
    .cv-detail-page .cv-detail-page-btns .btn-outline-info,
    .cv-detail-page .cv-detail-page-btns .btn-outline-danger,
    .cv-detail-page .cv-detail-page-btns .btn-success {
      padding: 1px 1px;
      width: 54px;
      height: 35px;
      font-size: 0.875rem; } }

.company-manage .sub-title {
  margin: 20px 0; }

.company-manage-form {
  border-top: 0.5px solid #008BEB;
  padding-top: 20px; }
  .company-manage-form .company-description-block {
    width: 100%; }

.company-manage-block {
  position: relative;
  padding: 20px 37px;
  margin-bottom: 10px;
  background: #FAFAFA;
  border: 0.5px solid #EDEDED; }
  .company-manage-block-title {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 500; }
  .company-manage-block label {
    line-height: 1.1;
    margin-bottom: 10px; }
  .company-manage-block > textarea {
    resize: none;
    height: 85px;
    padding-top: 9px; }
  .company-manage-block textarea.form-control {
    min-height: 106px;
    resize: none; }
  .company-manage-block .form-control:focus {
    color: #062336; }
  .company-manage-block .choices {
    margin-bottom: 20px; }
  .company-manage-block .info-icon {
    color: #008BEB;
    font-size: 16px;
    vertical-align: text-bottom; }
  .company-manage-block .ql-container,
  .company-manage-block .ql-toolbar {
    background: #fff; }
  .company-manage-block .ql-toolbar.ql-snow,
  .company-manage-block .ql-container.ql-snow {
    border: 1px solid #ddd; }
  .company-manage-block .error-text {
    margin-bottom: 0; }

.company-manage .block-basic-information {
  width: calc(100% - 160px); }
  .company-manage .block-basic-information-logo {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between; }

.company-manage .block-logo {
  width: 140px;
  height: 140px;
  border: 1px solid #BDE4FF;
  overflow: hidden; }
  .company-manage .block-logo img {
    max-height: 100%;
    width: auto;
    max-width: 100%;
    height: auto; }
  .company-manage .block-logo .fa-image {
    font-size: 48px;
    color: #BDE4FF; }

.company-manage .company-manage-form-row {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  margin-bottom: 20px; }
  .company-manage .company-manage-form-row__last {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
    .company-manage .company-manage-form-row__last > div {
      width: 100%; }
  .company-manage .company-manage-form-row:nth-last-child(1) {
    margin-bottom: 0; }
  .company-manage .company-manage-form-row input.form-control {
    padding-left: 0.9rem; }
  .company-manage .company-manage-form-row select.form-control {
    background: url("/images/select-icon.svg") no-repeat 99% 50% #fff;
    background-size: 9px 11px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none; }
  .company-manage .company-manage-form-row .is-invalid .choices__inner {
    border-color: #DC3545; }

.company-manage .company-manage-form-col {
  width: calc(50% - 10px); }

.company-manage .is-invalid-block label {
  color: #DC3545 !important; }

.company-manage .subtitle-block {
  line-height: 1.2;
  font-size: 14px;
  color: #062336;
  margin: 20px 0; }

.company-manage .help-text {
  font-size: 12px; }

.company-manage .no-tariff-modal {
  display: none; }

.company-manage .no-tariff .company-manage-form-row,
.company-manage .no-tariff .subtitle-block,
.company-manage .no-tariff label {
  -webkit-filter: blur(2px);
  filter: blur(2px); }

.company-manage .no-tariff .no-tariff-modal {
  display: inline-block;
  background-color: #FFFFCE;
  border: 1px solid #FFFFCE;
  -webkit-box-shadow: 0 1px 10px 0 rgba(50, 50, 50, 0.4);
  -moz-box-shadow: 0 1px 10px 0 rgba(50, 50, 50, 0.4);
  box-shadow: 0 1px 10px 0 rgba(50, 50, 50, 0.4);
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 20px 50px;
  z-index: 1; }

.company-manage .form-control:invalid,
.company-manage .form-control.is-invalid {
  background-image: none; }

.company-manage .btn-section {
  margin-top: 20px; }

.company-manage .company-description-block {
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }
  .company-manage .company-description-block > div {
    width: 100%; }

@media only screen and (max-width: 991px) {
  .company-manage-form {
    padding-top: 15px; }
  .company-manage .sub-title {
    margin: 20px 0 15px; }
  .company-manage-block {
    padding: 15px 20px; }
    .company-manage-block-title {
      margin-bottom: 15px;
      font-size: 16px; }
  .company-manage .block-logo {
    width: 80px;
    height: 80px;
    margin-bottom: 20px; }
    .company-manage .block-logo .fa-image {
      font-size: 27px; }
  .company-manage .block-basic-information {
    width: 100%; }
    .company-manage .block-basic-information-logo {
      -webkit-flex-direction: column;
      -moz-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column; }
  .company-manage .company-manage-form-row {
    margin-bottom: 15px; } }

@media only screen and (max-width: 767px) {
  .company-manage h2 {
    font-size: 18px; }
  .company-manage .sub-title {
    margin: 20px 0 10px;
    font-size: 14px; }
  .company-manage-form {
    padding-top: 10px; }
  .company-manage-block {
    padding: 10px 10px 15px; }
  .company-manage-block-title {
    margin-bottom: 10px; }
  .company-manage .company-manage-form-row {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 0; }
    .company-manage .company-manage-form-row select.form-control {
      background: url("/images/select-icon.svg") no-repeat 97% 50% #fff; }
  .company-manage .company-manage-form-col {
    width: 100%;
    margin-bottom: 15px; }
  .company-manage .subtitle-block {
    margin: 15px 0; }
  .company-manage .uploader {
    margin-bottom: 15px;
    margin-right: 20px; }
  .company-manage .help-text {
    margin-top: -12px; }
  .company-manage .btn-section {
    padding-top: 10px; } }

.form-block-dropdown-content .choices__list.choices__list--dropdown.is-active {
  z-index: 2; }

.form-block-dropdown-content .choices[data-type*="select-multiple"] select.choices__input {
  display: block !important;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  left: 0;
  bottom: 0; }

.company-stars {
  cursor: default;
  text-align: left;
  float: left; }
  .company-stars-item {
    padding-left: 3px; }
    .company-stars-item .company-rating {
      font-size: 12px;
      background-color: #D6D6D6; }
    .company-stars-item .company-rating-active {
      font-size: 12px;
      background-color: #2581bc; }
  .company-stars > fieldset {
    border: none;
    display: inline-block;
    direction: rtl; }
    .company-stars > fieldset:not(:checked) > input {
      position: absolute;
      top: 0px;
      clip: rect(0, 0, 0, 0); }
    .company-stars > fieldset:not(:checked) > label {
      float: right;
      width: 24px;
      cursor: pointer; }
      .company-stars > fieldset:not(:checked) > label:hover i,
      .company-stars > fieldset:not(:checked) > label:hover ~ label i {
        background-color: #2581bc; }
    .company-stars > fieldset > input:checked ~ label i {
      background-color: #2581bc; }
    .company-stars > fieldset > label:active {
      position: relative;
      top: 2px; }

.company-addreview {
  padding: 20px; }
  .company-addreview label {
    font-weight: 400;
    margin-bottom: 1px; }
  .company-addreview .form-group {
    margin-bottom: 12px; }
  .company-addreview-info, .company-addreview-positive, .company-addreview-negative {
    padding: 4px 16px; }
  .company-addreview-info {
    background-color: #EBF6FE; }
  .company-addreview-positive {
    background-color: #EBFEEE; }
  .company-addreview-negative {
    background-color: #FEECEB; }
  .company-addreview-spanyes {
    background-color: #0CAA41;
    color: #ffffff;
    padding: 3px 7px;
    border-radius: 3px;
    min-width: 46px;
    display: inline-block;
    text-align: center; }
    .company-addreview-spanyes:hover {
      color: #ffffff;
      text-decoration: none; }
  .company-addreview-spanno {
    color: #ffffff;
    background-color: #D80614;
    padding: 3px 9px;
    border-radius: 3px;
    min-width: 46px;
    display: inline-block;
    text-align: center; }
    .company-addreview-spanno:hover {
      color: #ffffff;
      text-decoration: none; }
  .company-addreview-buttonyes {
    color: #36C004;
    padding: 2px 15px;
    display: inline-block;
    text-align: center;
    border: 1px solid #36C004; }
    .company-addreview-buttonyes-selected {
      background-color: #F0F0F0;
      color: #36C004;
      padding: 2px 15px;
      display: inline-block;
      text-align: center; }
  .company-addreview-buttonno {
    color: #D80614;
    padding: 2px 15px;
    display: inline-block;
    text-align: center;
    border: 1px solid #D80614; }
    .company-addreview-buttonno-selected {
      background-color: #F0F0F0;
      color: #D80614;
      padding: 2px 15px;
      display: inline-block;
      text-align: center; }
  .company-addreview-recommend fieldset {
    display: inline-block;
    position: relative; }
    .company-addreview-recommend fieldset [type="radio"]:checked,
    .company-addreview-recommend fieldset [type="radio"]:not(:checked) {
      position: absolute;
      top: 0px;
      clip: rect(0, 0, 0, 0); }
    .company-addreview-recommend fieldset [type="radio"]:checked + label,
    .company-addreview-recommend fieldset [type="radio"]:not(:checked) + label {
      position: relative;
      cursor: pointer;
      line-height: 20px;
      display: inline-block; }
    .company-addreview-recommend fieldset [type="radio"]:checked + label.company-addreview-buttonyes {
      color: #ffffff;
      background-color: #36C004; }
    .company-addreview-recommend fieldset [type="radio"]:checked + label.company-addreview-buttonno {
      color: #ffffff;
      background-color: #DC3545; }
  .company-addreview-addphoto {
    display: inline-block;
    background-image: url("/front/images/icon-photo.svg");
    padding: 9px 11px;
    vertical-align: middle; }
  .company-addreview-addvideo {
    display: inline-block;
    background-image: url("/front/images/icon-video.svg");
    padding: 13px;
    vertical-align: middle; }
  .company-addreview-linkvideo {
    display: inline-block;
    width: 160px;
    vertical-align: middle; }
    .company-addreview-linkvideo .form-control {
      font-size: 10px;
      padding: 0 12px;
      height: 24px; }
    .company-addreview-linkvideo .input-group-append {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin-left: -1px; }
      .company-addreview-linkvideo .input-group-append .input-group-text {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-bottom: 0;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        text-align: center;
        white-space: nowrap;
        background-color: #e9ecef;
        border: 1px solid #ced4da;
        border-radius: 0 2px 2px 0;
        border-left: none;
        background-image: url("/front/images/icon-close-dark.svg");
        height: 24px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 65%;
        padding: 0 12px;
        cursor: pointer; }
  .company-addreview-files-item {
    background-image: url("/front/images/icon-clip.svg");
    background-color: #F0F0F0;
    border: #D5D5D5 1px solid;
    display: inline-block;
    padding: 3px 6px 3px 21px;
    font-size: 10px;
    background-repeat: no-repeat;
    background-position: 5px center;
    background-size: 11px;
    border-radius: 12px;
    margin-right: 2px;
    cursor: default;
    margin-bottom: 5px; }
    .company-addreview-files-item-close {
      width: 14px;
      height: 14px;
      background-image: url("/front/images/icon-close-dark.svg");
      display: inline-block;
      vertical-align: middle;
      margin-left: 2px; }
  .company-addreview .btn-grey {
    padding: 5px 12px; }

.comment-card-item {
  margin-bottom: 30px; }
  .comment-card-item:nth-last-child(1) {
    margin-bottom: 0; }

.account-employers-nav {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: flex-start;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 20px; }

.account-employers-btn-link {
  width: 16.6%; }
  .account-employers-btn-link:hover {
    text-decoration: none;
    color: #0074f0; }
    .account-employers-btn-link:hover .account-employers-btn {
      border-color: #008BEB; }

.account-employers-btn {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: flex-start;
  padding: 10px 15px;
  width: 100%;
  min-height: 80px;
  border: 0.5px solid #e0e0e0; }
  .account-employers-btn__name {
    width: calc(100% - 38px);
    margin-bottom: 0;
    font-size: 16px;
    line-height: 1.2;
    color: #008BEB; }
  .account-employers-btn .icon-wrap {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    width: 28px;
    height: 28px;
    margin-right: 10px; }
  .account-employers-btn .fas {
    color: #008BEB; }
  .account-employers-btn .fa-clipboard-list {
    font-size: 23px; }
  .account-employers-btn .fa-tools,
  .account-employers-btn .fa-file-invoice-dollar {
    font-size: 20px; }
  .account-employers-btn .fa-heart,
  .account-employers-btn .fa-power-off,
  .account-employers-btn .fa-envelope-open-text {
    font-size: 18px; }

.account-employers-content {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between; }

.employer-detail-block {
  width: 277px;
  padding: 20px;
  border: 0.5px solid #e0e0e0;
  position: relative; }
  .employer-detail-block__row-name {
    font-weight: 500;
    line-height: 1;
    margin-bottom: 10px; }
  .employer-detail-block__row-content {
    line-height: 1;
    margin-bottom: 15px; }
    .employer-detail-block__row-content:nth-last-child(1) {
      margin-bottom: 0; }

.employer-information-blocks {
  width: calc(100% - 298px); }

.employer-information-block {
  margin-bottom: 10px;
  border: 0.5px solid #e0e0e0;
  padding: 20px;
  width: 100%; }
  .employer-information-block__empty {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between; }
    .employer-information-block__empty > div {
      width: calc(100% - 280px); }
  .employer-information-block__title {
    font-size: 18px;
    line-height: 133%;
    font-weight: 500;
    margin-bottom: 15px; }
  .employer-information-block__subtitle {
    color: #6C757D;
    margin-bottom: 0; }

.employer-information-table__header {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: flex-start;
  border-top: 0.5px solid #E5E5E5;
  border-bottom: 0.5px solid #E5E5E5;
  padding: 10px 0;
  margin-top: 15px;
  margin-bottom: 15px; }
  .employer-information-table__header > p {
    font-weight: 500;
    font-size: 15px;
    color: #6C757D;
    margin-bottom: 0;
    padding-right: 10px; }
    .employer-information-table__header > p:nth-child(1) {
      width: 45%; }
    .employer-information-table__header > p:nth-child(2) {
      width: 24%; }
      .employer-information-table__header > p:nth-child(2) span {
        display: none; }
    .employer-information-table__header > p:nth-child(3) {
      width: 31%; }

.employer-information-table__row {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: baseline;
  -moz-align-items: baseline;
  -ms-align-items: baseline;
  align-items: baseline;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: flex-start;
  margin-bottom: 10px; }
  .employer-information-table__row:nth-last-child(1) {
    margin-bottom: 0; }
  .employer-information-table__row__btn {
    margin-top: 15px;
    text-align: right; }
  .employer-information-table__row__links {
    margin-top: 15px;
    text-align: left; }
    .employer-information-table__row__links > a {
      margin-right: 45px; }
  .employer-information-table__row > p {
    padding-right: 10px;
    margin-bottom: 0; }
    .employer-information-table__row > p:nth-child(1) {
      width: 45%; }
    .employer-information-table__row > p:nth-child(2) {
      width: 24%; }
      .employer-information-table__row > p:nth-child(2) span {
        display: none; }
    .employer-information-table__row > p:nth-child(3) {
      width: 31%; }

.company-name-rate {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: baseline;
  -moz-align-items: baseline;
  -ms-align-items: baseline;
  align-items: baseline;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: flex-start;
  margin-bottom: 20px; }
  .company-name-rate > a {
    font-size: 16px;
    margin-right: 5px; }
  .company-name-rate > p {
    font-weight: bold;
    font-size: 14px;
    color: #008BEB;
    margin-bottom: 0;
    margin-right: 5px; }
  .company-name-rate .fa-star {
    font-size: 14px;
    color: #FFDB49; }

.company-detail-info {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: flex-start; }
  .company-detail-info__block {
    width: 38%; }
    .company-detail-info__block:nth-child(1) {
      margin-right: 7%; }
    .company-detail-info__block p {
      color: #6C757D;
      margin-bottom: 0; }
    .company-detail-info__block span {
      color: #062336; }

@media only screen and (max-width: 1199px) {
  .account-employers-btn-link {
    width: 25%; } }

@media only screen and (max-width: 992px) {
  .employer-detail-block,
  .account-employers-nav {
    display: none; }
  .employer-information-blocks {
    width: 100%; }
  .employer-information-table__header > p:nth-child(1),
  .employer-information-table__row > p:nth-child(1) {
    width: 51%; }
  .employer-information-table__header > p:nth-child(2),
  .employer-information-table__row > p:nth-child(2) {
    width: 26%; }
  .employer-information-table__header > p:nth-child(3),
  .employer-information-table__row > p:nth-child(3) {
    width: 23%; }
  .company-name-rate {
    margin-bottom: 12px; }
  .company-detail-info {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
    .company-detail-info__block {
      width: 100%; }
      .company-detail-info__block:nth-child(1) {
        margin-right: 7%; } }

@media only screen and (max-width: 767px) {
  .account-page .container {
    padding-left: 0;
    padding-right: 0; }
  .account-page .account-employers-header {
    padding-right: 10px;
    padding-left: 10px; }
  .employer-information-block {
    margin-bottom: 0;
    border-left: none;
    border-right: none;
    border-top: none;
    padding: 15px 10px 15px;
    position: relative; }
    .employer-information-block__empty {
      padding-top: 10px; }
    .employer-information-block:before {
      content: '';
      height: calc(100% + 2px);
      width: 5px;
      position: absolute;
      left: -5px;
      top: -1px;
      border-top: 1px solid #e0e0e0;
      border-bottom: 1px solid #e0e0e0; }
    .employer-information-block:after {
      content: '';
      height: calc(100% + 2px);
      width: 5px;
      position: absolute;
      right: -5px;
      top: -1px;
      border-top: 1px solid #e0e0e0;
      border-bottom: 1px solid #e0e0e0; }
    .employer-information-block:nth-child(1) {
      border-top: 0.5px solid #e0e0e0; }
    .employer-information-block__empty {
      -webkit-flex-direction: column;
      -moz-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-align-items: flex-end;
      -moz-align-items: flex-end;
      -ms-align-items: flex-end;
      align-items: flex-end; }
      .employer-information-block__empty > div {
        width: 100%; }
    .employer-information-block__title {
      font-size: 16px;
      margin-bottom: 10px; }
    .employer-information-block__subtitle {
      margin-bottom: 15px; }
  .employer-information-table__header {
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between; }
    .employer-information-table__header > p {
      font-size: 12px;
      padding-right: 10px; }
      .employer-information-table__header > p:nth-child(1) {
        width: auto; }
      .employer-information-table__header > p:nth-child(2) {
        width: auto;
        padding-left: 0; }
        .employer-information-table__header > p:nth-child(2) span {
          display: inline-block; }
      .employer-information-table__header > p:nth-child(3) {
        display: none; }
  .employer-information-table__row {
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between; }
    .employer-information-table__row > p:nth-child(1) {
      width: 67%; }
    .employer-information-table__row > p:nth-child(2) {
      width: auto;
      padding-left: 0; }
      .employer-information-table__row > p:nth-child(2) span {
        display: inline-block; }
    .employer-information-table__row > p:nth-child(3) {
      display: none; }
  .company-name-rate {
    margin-top: 20px;
    margin-bottom: 10px; } }

.account-applicant-header .upload-photo-wrap .account-settings-page {
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center; }

.account-applicant-header .account-img {
  background-color: #E5E5E5;
  width: 100px;
  height: 100px;
  border: #E5E5E5 1px solid;
  margin-right: 24px;
  overflow: hidden;
  border-radius: 50%;
  position: relative; }
  .account-applicant-header .account-img img {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
  .account-applicant-header .account-img .no-user-avatar {
    width: 79px; }
  .account-applicant-header .account-img .is-user-avatar {
    width: auto;
    height: auto;
    max-height: 50px;
    max-width: 90px; }

.account-applicant-nav {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: flex-start;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 10px; }

.account-applicant-btn {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: flex-start;
  padding: 0 20px; }
  .account-applicant-btn:nth-child(1) {
    padding-left: 0; }
  .account-applicant-btn__name {
    width: calc(100% - 38px);
    margin-bottom: 0;
    font-size: 16px;
    line-height: 1.2;
    color: #008BEB; }
    .account-applicant-btn__name:hover {
      color: #0074f0; }
  .account-applicant-btn .icon-wrap {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    width: 28px;
    height: 28px;
    margin-right: 10px; }
  .account-applicant-btn .far,
  .account-applicant-btn .fas {
    color: #008BEB; }
  .account-applicant-btn .fa-tools {
    font-size: 20px; }
  .account-applicant-btn .fa-heart,
  .account-applicant-btn .fa-power-off,
  .account-applicant-btn .fa-envelope-open-text {
    font-size: 18px; }

.applicant-information-blocks {
  width: 100%; }
  .applicant-information-blocks .employer-information-block {
    padding-bottom: 10px; }
    .applicant-information-blocks .employer-information-block__empty {
      position: relative;
      padding-bottom: 25px; }
      .applicant-information-blocks .employer-information-block__empty > div {
        width: 61%; }
      .applicant-information-blocks .employer-information-block__empty .employer-information-block__subtitle {
        margin-bottom: 30px; }
    .applicant-information-blocks .employer-information-block__img {
      height: 232px;
      width: auto;
      position: absolute;
      top: 50%;
      right: 64px;
      -webkit-transform: translate(0, -50%);
      -moz-transform: translate(0, -50%);
      -ms-transform: translate(0, -50%);
      transform: translate(0, -50%); }
      .applicant-information-blocks .employer-information-block__img__mob {
        display: none; }
  .applicant-information-blocks .employer-information-table__row__links {
    margin-top: 22px;
    padding-top: 10px;
    border-top: 0.5px solid #E5E5E5; }
  .applicant-information-blocks .employer-information-table__row > p:nth-child(1) {
    width: 50%; }
  .applicant-information-blocks .employer-information-table__row > p:nth-child(2) {
    width: 18%; }
  .applicant-information-blocks .employer-information-table__row > p:nth-child(3) {
    width: 32%; }
  .applicant-information-blocks .employer-information-table__header > p:nth-child(1) {
    width: 50%; }
  .applicant-information-blocks .employer-information-table__header > p:nth-child(2) {
    width: 18%; }
  .applicant-information-blocks .employer-information-table__header > p:nth-child(3) {
    width: 32%; }

.show-all-jobs,
.applicant-form-info {
  display: none; }

.applicant-form-wrap {
  background-color: #F9FAFC;
  margin-bottom: 20px;
  padding-top: 30px;
  padding-bottom: 30px; }
  .applicant-form-wrap .form-control {
    color: #6C757D; }
    .applicant-form-wrap .form-control:focus {
      color: #6C757D;
      border-color: #E5E5E5; }
  .applicant-form-wrap .autocomplete .autocomplete-items {
    max-height: 262px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25); }
    .applicant-form-wrap .autocomplete .autocomplete-items div {
      padding: 15px 20px; }
      .applicant-form-wrap .autocomplete .autocomplete-items div:hover .applicant-form-info {
        background-color: #e9e9e9; }
      .applicant-form-wrap .autocomplete .autocomplete-items div b, .applicant-form-wrap .autocomplete .autocomplete-items div strong {
        font-weight: 400;
        color: #062336;
        font-size: 16px; }
  .applicant-form-wrap .jumbotron-form-field {
    width: 83%; }
  .applicant-form-wrap .jumbotron-form-btn {
    width: 15%; }
    .applicant-form-wrap .jumbotron-form-btn-icon:before {
      display: none; }

.applicant-form .applicant-form-info {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: flex-start;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0 !important;
  border-bottom: none !important; }
  .applicant-form .applicant-form-info p {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    margin-bottom: 0;
    margin-right: 36px;
    color: #6C757D; }
    .applicant-form .applicant-form-info p .fas {
      font-size: 10px;
      margin-right: 5px; }

.applicant-form .show-all-jobs {
  display: block;
  font-size: 14px;
  color: #008BEB;
  text-align: center;
  padding: 9px 0;
  margin: 0;
  background-color: #fff;
  cursor: pointer; }
  .applicant-form .show-all-jobs:hover {
    color: #0074f0; }

@media only screen and (max-width: 992px) {
  .account-applicant-nav {
    display: none; }
  .applicant-information-blocks .employer-information-block__empty {
    padding-bottom: 20px; }
    .applicant-information-blocks .employer-information-block__empty > div {
      width: 70%; }
    .applicant-information-blocks .employer-information-block__empty .employer-information-block__subtitle {
      margin-bottom: 20px; }
  .applicant-information-blocks .employer-information-block__img {
    height: 132px;
    right: 8px; }
  .applicant-information-blocks .employer-information-table__row__links {
    margin-top: 20px; }
  .applicant-information-blocks .employer-information-table__row > p:nth-child(1) {
    width: 51%; }
  .applicant-information-blocks .employer-information-table__row > p:nth-child(2) {
    width: 28%; }
  .applicant-information-blocks .employer-information-table__row > p:nth-child(3) {
    width: 21%; }
  .applicant-information-blocks .employer-information-table__header > p:nth-child(1) {
    width: 51%; }
  .applicant-information-blocks .employer-information-table__header > p:nth-child(2) {
    width: 28%; }
  .applicant-information-blocks .employer-information-table__header > p:nth-child(3) {
    width: 21%; }
  .applicant-form-wrap {
    margin-bottom: 10px;
    padding-top: 20px;
    padding-bottom: 20px; } }

@media only screen and (max-width: 767px) {
  .applicant-information-blocks .employer-information-block__empty > div {
    width: 100%;
    text-align: center; }
  .applicant-information-blocks .employer-information-block__empty .employer-information-block__subtitle {
    margin-bottom: 10px; }
  .applicant-information-blocks .employer-information-block__title, .applicant-information-blocks .employer-information-block__subtitle {
    text-align: left; }
  .applicant-information-blocks .employer-information-block__img {
    display: none; }
    .applicant-information-blocks .employer-information-block__img__mob {
      display: block;
      height: 132px;
      width: auto;
      margin: 0 auto 10px; }
  .applicant-information-blocks .employer-information-table__row > p:nth-child(1) {
    width: 67%; }
  .applicant-information-blocks .employer-information-table__row > p:nth-child(2) {
    width: auto; }
  .applicant-information-blocks .employer-information-table__header > p:nth-child(1) {
    width: auto; }
  .applicant-information-blocks .employer-information-table__header > p:nth-child(2) {
    width: auto; }
  .applicant-form-wrap {
    padding: 20px 16px; }
    .applicant-form-wrap .jumbotron-form-field {
      width: 100%;
      margin-bottom: 15px; }
    .applicant-form-wrap .jumbotron-form-btn {
      width: 100%; }
  .applicant-form .applicant-form-info p {
    margin-right: 18px; } }

.reg-employer-page {
  padding-top: 55px; }
  .reg-employer-page-content {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
    margin-bottom: 40px; }
    .reg-employer-page-content__left {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
      -moz-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-align-items: flex-start;
      -moz-align-items: flex-start;
      -ms-align-items: flex-start;
      align-items: flex-start;
      width: 517px; }
    .reg-employer-page-content__right {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: flex-start;
      -moz-align-items: flex-start;
      -ms-align-items: flex-start;
      align-items: flex-start;
      -webkit-justify-content: flex-start;
      -moz-justify-content: flex-start;
      -ms-justify-content: flex-start;
      justify-content: flex-start;
      -ms-flex-pack: flex-start;
      padding: 44px;
      background: #F8F5EE; }
  .reg-employer-page .btn-outline-primary:hover {
    background-color: #0074f0;
    border-color: #0074f0;
    color: #fff; }
  .reg-employer-page .alert button.close {
    top: 31%; }
  .reg-employer-page .alert-success {
    border: none;
    background: #D4EDDA;
    color: #155724;
    margin-bottom: 30px;
    padding: 27px 103px;
    position: relative; }
  .reg-employer-page .alert-img {
    height: 63px;
    position: absolute;
    left: 0;
    bottom: 0; }

.reg-employer-img {
  height: 217px; }
  .reg-employer-img__first {
    margin-right: 32px; }

.reg-employer-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 83%;
  margin-bottom: 20px; }
  .reg-employer-title__second {
    margin-top: 44px; }

.reg-employer-subtitle {
  font-size: 14px;
  line-height: 143%;
  margin-bottom: 20px; }

.reg-employer-links-list {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: flex-start;
  border-top: 0.5px solid #E0E0E0;
  border-bottom: 0.5px solid #E0E0E0;
  padding: 30px 0; }

.reg-employer-link {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: flex-start;
  width: 25%;
  padding-right: 20px; }
  .reg-employer-link__desc {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start;
    margin-left: 14px; }
  .reg-employer-link-name {
    font-size: 14px;
    line-height: 143%;
    font-weight: 500;
    margin-bottom: 5px; }
  .reg-employer-link-route {
    font-size: 13px; }
  .reg-employer-link .material-icons, .reg-employer-link .material-icons-outlined, .reg-employer-link .material-icons-round, .reg-employer-link .material-icons-sharp, .reg-employer-link .material-icons-two-tone {
    font-size: 30px; }
  .reg-employer-link .my-company {
    color: #A0B3A8; }
  .reg-employer-link .your-profile {
    color: #BB99B7; }
  .reg-employer-link .look {
    color: #C6A78F; }
  .reg-employer-link .sms {
    color: #ECC8C9; }

@media only screen and (max-width: 991px) {
  .reg-employer-page {
    padding-top: 32px; }
    .reg-employer-page .alert-success {
      padding: 16px 103px;
      margin-bottom: 20px; }
    .reg-employer-page-content {
      margin-bottom: 20px; }
      .reg-employer-page-content__left {
        width: 370px; }
      .reg-employer-page-content__right {
        -webkit-align-items: flex-start;
        -moz-align-items: flex-start;
        -ms-align-items: flex-start;
        align-items: flex-start;
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        padding: 28px 42px; }
  .reg-employer-img {
    height: 160px; }
    .reg-employer-img__first {
      margin-right: 0;
      margin-bottom: 24px; }
  .reg-employer-title {
    font-size: 18px;
    line-height: 111%;
    margin-bottom: 10px; }
    .reg-employer-title__second {
      margin-top: 70px; }
  .reg-employer-links-list {
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 20px 0 0; }
  .reg-employer-link {
    width: 50%;
    padding-right: 20px;
    margin-bottom: 20px; } }

@media only screen and (max-width: 767px) {
  .reg-employer-page-content {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
    .reg-employer-page-content__left {
      width: 100%; }
    .reg-employer-page-content__right {
      display: none; }
  .reg-employer-page .alert button.close {
    top: 19%;
    right: .5rem; }
  .reg-employer-page .alert-success {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0 15px 13px; }
  .reg-employer-page .alert-img {
    height: 53px;
    position: relative; }
  .reg-employer-title__second {
    margin-top: 20px; }
  .reg-employer-subtitle {
    margin-bottom: 10px; }
  .reg-employer-link {
    width: 100%;
    padding-right: 0; } }

.create-company-page {
  padding-top: 24px; }
  .create-company-page-content {
    margin-bottom: 20px; }
    .create-company-page-content__left {
      width: calc(100% - 495px); }
    .create-company-page-content__right {
      padding: 0;
      width: 473px;
      background: none; }
      .create-company-page-content__right img {
        width: 100%;
        height: auto; }

.create-company-links-list {
  padding: 20px 0; }

.create-company-link {
  width: 33%; }

@media only screen and (max-width: 1024px) {
  .create-company-page-content__left {
    width: calc(100% - 370px); }
  .create-company-page-content__right {
    width: 350px; } }

@media only screen and (max-width: 991px) {
  .create-company-page {
    padding-top: 0; }
    .create-company-page-content__left {
      width: calc(100% - 225px); }
    .create-company-page-content__right {
      width: 214px; }
  .create-company-links-list {
    padding: 20px 0 0; }
  .create-company-link {
    width: 50%; } }

@media only screen and (max-width: 767px) {
  .create-company-page-content__left {
    width: 100%;
    margin-bottom: 20px; }
    .create-company-page-content__left .reg-employer-subtitle {
      margin-bottom: 20px; }
  .create-company-page-content__right {
    width: 100%;
    display: inline-block;
    text-align: center; }
  .create-company-link {
    width: 100%; } }

@media only screen and (max-width: 767px) and (orientation: landscape) {
  .create-company-page-content__right img {
    width: 60%; } }
