.vacancies-filter {
	width: 280px;
	&-bg {
		padding: 15px 13px 17px;
		width: 100%;
		margin-bottom: 30px;
		border: 1px solid #F0F0F0;
	}
	.form-group {
		margin-bottom: 28px;
		label {
			margin-bottom: 7px;
		}
	}
	.btn-outline-primary {
		color: #14669B;
		border-color: #14669B;
		margin: 0 13px;
		&:hover {
			background-color: #14669B;
			border-color: #14669B;
			color: #fff;
		}
	}
	input[type=checkbox] {
		transform: scale(1.5);
	}
	.block-buttons {
		@include flexbox();
		@include align-items(center);
		@include justify-content(flex-end);
	}
	.btn-advanced-search {
		cursor: pointer;
		width: 100%;
		@include flexbox();
		@include align-items(center);
		@include justify-content(space-between);
		p {
			color: $primary;
			margin: 0;
		}
		i {
			color: $primary;
		}
		&.active {
			i {
				@include transform(rotate(-180deg));
			}
		}
	}
	.form-check-label {
		margin-left: 5px;
	}
}

.choices__inner {
	background-color: #fff;
}

@media only screen and (max-width: 991px) {
	.vacancies-filter {
		width: 255px;
	}
}

@media only screen and (max-width: 767px) {
	.vacancies-mob {
		.vacancies-content {
			position: relative;
		}
		.vacancies-filter {
			margin-bottom: 20px;
			width: 100%;
			display: none;
			position: absolute;
			background-color: #fff;
			z-index: 1;
			top: 52px;
			border-bottom: 1px solid #D6D6D6;
			padding-bottom: 20px;
			.form-group label {
				color: #676767;
			}
			&-bg {
				padding: 0 10px;
				border: none;
				margin-bottom: 20px;
			}
			.choices__inner,
			.form-control {
				color: rgba(0, 0, 0, .38);
			}
			.form-group {
				margin-bottom: 10px;
			}
			.block-buttons {
				-webkit-justify-content: center !important;
				-moz-justify-content: center !important;
				-ms-justify-content: center !important;
				justify-content: center !important;
				-ms-flex-pack: center !important;
			}
			.btn {
				margin: 0 10px;
			}
		}
	}
}
