.thanks-page{
    padding-top: 195px;
    text-align: center;
    .btn{
        margin-top: 65px;
    }
    &-title{
        font-weight: 600;
        margin-bottom: 40px;
    }
    img{
        width: 193px;
        height: auto;
        margin-bottom: 35px;
    }
}

a {
    color: $primary;
}

@media only screen and (max-width: 767px) {
    .thanks-page {
        padding-top: 95px;
        &-title,
        img{
            margin-bottom: 15px;
        }
        .btn{
            margin-top: 15px;
        }
    }
}