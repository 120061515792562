.vacancy-page {
  &-header {
    @include align-items(flex-start);
    @include justify-content(space-between);
    border-bottom: 1px solid $UILightGray;
    position: relative;

    &-left {
      width: calc(100% - 350px);
      @include align-items(flex-start);
      @include flex-direction(column);
      @include flexbox();
    }

    //.fa-pencil-alt {
    //  font-size: 17px;
    //}

    .employer-type-mob {
      display: none;
    }
  }

  .logo-company {
    width: 90px;

    img {
      width: auto;
      max-width: 100%;
      height: auto;
    }
  }

  .btn-mr {
    margin-right: 20px !important;
  }

  .btn-ml {
    margin-left: 20px !important;
  }

  &-form {
    padding: 19px;
    margin-bottom: 20px;
    background-color: #ECF5FC;
    border: 1px solid #ECF5FC;
    border-radius: 0;

    label {
      font-weight: 500;
      @include flexbox();
    }

    .fa-asterisk {
      font-size: 9px;
    }

    .btn-form-group {
      @include flexbox();
      @include align-items(center);
      @include justify-content(space-between);
    }
  }

  .modal-dialog {
    margin-top: 0;
  }

  .modal-body {
    text-align: left;
  }

  .left-col {
    padding-top: 0;
  }

  .right-col .form-notification-subscription {
    margin-top: 0;
  }

  .offer-title {
    font-weight: 400;
    font-size: 24px;
    line-height: 1;
    margin-bottom: 4px;
  }

  .author-info {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 8px;

    .url {
      font-weight: 500;
      color: #008AEA;

      .verified-block {
        height: 27px;
        width: auto;

      }
    }

    .for-disk {
      margin-left: 0;
    }
  }

  .for-disk {
    padding-left: 12px;
    margin-left: 5px;
  }

  .offer-info {
    flex: 1 1 auto;
    max-width: 285px;

    h3.block_header {
      font-size: 16px;
      margin-bottom: 0;
    }

    .info-title {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 0;
      margin-top: 10px;
    }

    .detail-info-val {
      margin-bottom: 0;

      .fa-paperclip {
        font-size: 11px;
      }
    }
  }

  .offer-content {
    margin-right: 130px;
    width: calc(100% - 350px);

    p {
      margin-bottom: 0;

      span {
        color: $textPrimary !important;
      }
    }

    ul {
      margin-bottom: 0;
    }
  }

  .date-id-views {
    margin-bottom: 20px;
    color: $secondary !important;
  }

  .actions-block {
    margin-bottom: 20px;
  }

  &-company-logo {
    width: 128px;
    height: 128px;
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);

    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }

  &-company-logo-mob {
    display: none;
  }

  hr {
    margin-top: 10px;
  }

  .vacancy-video {
    width: 500px;
    height: 300px;
    margin-bottom: 30px;
  }

  .reply-block-sticky {
    position: fixed;
    top: 96px;
    left: 0;
    padding: 20px 0;
    background: #fff;
    z-index: 3;
    width: 100%;
    -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
    display: none;

    &__fixed {
      display: inline-block;
    }

    &__desc {
      margin-bottom: 0;
    }

    .container {
      @include flexbox();
      @include align-items(center);
      @include justify-content(flex-start);
    }
  }
}

.color-secondary {
  color: $secondary !important;
}

.dropdown-offer-contacts-title {
  cursor: pointer;

  i {
    margin-left: 5px;
    margin-top: 0;
    font-size: 16px;
  }

  &.active-el {
    i {
      @include transform(rotate(180deg));
    }
  }
}

.show-phone {
  border-radius: 3px;
  border: none;
  background-color: #F3F3F3;
  color: #14669B;
  line-height: 35px;
  margin-bottom: 25px;
  padding: 0 10px;

  i {
    margin-right: 10px;
    @include rotate(90);
  }

  &:hover {
    background-color: #E2E2E2;
  }

  &.focus {
    outline: none;
  }

  .phone-hide {
    display: inline-block;
  }

  .phone-show {
    display: none;
  }

  &.phone-active {
    .phone-hide {
      display: none;
    }

    .phone-show {
      display: inline-block;
    }
  }
}

.block-logo-btns {
  @include flexbox();
  @include justify-content(space-between);
  @include flex-direction(column);
  width: 100%;
}

.block-vacancy-page-btn {
  padding-top: 20px;
  padding-bottom: 30px;
  @include flexbox();
  @include align-items(center);
  @include justify-content(flex-start);
}

.block-logo {
  @include flexbox();
  @include align-items(flex-start);
  @include justify-content(flex-start);
}

.vacancy-name-date-place {
  width: calc(100% - 95px);

  .vacancy-name {
    font-size: 18px;
    font-weight: 500;
    color: $primary;
  }

  .company-name {
    font-size: 14px;
    color: $primary;
  }
}

.block-logo-vacancy-name {
  font-size: 18px;
  font-weight: 500;
  color: $primary;
}

.description-vacancy {
  padding-top: 20px;
}

.date-place-tags {
  @include flexbox();
  @include align-items(baseline);
  @include justify-content(flex-start);

  p {
    position: relative;
    padding-left: 10px;
    margin: 0 25px 0 0;
    font-size: 14px;
    color: $textPrimary;
  }

  i {
    color: $primary;
  }

  > div {
    @include flexbox();
    @include align-items(baseline);
    @include justify-content(flex-start);
    padding-bottom: 8px;
  }
}

.vacancy-page-content {
  @include flexbox();
  @include align-items(flex-start);
  @include justify-content(space-between);
  margin-bottom: 35px;
}

.left-col {
  width: calc(100% - 400px);
  padding-top: 45px;
}

.right-col {
  width: 100%;
  max-width: 380px;

  .form-notification-subscription {
    border: 1px solid #F0F0F0;
    width: 100%;
    padding: 30px 20px 25px;
    margin-bottom: 15px;
  }

  .form-control,
  .form-notification-subscription .form-group,
  .form-notification-subscription-title {
    margin-bottom: 25px;
  }

  .form-notification-subscription .form-group {
    @include flex-direction(column);
    @include align-items(center);
  }
}

.block-similar-jobs {
  border: 1px solid #F0F0F0;
  border-radius: 2px;
  margin-bottom: 5px;

  .block-title {
    font-size: 16px;
    color: #062336;
    padding: 25px 20px;
    border-bottom: 1px solid #F0F0F0;
    margin: 0;
  }

  ul {
    padding: 0;
    margin: 0;
  }

  li {
    padding: 20px 20px 10px;
    border-bottom: 1px solid #F0F0F0;
  }

  li:nth-last-child(1) {
    border-bottom: 0;
  }

  .vacancy-title {
    margin-bottom: 0;
  }

  .type-employment {
    margin-bottom: 5px;
  }
}

.date-block-you-need {
  @include flexbox();
  @include align-items(flex-start);
  @include justify-content(flex-start);
  margin-bottom: 5px;

  p {
    margin: 0 15px 0 0;
  }
}

.btn-show-all {
  text-align: right;
  font-size: 13px;
  padding-right: 20px;
  margin: 0;
  position: relative;

  &:before {
    content: '';
    width: 12px;
    height: 8px;
    background: url("/images/arr-down.png") no-repeat;
    background-size: cover;
    position: absolute;
    right: 0;
    top: 50%;
    @include transform(translate(0, -50%) rotate(-90deg));
  }
}

li {
  list-style-type: none;
}

.block-recently-watched {
  padding: 35px 35px 30px;
  border: 1px solid #F0F0F0;
  border-radius: 2px;
  margin: 30px 0 10px;

  ul {
    padding: 0;
    margin: 0;
  }
}

.post-resume-banner-title {
  color: #14669B;
  font-size: 18px;
  text-align: center;
  margin-bottom: 30px;
}

.post-resume-banner-subtitle {
  text-align: center;
  margin-bottom: 40px;
}

.block-post-resume-banner {
  padding: 15px 20px 20px;
  border: 1px solid #F0F0F0;
  border-radius: 2px;
  text-align: center;
}

.block-rating {
  font-size: 14px;
  font-weight: 500;
  color: #5E5E5E;
}

.vacancy-subtitle {
  font-size: 14px;
  color: $textPrimary;
  text-align: left;
  margin-bottom: 25px;
  font-weight: 500;
}

.vacancy-text {
  font-size: 14px;
  color: $textPrimary;
  text-align: left;
  margin-bottom: 25px;
}

.block-gallery {
  @include flexbox();
  @include align-items(center);
  @include justify-content(flex-start);
  margin-bottom: 10px;
  margin-top: 11px;

  &-title {
    margin-top: 18px;
    font-size: 16px;
    font-weight: 500;
  }

  .img-wrap {
    margin-right: 20px;
    width: 80px;
    height: 80px;
    overflow: hidden;
    text-align: center;
    border: none;
    box-shadow: none;
    padding: 0;
    border-radius: 0;

    img {
      max-height: 100%;
      width: auto;
      min-width: 100%;
      height: auto;
      position: relative;
      left: 50%;
      top: 50%;
      @include translate(-50%, -50%);
    }
  }

  .img-wrap:nth-last-child(1) {
    margin-right: 0;
  }

  .fa-images {
    display: none;
  }
}

.ad-code-published-btn {
  @include flex-direction(column);
}

.ad-code-published {
  @include flexbox();
  @include align-items(center);
  @include justify-content(flex-start);
  margin-bottom: 20px;

  &-btn {
    @include flexbox();
    @include align-items(flex-start);
    @include justify-content(space-between);
  }

  p {
    color: #777777;
    font-size: 14px;
    margin-bottom: 6px;
    margin-right: 20px;
  }

  p:nth-last-child(1) {
    margin-bottom: 20px;
  }

  span {
    font-weight: 500;
  }

  a {
    color: #B13737;
    font-size: 14px;
  }
}

.cv-edit-btn {
  margin-left: 10px;
}

.vacancy-page-mob-show-portrait,
.vacancy-page-mob-show {
  display: none;
}

.vacancy-page-dropdown {
  display: none;
  position: absolute;
  right: 0;
  top: -11px;

  &-icon {
    width: 43px;
    height: 43px;
    border-radius: 50%;
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);

    &:hover {
      background-color: #F4F4F4;
    }

    > div {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: $primary;
      margin: 0 2px;
    }
  }
}

.vacancy-dropdown-menu {
  padding: 0;

  .dropdown-item {
    padding: 8px 10px;
    color: $textPrimary;

    &:hover,
    &:focus {
      color: #fff;
      background-color: $primary;
    }
  }
}

@media only screen and (max-width: 1199px) {
  .block-logo-btns {
    @include flex-direction(column);
  }
  .block-logo {
    @include align-items(flex-start);
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 1024px) {
  .vacancy-page .offer-content {
    margin-right: 90px;
    width: calc(100% - 335px);
  }
}

@media only screen and (max-width: 991px) {
  .vacancy-page-content {
    @include flex-direction(column);
    @include align-items(center);

    .offer-content {
      margin-right: 70px;
      width: calc(100% - 315px);
    }
  }
  .left-col,
  .right-col {
    width: 100%;
    max-width: 100%;
  }
  .date-block-you-need {
    @include flex-direction(column);
    @include align-items(flex-start);
  }
  .ad-code-published-btn {
    @include align-items(center);

    > div:nth-child(1) {
      text-align: center;

      button {
        margin: 0 10px 10px !important;
      }
    }
  }
  .right-col .form-notification-subscription,
  .block-recently-watched {
    padding: 10px;
  }
  .ad-code-published-btn > div:nth-child(1) {
    margin: auto;
  }
  .block-logo {
    @include flex-direction(column);
  }
  .vacancy-name-date-place {
    width: 100%;
  }
  .date-place-tags {
    @include flex-wrap(wrap);

    > div {
      width: 100%;
      margin-bottom: 20px;
      padding-bottom: 0;
    }
  }
  .vacancy-page .logo-company {
    margin: 20px auto 0;
  }
  .ad-code-published {
    @include flex-direction(column);
  }
  .description-vacancy {
    @include flex-direction(column);
    @include align-items(center);
    padding-top: 20px;
  }
  .vacancy-page {
    .offer-content {
      margin-right: 0;
      width: 100%;
    }

    .offer-info {
      width: 100%;
      max-width: 245px;
    }

    &-mob {
      &-hide {
        display: none;
      }

      &-show {
        @include flexbox();
        @include align-items(center);
        @include justify-content(space-between);
      }

      .offer-info {
        @include flex-direction(column);
        @include align-items(flex-start);
        max-width: none;

        .info-title {
          color: $secondary;
          font-size: 12px;
          margin-top: 0;
        }
      }

      .row-content-info {
        @include flexbox();
        @include align-items(center);
        margin-bottom: 7px;
        width: 100%;

        &__contacts {
          @include align-items(flex-start);
          @include flex-direction(column);
        }

        &__small {
          width: 351px;

          h3 {
            width: 52.8%;
          }

          p {
            width: 43.2%;
          }
        }

        h3 {
          width: 44%;
          min-width: 185px;
          padding-right: 25px;
        }

        p {
          width: 56%;
          min-width: 165px;
          font-size: 14px;
          color: $textPrimary;
          margin-bottom: 0;
        }

        a, div {
          width: 100%;
        }

        .dropdown-offer-contacts {
          margin-top: 10px;
        }
      }

      .block-posted-views-shotr {
        @include flexbox();
        @include align-items(flex-start);
        @include justify-content(space-between);
        margin-bottom: 20px;
        margin-top: 20px;

        p {
          margin-bottom: 0;
          color: #707070;
        }

        div {
          max-width: 50%;
        }
      }

      .offer-title {
        padding-right: 150px;
        margin-bottom: 10px;
      }

      .author-info {
        margin-bottom: 15px;
        padding-right: 150px;
      }

      .offer-content p {
        margin-bottom: 0;
      }

      .field-on-mob {
        resize: none;
        height: 70px;
      }

      .vacancy-page-form .btn-form-group {
        @include justify-content(flex-start);

        .btn {
          margin-left: 0;
        }
      }

      .report-form-mob {
        h5,
        label,
        p {
          color: rgba(0, 0, 0, 0.5)
        }

        textarea {
          width: 100%;
          height: 70px;
          resize: none;
          border: 1px solid $input-border-color;
        }

        label {
          @include flexbox();
          @include align-items(center);
        }

        .btn {
          margin-right: 20px;
        }
      }

      .vacancy-video {
        width: 361px;
        height: 217px;
        margin-bottom: 16px;
      }
    }

    &-company-logo {
      width: 120px;
      height: 120px;
      position: absolute;
      right: 0;
      top: 0;
    }

    .vacancy-page-header {
      &-left {
        width: 100%;
      }
    }

    .block-gallery {
      @include flex-wrap(wrap);
      margin-bottom: 20px;

      .block-gallery .img-wrap {
        margin-right: 14px;
      }
    }

    .reply-block-sticky__fixed {
      display: none;
    }
  }
}

@media only screen and (max-width: 767px) {
  .vacancy-page {
    &-header {
      @include flex-direction(column-reverse);
      @include align-items(center);
      border-bottom: none;

      &:before {
        content: '';
        width: 100%;
        height: 1px;
        background-color: #D6D6D6;
        position: absolute;
        bottom: 0;
        left: 0;
      }

      &-left {
        @include flex-direction(row);
      }

      .btn-info,
      .btn-outline-info {
        width: 25px;
        height: 25px;
        padding: 1px;

        span {
          display: none;
        }

        //.fa-pencil-alt {
        //  font-size: 14px;
        //}
      }

      .employer-type-mob {
        display: inline-block;
        font-size: 12px;
        color: $secondary;
        margin-bottom: 0;
        width: calc(100% - 45px);
      }

      .vacancy-dropdown-menu {
        .btn-info,
        .btn-outline-info {
          width: 100%;
          height: auto;
          padding: 0;
          background-color: #fff;
          border: none;
          margin: 0;
          @include align-items(flex-start);

          .fa-heart {
            display: none;
          }

          span {
            display: inline-block;
            padding: 8px 10px;
            color: #062336;
            font-size: 0.875rem;
          }
        }
      }
    }

    &-company-logo {
      display: none;

      &-mob {
        display: inline-block;
        width: 50px;
        height: 50px;
        position: absolute;
        right: -23px;
        top: -33px;
      }
    }

    .actions-block {
      @include align-items(flex-end);
      @include flex-direction(column);
      margin-bottom: 0;
    }

    .offer-title {
      padding-right: 0;
      font-size: 18px;
      line-height: 1.2;
      margin-bottom: 6px;
      width: calc(100% - 45px);
    }

    .author-info {
      width: calc(100% - 45px);

      .url {
        @include flexbox();
        @include align-items(center);

        .verified-block {
          height: 21px;
          margin-left: 5px;
        }
      }
    }

    &-mob {
      .author-info {
        font-size: 12px;
        padding-right: 0;
        margin-bottom: 0;

        .mob-hide {
          display: none;
        }
      }

      .row-content-info {
        &__small {
          &:nth-last-child(1) {
            margin-bottom: 20px;
          }
        }

        h3 {
          width: 42.6%;
          min-width: 120px;
          padding-right: 10px;
        }

        p {
          width: 57.4%;
          min-width: 160px;
        }
      }

      .block-posted-views-shotr div {
        max-width: 60%;
      }

      &-show-portrait {
        @include flexbox();
        @include justify-content(flex-end);
      }

      .offer-info {
        position: relative;
        padding-top: 52px;

        &:before {
          content: '';
          width: calc(100% + 25px);
          height: 1px;
          background-color: #D6D6D6;
          position: absolute;
          top: 32px;
          left: 0;
        }
      }
    }

    .btn {
      margin: 0 auto 20px;
      @include flexbox();
      @include flex-direction(column);
      @include align-items(center);
      @include justify-content(center);
    }
  }
  .block-logo-btns {
    width: calc(100% - 70px);
  }
  .vacancy-page-mob-dnone,
  .block-gallery-title {
    display: none;
  }
  .btn-report,
  .btn-success-mob {
    margin-left: 20px !important;
    padding: 1px 1px;
    width: 54px;
    height: 35px;

    span {
      display: none;
    }
  }
  .vacancy-page-dropdown {
    display: inline-block;
  }
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .block-vacancy-page-btn {
    @include flex-direction(column);
  }

  .vacancy-page {
    &-header {
      &:before {
        width: 100vw;
        left: -15px;
      }
    }

    .offer-info {
      &:before {
        width: 100vw;
        left: -15px;
      }
    }
  }
  .report-form-footer {
    @include flexbox();
    @include justify-content(flex-start);

    .btn {
      margin-left: 0;
    }
  }
}

@media only screen and (max-width: 450px) and (orientation: portrait) {
  .block-gallery {
    @include justify-content(flex-start);
    @include flex-wrap(wrap);

    .img-wrap {
      width: 100%;
      margin-right: 0;
    }
  }
  .vacancy-page-form {
    padding: 16px 15px 0;
    width: calc(100% + 30px);
    left: -15px;
  }
  .btn-form-group {
    margin-top: 25px;

    .btn {
      margin-right: 20px;
    }
  }
  .vacancy-page-mob {
    .img-on-mob {
      display: none;
    }

    .block-gallery {
      margin-bottom: 20px;
      position: relative;
      margin-top: 15px;

      .img-wrap {
        height: auto;
        max-height: 268px;
        margin-bottom: 0;

        img {
          max-height: 268px;
        }
      }

      &.more-img .fa-images {
        display: inline-block;
        color: $textForDarkElements;
        font-size: 21px;
        position: absolute;
        top: 5px;
        right: 5px;
        cursor: pointer;
        -webkit-box-shadow: -2px 3px 6px 0 rgba(0, 0, 0, 0.2);
        -moz-box-shadow: -2px 3px 6px 0 rgba(0, 0, 0, 0.2);
        box-shadow: -2px 3px 6px 0 rgba(0, 0, 0, 0.2);
        background-color: rgba(0, 0, 0, .05);
        z-index: 1;
      }
    }

    .vacancy-video {
      width: 100%;
      height: 204px;
      margin-bottom: 16px;
    }
  }
}

@media (min-width: 576px) {
  .vacancy-page .modal-dialog {
    max-width: 500px;
  }
}
