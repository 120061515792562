.rate-card-list {
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
    padding: 20px 0;
}

.rate-card {
    border: .5px solid #E2E2E2;
    text-align: center;
    width: 100%;
    max-width: 345px;
    margin: 0 15px;
    &:hover {
        -webkit-box-shadow: 0 4px 9px 0 rgba(0,0,0,.12);
        -moz-box-shadow: 0 4px 9px 0 rgba(0,0,0,.12);
        box-shadow: 0 4px 9px 0 rgba(0,0,0,.12);
        border-color: transparent;
        transition: box-shadow .3s ease,border .2s linear,opacity .3s ease;
    }
    &-small {
        max-width: 281px;
        .main-text {
            margin-bottom: 45px;
        }
        .rate-card-name {
            font-size: 25px;
        }
        .rate-card-header {
            margin-bottom: 40px;
        }
    }
    .main-text {
        font-size: 12px;
        line-height: 18px;
        color: #14669B;
    }
    &-header {
        padding: 20px 0 6px;
        background: url(/images/rate-card-bg.png) no-repeat;
        background-size: 100% 100%;
    }
    &-body {
        padding: 0 30px 30px;
        label.btn{
            display: inline;
        }
    }
    &-name {
        color: $textForDarkElements;
        font-size: 30px;
        font-weight: 500;
    }
    &-price {
        width: 127px;
        height: 127px;
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);
        background-color: $textForDarkElements;
        border: 1px solid #E2E2E2;
        border-radius: 50%;
        margin: auto;
        p {
            margin: 0;
            font-size: 40px;
            color: #039FCF;
        }
        span {
            font-weight: bold;
        }
    }
    .pro-advantages {
        padding: 0 0 0 15px;
        margin-bottom: 45px;
        li {
            text-align: left;
            list-style-type: disc;
            padding-left: 10px;
            color: #14669B;
        }
    }
    label {
        text-align: left;
        width: 100%;
    }
}

.active-tariff {
    -webkit-box-shadow: 7px 8px 5px 0px rgba(0,0,0,0.17);
    -moz-box-shadow: 7px 8px 5px 0px rgba(0,0,0,0.17);
    box-shadow: 7px 8px 5px 0px rgba(0,0,0,0.17);
    border-color: $primary;
    transition: box-shadow .3s ease,border .2s linear,opacity .3s ease;
    .btn-primary {
        background-color: #0074c5;
        border-color: #006db8;
        box-shadow: none;
    }
    &:hover {
        -webkit-box-shadow: 7px 8px 5px 0px rgba(0,0,0,0.17);
        -moz-box-shadow: 7px 8px 5px 0px rgba(0,0,0,0.17);
        box-shadow: 7px 8px 5px 0px rgba(0,0,0,0.17);
        border-color: $primary;
        transition: box-shadow .3s ease,border .2s linear,opacity .3s ease;
    }
}

@media only screen and (max-width: 1199px) {
    .rate-card {
        &-list {
            padding: 10px 0;
        }
        &-body {
            padding: 0 15px 20px;
        }
        &-price {
            width: 100px;
            height: 100px;
            p {
                font-size: 30px;
            }
        }
        &-name {
            font-size: 25px;
        }
        &-small {
            .rate-card-name {
                font-size: 20px;
            }
            .rate-card-header {
                margin-bottom: 25px;
            }
            .main-text {
                margin-bottom: 20px;
            }
        }
        .pro-advantages {
            padding: 0 0 0 15px;
            margin-bottom: 20px;
            li {
                padding-left: 0;
            }
        }
        .main-text {
            margin-bottom: 10px;
        }
        .form-control {
            padding: 0.375rem 0.75rem 0.375rem 0.3rem;
            font-size: 10px;
        }
    }
}