.company-manage {
  .sub-title {
    margin: 20px 0;
  }

  &-form {
    border-top: 0.5px solid #008BEB;
    padding-top: 20px;

    .company-description-block {
      width: 100%;
    }
  }

  &-block {
    position: relative;
    padding: 20px 37px;
    margin-bottom: 10px;
    background: #FAFAFA;
    border: 0.5px solid #EDEDED;

    &-title {
      margin-bottom: 20px;
      font-size: 18px;
      font-weight: 500;
    }

    label {
      line-height: 1.1;
      margin-bottom: 10px;
    }

    > textarea {
      resize: none;
      height: 85px;
      padding-top: 9px;
    }

    textarea.form-control {
      min-height: 106px;
      resize: none;
    }

    .form-control:focus {
      color: $textPrimary;
    }

    .choices {
      margin-bottom: 20px;
    }

    .info-icon {
      color: $primary;
      font-size: 16px;
      vertical-align: text-bottom;
    }

    .ql-container,
    .ql-toolbar {
      background: #fff;
    }

    .ql-toolbar.ql-snow,
    .ql-container.ql-snow {
      border: 1px solid #ddd;
    }

    .error-text {
      margin-bottom: 0;
    }
  }

  .block-basic-information {
    width: calc(100% - 160px);

    &-logo {
      @include flexbox();
      @include align-items(flex-start);
      @include justify-content(space-between);
    }
  }

  .block-logo {
    width: 140px;
    height: 140px;
    border: 1px solid #BDE4FF;
    overflow: hidden;

    img {
      max-height: 100%;
      width: auto;
      max-width: 100%;
      height: auto;
    }

    .fa-image {
      font-size: 48px;
      color: #BDE4FF;
    }
  }

  .company-manage-form {
    &-row {
      @include flexbox();
      @include align-items(flex-start);
      @include justify-content(space-between);
      margin-bottom: 20px;

      &__last {
        @include flex-direction(column);

        > div {
          width: 100%;
        }
      }

      &:nth-last-child(1) {
        margin-bottom: 0;
      }

      input.form-control {
        padding-left: 0.9rem;
      }

      select.form-control {
        background: url("/images/select-icon.svg") no-repeat 99% 50% #fff;
        background-size: 9px 11px;
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
        appearance: none;
      }

      .is-invalid .choices__inner {
        border-color: $danger;
      }
    }

    &-col {
      width: calc(50% - 10px);
    }
  }

  .is-invalid-block {
    label {
      color: $danger !important;
    }
  }

  .subtitle-block {
    line-height: 1.2;
    font-size: 14px;
    color: #062336;
    margin: 20px 0;
  }

  .help-text {
    font-size: 12px;
  }

  .no-tariff-modal {
    display: none;
  }

  .no-tariff {
    .company-manage-form-row,
    .subtitle-block,
    label {
      -webkit-filter: blur(2px);
      filter: blur(2px);
    }

    .no-tariff-modal {
      display: inline-block;
      background-color: #FFFFCE;
      border: 1px solid #FFFFCE;
      -webkit-box-shadow: 0 1px 10px 0 rgba(50, 50, 50, 0.4);
      -moz-box-shadow: 0 1px 10px 0 rgba(50, 50, 50, 0.4);
      box-shadow: 0 1px 10px 0 rgba(50, 50, 50, 0.4);
      position: absolute;
      top: 50%;
      left: 50%;
      @include translate(-50%, -50%);
      padding: 20px 50px;
      z-index: 1;
    }
  }

  .form-control:invalid,
  .form-control.is-invalid {
    background-image: none;
  }

  .btn-section {
    margin-top: 20px;
  }

  .company-description-block {
    @include align-items(flex-start);
    @include flex-direction(column);

    > div {
      width: 100%;
    }
  }
}


@media only screen and (max-width: 991px) {
  .company-manage {
    &-form {
      padding-top: 15px;
    }

    .sub-title {
      margin: 20px 0 15px;
    }

    &-block {
      padding: 15px 20px;

      &-title {
        margin-bottom: 15px;
        font-size: 16px;
      }
    }

    .block-logo {
      width: 80px;
      height: 80px;
      margin-bottom: 20px;

      .fa-image {
        font-size: 27px;
      }
    }

    .block-basic-information {
      width: 100%;

      &-logo {
        @include flex-direction(column);
      }
    }

    .company-manage-form-row {
      margin-bottom: 15px;
    }
  }

}

@media only screen and (max-width: 767px) {
  .company-manage {
    h2 {
      font-size: 18px;
    }

    .sub-title {
      margin: 20px 0 10px;
      font-size: 14px;
    }

    &-form {
      padding-top: 10px;
    }

    &-block {
      padding: 10px 10px 15px;
    }

    &-block-title {
      margin-bottom: 10px;
    }

    .company-manage-form {
      &-row {
        @include flex-direction(column);
        margin-bottom: 0;

        select.form-control {
          background: url("/images/select-icon.svg") no-repeat 97% 50% #fff;
        }
      }

      &-col {
        width: 100%;
        margin-bottom: 15px;
      }
    }

    .subtitle-block {
      margin: 15px 0;
    }

    .uploader {
      margin-bottom: 15px;
      margin-right: 20px;
    }

    .help-text {
      margin-top: -12px;
    }

    .btn-section {
      padding-top: 10px;
    }
  }
}