.cv-detail-page {
	.vacancy-page-header {
		@include flex-direction(column);
		padding-bottom: 20px;
		.cv-btns-actions {
			width: 100%;
			@include flexbox();
			@include align-items(flex-start);
			position: relative;
		}
		.btn-print {
			padding-left: 10px;
			padding-right: 10px;
			.fa-print {
				color: $primary;
				font-size: 18px;
			}
		}
		.cv-edit-btn {
			margin-left: 7px;
		}
	}
	.block-logo-btns {
		width: calc(100% - 430px);
		@include flexbox();
		@include flex-direction(row);
	}
	.offer-title {
		margin-bottom: 30px;
		width: 100%;
	}
	.cv-detail-left-col {
		width: 280px;
		padding-right: 15px;
		.bold-title {
			font-weight: 500;
			font-size: 16px;
			margin-bottom: 0;
		}
	}
	.cv-detail-right-col {
		width: calc(100% - 280px);
		.cv-detail-link {
			margin-bottom: 15px;
			line-height: 17px;
			&:nth-last-child(1){
				margin-bottom: 0!important;
			}
		}
		.ready-to-move {
			margin-bottom: 20px;
			line-height: 17px;
		}
		p{
			line-height: 20px;
			margin-bottom: 0;
		}
	}
	.photo-wrap {
		width: 120px;
		height: 120px;
		border: 1px solid #A0DDFD;
		img {
			max-height: 100%;
			width: auto;
			min-width: 100%;
			height: auto;
			position: relative;
			left: 50%;
			top: 50%;
			@include translate(-50%, -50%);
		}
		.no-img {
			width: 54px;
			height: auto;
			min-width: auto;
			min-height: auto;
		}
	}
	.author-name {
		font-size: 16px;
		margin-bottom: 18px;
	}
	.header-cv-info {
		@include flexbox();
		@include align-items(flex-start);
		margin-bottom: 3px;
		p {
			margin-bottom: 0;
		}
		p:nth-child(1) {
			width: 170px;
			color: $secondary;
			line-height: 25px;
		}
		p:nth-child(2) {
			width: calc(100% - 170px);
			line-height: 25px;
		}
	}
	.description-vacancy {
		padding-top: 18px;
	}
	.cv-content-description {
		@include flexbox();
		@include align-items(baseline);
		margin-bottom: 28px;
		&-gallery {
			margin-bottom: 15px;
		}
		&.work-experience {
			margin-bottom: 20px;
		}
	}
	.block-gallery .img-wrap {
		width: 80px;
		height: 80px;
		margin-right: 20px;
	}
	.fa-paperclip {
		font-size: 11px;
		a{
			font-size: 14px;
		}
	}
	.block-gallery {
		margin-bottom: 0;
	}
	.offer-content {
		margin-right: 0;
		width: 100%;
	}
	.cv-detail-page-btns {
		@include flexbox();
		@include align-items(center);
		@include justify-content(flex-end);
	}
	.reply-form .text-black-50 {
		color: $textPrimary !important;
		margin-bottom: 7px !important;
	}
	.vacancy-page-form {
		padding: 10px 20px 20px;
	}
	.vacancy-page-form .btn-form-group {
		@include justify-content(flex-start);
	}
}

.badge-outline-secondary {
	color: $secondary;
	border: 1px solid $secondary;
	font-size: 12px;
	font-weight: 400;
}

@media only screen and (max-width: 991px) {
	.cv-detail-page .vacancy-page-header {
		.cv-publish-btn,
		.cv-edit-btn {
			display: none;
		}
	}
	.cv-detail-page .cv-detail-left-col {
		width: 155px;
	}
	.cv-detail-page .cv-detail-right-col {
		width: calc(100% - 155px);
	}
}

@media only screen and (max-width: 767px) {
	.cv-detail-page {
		.offer-title {
			margin-bottom: 20px;
			padding-right: 20%;
		}
		.cv-detail-left-col {
			width: 100%;
			padding-right: 0;
		}
		.cv-detail-right-col {
			width: 100%;
			.cv-detail-link {
				margin-bottom: 20px;
			}
		}
		.block-logo-btns {
			@include flex-direction(column);
		}
		.header-cv-info p:nth-child(2),
		.header-cv-info p:nth-child(1) {
			line-height: 22px;
		}
		.btns-actions {
			position: absolute;
			right: 0;
			top: 0;
			>div >div {
				@include flexbox();
			}
		}
		.photo-wrap {
			margin-bottom: 15px;
		}
		.vacancy-page-header {
			border-bottom: none;
			.btn {
				width: 31px;
				height: 27px;
			}
		}
		.line-on-mob {
			position: relative;
			&:before {
				content: '';
				width: 100vw;
				height: 1px;
				background-color: #D6D6D6;
				position: absolute;
				bottom: 0;
				left: -15px;
			}
		}
		.line-top-on-mob {
			position: relative;
			&:after {
				content: '';
				width: 100vw;
				height: 1px;
				background-color: #D6D6D6;
				position: absolute;
				top: 0;
				left: -15px;
			}
		}
		.cv-content-description {
			@include flex-direction(column);
			margin-bottom: 30px;
			&.work-experience {
				margin-bottom: 15px;
				padding-top: 20px;
			}
		}
		.pb-mob {
			margin-bottom: 0;
			padding-bottom: 20px;
			.cv-detail-left-col {
				margin-bottom: 15px;
			}
		}
		.author-name {
			margin-bottom: 15px;
		}
		.attached-files,
		.education-block,
		.about-myself-block {
			padding-bottom: 20px;
			margin-bottom: 0;
		}
		.attached-files,
		.education-block {
			padding-top: 20px;
		}
		.block-gallery .img-wrap {
			max-height: 268px;
			width: auto;
			min-width: 100%;
			height: auto;
			img{
				left: 0;
				top: 0;
				@include translate(0,0);
			}
		}
		.download-file-block{
			padding-bottom: 20px;
			margin-bottom: 20px;
		}
		.cv-detail-page-btns {
			@include flex-wrap(wrap);
			margin-top: 30px;
			>div {
				margin-bottom: 30px;
				margin-left: 20px;
				&:nth-child(1){
					margin-left: 0;
				}
				.btn {
					margin: 0 auto;
					span{
						display: none;
					}
				}
			}
			.btn-info,
			.btn-outline-info,
			.btn-outline-danger,
			.btn-success{
				padding: 1px 1px;
				width: 54px;
				height: 35px;
				font-size: 0.875rem;
			}
		}
	}
}