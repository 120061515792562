.slider-similar-jobs {
  &-title {
    padding: 20px 15px;
    border-top: 1px solid #F0F0F0;
    width: 100%;
    color: $textPrimary;
    font-size: 16px;
    text-align: left;
    font-weight: 500;
    margin-bottom: -20px;
  }
  &-arrows {
    border: 1px solid #D5D5D5;
    border-radius: 3px;
    @include flexbox();
    width: 86px;
    position: absolute;
    right: 0;
    bottom: -56px;
  }
  .carousel-control-next {
    position: relative;
    width: 43px;
    height: 35px;
  }
  .carousel-control-prev {
    position: relative;
    width: 43px;
    height: 35px;
    border-right: 1px solid #D5D5D5;
    img {
      @include rotate(180);
    }
  }
  .slide-title {
    color: #2480B9;
    font-weight: 500;
    font-size: 15px;
  }
  .slide-company {
    color: #14669B;
    font-size: 15px;
    margin: 15px 0 20px;
  }
  .slide-deskription {
    color: $textPrimary;
    text-align: left;
    font-size: 14px;
    margin-bottom: 0;
    line-height: 1.3;
    span {
      font-weight: 500;
      display: block;
    }
  }
  .slide-wrap{
    border: 1px solid #F0F0F0;
    padding: 20px 15px;
  }
  .list-country-tags{
    padding: 0;
    @include flexbox();
    @include align-items(center);
    margin-bottom: 30px;
    li{
      @include flexbox();
      @include align-items(center);
      margin-right: 30px;
    }
    p{
      margin: 0;
    }
    i{
      margin-right: 15px;
      color: #14669B;
    }
  }
}

.similar-vacancy {
  margin-top: 20px;
  margin-right: 22.5px;
  border-radius: 5px;
  border: 1px solid #D6D6D6;
  width: calc(33.3% - 15px);
  padding: 10px 20px 16px 23px;
  position: relative;

  &-wrap {
    width: 100%;
    @include flexbox();
    @include align-items(flex-start);
    @include justify-content(flex-start);
    @include flex-wrap(wrap);
  }

  &:nth-child(3n + 3) {
    margin-right: 0;
  }

  &:before {
    content: '';
    width: 5px;
    height: 100%;
    background-color: #00B955;
    border-radius: 5px 0 0 5px;
    position: absolute;
    left: 0;
    top: 0;
  }

  .vacancy-title {
    font-weight: 400;
  }
}

@media only screen and (max-width: 1199px) {
  .slider-similar-jobs .list-country-tags {
    li {
      margin-right: 15px;
    }
    img{
      margin-right: 10px;
    }
  }
}

@media only screen and (max-width: 992px) {
  .slider-similar-jobs {
    &-title {
      padding: 19px 20px 23px;
    }
    .list-country-tags{
      @include flex-direction(column);
      @include align-items(flex-start);
    }

  }
  .similar-vacancy {
    margin-right: 28px;
    width: calc(50% - 14px);
    &:nth-child(3n + 3) {
      margin-right: 28px;
    }
    &:nth-child(2n + 2) {
      margin-right: 0;
    }
    .vacancy-title {
      font-size: 14px;
      margin-bottom: 3px;
    }
  }
}

.cv-slide-title-photo{
  @include flexbox();
  @include align-items(flex-start);
  @include justify-content(space-between);
  .slide-title{
    width: calc(100% - 70px);
  }
}

@media only screen and (max-width: 767px) {
  .similar-vacancy {
    width: 96%;
    margin: 20px auto 0;
  }
  .similar-vacancy:nth-child(3n + 3),
  .similar-vacancy:nth-child(2n + 2) {
    margin-right: auto;
  }
}