.hot-vacancies {
  &-list {
    @include flexbox();
    @include align-items(flex-start);
    @include justify-content(flex-start);
    @include flex-wrap(wrap);
    padding: 0;
    width: 100%;

    &__item {
      width: calc(33.3% - 13.4px);
      margin-right: 20px;
      margin-bottom: 9px;
      border-radius: 3px;
      border: 1px solid #E0E0E0;

      > a {
        @include flexbox();

        &:hover {
          text-decoration: none;
          box-shadow: 0 5px 11px rgba(0, 0, 0, .2);
        }
      }

      &:nth-child(3n + 3) {
        margin-right: 0;
      }
    }

    &__wrap {
      padding: 20px;
      @include flexbox();
      @include align-items(center);
      @include justify-content(flex-start);
    }
  }

  &-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: rgba(0, 138, 234, .6);
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
    margin-right: 15px;

    .fas {
      font-size: 22px;
      color: #fff;
    }
  }

  &-title {
    margin-bottom: 0;
    color: #008AEA;
    width: calc(100% - 65px);
  }
}

@media only screen and (max-width: 991px) {
  .hot-vacancies {
    &-list {
      &__wrap {
        padding: 15px;
      }
      &__item {
        width: calc(50% - 10px);
        margin-right: 10px;

        &:nth-child(3n + 3) {
          margin-right: 10px;
        }

        &:nth-child(2n + 2) {
          margin-right: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .hot-vacancies {
    &-list {
      padding: 0 15px;
      &__item {
        width: 100%;
        margin-right: 0;

        &:nth-child(3n + 3) {
          margin-right: 0;
        }
      }
    }
  }
}