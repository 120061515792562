.tariff-payment-table {
  margin-bottom: 20px;
  width: 100%;

  th {
    background-color: #2582BF;
    padding: 12px 20px;
    text-align: center;
    color: #fff;
    font-weight: 400;
    vertical-align: middle !important;
    &:nth-child(1) {
      width: 54.4%;
    }

    &:nth-child(2) {
      width: 11.8%;
    }

    &:nth-child(3) {
      width: 7.8%;
    }

    &:nth-child(4) {
      width: 7.8%;
    }

    &:nth-child(5) {
      width: 8.5%;
    }

    &:nth-child(6) {
      width: 9.7%;
    }
  }

  th,
  td {
    border: .5px solid #fff !important;
  }

  tbody td {
    background-color: #EAF3FA;
    padding: 22px 30px;
    text-align: center;

    &:nth-child(1) {
      text-align: left;
      font-weight: 500;
      width: 54.4%;
    }

    &:nth-child(2) {
      width: 11.8%;
    }

    &:nth-child(3) {
      width: 7.8%;
    }

    &:nth-child(4) {
      width: 7.8%;
    }

    &:nth-child(5) {
      width: 8.5%;
    }

    &:nth-child(6) {
      width: 9.7%;
    }
  }
}

.mob-tariff-payment-table {
  display: none;
  width: 100%;
  padding: 0;

  &__item {
    width: 100%;
    background-color: #EAF3FA;
    margin-bottom: 1px;
    padding: 15px 0;
    position: relative;

    &:before {
      content: '';
      height: 100%;
      width: 15px;
      position: absolute;
      left: -15px;
      top: 0;
      background-color: #EAF3FA;
    }

    &:after {
      content: '';
      height: 100%;
      width: 15px;
      position: absolute;
      right: -15px;
      top: 0;
      background-color: #EAF3FA;
    }
  }
}

.tariff-payment-table-row {
  @include flexbox();
  @include align-items(baseline);
  @include justify-content(space-between);
  margin-bottom: 10px;

  &__small {
    p {
      font-size: 12px;
    }
  }

  .price-label {
    font-size: 12px;
  }
}

.tariff-payment-details {
  margin-top: 12px;
  width: 100%;
  display: none;
}

.detail-btn-services-is-checked {
  .tariff-payment-details {
    display: inline-block;
  }
}

@media only screen and (max-width: 1200px) {
  .tariff-payment-table {
    margin-bottom: 20px;

    th {
      padding: 12px 20px;
      &:nth-child(1) {
        width: 36.1%;
      }

      &:nth-child(2) {
        width: 17.1%;
      }

      &:nth-child(3) {
        width: 10.9%;
      }

      &:nth-child(4) {
        width: 10.9%;
      }

      &:nth-child(5) {
        width: 11.5%;
      }

      &:nth-child(6) {
        width: 13.5%;
      }
    }

    tbody td {
      padding: 22px 30px;

      &:nth-child(1) {
        width: 36.1%;
      }

      &:nth-child(2) {
        width: 17.1%;
      }

      &:nth-child(3) {
        width: 10.9%;
      }

      &:nth-child(4) {
        width: 10.9%;
      }

      &:nth-child(5) {
        width: 11.5%;
      }

      &:nth-child(6) {
        width: 13.5%;
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .tariff-payment-table {
    th {
      padding: 15px 10px;
    }

    tbody td {
      padding: 15px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .tariff-payment-table {
    display: none;
  }
  .mob-tariff-payment-table {
    @include flexbox();
    @include align-items(flex-start);
    @include flex-direction(column);
  }
}
