.reg-employer {
  &-page {
    padding-top: 55px;

    &-content {
      @include flexbox();
      @include align-items(flex-start);
      @include justify-content(space-between);
      margin-bottom: 40px;

      &__left {
        @include flexbox();
        @include flex-direction(column);
        @include align-items(flex-start);
        width: 517px;
      }

      &__right {
        @include flexbox();
        @include align-items(flex-start);
        @include justify-content(flex-start);
        padding: 44px;
        background: #F8F5EE;
      }
    }

    .btn-outline-primary:hover {
      background-color: $hoverPrimary;
      border-color: $hoverPrimary;
      color: #fff;
    }

    .alert button.close {
      top: 31%;
    }

    .alert-success {
      border: none;
      background: #D4EDDA;
      color: $simpleSuccessAlertTextColor;
      margin-bottom: 30px;
      padding: 27px 103px;
      position: relative;
    }

    .alert-img {
      height: 63px;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }

  &-img {
    height: 217px;

    &__first {
      margin-right: 32px;
    }
  }

  &-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 83%;
    margin-bottom: 20px;

    &__second {
      margin-top: 44px;
    }
  }

  &-subtitle {
    font-size: 14px;
    line-height: 143%;
    margin-bottom: 20px;
  }

  &-links-list {
    @include flexbox();
    @include align-items(center);
    @include justify-content(flex-start);
    border-top: 0.5px solid #E0E0E0;
    border-bottom: 0.5px solid #E0E0E0;
    padding: 30px 0;
  }

  &-link {
    @include flexbox();
    @include align-items(center);
    @include justify-content(flex-start);
    width: 25%;
    padding-right: 20px;

    &__desc {
      @include flexbox();
      @include flex-direction(column);
      @include align-items(flex-start);
      margin-left: 14px;
    }

    &-name {
      font-size: 14px;
      line-height: 143%;
      font-weight: 500;
      margin-bottom: 5px;
    }

    &-route {
      font-size: 13px;
    }

    .material-icons {
      font-size: 30px;
    }

    .my-company {
      color: #A0B3A8;
    }

    .your-profile {
      color: #BB99B7;
    }

    .look {
      color: #C6A78F;
    }

    .sms {
      color: #ECC8C9;
    }
  }
}

@media only screen and (max-width: 991px) {
  .reg-employer {
    &-page {
      padding-top: 32px;

      .alert-success {
        padding: 16px 103px;
        margin-bottom: 20px;
      }

      &-content {
        margin-bottom: 20px;

        &__left {
          width: 370px;
        }

        &__right {
          @include align-items(flex-start);
          @include flex-direction(column);
          padding: 28px 42px;
        }
      }
    }

    &-img {
      height: 160px;

      &__first {
        margin-right: 0;
        margin-bottom: 24px;
      }
    }

    &-title {
      font-size: 18px;
      line-height: 111%;
      margin-bottom: 10px;

      &__second {
        margin-top: 70px;
      }
    }

    &-links-list {
      @include flex-wrap(wrap);
      padding: 20px 0 0;
    }

    &-link {
      width: 50%;
      padding-right: 20px;
      margin-bottom: 20px;
    }
  }

}

@media only screen and (max-width: 767px) {
  .reg-employer {
    &-page {
      &-content {
        @include flex-direction(column);
        &__left {
          width: 100%;
        }

        &__right {
          display: none;
        }
      }
      .alert button.close {
        top: 19%;
        right: .5rem;
      }
      .alert-success {
        @include flexbox();
        @include align-items(center);
        @include flex-direction(column);
        padding: 0 15px 13px;
      }

      .alert-img {
        height: 53px;
        position: relative;
      }
    }
    &-title {
      &__second {
        margin-top: 20px;
      }
    }
    &-subtitle {
      margin-bottom: 10px;
    }
    &-link {
      width: 100%;
      padding-right: 0;
    }
  }


}
