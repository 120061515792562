.home-section-title{
  font-size: 3.2vh;
  font-weight: 500;
  text-align: center;
  margin-bottom: 2.8vh;
}
@media screen and (orientation: landscape) and (min-width: 175vh) and (max-width: 991px) {
  .home-section-title {
    font-size: 5.2vh;
  }
}

@media screen and (orientation: landscape) and (max-width: 150vh) {
  .home-section-title {
    font-size: 5.2vh;
  }
}

@media only screen and (min-width: 768px) and (orientation: portrait) {
  .home-section-title{
    font-size: 2.6vw;
    &__big {
      font-size: 3.2vw;
    }
  }
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .home-section-title {
    font-size: 5vw;
    margin-bottom: 7.8vw;
  }
}