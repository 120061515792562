//Settings
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');
$font-family-sans-serif: 'Roboto', sans-serif;
//$font-family-sans-serif: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Fira Sans, Ubuntu, Oxygen, Oxygen Sans, Cantarell, Droid Sans, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Lucida Grande, Helvetica, Arial, sans-serif;
$body-color: rgb(6, 35, 54);
$font-size-base: .875rem;

$primary: #008BEB;
$hoverPrimary: #0074f0;
$disabledPrimary: #70A1D6;

$danger: #DC3545;
$hoverDanger: #C82333;
$disabledDanger: #E87C87;

$success: #00B955;
$hoverSuccess: #218838;
$disabledSuccess: #74C687;

$secondary: #6C757D;
$hoverSecondary: #545B62;
$disabledSecondary: #B4B4B4;

$UIGray: #737373;
$UILightGray: #e0e0e0;

$textPrimary: #062336;
$textForDarkElements: #fff;

$yellowColorButtonHeader: #ffdb49;
$simpleWarningAlert: #FFF3CD;
$simplePrimaryAlertBorderColor: #ffeeba;
$simpleWarningAlertTextColor: #856404;
$simplePrimaryAlert: #cce5ff;
$simplePrimaryAlertBorderColor: #b8daff;
$simpleWarningAlertTextColor: #b8daff;
$simpleSuccessAlertBorderColor: #c3e6cb;
$simpleSuccessAlertTextColor: #155724;
$simpleDangerAlertBorderColor: #f5c6cb;
$simpleDangerTextColor: #721c24;







$warning: rgb(255, 219, 73);
$info: rgb(20, 102, 155);
$light: rgb(240, 240, 240);

$green-primary: rgb(11, 144, 105);
$green-secondary: rgb(57, 216, 184);

$dark: rgba(94, 94, 94, 0.87);
$dark-medium: rgb(240, 240, 240);
$muted: rgb(122, 122, 140);

$navbar-dark-hover-color: $primary;
$navbar-dark-color: #e4e5e8;
$navbar-dark-active-color: #e4e5e8;

$card-spacer-x: 1.5rem !default;
$card-spacer-y: 1.5rem !default;
$card-border-color: $dark-medium;
$box-shadow-lg: 0 1rem 3.5rem rgba(150, 157, 186, 0.75);

$btn-padding-x: 0.72rem !default;

$h2-font-size: 1.6rem;


$table-cell-padding-sm: .4rem;

$breadcrumb-bg: transparent;
$breadcrumb-active-color: $primary;

$pagination-active-bg: $secondary;

$input-bg: #fff;
$input-border-color: #d1d1d1;
$input-font-size-lg: .9rem;
$input-focus-border-color: $secondary;


$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
) !default;

$grid-gutter-width: 30px !default;

// number of cards per line for each breakpoint
$cards-per-line: (
        xs: 1,
        sm: 2,
        md: 2,
        lg: 3,
        xl: 4
);

@each $name, $breakpoint in $grid-breakpoints {
  @media (min-width: $breakpoint) {
    .card-deck .card {
      flex: 0 0 calc(#{100%/map-get($cards-per-line, $name)} - #{$grid-gutter-width}) !important;
    }
  }
}
