.section-banner {
  background: url("/images/banner-bg.jpg") bottom center no-repeat;
  background-size: cover;
  padding: 20px 0 68px;
  position: relative;
}

.section-banner-img {
  display: none;
  height: 77px;
  position: absolute;
  right: 27px;
  bottom: 0;
}

.section-banner .container {
  @include align-items(center);
  @include flex-direction(column);
  @include flexbox();
}

.banner-title {
  font-size: 35px;
  font-weight: 500;
  color: $primary;
  margin-bottom: 15px;
}

.banner-subtitle {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.1;
  text-align: center;
  color: $secondary;
  max-width: 570px;
}

.choices__inner {
  padding: 2px 7.5px 0;
  min-height: 35px;
}

.img-responsive {
  max-width: 100%;
  height: auto;
}

.banner-yellow {
  margin-top: 35px;

  .container {
    > div {
      position: relative;
      background-color: #FFFFD4;
      padding: 22px 0 24px 28px;
    }
  }

  .img-desc {
    position: absolute;
    right: 0;
    top: 50%;
    @include translate(0, -50%);
    height: 185px;
    width: auto;
  }

  .img-desc-mob {
    display: none;
    width: auto;
    height: 159px;
  }
}

.banner-grey {
  margin-top: 35px;

  .container {
    > div {
      position: relative;
      background-color: #F3F4F4;
      padding: 22px 0 24px 28px;
    }
  }

  .img-desc {
    position: absolute;
    right: 0;
    top: 50%;
    @include translate(0, -50%);
    height: 190px;
    width: auto;
  }

  .img-desc-mob {
    display: none;
    width: auto;
  }
}

.banner-text {
  font-size: 16px;
  margin-bottom: 20px;
  color: #116CAC;
  font-weight: 500;

  span {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 600;
    color: #EE2181;
  }
}

@media only screen and (max-width: 1199px) {
  .banner-grey .img-desc {
    top: 61%;
    height: 143px;
  }
  .banner-yellow .img-desc {
    height: 148px;
  }
  .banner-text {
    font-size: 14px;

    span {
      font-size: 18px;
    }
  }
}

@media only screen and (max-width: 991px) {
  .banner-yellow .img-desc,
  .banner-grey .img-desc {
    display: none;
  }

  .banner-grey {
    margin-top: 43px;

    .container {
      background-color: #F3F4F4;
      text-align: right;

      > div {
        text-align: left;
        padding: 20px 18px 0;
        width: 100%;
      }
    }

    .img-desc-mob {
      display: inline-block;
      height: 100px;
      right: -18px;
      position: relative;
      margin-top: -77px;
    }
  }

  .banner-yellow {
    .container {
      background-color: #FFFFD4;
      text-align: right;

      > div {
        text-align: left;
        padding: 20px 0 0 18px;
      }
    }

    .banner-text {
      margin-bottom: 50px;
    }

    .img-desc-mob {
      display: inline-block;
      height: 159px;
      margin-top: -95px;
      position: relative;
      right: -15px;
    }
  }

  .banner-text {
    font-size: 16px;
    margin-bottom: 20px;

    span {
      font-size: 23px;
    }
  }
  .section-banner {
    background: url("/images/banner-bg-md.jpg") bottom center no-repeat;
    background-size: cover;
    padding: 22px 0 44px;
  }
  .banner-title {
    font-size: 24px;
    margin-bottom: 11px;
  }
  .banner-subtitle {
    font-size: 14px;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .section-banner {
    background: #F9FAFC;
    padding-top: 10px;
    padding-bottom: 36px;
  }
  .section-banner-img {
    display: inline-block;
  }
  .banner-title {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .banner-subtitle {
    font-size: 15px;
    margin-bottom: 14px;
  }
  .banner-grey {
    margin-top: 22px;
  }

  .banner-text {
    width: 92%;

    &-mob {
      display: none;
    }
  }
  .banner-grey .img-desc-mob {
    height: 145px;
    right: -9px;
    margin-top: -101px;
  }
  .banner-yellow {
    margin-top: 22px;

    .img-desc-mob {
      right: -10px;
      margin-top: -86px;
    }
  }
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .banner-grey {
    padding: 0 10px;

    .img-desc-mob {
      right: 10px;
      position: relative;
      margin-top: -26px;
      width: calc(100% + 20px);
      height: auto;
    }
  }

  .banner-yellow {
    padding: 0 10px;

    .img-desc-mob {
      right: 10px;
      position: relative;
      margin-top: -62px;
      width: calc(100% + 20px);
      height: auto;
    }
  }
}
