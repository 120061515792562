.faq {
  &-items {
    padding: 0 20px;
    min-height: 342px;
    &-link {
      border-top: #F0F0F0 2px solid;
      display: block;
      position: relative;
      &:last-child {
        padding-bottom: 0;
      }
      &:hover, &:focus {
        text-decoration: none;
        color: $primary;
      }
      &-arrow {
        background-image: url('/front/images/icon-arrow.png');
        width: 12px;
        height: 7px;
        background-size: 100%;
        position: absolute;
        right: 10px;
        top: 20px;
        transition: all .5s ease;
      }
      &[aria-expanded="true"] {
        .faq-items-link-arrow {
          transform: rotate(180deg);
        }
      }
      &-num {
        font-size: 20px;
        font-weight: 600;
        color: #96CBF2;
        padding-right: 10px;
      }
    }
    &-collapse {
      padding: 10px 32px 20px 32px;
    }
  }
  &-menu {
    margin: -20px;
    &-link {
      display: block;
      border-top: #F0F0F0 2px solid;
      padding: 20px 34px;
      font-size: 16px;
      display: block;
      width: 100%;
      &:hover, &:focus {
        text-decoration: none;
        color: $primary;
      }
      &-active {
        color: $primary;
        border-left: #96CBF2 8px solid;
        cursor: default;
        padding-left: 26px;
        &:hover {
          color: $primary;
        }
      }
      &:first-child {
        border-top: none;
      }
    }
  }
}

.faq-page {
  padding: 95px 0 20vh;
  &-title {
    font-size: 22px;
    color: #062336;
    text-align: center;
    margin-bottom: 30px;
  }
}

.tabs-faq-applicant-company {
  border: none;
  margin-bottom: 30px;
  text-align: center;
  @include justify-content(center);
  .nav-link {
    padding: 0.5rem 4rem;
    border: 1px solid #0570B5;
    color: #0570B5;
    border-radius: 3px;
    margin: 0 5px;
  }
  .nav-link.active {
    border: 1px solid #0570B5;
    background-color: #0077C3;
    color: #fff;
  }
}

.tab-content .tabs-question-answers-categories {
  @include flexbox();
  @include align-items(flex-start);
  @include justify-content(space-between);
}

.tabs-question-categories {
  width: 100%;
  max-width: 380px;
  @include flex-direction(column);
  border: 1px solid #F0F0F0;
  .nav-link {
    @include flexbox();
    @include align-items(center);
    color: #14669B;
    padding: 0 5px 0 0;
    border-bottom: 1px solid #F0F0F0;
    border-radius: 0;
    &.active {
      border-bottom: 1px solid #F0F0F0;
      border-top: 1px solid #F0F0F0;
      border-left: none;
      .bor-l {
        visibility: visible;
      }
    }
  }
  .nav-item:nth-last-child(1) .nav-link {
    border-bottom: none;
  }
  .bor-l {
    display: inline-block;
    height: 65px;
    width: 6px;
    background-color: #96CBF2;
    margin-right: 29px;
    visibility: hidden;
  }
}

.tabs-answers-categories {
  width: calc(100% - 405px);
  border: 1px solid #F0F0F0;
  padding: 0 20px;
  .tab-title {
    font-weight: 600;
    padding: 22px 0;
    margin: 0;
  }
}

.list-question-answers {
  padding: 0;
  list-style-type: none;
  margin: 0;
  .item-question-title {
    border-top: 1px solid #F0F0F0;
    cursor: pointer;
    padding: 17px 10px;
    @include flexbox();
    @include align-items(center);
    @include justify-content(space-between);
    p {
      margin: 0 5px 0 0;
      color: #14669B;
      font-weight: 600;
      span {
        font-size: 20px;
        font-weight: 600;
        color: #96CBF2;
        margin-right: 20px;
      }
    }
  }
  .fa-angle-left {
    color: #14669B;
    font-size: 15px;
    @include transform(rotate(-90deg));
  }
  .active {
    .fa-angle-left {
      @include transform(rotate(90deg));
    }
    > p {
      display: inline-block;
    }
  }
  li {
    > p {
      margin: 0;
      padding: 25px 10px 25px 47px;
      display: none;
    }
  }
}

@media only screen and (max-width: 991px) {
  .tabs-question-categories {
    max-width: 290px;
  }
  .tabs-answers-categories {
    width: calc(100% - 300px);
    padding: 0 10px;
  }
  .list-question-answers {
    li > p {
      padding: 10px 10px 5px 37px;
    }
    .item-question-title {
      padding: 10px;
      @include align-items(baseline);
      p span {
        margin-right: 10px;
        font-size: 15px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .tabs-faq-applicant-company .nav-link {
    padding: 0.5rem 3rem;
  }
  .tab-content .tabs-question-answers-categories {
    @include flex-direction(column-reverse);
    @include align-items(center);
  }
  .tabs-answers-categories,
  .tabs-question-categories {
    width: 100%;
    max-width: 100%;
  }
  .list-question-answers {
    .item-question-title {
      padding: 10px 0;
    }
    li > p {
      padding-left: 0;
    }
  }
  .faq-page-title {
    font-size: 20px;
  }
  .tabs-question-categories {
    margin-bottom: 15px;
    .bor-l {
      height: 45px;
    }
  }
  .tabs-answers-categories .tab-title {
    padding: 12px 0;
  }
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .tabs-faq-applicant-company {
    @include flex-direction(column-reverse);
    margin-bottom: 10px;
    .nav-item {
      width: 100%;
      margin-bottom: 10px;
    }
  }
}