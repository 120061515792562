.breadcrumb {
  padding: 1.5rem 0 0.75rem 0;
  margin-bottom: 10px;

  .fa-home {
    color: $primary;
  }
}

.breadcrumb-item.active,
.breadcrumb-item,
.breadcrumb-item .fas {
  color: $primary;
}

@media only screen and (max-width: 768px) {
  .breadcrumb {
    padding: 17px 0 20px;
    margin-bottom: 0;
    font-size: 12px;
  }
}