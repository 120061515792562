.no-letters {
  @include flexbox();
  @include align-items(flex-end);
  @include justify-content(center);
  margin-top: 20px;

  &-content {
    @include flexbox();
    @include align-items(flex-start);
    @include flex-direction(column);
    margin-bottom: 18px;
    margin-right: 23px;
    width: 301px;

    &__p {
      margin-bottom: 0;
    }

    &__a {
      line-height: 1.2;
      margin-top: 15px;

      &:hover {
        text-decoration: none;
        color: $hoverPrimary;
      }
    }
  }

  &__img {
    height: 194px;
  }
}

.newsletter-settings {
  &-block {
    @include flexbox();
    @include align-items(flex-start);
    @include justify-content(space-between);
  }

  &-control {
    width: 675px;
    min-height: 190px;
    padding: 34px 30px;
    background-color: #FBFAFA;

    &__row {
      @include flexbox();
      @include align-items(center);
      @include justify-content(flex-start);
      margin-bottom: 13px;

      &:nth-last-child(1) {
        margin-bottom: 0;
      }

      > div {
        @include flexbox();
        @include align-items(center);
        @include justify-content(flex-start);
        margin-left: 20px;

        p {
          line-height: 1;
          margin-bottom: 0;

          &:nth-child(1) {
            width: 257px;
            padding-right: 20px;
          }

          &:nth-child(2) {
            font-size: 12px;
            width: 100px;
          }
        }
      }
    }
  }

  &-info {
    width: 475px;
    height: 190px;
    padding: 19px 0 25px 27px;
    background-color: #FAFAFA;
    border-top: 4px solid #FFEEB3;
    position: relative;
    text-align: left;

    &__text {
      color: $secondary;
      width: 100%;
      height: 100%;
      overflow-y: auto;
      padding-right: 144px;

      .big-text {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 12px;
      }

      .small-text {
        font-size: 12px;
        margin-bottom: 12px;

        &:nth-last-child(1) {
          margin-bottom: 0;
        }

        span {
          font-weight: 500;
        }
      }
    }

    &__img {
      height: 119px;
      position: absolute;
      top: 34px;
      right: 27px;
    }
  }
}

.subscriptions-list {
  &__title {
    font-weight: 500;
    font-size: 16px;
  }

  .pagination {
    margin-bottom: 40px;
  }
}

.subscription-block {
  @include flexbox();
  @include align-items(center);
  @include justify-content(flex-start);
  border: 1px solid #E0E0E0;
  padding: 22px 10px 22px 20px;
  margin-bottom: 10px;
  position: relative;

  .custom-checkbox__sm {
    margin-right: 25px;
  }

  .btn-vacancy-toggle {
    position: absolute;
    right: 10px;
    top: 50%;
    @include translate(0, -50%);

    &:hover,
    &:focus {
      background: #fff;
    }
  }
}

.subscription-content {
  @include flexbox();
  @include align-items(flex-start);
  @include flex-direction(column);
  max-width: 60%;

  p {
    margin-bottom: 0;

    &:nth-last-child(1) {
      margin-bottom: 0;
    }

    .divider {
      position: relative;
      height: 100%;
      margin: 0 9px;

      &:before {
        content: '';
        position: absolute;
        left: -3px;
        top: 50%;
        @include translate(0, -50%);
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: $textPrimary;
      }

    }
  }
}

@media only screen and (max-width: 991px) {
  .block-newsletterSetting h2 {
    font-size: 16px;
  }
  .no-letters {
    &__img {
      height: 97px;
    }

    &-content {
      margin-bottom: 0;

      &__p {
        margin-bottom: 5px;
      }

      &__a {
        margin-top: 10px;
      }
    }
  }

  .newsletter-settings {
    &-control {
      width: 370px;
      min-height: 230px;
      padding: 25px 18px;

      &__row {
        margin-bottom: 20px;

        > div {
          p {
            &:nth-child(1) {
              width: 171px;
            }

            &:nth-child(2) {
              width: auto;
            }
          }
        }
      }
    }

    &-info {
      width: 310px;
      height: 230px;
      padding: 11px 0 20px 15px;

      &__text {
        padding-right: 20px;
      }

      &__img {
        display: none;
      }
    }
  }

  .subscription-block {
    padding: 22px 10px 22px 13px;

    .custom-checkbox__sm {
      margin-right: 13px;
    }
  }

  .subscription-content {
    max-width: 81%;
  }
}

@media only screen and (max-width: 767px) {
  .block-newsletterSetting {
    .nav-tabs-castom {
      &-services {
        padding-left: 0;
      }
    }
  }
  .no-letters {
    @include flexbox();
    @include align-items(center);
    @include flex-direction(column);
    margin-top: 0;

    &__img {
      height: 95px;
    }

    &-content {
      width: 100%;
      margin-bottom: 30px;
      margin-right: 0;

      &__p {
        margin-bottom: 0;
      }
    }
  }

  .newsletter-settings {
    &-block {
      @include flex-direction(column);
      @include align-items(center);
    }

    &-control {
      width: 100%;
      min-height: auto;
      padding: 18px 15px;
      margin-bottom: 10px;

      &__row {
        > div {
          @include flex-direction(column);
          @include align-items(flex-start);
          margin-left: 20px;

          p {
            line-height: 1.2;

            &:nth-child(1) {
              width: 100%;
              margin-bottom: 5px;
              padding-right: 0;
            }

            &:nth-child(2) {
              width: 100%;
            }
          }
        }
      }
    }

    &-info {
      width: 100%;
      height: 177px;
      padding: 19px 0 15px 15px;

      &__text {
        padding-right: 18px;
      }
    }
  }

  .subscription-block {
    padding: 15px 13px;
    border-left: none;
    border-right: none;
    width: 100vw;
    margin-left: -15px;
  }

  .subscription-content {
    max-width: 70%;
  }
}
