.card-block {
	padding: 20px;
	@include border-top-radius(4px);
	@include border-bottom-radius(4px);
	border: #e8e8e8 1px solid;


	li.disabled {
		color: #7a7a7a;
	}

	&.white-bg {
		background-color: #fff;
	}

	&.grey-bg {
		background-color: #f9f9f9;
	}

	.flag-icon {
		margin: -1px 0 0 2px;
	}

	.photo {
		margin-bottom: 20px;

		span {
			float: left;
			margin-right: 20px;
		}

		img {
			width: 200px;
			height: auto;
			border: 1px solid #ddd;
			padding: 2px;
			display: inline;
		}
	}

	a {
		word-wrap: break-word;
	}

	.alert-warning {
		border-radius: 0;
		background-color: #F7F7F7;
		border-color: #DCDCDC;
		color: #000000;
		margin-bottom: 27px;
	}

	#preview {
		left: 20px;
		right: 20px;
	}

	#addOfferForm {
		left: 20px;
		right: 20px;

		.small {
			font-size: 11px;
		}
	}
	&._tabs {
		border-top: 0;
		border-top-right-radius: 0;
		border-top-left-radius: 0;
	}
}

.information-block {
	padding: 10px;
	font-weight: 600;
	@include border-top-radius(4px);
	@include border-bottom-radius(4px);
	display: inline;

	&.warning {
		background-color: #ffd556;
	}

	&.phone {
		display: none;
		margin-bottom: 0;
		text-align: center;
		vertical-align: middle;
		border: 1px solid transparent;
		padding: 6px 12px;
		font-size: 15px;
		line-height: 17px;
		border-radius: 4px;
	}
}

.danger-block {
  	display: none;
	color: $danger;
	border: $danger 1px solid;
	background-color: $danger;
	padding: 5px 10px;
}
