.company {
  padding: 40px;

  &-address {
    line-height: 20px;
  }

  .offers-list-item .offers-list-name {
    font-weight: 400;
  }

  &-list {
    &-item {
      padding: 16px 26px;
      height: 82px;
      background-color: #FAFAFA;
      border: #FAFAFA 1px solid;

      & > div {
        height: 100%;
      }

      &-center {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
      }

      &-icon {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background-color: #F0F0F0;
        border: #D5D5D5 1px solid;
        background-image: url('/images/icon-house.svg');
        background-position: center;
        background-size: 60%;
        background-repeat: no-repeat;

        &.cover {
          background-size: cover;
        }
      }

      &-head {
        color: $primary;
        font-weight: 600;
      }

      &-text {
        color: #5E5E5E;
      }

      &-approved {
        cursor: pointer;
        position: relative;
        background-image: url('/images/icon-check-circle.svg');
        background-position: center;
        width: 12px;
        height: 12px;
        display: inline-block;
        background-size: 100%;
        margin-left: 15px;

        &._warning {
          background-image: url('/images/icon-warning.svg');
          height: 11px;
        }

        &-text {
          color: $textForDarkElements;
          position: absolute;
          top: 16px;
          left: 20px;
          font-size: 10px;
          display: none;
          background-color: #616161;
          opacity: .9;
          padding: 5px 6px;
          font-style: normal;
          font-weight: 400;
          border-radius: 3px;
        }

        &:hover {
          .company-list-item-approved-text {
            display: block;
          }
        }
      }

      &-rating {
        text-align: right;
      }

      &-stars {
        color: #2581bc;
      }
    }
  }

  &-rating {
    color: $textForDarkElements;
    background-color: #2581bc;
    padding: 3px;
    font-size: 9px;
    vertical-align: middle;

    &.gray {
      background-color: #D6D6D6;
    }
  }

  &-rating-active {
    color: $textForDarkElements;
    background-color: #2581bc;
    padding: 3px;
    font-size: 9px;
    vertical-align: middle;
  }

  &-input {
    &-search {
      .input-group-btn {
        .btn {
          padding: 7px 10px 6px;
          background: $textForDarkElements;
          border: 1px solid #ccc;
          border-left: none;

          i {
            padding: 0;
            background: $textForDarkElements;
            color: #707070;
            font-size: 18px;
          }
        }
      }
    }
  }

  &-main {
    &-icon {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: #F0F0F0;
      border: #F0F0F0 1px solid;
      margin-right: 10px;
      @include flexbox();
      @include justify-content(center);
      @include align-items(center);
      .fa-user {
        color: #000;
        font-size: 10px;
      }
      &.cover {
        background-size: cover;
      }
    }

    &-approved {
      position: relative;
      background-image: url('/images/icon-check-circle.svg');
      background-position: center;
      width: 18px;
      height: 18px;
      display: inline-block;
      background-size: 100%;
      vertical-align: middle;
    }

    &-stars {
      cursor: default;
      text-align: left;
      float: left;

      &-item {
        padding-left: 3px;

        .company-rating {
          font-size: 12px;
          background-color: #D6D6D6;
        }

        .company-rating-active {
          font-size: 12px;
          background-color: #2581bc;
        }
      }

      //& > span:hover, & > span:hover ~ span {
      //	.company-rating {
      //		background-color: #2581bc;
      //	}
      //}
      //& > label:hover, & > label:hover ~ label {
      //	.company-rating {
      //		background-color: #2581bc;
      //	}
      //}
      > fieldset {
        border: none;
        display: inline-block;
        direction: rtl;

        &:not(:checked) {
          > input {
            position: absolute;
            top: 0px;
            clip: rect(0, 0, 0, 0);
          }

          > label {
            float: right;
            width: 24px;
            cursor: pointer;

            &:hover i,
            &:hover ~ label i {
              background-color: #2581bc;
            }
          }
        }

        > input:checked {
          & ~ label i {
            background-color: #2581bc;
          }
        }

        > label:active {
          position: relative;
          top: 2px;
        }
      }
    }

    &-rates {
      font-size: 12px;
      margin-top: 2px;
      margin-left: 8px;
      float: left;
    }

    &-add {
      border: #337aa8 1px solid;
      padding: 5px 20px;
      border-radius: 2px;
      float: right;

      span {
        vertical-align: middle;
        font-size: 14px;
      }

      &:hover {
        text-decoration: none;
      }
    }

    &-tabs {
      .nav-tabs.nav-justified li {
        border-top: none;
        //border-bottom: #e8e8e8 2px solid;
        &:first-child {
          border-left: none;
        }

        &:last-child {
          border-right: none;
        }

        &.active {
          border-top: none;
          border-bottom: #2581BC 4px solid;
        }

        a {
          padding: 10px;
        }
      }
    }
  }

  &-reviews {
    &-spanno {
      background-color: $primary;
      color: $textForDarkElements;
      border-radius: 3px;
      padding: 3px 5px;
    }

    &-spanyes {
      background-color: $danger;
      color: $textForDarkElements;
      border-radius: 3px;
      padding: 3px 5px;
    }

    &-item {
      &-header {
        background-color: #EBF6FE;
        padding: 12px;

        &:hover {
          cursor: pointer;
        }

        .company-list-item-stars {
          float: right;
          line-height: 17px;
          font-weight: 800;
        }

        .company-rating {
          font-size: 7px;
          vertical-align: top;
          margin-top: 2px;
          margin-right: 1.5px;
        }

        .company-rating-active {
          font-size: 7px;
          vertical-align: top;
          margin-top: 2px;
          margin-right: 1.5px;
        }

        &-time {
          margin-left: 17px;
        }
      }

      &-stars {
        float: right;
      }

      &._open {
        .company-reviews-item {
          &-header {
            background-color: #B8DDF8;
            border: #92C0FF 1px solid;
          }

          &-content {
            &-rating {
              //background-color: #EBF6FE;
              //padding: 15px;
            }
          }
        }
      }
    }

    &-likes {
      color: $success;

      &.disable {
        color: $btn-disabled-opacity
      }
    }

    &-dislikes {
      color: $danger;

      &.disable {
        color: $btn-disabled-opacity
      }
    }

    &-plus {
      border-left: $success 3px solid;
      padding-left: 13px;
    }

    &-minus {
      border-left: $danger 3px solid;
      padding-left: 13px;
    }

    &-likes, &-dislikes {
      margin: 0 10px;
      cursor: pointer;

      i {
        font-size: 17px;
      }
    }

    /*&-leavecomment {
        background-image: url('/images/icon-arrow.svg');
        background-size: 16px;
        background-position: 16px center;
        padding-left: 46px;
        background-repeat: no-repeat;
    }*/
    /*&-report {
        background-image: url('/images/icon-comments.svg');
        padding: 2px 11px;
        margin: 0 10px;
        position: relative;
        &-text {
            display: none;
        }
        &:hover {
            .company-reviews-report-text {
                display: block;
                position: absolute;
                padding: 4px 8px;
                background-color: #616161;
                color: #ffffff;
                font-size: 10px;
                top: -26px;
                left: -56px;
                border-radius: 3px;
            }

        }
    }*/
    .company-main-icon {
      width: 25px;
      height: 25px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 19px;
    }

    &-comment {
      &-employer {
        .company-reviews-item-content {
          padding: 0 16px 0 60px;
        }

        &-header {
          padding: 8px 16px;
          line-height: 26px;
        }
      }

      &-user {
        border-top: #D5D5D5 1px solid;

        .company-reviews-item-content {
          padding: 0 0 0 60px;
        }

        &-header {
          padding: 26px 16px 0px 60px;
        }
      }
    }

    &-media {
      &-item {
        margin-bottom: 5px;
      }
    }

    &-yourcomment {
      background-color: #F0F0F0;
      padding: 14px 28px 8px;
      border: #D5D5D5 1px solid;

      &-close {
        background-image: url('/images/icon-close-dark.svg');
        width: 18px;
        height: 18px;
        float: right;
      }

      &-line {
        width: 100%;
        background-image: url('/images/comment-line.svg');
        padding-bottom: 3%;
        background-repeat: no-repeat;
        background-position: center;
      }

      &-send {
        font-size: 12px;
        padding: 4px 10px;
        min-width: 76px;
        border-radius: 3px;
      }
    }
  }

  &-addreview {
    padding: 20px;

    label {
      font-weight: 400;
      margin-bottom: 1px;
    }

    .form-group {
      margin-bottom: 12px;
    }

    .form-control {
      //height: 36px;
    }

    &-info, &-positive, &-negative {
      padding: 4px 16px;
    }

    &-info {
      background-color: #EBF6FE;
    }

    &-positive {
      background-color: #EBFEEE;
    }

    &-negative {
      background-color: #FEECEB;
    }

    &-spanyes {
      background-color: #0CAA41;
      color: $textForDarkElements;
      padding: 3px 7px;
      border-radius: 3px;
      min-width: 46px;
      display: inline-block;
      text-align: center;

      &:hover {
        color: $textForDarkElements;
        text-decoration: none;
      }
    }

    &-spanno {
      color: $textForDarkElements;
      background-color: #D80614;
      padding: 3px 9px;
      border-radius: 3px;
      min-width: 46px;
      display: inline-block;
      text-align: center;

      &:hover {
        color: $textForDarkElements;
        text-decoration: none;
      }
    }

    /*		&-buttonyes {
                color: #36C004;
                border-radius: 3px;
                padding: 3px 8px;
                display: inline-block;
                text-align: center;
                border: 1px solid #36C004;

                &-selected {
                    background-color: #F0F0F0;
                    color: #36C004;
                    border-radius: 3px;
                    padding: 3px 8px;
                    display: inline-block;
                    text-align: center;
                }
            }
            &-buttonno {
                color: #FE5E00;
                border-radius: 3px;
                padding: 3px 8px;
                display: inline-block;
                text-align: center;
                border: 1px solid #FE5E00;

                &-selected {
                    background-color: #F0F0F0;
                    color: #FE5E00;
                    border-radius: 3px;
                    padding: 3px 8px;
                    display: inline-block;
                    text-align: center;
                }
            }*/
    /*		&-recommend {
                fieldset {
                    display: inline-block;
                    position: relative;
                    [type="radio"]:checked,
                    [type="radio"]:not(:checked) {
                        position: absolute;
                        top: 0px;
                        clip: rect(0,0,0,0);
                    }

                    [type="radio"]:checked + label,
                    [type="radio"]:not(:checked) + label {
                        position: relative;
                        cursor: pointer;
                        line-height: 20px;
                        display: inline-block;
                    }
                    [type="radio"]:checked + label.company-addreview-buttonyes {
                        color: #ffffff;
                        background-color: #36C004;
                        margin-right: 10px;
                    }
                    [type="radio"]:checked + label.company-addreview-buttonno {
                        color: #ffffff;
                        background-color: #FE5E00;
                        margin-left: 10px;
                    }
                }
            }*/
    &-addphoto {
      display: inline-block;
      background-image: url('/images/icon-photo.svg');
      padding: 9px 11px;
      vertical-align: middle;
    }

    &-addvideo {
      display: inline-block;
      background-image: url('/images/icon-video.svg');
      padding: 13px;
      vertical-align: middle;
    }

    &-linkvideo {
      display: inline-block;
      width: 160px;
      vertical-align: middle;

      .form-control {
        font-size: 10px;
        padding: 0 12px;
        height: 24px;
      }

      .input-group-append {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin-left: -1px;

        .input-group-text {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          margin-bottom: 0;
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.5;
          color: #495057;
          text-align: center;
          white-space: nowrap;
          background-color: #e9ecef;
          border: 1px solid #ced4da;
          border-radius: 0 2px 2px 0;
          border-left: none;
          background-image: url('/images/icon-close-dark.svg');
          height: 24px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: 65%;
          padding: 0 12px;
          cursor: pointer;
        }
      }
    }

    &-files {
      &-item {
        background-image: url('/images/icon-clip.svg');
        background-color: #F0F0F0;
        border: #D5D5D5 1px solid;
        display: inline-block;
        padding: 3px 6px 3px 21px;
        font-size: 10px;
        background-repeat: no-repeat;
        background-position: 5px center;
        background-size: 11px;
        border-radius: 12px;
        margin-right: 2px;
        cursor: default;
        margin-bottom: 5px;

        &-close {
          width: 14px;
          height: 14px;
          background-image: url('/images/icon-close-dark.svg');
          display: inline-block;
          vertical-align: middle;
          margin-left: 2px;
        }
      }
    }

    .btn-grey {
      padding: 5px 12px;
    }
  }

  .modal-dialog {
    width: 430px;
  }

  &-userlist {
    &-accept, &-decline {
      color: $textForDarkElements;
      width: 25px;
      height: 25px;
      line-height: 25px;
      border-radius: 3px;
      font-size: 18px;
      display: inline-block;
      text-align: center;

      &:hover, &:focus {
        color: $textForDarkElements;
      }
    }

    &-accept {
      background-color: #24A804;
    }

    &-accepted {
      color: #24A804;
    }

    &-decline {
      background-color: #D80614;
    }

    &-declined {
      color: #D80614;
    }

    &-item {
      background-color: #FBFAFA;
      border: #F0F0F0 1px solid;
      padding: 8px 16px;

      &-open {
        padding: 8px 16px;
        border: #F0F0F0 1px solid;
        position: relative;
      }

      .dropdown {
        line-height: 26px;
      }
    }

    &-plane {
      width: 18px;
      vertical-align: middle;
      margin-left: 5px;
    }

    &._invited {
      .company-userlist-item {
        line-height: 38px;
      }

      .company-hint-text {
        line-height: 29px;
        white-space: nowrap;
        width: initial;
        padding: 0px 10px;
        left: 45px;
        bottom: 5px;
      }
    }

    &-dropdown {
      left: inherit;
      right: 0;
      padding: 0;
      margin: 0;
      box-shadow: none;
      -webkit-box-shadow: none;
      border-radius: 0;
      border-color: #F0F0F0;

      &-toggle {
        min-width: 160px;
        display: inline-block;
        text-align: center;
        font-size: 12px;
        line-height: 26px;

        i {
          vertical-align: text-top;
        }

        a {
          color: $black;
        }
      }

      a {
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        padding: 0 10px;
        line-height: 34px;
        display: block;
        color: $black;
        font-size: 12px;

        &:last-child {
          border-bottom: none;
        }

        &:hover {
          text-decoration: none;
          background-color: #EEF3F6;
        }
      }
    }
  }

  &-requests {
    background-color: #4291c5;
    color: $textForDarkElements;
    font-size: 10px;
    padding: 2px 8px;
    border-radius: 8px;
    margin-left: 8px;
  }

  &-filtered {
    background-color: #F0F0F0;
    color: #6F6F6F;
    font-size: 10px;
    padding: 2px 8px;
    border-radius: 8px;
    margin-left: 8px;
  }

  &-hint {
    cursor: pointer;
    position: relative;
    color: $primary;

    &-text {
      display: none;
      position: absolute;
      bottom: 20px;
      left: 20px;
      font-size: 10px;
      color: $textForDarkElements;
      background: url('/images/bg-black.png') repeat;
      padding: 4px 10px;
      width: 195px;
      border-radius: 3px;
      z-index: 1;
    }

    &:hover {
      .company-hint-text {
        display: block;
      }
    }
  }

  &-logo {
    width: 88px;
    height: 88px;
    border-radius: 50%;
    border: $primary 1px solid;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $primary;
    cursor: pointer;
    overflow: hidden;
  }

  &-sidemenu {
    position: fixed;
    margin-top: 10px;

    &-item {
      list-style: none;
      margin-bottom: 10px;

      &._active {
        position: relative;

        a {
          color: $primary;
          cursor: default;

          &:hover {
            text-decoration: none;
          }
        }

        &:before {
          content: '';
          display: block;
          position: absolute;
          width: 3px;
          height: 19px;
          background-color: $primary;
          left: -32px;
        }
      }
    }
  }

  &-suggest {
    background-color: #E6F3FF;
    display: flex;
    padding: 10px;
    border: #F0F0F0 1px solid;
    position: relative;

    &._request {
      background-color: #FBFAFA;
    }

    &-request {
      width: 22px;
      height: 22px;
      background-color: #FDA75A;
      color: $textForDarkElements;
      position: absolute;
      right: -1px;
      top: -1px;
      padding: 3px;

      &-hint {
        display: none;
        background-image: url('/images/bg-black.png');
        padding: 5px;
        font-size: 10px;
        color: $textForDarkElements;
        position: absolute;
        top: -10px;
        left: 50px;
        white-space: nowrap;
        border-radius: 2px;
      }

      &:hover {
        .company-suggest-request-hint {
          display: block;
        }
      }
    }

    &-accept {
      width: 22px;
      height: 22px;
      background-color: #008C3D;
      color: $textForDarkElements;
      position: absolute;
      right: -1px;
      top: -1px;
      padding: 3px;
    }

    &-icon {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      overflow: hidden;
      border: #D5D5D5 1px solid;
      margin-right: 20px;
      background-size: 100%;
      background-size: cover;
      background-position: center;
    }

    &-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &-collapse {
    text-decoration: none;
    color: $black;

    .fa-chevron-down {
      &:before {
        content: "";
      }
    }

    &.collapsed {
      .fa-chevron-down {
        &:before {
          content: "";
        }
      }
    }

    &:hover, &:focus {
      text-decoration: none;
      color: $black;
    }

    p {
      line-height: 21px;
      margin-bottom: 20px;
    }
  }

  &-btn {
    &-cancel {
      border: #95989A 1px solid;
      border-radius: 3px;
      background-color: $textForDarkElements;
      color: #5E5E5E;
      min-width: 140px;
    }

    &-save {
      background-color: #00B955;
      color: $textForDarkElements;
      margin-left: 18px;
      line-height: 21px;
      border: #00B955 1px solid;
    }
  }

  &-verification {
    padding: 0 50px;

    &-addfiles {
      &-button {
        border: #14669B 1px solid;
        border-radius: 3px;
        background-image: url('/images/icon-clip-blue.svg');
        background-position: 20px center;
        background-repeat: no-repeat;
        background-size: 17px;
        padding: 7px 20px 7px 40px;
        text-align: center;
        margin: 0 10px;

        &:hover {
          text-decoration: none;
        }

        &._disabled {
          color: #707070;
          border: #707070 1px solid;
        }
      }

      &-items {
        display: flex;
        justify-content: center;
      }

      &-item {
        border: #D5D5D5 1px solid;
        background-color: #F0F0F0;
        background-image: url('/images/icon-file.svg');
        background-repeat: no-repeat;
        width: 83px;
        height: 67px;
        background-repeat: no-repeat;
        background-size: 28px;
        background-position: center 60%;
        font-size: 6px;
        text-align: left;
        padding: 5px;
        border-radius: 3px;
        position: relative;
        margin: 0 8px;

        &-close {
          width: 12px;
          height: 12px;
          background-color: $textForDarkElements;
          background-image: url('/images/icon-close-black.svg');
          background-repeat: no-repeat;
          border-radius: 50%;
          cursor: pointer;
          position: absolute;
          right: 5px;
          top: 3px;
          background-size: 4px;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }
  }

  &-page {
    .comment-card-wrap {
      width: 100%;
    }

    .comment-autor-name {
      font-size: 14px;
      color: $textPrimary;
    }
  }
}

.view-comments {
  &:hover {
    cursor: pointer;
  }
}

.full-comments {
  &:hover {
    cursor: pointer;
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .company {
    &-list {
      &-item {
        height: inherit;

        &-rating {
          text-align: left;
          margin-top: 12px;
        }
      }
    }

    &-userlist-item-open-edit {
      position: absolute;
      top: 18px;
      right: 0;
    }

    .items .nav-tabs.nav-justified li {
      border-right: #e8e8e8 1px solid;
    }

    &-userlist {
      .flex-center {
        justify-content: left;
        align-items: center;
        flex-wrap: wrap;
      }

      &._invited .company-hint-text {
        left: initial;
        bottom: -35px;
        right: 0;
      }
    }
  }
}

#removeLogo {
  input {
    margin-right: 4px;
  }
}