.nav-pills {
  margin-bottom: -3px;
  @include flex-wrap(inherit);
  white-space: nowrap;

  @include media-breakpoint-up(md) {
    overflow-x: auto;
    white-space: inherit;
  }

  .nav-item {
    padding: 16px 20px 0;

    @include media-breakpoint-up(md) {
      padding: 16px 30px 0;
    }

    .nav-link {
      padding: 12px 20px 20px;
      border-radius: 0;
      color: $textForDarkElements;
      position: relative;


      &.active {
        background: #2B73B4;
      }
    }
  }
}

.nav-tabs {
  overflow-x: auto;
  @include flex-wrap(inherit);
  white-space: nowrap;
  overflow-y: hidden;

  @include media-breakpoint-up(sm) {
    overflow-x: inherit;
    overflow-y: inherit;
    white-space: inherit;
  }

  .nav-item {
    margin-bottom: 1px;

    @include media-breakpoint-up(sm) {
      margin-bottom: -1px;
    }
  }

  .nav-link {
    padding: 0 .6rem  1rem;
    border: 0;

    @include media-breakpoint-up(md) {
      padding: 0 4rem  1rem;
    }

    &.active {
      border-bottom: 4px solid $primary;
      //margin-bottom: -1px;
    }
  }
}


.tab-content {
  border-top: 1px solid $textForDarkElements;
}

@media only screen and (max-width: 767px) {
  .nav-pills .nav-item .nav-link {
    padding: 10px 20px;
  }
}
