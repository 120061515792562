.block-site-video-links {
	@include flexbox();
	@include justify-content(space-between);
}

.block-sitelinks {
	width: calc(50% - 10px);
	p {
		cursor: pointer;
		margin-bottom: 0;
		text-decoration: underline;
		text-decoration-style: dotted;
	}
	i {
		margin-right: 10px;
		color: $textPrimary;
	}
	.form-group {
		display: none;
	}
	&.add-link {
		.form-group {
			display: block;
		}
	}
}

.drop-down-box-content {
	display: none;
	margin-top: 16px;
}

.icon-info-center {
	border-radius: 50%;
	border: 1px solid $primary;
	background-color: $textForDarkElements;
	@include flexbox();
	@include align-items(center);
	@include justify-content(center);
	width: 17px;
	height: 17px;
	margin-left: 5px;
	span {
		font-size: 10px;
		font-weight: 500;
		color: $primary;
	}
}

.type-employer {
	&-list {
		padding: 0;
		@include flexbox();
		@include align-items(center);
		margin-bottom: 0;
		li {
			width: 50%;
			> p {
				@include flexbox();
				@include align-items(center);
				@include justify-content(flex-start);
				margin-bottom: 0;
				cursor: pointer;
			}
			label {
				margin-left: 11px;
				font-weight: 400;
			}
		}
	}
	&-icon {
		margin-right: 13px;
		width: 20px;
		height: 20px;
		border: 1px solid #D5D5D5;
		background-color: #F0F0F0;
		@include flexbox();
		@include align-items(center);
		@include justify-content(center);
		border-radius: 50%;
		span {
			background-color: $primary;
			border-radius: 50%;
			width: 12px;
			height: 12px;
			display: none;
		}
	}
	&-active {
		.type-employer-icon span {
			display: inline-block;
		}
	}
}

@media only screen and (max-width: 991px) {
	.new-job-add-filter {
		width: 245px;
	}
	.type-employer-list {
		li {
			width: 40%;
		}
	}
	.block-site-video-links {
		.block-sitelinks {
			width: 100%;
			&:nth-child(1) {
				margin-bottom: 1rem;
			}
		}
	}
}

@media only screen and (max-width: 767px) {
	.new-job-add-filter {
		margin-bottom: 15px;
		width: 100%;
	}
	.type-employer-list {
		@include flex-direction(column);
		li {
			width: 100%;
			margin-bottom: 1rem;
			&:nth-last-child(1) {
				margin-bottom: 0;
			}
		}
	}
}