.notification-block-header {
	position: relative;
	.fa-bell {
		color: $textForDarkElements;
		font-size: 18px;
		cursor: pointer;
		line-height: 26px;
		vertical-align: middle;
	}
	.notifications-icon {
		display: none;
		background-color: #EFD031;
		width: 7px;
		height: 7px;
		position: absolute;
		right: -2px;
		top: 0;
	}
	&.there-are-notifications {
		.notifications-icon {
			display: inline-block;
		}
	}
	.notification-block-list {
		position: absolute;
		top: 100%;
		right: -6px;
		border: 1px solid $UILightGray;
		background-color: $textForDarkElements;
		width: 414px;
		z-index: 2;
		max-height: calc(100vh - 52px);
		overflow-y: scroll;
		ul {
			padding: 0 9px;
			li {
				border-bottom: 1px solid $UILightGray;
				padding: 14px 45px 14px 40px;
				position: relative;
				p{
					text-align: left;
					margin-bottom: 0;
					line-height: 18px;
					a{
						color: #008AEA;
						&:hover{
							text-decoration: underline;
						}
					}
				}
			}
			.fa-times{
				position: absolute;
				right: 0;
				top: 50%;
				@include translate(0, -50%);
				color: #ADB5BD;
				font-size: 14px;
				cursor: pointer;
			}
			.info-icon{
				position: absolute;
				left:0;
				top: 50%;
				@include translate(0, -50%);
			}

			.notification-type-icon {
				font-size: 25px;
			}

			/*.fa-exclamation-triangle {
				color: #DC3545;
				font-size: 23px;
			}
			.fa-question-circle {
				color: #008AEA;
				font-size: 27px;
			}
			.fa-check {
				color: #00B955;
				font-size: 24px;
			}*/
		}
	}
	.notification-block-list::-webkit-scrollbar { width: 0; }
	.notification-block-list { -ms-overflow-style: none; }

	.btn-close-all {
		text-align: center;
		padding: 10px 0;
		a{
			color: #008AEA;
			&:hover{
				text-decoration: underline;
			}
		}
	}
	.no-notification-text {
		margin-bottom: 0;
		padding: 10px 13px;
		text-align: left;
		display: none;
	}
	.no-notification{
		>ul,
		.btn-close-all {
			display: none;
		}
		.no-notification-text {

			display: inline-block;
		}
	}
}

@media only screen and (max-width: 991px) {
	.notification-block-header {
		.notification-block-list {
			ul {
				border-bottom: 1px solid $UILightGray;
				li {
					&:nth-last-child(1) {
						border-bottom: none;
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
	.notification-block-header {
		margin-right: 0;
		.notification-block-list {
			width: calc(100vw - 60px);
			//right: -29px;
			ul {
				li {
					padding: 19px 25px 19px 39px;
				}
				.info-icon {
					left: 0;
				}
			}
		}
	}
}

@media only screen and (max-width: 767px) and (orientation: landscape) {
	.notification-block-header {
		.notification-block-list {
			width: 540px;
			right: -33px;
		}
	}
}

@media only screen and (max-width: 576px) and (orientation: landscape) {
	.notification-block-header {
		.notification-block-list {
			width: calc(100vw - 9px);
			right: -32px;
		}
	}
}

@media only screen and (max-height: 500px) and (orientation: landscape) {
	.notification-block-header {
		margin-top: -7px;
	}
}
