h2 {
    font-weight: 400;
}

a {
    color: #14669b;

    &.no-underline {
        &:hover {
            text-decoration: none;
        }
    }

    &:hover {
        color: $hoverPrimary;
    }
}

.text-underline {
    text-decoration: underline;
}

.font-weight-bolder {
    font-weight: 500 !important;
}

.list-dot {
    li {
        position: relative;
        margin: 0;

        @include media-breakpoint-up(md) {
            margin: 0 10px;
        }

        &:first-of-type {
            margin-left: 0;
        }

        &:last-of-type {

            &:after {
                display: none;
            }
        }

        &:after {
            content: "•";
            position: absolute;
            margin: 0 8px;
            color: $primary;

            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
    }
}

.list-gap {
    li {
        padding-bottom: .5rem;

        &:last-of-type {
            padding-bottom: 0;
        }

        i {
            width: 100%;
            text-align: center;
            margin-right: 0;

            @include media-breakpoint-up(sm) {
                width: 14px;
                text-align: center;
                margin-right: 10px;
            }
        }
    }
}

.text-orange {
    color: #E96E25;
}

.text-dotted {
    border-bottom: 1px dotted;
}

.display-6 {
    font-size: 1.5rem;
}

.display-7 {
    font-size: 1.3rem;
}

.display-8 {
    font-size: 1.1rem;
}

.display-9 {
    font-size: .9rem;
}

label {
    color: $textPrimary!important;
    font-weight: 600;
}