.list-partner-companies {
  margin-bottom: 2.8vh;
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .list-partner-companies {
    margin-bottom: 0;
  }
  .list-partner-companies__btn {
    font-size: 3.9vw;
    color: $primary;
    background: none;
    border-color: #fff;
    padding: 0;
    &:hover {
      color: $primary;
      background: none;
      border-color: #fff;
    }
  }
}
