.carousel {
  position: relative;

  h2 {
    margin-bottom: 15px;
  }

  p {
    font-size: .8rem;
    line-height: 1.5rem;
  }

  .carousel-indicators {
    background-color: $textForDarkElements;
    @include translate(-50%, 0);
    left: 50%;
    margin: 0;
    right: inherit;
    padding: 5px;
    border-radius: 20px;

    li {
      border-radius: 50%;
      background-color: #e9ebec;
      width: 12px;
      height: 12px;

      &.active {
        background-color: $primary;
      }
    }
  }

  .read-more-btn {
    color: #419DE4;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }

  .carousel-more-text {
    display: none;
  }

  .active-more-text {
    .carousel-more-text {
      display: inline-block;
    }
    .read-more-btn {
      display: none;
    }
  }
}