.field-password-wrap {
  .input-group {
    input {
      width: calc(100% - 36px) !important;
      border: none;
      height: calc(1.5em + 0.75rem - 2px);
      margin-top: 1px;
      margin-bottom: 1px;
      padding-left: .75rem;
      padding-right: .75rem;

      &:focus {
        color: #495057;
        background-color: #fff;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(0, 139, 235, 0.25);
      }
    }

    .input-group-append {
      .btn {
        background: none;
        padding: 0;
        width: 36px;
        border: none;
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);

        .fa-eye {
          font-size: 11px;
        }
      }
    }
  }

  &.is-invalid-block {
    label {
      color: #E63535 !important;
    }
  }
}

.form-reg-field {
  border: 1px solid $UILightGray !important;

  &.is-invalid {
    border-color: #E63535 !important;
    background-image: none;
  }
}

.reg-field-border {
  .form-control {
    //border: 1px solid #D1D1D1 !important;
    &:focus {
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(0, 139, 235, 0.25);
    }
  }
}

.auth-page-mob {
  padding-top: 40px;

  .invalid-feedback {
    font-size: 14px;
  }
}

.registration-page {
  padding-top: 40px;
  width: 575px;
  margin: auto;

  .reg-title-mob {
    font-size: 25px;
    line-height: 192%;
    margin-bottom: 10px;
    text-align: center;
  }

  .registration-sub-title {
    font-size: 14px;
    line-height: 143%;

    //&__gray {
    //  color: $secondary;
    //}
  }

  .manage-password,
  .reg-field-border {
    margin-bottom: 10px;

    label {
      margin-bottom: 10px;
    }
  }

  .registration-form-btn {
    margin: 15px 0 20px;
  }

  .already-registered-text {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 20px;
  }

  .social-auth {
    @include flexbox();
    @include align-items(center);
    @include justify-content(space-between);
    margin-top: 10px;
  }

  .btn-sm {
    padding-left: 30px;
    padding-right: 30px;
    margin-left: 20px;
  }

  .form-control__desc {
    font-size: 13px;
    line-height: 154%;
    color: $secondary;
    margin-bottom: 0;
  }

  .form-group__first {
    @include flexbox();
    @include align-items(baseline);
    @include justify-content(space-between);

    .reg-field-border {
      &:nth-child(1) {
        width: 61%;
      }

      &:nth-child(2) {
        width: 35%;
      }
    }
  }

  .material-icons-outlined {
    font-size: 16px;
    color: $primary;
    vertical-align: sub;
    cursor: pointer;
  }
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: none;
}

.is-invalid-block {
  label {
    color: $hoverDanger !important;
  }

}

.tooltip-inner {
  padding: 10px 20px;
  background: #fff;
  font-size: 14px;
  line-height: 143%;
  color: $secondary;
  text-align: left;
  border: 0.5px solid #E0E0E0;
  box-sizing: border-box;
  box-shadow: 0 4px 20px rgba(125, 125, 125, 0.25);
}

.tooltip.bs-tooltip-auto[x-placement^=top] .arrow::before, .tooltip.bs-tooltip-top .arrow::before {
  margin-left: -3px;
  content: "";
  border-width: 5px 5px 0;
  border-top-color: #fff;
  box-shadow: 0 4px 20px rgba(125, 125, 125, 0.25);
}

@media only screen and (max-width: 767px) {
  .auth-title-mob {
    font-size: 18px;
    margin: 0 0 18px !important;
  }
  .reg-title-mob {
    font-size: 18px;
  }
  .registration-page {
    padding-top: 20px;
    width: 100%;

    .reg-title-mob {
      font-size: 18px;
      line-height: 48px;
      margin-bottom: 5px;
    }

    .form-control__desc {
      margin-top: 5px;
    }

    .form-group__first {
      @include flex-direction(column);

      .reg-field-border {
        &:nth-child(1),
        &:nth-child(2) {
          width: 100%;
        }
      }
    }

    .registration-form-btn {
      margin: 10px 0 20px;
    }

    .social-auth {
      @include flex-direction(column);
      @include align-items(center);

      > div {
        width: 100%;
        text-align: center;
      }
    }

    .btn-sm {
      margin: 20px 20px 0;
    }
  }
}
