.user-menu {
	left: auto;
	right: 20em;
}

#navbar-brand-img-bee-small {
	width: 40px;
	display: none;
}

.btn-height {
	height: 28px;
	@include flexbox();
}

.navbar-custom {
	&-wallet {
		padding: 0 2px 0 10px;
		line-height: 15px;
		position: relative;
		display: inline-block;
		svg {
			width: 17px;
			position: absolute;
			top: 5px;
		}
		&-amount {
			padding: 0 6px 0 10px;
		}
		&-add {
			display: inline-block;
			border-left: #ffffff 1px solid;
			padding: 4px 7px 7px;
		}
		&:hover {
			text-decoration: none;
			background-color: transparent;

			.navbar-custom-wallet-add {
				text-decoration: underline;
				background-color: transparent;
			}
		}
	}
}

.user-menu-dropdown {
	border-radius: 3px;
	background-color: #fff;
	position: absolute;
	left: 50%;
	top: 50px;
	cursor: auto;
	@include translate(-50%, 0);
	max-width: 454px;
	width: 100%;
	&-header {
		padding: 8px 15px;
		@include flexbox();
		@include justify-content(space-between);
		.img-wrap {
			width: 31px;
			height: 31px;
			margin-right: 23px;
			border-radius: 50%;
			overflow: hidden;
			img {
				width: 100%;
				height: auto;
				min-height: 100%;
			}
		}
	}
}

.block-img-name {
	@include flexbox();
	@include align-items(center);
}

.user-email {
	color: $dark;
	font-size: 0.625rem;
}

.dropdown-header-user-name {
	font-size: 1rem;
	line-height: 1;
	&:hover {
		color: $hoverPrimary;
	}
}

.icon-btn-exit {
	margin-right: 5px;
	font-size: 13px;
}

.btn-exit {
	border: none;
	background: none;
	color: #2681BD;
	font-size: 0.875rem;
}

.list-user-settings {
	width: 100%;
	@include flexbox();
	@include flex-wrap(wrap);
	padding: 0;
	li {
		width: 50%;
		border-top: 1px solid #F0F0F0;
		border-right: 1px solid #F0F0F0;
		list-style-type: none;
		position: relative;
		@include flexbox();
		@include align-items(center);
	}
	li:nth-child(even) {
		border-left: none;
		border-right: none;
	}
	&-title {
		margin: 0;
		width: auto;
		padding: 15px 0 15px 75px;
		color: $dark;
		text-decoration: none;
		font-size: 0.875rem;
		&:hover {
			text-decoration: none;
		}
	}
}

.list-user-img {
	font-size: 21px;
	position: absolute;
	left: 25px;
	top: 17px;
	@include translate(0, 0);
	color: rgba(94, 94, 94, 0.87);
}

.navbar {
	li {
		list-style-type: none;
		.number-response {
			width: 22px;
			height: 22px;
			border-radius: 50%;
			background-color: $primary;
			@include flexbox();
			@include align-items(center);
			@include justify-content(center);
			margin-left: 5px;
			p {
				color: #fff;
				font-size: 9px;
				margin-bottom: 0;
			}
		}
	}
	.nav-item {
		&-active {
			position: relative;
			&:before {
				content: '';
				height: 1px;
				width: calc(100% - 1.6rem);
				background-color: #fff;
				position: absolute;
				bottom: -2px;
				left: .8rem;
			}
		}
		&:nth-child(1){
			&:before {
				width: calc(100% - .8rem);
				left: 0;
			}
		}
	}
}

.block-account-balance {
	padding: 7px 13px;
	background-color: #EBF6FF;
	width: 100%;
	@include flexbox();
	@include align-items(center);
	@include justify-content(space-between);
}

.account-balance-text {
	font-size: 10px;
	color: #2681BD;
	margin-bottom: 0;
	span {
		font-size: 14px;
		font-weight: 700;
	}
}

.btn-menu-blue {
	font-size: 10px;
	color: #007BFF;
	background: none;
	border: 1px solid #007BFF;
	border-radius: 4px;
	line-height: 24px;
	padding: 0 9px;
}

.on-mob-viz {
	display: none !important;
	.user-menu-dropdown-header {
		padding: 5px 25px 20px 10px;
	}
	.block-account-balance {
		padding: 4px 13px;
	}
}

.btn-dropdown-menu {
	max-width: 82px;
}

.menu-mob {
	width: 100%;
}

.search-on-mob {
	width: 100%;
	padding-top: 10px;
	.input-group {
		@include flex-direction(column);
	}
	.on-mob-100,
	.dropdown-header-user-name {
		width: 100% !important;
		margin-bottom: 5px;
	}
}

.for-mob-landscape {
	display: none !important;
	.user-email {
		margin-bottom: 0;
	}
	.list-user-settings-title {
		line-height: 31px;
	}
	.user-menu-dropdown-header {
		@include align-items(center);
		padding: 4px 15px;
	}
	.block-account-balance {
		padding: 3px 13px;
	}
	.list-user-settings li {
		border-left: 1px solid #F0F0F0;
	}
}

.bg-white {
	background-color: #fff;
}

.menu-desktop {
	right: 16px;
	width: 100%;
	max-width: 525px;
}

.no-arrow::after {
	display: none;
}

.choices:last-child {
	margin-bottom: 1rem;
}

.user-email {
	margin-bottom: 0;
}

.dropdown-header-user-name {
	margin-bottom: 5px;
}

.dropdown-menu {
	border-radius: 0;
}

@media (min-width: 992px) {
	.list-user-settings {
		li {
			&:focus,
			&:hover {
				background-color: #EBF6FF;
				.list-user-settings-title {
					color: rgba(94, 94, 94, 0.87);
				}
			}
		}
	}
}

@media only screen and (max-width: 991px) {
	.user-menu-dropdown {
		max-width: 310px;
		&-header .img-wrap {
			width: 42px;
			height: 42px;
			margin-right: 11px;
		}
	}
	.list-user-settings {
		@include flex-direction(column);
		&-title {
			line-height: 25px;
			padding-left: 64px;
			padding-top: 0;
			padding-bottom: 0;
			width: 100%;
			&:hover {
				color: #fff !important;
			}
		}
		li {
			width: 100%;
			border-left: none;
			padding: 5px 0;
			border-top: none;
			i {
				padding-left: 7px;
			}
			.fa-users {
				left: 13px;
			}
			.fa-building,
			.fa-file-alt,
			.fa-file-invoice-dollar {
				left: 18px;
			}
			&.active-link {
				background-color: #2B73B4;
			}
			&:focus,
			&:hover {
				background-color: #2B73B4;
			}
			&.on-mob-viz {
				display: -webkit-box!important;
				display: -ms-flexbox!important;
				display: flex!important;
			}
		}
	}
	.user-menu-dropdown-header {
		@include align-items(center);
	}
	.list-user-img {
		left: 15px;
		color: #fff;
		font-size: 16px;
		top: 50%;
		@include translate(0, -50%);
	}
	.on-mob-hide,
	.on-mob-por-hide {
		display: none !important;
	}
	.on-mob-viz {
		display: inline-block !important;
		.user-menu-dropdown-header {
			padding: 5px 10px 20px;
			border-bottom: 1px solid #B3D6FC;
		}
	}
	.link-line {
		border-bottom: 1px solid #B3D6FC;
		margin-bottom: 10px;
	}
	.navbar-nav {
		background-color: $primary;
		max-height: calc(100vh - 51px);
		width: calc(100% + 10px);
		margin-left: -5px;
		overflow: scroll;
		@include align-items(flex-start);
		margin-top: 0 !important;
		margin-bottom: 0 !important;
		padding-bottom: 15px;
		padding-top: 6px;
		&.navbar-nav-employer {
			background: rgb(72,168,250);
			background: -o-linear-gradient(23deg, rgba(72,168,250,1) 0%, rgba(27,124,209,1) 55%, rgba(154,128,231,1) 100%, rgba(147,115,226,1) 100%);
			background: linear-gradient(67deg, rgba(72,168,250,1) 0%, rgba(27,124,209,1) 55%, rgba(154,128,231,1) 100%, rgba(147,115,226,1) 100%);
		}
		.btn {
			min-width: 120px;
			width: auto;
			padding: 4px 10px;
			border-radius: 0 !important;
		}
		.on-mob-viz .btn {
			margin-right: 0;
		}
		.nav-item {
			@include flexbox();
			@include align-items(baseline);
			//padding-left: 40px;
			position: relative;
			padding: 5px 0!important;
			width: 100%;
			.items-for-mob {
				color: #fff;
				font-size: 17px;
				width: 1.25em;
				left: 10px;
				padding-left: 7px;
				//position: relative;
				position: absolute;
				//left: 16px;
				//top: 4px;
			}
			.nav-link{
				line-height: 25px;
				padding-left: 64px!important;
				margin: 0;
				width: 100%;
			}
		}
	}
	.on-mob-width {
		width: 100%;
	}
	.user-email {
		color: #fff;
	}
	.navbar .nav-link,
	.btn-exit,
	.dropdown-header-user-name,
	.user-email,
	.list-user-settings-title {
		color: #fff;
	}
	.dropdown-header-user-name{
		text-decoration: underline;
		&:hover {
			color: #fff;
		}
	}
	.navbar-top {
		> .container {
			@include flex-direction(row);
			@include justify-content(flex-end);
			padding-left: 10px;
			padding-right: 10px;
			.list-unstyled,
			> div {
				width: 33.3%;
				text-align: center;
			}
		}
		.fa-globe {
			font-size: 20px;
		}
	}
	.btn-height {
		@include justify-content(center);
	}
	.navbar {
		padding: 0;
		.navbar-toggler {
			position: absolute;
			top: -36px;
			padding-left: 10px;
			.fa-user {
				font-size: 19px;
			}
		}
		li {
			.number-response {
				background-color: #fff;
				width: 16px;
				height: 16px;
				p {
					color: #008AEA;
					font-size: 8px;
					margin-left: -1px;
				}
			}
		}
	}
	.width-on-mob {
		width: 100%;
	}
	.btn-exit .icon-btn-exit {
		font-size: 19px;
	}
	.block-img-name {
		padding-left: 0;
	}


}

@media only screen and (max-width: 991px) and (orientation: portrait) {
	.list-user-settings li {
		border-right: none;
	}
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
	.navbar-top {
		padding: 5px 0 !important;
		.container {
			> div {
				width: 50%;
				position: relative;
				transform: translateX(-15%);
			}
			.list-unstyled {
				width: auto;
				@include flex-direction(row-reverse);
			}
		}
	}
	.navbar .navbar-toggler {
		top: -41px;
	}
	.on-mob-portrait {
		p {
			display: none;
		}
		.btn-height {
			@include flexbox();
			@include align-items(center);
			@include justify-content(center);
			width: 29px;
			min-width: auto;
			padding: 0;
		}
	}

	.navbar-nav .on-mob-viz .btn {
		margin-right: 1.5px;
	}
}

@media only screen and (max-width: 815px) and (orientation: landscape) {
	.list-user-settings {
		@include flex-direction(row);
		@include flex-wrap(wrap);
		li {
			width: 50%;
		}
	}
}

@media only screen and (max-height: 500px) and (orientation: landscape) {
	.list-user-settings {
		&-title {
			padding-left: 54px;
		}
		li {
			border-right: none;
			i {
				padding-left: 0;
			}
			.fa-users {
				left: 10px;
			}
		}
	}
	.link-line {
		width: 100% !important;
	}
	.navbar .navbar-toggler {
		top: -39px;
		padding-left: 0;
	}
	.navbar-top {
		height: 50px;
		.navbar-brand {
			padding-top: 0;
		}
		.lang-icon {
			padding-top: 0;
			padding-bottom: 0;
			margin-top: -9px;
		}
		.container {
			padding-left: 0;
			padding-right: 0;
		}
	}
	.on-mob-viz .user-menu-dropdown-header {
		padding-left: 0;
		padding-right: 0;
	}
	.navbar-nav {
		.on-mob-viz .btn {
			margin-right: 0.5px;
		}
		.nav-item {
			padding-left: 31px;
			.items-for-mob {
				left: 5px;
			}
			.nav-link {
				padding-left: 54px !important;
			}
		}
	}
}

@media only screen and (max-height: 320px) and (orientation: landscape) {
	.navbar-nav {
		.on-mob-viz {
			.btn {
				margin-right: 10px;
			}
			.user-menu-dropdown-header {
				padding-left: 10px;
			}
		}
	}
	.navbar-top .container {
		padding-left: 4px;
		padding-right: 2px;
	}
	.navbar .navbar-toggler {
		padding-left: 10px;
	}
	.navbar-top ul .lang-icon {
		padding-right: 8px;
	}
	.list-user-settings {
		padding-left: 10px;
	}
}
