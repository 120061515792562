<template>
	<div>
		<div class="alert-success alert" v-if="errors.form < 0">
			{{ replySuccessText }}
		</div>
		
		<div v-if="!isSent" class="card collapse rounded-sm p-3 mt-4 mb-3 vacancy-page-form reply-form"
		     id="showReplyForm"
		     style=""
		     v-bind:class="[collapsed ? 'collapse' : '']">
			<div class="card-body p-0">
				<div class="float-right text-black-50 pb-3">
					<span>{{ formRequiredLabel }}</span>
				</div>
				<form @submit="onSubmitForm" method="post" novalidate="true">
					<div class="form-group" v-bind:class="[errors.email ? 'has-error' : '']">
						<label class="text-black-50" for="name">{{ nameLabel }}</label>
						<input
								class="form-control"
								id="name"
								name="name"
								required="required"
								type="text"
								v-bind:class="[(errors.name > 0 ? 'is-invalid text-danger' : ''), (errors.name < 0 ? 'is-valid' : '')]"
								v-bind:placeholder="namePlaceholder"
								v-model="name"
								v-on:change="validateName"
								v-on:focusout="clearName"
								v-on:keyup="validateName"
						>
						<span class="text-danger" v-if="errors.name > 0">Incorrect format</span>
					</div>
					<div class="row">
						<div class="form-group col-sm-6"
						     v-bind:class="[errors.email ? 'has-error' : '']">
							<label class="text-black-50" for="email">{{ emailLabel }}</label>
							<input
									class="form-control"
									id="email"
									name="email"
									required="required"
									type="email"
									v-bind:class="[(errors.email > 0 ? 'is-invalid text-danger' : ''), (errors.email < 0 ? 'is-valid' : '')]"
									v-bind:placeholder="emailPlaceholder"
									v-model="email"
									v-on:change="validateEmail"
									v-on:keyup="validateEmail">
							<span class="text-danger"
							      v-if="errors.email > 0">Incorrect format</span>
						</div>
						<div class="form-group col-sm-6"
						     v-bind:class="[errors.email ? 'has-error' : '']">
							<label class="text-black-50" for="phone">
								{{ phoneLabel }}
							</label>
							<input
									class="form-control"
									id="phone"
									name="phone"
									required="required"
									type="text"
									v-bind:class="[(errors.phone > 0 ? 'is-invalid text-danger' : ''), (errors.phone < 0 ? 'is-valid' : '')]"
									v-bind:placeholder="phonePlaceholder"
									v-model="phone"
									v-on:change="validatePhone"
									v-on:keyup="validatePhone"
							>
							<span class="text-danger"
							      v-if="errors.phone > 0">Incorrect format</span>
							<span class="text-muted">{{ phoneHelp }}</span>
						</div>
					</div>
					<div class="form-group" v-bind:class="[errors.message ? 'has-error' : '']">
						<label class="text-black-50" for="message">{{ messageLabel }}</label>
						<textarea
								class="form-control"
								id="message"
								name="message"
								required="required"
								v-bind:class="[(errors.message > 0 ? 'is-invalid text-danger' : ''), (errors.message < 0 ? 'is-valid' : '')]"
								v-bind:placeholder="messagePlaceholder"
								v-model="message"
								v-on:change="validateMessage"
								v-on:keyup="validateMessage"
						></textarea>
						<p class="text-danger" v-if="errors.message > 0">Incorrect format</p>
					</div>
					<div class="alert-danger alert" v-if="errors.form > 0">
						{{ replyFailedText }}
					</div>
					<div class="form-group btn-form-group">
						<button class="btn btn-primary margin-top-10-xs"
						        id="reply"
						        name="reply"
						        type="submit"
						        v-on:click="validateForm">
							<i class="fa fa-paper-plane"></i> <span class="vacancy-page-mob-hide">{{ sendLabel }}</span>
						</button>
						<a aria-controls="showReplyForm"
						   aria-expanded="true"
						   class="btn btn-danger pull-right pull-left-xs margin-top-10-xs"
						   data-target="#showReplyForm"
						   data-toggle="collapse"
						   href="#showReplyForm">
							<i class="fa fa-times"></i> <span class="vacancy-page-mob-hide">{{ cancelLabel }}</span>
						</a>
					</div>
					<input id="_token"
					       name="_token"
					       type="hidden"
					       value="L-aTqKXk7JIz99FHte01SKQyx5Z6ZF3PivtiVnkmZuE">
				</form>
			
			</div>
		</div>
	</div>
</template>

<script>
	import * as axios from 'axios';
	
	export default {
		name: "ReplyForm",
		props: {
			phoneLabel: String,
			phoneHelp: String,
			phonePlaceholder: String,
			emailLabel: String,
			emailHelp: String,
			emailPlaceholder: String,
			nameLabel: String,
			nameHelp: String,
			namePlaceholder: String,
			messageLabel: String,
			messageHelp: String,
			messagePlaceholder: String,
			sendLabel: String,
			cancelLabel: String,
			formRequiredLabel: String,
			replyFailedText: String,
			replySuccessText: String,
			offerId: String,
			offerType: String,
			userName: String,
			userEmail: String,
			userPhone: String,
		},
		data() {
			return {
				collapsed: true,
				name: this.userName,
				email: this.userEmail,
				phone: '',
				message: '',
				errors: {
					name: 0,
					email: 0,
					phone: 0,
					message: 0,
					form: 0,
				},
				isSent: false,
			}
		},
		methods: {
			toggle() {
				this.collapsed = !this.collapsed;
			},
			onSubmitForm(event) {
				
				let self = this;
				
				this.validateForm(event);
				
				event.preventDefault();
			},
			clearName: function (event) {
				this.name = this.name.replace(/[&\/\\#,+()!^=_$~@%.'":*?<>{}№;]/g, '');
			},
			
			validateName: function (event) {
				let format = /^[\D]+$/,
				    self   = this;
				
				let result = self.name.length > 0 && format.test(self.name);
				
				self.errors.name = result ? -1 : 1;
				
				return result;
			},
			
			validateEmail: function () {
				
				let re     = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
				    self   = this,
				    result = self.email.length > 0 && re.test(self.email);
				
				self.errors.email = result ? -1 : 1;
				
				return result;
			},
			
			validatePhone: function () {
				let format = /^[\+]{0,1}[0-9 \-]+$/,
				    self   = this,
				    result = self.phone.length > 0 && format.test(self.phone);
				
				self.errors.phone = result ? -1 : 1;
				
				return result;
			},
			
			validateMessage: function () {
				let self   = this,
				    result = self.message.length > 0;
				
				self.errors.message = result ? -1 : 1;
				
				return result;
			},
			
			validateAllFields: function () {
				let self = this;
				
				return (self.validateName() < 0) && (self.validateEmail() < 0) && (self.validatePhone() < 0) && (self.validateMessage() < 0);
			},
			formHasErrors: function () {
				this.validateEmail();
				this.validateName();
				this.validatePhone();
				this.validateMessage();
				
				return this.errors.name >= 0 || this.errors.email >= 0 || this.errors.phone >= 0 || this.errors.message >= 0;
			},
			validateForm(event) {
				let self = this;
				
				self.errors.form = 0;
				
				event.preventDefault();
				
				// let fieldsWithErrors = Object.fromEntries(Object.entries(errors).filter(([key, value]) => value >= 0));
				
				if (!self.formHasErrors()) {
					
					axios.post('/api/' + self.offerType + '/reply', {
						job: self.offerId,
						name: self.name,
						email: self.email,
						phone: self.phone,
						message: self.message
					}).then(function (response) {
						
						self.errors.form = -1;
						self.toggle();
						self.isSent = true;
						
					}).catch(function(error) {
						if (error.response) {
							/*
							if(error.response && error.response.data && error.response.data.length > 0) {
								self.replyFailedText = error.response.data[0];
							}*/
						} else if (error.request) {
							console.error("Request error:", error.request);
						} else {
							// Something happened in setting up the request that triggered an Error
							console.error('Error:', error.message);
						}
						
						self.errors.form = 1;
					});
					
				} else {
					self.errors.form = 1;
				}
			},
			
			clearErrorsByField: function (field) {
				let errorIndex = this.errors.indexOf(field);
				
				if (errorIndex > -1) {
					this.errors.splice(errorIndex, 1);
				}
			},
		},
		mounted() {
			this.$root.$on('replyToggle', (status) => {
				this.collapsed = status;
			});
		},
	}
</script>

<style scoped>
	.has-errors >>> .form-control {
		border-color: #DC3545;
	}
	
	.has-errors >>> .required {
		color: #DC3545;
	}
</style>