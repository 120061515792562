.block-posts {
	padding: 20px 0 100px;
	.container{
		position: relative;
	}
	h2 {
		margin-bottom: 20px;
	}
	.posts-list{
		margin-bottom: 0;
		padding-left: 15px;
		width: 80%;
		li{
			list-style-type: disc;
			a:hover{
				text-decoration: none;
			}
			&:nth-last-child(1){
				margin-bottom: 0;
			}
		}
		.post-title {
			margin-bottom: 0;
		}
	}
	.show-all {
		position: absolute;
		right: 15px;
		bottom: 0;
	}
}

@media only screen and (max-width: 767px) {
	.block-posts {
		padding: 30px 0 40px;
		h2 {
			margin-bottom: 18px;
			font-size: 18px;
		}
		.posts-list {
			margin-bottom: 20px;
			width: 100%;
		}
		.show-all {
			position: relative;
			float: right;
			text-decoration: underline;
		}
	}
}