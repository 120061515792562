.block-custome-input {
	.custome-input:checked,
	.custome-input:not(:checked) {
		position: absolute;
		left: -9999px;
	}
	.custome-label {
		width: 100%;
		position: relative;
	}
	.custome-input:checked label,
	.custome-input:not(:checked) + label {
		padding-right: 21px;
		cursor: pointer;
		display: inline-block;
	}
	.custome-input:checked + label:before,
	.custome-input:not(:checked) + label:before {
		content: '';
		position: absolute;
		right: 0;
		top: 0;
		width: 20px;
		height: 20px;
		border-radius: 100%;
		background-color: #F0F0F0;
		border: 1px solid #D5D5D5;
	}
	.custome-input:checked + label:after,
	.custome-input:not(:checked) + label:after {
		content: '';
		width: 12px;
		height: 12px;
		background: $primary;
		position: absolute;
		top: 4px;
		right: 4px;
		border-radius: 100%;
		-webkit-transition: all 0.2s ease;
		transition: all 0.2s ease;
	}
	.custome-input:not(:checked) + label:after {
		opacity: 0;
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	.custome-input:checked + label:after {
		opacity: 1;
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}