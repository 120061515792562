.popular-professions-list {
	padding: 0 15px;
	@include flexbox();
	@include flex-wrap(wrap);
	li {
		width: 33%;
		padding-right: 30px;
	}
	li:nth-child(3n+3) {
		padding-right: 0;
	}
}

.popular-professions-block {
	&-title {
		margin-bottom: 20px;
	}
}

@media (max-width: 991px) {
	.popular-professions-list {
		li {
			width: 33%;
			padding-right: 15px;
		}
	}
}

.popular-professions-block {
	padding-top: 35px;
	padding-bottom: 9px;
	.popular-professions-list {
		width: 100%;
	}
}

@media (max-width: 767px) {
	.popular-professions-list {
		li {
			width: 50%;
		}
		li:nth-child(3n+3) {
			padding-right: 15px;
		}
		li:nth-child(2n+2) {
			padding-right: 0;
		}
	}
	.popular-professions-block {
		padding-top: 30px;
		&-title {
			margin-bottom: 18px;
			font-size: 18px;
		}
		.popular-professions-list {
			width: 100%;
		}
	}
}

@media (max-width: 500px) {
	.popular-professions-list {
		li {
			width: 100%;
			padding-right: 0;
		}
	}
}