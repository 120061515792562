.icon_select_mate {
  position: absolute;
  top: 6px;
  right: 4px;
  font-size: 16px;
  height: 22px;
  cursor: pointer;
}

.select_mate {
  position: relative;
  float: left;
  width: 100%;
  min-height: 35px;
  color: #062336;
  background-color: #fff;
  border: 1px solid $UILightGray;
  transition: all 375ms ease-in-out;
}

.select_mate select {
  position: absolute;
  overflow: hidden;
  height: 0;
  opacity: 0;
  z-index: -1;
}

.cont_list_select_mate {
  position: relative;
  float: left;
  width: 100%;
}

.cont_select_int {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
  overflow: hidden;
  overflow-y: auto;
  max-height: 200px;
  height: 0;
  width: 100%;
  background-color: #fff;
  padding: 0;
  margin-bottom: 0;
  margin-top: 0;
  transition: all 375ms ease-in-out;
  border: 1px solid $UILightGray;
  box-shadow: 1px 4px 10px -2px rgba(0, 0, 0, 0.2);
}

.cont_select_int li {
  position: relative;
  float: left;
  width: 100%;
  list-style-type: none;
  margin: 0;
  padding: 5px 5px 5px 5px;
  display: block;
  cursor: pointer;
}

.cont_select_int li:last-child {
  border-radius: 3px;
  border-bottom: 0;
}

.cont_select_int li:hover {
  background-color: #008AEA;
  color: #fff;
}

.selecionado_opcion {
  padding: 9px 26px 9px 10px;
  width: 100%;
  display: block;
  margin: 0;
  cursor: pointer;
}