.app-content-wrap {
	padding: 95px 0 100px;
	min-height: calc(100vh - 380px);
}

@media only screen and (max-width: 991px) {
	.app-content-wrap {
		padding: 51px 0 100px;
		min-height: calc(100vh - 800px);
	}
}

@media only screen and (max-width: 767px) {
	.app-content-wrap {
		padding: 51px 0 50px;
	}
}