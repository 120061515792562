.block-create-newsletter {
	@include flexbox();
	@include align-items(flex-start);
	@include justify-content(space-between);
	.right-block {
		background-color: rgba(65, 157, 228, 0.03);
		width: 360px;
		padding: 50px 30px;
		p {
			margin-bottom: 0;
		}
		p:nth-child(1) {
			font-weight: 500;
		}
	}
	.left-block {
		width: calc(100% - 400px);
		label {
			font-weight: 500;
		}
		#email {
			width: 100%;
			max-width: 380px;
		}
		> p:nth-child(1) {
			font-weight: 500;
		}
	}

	.form-group {
		margin-bottom: 30px;
	}

	.block-newsletter-activate {
		margin-bottom: 30px;
		padding-left: 1.8rem;
		.form-check-input {
			@include transform(scale(1.6));
			margin-left: -1.5rem;
		}
	}

	input[type='radio']:after {
		content: '';
		width: 20px;
		height: 20px;
		border-radius: 50%;
		top: -2px;
		left: -1px;
		position: relative;
		background-color: #F0F0F0;
		display: inline-block;
		visibility: visible;
		border: 1px solid #D5D5D5;
	}

	input[type='radio']:checked:before {
		content: '';
		width: 20px;
		height: 20px;
		border-radius: 50%;
		top: -2px;
		left: -1px;
		position: relative;
		background-color: #F0F0F0;
		display: inline-block;
		visibility: visible;
		border: 1px solid #D5D5D5;
	}

	input[type='radio']:checked:after {
		content: '';
		width: 12px;
		height: 12px;
		border-radius: 50%;
		top: 2px;
		left: 3px;
		position: absolute;
		background-color: $primary;
		display: inline-block;
		visibility: visible;
		border: none;
		z-index: 1;
	}

	.form-check-input {
		margin-top: 0.2rem;
	}

	.type-mailing {
		@include flexbox();
		@include align-items(center);
		@include justify-content(flex-start);
		margin-bottom: 1.2rem;
		.form-check {
			margin-right: 100px;
			label {
				margin-left: 12px;
			}
		}
		.form-check:nth-last-child(1) {
			margin-right: 0;
		}
	}
	.is-open .choices__inner {
		background-color: #fff;
		border-color: #4e8bbf;
		box-shadow: 0 0 0 0.2rem rgba(0, 139, 235, 0.25);
	}
	.choices__inner {
		background-color: #fff;
	}

	.tagify__input {
		width: 79px;
		margin: 0 0 0 5px;
		padding: 0;
		z-index: 0;
	}

	.tagify-height {
		height: auto;
		@include flexbox();
		@include align-items(center);
		@include flex-wrap(wrap);
		padding: 0;
		min-height: 35px;
	}
}

.choices__input {
	background-color: #fff;
	padding: 5px 0 0 2px;
	margin-top: 1px;
}

.is-open .choices__inner,
.choices__inner,
.choices__list--dropdown {
	border-radius: 0;
}

.choices__list--multiple .choices__item {
	margin: 2px 2.5px;
	border-radius: 0;
	background-color: #228bd7;
	border: 1px solid #228bd7;
}

.choices[data-type*="select-multiple"] .choices__button, .choices[data-type*="text"] .choices__button {
	border-left: 1px solid #fff;
}

.choices__inner {
	padding-top: 0;
}

@media only screen and (max-width: 991px) {
	.block-create-newsletter {
		@include flex-direction(column-reverse);
		.right-block,
		.left-block {
			width: 100%;
		}
		.right-block {
			padding: 10px;
			margin-bottom: 10px;
		}
	}
}

@media only screen and (max-width: 767px) {
	.block-create-newsletter .type-mailing {
		@include flex-direction(column);
		@include align-items(flex-start);
		.form-check {
			margin: 5px 0;
		}
	}
}
