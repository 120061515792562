.complex-advertising {
  &-block {
    max-width: 1010px;
    width: 95%;
    margin: auto;
    h2 {
      max-width: 675px;
      width: 80%;
      margin: 0 auto 75px;
      text-align: center;
    }
  }
  &-list {
    padding: 0;
    @include flexbox();
    @include flex-wrap(wrap);
    li {
      @include flexbox();
      @include align-items(flex-start);
      margin-bottom: 60px;
      width: 50%;
      padding: 0 80px 0 0;
      >div:nth-last-child(1){
        width: calc(100% - 92px);
      }
    }
    li:nth-child(2n + 2){
      padding: 0 0 0 80px;
    }
  }
  &-img {
    width: auto;
    min-width: 45px;
    max-width: 57px;
    margin-right: 35px;
  }
  &-title {
    font-size: 16px;
    color: #419DE4;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  &-description {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 1199px) {
  .complex-advertising {
    &-list {
      li {
        margin-bottom: 40px;
        padding: 0 40px 0 0;
      }
      li:nth-child(2n + 2){
        padding: 0 0 0 40px;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .complex-advertising {
    &-list {
      li {
        padding: 0 20px 0 0;
      }
      li:nth-child(2n + 2){
        padding: 0 0 0 20px;
      }
    }
  }
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .complex-advertising {
    &-list {
      @include flex-direction(column);
      li {
        width: 100%;
        padding: 0;
      }
      li:nth-child(2n + 2){
        padding: 0;
      }
    }
  }
}