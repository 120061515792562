.form-response-vacancy {
	@include flexbox();
	@include align-items(center);
	@include justify-content(space-between);
	margin-bottom: 35px;
	input {
		width: calc(41.25% - 20px);
	}
	> div {
		@include flexbox();
		@include align-items(center);
		@include justify-content(space-between);
		width: 17.5%;
		button, a {
			width: calc(50% - 10px);
			padding: .375rem 0;
		}
	}
	button {
		padding-left: 20px;
		padding-right: 20px;
	}
}

.block-response-vacancy {
	.btn-show-filter-mob {
		display: none;
		@include align-items(center);
		@include justify-content(center);
		width: 58px;
		height: 58px;
		border-radius: 50%;
		-webkit-box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.4);
		-moz-box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.4);
		box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.4);
		position: fixed;
		bottom: 40px;
		right: 20px;
		background-color: rgba(255, 255, 255, .5);
		cursor: pointer;
		i {
			font-size: 28px;
			color: $primary;
		}
	}
	.btn-close {
		display: none;
		color: $primary;
		border: none;
		background: none;
	}
}

.table-response-vacancy {
	margin-bottom: 35px;
	border-bottom: 1px solid $UILightGray;
	&-header {
		@include flexbox();
		@include align-items(center);
		@include justify-content(space-between);
		border-bottom: 1px solid $UILightGray;
		padding-bottom: 10px;
		p {
			font-weight: 500;
			text-align: center;
			margin-bottom: 0;
		}
	}
	.col1 {
		width: 16%;
		padding: 0 10px;
	}
	.col2 {
		width: 26%;
		padding: 0 10px;
		color: #676767;
	}
	.col3 {
		width: 15%;
		padding: 0 10px;
		color: #676767;
	}
	.col4 {
		width: 43%;
		padding: 0 10px;
	}
	.col5 {
		width: 43%;
		padding: 0 10px;
	}
	&-body {
		padding: 0;
		margin-bottom: 0;
	}
	&-row {
		padding: 18px 0;
		border-bottom: 1px solid $UILightGray;
		@include flexbox();
		@include align-items(center);
		@include justify-content(space-between);
		p {
			margin-bottom: 0;
			text-align: center;
		}
	}
	&-row:nth-last-child(1) {
		border: none;
	}
	.not-read {
		background-color: #F3FBFF;
	}
	.when-no-response {
		padding: 30px 0;
		text-align: center;
		margin-bottom: 0;
	}
}

@media only screen and (max-width: 991px) {
	.form-response-vacancy {
		input {
			width: calc(39% - 10px);
		}
		> div {
			width: 22%;
			button {
				width: calc(50% - 5px);
			}
		}
	}
	.table-response-vacancy {
		.col1 {
			width: 19%;
		}
		.col2 {
			width: 27%;
		}
		.col3 {
			width: 11%;
		}
	}
}

@media only screen and (max-width: 767px) {
	.table-response-vacancy-header,
	.col2,
	.form-response-vacancy {
		display: none;
	}

	.table-response-vacancy {
		border: none;
		.col1 {
			width: 69%;
			text-align: left;
		}
		.col3 {
			width: 31%;
			padding-left: 0;
		}
		.col4 {
			width: 100%;
		}
		&-row {
			&:nth-child(1){
				border-top: 1px solid #e0e0e0;
			}
			@include flex-wrap(wrap);
			.col4 {
				text-align: left;
				margin-top: 1px;
				color: $textPrimary;
			}
		}
		&-row,
		&-row:nth-last-child(1) {
			border-bottom: 1px solid #e0e0e0;
			//margin-bottom: 10px;
			padding: 10px 0;
		}
	}
	.block-response-vacancy {
		@include flexbox();
		@include flex-direction(column-reverse);
		.form-response-vacancy-show {
			@include flexbox();
			@include flex-wrap(wrap);
			padding: 15px;
			.form-control {
				width: 100%;
				margin-bottom: 20px;
			}
			> div {
				width: 100%;
				@include flexbox();
				@include align-items(center);
				@include justify-content(center);
			}
			button {
				margin: 0 10px;
				width: calc(50% - 5px);
			}
			.btn-close {
				display: inline-block;
				margin: 25px auto 0;
			}
		}
		.btn-show-filter-mob.show {
			@include flexbox();
			z-index: 7;
		}
	}
}

@media only screen and  (max-width: 767px) and (orientation: portrait) {
	.table-response-vacancy {
		&-row,
		&-row:nth-last-child(1) {
			border-top: 1px solid #e0e0e0;
			//border-bottom: 1px solid #e0e0e0;
			margin-bottom: 10px;
			//padding: 10px 0;
			width: 100vw;
			margin-left: -15px;
		}
	}
}

@media only screen and (max-width: 370px) {
	.table-response-vacancy .col3 {
		font-size: 10px;
		line-height: 1.35rem;
	}
}
