.img-text-description {
  @include flexbox();
  @include align-items(flex-start);
  @include justify-content(center);
  margin-bottom: 2.8vh;

  &-last {
    margin-bottom: 0;
  }

  &__text {
    width: 52.8vh;
    padding-top: 3.9vh;
  }

  &__p {
    margin-bottom: 0;
  }

  &__title {
    font-size: 24px;
    text-align: center;
    color: #008AEA;
    margin-bottom: 1.9vh;
  }

  &__img {
    width: auto;

    &__first {
      height: 23.6vh;
      margin-right: 3.8vh;
    }

    &__second {
      height: 23.6vh;
      margin-left: 2.6vh;
    }

    &__third {
      height: 23.6vh;
      margin-right: 3.5vh;
    }
  }
}

@media screen and (orientation: landscape) and (min-width: 175vh) and (max-width: 991px) {
  .img-text-description{
    &__title {
      font-size: 22px;
    }
    &__text {
      width: 78.8vh;
    }
    &__img__first {
      margin-right: 7.8vh;
      height: 44.6vh;
    }
    &__img__second {
      margin-left: 6.6vh;
      height: 40.6vh;
    }
    &__img__third {
      margin-right: 10.5vh;
      height: 37.6vh;
    }
  }
}

@media screen and (orientation: landscape) and (min-width: 200vh) {
  .img-text-description__img{
    &__first {
      margin-right: 18.8vh;
    }
    &__second {
      margin-left: 15.6vh;
    }
    &__third {
      margin-right: 15.5vh;
    }
  }
}

@media screen and (orientation: landscape) and (min-width: 216vh) {
  .img-text-description{
    &__text {
      width: 78.8vh;
    }
    &__img__first {
      margin-right: 7.8vh;
      height: 44.6vh;
    }
    &__img__second {
      margin-left: 6.6vh;
      height: 40.6vh;
    }
    &__img__third {
      margin-right: 10.5vh;
      height: 37.6vh;
    }
  }
}

@media screen and (orientation: landscape) and (max-width: 150vh) {
  .img-text-description {
    &__title {
      font-size: 16px;
    }
    &__p {
      font-size: 13px;
    }
    &__text {
      width: 56.8vh;
    }
    &__img {
      &__first {
        height: 31.6vh;
      }
      &__second {
        height: 31.6vh;
      }
      &__third {
        height: 29.6vh;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (orientation: portrait) {
  .img-text-description__title {
    font-size: 18px;
  }
  .img-text-description__img__third,
  .img-text-description__img__second,
  .img-text-description__img__first {
    height: 19.6vh;
  }
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .img-text-description {
    @include flex-direction(column);
    @include align-items(center);
    margin-bottom: 11.1vw;
    &__text {
      width: 100%;
      padding-top: 0;
      order: 2;
      margin-top: 5vw;
    }

    &__title {
      font-size: 5vw;
      margin-bottom: 5.6vw;
    }
    &__img {
      width: auto;

      &__first {
        height: 40.8vw;
        margin-right: 0;
      }

      &__second {
        height: 39.2vw;
        margin-left: 0;
        order: 1;
      }

      &__third {
        height: 38.3vw;
        margin-right: 0;
      }
    }
  }
}