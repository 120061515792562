footer {
    color: rgba(94, 94, 94, 0.87);
    background: rgb(26, 26, 26);

    .footer-top {
        padding-top: 3rem;
        padding-bottom: 3rem;

        h3 {
            font-size: 16px;
            position: relative;
            margin-bottom: 1rem;
            color: #2581BC;

            &:before {
                content: "";
                position: absolute;
                bottom: -5px;
                left: 0;
                width: 100%;
                border-top: 1px solid rgba(94, 94, 94, 0.87);
            }

            &:after {
                content: "";
                position: absolute;
                bottom: -5px;
                left: 0;
                width: 35%;
                border-top: 1px solid $primary;
            }

        }

        ul {
            padding-left: 17px !important;

            li {
                padding-bottom: .4rem;
                text-indent: -17px;

                &:last-of-type {
                    padding-bottom: 0;
                }

                a {
                    color: rgba(94, 94, 94, 0.87);
                    margin-left: 5px;

                    &:hover {
                        text-decoration: underline;
                    }
                }

                //&::before {
                //    content: "\f054";
                //    font-family: "Font Awesome 5 Free";
                //    margin-right: 5px;
                //    font-weight: 900;
                //}
            }
        }
    }

    .footer-bottom {
        padding: 1rem 0;
        background-color: rgb(22, 22, 22);

        .svg-inline--fa {
            font-size: 20px;
        }

    }

    .footer-brand img {
        width: 147px;
        height: auto;
    }
}

@media only screen and (max-width: 991px) {
    footer .footer-brand img {
        width: 102px;
    }
}