.payment-order-logo-n-date {
  margin-top: 37px;
  margin-bottom: 14px;
  @include flexbox();
  @include align-items(flex-start);
  @include justify-content(flex-start);
}

.payment-order-logo {
  width: 269px;
  margin-right: 184px;

  img {
    width: auto;
    max-width: 100%;
    height: auto;
  }
}

.payment-order-date {
  .payment-order-date__title {
    color: $primary;
    font-size: 25px;
    text-decoration: underline;
    font-weight: 500;
    text-align: left;
    margin-bottom: 12px;
  }

  p {
    color: $textPrimary;
    font-size: 16px;
    margin-bottom: 0;
    text-align: left;

    span {
      font-weight: 500;
      min-width: 140px;
    }
  }
}

.payment-order-seller-customer {
  margin-bottom: 31px;
  @include flexbox();
  @include align-items(flex-start);
  @include justify-content(flex-start);

  .payment-order-seller-customer__title {
    margin-bottom: 10px;
    color: $textPrimary;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    text-decoration: underline;
  }
}

.customer-info,
.seller-info {
  p {
    color: $textPrimary;
    font-size: 14px;
    margin-bottom: 0;
    text-align: left;

    span {
      font-weight: 500;
    }
  }
}

.seller-info {
  width: 255px;
  margin-right: 190px;
}

.customer-info {
  background-color: #F5F7F6;
  padding: 20px 18px;
  width: calc(100% - 445px);
}

.payment-order-table {
  margin-bottom: 20px;
  width: 100%;

  th {
    border: .5px solid #fff;
    text-align: center;
    font-size: 14px;
    color: #fff;
    padding: 13px 18px 10px;
    background-color: #2582BF;
    font-weight: 400;

    &:nth-child(1) {
      width: 54.9%;
    }

    &:nth-child(2) {
      width: 11.8%;
    }

    &:nth-child(3) {
      width: 7.8%;
    }

    &:nth-child(4) {
      width: 7.8%;
    }

    &:nth-child(5) {
      width: 8.5%;
    }

    &:nth-child(6) {
      width: 9.7%;
    }
  }

  td {
    border: .5px solid #fff;
    background-color: #EAF3FA;
    padding: 20px 0;
    text-align: center;
    font-size: 14px;
    color: $textPrimary;

    &:nth-child(1) {
      width: 54.9%;
      padding: 20px 30px;
      text-align: left;
      font-weight: 500;
    }

    &:nth-child(2) {
      width: 11.8%;
    }

    &:nth-child(3) {
      width: 7.8%;
    }

    &:nth-child(4) {
      width: 7.8%;
    }

    &:nth-child(5) {
      width: 8.5%;
    }

    &:nth-child(6) {
      width: 9.7%;
    }
  }

  &-footer {
    width: 100%;

    &__row {
      @include flexbox();
      @include align-items(center);
      @include justify-content(flex-end);
      margin-bottom: 10px;

      p {
        margin-bottom: 0;
        font-size: 14px;

        &:nth-child(1) {
          width: 200px;
          text-align: left;
        }

        &:nth-last-child(1) {
          width: 112px;
          text-align: right;
        }
      }

      &__last {
        width: 100%;
        padding: 10px 0;
        border-top: 1px solid $primary;
        border-bottom: 1px solid $primary;
        margin-bottom: 30px;

        p {
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
  }
}

.already-paid-block {
  @include flexbox();
  @include align-items(center);
  @include justify-content(space-between);

  > p {
    color: #DC3545;
    font-size: 30px;
    line-height: 55px;
    font-weight: bold;
    display: inline-block;
    border: 1px solid #DC3545;
    padding: 0 171px;
    margin-bottom: 0;
  }
}

.stamp-block {
  @include flexbox();
  @include flex-direction(column);
  @include align-items(flex-start);

  > p {
    color: $textPrimary;
    font-size: 14px;
    text-align: left;
    margin-bottom: 15px;
  }

  > img {
    width: 280px;
  }
}

@media only screen and (max-width: 1024px) {
  .payment-order-logo-n-date {
    margin-bottom: 22px;
  }
  .payment-order-logo {
    width: 206px;
    margin-right: 86px;
  }
  .payment-order-date {
    .payment-order-date__title {
      font-size: 24px;
      margin-bottom: 10px;
    }

    p {
      font-size: 14px;
    }
  }
  .seller-info {
    width: 260px;
    margin-right: 15px;
  }
  .customer-info {
    padding: 15px;
    width: calc(100% - 275px);
  }
  .payment-order-seller-customer {
    margin-bottom: 23px;
  }
  .payment-order-table {
    th {
      font-size: 12px;
      padding: 13px 10px;

      &:nth-child(1) {
        width: 36.3%;
      }

      &:nth-child(2) {
        width: 16.7%;
      }

      &:nth-child(3) {
        width: 10.9%;
      }

      &:nth-child(4) {
        width: 10.9%;
      }

      &:nth-child(5) {
        width: 11.7%;
      }

      &:nth-child(6) {
        width: 13.5%;
      }
    }

    td {
      padding: 15px 0;

      &:nth-child(1) {
        width: 36.3%;
        padding: 15px;
      }

      &:nth-child(2) {
        width: 16.7%;
      }

      &:nth-child(3) {
        width: 10.9%;
      }

      &:nth-child(4) {
        width: 10.9%;
      }

      &:nth-child(5) {
        width: 11.7%;
      }

      &:nth-child(6) {
        width: 13.5%;
      }
    }

    &-footer {
      &__row {
        margin-bottom: 8px;

        &__last {
          margin-bottom: 20px;
        }
      }
    }
  }
  .already-paid-block {
    > p {
      font-size: 20px;
      line-height: 50px;
      padding: 0 100px;
    }
  }
}

@media only screen and (max-width: 992px) {
  .payment-order-table {
    th {
      &:nth-child(1) {
        width: 26.1%;
      }

      &:nth-child(2) {
        width: 17.4%;
      }

      &:nth-child(3) {
        width: 10.8%;
      }

      &:nth-child(4) {
        width: 10.6%;
      }

      &:nth-child(5) {
        width: 11.6%;
      }

      &:nth-child(6) {
        width: 13.5%;
      }
    }

    td {
      font-size: 14px;

      &:nth-child(1) {
        width: 26.1%;
      }

      &:nth-child(2) {
        width: 17.4%;
      }

      &:nth-child(3) {
        width: 10.8%;
      }

      &:nth-child(4) {
        width: 10.6%;
      }

      &:nth-child(5) {
        width: 11.6%;
      }

      &:nth-child(6) {
        width: 13.5%;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .customer-info,
  .payment-order-table {
    display: none;
  }
  .payment-order-logo-n-date {
    margin-top: 0;
    margin-bottom: 20px;
    @include flex-direction(row-reverse);
    @include align-items(flex-end);
    @include justify-content(space-between);
  }
  .payment-order-logo {
    width: 108px;
    margin-right: 0;
  }
  .payment-order-date {
    width: calc(100% - 137px);
  }
  .seller-info {
    width: 100%;
    margin-right: 0;
  }
  .payment-order-date {
    .payment-order-date__title {
      font-size: 18px;
    }

    p span {
      min-width: 120px;
    }
  }
  .payment-order-seller-customer {
    margin-bottom: 20px;
  }
  .already-paid-block {
    @include flex-direction(column);
    @include align-items(center);
  }
  .already-paid-block > p {
    font-size: 18px;
    line-height: 44px;
    padding: 0 58px;
    margin-bottom: 15px;
  }
  .tariff-payment-table-row {
    overflow: hidden;
  }
}