.mainFilters {
	opacity: 0;
	-webkit-transition: opacity .6s;
	-moz-transition: opacity .6s;
	-o-transition: opacity .6s;
	transition: opacity .6s;

	&.fast {
		-webkit-transition: opacity .3s;
		-moz-transition: opacity .3s;
		-o-transition: opacity .3s;
		transition: opacity .3s;
	}
}

.language-filter .chosen-container {
	.chosen-choices {
		width: 320px;
		line-height: 28px;
	}
	.chosen-drop {
		width: 320px;
	}
}

.chosen-container {
	width: 100% !important;
	font-size: 14px;
	.chosen-choices {
		list-style: none;
		line-height: 32px;
		border-radius: 4px;
		border: 1px solid #ccc;
		background-image: none;
		box-shadow: none;
		padding: 0px 12px;
		&:after {
			content: "▾";
			color: #787878;
			position: absolute;
			font-size: 20px;
			top: 1.5em;
			right: 1.5em;
		}
		&:focus {
			box-shadow: none;
			outline: none;
		}
	}
	.chosen-results li.highlighted {
		background-image: none;
		background-color: $primary;
	}
	&-multi {
		.chosen-choices li.search-choice {
			margin: 5px 5px 3px 0;
			border: none;
			background-image: none;
			background-color: #e8e8e8;
			border-radius: 4px;
			padding: 5px 20px 5px 5px;
			float: left;
			line-height: 13px;
			position: relative;
			.search-choice-close {
				top: 6px;
			}
		}
		.chosen-choices li.search-field input[type="text"] {
			//max-width: 105px;
			background: transparent !important;
			border: 0 !important;
			color: #55595c;
			margin: 0;
			padding: 0.375rem 0.75rem;
			outline: 0;
			height: 1%;
		}
	}
	&-active {
		.chosen-choices {
			border: $warning 1px solid;
		}
	}
}

.chosen-container .chosen-drop {
	background: $textForDarkElements;
	border: 1px solid #ccc;
	border-bottom-right-radius: 0.25rem;
	border-bottom-left-radius: 0.25rem;
	margin-top: -1px;
	position: absolute;
	top: 100%;
	left: -9000px;
	z-index: 1060;
}

.chosen-container.chosen-with-drop .chosen-drop {
	left: 0;
	right: 0;
}

.chosen-container-multi .chosen-results {
	margin: 0 0 0 0;
	padding: 0;
}

.chosen-container .chosen-results {
	color: #55595c;
	margin: 0 4px 4px 0;
	max-height: 240px;
	padding: 0 0 0 4px;
	position: relative;
	overflow-x: hidden;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
}

.chosen-container-multi .chosen-results {
	margin: 0 0 0 0;
	padding: 0;
}

.chosen-container .chosen-results li.active-result {
	cursor: pointer;
	display: list-item;
}

.chosen-container .chosen-results li {
	display: none;
	line-height: 1.5;
	list-style: none;
	margin: 0;
	padding: 5px 6px;
}

.chosen-container {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	-webkit-user-select: none;
	user-select: none;
}

.google-select {
	.chosen-results {
		&:after {
			content: '';
			display: block;
			width: 100%;
			height: 32px;
			background-image: url('/images/powered_by_google_on_white_hdpi.png');
			background-position: center right;
			background-size: 130px;
			background-repeat: no-repeat;
			position: sticky;
			bottom: 0;
			background-color: $textForDarkElements;
		}
	}
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.chosen-container {
		.chosen-drop {
			width: 166px;
		}
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {}
@media only screen and (min-width: 320px) and (max-width: 767px) {
	.chosen-container {
		.chosen-choices {
			width: 100% !important;
		}
		.chosen-drop {
			width: 100% !important;
		}
	}
}

.choices__list--dropdown {
	z-index: 4;
}