.block-response-vacancy .table-response-replies {
	.table-response-vacancy-row {
		position: relative;
	}
	.col1 {
		width: 20%;
	}
	.col2 {
		width: 18%;
	}
	.col3 {
		width: 19%;
	}
	.col4 {
		width: 38%;
	}
	.col5 {
		width: 5%;
		padding: 0 10px;
	}
	.table-response-vacancy-header p {
		color: $textPrimary;
	}
}

@media only screen and (max-width: 768px) {
	.block-response-vacancy .table-response-replies {
		.col1,
		.col2,
		.col3,
		.col4 {
			width: 88%;
			text-align: left;
			padding: 0 15px;
			margin: 0;
		}
		.col5 {
			width: auto;
			position: absolute;
			right: 0;
			top: 0;
			padding: 0 7px;
		}
		.col3 {
			order: 1;
		}
		.col1 {
			order: 2;
			margin: 5px 0;
		}
		.col4 {
			order: 3;
		}
	}
}