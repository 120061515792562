.card-list {
    .card {
        margin-bottom: -1px;
    }
}

@include media-breakpoint-up(md) {

    .card-deck {
        .card {
            &.card-num-2 {
                flex: 0 0 calc(#{100%/2} - #{$grid-gutter-width}) !important;
            }

            &.card-num-3 {
                flex: 0 0 calc(#{100%/3} - #{$grid-gutter-width}) !important;
            }
        }
    }
}

.card-deck {
    .card {
        &.card-num-3 {

            @include media-breakpoint-down(md) {
                flex: 0 0 calc(#{100%/2} - #{$grid-gutter-width}) !important;
            }

            &.card-3 {
                &:last-of-type {
                    @include media-breakpoint-down(md) {
                        flex: 0 0 calc(#{100%/1} - #{$grid-gutter-width}) !important;
                    }
                }
            }
        }
    }
}

.card-company {
    perspective: 1000px;
    min-height: 113px;
    position:relative;
    .card-company:hover .card-front {
        transform: rotateY(-180deg);
    }

    .card-content {
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);
        transition: all 1.5s;
        position:absolute;
        top: 0;
        left: 8px;
        backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
        border: 1px solid #BDE4FF;
        width: calc(100% - 16px);
        height: 100%;
        background: #fff;
        &__text {
            text-align: center;
            font-size: 12px;
            color: #676767;
            margin: 0;
            font-weight: 500;
        }
        img {
            object-fit: cover;
            width: auto;
            max-width: 130px;
            height: auto;
            max-height: 90px;
        }
    }
    .card-back{
        transform:rotateY(180deg);
        padding: 0 10px;
    }
}

.card-company:hover .card-front {
    transform: rotateY(-180deg);
}

.card-company:hover .card-back {
    transform: rotateY(0);
}
