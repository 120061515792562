.form-block-dropdown-content {
	.choices__list.choices__list--dropdown.is-active {
		z-index: 2
	}
	.choices[data-type*="select-multiple"] select.choices__input {
		display: block !important;
		opacity: 0;
		pointer-events: none;
		position: absolute;
		left: 0;
		bottom: 0;
	}
}