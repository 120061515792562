.types-work-permit {
	margin-bottom: 10px;
	label {
		color: $textPrimary;
	}
	.form-check-input {
		width: 20px;
		height: 20px;
		margin-top: 0;
		background-color: #F0F0F0;
		border-radius: 0;
	}
}

.cv-photo-content {
	@include flexbox();
	@include align-items(flex-start);
	@include justify-content(space-between);
	margin-bottom: 15px;
}

.cv-photo-add {
	width: 100%;
	max-width: 227px;
	p {
		font-size: 10px;
	}
}

.cv-photo-wrap {
	width: 100%;
	height: auto;
	margin-bottom: 15px;
	position: relative;
	img {
		width: 100%;
		height: auto;
	}
	.btn-close-img {
		position: absolute;
		top: 0;
		right: 0;
		cursor: pointer;
		background-color: rgba(0, 139, 235, .3);
		width: 30px;
		height: 30px;
		@include flexbox();
		@include align-items(center);
		@include justify-content(center);
		i {
			font-size: 14px;
			color: #fff;
		}
	}
}

.cv-content {
	width: calc(100% - 242px);
}

.pt-title-js {
	padding-top: 13px;
}

.new-cv-add-page {
	position: relative;
	.form-control {
		color: $textPrimary;
		&::-webkit-input-placeholder {
			color: $secondary;
		}
		&::-moz-placeholder {
			color: $secondary;
		}
		&:-ms-input-placeholder {
			color: $secondary;
		}
		&:-moz-placeholder {
			color: $secondary;
		}
	}
	.wrap-form-lang-select {
		width: calc(50% - 10px);
		//border: 1px solid $UILightGray;
		.form-control {
			//width: calc(50% - 10px);
			//border: none;
			//width: calc(100% - 7px);
			color: #062336;
		}
	}
	.btn-control-lang{
		padding: 0 10px;
		height: calc(1.5em + 0.75rem + 2px);
		@include flexbox();
		@include align-items(center);
		@include justify-content(center);
	}
	.jobseeker-lang-row {
		@include flexbox();
		@include align-items(center);
		@include justify-content(space-between);
	}
	.btn-add-languages-block {
		border: none;
		background: none;
		color: $primary;
		font-size: 14px;
		&:focus {
			outline: none;
		}
	}
	.work-experience-block {
		width: 100%;
		textarea {
			height: 100px;
			padding: 10px;
		}
	}
	.block-month-year {
		width: 100%;
		max-width: 340px;
		@include flexbox();
		@include align-items(center);
		@include justify-content(space-between);
		margin-bottom: 13px;
		input {
			width: calc(50% - 10px);
		}
	}
	.custom-checkbox {
		position: absolute;
		z-index: -1;
		opacity: 0;
	}
	.custom-checkbox + label::before {
		content: '';
		display: inline-block;
		width: 20px;
		height: 20px;
		flex-shrink: 0;
		flex-grow: 0;
		border: 1px solid $UILightGray;
		margin-right: 10px;
		background-color: #F0F0F0;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 50% 50%;
		top: 4px;
		position: relative;
	}
	.custom-checkbox:checked + label::before {
		border-color: #0074F0;
		background-color: #0074F0;
		background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
		background-size: 13px 13px;
	}
	.work-experience-result {
		&-block {
			margin-top: -10px;
		}
		&-wrap {
			border: 1px solid #F3F3F3;
			background-color: #F3F3F3;
			padding: 10px;
			position: relative;
		}
		&-title {
			font-weight: 500;
			width: 80%;
			margin-bottom: 10px;
		}
		&-description,
		&-autor {
			margin-bottom: 10px;
		}
		&-date {
			margin-bottom: 0;
		}
		&-btns {
			position: absolute;
			right: 10px;
			top: 10px;
			@include flexbox();
			@include align-items(center);
		}
	}
	.fa-times{
		font-size: 16px;
		color: #6C757D;
		cursor: pointer;
	}
	.fa-pen{
		cursor: pointer;
		font-size: 11px;
		color: #6C757D;
		margin-right: 15px;
	}
	.form-field-title {
		line-height: 18px;
	}
	.edit-block {
		border-color: #008BEB;
	}
}

.text-normal {
	font-weight: 400;
}

@media only screen and (max-width: 991px) {
	.cv-photo-add {
		max-width: 170px;
	}
	.cv-content {
		width: calc(100% - 180px);
	}
	.new-cv-add-page {
		.new-job-add-block-wrap-title {
			display: inline-block;
		}
		.work-experience-result-block {
			margin-top: 0;
		}
		.btn--browse {
			width: 54px;
		}
		.field-add-file {
			margin-bottom: 0;
		}
		.new-cv-pb{
			padding-bottom: 0;
			&:before {
				display: none;
			}
		}
		.hide-mob {
			display: none;
		}
	}
}

@media only screen and (max-width: 767px) and (orientation: landscape) {
	.cv-photo-add {
		max-width: 177px;
	}
	.cv-content {
		width: calc(100% - 190px);
	}
}

@media only screen and (max-width: 560px) {
	.cv-photo-add {
		max-width: 227px;
		margin: 0 auto 15px;
	}
	.cv-content {
		width: 100%;
	}
	.cv-photo-content {
		@include flex-direction(column);
	}
}