.block-employers-banner{
  .container{
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .img-wrap{
    width: 18%;
    max-width: 211px;
    margin-right: 13%;
  }
  h2{
    width: 47%;
    font-size: 33px;
    color: #419DE4;
    text-align: center;
    span{
      font-weight: 500;
    }
  }
}

@media only screen and (max-width: 1199px) {
  .block-employers-banner {
    .img-wrap{
      width: 20%;
      margin-right: 10%;
    }
    h2 {
      font-size: 20px;
    }
  }
}

@media only screen and (max-width: 767px)  {
  .block-employers-banner {
    .img-wrap{
      width: 25%;
    }
    h2 {
      font-size: 18px;
    }
    .img-wrap{
      width: 45%;
      margin-right: 0;
    }
  }
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .block-employers-banner {
    .container {
      padding-top: 55px;
      padding-bottom: 55px;
      @include flex-direction(column);
    }
    h2{
      width: 100%;
    }
  }
}