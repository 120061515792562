.popular-queries {
	padding-top: 35px;
}

.popular-queries-title{
	margin-bottom: 24px;
}

@media (max-width: 767px) {
	.popular-queries{
		padding-top: 30px;
		&-title {
			margin-bottom: 18px;
			font-size: 18px;
		}
		.popular-vacancies-title-color {
			color: #008AEA;
		}
	}
}