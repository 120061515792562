.rate-detail-content {
	@include flexbox();
	@include align-items(flex-start);
	.service-card {
		margin-right: 60px;
		width: 280px;
		li p {
			line-height: 2;
		}
	}
}

.rate-detail-list {
	padding: 0;
	margin-bottom: 96px;
	li {
		@include flexbox();
		margin-bottom: 20px;
		p {
			margin-bottom: 0;
		}
	}
	.rate-detail-title {
		width: 385px;
	}
}

.rates-list-content {
	.table-response-vacancy {
		.col1 {
			width: 32%;
			text-align: left;
			padding-left: 15px;
		}
		.col2 {
			width: 12%;
			text-align: center;
			color: $textPrimary;
		}
		.col3 {
			width: 28%;
			color: $textPrimary;
		}
		.col4 {
			width: 15%;
			color: $textPrimary;
		}
		.col5 {
			width: 13%;
			text-align: center;
		}
		&-row {
			padding: 25px 0;
			@include align-items(center);
			.col1 {
				padding-left: 20px;
			}
			.col3,
			.col4 {
				color: #676767;
				.text-for-mob{
					display: none;
				}
			}
		}
	}
	.show {
		.btn-rate-toggle {
			border: 1px solid #008AEA;
			color: #008AEA;
		}
	}
	.btn-rate-toggle {
		border: 1px solid $textPrimary;
		color: $textPrimary;
		background: none;
		padding: 4px 8px;
		&:hover,
		&:focus {
			border: 1px solid #008AEA;
			color: #008AEA;
			outline: none;
		}
		.fa-caret-down {
			margin-left: 8px;
		}
		&-active-status {
			border: 1px solid #008AEA;
			color: #008AEA;
		}
	}
	.btn-rate-toggle-disabled {
		border: 1px solid #A0A6AB;
		color: #A0A6AB;
		background: none;
		padding: 4px 16px;
		&:hover,
		&:focus {
			border: 1px solid #A0A6AB;
			color: #A0A6AB;
			outline: none;
		}
	}
	.not-active {
		&:hover,
		&:focus {
			border: 1px solid #A0A6AB;
			color: #A0A6AB;
			outline: none;
		}
	}
}

.rates-label {
	border-radius: 3px;
	border: 1px solid $textPrimary;
	color: $textPrimary;
	padding: 4px 12px;
	display: inline-block;
	&-not-paid {
		color: $danger;
		border-color: $danger;
	}
	&-canceled {
		color: #6F7273;
		border-color: #6F7273;
	}
	&-active {
		color: #00B955;
		border-color: #00B955;
	}
}

.modal-body {
	padding-top: 2rem;
}

.modal-footer {
	padding-bottom: 1.5rem;
}

@media only screen and (max-width: 1024px) and (orientation: landscape) {
	.rates-list-content {
		.table-response-vacancy {
			.col1 {
				width: 30%;
				padding-left: 10px;
			}
			.col2 {
				width: 15%;
			}
			.col4 {
				width: 18%;
			}
		}
	}
}

@media only screen and (orientation: portrait) {
	.rate-detail-list .rate-detail-title {
		width: 270px;
	}
	.rate-detail-content .service-card {
		margin-right: 30px;
	}
	.rates-list-content {
		.table-response-vacancy {
			.col1 {
				width: 25%;
				padding-left: 10px;
			}
			.col2 {
				width: 20%;
			}
			.col3 {
				width: 22%;
			}
			.col4 {
				width: 23%;
			}
		}
	}
}

@media only screen and (max-width: 767px) {
	.rates-list-content {
		.table-response-vacancy-row {
			@include align-items(baseline);
			padding: 25px 10px;
			.col1 {
				padding-left: 0;
				width: 50%;
				order: 1;
			}
			.col2 {
				display: inline-block;
				width: 50%;
				padding: 0;
				order: 2;
				margin-bottom: 10px;
				text-align: right;
			}
			.col3 {
				width: 100%;
				order: 5;
				text-align: left;
				font-size: 13px;
				.text-for-mob{
					display: inline-block;
					margin-right: 10px;
				}
			}
			.col4 {
				width: 50%;
				padding: 0;
				order: 3;
			}
			.col5 {
				order: 4;
				width: 50%;
				text-align: right;
			}
		}
	}
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
	.rate-detail-content {
		@include flex-direction(column);
		@include align-items(center);
		.service-card {
			margin-right: 0;
			width: 100%;
			max-width: 280px;
		}
	}
	.rate-detail-list {
		margin-bottom: 15px;
		li {
			margin-bottom: 15px;
		}
		.rate-detail-title {
			width: 200px;
		}
	}
}

@media only screen and (min-width: 751px) and (max-width: 850px) and (max-height: 500px) and (orientation: landscape) {
	.rates-list-content {
		.table-response-vacancy {
			.col1 {
				width: 25%;
				padding-left: 10px;
			}
			.col2 {
				width: 20%;
			}
			.col3 {
				width: 22%;
			}
			.col4 {
				width: 20%;
			}
			.col5 {
				width: 14%;
			}
		}
		.btn-rate-toggle {
			padding: 4px 12px;
		}
	}
}

@media only screen and (max-height: 500px) and (orientation: landscape) {
	.rate-detail-content {
		@include flex-direction(column);
		@include align-items(center);
		.service-card {
			margin-right: 0;
			width: 100%;
			max-width: 280px;
		}
	}
	.rate-detail-list {
		margin-bottom: 15px;
		li {
			margin-bottom: 15px;
		}
		.rate-detail-title {
			width: 200px;
		}
	}
}