.email-us-form {
  -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  padding: 3.8vh 4.6vh 4.6vh;
  width: 52.8vh;
  margin: 0 auto;
  @include flexbox();
  @include align-items(center);
  @include flex-direction(column);

  &__title {
    font-size: 3.2vh;
    font-weight: 500;
    text-align: center;
    margin-bottom: 6.6vh;
  }
  &__item {
    display: inline-block;
    vertical-align: top;
    position: relative;
    width: 100%;
    margin-bottom: 6.5vh;
    &__margin-b {
      margin-bottom: 4.4vh;
    }
  }
  &__input {
    width: 100%;
    padding: 0;
    border: none;
    border-bottom: 1px solid #EAEAEA;
    font-size: 14px;
    line-height: 26px;
    &:focus{
      outline: none;
    }

    &:not(:focus):valid~.email-us-form__label,
    &:focus~.email-us-form__label {
      top: -15px;
      font-size: 10px;
    }
  }
  &__textarea {
    height: 27px;
    resize: none;
    &:focus{
      height: 126px;
    }
  }
  &__label {
    position: absolute;
    pointer-events: none;
    left: 0;
    top: -9px;
    white-space: nowrap;
    overflow: hidden;
    line-height: 16px;
    transition: .3s;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0;
  }
}

@media screen and (orientation: landscape) and (min-width: 175vh) and (max-width: 991px) {
  .email-us-form {
    width: 108.8vh;
    &__title {
      font-size: 6.2vh;
    }
  }
}

@media screen and (orientation: landscape) and (max-width: 150vh) {
  .email-us-form {
    width: 99.8vh;
    &__title {
      font-size: 6.2vh;
    }
  }
}

@media only screen and (min-width: 768px) and (orientation: portrait) {
  .email-us-form__title {
    font-size: 2.6vh;
    margin-bottom: 3.6vh;
  }
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .email-us-form {
    padding: 0 4.2vw;
    width: 100%;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    &__title {
      font-size: 5vw;
      margin-bottom: 9.8vw;
    }
    &__item {
      margin-bottom: 5.5vh;
      &__margin-b {
        margin-bottom: 4.4vh;
      }
    }
  }
}