.landing-page {
  &-title {
    font-size: 30px;
    font-weight: 500;
    color: #116CAC;
    margin-bottom: 0;
    text-align: center;
    text-transform: uppercase;
    z-index: 1;
    position: relative;
  }

  &-subtitle {
    font-size: 28px;
    color: #116CAC;
    margin-bottom: 30px;
    margin-top: -7px;
    text-align: center;
    z-index: 1;
    position: relative;
  }

  &-header {
    background-color: #E0F2FF;
    position: relative;

    &:before {
      content: '';
      width: 100%;
      height: 116px;
      background-color: #fff;
      position: absolute;
      left: 0;
      bottom: 0;
    }

    &-img {
      height: 389px;
      width: auto;
      position: relative;
      left: -52px;
    }

    &-icon {
      height: 134px;
      width: auto;
      position: absolute;
      right: 215px;
      top: 95px;
    }

    .container {
      position: relative;

      &:before {
        content: '';
        width: 845px;
        height: calc(100% - 116px);
        background-color: #fff;
        position: absolute;
        right: 73px;
        top: 0;
        @include transform(skewX(-30deg));
      }
    }
  }

  &-block-info {
    padding-bottom: 50px;

    .text-description {
      margin: 50px auto 0;
      width: 81%;
      max-width: 947px;

      p {
        font-size: 18px;
        color: #444444;
        margin-bottom: 0;
        text-align: center;
      }
    }
  }

  &-visas-list {
    ul {
      padding: 0;
      @include flexbox();
      @include flex-wrap(wrap);
      @include justify-content(space-between);
    }

    li {
      margin-bottom: 20px;
      width: calc(50% - 10px);
      border: .33px solid $primary;
      padding: 20px;
      min-height: 320px;

      &:nth-child(odd) {
        margin-right: 20px;
      }
    }

    .visas-list {
      &-img {
        margin-bottom: 23px;
        width: 55px;
        height: auto;
      }

      &-title {
        margin-bottom: 33px;
        font-size: 28px;
        color: $primary;
      }

      &-subtitle {
        margin-bottom: 0;
        font-size: 14px;
      }
    }
  }

  &-field-activity-block {
    padding: 30px 0 25px;

    ul {
      padding: 0;
      max-width: 732px;
      width: 100%;
      margin: 50px auto 0;
      @include flexbox();
      @include flex-wrap(wrap);

      li {
        margin-bottom: 30px;
        width: calc(33.3% - 20px);
        height: 149px;
        margin-right: 30px;
        position: relative;
        overflow: hidden;

        &:nth-child(3n+3) {
          margin-right: 0;
        }

        &:hover {
          .hover-block {
            opacity: 1;
          }
        }

        img {
          width: 100%;
          height: auto;
          max-height: 100%;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }

    .hover-block {
      background-color: rgba(8, 101, 197, .49);
      @include flexbox();
      @include align-items(center);
      @include justify-content(center);
      font-size: 16px;
      font-weight: 500;
      color: #fff;
      text-decoration: none;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }

  &-full-information {
    background-color: #E0F2FF;
    position: relative;
    padding: 41px 0 0;

    &:before {
      content: '';
      width: 100%;
      height: 41px;
      background-color: #fff;
      position: absolute;
      left: 0;
      top: 0;
    }

    .container {
      position: relative;
      padding-top: 50px;

      &:before {
        content: '';
        width: 938px;
        height: 100%;
        background-color: #fff;
        position: absolute;
        right: 112px;
        top: 0;
        @include transform(skewX(-20deg));
      }
    }

    img {
      height: 441px;
      width: auto;
      position: absolute;
      left: -15px;
      bottom: 0;
    }

    .managers-list {
      padding: 0;
      @include flexbox();
      @include flex-wrap(wrap);
      @include align-items(center);
      @include justify-content(center);
      width: 100%;
      max-width: 537px;
      z-index: 1;
      position: relative;
      margin: auto;

      li {
        border: .33px solid $primary;
        margin: 0 24px 40px;
        padding: 17px 40px;
      }
    }

    .phone {
      font-weight: 500;
      color: $primary;
      font-size: 16px;
      margin-bottom: 0;

      &:hover {
        text-decoration: none;
      }
    }

    .name {
      font-size: 14px;
      color: $primary;
      margin-bottom: 0;
    }
  }

  &-our-partners {
    padding-top: 50px;

    .partners-list {
      margin-top: 50px;
      margin-bottom: 0;
      padding: 0;
      @include flexbox();
      @include flex-wrap(wrap);

      li {
        border: .33px solid $primary;
        margin: 0 14px 20px 0;
        padding: 0 10px;
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);
        width: calc(12.5% - 12.3px);
        height: 57px;

        &:nth-child(8n+8) {
          margin-right: 0;
        }

        img {
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 1400px) {
  .landing-page {
    &-title {
      font-size: 25px;
    }

    &-header-img {
      height: 315px;
      left: 76px;
    }

    &-header-icon {
      height: 95px;
      width: auto;
      position: absolute;
      right: 227px;
      top: 95px;
    }

    &-block-info .text-description p {
      font-size: 16px;
    }

    &-visas-list {
      &-img {
        margin-bottom: 15px;
        width: 50px;
      }

      &-title {
        margin-bottom: 30px;
        font-size: 25px;
      }

      li {
        min-height: 280px;
      }
    }
  }
}

@media only screen and (max-width: 1199px) {
  .landing-page {
    &-title {
      font-size: 27px;
    }

    &-header {
      &-img {
        height: 316px;
      }

      &-icon {
        height: 92px;
        top: 65px;
      }

      .container:before {
        width: 711px;
      }
    }

    &-visas-list {
      .visas-list-title {
        margin-bottom: 10px;
        font-size: 20px;
      }

      li {
        min-height: 290px;
      }
    }

    &-full-information img {
      height: 407px;
    }
  }
}

@media only screen and (max-width: 991px) {
  .landing-page {
    &-header {
      background-color: #fff;

      &-img {
        height: 179px;
        left: 0;
      }

      &-icon {
        height: 76px;
        right: 0;
        top: -10px;
        position: relative;
      }

      .container {
        @include flexbox();
        @include flex-direction(column);
        @include align-items(center);

        &:before {
          display: none;
        }
      }
    }

    &-our-partners .partners-list li {
      width: calc(14.2% - 12px);

      &:nth-child(8n+8) {
        margin-right: 14px;
      }

      &:nth-child(7n+7) {
        margin-right: 0;
      }
    }

    &-full-information {
      background-color: #fff;
    }
  }
  .landing-page-header .container:before,
  .landing-page-full-information img,
  .landing-page-full-information .container:before,
  .landing-page-full-information:before {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .landing-page {
    &-title {
      font-size: 20px;
    }

    &-subtitle {
      font-size: 16px;
    }

    &-block-info {
      padding-bottom: 30px;

      .text-description {
        margin: 30px auto 0;
        width: 100%;

        p {
          font-size: 14px;

          &:nth-child(1) {
            margin-bottom: 20px;
          }
        }
      }
    }

    &-visas-list {
      padding: 0 0 10px;

      .visas-list-img {
        margin-bottom: 20px;
        width: 32px;
      }

      .visas-list-title {
        margin-bottom: 0;
      }

      ul {
        margin-bottom: 0;
      }

      li {
        width: 100%;
        padding: 20px 15px;
        min-height: auto;

        &:nth-child(odd) {
          margin-right: 0;
        }
      }
    }

    &-field-activity-block {
      padding: 0 0 10px;

      ul {
        margin-top: 30px;

        li {
          margin-bottom: 20px;
          height: 103px;
        }
      }
    }

    &-full-information {
      padding: 0;

      .container {
        padding-top: 0;
      }

      &-subtitle {
        font-size: 16px;
        margin-top: -7px;
      }

      .managers-list li {
        margin: 0 5px 10px;
      }
    }

    &-our-partners {
      padding-top: 10px;

      .partners-list {
        margin-top: 30px;

        li {
          width: calc(20% - 12px);
          margin-right: 10px;

          &:nth-child(8n+8) {
            margin-right: 10px;
          }

          &:nth-child(7n+7) {
            margin-right: 10px;
          }

          &:nth-child(5n+5) {
            margin-right: 0;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .landing-page {
    &-visas-list {
      .container {
        padding-right: 0;
        padding-left: 0;
        width: 100vw;
      }

      li {
        border-right: none;
        border-left: none;
      }
    }

    &-field-activity-block {
      ul {
        @include flex-direction(column);
        @include align-items(center);

        li {
          width: 224px;
          height: 149px;
          margin-right: 0;
        }
      }

    }

    &-full-information {
      .managers-list {
        @include flex-direction(column);

        li {
          margin: 0 0 20px;
          padding: 20px 45px;
        }
      }

      .name,
      .phone {
        text-align: center;
      }
    }

    &-our-partners .partners-list {
      @include justify-content(center);

      li {
        width: 125px;
        margin-right: 20px;
        margin-bottom: 10px;

        &:nth-child(5n+5) {
          margin-right: 20px;
        }

        &:nth-child(8n+8) {
          margin-right: 20px;
        }

        &:nth-child(7n+7) {
          margin-right: 20px;
        }
      }
    }
  }
}
