.rates-list-content {
  .card {
    border: 1px solid #008BEB;

    &-header {
      padding: 12px 20px;
      background: none;
      border-bottom: none;

      &__open {
        background: #F6F6F6;
      }

      .package-name {
        cursor: pointer;

        u {
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    &-body {
      padding: 0;
    }
  }

  .services-order-item {
    padding: 10px 20px;

    &__title {
      color: #40505F;
    }

    &__desk {
      font-size: 13px;
      color: $secondary;
      margin-bottom: 0;
      width: 97%;
    }

    > div {
      &:nth-child(1) {
        width: calc(100% - 85px);
      }

      &:nth-child(2) {
        width: 85px;
        text-align: right;
      }
    }
  }

  .services-orders-footer {
    padding: 20px;
    @include flexbox();
    @include align-items(center);
    @include justify-content(space-between);

    > div {
      width: 70%;
      @include flexbox();
      @include align-items(center);
      @include justify-content(flex-end);

      > .btn {
        margin-left: 10px;
      }
    }
  }

  .btn-services {
    &-icon {
      display: none;
      width: auto;
    }

    &-payments {
      margin-left: 10px;
    }
  }
}

.detail-btn-services {
  margin-bottom: 0;
  color: $secondary;
  font-size: 12px;
  display: none;

  .fa-angle-down {
    color: $secondary;
    font-size: 11px;
    margin-left: 3px;
  }
}

.edit-service-modal {
  button.close {
    padding: 0;
    margin: 0;
    right: 7px;
    top: 7px;
    color: #E0E0E0;
  }

  .modal-body {
    padding: 46px 0 0;
  }

  .modal-footer {
    padding: 0 30px 20px;
  }

  .service-detail-counter {
    @include flexbox();
    @include align-items(center);
    @include justify-content(space-between);
    padding: 10px 30px;
    border-bottom: 1px solid $UILightGray;

    .service-total-block {
      min-width: 78px;
      text-align: right;
    }
  }

  .service-modal-detail {
    margin-right: 15px;
    width: 290px;

    p {
      margin-bottom: 10px;
      font-size: 13px;
      color: #6C757D;
      text-align: left;

      &:nth-child(1) {
        margin-bottom: 8px;
        font-size: 14px;
        color: $textPrimary;
      }
    }
  }
}

.no-services {
  text-align: center;

  img {
    width: 251px;
    margin-bottom: 10px;
  }

  p {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 991px) {
  .rates-list-content {
    .card {
      &-header {
        padding: 12px 15px;

        &[aria-expanded="true"] {
          padding: 17px 15px;
        }
      }
    }

    .services-order-item {
      padding: 10px 15px;

      &__desk {
        width: 94%;
      }
    }

    .services-orders-footer {
      padding: 20px;
      @include flexbox();
      @include align-items(center);
      @include justify-content(space-between);

      > div {
        width: 68%;

        > .btn {
          margin-left: 10px;
        }

        .btn {
          font-size: 12px;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .rates-list-content {
    .btn-services {
      padding-left: 5px;
      padding-right: 5px;
      width: 37px;

      &-icon {
        display: inline-block;
      }

      &-info {
        .btn-services-icon {
          height: 15px;
        }
      }

      &-payments {
        .btn-services-icon {
          height: 18px;
        }
      }

      span {
        display: none;
      }
    }

    .services-date-btns {
      @include flex-direction(column);
      webkit-align-items: flex-end !important;
      -moz-align-items: flex-end !important;
      -ms-align-items: flex-end !important;
      align-items: flex-end !important;

      > span {
        font-size: 12px;
      }

      > div {
        margin-top: 10px;
      }
    }

    .package-name {
      margin-bottom: 14px;
    }

    .card {
      border: none;
      -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
      -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);

      &-header {
        padding: 15px;
      }
    }

    .services-orders-footer {
      padding: 15px 16px;

      > div {
        width: 53%;

        .btn {
          width: 33px;
          padding-left: 0;
          padding-right: 0;
        }
      }
    }

    .services-order-item__desk {
      display: none;
    }
  }

  .detail-btn-services {
    display: inline-block;
  }

  .detail-btn-services-is-checked {
    .detail-btn-services {
      .fa-angle-down {
        transform: rotate(180deg);
        @include rotate(180);
      }
    }

    .services-order-item__desk {
      display: inline-block;
      margin-top: 8px;
    }
  }
  .tariff-payment-title {
    padding-left: 0;
    padding-right: 0;
  }
  .edit-service-modal {
    .modal-dialog {
      width: 91%;
    }

    .modal-footer {
      padding: 0 20px 20px;
    }

    .service-modal-detail {
      padding-right: 0;
      width: 100%;
    }

    div.d-flex.align-items-center.justify-content-between {
      width: 100%;
    }
  }
}

@media (min-width: 576px) {
  .edit-service-modal .modal-dialog {
    max-width: 545px;
  }
}

@media (max-width: 575px) {
  .edit-service-modal {
    button.close span {
      font-size: 15px;
    }

    .modal-body {
      padding: 44px 0 0;
    }

    .service-detail-counter {
      padding: 0 20px 15px;
      @include flex-direction(column);
      @include align-items(flex-start);
    }

    .service-modal-detail {
      p {
        display: none;
      }

      p:nth-child(1) {
        margin-bottom: 10px;
        display: inline-block;
      }
    }

    .counterwrap-modal {
      width: 100%;
    }
  }
}