.guideline-wrap {
	min-height: 100vh;
	@include flexbox();
	.side-bar {
		border: 1px solid #707070;
		background-color: $textPrimary;
		width: 300px;
		&-logo {
			padding: 20px 0;
			text-align: center;
			border-bottom: 1px solid #707070;
			position: relative;
			img {
				width: 185px;
				height: auto;
			}
		}
		.nav-tabs {
			@include flexbox();
			@include flex-direction(column);
			@include align-items(flex-start);
			border: none;
			padding-top: 34px;
			.nav-link {
				padding: 10px 50px;
				width: 100%;
				font-size: 16px;
				color: $secondary;
				border-radius: 0;
				&.active {
					color: #fff;
					background: #394C60;
					border-bottom: none;
				}
			}
		}
	}
	.tab-content {
		width: calc(100% - 300px);
		.tab-pane {
			padding: 85px 10px 80px 268px;
		}
		&-page-title {
			font-size: 30px;
			font-weight: bold;
			color: $secondary;
			margin-bottom: 50px;
		}
	}
	.logo-template-line {
		@include flexbox();
		@include align-items(center);
		@include justify-content(flex-start);
		margin-bottom: 56px;
	}
	.block-position {
		background-color: #F7F7F7;
		@include flexbox();
		@include align-items(center);
		@include justify-content(center);
		width: 90px;
		height: 90px;
		margin-right: 8px;
		p {
			margin: 0;
			color: #868686;
		}
	}
	.block-logo-template-mob {
		@include flexbox();
		@include flex-direction(column);
		@include align-items(center);
		margin-right: 70px;
		p {
			margin: 0;
			color: #868686;
		}
	}
	.logo-template-mob {
		width: 188px;
		height: 90px;
		@include flexbox();
		@include align-items(center);
		@include justify-content(center);
		margin: 15px 0 10px;
		img {
			width: 128px;
			height: auto;
		}
	}
	.block-logo-template-desc {
		margin-right: 0;
		.logo-template-mob {
			width: 264px;
			img {
				width: 185px;
			}
		}
	}
	.logo-template-line-footer {
		margin-bottom: 0;
		.logo-template-mob {
			img {
				width: 102px;
			}
		}
		.block-logo-template-desc {
			.logo-template-mob {
				img {
					width: 147px;
				}
			}
		}
	}
	.desc-invis {
		visibility: hidden;
	}
	.menu-icon {
		position: absolute;
		left: 20px;
		top: 50%;
		@include translate(0, -50%);
		padding: 0.3rem;
		cursor: pointer;
		color: inherit;
		background-color: transparent;
		border: 0;
		margin: 0;
		overflow: visible;
		z-index: 2;
		display: none;
		&:focus {
			outline: none;
		}
	}
	.menu-icon-box {
		width: 26px;
		height: 26px;
		display: inline-block;
		position: relative;
	}
	.menu-icon-inner {
		display: block;
		top: 50%;
		margin-top: -2px;
	}
	.menu-icon-inner,
	.menu-icon-inner::before,
	.menu-icon-inner::after {
		position: absolute;
		width: 100%;
		height: 3px;
		background-color: #fff;
	}
	.menu-icon-inner::before,
	.menu-icon-inner::after {
		content: "";
		display: block;
	}
	.menu-icon-inner::before {
		top: -8px;
	}
	.menu-icon-inner::after {
		bottom: -8px;
	}
}

@media only screen and (max-width: 1439px) {
	.guideline-wrap .tab-content .tab-pane {
		padding-left: 50px;
	}
}

@media only screen and (max-width: 1024px) {
	.guideline-wrap .tab-content .tab-pane {
		padding-left: 30px;
	}
}

@media only screen and (max-width: 992px) {
	.guideline-wrap {
		.menu-icon {
			@include flexbox();
		}
		.side-bar {
			border: none;
			.nav-tabs {
				position: absolute;
				top: 68px;
				left: 0;
				right: 0;
				overflow-y: auto;
				overflow-x: hidden;
				z-index: 2;
				background-color: $textPrimary;
				@include translate(-100%, 0);
				-webkit-transition: -webkit-transform 300ms cubic-bezier(.2, 0, .2, 1);
				transition: -webkit-transform 300ms cubic-bezier(.2, 0, .2, 1);
				-o-transition: transform 300ms cubic-bezier(.2, 0, .2, 1);
				transition: transform 300ms cubic-bezier(.2, 0, .2, 1);
				transition: transform 300ms cubic-bezier(.2, 0, .2, 1),
				-webkit-transform 300ms cubic-bezier(.2, 0, .2, 1);
				&.menu-active {
					@include translate(0, 0);
					//position: absolute;
				}
				.nav-link {
					padding: 20px 50px;
				}
			}
		}
		.logo-template-line {
			@include flex-direction(column);
			@include align-items(flex-start);
		}
		.block-logo-template-mob {
			margin: 0;
		}
		.block-logo-template-desc {
			margin-top: 10px;
		}
		.block-position {
			margin-bottom: 10px;
		}
		.desc-invis {
			visibility: visible;
		}
		.tab-content-page-title {
			margin-bottom: 30px;
		}
	}
}

@media only screen and (max-width: 992px) {
	.guideline-wrap {
		@include flex-direction(column);
		.side-bar,
		.tab-content {
			width: 100%;
		}
		.tab-content .tab-pane {
			padding-top: 20px;
			padding-bottom: 15px;
		}
		.side-bar-logo img {
			width: 135px;
		}
	}
}