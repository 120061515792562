.company {
  &-stars {
    cursor: default;
    text-align: left;
    float: left;

    &-item {
      padding-left: 3px;

      .company-rating {
        font-size: 12px;
        background-color: #D6D6D6;
      }

      .company-rating-active {
        font-size: 12px;
        background-color: #2581bc;
      }
    }

    > fieldset {
      border: none;
      display: inline-block;
      direction: rtl;

      &:not(:checked) {
        > input {
          position: absolute;
          top: 0px;
          clip: rect(0, 0, 0, 0);
        }

        > label {
          float: right;
          width: 24px;
          cursor: pointer;

          &:hover i,
          &:hover ~ label i {
            background-color: #2581bc;
          }
        }
      }

      > input:checked {
        & ~ label i {
          background-color: #2581bc;
        }
      }

      > label:active {
        position: relative;
        top: 2px;
      }
    }
  }

  &-addreview {
    padding: 20px;

    label {
      font-weight: 400;
      margin-bottom: 1px;
    }

    .form-group {
      margin-bottom: 12px;
    }

    .form-control {
      //height: 36px;
    }

    &-info, &-positive, &-negative {
      padding: 4px 16px;
    }

    &-info {
      background-color: #EBF6FE;
    }

    &-positive {
      background-color: #EBFEEE;
    }

    &-negative {
      background-color: #FEECEB;
    }

    &-spanyes {
      background-color: #0CAA41;
      color: #ffffff;
      padding: 3px 7px;
      border-radius: 3px;
      min-width: 46px;
      display: inline-block;
      text-align: center;

      &:hover {
        color: #ffffff;
        text-decoration: none;
      }
    }

    &-spanno {
      color: #ffffff;
      background-color: #D80614;
      padding: 3px 9px;
      border-radius: 3px;
      min-width: 46px;
      display: inline-block;
      text-align: center;

      &:hover {
        color: #ffffff;
        text-decoration: none;
      }
    }

    &-buttonyes {
      color: #36C004;

      padding: 2px 15px;
      display: inline-block;
      text-align: center;
      border: 1px solid #36C004;

      &-selected {
        background-color: #F0F0F0;
        color: #36C004;

        padding: 2px 15px;
        display: inline-block;
        text-align: center;
      }
    }

    &-buttonno {
      color: #D80614;
      //border-radius: 3px;
      padding: 2px 15px;
      display: inline-block;
      text-align: center;
      border: 1px solid #D80614;

      &-selected {
        background-color: #F0F0F0;
        color: #D80614;
        //border-radius: 3px;
        padding: 2px 15px;
        display: inline-block;
        text-align: center;
      }
    }

    &-recommend {
      fieldset {
        display: inline-block;
        position: relative;

        [type="radio"]:checked,
        [type="radio"]:not(:checked) {
          position: absolute;
          top: 0px;
          clip: rect(0, 0, 0, 0);
        }

        [type="radio"]:checked + label,
        [type="radio"]:not(:checked) + label {
          position: relative;
          cursor: pointer;
          line-height: 20px;
          display: inline-block;
        }

        [type="radio"]:checked + label.company-addreview-buttonyes {
          color: #ffffff;
          background-color: #36C004;
          //margin-right: 10px;
        }

        [type="radio"]:checked + label.company-addreview-buttonno {
          color: #ffffff;
          background-color: $danger;
          //margin-left: 10px;
        }
      }
    }

    &-addphoto {
      display: inline-block;
      background-image: url('/front/images/icon-photo.svg');
      padding: 9px 11px;
      vertical-align: middle;
    }

    &-addvideo {
      display: inline-block;
      background-image: url('/front/images/icon-video.svg');
      padding: 13px;
      vertical-align: middle;
    }

    &-linkvideo {
      display: inline-block;
      width: 160px;
      vertical-align: middle;

      .form-control {
        font-size: 10px;
        padding: 0 12px;
        height: 24px;
      }

      .input-group-append {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin-left: -1px;

        .input-group-text {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          margin-bottom: 0;
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.5;
          color: #495057;
          text-align: center;
          white-space: nowrap;
          background-color: #e9ecef;
          border: 1px solid #ced4da;
          border-radius: 0 2px 2px 0;
          border-left: none;
          background-image: url('/front/images/icon-close-dark.svg');
          height: 24px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: 65%;
          padding: 0 12px;
          cursor: pointer;
        }
      }
    }

    &-files {
      &-item {
        background-image: url('/front/images/icon-clip.svg');
        background-color: #F0F0F0;
        border: #D5D5D5 1px solid;
        display: inline-block;
        padding: 3px 6px 3px 21px;
        font-size: 10px;
        background-repeat: no-repeat;
        background-position: 5px center;
        background-size: 11px;
        border-radius: 12px;
        margin-right: 2px;
        cursor: default;
        margin-bottom: 5px;

        &-close {
          width: 14px;
          height: 14px;
          background-image: url('/front/images/icon-close-dark.svg');
          display: inline-block;
          vertical-align: middle;
          margin-left: 2px;
        }
      }
    }

    .btn-grey {
      padding: 5px 12px;
    }
  }
}

.comment-card-item {
  margin-bottom: 30px;

  &:nth-last-child(1) {
    margin-bottom: 0;
  }
}